import { personal_user_routes } from "./user-routes";
import { personal_cards_routes } from "./cards-routes";
import { personal_deposit_routes } from "./deposit-routes";
import { personal_fraud_routes } from "./fraud-routes";
import { personal_transfer_routes } from "./transfer-routes";
import { personal_savings_routes } from "./savings";
import { personal_compliance_rotes } from "./compliance";
import { personal_overview_routes } from "./overview";
import { personal_insights_routes } from "./insights-routes";
import { personal_scheduled_transaction_routes } from "./scheduled-routes";

export const personal_nested_routes = [
  ...personal_user_routes,
  ...personal_cards_routes,
  ...personal_fraud_routes,
  ...personal_deposit_routes,
  ...personal_transfer_routes,
  ...personal_savings_routes,
  ...personal_compliance_rotes,
  ...personal_overview_routes,
  ...personal_insights_routes,
  ...personal_scheduled_transaction_routes,
];
