import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { useProductColor } from "@/context/ProductTheme";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { iife } from "@/utils/general";
import { SelectedCard } from "..";

interface FreezeOrUnfreezeCardProps {
  onCancel(): void;
  selectedCard: SelectedCard;
  refetch: RefetchFn;
}

export const FreezeOrUnfreezeCardModal = (props: FreezeOrUnfreezeCardProps) => {
  const { onCancel, refetch } = props;
  const { card, ctx } = props.selectedCard;
  const [loading, setLoading] = useState(false);

  const { colorToken } = useProductColor();

  const ActionText = iife(() => {
    if (ctx === "freeze") return "Freeze Card";
    return "Unfreeze Card";
  });

  const isMainAction = ctx === "unfreeze";

  const handleFreezeOrUnfreeze = async () => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.freezeOrUnfreezeCard({
        action: ctx,
        id: card.id,
      });
      APIResponseHandler.tryHandler({ response });
      await refetch();
      onCancel();
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleFreezeOrUnfreeze}
      cancelText="No, Cancel"
      actionText={ActionText}
      btnColor={isMainAction ? colorToken : "red"}
      body={`Are you sure you want to ${
        isMainAction ? "Unfreeze" : "freeze"
      } this user card ? You can always come back to perform the action.`}
      title={isMainAction ? "Unfreeze Card" : "Freeze Card"}
    />
  );
};
