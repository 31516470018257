import * as TabPrimitives from "@radix-ui/react-tabs";
import "./styles/index.css";
import { cn } from "@/utils/colorConvert";
import { useDarkMode } from "@/hooks/useDarkMode";
import { TabWithDropdown } from "./TabWithDropdown";
import {
  TabElementDefaults,
  TabsElement,
  getSelectedTabValue,
  isTabWithDropdown,
} from "./shared";
import { useProductTheme } from "@/context/ProductTheme";
import { toggleRedact } from "@/redux/actions/actions";
import { iife } from "@/utils/general";

interface RenderAsProps {
  tab: TabsElement;
}

interface TabHeaderProps {
  /** can pass in an array of string values or an object of label, and value */
  tabs: TabsElement[] | readonly TabsElement[];
  className?: string;
  /** Pass a custom function that allows you to render any element as a tab header
   * useful with icons, or custom tab implementations
   *
   * - listen to active state by styling
   * `[data-state]     "active" | "inactive"`
   */
  renderAs?: (props: RenderAsProps) => React.ReactNode;
  selectedDropdown?: TabElementDefaults;
  pageSlug?: string;
}

const TabHeader = (props: TabHeaderProps) => {
  const { tabs, className, renderAs, selectedDropdown, pageSlug } = props;
  const [theme] = useDarkMode(localStorage.getItem("theme"));

  const isDarkMode = theme === "dark";

  return (
    <TabPrimitives.List
      className={cn(
        "tabcomponent__list hide-scrollbar",
        isDarkMode && "bdr-eee-333 tabcomponent-dark",
        className,
      )}
    >
      {tabs.map((ele) => {
        if (renderAs) return renderAs({ tab: ele });

        if (typeof ele === "string") {
          return (
            <TabPrimitives.Trigger
              key={ele}
              className="tabcomponent__trigger"
              value={ele}
            >
              {ele}
            </TabPrimitives.Trigger>
          );
        }

        const { dropdown, label, value } = ele;

        if (dropdown) {
          return (
            <TabWithDropdown
              key={ele.value}
              selectedDropdown={selectedDropdown}
              pageSlug={pageSlug}
              tab={{ ...ele, dropdown }}
            />
          );
        }

        return (
          <div key={value} className="tabcomponent__trigger__wrapper">
            <TabPrimitives.Trigger className="tabcomponent__trigger" value={value}>
              {label}
            </TabPrimitives.Trigger>
          </div>
        );
      })}
    </TabPrimitives.List>
  );
};

interface TabContentProps extends Omit<TabPrimitives.TabsContentProps, "value"> {
  value: TabsElement;
}

const TabContent = ({ value, ...props }: TabContentProps) => {
  return (
    <TabPrimitives.Content
      {...props}
      className={cn("tabcomponent__content", props.className)}
      value={getSelectedTabValue(value)}
    />
  );
};

export type TabComponentProps = Omit<
  TabPrimitives.TabsProps,
  "defaultValue" | "value"
> & {
  defaultValue?: TabsElement;
  value?: TabsElement;
  variant?: "primary" | "secondary";
  /**
   * To get the original value passed
   */
  tabs?: TabHeaderProps["tabs"];
  onTabValueChange?(tab: TabHeaderProps["tabs"][number]): void;
};

/**
 * @example
 * ```tsx
 * <TabComponent>
 *   <TabComponent.Tabs tabs={["rehk", "mansa", "codes"]}/>
 *   {// number of Content should be equal to the number of tabs }
 *   <TabComponent.Content value="rehk" />
 *   <TabComponent.Content value="mansa" />
 *   <TabComponent.Content value="codes" />
 * </TabComponent>
 * ```
 */
const TabComponent = (props: TabComponentProps) => {
  const {
    defaultValue,
    value,
    variant = "primary",
    tabs,
    onTabValueChange,
    onValueChange,
    ...prop
  } = props;

  const { productTheme: product } = useProductTheme();

  const { bgColor, color } = iife(() => {
    switch (product) {
      case "personal":
        return { color: "#755ae2", bgColor: "#FAFAFF" };
      case "atlas":
        return { color: "#0B8376", bgColor: "#E8FFF6" };
      case "pos":
        return { color: "#EA872D", bgColor: "#FFF6ED" };
      case "business":
        return { color: "#020202", bgColor: "#F7F7F7" };
    }
  });

  return (
    <TabPrimitives.Root
      {...prop}
      style={
        {
          "--tab-active-color": color ?? "#755ae2",
          "--tab-bg-active-color": bgColor ?? "#FAFAFF",
        } as React.CSSProperties
      }
      className={cn("tabcomponent", `tabcomponent-${variant}`, props.className)}
      defaultValue={getSelectedTabValue(defaultValue)}
      value={getSelectedTabValue(value)}
      onValueChange={(selected) => {
        if (onTabValueChange) {
          if (!tabs)
            throw new Error("You are trying to access onChange without passing tabs");
          const selectedTab = tabs.find((tab) => {
            if (!isTabWithDropdown(tab)) return tab === selected;
            return tab.value === selected;
          });

          if (!selectedTab) {
            throw new Error("Make sure value passed matches the selected");
          }

          toggleRedact(true);
          onTabValueChange(selectedTab);
        }

        if (onValueChange) {
          onValueChange(selected);
        }
      }}
    />
  );
};

TabComponent.Tabs = TabHeader;
TabComponent.Content = TabContent;

export default TabComponent;

export * from "./shared";
