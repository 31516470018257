import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useSingleUserState } from "../../context";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { PersonalComplianceCards } from "./compliance-card";
import { TypeIs, renderConditionally, safeParse } from "@/utils/helpers";
import { cn } from "@/utils/colorConvert";
import { Util } from "@/utils/utility";
import { capitalizeFirstLetter, formatDateTime } from "@/utils/helper/Helper";
import { PersonalAPIService } from "@/redux/slices/personal";
import { useState } from "react";
import { APIResponseHandler } from "@/utils/api";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import ActionModal from "@/components/common/modal/ActionModal";
import { toast } from "@ravenpay/raven-bank-ui";
import imagePlaceholder from "@/assets/images/image-placeholder.png";

export const UsersCompliance = () => {
  const {
    email,
    refetch: refetchUser,
    nin_verified_at,
    address_verified_at,
    bvn_verified_at,
    next_of_kin_verified_at,
  } = useSingleUserState();
  const [loading, setLoading] = useState(false);
  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetUserComplianceQuery(email);

  const [revokeModal, setRevokeModal] = useState<"nin" | "bvn" | "address">();
  const { renderDynamicModal } = useDynamicModal(Boolean(revokeModal));

  const joinedLoader = loading || isLoading || isFetching;

  if (isLoading || !data)
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );

  const { address, bvn, next_of_kin, nin } = data;

  const handleRevoke = async () => {
    if (!revokeModal) return toast.error("Please select an option");
    setLoading(true);
    try {
      const response = await PersonalAPIService.stripVerification({
        email,
        verification_type: revokeModal,
      });
      APIResponseHandler.tryHandler({ response });
      setRevokeModal(undefined);
      await (refetchUser() as any);
      await refetch();
    } catch (error) {
      APIResponseHandler.catchHandler({ error });
    }
    setLoading(false);
  };

  return (
    <div className="mt-20 space-y-20">
      <BVNPage
        loading={joinedLoader}
        bvn={bvn}
        onRevokeClick={() => setRevokeModal("bvn")}
        date_verified={bvn_verified_at}
      />
      <NINPage
        loading={joinedLoader}
        nin={nin}
        onRevokeClick={() => setRevokeModal("nin")}
        date_verified={nin_verified_at}
      />
      <NextOfKin
        loading={joinedLoader}
        nextOfKin={next_of_kin}
        date_verified={next_of_kin_verified_at}
      />
      <Address
        loading={joinedLoader}
        address={address}
        onRevokeClick={() => setRevokeModal("address")}
        date_verified={address_verified_at}
      />

      {revokeModal &&
        renderDynamicModal(
          <ActionModal
            visible={Boolean(revokeModal)}
            onCancel={() => setRevokeModal(undefined)}
            onClick={handleRevoke}
            cancelText="No, Cancel"
            actionText="Yes, Revoke"
            btnColor="red"
            body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
            title={`Revoke ${
              revokeModal.includes("address")
                ? capitalizeFirstLetter(revokeModal)
                : revokeModal.toUpperCase()
            } ?`}
            loading={loading}
          />
        )}
    </div>
  );
};

type BaseProps<T> = {
  onRevokeClick(): void;
  loading: boolean;
  date_verified: string;
} & T;

const BVNPage = ({
  bvn,
  onRevokeClick,
  loading,
  date_verified,
}: BaseProps<{ bvn: PersonalBVN | null }>) => {
  if (!bvn) {
    return (
      <PersonalComplianceCards
        meta={{ app: "Personal Banking", label: "BVN Verification", type: "BVN" }}
        data={null}
        loading={loading}
        isVerified={false}
      />
    );
  }

  const {
    photo,
    first_name,
    last_name,
    middle_name,
    bvn: bvnToken,
    ...rest
  } = safeParse<PersonalBVNMeta>(bvn.res) ?? ({} as PersonalBVNMeta as any);

  const others = TypeIs.any(rest).data ? TypeIs.any(rest).data : TypeIs.any(rest);

  return (
    <PersonalComplianceCards
      meta={{ app: "Personal Banking", label: "BVN Verification", type: "BVN" }}
      data={{
        fullName: cn(Util.safeText(first_name), Util.safeText(last_name), middle_name),
        ...others,
        BVN: bvnToken ?? bvn.token,
        "Date Authorized": getDateAuthorized(date_verified),
      }}
      onRevokeClick={onRevokeClick}
      imgData={loading ? [] : [{ alt: "image", src: photo || imagePlaceholder }]}
      loading={loading}
      isVerified={isVerifiedFn(date_verified)}
    />
  );
};

const NINPage = ({
  nin,
  onRevokeClick,
  loading,
  date_verified,
}: BaseProps<{ nin: PersonalNIN | null }>) => {
  if (!nin) {
    return (
      <PersonalComplianceCards
        meta={{ app: "Personal Banking", label: "NIN Verification", type: "NIN" }}
        data={null}
        loading={loading}
        isVerified={false}
      />
    );
  }

  const payload = safeParse<PersonalNINResponse>(nin.res) ?? ({} as PersonalNINResponse);

  const getData = () => {
    if (!payload) return {} as PersonalNINMainData;
    if ("data" in payload) return payload.data;
    return payload as PersonalNINMainData;
  };
  const {
    first_name,
    last_name,
    address,
    lga_of_residence,
    photo,
    middle_name,
    bvn,
    nin: token,
    ...rest
  } = getData() as any;

  return (
    <PersonalComplianceCards
      meta={{ app: "Personal Banking", label: "NIN Verification", type: "NIN" }}
      data={{
        fullName: cn(Util.safeText(first_name), Util.safeText(last_name), middle_name),
        Address: Util.safeText(address),
        nin: Util.safeText(token ?? nin.token),
        ...TypeIs.any(rest),
        "Date Authorized": getDateAuthorized(date_verified),
      }}
      onRevokeClick={onRevokeClick}
      loading={loading}
      isVerified={isVerifiedFn(date_verified)}
    />
  );
};

type WithLoading<T> = {
  loading: boolean;
} & T;

const NextOfKin = ({
  nextOfKin,
  loading,
  date_verified,
}: WithLoading<{ nextOfKin: PersonalNextOfKin | null; date_verified: string }>) => {
  if (!nextOfKin) {
    return (
      <PersonalComplianceCards
        meta={{
          app: "Personal Banking",
          label: "Next of Kin",
          type: "Next of kin",
        }}
        data={null}
        loading={loading}
        isVerified={false}
      />
    );
  }

  const { updated_at, created_at, first, last, kin_email, email, id, ...rest } =
    nextOfKin;

  return (
    <PersonalComplianceCards
      meta={{
        app: "Personal Banking",
        label: "Next of Kin",
        type: "Next of kin",
      }}
      data={{
        fullName: cn(Util.safeText(first), Util.safeText(last)),
        email: Util.safeText(kin_email),
        ...TypeIs.any(rest),
        "Date Authorized": getDateAuthorized(date_verified),
      }}
      loading={loading}
      isVerified={isVerifiedFn(date_verified)}
    />
  );
};

const Address = ({
  address,
  loading,
  date_verified,
  onRevokeClick,
}: WithLoading<{
  address: PersonalAddress | null;
  date_verified: string;
  onRevokeClick?: () => void;
}>) => {
  if (!address) {
    return (
      <PersonalComplianceCards
        meta={{
          app: "Personal Banking",
          label: "Address",
          type: "Address",
        }}
        data={null}
        loading={loading}
        isVerified={false}
      />
    );
  }

  const {
    location_image2_url,
    location_image_url,
    state,
    lga,
    location,
    home_address,
    landmark,
  } = address;

  return (
    <PersonalComplianceCards
      meta={{
        app: "Personal Banking",
        label: "Address",
        type: "Address",
      }}
      data={{
        state: Util.safeText(state),
        lga: Util.safeText(lga),
        home_address: Util.safeText(home_address),
        landmark: Util.safeText(landmark),
        "Date Authorized": getDateAuthorized(date_verified),
      }}
      imgData={[
        { alt: "image", src: location_image_url || imagePlaceholder },
        { alt: "image", src: location_image2_url || imagePlaceholder },
      ]}
      loading={loading}
      onRevokeClick={onRevokeClick}
      isVerified={isVerifiedFn(date_verified)}
    />
  );
};

function isVerifiedFn(status: unknown) {
  return Boolean(status);
}

const getDateAuthorized = (date?: string) => {
  if (!date) return "N/A";
  return String("");
};
