import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { formatStatus, mapHotkey } from "@/utils/helper/Helper";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";

import "./styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";

const Tabs = [
  "Deposit",
  "Transfer",
  "Airtime",
  "Data",
  "Electricity",
  "Cable",
  "Betting",
];

const BusinessResolutionCenter = () => {
  const [empty, setEmpty] = useState(false);
  const [blockModal, showBlockModal] = useState({ chi: "", on: false });
  const [integrity, showIntegrityModal] = useState({ chi: "", on: false });
  const navigate = useNavigate();
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<string>("Deposit");

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Resolution Center">
          <div className="personal-resolution__top-wrap">
            <TabComponent onValueChange={(e) => setSelectedTab(e)} defaultValue={Tabs[0]}>
              <TabComponent.Tabs tabs={Tabs} />
              {Tabs.map((tab) => (
                <TabComponent.Content key={tab} value={tab}></TabComponent.Content>
              ))}
            </TabComponent>
          </div>
          <div className="personal-resolution__container">
            <div className="personal-resolution__container--input-view">
              <div className="input-view">
                <div className="input-view__title">{selectedTab}</div>
                {selectedTab === "Airtime" || selectedTab === "Data" ? (
                  <>
                    <div className="input-view__input">
                      <RavenInputField
                        label="Transfer Reference"
                        placeholder="9045 xxxx"
                        type={"phone"}
                        color="black-light"
                      />
                    </div>
                  </>
                ) : (
                  <div className="input-view__input">
                    <RavenInputField
                      label="Transfer Reference"
                      placeholder="Withrawal"
                      color="black-light"
                    />
                  </div>
                )}

                <div className="input-view__button">
                  <RavenButton color={"black-light"} label="Reprocess Transfer" />
                </div>
              </div>
            </div>

            <div className="personal-resolution__container--result-view focused">
              <InlineDetails
                direction={""}
                title={"Transaction Details"}
                amount={0}
                onClick={() => {}}
                content={[
                  {
                    label: "Recipient",
                    value: "@loomie",
                  },
                  {
                    label: "Description",
                    value: "Money Recieved from  Adekunl...",
                  },
                  {
                    label: "Description",
                    value: formatStatus("successful") as unknown as string,
                  },
                  {
                    label: "Reference ID",
                    value: "6859438374576",
                    copy: true,
                  },

                  {
                    label: "Session ID",
                    value: "6859438374576",
                    copy: true,
                  },
                  {
                    label: "Fee",
                    value: "₦10",
                  },

                  {
                    label: "Date",
                    value: "5 Sept, 2022 - 5:48PM",
                  },
                ]}
              />
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={blockModal.on}
        onCancel={() => {
          showBlockModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Unblock"
        btnColor="black"
        body="Are you sure to unblock this user? You can always come back to perform the action."
        title="Unblock User?"
      />

      <ActionModal
        visible={integrity.on}
        onCancel={() => {
          showIntegrityModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Transaction is Genuine"
        btnColor="#1ACE37"
        hideCancel={true}
        body="This would be sending a message to 235 businesses pertaining to their blocked accounts."
        title="Confirm the Integrity of Transaction"
      />
    </>
  );
};

export default BusinessResolutionCenter;
