import PersonalApi from "@/utils/axios/personal";
import { Util } from "@/utils/utility";
import { toast } from "@ravenpay/raven-bank-ui";
import { APIResponseHandler } from "@/utils/api";
import { GLOBAL_PER_PAGE } from "@/constants";

export const searchPhysicalCards = async (search: string) => {
  const pagination = {
    perPage: GLOBAL_PER_PAGE,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    totalPages: 1,
    total: 1,
  };

  const isValid = (str: string) => str !== undefined;

  const [first, last] = Util.splitByAsterisksAndUnderscores(search);

  if (!isValid(first) || !isValid(last)) {
    return toast.error("Card pan should be separated by asterisk");
  }

  try {
    const res = await PersonalApi.post("/support/search_physical_card", {
      first_six_digits: first,
      last_four_digits: last,
    });

    const isSuccess = APIResponseHandler.tryHandler({ response: res });

    if (isSuccess) {
      if (Array.isArray(res.data.data))
        return {
          data: {
            physicalCards: res.data.data,
            pagination: { ...pagination, total: res.data.data.length },
          },
        };

      return {
        data: {
          physicalCards: [res.data.data],
          pagination,
        },
      };
    }
  } catch (error) {
    APIResponseHandler.catchHandler(error);
  }
};
