import "./styles/index.css";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES, SPECIAL_ROUTES } from "@/constants";
import { TabsElement } from "@/components/common/Tabs";

const Tabs: TabsElement[] = [
  {
    label: "BVN Verification",
    value: "bvn",
  },
  { label: "NIN Verification", value: "nin" },

  {
    label: "Address Verification",
    value: "address",
  },
  {
    label: "CAC Verification",
    value: "cac",
  },
  {
    label: "Business Details",
    value: "business_info",
  },
  { label: "Details Checker", value: SPECIAL_ROUTES.DETAILS_CHECKER },
];

const BusinessCompliance = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Compliance">
        <OutletTab>
          <OutletTab.Tabs
            className={GENERAL_CLASSES.OUTLET}
            baseURL="/business-compliance"
            tabs={Tabs}
            specialUrls={[SPECIAL_ROUTES.DETAILS_CHECKER]}
          />
          <OutletTab.Outlet />
        </OutletTab>
      </PageLayout>
    </DashboardLayout>
  );
};

export default BusinessCompliance;
