import EmptyScreen from "@/components/common/emptyScreen";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { formatNumberToCurrency, renderConditionally } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import TwinValue from "@/components/common/twinValue";
import { isEmpty } from "../__internals__/assertIfEmpty";
import { Util } from "@/utils/utility";
import { useSelector } from "react-redux";
import Redacted from "@/components/common/shimmer/redact";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  trimLongString,
} from "@/utils/helper/Helper";
import usePosGetQuery from "../__internals__/hooks/usePosGetQuery";
import SmartFilter from "@/components/common/smartFilter";
import { useState } from "react";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";

const PosVirtualAccountsTable = () => {
  const { SmartFilterProps, combinedLoading, tableData } =
    usePosGetQuery<PosBankCollection>("getBankCollections");

  const merchants = tableData;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<
      { meta: PosBankCollectionMeta; tx: PosBankCollection } & PosBankCollection
    >;
  }>({
    on: false,
    data: {},
  });
  return (
    <>
      <SmartFilter {...SmartFilterProps} searchTitle="Search bank collection..." />
      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "AMOUNT",
            "FEE",
            "SOURCE",
            "DESTINATION",
            "TYPE",
            "DATE DEPOSITED",
          ]}
        >
          {merchants?.map((chi, idx) => {
            const meta: PosBankCollectionMeta = JSON.parse(chi.source);

            return (
              <RavenTableRow
                key={idx}
                onRowClick={() =>
                  setView({
                    on: true,
                    data: {
                      ...chi,
                      tx: chi,
                      meta,
                    },
                  })
                }
                one={
                  isRedacting ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <TwinValue
                      one={trimLongString(
                        Util.join(meta?.first_name).with(meta?.last_name),
                        17
                      )?.toUpperCase()}
                      two={Util.safeText(chi?.email)}
                      copy
                    />
                  )
                }
                two={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(Number(chi?.amount), chi.currency)
                  )
                }
                three={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(Number(chi?.fee), chi.currency)
                  )
                }
                four={
                  isRedacting ? (
                    <Redacted.Labelled />
                  ) : (
                    <TwinValue
                      one={Util.join(meta?.first_name)
                        .with(meta?.last_name)
                        .toUpperCase()}
                      two={`${Util.safeValue(
                        trimLongString(meta.bank, 15)
                      )?.toUpperCase()} • ${Util.safeText(meta?.account_number)}`}
                    />
                  )
                }
                five={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    Util.safeValue(chi.account_number)
                  )
                }
                six={
                  <TwinValue
                    one={Util.safeValue(chi.type)}
                    two={Util.join("TID:").with(Util.safeValue(chi?.t_id))}
                    copy
                  />
                }
                seven={isRedacting ? <Redacted.Table /> : formatDateTime(chi.created_at)}
              />
            );
          })}
        </RavenTable>
      )}

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        // onClick={() =>
        //   // handleDownload(String(view.data.trx_ref), String(view.data.business_id))
        // }
        onClick={() => {}}
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={combinedLoading}
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={"credit"}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "Amount",
                value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
                // copy: true,
              },

              ...renderConditionally("TID", view?.data?.t_id),

              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },
              ...renderConditionally(
                "Sender Name",
                view?.data?.meta?.first_name + " " + view.data.meta?.last_name
              ),
              ...renderConditionally("Account Number", view?.data?.meta?.account_number),
              ...renderConditionally("Bank", view?.data?.meta?.bank),

              {
                label: "Session ID",
                value: view.data?.session_id,
                copy: true,
                trim: true,
              },

              // {
              //   label: "Status",
              //   value: formatStatus(detectStatus(Number(view.data?.meta?.status))),
              // },

              {
                label: "Date Authorized",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </>
  );
};

export const PosBankCollections = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Bank Collection">
        <PosVirtualAccountsTable />
      </PageLayout>
    </DashboardLayout>
  );
};
