import "./style/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatStatus } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { CopyText } from "@/components/common/copyCheck";
import { cn } from "@/utils/colorConvert";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { iife } from "@/utils/general";

interface CardTypeProps {
  status: string;
}
const CardType = ({ status }: CardTypeProps) => {
  const cardStatus = iife(() => {
    switch (status?.toLowerCase()) {
      case "funding":
        return "successful";
      case "purchase":
        return "pending";
      case "refund":
        return "refunded";
      case "charge back":
        return "inreview";
      default:
        return "deactivated";
    }
  });

  return (
    <div className={cn("bugiss-status", cardStatus)}>
      <p>{status}</p>
    </div>
  );
};

const status = ["successful", "pending", "failed", "pending"];

const cardTypes = ["Funding", "Purchase", "Refund", "Charge Back"];

export const PosCardDetails = () => {
  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Merchant Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img card-avatar-image">
              {icons.atlas_cards}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  <MainContentCol.Item title="Card holder email">
                    emmy4sure.web@gmail...
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Date Joined">
                    26, October 2023
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Card Name">
                    Netflix Subscriptions
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Currency">Dollar ($)</MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Card Type">
                    Virtual Card
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Expiry Date">12 / 25</MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Card CVV">456</MainContentCol.Item>
                  <MainContentCol.Item title="Card Balance">$400</MainContentCol.Item>
                </MainContentCol>
              </div>
              <div className="user-data__account-summary">
                <div className="top-wrap__actions">
                  <IconButton
                    borderColor="#FF0F00"
                    text="Terminate Card"
                    icon="slash_circle_01"
                  />
                  <IconButton
                    text="Freeze Card"
                    borderColor="#EA872D"
                    icon="snowflakes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-user-details__table-wrap">
          <YStack>
            <SmartFilter />
          </YStack>
          <RavenTable
            headerList={[
              "REFERENCE",
              "CARD ID",
              "DESCRIPTION",
              "AMOUNT",
              "TYPE",
              "TRANSACTION DATE",
              "STATUS",
            ]}
          >
            {status.map((status, idx) => (
              <RavenTableRow
                key={status}
                one="7685493023456..."
                two={<CopyText text="7867954876..." />}
                three="Table Body Content"
                four="$200"
                six="₦12,000,000.00"
                five={<CardType status={cardTypes[idx]} />}
                seven={formatStatus(status)}
              />
            ))}
          </RavenTable>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
