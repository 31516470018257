import BusinessOverview from "@/apps/business/overview";
import BusinessPage from "@/apps/business/businesses";
// import BusinessSingleUser from "@/apps/business/businesses/components/singleBusiness";
import TransactionsPage from "@/apps/business/transactions";
import { BBKickbacksPage, BBTempKickbacksInner } from "@/apps/business/kickbacks";
import { BBExpensesPage } from "@/apps/business/expenses";
import BusinessTransfers from "@/apps/business/transfers";
import BusinessFraudCenter from "@/apps/business/fraud";
import BusinessResolutionCenter from "@/apps/business/resolutionCenter";
// import BusinessCompliance from "@/apps/business/compliance";
import BusinessDispute from "@/apps/business/dispute";
import BusinessNotification from "@/apps/business/notification";
import BusinessPendingDeposits from "@/apps/business/pendingDeposits";
import BusinessPayrolls from "@/apps/business/payrolls";
import BusinessPayrollDetails from "@/apps/business/payrolls/component/payrollView";
import BusinessPayrollEmployeeDetails from "@/apps/business/payrolls/component/employeeDetails";
import BusinessBankbox from "@/apps/business/bankbox";
import BusinessBankboxDetail from "@/apps/business/bankbox/bankboxDetail";
import { BBCards, BusinessTempPhysicalCards } from "@/apps/business/cards";
import { BBInvoices } from "@/apps/business/invoices";
import { BBBulkTransferDetails } from "@/apps/business/transfers/pages/bulk-transfer-details";
import BusinessPaymentLinks from "@/apps/business/paymentLinks";
import BusinessPaymentLinksDetails from "@/apps/business/paymentLinks/linkDetails";
import { BBBills } from "@/apps/business/bills";
import {
  BillsAirtimePage,
  BillsBettingPage,
  BillsCablePage,
  BillsDataPage,
  BillsElectricityPage,
} from "@/apps/business/bills/components/pages";
import { BusinessVirtualCardsPage } from "@/apps/business/cards/components/VirtualCards";
import { SingleBusinessCardPage } from "@/apps/business/cards/components/SingleCardPage";
import { BusinessAllTransfer } from "@/apps/business/transfers/pages/all-transfers";
import { BusinessDeductedDepositsPage } from "@/apps/business/transfers/pages/deducted-deposits";
import { BusinessTransferIssuesPage } from "@/apps/business/transfers/pages/transfer-issues";
import { BusinessTransferSummary } from "@/apps/business/transfers/pages/transfer-summary";
import { business_compliance_routes } from "./nested-routes/compliance";
import BBAuditTrail from "@/apps/business/auditTrail";
import { GlobalAccountDetails } from "@/apps/business/global-account/account-details";
import BGlobalDeposits from "@/apps/business/global-account/pages/Deposits";
import BGlobalTransfers from "@/apps/business/global-account/pages/Transfers";
import { BBGlobalWallets } from "@/apps/business/global-account";
import { BGlobalAccounts } from "@/apps/business/global-account/pages/Accounts";
import BlockedBusiness from "@/apps/business/fraud/pages/BlockedBusiness";
import BRagnarStrike from "@/apps/business/fraud/pages/RagnarStrike";
import LienedBusinesses from "@/apps/business/fraud/pages/LienedBusinesses";
import UnlienedBusinesses from "@/apps/business/fraud/pages/UnlienedBusinesses";
import { business_details_routes } from "./nested-routes/business_details";
import { business_hni_routes } from "./nested-routes/hni";
import BusinessHNI from "@/apps/business/hni";
import BusinessHniManagers from "@/apps/business/hni/managers";
import { BGBusinessDisputes } from "../../apps/business/disputes";

export const business_routes_group: RouteArray[] = [
  { path: "/business-overview", element: BusinessOverview },
  { path: "/business-businesses", element: BusinessPage },
  { path: "/business-hni", element: BusinessHNI },
  { path: "/business-managers", element: BusinessHniManagers },

  { path: "/business-transactions", element: TransactionsPage },
  { path: "/business-transactions", element: TransactionsPage },
  {
    path: "/business-kickbacks",
    element: BBKickbacksPage,
    children: [
      { path: "", element: () => <BBTempKickbacksInner tab="Airtime" /> },
      { path: "airtime", element: () => <BBTempKickbacksInner tab="Airtime" /> },
      { path: "data", element: () => <BBTempKickbacksInner tab="Data" /> },
      {
        path: "electricity",
        element: () => <BBTempKickbacksInner tab="Electricity" />,
      },
      { path: "cable-tv", element: () => <BBTempKickbacksInner tab="Cable TV" /> },
      { path: "betting", element: () => <BBTempKickbacksInner tab="Betting" /> },
    ],
  },
  { path: "/business-expenses", element: BBExpensesPage },
  {
    path: "/business-transfer",
    element: BusinessTransfers,
    children: [
      { path: "", element: BusinessAllTransfer },
      { path: "transfers", element: BusinessAllTransfer },
      { path: "deducted-deposits", element: BusinessDeductedDepositsPage },
      { path: "transfer-issues", element: BusinessTransferIssuesPage },
      { path: "transfer-summary", element: BusinessTransferSummary },
    ],
  },
  {
    path: "/business-transfer/bulk-transfers/:bulk_reference/:business_email",
    element: BBBulkTransferDetails,
  },
  {
    path: "/business-fraud-center",
    element: BusinessFraudCenter,
    children: [
      { path: "", element: BlockedBusiness },
      { path: "blocked-business", element: BlockedBusiness },
      { path: "ragnar-strike", element: BRagnarStrike },
      { path: "liened-accounts", element: LienedBusinesses },
      { path: "unlien-history", element: UnlienedBusinesses },
    ],
  },
  { path: "/business-resolution-center", element: BusinessResolutionCenter },
  { path: "/business-dispute", element: BusinessDispute },
  { path: "/business-notification", element: BusinessNotification },
  { path: "/business-pending-deposits", element: BusinessPendingDeposits },
  { path: "/business-payroll", element: BusinessPayrolls },
  { path: "/business-payroll/details", element: BusinessPayrollDetails },
  { path: "/business-payroll/details/:id", element: BusinessPayrollEmployeeDetails },
  { path: "/business-bankbox", element: BusinessBankbox },
  { path: "/business-bankbox/:id", element: BusinessBankboxDetail },
  { path: "/business-links", element: BusinessPaymentLinks },
  { path: "/business-links/:id", element: BusinessPaymentLinksDetails },
  {
    path: "/business-cards",
    element: BBCards,
    children: [
      { path: "", element: BusinessVirtualCardsPage },
      { path: "virtual-cards", element: BusinessVirtualCardsPage },
      { path: "physical-cards", element: BusinessTempPhysicalCards },
    ],
  },
  { path: "/business-cards/:id", element: SingleBusinessCardPage },
  { path: "/business-invoices", element: BBInvoices },
  { path: "/business-audit-trail", element: BBAuditTrail },
  {
    path: "/business-bills",
    element: BBBills,
    children: [
      { path: "", element: BillsAirtimePage },
      { path: "airtime", element: BillsAirtimePage },
      { path: "data", element: BillsDataPage },
      { path: "electricity", element: BillsElectricityPage },
      { path: "cable", element: BillsCablePage },
      { path: "betting", element: BillsBettingPage },
    ],
  },
  {
    path: "/business-global-account",
    element: BBGlobalWallets,
    children: [
      {
        path: "",
        element: BGlobalAccounts,
      },
      {
        path: "accounts",
        element: BGlobalAccounts,
      },
      {
        path: "deposits",
        element: BGlobalDeposits,
      },
      {
        path: "transfers",
        element: BGlobalTransfers,
      },
    ],
  },

  {
    path: "/business-global-account/accounts/:id",
    element: () => <GlobalAccountDetails />,
  },
  {
    path: "/business-disputes",
    element: BGBusinessDisputes,
  },
  ...business_compliance_routes,
  ...business_details_routes,
  ...business_hni_routes,
];
