import { PersonalSavings } from "~pb/savings";
import {
  FixedSavingsPage,
  GlobalSavingsPage,
  HIFASAvingsPage,
  NewFixedSavingsPage,
  NewTargetSavingsPage,
  Pockets,
  SpendXSave,
  TargetSavingsPage,
  PersonalSingleSavingsPage,
  PersonalSingleSavingsPocketPage,
  PersonalSingleSavingsSpendSavePage,
} from "~pb/savings/pages";

export const personal_savings_routes: RouteArray[] = [
  {
    path: "/personal-savings",
    element: PersonalSavings,
    children: [
      { path: "", element: FixedSavingsPage },
      { path: "fixed", element: FixedSavingsPage },
      { path: "fixed_new", element: NewFixedSavingsPage },
      { path: "target", element: TargetSavingsPage },
      { path: "target_new", element: NewTargetSavingsPage },
      { path: "hifa", element: HIFASAvingsPage },
      { path: "global", element: GlobalSavingsPage },
      { path: "spendxsave", element: SpendXSave },
      { path: "pocket", element: Pockets },
    ],
  },
  { path: "/personal-savings/:id", element: PersonalSingleSavingsPage },
  {
    path: "/personal-savings/spendsave/:id",
    element: PersonalSingleSavingsSpendSavePage,
  },
  { path: "/personal-savings/pocket/:id", element: PersonalSingleSavingsPocketPage },
];
