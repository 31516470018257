import { ManualDepositResolution } from "@/apps/personal/resolution-center/pages/manual-deposit-resolution";
import ActionModal from "@/components/common/modal/ActionModal";
import { cn } from "@/utils/colorConvert";

interface Props {
  onClose(): void;
}

export const PbManualDepositModal = (props: Props) => {
  const { onClose } = props;
  return (
    <ActionModal
      hideCancel
      hideNormalButton
      visible
      preventOuterClose
      big
      title="Missing Deposit"
      onCancel={onClose}
      onClick={() => {}}
      className={cn("manual_deposit_resolution_modal")}
    >
      <div className="manual_deposit_resolution_modal__wrapper">
        <ManualDepositResolution selectPosition="top" onClose={onClose} />
      </div>
    </ActionModal>
  );
};
