import Mascot from "@/assets/images/cards/coming-soon-mascot.png";
import { PersonalVirtualCardsPage } from "./virtual-cards";
import { getQueryVariable } from "@/utils/helpers";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { TRANSFER_EMPTY_SCREEN } from "@/constants";
import { PersonalPhysicalCardsPage } from "./physical-cards";

const MascotImage = () => (
  <figure>
    <img src={Mascot} alt="" />
  </figure>
);

const EmptyScreen = () => (
  <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} mascot={<MascotImage />} />
);

export const UsersCards = () => {
  const type = getQueryVariable("type");

  if (type === "physical-cards") return <PersonalPhysicalCardsPage />;

  return <PersonalVirtualCardsPage />;
};
