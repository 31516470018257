import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./styles/index.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const POSCategoryMetrics = () => {
  const data = {
    labels: ["BankBox", "K • 11"],
    datasets: [
      {
        data: [125584483022, 125584483022],
        backgroundColor: ["#F0EEFF", "#FFF6ED"],
        borderColor: ["#755AE2", "#EA872D"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="pos-category-metrics-card">
      <div className="header">
        <h2>POS CATEGORY METRICS</h2>
        <div className="year-selector">
          <span>2023</span>
        </div>
      </div>
      <div className="chart-container">
        <Pie data={data} options={options} />
      </div>
      <div className="metrics">
        <div className="metric">
          <span className="dot" style={{ backgroundColor: "#755AE2" }}></span>
          <span className="label">BankBox</span>
          <span className="value">₦125,584,483,022</span>
        </div>
        <div className="metric">
          <span className="dot" style={{ backgroundColor: "#EA872D" }}></span>
          <span className="label">K • 11</span>
          <span className="value">₦125,584,483,022</span>
        </div>
      </div>
    </div>
  );
};

export default POSCategoryMetrics;
