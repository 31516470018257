type Status =
  | (
      | "success"
      /** This status is a never hit status, but still provision a fallback for it */
      | "failed"
    )
  | ("reversed" | "failed" | "successful" | "pending_reversal");
export const getCombinedTransferStatus = (ctx: "internal" | "external") => {
  const getStatus = (status: number, refunded: IsUncertain<number>): Status => {
    if (ctx === "internal") return status === 4 ? "success" : "failed";

    if (refunded && refunded === 1) return "reversed";
    switch (status) {
      case 2:
        return "failed";
      case 3:
        return "successful";
      case 5:
        return "pending_reversal";
      default:
        return "failed";
    }
  };

  return { getStatus };
};
