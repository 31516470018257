import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { IconVault, formatStatus } from "@/utils/helper/Helper";
import { FakePaginationProps } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";

const isEmpty = false;

const MerchantsTable = () => {
  return (
    <div>
      <SmartFilter pagination={FakePaginationProps} />
      <RavenTable
        headerList={[
          "MERCHANT NAME",
          "EMAIL ADDRESS",
          "MOBILE NUMBER",
          "DATE JOINED",
          "STATUS",
          " ",
        ]}
      >
        <RavenTableRow
          one={<Avatar name="Rehk and Sons Auto LTD" full />}
          two="emmy4sure.web@gmail.com"
          three="070 1526 3711"
          four="5 Sept, 2022"
          five={formatStatus("active")}
          six={
            <Dropdown
              rightPosition
              items={[
                { label: "View Details", img: IconVault(icons.edit_04) },
                { label: "Deactivate Merchant", img: IconVault(icons.user_profile_x) },
                { label: "Generate Statement", img: IconVault(icons.file) },
                { label: "Block Merchant", img: IconVault(icons.slash_circle_01) },
              ]}
              buttonChild={() => {
                return <ActionDrop more />;
              }}
            />
          }
        />
      </RavenTable>
    </div>
  );
};

export const AtlasMerchants = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Merchants">
        {isEmpty ? <EmptyScreen /> : <MerchantsTable />}
      </PageLayout>
    </DashboardLayout>
  );
};
