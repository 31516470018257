import PLACEHOLDER_IMAGE from "@/assets/images/image-placeholder.png";

export const REASONS_FOR_BLOCK = [
  { label: "Erroneous Transfer", value: "erroneous-transfer" },
  { label: "Crypto Ban", value: "crypto-ban" },
  { label: "Negative Values", value: "negative-value" },
  { label: "Exponential Values", value: "exponential-value" },
  { label: "A known scammer", value: "known-scammer" },
  { label: "EFCC blacklist", value: "efcc-blacklist" },
  { label: "Multiple accounts", value: "multiple-accounts" },
  { label: "Hacked/Compromised account", value: "hacked-compromised-account" },
  { label: "Suspicious transactions", value: "suspicious-transactions" },
  { label: "Fraudulent report", value: "fraudulent-report" },
  { label: "External glitch report", value: "external-glitch-report" },
  { label: "Second beneficiary", value: "second-beneficiary" },
  { label: "Impersonation", value: "impersonation" },
  { label: "Custom", value: "custom" },
  { label: "", value: "" },
];

const isConst = <T>(value: T): T => value;

export const CURRENCY_MAPPER = {
  USD: isConst("$"),
  NGN: isConst("₦"), // Nigerian Naira
  GBP: isConst("£"),
  EUR: isConst("€"),
  GHS: isConst("₵"), // Ghanaian Cedi
  CAD: isConst("CA$"), // Canadian Dollar
  ZAR: isConst("R"), // South African Rand
  KES: isConst("KSh"), // Kenyan Shilling
} as const;

export const ALL_CURRENCY_LABEL = {
  USD: "USD",
  NGN: "NGN",
  GBP: "GBP",
  EUR: "EUR",
  GHS: "GHS",
  CAD: "CAD",
  ZAR: "ZAR",
  KES: "KES",
};

export type CURRENCY_MAPPER_KEYS = keyof typeof CURRENCY_MAPPER;

export const ALL_CURRENCIES = [
  { label: "USD", value: CURRENCY_MAPPER.USD },
  { label: "NGN", value: CURRENCY_MAPPER.NGN },
  { label: "GBP", value: CURRENCY_MAPPER.GBP },
  { label: "EUR", value: CURRENCY_MAPPER.EUR },
  { label: "GHS", value: CURRENCY_MAPPER.EUR },
  { label: "CAD", value: CURRENCY_MAPPER.CAD },
  { label: "ZAR", value: CURRENCY_MAPPER.ZAR },
  { label: "KES", value: CURRENCY_MAPPER.KES },
] as const;

export const SEARCH_DOES_NOT_EXIST = undefined;

export const AUTH_ERROR_STATUS = ["token_failure", "invalid_token", "Invalid token"];

export const AUTH_EXPIRED_CUSTOM_EVENT = "SESSION-EXPIRED";

export const LOGOUT_CUSTOM_EVENT = "LOG_OUT";

export const RENDER_DYNAMIC_MODAL_ID = "rvp-modal-portal";
export const PERSONAL_EXPORT_MODAL_ID = "personal-modal-export-wrapper";

export const GENERAL_CLASSES = {
  SCROLLABLE_MODAL: "scrollable-content-modal",
  NO_SHADOW: "no-shadow",
  BUGGIS_STATUS: "bugiss-status",
  DETAILS_GRID_ROW: "user-data__main-content",
  PERSONAL_TRANSFER_TABLE_WRAP: "personal-transfers__table-wrap",
  TABLE_WITH_DROPDOWN: "table-with-dropdown",
  TOOL_TIP_WRAPPER: "tooltip-hover-wrap",
  BIG_MODAL: "very-big-modal",
  NORMAL_BIG_MODAL: "normal-very-big-modal",
  INLINE_DETAILS_WRAPPER: "inline-details-wrapper__xay",
  SHIMMER: "redacted curved",
  TWIN: {
    WRAPPER: "twin_value",
    ONE: "twin_value__one",
    TWO: "twin_value__two",
    CUSTOM: {
      ONE: "custom_twin_value__one",
      COPY_EMAIL: "custom_twin_value__copy",
    },
  },
  OVERRIDE_COPY_STYLE: "override-table-copy",
  INLINE_DETAILS: {
    NO_SHADOW: "no-shadow",
    OVERRIDE: "inline-details-override-wrapper",
    WRAPPER: "inline-details-wrapper__xay",
  },
  ACTION_BAR_PRESENT: "page-has-action-bar",
  OUTLET: "is-outlet-tab",
  DETAILS_CHECKER_FORM: "DETAILS_CHECKER_FORM",
  FORM_GROUP_ERROR: "form-group-error",
  DROPDOWN_W_IMAGE: "SPECIAL_DROPDOWN_W_IMAGE",
  EXPAND_MODAL_BUTTON: "expand-modal-button",
} as const;

export const CUSTOM_FILTER_VALUE_FOR_ALL = "all";

export const NEVER_CACHE_TIME = 0;

export const FILTER_KEYS_TO_EXCLUDE_ALL_FROM = ["status", "direction"] as const;

export const SESSION_COOKIE_NAME = "token";

export const GENERAL_AUTH = {
  AUTH_ERROR_STATUS,
  AUTH_EXPIRED_CUSTOM_EVENT,
  LOGOUT_CUSTOM_EVENT,
} as const;

export const ALL_CUSTOM_EVENTS = {
  ...GENERAL_AUTH,
  LOGGED_DEPOSITS: "user-logged-a-deposits",
} as const;

export const GLOBAL_PER_PAGE = 100;

export const TRANSFER_EMPTY_SCREEN = {
  height: "70rem",
  className: "mt-20",
} as const;

export const SMART_FILTER = {
  HAS_NO_SEARCH: " ",
};

export const STRING_TRIM_LENGTH = 16;

export const LONG_TRIM_LENGTH = 30;

export const ACTION_BAR_ID = "action-bar-rendered-here";

export const SPECIAL_ROUTES = {
  DETAILS_CHECKER: "details-checker",
} as const;

export { PLACEHOLDER_IMAGE };
