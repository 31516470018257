import { cn } from "@/utils/colorConvert";
import { DetailsWrapper } from "../components/details-wrapper";
import { useGetDetailsChecker } from "../components/details-wrapper/context";
import InlineDetails from "@/components/common/inlineDetails";
import { GENERAL_CLASSES } from "@/constants";
import { formatWord } from "@/utils/formatWord";
import { Util } from "@/utils/utility";
import {
  DriversLicenseOrPassportForm,
  LICENSE_OR_PASSPORT_CTX,
} from "../components/drivers-license-passort-form";
import { capitalizeFirstLetter } from "@/utils/helper/Helper";

interface Props {
  ctx: LICENSE_OR_PASSPORT_CTX;
}

const JoinedContent = ({ ctx }: Props) => {
  const title = cn(capitalizeFirstLetter(ctx), "Checker");

  const { data } = useGetDetailsChecker<Record<string, any>>();

  const { img, image, photo, ...rest } = data ?? {};

  const content = Object.entries(rest).map(([key, value]) => ({
    label: formatWord(key),
    value: Util.safeText(value),
  }));

  return (
    <DetailsWrapper
      title={title}
      formElement={<DriversLicenseOrPassportForm ctx={ctx} />}
    >
      <div className="gtd-details-section">
        <InlineDetails
          simple
          className={cn(GENERAL_CLASSES.NO_SHADOW)}
          content={content}
        />
      </div>
    </DetailsWrapper>
  );
};

export const DriversLicenseDetailsChecker = () => <JoinedContent ctx="license" />;
export const PassportDetailsChecker = () => <JoinedContent ctx="passport" />;
