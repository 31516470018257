import Verve from "./verve.png";
import Master from "./master.png";
import Visa from "./visa.png";

export const CardAvatarIcons = {
  verve: Verve,
  master: Master,
  mastercard: Master,
  visa: Visa,
};

/**
 * Do not ship without getting all types
 */
export const assertCardType = (type: string) => {
  type CardIcon = keyof typeof CardAvatarIcons;
  if (type in CardAvatarIcons) return CardAvatarIcons[type as CardIcon];

  return Visa;
};
