import { getQueryVariable } from "@/utils/helpers";
import { useSingleUserState } from "../../context";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import {
  mapTransfersPagination,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { CombinedBillerTable } from "./combined-table";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { iife } from "@/utils/general";
import { PersonalAirtimeOrDataTable } from "~pb/__internals__/combined-tables/bills/airtime-or-data-table";
import { createStatusDropdownForBills } from "@/apps/personal/__internals__/combined-tables/bills/utils";
import { usePbBillerExport } from "@/apps/personal/__internals__/internal-hooks/bills";

type TabType = "airtime" | "data";

const AirtimeOrDataTable = ({ tab }: { tab: TabType }) => {
  const options = createStatusDropdownForBills(tab);
  const { email } = useSingleUserState();
  const { filterArgs, filterProps, pagination } = useSmartFilterCustomFilter([
    { label: "Status", key: "status", options },
  ]);
  const { data, isFetching, isLoading } = personalReduxAPI.useGetBothAirtimeAndDataQuery({
    page: pagination.currentPage,
    // phone_number: phone,
    per_page: GLOBAL_PER_PAGE,
    search: pagination.searchQuery,
    type: tab,
    start_date: pagination.filterValue.startDate,
    end_date: pagination.filterValue.endDate,
    status: filterArgs?.status as any,
    email: email,
  } as any);
  const { ExportModal, onExport } = usePbBillerExport(tab, email);

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />;

  const selectedBiller = iife(() => {
    if ("airtimeRecharges" in data) return data.airtimeRecharges;
    return data.dataRecharges;
  });

  return (
    <HeightWithMarginOffset>
      <SmartFilter
        {...SmartFilterProps}
        {...filterProps}
        searchQuery={pagination.searchQuery}
        onExport={onExport}
      />
      {selectedBiller?.length > 0 ? (
        <PersonalAirtimeOrDataTable
          redactedFn={redactedFn}
          ctx="user"
          data={selectedBiller}
        />
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};

export const UsersBills = () => {
  const selectedTab = getQueryVariable("type");

  // Using key to enforce it rerenders.
  const renderSelection = () => {
    switch (selectedTab) {
      case "airtime":
      case "data":
        return <AirtimeOrDataTable tab={selectedTab} key={selectedTab} />;
      case "electricity":
      case "cable":
      case "betting":
        return <CombinedBillerTable tab={selectedTab} key={selectedTab} />;
      default:
        return <AirtimeOrDataTable tab="airtime" key={selectedTab} />;
    }
  };

  return renderSelection();
};
