class EventWorker extends EventTarget {
  private handledEvents: Set<string>;

  constructor() {
    super();
    this.handledEvents = new Set();
    this.setupClearInterval();
  }

  emit(type: string, data?: any): void {
    this.dispatchEvent(new CustomEvent(type, { detail: data }));
  }

  subscribe(key: string, callback: (event: CustomEvent) => void): void {
    const eventHandler: any = (e: CustomEvent) => {
      if (!this.handledEvents.has(key)) {
        this.handledEvents.add(key);
        callback(e);
      }
    };

    this.addEventListener(key, eventHandler);
  }

  private setupClearInterval(): void {
    setInterval(() => {
      this.handledEvents.clear();
    }, 60000); // Clear every 60 seconds, adjust interval as needed
  }
}

export const $event = new EventWorker();

export default EventWorker;
