import { DashboardInfoCard } from "@/components/common/dashboardInfo";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatWord, replaceSymbolInText } from "@/utils/formatWord";
import { Util } from "@/utils/utility";

export const PersonalInsightsSavingsPage = () => {
  const { data, isLoading } = personalReduxAPI.useGetSavingsAnalyticsForAPeriodQuery({
    start_date: "2023-01-01",
    end_date: "2024-04-19",
  });

  if (isLoading || !data) return <TransferEmptyScreen />;

  const mappedAnalytics = {
    withdrawal_fees: data.withdrawal_fees,
    ...data.savings_data,
  };

  return (
    <div>
      <DashboardInfoCard.Wrapper>
        {Object.entries(mappedAnalytics).map(([key, value]) => (
          <DashboardInfoCard
            key={key}
            title={formatWord(Util.safeText(key))}
            value={replaceSymbolInText(Util.safeText(value))}
          />
        ))}
      </DashboardInfoCard.Wrapper>
    </div>
  );
};
