import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";

export const getPbDepositsStatus = (status: number) => {
  switch (status) {
    case 1:
      return "Credited";
    case 3:
      return "Pending Crediting";
    case 10:
      return "Delayed Crediting";
    default:
      return "failed";
  }
};

export const PERSONAL_DEPOSIT_STATUS = [
  { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
  { label: "Credited", value: 1 },
  { label: "Pending", value: 3 },
  { label: "Delayed", value: 10 },
];

export const getPbUnapprovedDepositsStatus = (status: string) => {
  switch (status) {
    case "approved":
    case "pending":
      return status;
    case "approval_failed":
      return "failed";
    case "approval_processing":
      return "--processing--";
    default:
      return "pending";
  }
};
