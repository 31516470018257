import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { BusinessAPI } from "@/utils/axios/business";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toggleRedact } from "@/redux/actions/actions";
import { toast } from "@ravenpay/raven-bank-ui";
import { Util } from "@/utils/utility";

export const fetchInvoice = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_invoice",
  async (payload, thunkAPI) => {
    try {
      const {
        range,
        business_email,
        period_type,
        search_term,
        per_page,
        current_page,
        date_1,
        date_2,
        paginate,
      } = payload;

      const queryParams = {
        range: range ?? "3",
        business_email: business_email ?? "",
        period_type: period_type ?? "",
        search_term: search_term ?? "",
        per_page: per_page ?? 20,
        current_page: current_page ?? "",
        date_1: date_1 ?? "",
        date_2: date_2 ?? "",
        paginate: payload.paginate,
      };

      const data = await axios.get(
        "invoices/get_filter_or_search_invoice_with_businesses",
        {
          params: queryParams,
        }
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        if (Util.isNotExportReq(paginate)) {
          thunkAPI.dispatch(toggleRedact(false));
          thunkAPI.dispatch(SET_INVOICES(data.data.data?.invoice_records));
        }

        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchSingleInvoice = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_an_invoice_with_reference",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `invoices/fetch_an_invoice_with_reference?range=${
          payload?.range ?? "3"
        }&reference=${payload?.reference ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}`
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_INVOICE_DETAILS(data.data.data));
        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const deleteInvoice = createAsyncThunk<any, { id: string; business_id: string }>(
  "/delete_invoice",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`invoices/delete_invoice`, {
        id: payload.id,
        business_id: payload.business_id,
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        thunkAPI.dispatch(toggleRedact(false));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const downloadInvoice = createAsyncThunk<
  any,
  Partial<PaginationQuery & { invoice_id: string }>
>("/download_an_invoice", async (payload, thunkAPI) => {
  try {
    const data = await axios.get(`invoices/download_an_invoice`, {
      params: {
        business_id: payload.business_id,
        invoice_id: payload.invoice_id,
      },
    });

    if (data?.data?.status === "fail") {
      thunkAPI.dispatch(toggleRedact(false));
      toast.error(data?.data?.message, {
        position: "top-right",
      });

      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      // toast.success(data?.data?.message, {
      //   position: "top-right",
      // });

      thunkAPI.dispatch(toggleRedact(false));
      return data.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});
const initialState: any = {
  loading: false,
  loadingSingle: false,
  business_invoices: [],
  invoice_details: [],
};

export const businessInvoiceSlice = createSlice({
  name: "business-compliance",
  initialState,
  reducers: {
    SET_INVOICES: (state, action) => {
      state.business_invoices = action.payload;
    },
    SET_INVOICE_DETAILS: (state, action) => {
      state.invoice_details = action.payload;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(fetchInvoice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingleInvoice.pending, (state) => {
      state.loadingSingle = true;
    });

    //fullfilled state changes

    builder.addCase(fetchInvoice.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSingleInvoice.fulfilled, (state) => {
      state.loadingSingle = false;
    });

    //++++++ rejected state changes ++++++

    builder.addCase(fetchInvoice.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchSingleInvoice.rejected, (state) => {
      state.loadingSingle = false;
    });

    // Modern approach using reduxBuilder

    const builderHandler = new ReduxBuilderHandler(builder, initialState);
    builderHandler.handleStates(deleteInvoice);
    builderHandler.handleStates(downloadInvoice);
  },
});

// Action creators are generated for each case reducer function
export const { SET_INVOICES, SET_INVOICE_DETAILS } = businessInvoiceSlice.actions;

export default businessInvoiceSlice.reducer;
