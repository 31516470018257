import { ClickAbleEmail } from "@/components/common/clickable-row";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, RavenToolTip } from "@ravenpay/raven-bank-ui";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import React, { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { GENERAL_CLASSES, STRING_TRIM_LENGTH } from "@/constants";
import { CreateTableRowProps } from "../shared";
import { PersonalTransactionModal } from "./modals/transaction-details-modal";

export const PersonalTransactionTable = (
  props: CreateTableRowProps<{ data: PersonalTransactionModel[] }>
) => {
  const [selectedRow, setSelectedRow] = useState<PersonalTransactionModel>();
  const { renderSafeModal } = useDynamicModal(selectedRow);
  const { RedactedLabel, RedactedAvatarWithValue, RedactedTable } = props.redactedFn;

  return (
    <RavenTable
      className="table__main"
      headerList={[
        "REFERENCE",
        "DESCRIPTION",
        "USER DETAILS",
        "BAL. BEFORE",
        "BAL. AFTER",
        "AMOUNT",
        "TRANSACTION DATE",
      ]}
    >
      {props.data.map((row) => {
        const {
          id,
          direction,
          reference,
          description,
          email,
          type,
          amount,
          created_at,
          b_after,
          b_before,
          currency,
        } = row;

        return (
          <RavenTableRow
            className={GENERAL_CLASSES.OVERRIDE_COPY_STYLE}
            key={id}
            one={RedactedAvatarWithValue(
              <Txreference
                reference={Util.safeValue(reference)}
                direction={direction}
                trim={true}
                trimLength={15}
                hideCopy={!Boolean(reference)}
              />
            )}
            two={RedactedLabel(
              <TwinValue
                className={GENERAL_CLASSES.TOOL_TIP_WRAPPER}
                one={type}
                two={TypeIs.any(
                  <>
                    <RavenToolTip color={"purple-dark"} position="top-center">
                      {React.cloneElement(<p>{Util.safeText(description)}</p>, {
                        style: { color: "white" },
                      })}
                    </RavenToolTip>

                    <p style={{ maxWidth: "220px" }}>
                      {trimLongString(
                        replaceSymbolInText(Util.safeText(description)),
                        25
                      )}
                    </p>
                  </>
                )}
              />
            )}
            three={RedactedTable(
              <ClickAbleEmail email={Util.safeText(email)} trim={STRING_TRIM_LENGTH} />
            )}
            four={RedactedTable(formatNumberToCurrency(b_before, currency))}
            five={RedactedTable(formatNumberToCurrency(b_after, currency))}
            six={RedactedTable(replaceSymbolInText(Util.safeText(amount)))}
            seven={RedactedTable(formatDateTime(created_at))}
            onRowClick={() => setSelectedRow(row)}
          />
        );
      })}

      {renderSafeModal(
        <PersonalTransactionModal
          transaction={TypeIs.safe(selectedRow)}
          onCancel={() => setSelectedRow(undefined)}
          ctx={props.ctx}
        />,
        selectedRow
      )}
    </RavenTable>
  );
};
