import { icons } from "@/assets/icons";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { ConditionalCopyText } from "@/components/common/copyCheck";
import { MainContentCol } from "@/components/common/mainContentCol";
import { EmptyShell, TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { ControlledTableContent, YStack } from "@/components/common/stacks";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { TRANSFER_EMPTY_SCREEN } from "@/constants";
import { useDynamicQueryParams } from "@/hooks/useDynamicQueryParams";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";

const headerList = ["Reference", "Amount", "Type", "Description", "Date"];

export const PersonalSingleSavingsPocketPage = () => {
  const { id } = useDynamicQueryParams({ keys: "id" });
  const { data, isFetching, isLoading } = personalReduxAPI.useGetPocketByIdQuery(id);

  const joinedLoader = isLoading || isFetching;

  const { RedactedLabel, RedactedTable } = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return (
      <EmptyShell pageTitle=" ">
        <TransferEmptyScreen loading={joinedLoader} />
      </EmptyShell>
    );

  const filteredSavings = data.transactions;

  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Pocket Savings Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap reusable-details-card-top">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img card-avatar-image personal-cards-page">
              {icons.savings_01}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div>
                <h4 className="savings-details-title">{data.name}</h4>
              </div>
              <div className="user-data__main-content">
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Savings Type">
                      <p>Pocket Savings</p>
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Amount">
                      <p>{Util.safeText(formatNumberToCurrency(data.balance))}</p>
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Date Created">
                      {data.created_at ? formatDateTime(data?.created_at) : "--"}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Status">
                      {
                        <div
                          className={cn(
                            "savings-details-status",
                            !data.deleted_at
                              ? "completed-savings-details"
                              : "in-progress-savings-details"
                          )}
                        >
                          {!data.deleted_at ? "Active" : "In Progress"}
                        </div>
                      }
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
              </div>
            </div>
          </div>
        </div>
        <div className="single-user-details__table-wrap">
          {data.transactions.length > 0 ? (
            <>
              <YStack>
                <SmartFilter
                  hideFilter
                  alternateTitle=" "
                  tableExport={{
                    data: data.transactions,
                    fileName: `Export for pocket savings`,
                  }}
                  pagination={{
                    currentPage: 1,
                    itemsPerPage: filteredSavings.length,
                    onPageChange: () => {},
                    totalItems: filteredSavings.length,
                  }}
                  hideExport
                />
              </YStack>
              <RavenTable headerList={headerList}>
                {filteredSavings.map((transaction) => {
                  const { amount, description, reference, type, created_at } =
                    transaction;

                  return (
                    <RavenTableRow
                      one={RedactedTable(<ConditionalCopyText text={reference} />)}
                      two={RedactedTable(replaceSymbolInText(amount ?? ""))}
                      three={RedactedTable(type)}
                      four={RedactedTable(
                        <ControlledTableContent maxWidth={300}>
                          {replaceSymbolInText(description)}
                        </ControlledTableContent>
                      )}
                      five={RedactedTable(formatDateTime(created_at))}
                    />
                  );
                })}
              </RavenTable>
            </>
          ) : (
            <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
