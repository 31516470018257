import { useProductColor } from "@/context/ProductTheme";
import { RavenButton } from "@ravenpay/raven-bank-ui";

type ButtonProps = Omit<React.ComponentProps<typeof RavenButton>, "color">;

export const Button = (props: ButtonProps) => {
  const { colorVariable } = useProductColor();

  return <RavenButton color={colorVariable} {...props} />;
};
