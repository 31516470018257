import { useSingleUserState } from "../../context";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import {
  mapTransfersPagination,
  usePaginationRefresh,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { PersonalJointBillersTable } from "~pb/__internals__/combined-tables/bills/joint-billers-table";
import { createStatusDropdownForBills } from "@/apps/personal/__internals__/combined-tables/bills/utils";
import { usePbBillerExport } from "@/apps/personal/__internals__/internal-hooks/bills";

type TabType = "betting" | "cable" | "electricity";

export const CombinedBillerTable = ({ tab }: { tab: TabType }) => {
  const { email } = useSingleUserState();
  const { filterArgs, filterProps, pagination } = useSmartFilterCustomFilter([
    { label: "Status", key: "status", options: createStatusDropdownForBills(tab) },
  ]);
  const { ExportModal, onExport } = usePbBillerExport(tab, email);
  const { data, isFetching, isLoading } =
    personalReduxAPI.useGetBettingCableElectricityQuery({
      page: pagination.currentPage,
      email,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      type: tab,
      start_date: pagination.filterValue.startDate,
      end_date: pagination.filterValue.endDate,
      status: filterArgs?.status as any,
    });

  usePaginationRefresh(pagination);

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return <TransferEmptyScreen loading={joinedLoader} {...TRANSFER_EMPTY_SCREEN} />;

  const selectedBiller = data.jointBillModel;

  return (
    <HeightWithMarginOffset>
      <SmartFilter {...SmartFilterProps} {...filterProps} onExport={onExport} />
      {selectedBiller.length > 0 ? (
        <PersonalJointBillersTable
          redactedFn={redactedFn}
          data={selectedBiller}
          ctx="user"
          tab={tab}
        />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} {...TRANSFER_EMPTY_SCREEN} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};
