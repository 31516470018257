import React from "react";
import AccountSummaryItem from ".";

// Define a type for individual data items
interface AccountSummaryDataItem {
  label: string;
  value: string;
  valueColor?: string;
}

// Define the props type for the component
interface AccountSummaryItemBlockProps {
  loading: boolean;
  data: AccountSummaryDataItem[];
}

const AccountSummaryItemBlock: React.FC<AccountSummaryItemBlockProps> = ({
  loading,
  data,
}) => {
  return (
    <div className="user-data__account-summary">
      {data.map((item, index) => (
        <AccountSummaryItem
          key={index}
          label={item.label}
          value={item.value}
          loading={loading}
          valueColor={item.valueColor}
        />
      ))}
    </div>
  );
};

export default AccountSummaryItemBlock;
