import "./style/index.css";

import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { cn } from "@/utils/colorConvert";
import { RavenDropDown } from "@ravenpay/raven-bank-ui";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { icons } from "@/assets/icons";
import { createStyleCSSProperty } from "@/utils/helpers";
import { iife } from "@/utils/general";

type RavenDropdownProps = ComponentProps<typeof RavenDropDown>;

type LIST = RavenDropdownProps["list"][number];

type CleanProps = Omit<RavenDropdownProps, "list" | "onAction">;

export interface Items extends Partial<RavenDropdownProps["list"][number]> {
  label: string;
  value?: string;
}

export type Defaults = Items | SN;

function assertListType(list: Defaults): list is Items {
  return typeof list === "object";
}

const convertSelectedValue = (arg?: Defaults): Items | undefined => {
  if (arg) return assertListType(arg) ? arg : { label: arg as string };
};

type Render<T> = { selected?: T };

export type DropdownItemType = CreateArrayDefaults<Defaults>;

type OnChange<T> = { event: SyntheticEvent } & T;

interface DropdownProps<T extends Defaults> extends CleanProps {
  items: CreateArrayDefaults<T>;
  value?: T;
  placeholder?: T;
  onChange?(value: T): void;
  defaultValue?: T;
  /**
   * the element to render as the child of the button/trigger
   * do not use a button element as the buttonChild to prevent nested buttons
   */
  buttonChild?(render: Render<Items>): React.ReactNode;
  hideCaretIcon?: true;
  /** Pass true to use default values. */
  rightPosition?: SN | true;
  disableTriggerButton?: boolean;
  onChangeWithEvent?: (value: OnChange<T>) => void;

  /** Closes the dropdown select if value is true */
  closeOnChange?: boolean;
}

const Dropdown = <T extends Defaults>(props: DropdownProps<T>) => {
  const {
    items,
    value,
    className,
    placeholder,
    onChange,
    defaultValue,
    buttonChild,
    hideCaretIcon,
    rightPosition,
    disableTriggerButton,
    onChangeWithEvent,
    closeOnChange,
    ...rest
  } = props;

  const [selected, setSelected] = useState<DropdownProps<T>["value"]>(
    defaultValue ?? value
  );

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [showMenu, setShowMenu] = useState(false);
  const ref = useOnClickOutside(() => setShowMenu(false));

  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (btnRef.current) {
      const { clientHeight, clientWidth } = btnRef.current;
      setDimensions({
        width: clientWidth,
        height: clientHeight,
      });
    }
  }, [btnRef]);

  const handleChange = (item: OnChange<T>) => {
    setSelected(item);
    setShowMenu(false);

    if (onChange) {
      onChange(item);
    }

    if (onChangeWithEvent) {
      onChangeWithEvent(item as OnChange<T>);
    }
  };

  const label = iife((): SN => {
    const Label = value ?? selected ?? defaultValue ?? placeholder ?? "Select...";

    if (assertListType(Label)) {
      return Label.label;
    }

    return Label;
  });

  const portalCSSStyle = createStyleCSSProperty(
    "--anchor-more-position",
    typeof rightPosition === "boolean" ? 2.2 : rightPosition ?? "20rem"
  );

  return (
    <div
      ref={ref}
      onClick={() => {
        !closeOnChange && setShowMenu(true);
      }}
      className={cn(
        "pb-dropdown",
        (rightPosition || disableTriggerButton) && "disable-trigger-padding",
        className
      )}
      style={
        {
          "--button-height": dimensions.height,
          "--button-width": dimensions.width,
        } as React.CSSProperties
      }
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((c) => !c);
        }}
        className="pb-dropdown__trigger"
        type="button"
        ref={btnRef}
      >
        {buttonChild ? (
          buttonChild({ selected: convertSelectedValue(selected) })
        ) : (
          <div className="pb-dropdown__trigger__content">
            <span>{label}</span> {!hideCaretIcon && <figure>{icons.chevron_down}</figure>}
          </div>
        )}
      </button>
      <div
        style={portalCSSStyle}
        className={cn(
          "pb-dropdown__portal",
          rightPosition && "pb-dropdown__portal--anchor__more",
          !showMenu ? "pb-dropdown__portal--closed" : "pb-dropdown__portal--open"
        )}
      >
        <RavenDropDown
          {...rest}
          className="pb-dropdown-content"
          list={items.map((item) => {
            if (assertListType(item)) {
              return item as LIST;
            }

            return { label: item } as LIST;
          })}
          style={props.style}
          onAction={handleChange}
        />
      </div>
    </div>
  );
};

export default Dropdown;
