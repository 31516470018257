import { getQueryVariable } from "@/utils/helpers";
import { PersonalSingleUserBankPage } from "./pages/bank-transfer";
import { PersonalSingleUserInternalPage } from "./pages/internal-transfer";

export const UsersTransfers = () => {
  const selectedTab = getQueryVariable("type");

  const renderSelected = () => {
    switch (selectedTab) {
      case "bank":
        return <PersonalSingleUserBankPage />;
      case "internal":
        return <PersonalSingleUserInternalPage />;
      default:
        return <PersonalSingleUserBankPage />;
    }
  };

  return renderSelected();
};
