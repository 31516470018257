import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import { OutletTab } from "@/components/common/OutletTab";

const tabElements = ["Airtime", "Data", "Electricity", "Cable TV", "Betting"];

const PageBaseURL = "/atlas-bills";

export const AtlasBills = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Kickbacks">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="airtime"
            ></OutletTab.Tabs>
            <div style={{ marginBottom: "-2.8rem" }}>
              <SmartFilter
                pagination={{
                  currentPage: 0,
                  itemsPerPage: 0,
                  totalItems: 0,
                  onPageChange: (page: number) => {
                    throw new Error("Function not implemented.");
                  },
                  activeFilter: "All Transactions",
                }}
              />
            </div>
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
