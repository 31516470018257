import { RavenTable } from "@ravenpay/raven-bank-ui";
import { BillsTable, ReversalTransfersTable } from "../tables/PosTables";
import { ReactElement } from "react";
import SmartFilter from "@/components/common/smartFilter";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { Util } from "@/utils/utility";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { formatDateTime } from "@/utils/helper/Helper";

const AirtimeHeader = [
  "BUSINESS DETAILS",
  "REFERENCE",
  "PROVIDER",
  "AMOUNT",
  "MOBILE NUMBER",
  "DATE",
  "STATUS",
];
export const PosReversalBillsAirtimePage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillData>("getAirtimeRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.AirtimeData key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

export const PosReversalBillsDataPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillData>("getDataRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.AirtimeData key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

export const PosReversalBillsElectricityPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillElectricity>("getElectricityRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "AMOUNT",
          "METER NUMBER",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Electricity key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

export const PosReversalBillsCablePage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillCable>("getCableRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "CABLE PLAN",
          "AMOUNT",
          "CARD NUMBER",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Cable key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

export const PosReversalBillsBettingPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillBet>("getBettingRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "AMOUNT",
          "USER ID",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Betting
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            loading={combinedLoading}
          />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

export const PosReversalTransferPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosBillBet>("getBettingRecords");
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "REFERENCE",
          "RECIPIENT",
          "AMOUNT",
          "TRANSACTION DATE",
          "ACTION",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <ReversalTransfersTable
            key={idx}
            id={0}
            email={""}
            amount={""}
            account_number={""}
            account_name={""}
            bank={""}
            status={""}
            primary_reference={""}
            reference={""}
            settlement_type={""}
            meta_data={null}
            marked_by={null}
            created_at={`${new Date()}` as any}
          />
        ))}
      </RavenTable>
    ),
    SmartFilterProps
  );
};

function renderWithSmartFilter(node: ReactElement, SmartFilterProps: any): ReactElement {
  return (
    <>
      <SmartFilter {...SmartFilterProps} />
      {node}
    </>
  );
}
