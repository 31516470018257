import React from "react";
import SearchBar from "./searchBar";
import DisputeItem from "./disputeItem";
import SmartFilter from "@/components/common/smartFilter";

const DisputesList: React.FC = () => {
  return (
    <div className="bg-disputes__list">
      <SmartFilter hideExport simple />
      <div className="bg-disputes__items">
        <DisputeItem active={true} />
        <DisputeItem />
        <DisputeItem />
        <DisputeItem />
        <DisputeItem />
      </div>
    </div>
  );
};

export default DisputesList;
