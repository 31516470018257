import React from "react";
import "./style/index.css";
import { formatDate, formatDateTime } from "../../../utils/helper/Helper";
import { isEmptyObject } from "@/utils/helpers";
import { addDays } from "date-fns";
import { Util } from "@/utils/utility";
import { Row } from "../stacks";
import { icons } from "@/assets/icons";

export interface ActiveFilters {
  type: any[];
  days: string;
  startDate: string;
  endDate: string;
  filterValue: FilterValue[];
  filterBy: string;
}

export interface FilterValue {
  value: string;
  label: string;
}

export interface FilterChipProps {
  onClear?: (e?: any) => void;
  onDelete?:(e?:any) =>void;
  className?: string;
  activeFilter?: ActiveFilters;
  isDefaultDate?:boolean
  dateFilter?: {
    to: any;
    from: any;
  };
}

const FilterChip: React.FC<FilterChipProps> = (props) => {
  const { className, onClear, activeFilter, dateFilter, isDefaultDate, onDelete } = props;

  const defaultDate = {
    from: "2022-06-01",
    to: formatDate(addDays(new Date(), 1)),
  };

  const isTriggered =
    formatDate(new Date(dateFilter?.from)) !== defaultDate.from &&
    formatDate(new Date(dateFilter?.to)) !== defaultDate.to;

  return (
    <div
      className={`main__active-filters ${
        (activeFilter ||
          (dateFilter && typeof dateFilter.to === "object") ||
          (dateFilter && typeof dateFilter.from === "object")) &&
        "main__active-filters--show"
      } ${className}`}
    >
      <Row>
        {!isTriggered && !isDefaultDate && (
          <span className="active-filters__item">
            <p>
              <b>Date:</b>
            </p>
            {(dateFilter && typeof dateFilter.to === "object") ||
            (dateFilter && typeof dateFilter.from === "object")
              ? formatDate(dateFilter.from) + " - " + formatDate(dateFilter.to)
              : null}
            <figure onClick={() => onDelete && onDelete({...dateFilter, type: 'date'})}>{icons.x_circle}</figure>
          </span>
        )}

        {Array.isArray(activeFilter) &&
          activeFilter?.length > 0 &&
          Util.safeArray(activeFilter).map((item) => {
            return (
              <span className="active-filters__item">
                <p>
                  <b>Others:</b>
                </p>
                {item.label}
                <figure onClick={() => onDelete && onDelete({...item, type: 'others'})}>{icons.x_circle}</figure>
              </span>
            );
          })}
      </Row>
      
      <span
        onClick={() => {
          onClear &&
            onClear({
              to: "",
              from: "",
            });
          onClear && onClear("");
        }}
        className="active-filters__clear-item"
      >
        <p>Clear All</p>
      </span>
    </div>
  );
};

export default FilterChip;
