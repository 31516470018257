/* eslint-disable jsx-a11y/anchor-is-valid */
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { usePagination } from "@/hooks/usePagination";
import {
  mapTransfersPagination,
  usePaginationRefresh,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatWord } from "@/utils/formatWord";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import {
  RejectManualAddressVerification,
  AcceptManualAddressVerification,
} from "../components/modals";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { ApproveManualAddress } from "./modals/approve-manual-address";
import { GLOBAL_PER_PAGE } from "@/constants";
import { ControlledTableContent } from "@/components/common/stacks";

interface ComplianceNINOrBVNProps {
  tab: string;
}

export const ManualAddressVerification = (props: ComplianceNINOrBVNProps) => {
  const { tab } = props;
  const [view, setView] = useState<PersonalAddress>();
  const [acceptModal, setAccept] = useState<SN>();
  const [revokeModal, showRevokeModal] = useState<SN>();
  const { renderDynamicModal } = useDynamicModal(Boolean(revokeModal));
  const { renderDynamicModal: renderView } = useDynamicModal(Boolean(view));
  const { renderDynamicModal: renderAcceptModal } = useDynamicModal(Boolean(acceptModal));

  const dropdown = [
    {
      label: `Revoke ${formatWord(tab)}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
    {
      label: `Verify ${formatWord(tab)}`,
      img: IconVault(icons.check_circle_green),
      func: setAccept,
    },
  ];

  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetManualAddressQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable, RedactedLabel } = renderRedactedComponent(joinedLoader);

  usePaginationRefresh(pagination, [tab]);

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  const handleVerification = () => {
    if (view?.verification_status === "pending_verification") {
      setAccept(view.id);
      setView(undefined);
    }
    return setView(undefined);
  };

  return (
    <>
      <SmartFilter searchTitle="Search by email" {...SmartFilterProps} />

      {data.addresses?.length > 0 ? (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "Email",
              "State",
              "Full Address",
              "Landmarks",
              "Date",
              "Status",
              " ",
            ]}
          >
            {data.addresses.map((row) => {
              const { id } = row;

              return (
                <RavenTableRow
                  key={id}
                  onRowClick={() => setView(row)}
                  {...{
                    one: RedactedTable(<ClickAbleEmail email={row.email} />),
                    two: RedactedTable(
                      <ControlledTableContent>
                        <div style={{ minWidth: "10rem" }}>
                          <TwinValue
                            two={Util.safeText(row.lga)}
                            one={Util.safeText(row.state)}
                          />
                        </div>
                      </ControlledTableContent>
                    ),
                    three: RedactedTable(
                      <ControlledTableContent>
                        <p>{Util.safeText(row.home_address)}</p>
                      </ControlledTableContent>
                    ),
                    four: RedactedTable(
                      <ControlledTableContent>
                        <p>{Util.safeText(row.landmark)}</p>
                      </ControlledTableContent>
                    ),
                    five: RedactedLabel(formatDateTime(row.created_at)),
                    six: RedactedTable(
                      row.verification_status
                        ? formatStatus(row.verification_status)
                        : "--"
                    ),
                    seven: RedactedTable(
                      <div>
                        <Dropdown
                          items={dropdown}
                          defaultValue={dropdown[0]}
                          onChange={(e: any) => {
                            e.event.stopPropagation();
                            e.func(id);
                          }}
                          className="ragna-dropdown"
                          disableTriggerButton
                          rightPosition
                          buttonChild={({ selected }) => <ActionDrop more />}
                        />
                      </div>
                    ),
                  }}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}

      {revokeModal &&
        renderDynamicModal(
          <RejectManualAddressVerification
            id={revokeModal}
            refetch={refetch}
            onCancel={() => showRevokeModal(undefined)}
          />
        )}
      {acceptModal &&
        renderAcceptModal(
          <AcceptManualAddressVerification
            id={acceptModal}
            refetch={refetch}
            onCancel={() => setAccept(undefined)}
          />
        )}

      {view &&
        renderView(
          <ApproveManualAddress
            view={view}
            setView={setView}
            handleVerification={handleVerification}
            showRevokeModal={showRevokeModal}
            tab={tab}
          />
        )}
    </>
  );
};
