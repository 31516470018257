import { formatStatus } from "@/utils/helper/Helper";
import React from "react";

const DisputeItem: React.FC<{ active?: boolean }> = ({ active }) => {
  return (
    <div className={`bg-disputes__item ${active ? "bg-disputes__item--active" : ""}`}>
      <div className="bg-disputes__item-header">
        <span className="bg-disputes__item-id">#2398819</span>
        <span className="bg-disputes__item-status">{formatStatus("In Review")}</span>
        <button className="bg-disputes__item-more">•••</button>
      </div>
      <div className="bg-disputes__item-details">
        <div className="bg-disputes__item-field">
          <span className="bg-disputes__item-label">Category:</span>
          <span className="bg-disputes__item-value">
            Transfer failed but no refund yet
          </span>
        </div>
        <div className="bg-disputes__item-field">
          <span className="bg-disputes__item-label">Amount:</span>
          <span className="bg-disputes__item-value">NGN 8500</span>
        </div>
        <div className="bg-disputes__item-field">
          <span className="bg-disputes__item-label">Type:</span>
          <span className="bg-disputes__item-value">Bank Transfer</span>
        </div>
        <div className="bg-disputes__item-field">
          <span className="bg-disputes__item-label">Date:</span>
          <span className="bg-disputes__item-value">12,May 2024, 08:24PM</span>
        </div>
      </div>
    </div>
  );
};

export default DisputeItem;
