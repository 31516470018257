import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenDateTime, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Avatar from "@/components/common/avatar";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import usePosGetQuery from "../../__internals__/hooks/usePosGetQuery";
import { CopyText } from "@/components/common/copyCheck";

const PosHniManagers = () => {
  const { isDarkMode } = useThemeMode();

  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosHNIManager>("getManagers");

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Managers">
        <SmartFilter {...SmartFilterProps} searchTitle="Search managers..." />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {(!isRedacting && combinedLoading) || tableData?.length === 0 || !tableData ? (
            <EmptyScreen loading={combinedLoading} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MANAGERS NAME",
                "EMAIL ADDRESS",
                "BUSINESS MANAGED",
                "DATE ADDED",
                "",
              ]}
            >
              {tableData?.map((chi, idx) => (
                <RavenTableRow
                  key={idx}
                  one={
                    isRedacting ? (
                      <Redacted.AvatarWithValue />
                    ) : (
                      <Avatar name={Util.safeValue(chi?.staff_name)} full />
                    )
                  }
                  two={
                    isRedacting ? <Redacted.Table /> : Util.safeValue(chi?.business_email)
                  }
                  three={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <CopyText text={chi?.staff_email} />
                    )
                  }
                  four={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      RavenDateTime({ date: chi?.created_at })
                    )
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PosHniManagers;
