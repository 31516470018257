import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenDateTime, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Avatar from "@/components/common/avatar";
import { businessReduxAPI } from "@/redux/slices/business/api";
import EmptyScreen from "@/components/common/emptyScreen";
import { useSelector } from "react-redux";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";

const BusinessHniManagers = () => {
  const { isDarkMode } = useThemeMode();

  const { data, isFetching, isLoading } = businessReduxAPI.useGetOperationStaffsQuery({});
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const loading = isFetching || isLoading;
  const ops = data?.operation_staffs;
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Managers">
        <SmartFilter searchTitle="Search managers..." />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {(!isRedacting && loading) || ops?.length === 0 || !ops ? (
            <EmptyScreen loading={loading} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MANAGERS NAME",
                "EMAIL ADDRESS",
                "BUSINESS MANAGED",
                "DATE ADDED",
                "",
              ]}
            >
              {ops?.map((chi, idx) => (
                <RavenTableRow
                  key={idx}
                  one={
                    isRedacting ? (
                      <Redacted.AvatarWithValue />
                    ) : (
                      <Avatar name={Util.join(chi?.fname).with(chi?.lname)} full />
                    )
                  }
                  two={isRedacting ? <Redacted.Table /> : Util.safeValue(chi?.email)}
                  three={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <TwinValue one={chi?.platforms?.length} two={chi?.email} />
                    )
                  }
                  four={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      RavenDateTime({ date: chi?.created_at })
                    )
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default BusinessHniManagers;
