import BusinessCompliance from "@/apps/business/compliance";
import { ComplianceDetailsPage } from "@/apps/business/compliance/details-checker";
import {
  BVNDetailsChecker,
  DriversLicenseDetailsChecker,
  NINDetailsChecker,
  PassportDetailsChecker,
  VotersCardDetailsChecker,
} from "@/apps/business/compliance/details-checker/pages";
import {
  CableInfoDetailsChecker,
  MeterNumberDetailsChecker,
} from "@/apps/business/compliance/details-checker/pages/cable-info";
import {
  BusinessAddressVerification,
  BusinessBvnCompliancePage,
  BusinessCacCompliancePage,
  BusinessInfoCompliancePage,
  BusinessNINCompliancePage,
} from "@/apps/business/compliance/pages";
// import BNinVerification from "@/apps/business/compliance/pages/nin_verification";

export const business_compliance_routes: RouteArray[] = [
  {
    path: "/business-compliance",
    element: BusinessCompliance,
    children: [
      { element: BusinessBvnCompliancePage, path: "" },
      { element: BusinessAddressVerification, path: "address" },
      { element: BusinessBvnCompliancePage, path: "bvn" },
      { element: BusinessNINCompliancePage, path: "nin" },
      { element: BusinessInfoCompliancePage, path: "business_info" },
      { element: BusinessCacCompliancePage, path: "cac" },

      {
        element: ComplianceDetailsPage,
        path: "details-checker",
        children: [
          { element: NINDetailsChecker, path: "" },
          { element: NINDetailsChecker, path: "nin" },
          { element: BVNDetailsChecker, path: "bvn" },
          { element: PassportDetailsChecker, path: "passport" },
          { element: DriversLicenseDetailsChecker, path: "drivers-license" },
          { element: VotersCardDetailsChecker, path: "voters-card" },
          { element: CableInfoDetailsChecker, path: "cable-info" },
          { element: MeterNumberDetailsChecker, path: "meter-info" },
        ],
      },
    ],
  },
];
