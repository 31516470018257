import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/general";
import { toast } from "@ravenpay/raven-bank-ui";
import { getCookie, setCookie } from "@/utils/helper/Helper";
import { SESSION_COOKIE_NAME } from "@/constants";

export const authenticate = createAsyncThunk<any, AuthPayload>(
  "/authenticate",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/authenticate", payload);

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        LOGIN(data?.data?.data?.token);
        setCookie(SESSION_COOKIE_NAME, data?.data?.data?.token, 344444);
        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  "/web/logout",
  async (payload: any, thunkAPI) => {
    try {
      const { data } = await axios.post("/web/logout");

      if (data?.status === "fail") {
        if (typeof data.data === "string") {
          toast.error(data.data);
        } else
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        return data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response?.data?.status === "fail" && error.response?.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getUser = createAsyncThunk<any, any>(
  "/get-user",
  async (payload, thunkAPI) => {
    try {
      const token = getCookie("token");

      const data = await axios.get("/get_support", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          // toast.error(data?.data.data, {
          //   position: "top-right",
          // });
        } else {
          // toast.error(data?.data.message, {
          //   position: "top-right",
          // });
        }
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        return data?.data;
      }
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: any = {
  loading: false,
  logging_out: false,
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    LOGIN: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    SET_TOKEN: (state, action) => {
      state.token = action.payload;
    },
    SET_USER: (state, action) => {
      state.user = action.payload;
    },
    LOGOUT: (state, action) => {
      state.logging_out = action.payload;
      state.isAuth = true;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(authenticate.pending, (state) => {
      state.loading = true;
    });

    //fullfilled state changes
    builder.addCase(authenticate.fulfilled, (state) => {
      state.loading = false;
    });

    //rejected state changes
    builder.addCase(authenticate.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { LOGIN, LOGOUT, SET_TOKEN, SET_USER } = authSlice.actions;

export default authSlice.reducer;
