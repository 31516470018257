import { BusinessAPI } from "@/utils/axios/business";
import { APIResponseHandler } from "@/utils/api";
import { BusinessBuilder } from "./api";

export const BusinessGlobalAccountAPI = (builder: BusinessBuilder) => {
  return {
    getGlobalAccounts: builder.query<
      BusinessGlobalAccount,
      Partial<PaginationQuery & { currency: string }>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
          currency,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          currency,
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/get_or_filter_global_accounts",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    getSingleGlobalAccounts: builder.query<
      {
        global_account: GlobalAccount;
        business: Business;
        transaction_summary: {
          summation: number;
          count: number;
        };
      },
      Partial<{ business_id: string; global_account_id: string }>
    >({
      queryFn: async (payload) => {
        const { global_account_id, business_id } = payload;
        const params = {
          global_account_id,
          business_id,
        };
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/fetch_single_global_account",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({ response, hideSuccessToast: true });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    getGlobalAccountsTrx: builder.query<
      {
        transactions: BusinessTransactionModel;
      },
      { business_id: string; currency: string } & Partial<PaginationQuery>
    >({
      queryFn: async (payload, thunk) => {
        const {
          currency,
          business_id,
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
        } = payload;
        const params = {
          currency,
          business_id,
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",

          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/global_account_transactions",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    getCurrencies: builder.query<{ all_support_currencies: Currencies[] }, any>({
      queryFn: async (_, thunk) => {
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/fetch_available_currencies",
            _
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    fetchGlobalAccLien: builder.query<
      { account_liens: GlobalAccountLiensModel },
      Partial<PaginationQuery & { currency: string }>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_id,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
          currency,
          status,
        } = payload;
        const params = {
          business_id,
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          currency,
          status,
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "/global_accounts_controller/global_account_liens_",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    getGlobalAccountsTransfers: builder.query<
      {
        transfers: GlobalAccountTransfersModel;
      },
      Partial<PaginationQuery & { currency: string }>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
          currency,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          currency,
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/global_accounts_transfers",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    getGlobalAccountsDeposits: builder.query<
      {
        transactions: GlobalAccountDepositsModel;
      },
      Partial<PaginationQuery & { currency: string }>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
          currency,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          currency,
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "global_accounts_controller/global_accounts_deposits",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
