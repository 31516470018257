import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";

interface SelectedExpenseModalProps {
  onCancel: OnFunction;
  visible: boolean;
  transactions: BaseTransactionProps;
}

export interface BaseTransactionProps {
  amount: number;
  narration: IsUncertain<string>;
  fee: number;
  recipient: string;
  reference: string;
  session_id: string;
  created_at: string;
  currency: string;
  type: "internal" | "bank";
  username?: string;
  bank?: string;
  account_number?: string;
  status: SN;
}

export const SelectedTransactionModal = (props: SelectedExpenseModalProps) => {
  const { onCancel, visible, transactions } = props;

  const {
    amount,
    currency,
    created_at,
    fee,
    narration,
    recipient,
    reference,
    session_id,
    type,
    username,
    bank,
    account_number,
    status,
  } = transactions;

  const renderExtraField = () => {
    if (type === "internal")
      return [{ label: "Username", value: cleanWhiteSpace("@", username) }];

    return [
      { label: "Bank", value: Util.safeValue(bank) },
      { label: "Bank Account", value: Util.safeValue(account_number) },
    ];
  };

  const Content = [
    { label: "Amount", value: formatNumberToCurrency(amount, currency) },
    { label: "Narration", value: Util.safeValue(narration) },
    { label: "Fee", value: formatNumberToCurrency(fee, currency) },
    { label: "Recipient", value: Util.safeValue(recipient) },
    ...renderExtraField(),
    { label: "Reference", value: reference },
    { label: "Session ID", value: session_id },
    {
      label: "Status",
      value: formatStatus(detectStatus(Number(status), "transactions")) as any,
    },
    { label: "Date Logged", value: formatDateTime(created_at) },
  ];

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      onClick={() => {}}
      btnColor="#FF0F00"
      title="Transfer Details"
      hideCancel
      hideNormalButton
    >
      <div className="expense__details">
        <InlineDetails
          direction=""
          title={"Transaction Details"}
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={Content}
        />
      </div>
    </ActionModal>
  );
};
