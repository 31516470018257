import React from "react";
import "./style/index.css";
import { icons } from "../../../assets/icons";
import Skeleton from "react-loading-skeleton";
import { formatDateTime } from "../../../utils/helper/Helper";
import { cn } from "@/utils/colorConvert";
import { iife } from "@/utils/general";

export interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onFilterChange?: (e?: any) => void;
  className?: string;
  activeFilter?: string;
  dateFilter?: {
    to: string;
    from: string;
  };
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange,
    className,
    onFilterChange,
    activeFilter,
    dateFilter,
  } = props;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPages = () => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      const isActive = i === currentPage;

      pages.push(
        <div
          key={i}
          className={`pagination__page${isActive ? " pagination__page--active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </div>
      );
    }

    return pages;
  };

  const CurrentPage = iife(() => {
    if (currentPage > totalItems) return "0";

    return (currentPage - 1) * itemsPerPage + 1;
  });

  const TotalPage = String(Math.min(currentPage * itemsPerPage, totalItems));

  return (
    <div>
      <div
        className={`main__active-filters ${
          (activeFilter ||
            (dateFilter && typeof dateFilter.to === "object") ||
            (dateFilter && typeof dateFilter.from === "object")) &&
          "main__active-filters--show"
        }`}
      >
        <span className="active-filters__item">
          <p>
            {/* <b>Date:</b> */}
            {(dateFilter && typeof dateFilter.to === "object") ||
            (dateFilter && typeof dateFilter.from === "object")
              ? formatDateTime(dateFilter.from).split(",")[0] +
                " - " +
                formatDateTime(dateFilter.to).split(",")[0]
              : activeFilter}
          </p>
          {/* <figure>{icons.x_circle}</figure> */}
        </span>
        <span
          onClick={() => {
            onFilterChange &&
              onFilterChange({
                to: "",
                from: "",
              });
            onFilterChange && onFilterChange("");
          }}
          className="active-filters__clear-item"
          style={{ display: "none" }}
        >
          <p>Clear All</p>
        </span>
      </div>

      <div className={`pagination ${className}`}>
        <div className="pagination__controls">
          {typeof currentPage !== "number" && itemsPerPage ? (
            <></>
          ) : (
            <div className="pagination__page-count">
              {isNaN(totalItems) ? (
                <Skeleton width={80} height={20} />
              ) : (
                <>
                  <p>{cn(CurrentPage, "-", TotalPage, "of")}</p>
                  <p className="text-black">{totalItems ?? 0}</p>
                </>
              )}
            </div>
          )}
          <div
            className={`pagination__button${
              currentPage === 1 ? " pagination__button--disabled" : ""
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {icons.chevron_left}
          </div>
          <div
            // Updated pagination style to be disabled if currentPage is greater than or equal to total pages
            // If anything breaks, revert please.
            className={`pagination__button${
              currentPage >= totalPages ? " pagination__button--disabled" : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {icons.chevron_right}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
