import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useExportHandler } from "./useExportModal";
import { AppDispatch, useAppDispatch } from "@/redux/store";

type useExportProp = {
  filterValue: any;
  apiFunction?: (
    arg: Partial<PaginationQuery>
  ) => AsyncThunkAction<any, Partial<PaginationQuery>, any>;
  dispatchFunction?: (arg?: any) => any;
  setFilterValue: any;
  dataPath?: string;
  loading: boolean;
  data?: Record<any, any>;
  valuePair: {
    [x: string]: string | string[];
  };
  title: string;
};
const useIsolatedExport = ({
  apiFunction,
  dataPath,
  loading,
  valuePair,
  title,
  data,
  dispatchFunction,
}: useExportProp) => {
  const [isExporting, setIsExporting] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [exportData, setExportData] = useState<Record<any, any>>({});
  const splitPath = dataPath?.split(".");
  const [finalData, setFinalData] = useState(null);

  const { handleExport } = useExportHandler({
    setFilterValue,
    data: exportData.length > 0 ? exportData : data ?? (finalData as any),
    loading,
    valuePair,
    title,
  });

  const dispatch: any = useAppDispatch();

  if (apiFunction && dispatchFunction) {
    throw new Error("apiFunction & dispatchFunction cannot be used together");
  }

  if (apiFunction && data) {
    throw new Error("apiFunction & data prop cannot be used together");
  }

  const triggerExport = () => {
    if (dispatchFunction && !data) {
      throw new Error("data is required if dispatchFunction is being used");
    }

    setIsExporting(true);
  };

  const getExportData = async () => {
    if (apiFunction !== undefined) {
      const resp = await dispatch(
        apiFunction({
          ...filterValue,
          current_page: 1,
          per_page: 20,
          paginate: "no",
        })
      );
      let finalPath: any = resp?.payload?.data ?? resp?.payload;

      if (splitPath && splitPath?.length > 0) {
        // Iterate over each part of the splitPath array
        for (let i = 0; i < splitPath.length; i++) {
          const path = splitPath[i];

          // Check if the current part of the path exists in the object
          if (finalPath && typeof finalPath === "object" && path in finalPath) {
            // Update finalPath to the nested object
            finalPath = finalPath[path];

            // Check if it's the final iteration
            if (i === splitPath.length - 1) {
              // For example, if using React useState hook:
              setFinalData(finalPath);
            }
          } else {
            // If the current part of the path does not exist, break the loop
            finalPath = null;
            break;
          }
        }
      }

      if (finalData !== null) {
        setExportData(finalPath);
      }
    } else if (dispatchFunction !== undefined) {
      dispatchFunction();
      setExportData(data as any);
    }
  };

  useEffect(() => {
    if (isExporting) {
      getExportData();
    }
  }, [isExporting]);

  useEffect(() => {
    if (isExporting && finalData !== null && !data) {
      handleExport();
    }

    if (isExporting && !finalData !== null && data) {
      handleExport();
    }

    return () => {
      setIsExporting(false);
    };
  }, [exportData, finalData, data]);

  return triggerExport;
};

export default useIsolatedExport;
