import bankbox from "@/assets/images/bankbox-img.png";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { IconButton } from "@/components/common/icon-button";
import Image from "@/components/common/image/Image";
import { MainContentCol } from "@/components/common/mainContentCol";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import env from "@/env";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { sendRequest } from "@/utils/api-methods";
import { formatDate, formatStatus } from "@/utils/helper/Helper";
import { isEven } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { toast } from "@ravenpay/raven-bank-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "~/common/shared/single-user/index.css";
import "~/common/used-by-details/index.css";
import { useQueryLoadingState } from "../../__internals__/hooks/useQueryLoadingState";
import "../styles/index.css";
import AddAlertRecievers, { ViewAlertRecievers } from "./__modals__/alert-reciever";
import PosDetailsTable from "./table";
const status = ["successful", "pending", "failed"];

export const PosTerminalDetails = () => {
  const { id } = useParams();

  const res = posReduxAPI.useGetATerminalQuery({ id });
  const [modal, setModal] = useState<any>("");

  const [addRecieverForm, setAddRecieverForm] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const { data } = res;
  const skip = !data?.terminal?.tid;
  const alertRecievers = posReduxAPI.useGetAlertRecieversQuery(
    {
      tid: data?.terminal?.tid,
    },
    { skip },
  );

  const recievers = alertRecievers.data;

  const combinedLoading = useQueryLoadingState(res);
  const [isReqn, setIsReqn] = useState(false);

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/de_re_activate",
      {
        tid: modal.tid,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      },
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function addAlertRecievers() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/alert/detail/add",
      {
        tid: modal.tid,
        serial: data?.terminal?.serial,
        phone: addRecieverForm?.phone,
        fname: addRecieverForm?.name?.split(" ")[0],
        lname: addRecieverForm?.name?.split(" ")[1],
        contact_email: addRecieverForm?.email,
        whatsapp_enabled: "1",
        sms_enabled: "0",
        email_enabled: "0",
        poseidon_owner_email: data?.terminal?.poseidon_business_email,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      },
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function removeAlertRecievers() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/alert/detail/remove",
      {
        id: modal?.id,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      },
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  useEffect(() => {
    if (modal.alert) {
      alertRecievers.refetch();
    }
  }, [modal.alert]);
  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Terminal Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img product-single-details-page__avatar">
              <Image width={"100%"} height={"100%"} src={bankbox} />
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  <MainContentCol.Item title="Terminal ID">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.terminal?.tid)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Date Disbursed">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      formatDate(new Date(String(data?.terminal?.created_at)))
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Status">
                    <p className="terminal-status active">
                      {combinedLoading ? (
                        <Redacted.Table />
                      ) : (
                        <span className="ul">
                          {formatStatus(
                            Boolean(data?.terminal?.status) ? "active" : "deactivated",
                          )}
                        </span>
                      )}
                    </p>
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Terminal Label ">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.terminal?.tid_label)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Account Number">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.terminal?.account_number_generated)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Cash In">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.terminal?.card_injection)
                    )}
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Serial Number">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.terminal?.serial)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Account Name">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(
                        data?.terminal?.poseidon_business_name ??
                          data?.terminal?.business_name,
                      )
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Cash Out">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.totalVolume)
                    )}
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Volume">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.totalVolume)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Bank">
                    {combinedLoading ? <Redacted.Table /> : "Best Star"}
                  </MainContentCol.Item>
                </MainContentCol>
              </div>
              <div>
                <div className="top-wrap__actions">
                  <IconButton
                    onClick={() => {
                      setModal({
                        tid: data?.terminal?.tid,
                        disable: true,
                        label: Boolean(data?.terminal?.status) ? "Disable" : "Activate",
                      });
                    }}
                    borderColor="#FF0F00"
                    text="Disable Terminal"
                    icon="slash_circle_01"
                  />
                  <IconButton
                    borderColor="#EA872D"
                    text="View Alert Reciever"
                    icon="arrow_circle_broken"
                    onClick={() => {
                      setModal({
                        tid: data?.terminal?.tid,
                        alert: true,
                        label: Boolean(data?.terminal?.status) ? "Disable" : "Activate",
                      });
                    }}
                  />
                  <IconButton text="View Payment Fliers" icon="arrow_circle_broken" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PosDetailsTable email={data?.terminal?.email as string} />

        <ActionModal
          visible={Boolean(modal?.disable)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            handleActivateDeactivate();
          }}
          cancelText="No, Cancel"
          actionText={`Yes, Disable`}
          btnColor={"red"}
          body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
          title={`${modal?.label} Terminal?`}
        ></ActionModal>

        <ActionModal
          visible={Boolean(modal?.delete)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            removeAlertRecievers();
          }}
          cancelText="No, Cancel"
          actionText={`Yes, Remove`}
          btnColor={"red"}
          body={`Are you sure to delete this alert reciever? This action can't be undone`}
          title={`Remove Alert Reciever?`}
        ></ActionModal>

        <ActionModal
          visible={Boolean(modal?.add_alert)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            addAlertRecievers();
          }}
          hideCancel
          actionText={`Add Alert Reciever`}
          btnColor={"#EA872D"}
          body={`Alert recievers are contact where certain notifications concerning your transactions will be sent to`}
          title={`Add Alert Recievers`}
        >
          <p style={{ color: "#676767" }}>
            Alert recievers are contact where certain notifications concerning your
            transactions will be sent to
          </p>

          <AddAlertRecievers onChange={setAddRecieverForm} value={addRecieverForm} />
        </ActionModal>

        <ActionModal
          visible={Boolean(modal?.alert)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            setModal({
              tid: data?.terminal?.tid,
              add_alert: true,
              label: Boolean(data?.terminal?.status) ? "Disable" : "Activate",
            });
          }}
          hideCancel
          actionText={`Add Alert Reciever`}
          btnColor={"#EA872D"}
          body={`Alert recievers are contact where certain notifications concerning your transactions will be sent to`}
          title={`Add Alert Recievers`}
        >
          <p style={{ color: "#676767" }}>
            Here is a list of all your current alert recievers
          </p>
          <ViewAlertRecievers
            data={recievers}
            onDelete={(id: number) => setModal({ id, delete: true })}
          />
        </ActionModal>
      </PageLayout>
    </DashboardLayout>
  );
};
