import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, FakePaginationProps } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import { posIcons } from "@/assets/icons/posIcons";
import TwinValue from "@/components/common/twinValue";
import { cn } from "@/utils/colorConvert";
import { useNavigate } from "react-router-dom";
import { useQueryLoadingState } from "../__internals__/hooks/useQueryLoadingState";
import { useSelector } from "react-redux";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { usePagination } from "@/hooks/usePagination";
import { isEmpty } from "../__internals__/assertIfEmpty";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { sendRequest } from "@/utils/api-methods";

const DeviceRequestsTable = () => {
  const pagination = usePagination();

  const res = posReduxAPI.useGetTerminalRequestsQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
  });
  const { data } = res;

  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  const merchants = data?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const combinedLoading = useQueryLoadingState(res);

  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);

  const [modal, setModal] = useState({
    approve: "",
    dispatch: "",
  });
  async function handleStatus({ id, status }: { id: number; status: number }) {
    setBtnLoading(true);
    const resp = await sendRequest("terminal/request/update/status", {
      id,
      status,
    });

    if (resp) {
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
    }
  }

  return (
    <div>
      <SmartFilter {...SmartFilterProps} />

      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "TYPE",
            "DELIVERY",
            "REQUEST DETAILS",
            "STATUS",
            "DATE REQUESTED",
            "",
          ]}
        >
          {merchants?.map((data, idx) => (
            <RavenTableRow
              key={idx}
              one={isRedacting ? <Redacted.Table /> : Util.safeValue(data.email)}
              two={
                isRedacting ? (
                  <Redacted.Labelled />
                ) : (
                  <TwinValue
                    one={Util.safeValue(Util.capitalizeEachWord(data?.type))}
                    two={`Qty: ${Util.safeValue(data?.quantity)}`}
                  />
                )
              }
              three={Util.cleanText(Util.safeValue(data?.delivery_option))}
              four={
                isRedacting ? (
                  <Redacted.Labelled />
                ) : (
                  <TwinValue
                    one={Util.safeValue(Util.capitalizeEachWord(data?.purpose))}
                    two={`${Util.safeValue(data?.delivery_address)}, ${Util.safeValue(
                      data?.delivery_state,
                    )}`}
                  />
                )
              }
              five={<DeviceRequestStatus status={data?.status} />}
              six={formatDateTime(data?.created_at)}
              eight={
                <Dropdown
                  rightPosition
                  items={[
                    {
                      label: "Set to In Approved",
                      img: IconVault(icons.check_circle_green),
                    },
                    { label: "Set to Dispatched", img: IconVault(posIcons.dispatch_van) },
                    {
                      label: "View Details",
                      img: IconVault(posIcons.arrow_right_circular),
                    },
                  ]}
                  onChange={(e: any) => {
                    e.event.stopPropagation();
                    setModal({
                      dispatch: "",
                      approve: String(data?.id),
                    });

                    e.func({ chi: "", on: true });
                  }}
                  buttonChild={() => {
                    return <ActionDrop more />;
                  }}
                />
              }
            />
          ))}
        </RavenTable>
      )}

      <ActionModal
        title={"Set To Approved"}
        body="Are you sure to set this device request to approved? You can always come back to perform the action."
        visible={Boolean(modal.approve)}
        onCancel={function (): void {
          setModal({
            ...modal,
            approve: "",
          });
        }}
        cancelText="No Cancel"
        onClick={function (): void {
          handleStatus({
            status: 1,
            id: Number(modal.approve),
          });
        }}
      />
    </div>
  );
};

export const PosDeviceRequests = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Device Requests">{<DeviceRequestsTable />}</PageLayout>
    </DashboardLayout>
  );
};

interface RequestStatus {
  status: "approved" | "dispatched" | "pending";
}

const DeviceRequestStatus = ({ status }: { status: number }) => {
  return formatStatus(detectStatus(status));
};
