import { RavenInputField, RavenModal, toast } from "@ravenpay/raven-bank-ui";
import "./styles/index.css";
import { XStack, YStack } from "@/components/common/stacks";
import { formatDateToYYYYMMDDHHMMSS, reactSelectStyle } from "@/utils/helper/Helper";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { formatWord } from "@/utils/formatWord";
import React, { useState } from "react";
import { Validator } from "@/utils/validator";
import { BusinessAPI } from "@/utils/axios/business";
import { z } from "zod";
import { APIResponseHandler } from "@/utils/api";
import { useAppDispatch } from "@/redux/store";
import { BugissApi } from "@/redux/slices";
import { objectToFormData } from "@/utils/helpers";

interface LogExpenseModalProps {
  visible: boolean;
  onClose(): void;
}

type SelectField = { label: SN; value: SN };

interface FormState {
  expense_file?: File;
  category: SelectField;
  currency: SelectField;
  amount: SN;
  expense_date: string;
  description: string;
}

type Errors = Partial<Record<keyof FormState, string>>;

const schema = Validator.object({
  expense_file: Validator.file({ key: "Expense Receipt" }),
  category: Validator.select("Category"),
  currency: Validator.select("Currency"),
  amount: Validator.number("Amount"),

  // TODO: Look at this - might be broken
  expense_date: Validator.date("Expense"),
  description: Validator.string("Description", 1, 1000),
});

export const LogExpenseModal = ({ onClose, visible }: LogExpenseModalProps) => {
  const { data } = businessReduxAPI.useGetExpenseCategoriesQuery();
  const [formState, setFormState] = useState<Partial<FormState>>({});
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const categories = (() => {
    if (!data) return [];

    return (data?.expense_categories ?? []).map((expense: any) => ({
      label: formatWord(expense),
      value: expense,
    }));
  })();

  const handleChange = <T extends keyof FormState>(name: T, value: FormState[T]) => {
    setFormState((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleInputChange = (event: InputOnChange) => {
    const { name, value } = event.target as any;

    handleChange(name, value);
  };

  const fields = [
    {
      placeholder: "Select Category",
      label: "Category",
      type: "select",
      selectOption: categories,
      selectStyles: reactSelectStyle,
      name: "category" as const,
      onChange: (category: SelectField) => handleChange("category", category),
    },
    [
      {
        placeholder: "NGN",
        label: "Currency",
        type: "select",
        selectStyles: reactSelectStyle,
        selectOption: [{ label: "NGN", value: "NGN" }],
        name: "currency" as const,
        onChange: (currency: SelectField) => handleChange("currency", currency),
      },
      {
        placeholder: "6924",
        label: "Amount",
        type: "amount",
        name: "amount" as const,
        onChange: handleInputChange,
      },
    ],
    {
      placeholder: "Expense Date",
      label: "Select Date",
      type: "date",
      name: "expense_date" as const,
      onChange: (date: string[]) => handleChange("expense_date", date[0]),
    },
    {
      placeholder: "Enter Description",
      label: "Description",
      name: "description" as const,
      onChange: handleInputChange,
    },
    {
      placeholder: "6924",
      label: "Upload File",
      type: "upload",
      textColor: "black-light" as const,
      labelColor: "black-light" as const,
      name: "expense_file" as const,
      onChange: (file: File) => handleChange("expense_file", file),
      onRemoveFile: () => handleChange("expense_file", undefined),
    },
  ];

  const logExpense = async (data: z.infer<typeof schema>) => {
    setLoading(true);
    try {
      const { category, currency, expense_date, ...rest } = data;
      const payload = {
        ...rest,
        category: category.value,
        currency: currency.value,
        expense_date: formatDateToYYYYMMDDHHMMSS(expense_date),
      };
      const response = await BusinessAPI.post(
        "/expenses/add_an_expenses",
        objectToFormData(payload),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const success = APIResponseHandler.tryHandler({ response });
      if (success) {
        dispatch(
          BugissApi.business.getExpenses({
            current_page: 1,
            per_page: 20,
          })
        );
        onClose();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      const payload = schema.parse(formState);
      logExpense(payload);
    } catch (error) {
      const validationErrors = Validator.getErrorMessage(error, schema);

      if (validationErrors) {
        setErrors(validationErrors);

        if (validationErrors.expense_file) toast.error(validationErrors.expense_file);

        return;
      }

      return setErrors({});
    }
  };

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      btnColor="black-light"
      btnLabel="Log Expense"
      onBtnClick={handleFormSubmit}
      className="lem"
      loading={loading}
    >
      <h4 className="lem__title">Log an expense</h4>
      <form onSubmit={handleFormSubmit}>
        <YStack>
          {fields.map((field, id) => {
            return (
              <React.Fragment key={id}>
                {Array.isArray(field) ? (
                  <XStack className="lem__stack">
                    {field.map((inner, idx) => (
                      <RavenInputField
                        key={inner.name + idx}
                        color="black-light"
                        value={formState[inner.name]}
                        showError={Boolean(errors[inner.name])}
                        errorText={errors[inner.name]}
                        {...inner}
                      />
                    ))}
                  </XStack>
                ) : (
                  <RavenInputField
                    key={field.name + id}
                    color="black-light"
                    showError={Boolean(errors[field.name])}
                    errorText={errors[field.name]}
                    value={formState[field.name]}
                    {...field}
                  />
                )}
              </React.Fragment>
            );
          })}
          <XStack className="lem__xstack"></XStack>
        </YStack>
      </form>
    </RavenModal>
  );
};
