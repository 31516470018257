import { MerchantBills } from "./bills/components/pages";
import { MerchantPosCard } from "./cards";
import { MerchantPosBankCollections } from "./collections";
import { PosMerchantDeviceRequest } from "./device-requests";
import { PosMerchantSettlement } from "./settlement";
import { MerchantPosTerminals } from "./terminals";
import PosMerchantTransactions from "./transactions";

export function renderMerchantTable(tab: string, email?: string) {
  switch (tab) {
    case "Transactions":
      return <PosMerchantTransactions email={email} />;
    case "Airtime":
      return <MerchantBills.BillsAirtimePage email={email} />;
    case "Data":
      return <MerchantBills.BillsDataPage email={email} />;
    case "Betting":
      return <MerchantBills.BillsBettingPage email={email} />;
    case "Cable TV":
      return <MerchantBills.BillsCablePage email={email} />;
    case "Electricity":
      return <MerchantBills.BillsElectricityPage email={email} />;
    case "Card Collection":
      return <MerchantPosCard email={email} />;
    case "Bank Collection":
      return <MerchantPosBankCollections email={email} />;
    case "Terminals":
      return <MerchantPosTerminals email={email} />;
    case "Settlement":
      return <PosMerchantSettlement email={email} />;
    case "Device Requests":
      return <PosMerchantDeviceRequest email={email} />;
    default:
      return <PosMerchantTransactions email={email} />;
  }
}
