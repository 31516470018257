import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../shared";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import TwinValue from "@/components/common/twinValue";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { GENERAL_CLASSES, STRING_TRIM_LENGTH } from "@/constants";
import { ControlledTableContent } from "@/components/common/stacks";
import { cn } from "@/utils/colorConvert";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { getPbDepositsStatus } from "./utils";
import { useState } from "react";
import { DepositDetails } from "@/apps/personal/__internals__/combined-tables/deposits/modals/deposits-details";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { CopyText } from "@/components/common/copyCheck";
import { iife } from "@/utils/general";

type Props = CreateTableRowProps<{ data: PersonalDepositsModel[] }>;

const header = [
  "DESTINATION DETAILS",
  "Amount",
  "Credited Amount",
  "Reference",
  "Senders Details",
  "Date Deposited",
  "Status",
];

export const PersonalDepositsTable = (props: Props) => {
  const { ctx, data, redactedFn } = props;
  const [selectedRecord, setSelectedRecord] = useState<PersonalDepositsModel>();
  const { renderDynamicModal } = useDynamicModal(selectedRecord);

  const { RedactedTable, RedactedLabel } = redactedFn;

  const tableHeader = iife(() => {
    return ctx === "page" ? header : header.slice(1);
  });

  return (
    <RavenTable className="table__main" headerList={tableHeader}>
      {data.map((record) => {
        const { id, created_at, status, currency, amount, reference, credited_amount } =
          record;

        const { first_name, last_name, account_number, bank } = record.source;

        const RowOne =
          ctx === "page"
            ? RedactedTable(
                <TwinValue
                  className={cn(GENERAL_CLASSES.TWIN.CUSTOM.COPY_EMAIL)}
                  one={<ClickAbleEmail email={record.email} trim={STRING_TRIM_LENGTH} />}
                  two={Util.safeText(record.destination_account_number)}
                />
              )
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            two={RedactedTable(Util.safeText(formatNumberToCurrency(amount, currency)))}
            three={RedactedTable(
              Util.safeText(formatNumberToCurrency(credited_amount, currency))
            )}
            four={RedactedTable(
              Util.safeNode(
                <CopyText
                  text={Util.safeText(reference, STRING_TRIM_LENGTH)}
                  textToCopy={Util.safeText(reference)}
                />,
                reference
              )
            )}
            five={RedactedLabel(
              <TwinValue
                one={cn(first_name, last_name)}
                two={TypeIs.any(
                  <ControlledTableContent>
                    {cn(bank, "•", account_number)}
                  </ControlledTableContent>
                )}
              />
            )}
            six={RedactedTable(Util.safeText(formatDateTime(created_at)))}
            seven={RedactedTable(formatStatus(getPbDepositsStatus(status)))}
            onRowClick={() => setSelectedRecord(record)}
          />
        );
      })}

      {renderDynamicModal(
        <DepositDetails
          onCancel={() => setSelectedRecord(undefined)}
          record={TypeIs.safe(selectedRecord)}
        />
      )}
    </RavenTable>
  );
};
