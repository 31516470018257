import { RavenTable } from "@ravenpay/raven-bank-ui";
import { AirtimeDataProps, BillsTable } from "../tables/AtlasTables";

const airtimeData: AirtimeDataProps[] = [
  {
    name: "Rehkmansa",
    amount: "₦1,200",
    phoneNumber: "0701 -526 -3711",
    date: "5 Sept, 2022 • 5:48PM",
    email: "rehkmansa@gmail.com",
    provider: "glo",
    reference: "3049586758...",
    status: "successful",
  },
  {
    name: "Rehkmansa",
    amount: "₦1,200",
    phoneNumber: "0701 -526 -3711",
    date: "5 Sept, 2022 • 5:48PM",
    email: "rehkmansa@gmail.com",
    provider: "mtn",
    reference: "3049586758...",
    status: "failed",
  },
  {
    name: "Rehkmansa",
    amount: "₦1,200",
    phoneNumber: "0701 -526 -3711",
    date: "5 Sept, 2022 • 5:48PM",
    email: "rehkmansa@gmail.com",
    provider: "airtel",
    reference: "3049586758...",
    status: "pending",
  },
];

const AirtimeHeader = [
  "BUSINESS DETAILS",
  "REFERENCE",
  "PROVIDER",
  "AMOUNT",
  "MOBILE NUMBER",
  "DATE",
  "STATUS",
];
export const BillsAirtimePage = () => {
  return (
    <RavenTable headerList={AirtimeHeader}>
      {airtimeData.map((bill, idx) => (
        <BillsTable.AirtimeData key={idx} {...bill} />
      ))}
    </RavenTable>
  );
};

export const BillsDataPage = () => {
  return (
    <RavenTable headerList={AirtimeHeader}>
      {airtimeData.map((bill, idx) => (
        <BillsTable.AirtimeData key={idx} {...bill} />
      ))}
    </RavenTable>
  );
};

export const BillsElectricityPage = () => {
  const electricityData = airtimeData.map(({ provider, phoneNumber, ...data }) => ({
    ...data,
    meterNumber: "87695043948567",
  }));

  return (
    <RavenTable
      headerList={[
        "BUSINESS DETAILS",
        "PROVIDER",
        "AMOUNT",
        "METER NUMBER",
        "DATE",
        "STATUS",
      ]}
    >
      {electricityData.map((bill, idx) => (
        <BillsTable.Electricity provider="IKEDC" key={idx} {...bill} />
      ))}
    </RavenTable>
  );
};

export const BillsCablePage = () => {
  const cableData = airtimeData.map(({ provider, phoneNumber, ...data }) => ({
    ...data,
    cableNumber: "87695043948567",
    cablePlan: "DSTV Premium",
    provider: "DSTV",
  }));

  return (
    <RavenTable
      headerList={[
        "BUSINESS DETAILS",
        "PROVIDER",
        "CABLE PLAN",
        "AMOUNT",
        "CARD NUMBER",
        "DATE",
        "STATUS",
      ]}
    >
      {cableData.map((bill, idx) => (
        <BillsTable.Cable key={idx} {...bill} />
      ))}
    </RavenTable>
  );
};

export const BillsBettingPage = () => {
  const billers = ["Bet9ja", "1960Bet", "BetWay", "BetKing", "360Bet", "1xBet"];

  return (
    <RavenTable
      headerList={["BUSINESS DETAILS", "PROVIDER", "AMOUNT", "USER ID", "DATE", "STATUS"]}
    >
      {billers.map((biller, idx) => (
        <BillsTable.Betting
          key={biller}
          {...airtimeData[0]}
          provider={biller.toLowerCase()}
          userId="758 4932 875"
        />
      ))}
    </RavenTable>
  );
};
