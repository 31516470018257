import { SPECIAL_ROUTES } from "@/constants";
import "./style/index.css";

import { XStack, YStack } from "@/components/common/stacks";
import { useOutletURL } from "@/hooks/useOutletURL";
import { cn } from "@/utils/colorConvert";
import { NavLink, Outlet } from "react-router-dom";
import { iife } from "@/utils/general";
import { useGetDetailsChecker } from "./components/details-wrapper/context";

const tabs = [
  { label: "NIN Checker", value: "nin" },
  { label: "BVN Checker", value: "bvn" },
  { label: "Passport Checker", value: "passport" },
  { label: "Driver's License", value: "drivers-license" },
  { label: "Voter's Card", value: "voters-card" },
  { label: "Meter Info", value: "meter-info" },
  { label: "Cable Info", value: "cable-info" },
];

const activeClass = "global-details-checker__tab__link--active";

export const ComplianceDetailsPage = () => {
  const { setData } = useGetDetailsChecker();
  const { pageSlug } = useOutletURL({
    baseURL: "/business-compliance",
  });

  return (
    <XStack gap={0} className="global-details-checker">
      <YStack className="global-details-checker__tab hide-scrollbar">
        {tabs.map((tab) => {
          const isDefaultUrl = iife(() => {
            if (pageSlug === SPECIAL_ROUTES.DETAILS_CHECKER) {
              return tab.value === "nin";
            }
            return false;
          });

          const url = `/business-compliance/details-checker/${tab.value}`;

          return (
            <NavLink
              to={url}
              key={tab.value}
              className={({ isActive }) =>
                cn("global-details-checker__tab__link", {
                  [activeClass]: isActive || isDefaultUrl,
                })
              }
              onClick={() => {
                setData(undefined);
              }}
            >
              {tab.label}
            </NavLink>
          );
        })}
      </YStack>
      <Outlet />
    </XStack>
  );
};
