import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth/auth";
import thunk from "redux-thunk";
import thunkMiddleware from "redux-thunk";
import * as reduxThunk from "redux-thunk/extend-redux";
import generalReducer from "./general";
import themeReducer from "./theme";
import { businessStore } from "./slices/business/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { businessReduxAPI } from "./slices/business/api";
import { personalReduxAPI } from "./slices/personal/api";
import { posReduxAPI } from "./slices/pos/apis";

// create a makeStore function
const makeStore = () =>
  configureStore({
    reducer: {
      general: generalReducer,
      auth: authSlice.reducer,
      // Access the reducer generated by createSlice
      theme: themeReducer,
      ...businessStore,
      [businessReduxAPI.reducerPath]: businessReduxAPI.reducer,
      [personalReduxAPI.reducerPath]: personalReduxAPI.reducer,
      [posReduxAPI.reducerPath]: posReduxAPI.reducer,
    },
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        thunk,
        thunkMiddleware,
        businessReduxAPI.middleware,
        personalReduxAPI.middleware,
        posReduxAPI.middleware
      ),
  });

const store = makeStore();

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
