import "~/common/format-status/index.css";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { useProductTheme } from "@/context/ProductTheme";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { usePagination } from "@/hooks/usePagination";
import {
  mapAltPersonalPagination,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { PersonalAPIService } from "@/redux/slices/personal";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { APIResponseHandler } from "@/utils/api";
import { formatWord } from "@/utils/formatWord";
import { IconVault, formatDateTime } from "@/utils/helper/Helper";
import { getThemePrimaryColor } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { RejectIdVerification } from "../components/modals";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { IDVerificationModal } from "./modals/id-verification-modal";
import { GLOBAL_PER_PAGE } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { iife } from "@/utils/general";

const PENDING = "pending_verification";

interface ComplianceNINOrBVNProps {
  tab: string;
}

export const IdAddressVerification = (props: ComplianceNINOrBVNProps) => {
  const { tab } = props;
  const [view, setView] = useState<PersonalIdVerification>();
  const { productTheme } = useProductTheme();
  const [revokeModal, showRevokeModal] = useState<SN>();
  const [verifyModal, setVerifyModal] = useState<SN>();
  const { renderDynamicModal } = useDynamicModal(Boolean(revokeModal));
  const { renderDynamicModal: verifyDynamicModal } = useDynamicModal(
    Boolean(verifyModal)
  );
  const { renderDynamicModal: renderView } = useDynamicModal(Boolean(view));
  const [loading, setLoading] = useState(false);

  const dropdown = [
    {
      label: `Reject ${formatWord(tab)}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
    {
      label: `Verify ${formatWord(tab)}`,
      img: IconVault(icons.check_circle_green),
      func: setVerifyModal,
    },
  ];

  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetManualIdVerificationQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const { SmartFilterProps } = useSmartFilterProps(
    mapAltPersonalPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable, RedactedLabel } = renderRedactedComponent(joinedLoader);

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  const verifyUser = async (id?: SN) => {
    console.log(id, view, "verifyId");
    if (!id) return toast.error("User not selected");
    setLoading(true);
    try {
      const response = await PersonalAPIService.approveOrRejectId({
        action: "approve",
        id: id ?? view?.id,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        setView(undefined);
        setVerifyModal(undefined);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = () => {
    if (view?.verification_status === "pending_verification") return verifyUser(view.id);
    return setView(undefined);
  };

  return (
    <>
      <SmartFilter
        {...SmartFilterProps}
        searchTitle="Search by email"
        tableExport={{
          fileName: cn("Manual Id Table", new Date().toString()),
          data: data.verifications,
        }}
        hideExport
      />

      {data.verifications?.length > 0 ? (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={["Email", "Date", "Status", " "]}
          >
            {data.verifications.map((row) => {
              const { id } = row;

              return (
                <RavenTableRow
                  key={id}
                  onRowClick={() => setView(row)}
                  {...{
                    one: RedactedTable(
                      <ClickAbleEmail email={Util.safeText(row.email)} />
                    ),
                    two: RedactedLabel(formatDateTime(row.created_at)),
                    three: RedactedTable(
                      <IDAddressStatus status={row.verification_status} />
                    ),
                    four: RedactedTable(
                      <div>
                        {row.verification_status === PENDING ? (
                          <Dropdown
                            items={dropdown}
                            defaultValue={dropdown[0]}
                            onChange={(e: any) => {
                              e.event.stopPropagation();
                              e.func(id);
                            }}
                            className="ragna-dropdown"
                            disableTriggerButton
                            rightPosition
                            buttonChild={({ selected }) => <ActionDrop more />}
                          />
                        ) : null}
                      </div>
                    ),
                  }}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}

      {revokeModal &&
        renderDynamicModal(
          <RejectIdVerification
            id={revokeModal}
            onCancel={() => showRevokeModal(undefined)}
            refetch={refetch}
          />
        )}
      {verifyModal &&
        verifyDynamicModal(
          <ActionModal
            visible={Boolean(verifyModal)}
            onCancel={() => setVerifyModal(undefined)}
            onClick={() => verifyUser(verifyModal)}
            cancelText="No, Cancel"
            actionText="Yes, Verify"
            btnColor={getThemePrimaryColor(productTheme)}
            body="Are you sure you want to accept this user's ID verification."
            title={`Accept ${formatWord(tab)} ?`}
            loading={loading}
          />
        )}

      {view &&
        renderView(
          <IDVerificationModal
            handleVerification={handleVerification}
            setView={setView}
            view={view}
            showRevokeModal={showRevokeModal}
            tab={tab}
            loading={loading}
          />
        )}
    </>
  );
};

const IDAddressStatus = ({ status }: { status: string }) => {
  const s = iife(() => {
    if (status === PENDING) {
      return "pending approval";
    }
    return status;
  });

  return (
    <div
      className={cn("bugiss-status", s && s?.toLowerCase().replaceAll(" ", ""), {
        "deposit__status--processing": s === "--processing--",
      })}
    >
      <p>{s}</p>
    </div>
  );
};
