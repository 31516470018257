export const CARD_STATUS = {
  SUCCESFUL: "00",
};

export const TERMINAL_TYPE = {
  BANKBOX: "pdon_card_raven",
};

export const SETTLEMENT_STATUS = {
  PENDING: 0,
  FAILED: 2,
  SUCCESS: 3,
};
