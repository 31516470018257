export const getPersonalVirtualCardStatus = (status: SN) => {
  switch (status) {
    case 1:
    case "active":
      return "active";
    default:
      return "frozen";
  }
};

export enum PHYSICAL_CARDS_STATUS {
  ACTIVE = "active",
}

// TODO: confirm physical card status
export const getPBPhysicalCardsStatus = (status: SN) => {
  switch (status) {
    case 1:
    case PHYSICAL_CARDS_STATUS.ACTIVE:
      return PHYSICAL_CARDS_STATUS.ACTIVE;
    default:
      return status;
  }
};

// TODO: confirm request status
export const getPhysicalCardsRequestStatus = (status: PHYSICAL_CARDS_REQUEST_STATUS) => {
  return status;
};

export const getCardDeliveryStatus = (
  status: IsUncertain<PHYSICAL_CARDS_DELIVERY_STATUS>
) => {
  return status;
};
