export const electricityBillerIcon = (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={16} cy={16} r={16} fill="#FAFAFF" />
    <path
      d="M17.8746 7.6665H13.0783C12.9287 7.6665 12.8539 7.6665 12.7879 7.68928C12.7295 7.70942 12.6764 7.74228 12.6322 7.7855C12.5823 7.83436 12.5489 7.90125 12.482 8.03503L8.98201 15.035C8.82228 15.3545 8.74241 15.5142 8.76159 15.6441C8.77834 15.7575 8.84106 15.8589 8.93498 15.9246C9.04253 15.9998 9.22112 15.9998 9.57829 15.9998H14.7496L12.2496 24.3332L22.4105 13.7959C22.7533 13.4404 22.9247 13.2627 22.9348 13.1106C22.9435 12.9786 22.8889 12.8502 22.7879 12.7649C22.6714 12.6665 22.4245 12.6665 21.9306 12.6665H15.9996L17.8746 7.6665Z"
      fill="url(#paint0_linear_5448_5030)"
      stroke="url(#paint1_linear_5448_5030)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5448_5030"
        x1="19.6929"
        y1="-21.7034"
        x2="0.505266"
        y2="-18.7696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5D5FEF" />
        <stop offset={1} stopColor="#7879F1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5448_5030"
        x1="19.6929"
        y1="-21.7034"
        x2="0.505266"
        y2="-18.7696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5D5FEF" />
        <stop offset={1} stopColor="#7879F1" />
      </linearGradient>
    </defs>
  </svg>
);
