import env from "@/env";
import { PosBuilder, posGetHandler } from "..";

export const PosOverviewApi = (builder: PosBuilder) => {
  return {
    getOverview: builder.query<PosOverview, any>({
      queryFn: (params: any, thunk) => {
        return posGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `dashboard`,
            params,
          },
          thunk
        );
      },
    }),
    getInsight: builder.query<PosAlertRecievers[], any>({
      queryFn: (params: { module: string }, thunk) => {
        return posGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `insight`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
