import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";

export class PosInsightsTransfers {
  static BankTransfers = () => {
    const { data, isLoading } = posReduxAPI.useGetInsightQuery({
      module: "expense",
    });
    return (
      <div className="pos-insights-tabs">
        <Row>
          {/* <PosInsightsSideBar /> */}
          <CategoryMetricsCard />
          <MetricsGraph />
        </Row>
      </div>
    );
  };

  static InternalTransfers = () => {
    return (
      <div className="pos-insights-tabs">
        <Row>
          {/* <PosInsightsSideBar /> */}
          <CategoryMetricsCard />
          <MetricsGraph />
        </Row>
      </div>
    );
  };
}
