import React, { SyntheticEvent } from "react";
import { RavenTableRow } from "@ravenpay/raven-bank-ui";
import {
  formatDateTime,
  formatNumWithComma,
  formatStatus,
  symbol,
} from "@/utils/helper/Helper";
import Avatar from "@/components/common/avatar";
import { icons } from "@/assets/icons";
import { CopyText } from "@/components/common/copyCheck";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { IconVault } from "@/utils/helper/IconVault";
import { detectStatus } from "@/utils/helpers";
import { capitalizeEachWord } from "@/utils/formatWord";
import "./styles/index.css";
import { autoBillersLogo } from "@/constants/auto-billers-logo";

interface Defaults {
  name: string;
  email: string;
  amount: SN;
  provider: string;
  date: string;
  status: string;
}

export interface AirtimeDataProps extends Defaults {
  phoneNumber: string;
  reference: string;
}

const imagePlaceholder = IconVault(icons.electricity_placeholder);

const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = imagePlaceholder;
};

export class BillsTable {
  content: BusinessAirtimeModel;
  loading: boolean;
  constructor({ content, loading }: { content: BusinessAirtimeModel; loading: boolean }) {
    this.content = content || {};
    this.loading = loading;
  }

  AirtimeData = (trx: Partial<BusinessAirtimeModel>) => {
    const biller = autoBillersLogo(
      Util.safeValue(
        trx?.network?.toLowerCase() || (trx?.provider?.toLowerCase() as unknown as string)
      )
    ) as string;

    return (
      <RavenTableRow
        // onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.business_email as string)} />
          )
        }
        two={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.trx_ref)} />
          )
        }
        three={
          this.loading ? (
            <Redacted.AvatarWithValue />
          ) : (
            <>
              <figure className="biller-logo">
                <Avatar
                  name={Util.safeValue(
                    capitalizeEachWord(trx.network || (trx.provider as unknown as string))
                  )}
                  full
                  onError={addImageFallback}
                  imgSrc={biller}
                />
              </figure>
            </>
          )
        }
        four={
          this.loading && trx?.cable_plan && trx?.cable_plan?.length > 0 ? (
            <Redacted.Table />
          ) : trx?.cable_plan && trx?.cable_plan?.length > 0 ? (
            Util.cleanText(trx?.cable_plan)
          ) : (
            ""
          )
        }
        five={
          this.loading ? (
            <Redacted.Table />
          ) : (
            symbol("ngn") + formatNumWithComma(trx.amount ?? 0, "ngn")
          )
        }
        six={this.loading ? <Redacted.Table /> : Util.safeValue(trx?.phone)}
        seven={this.loading ? <Redacted.Table /> : formatDateTime(trx.created_at ?? "")}
        eight={
          this.loading ? (
            <Redacted.Table />
          ) : (
            formatStatus(detectStatus(Number(trx.status)))
          )
        }
      />
    );
  };
  Electricity = (trx: Partial<BusinessAirtimeModel>) => {
    const biller = autoBillersLogo(
      Util.safeValue(
        trx?.network?.toLowerCase() || (trx?.provider?.toLowerCase() as unknown as string)
      )
    ) as string;

    return (
      <RavenTableRow
        // onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.business_email as string)} />
          )
        }
        two={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.trx_ref)} />
          )
        }
        three={
          this.loading ? (
            <Redacted.Avatar />
          ) : (
            <>
              <figure className="biller-logo">
                <Avatar
                  name={Util.safeValue(
                    capitalizeEachWord(trx.network || (trx.provider as unknown as string))
                  )}
                  full
                  onError={addImageFallback}
                  imgSrc={biller}
                />
              </figure>
            </>
          )
        }
        four={
          this.loading && trx?.cable_plan && trx?.cable_plan?.length > 0 ? (
            <Redacted.Table />
          ) : trx?.cable_plan && trx?.cable_plan?.length > 0 ? (
            Util.cleanText(trx?.cable_plan)
          ) : (
            ""
          )
        }
        five={
          this.loading ? (
            <Redacted.Table />
          ) : (
            symbol("ngn") + formatNumWithComma(trx.amount ?? 0, "ngn")
          )
        }
        six={
          this.loading ? <Redacted.Table /> : Util.safeValue(trx?.meter_no ?? trx.phone)
        }
        seven={this.loading ? <Redacted.Table /> : formatDateTime(trx.created_at ?? "")}
        eight={
          this.loading ? (
            <Redacted.Table />
          ) : (
            formatStatus(detectStatus(Number(trx.status)))
          )
        }
      />
    );
  };
  Cable = (trx: Partial<BusinessAirtimeModel>) => {
    const biller = autoBillersLogo(
      Util.safeValue(
        trx?.network?.toLowerCase() || (trx?.provider?.toLowerCase() as unknown as string)
      )
    ) as string;

    return (
      <RavenTableRow
        // onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.business_email as string)} />
          )
        }
        two={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.trx_ref)} />
          )
        }
        three={
          this.loading ? (
            <Redacted.AvatarWithValue />
          ) : (
            <>
              <figure className="biller-logo">
                <Avatar
                  name={Util.safeValue(
                    capitalizeEachWord(trx.network || (trx.provider as unknown as string))
                  )}
                  full
                  onError={addImageFallback}
                  imgSrc={biller}
                />
              </figure>
            </>
          )
        }
        four={
          this.loading ? (
            <Redacted.Table />
          ) : (
            Util.cleanText(Util.safeValue(trx?.cable_plan))
          )
        }
        five={
          this.loading ? (
            <Redacted.Table />
          ) : (
            symbol("ngn") + formatNumWithComma(trx.amount ?? 0, "ngn")
          )
        }
        seven={this.loading ? <Redacted.Table /> : formatDateTime(trx.created_at ?? "")}
        eight={
          this.loading ? (
            <Redacted.Table />
          ) : (
            formatStatus(detectStatus(Number(trx.status)))
          )
        }
      />
    );
  };
  Betting = (trx: Partial<BusinessAirtimeModel>) => {
    const biller = autoBillersLogo(
      Util.safeValue(
        trx?.network?.toLowerCase() || (trx?.provider?.toLowerCase() as unknown as string)
      )
    ) as string;

    return (
      <RavenTableRow
        // onRowClick={onRowClick ? (onRowClick as () => void) : () => {}}
        one={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.business_email as string)} />
          )
        }
        two={
          this.loading ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(trx.trx_ref)} />
          )
        }
        three={
          this.loading ? (
            <Redacted.AvatarWithValue />
          ) : (
            <>
              <figure className="biller-logo">
                <Avatar
                  name={Util.safeValue(
                    capitalizeEachWord(trx.network || (trx.provider as unknown as string))
                  )}
                  full
                  onError={addImageFallback}
                  imgSrc={biller}
                />
              </figure>
            </>
          )
        }
        four={
          this.loading ? (
            <Redacted.Table />
          ) : (
            symbol("ngn") + formatNumWithComma(trx.amount ?? 0, "ngn")
          )
        }
        five={this.loading ? <Redacted.Table /> : formatDateTime(trx.created_at ?? "")}
        six={
          this.loading ? (
            <Redacted.Table />
          ) : (
            formatStatus(detectStatus(Number(trx.status)))
          )
        }
      />
    );
  };
}
