import { z } from "zod";

/**
 * to read anymore env file, add it to the schema here
 * @link https://sergiodxa.com/articles/using-zod-to-safely-read-env-variables
 */
const envSchema = z.object({
  REACT_APP_base_url: z.string(),
  REACT_APP_business_api: z.string(),
  REACT_APP_personal_api: z.string(),
  REACT_APP_pos_api: z.string(),
});

type TEnvSchema = z.infer<typeof envSchema>;

type ENV = {
  [K in keyof TEnvSchema as K extends `REACT_APP_${infer Rest}`
    ? Rest
    : K]: TEnvSchema[K];
}; // using mapped types and replace on type level to remove REACT_APP_;

const baseEnv = envSchema.parse(process.env);

const env: ENV = Object.fromEntries(
  Object.entries(baseEnv).map(([key, value]) => [key.replace("REACT_APP_", ""), value])
) as ENV;

export default env;
