import { AvatarWithTwinValue } from "@/components/common/avatar";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { useState } from "react";
import { BusinessAPIService } from "@/utils/api-methods";
import { cn } from "@/utils/colorConvert";
import { icons } from "@/assets/icons";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";

export const BusinessDeductedDepositsPage = () => {
  const pagination = usePagination();
  const [filterValue, setFilterValue] = useState({});
  const { currentPage, searchQuery } = pagination;
  const { data, isLoading, isFetching, refetch } =
    businessReduxAPI.useGetAllDeductedDepositsQuery({
      current_page: currentPage,
      search_term: searchQuery,
      ...filterValue,
    });

  const exportApi = businessReduxAPI.useGetAllDeductedDepositsQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
    paginate: "no",
  });
  const [isMutating, setIsMutating] = useState<true>();

  const { SmartFilterProps } = useSmartFilterProps(data, pagination);

  const { renderRedacted } = renderRedactedComponent(isMutating ?? isFetching);

  const handleStatusChange = async (id: SN) => {
    setIsMutating(true);
    const res = await BusinessAPIService.markDeductedTransferAsPaid(id);
    if (res) refetch();
    setIsMutating(undefined);
  };

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "business_email",
    "ACCOUNT NUMBER": "account_number",
    "ACCOUNT NAME": "account_name",
    AMOUNT: "amount_involved",
    REASON: "reason",
    "DEDUCTION/CREDIT LOG ID": "deductions_and_credit_logs_id",
    CREATED: "created_at",
    STATUS: "status",
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: exportApi.data?.data as any,
    loading: isLoading,
    valuePair,
    title: "Business Deducted Deposits(Bugiss)",
    filterValue,
    dispatchFunction: exportApi.refetch,
  });

  const [exportTriggered, triggerExport] = useState(false);
  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  if (isLoading) return <TransferEmptyScreen height="91%" className="mt-20" loading />;
  if (!data) return <TransferEmptyScreen />;

  return (
    <div className="h-empty-screen mt-20">
      <SmartFilter
        {...SmartFilterProps}
        onFilter={(e: any) => {
          setFilterValue(Util.filterStruct(e) as any);
          // reset current page to 1 on filter change
          pagination.setCurrentPage(1);
        }}
        onExport={() => {
          toggleModal("Export Deducted Deposits");
          triggerExport(true);
        }}
      />
      {data.data?.length > 0 ? (
        <RavenTable
          headerList={["Business Details", "Reason", "Amount", "Date Deducted", "Type"]}
        >
          {data.data.map((record: any) => {
            const {
              created_at,
              business_name,
              reason,
              amount_involved,
              deductions_and_credit_logs_id,
              business_email,
              _status,
            } = record;

            return (
              <RavenTableRow
                key={deductions_and_credit_logs_id}
                one={renderRedacted(
                  <AvatarWithTwinValue
                    name={business_name}
                    one={Util.safeValue(business_name)}
                    two={Util.safeValue(business_email)}
                  />,
                  "AvatarWithValue",
                )}
                two={renderRedacted(Util.safeValue(reason), "Table")}
                three={renderRedacted(
                  formatNumberToCurrency(Number(amount_involved), "NGN"),
                  "Table",
                )}
                four={renderRedacted(formatDateTime(created_at), "Table")}
                five={renderRedacted(<DeductedDepositStatus status={_status} />, "Table")}
                onRowClick={() => {
                  if (!_status) {
                    handleStatusChange(deductions_and_credit_logs_id);
                  }
                }}
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen inner loading={isFetching} />
      )}
    </div>
  );
};

export const DeductedDepositStatus = ({ status }: { status: number }) => {
  const statusText = status === 0 ? "Mark as Paid" : "Paid";

  return (
    <div className={cn("bugiss-status", !status ? "refunded" : "paid")}>
      <p>{statusText}</p>
      {status === 0 && <figure>{icons.purple_check}</figure>}
    </div>
  );
};
