import { PersonalInsightsPage } from "@/apps/personal/insights";
import { PersonalInsightsSavingsPage } from "@/apps/personal/insights/pages/savings/page";

export const personal_insights_routes = [
  {
    path: "/personal-insights",
    element: PersonalInsightsPage,
    children: [
      { path: "", element: PersonalInsightsSavingsPage },
      { path: "savings", element: PersonalInsightsSavingsPage },
    ],
  },
];
