import { getUser } from "@/redux/slices/auth/auth";
import { getCookie, setCookie, trimLongString } from "./helper/Helper";
import { ExportType } from "@/hooks/useExportModal";
import exportFromJSON from "export-from-json";
import { lowerCase } from "./formatWord";
import { detectStatus } from "./helpers";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
  parse,
  addDays,
} from "date-fns";

export type GraphFilterType = "daily" | "weekly" | "monthly" | "yearly";

type SN = string | number | null | undefined;
export interface FilterType {
  filterBy: any;
  type: any[];
  paginate: "yes" | "no";
  days: string;
  startDate: string;
  endDate: string;
  filterValue: string;
}

type UnsafeText = SN | string | null | undefined;

export const Util = {
  cleanText: (str: string) => {
    // Split the string at underscores

    const words: string[] = str ? String(str)?.split(/[_-]/) : [];

    // Capitalize each word and join them with a space
    const formattedString = words
      ?.map((word: string) => word?.charAt(0).toUpperCase() + word?.slice(1))
      ?.join(" ");

    return formattedString;
  },

  safeValue: (str: UnsafeText) => {
    if ((str && str?.toString()?.length === 0) || !Boolean(str)) {
      return "--";
    } else {
      return str as unknown as string;
    }

    // else return str as ;
  },

  detectCard: (event: any) => {
    const rawValue = event.replace(/\s/g, "");
    //   const maskedValue = rawValue.replace(/(\d{1,4})/g, "$1 ").trim();

    // Detect card type
    if (/^4/.test(rawValue)) {
      return "Visa";
    } else if (/^5[1-5]/.test(rawValue)) {
      return "Mastercard";
    } else if (/^3[47]/.test(rawValue)) {
      return "American Express";
    } else if (/^6(?:011|5)/.test(rawValue)) {
      return "Discover";
    } else if (/^5[6-9]|^506[0-9]|^6500[0-9]/.test(rawValue)) {
      return "Verve";
    } else {
      return "";
    }
  },

  capitalizeEachWord(str: string) {
    return str?.replace(/\b\w/g, (match) => match.toUpperCase());
  },

  safeText: (
    str: IsUncertain<UnsafeText>,
    /** Value to trim string by, ideal length is 20 */
    trim?: number
  ): string => {
    const text = Util.safeValue(str);

    if (trim) return trimLongString(text, trim) ?? "--";

    return text || "--";
  },

  safeNode(node: React.ReactNode, value: IsUncertain<unknown>) {
    return value ? node : "--";
  },

  join: (str1: SN) => {
    return {
      with: (str2: string): string => {
        return String(str1) + " " + str2;
      },
    };
  },

  filterStruct: (
    payload: FilterType,
    app: "business-banking" | "atlas" | "personal" | "pos" = "business-banking"
  ) => {
    let bbFilter;

    if (app === "business-banking") {
      bbFilter = {
        date_1: payload.startDate?.replaceAll("/", "-"),
        date_2: payload.endDate?.replaceAll("/", "-"),
        period_type: "custom",
        filterBy: payload?.filterBy,
        [payload?.filterBy]: payload?.filterValue,
        filterValue: payload?.filterValue,
        type: payload?.type,
      };
    }
    if (app === "pos") {
      bbFilter = {
        start_date: payload.startDate?.replaceAll("/", "-"),
        stop_date: payload.endDate?.replaceAll("/", "-"),
        period_type: "custom",
        filterBy: payload?.filterBy,
        filterValue: payload?.filterValue,
        type: payload?.type,
      };
    }

    return bbFilter;
  },

  exportStruct: (
    payload: ExportType,
    app: "business-banking" | "atlas" | "personal" = "business-banking"
  ) => {
    let bbFilter;
    if (app === "business-banking") {
      bbFilter = {
        date_1: payload.start_date?.replaceAll("/", "-"),
        date_2: payload.end_date?.replaceAll("/", "-"),
        period_type: "custom",
        fileType: payload?.fileType,
      };
    }

    return bbFilter as unknown as FilterType & { fileType: string };
  },

  /**
   * Generates a masked card PAN from a card number.
   *
   * @param cardNumber - The full card number.
   * @param maskChar - The character to use for masking. Defaults to '*'.
   * @returns The masked card PAN.
   */
  maskCardPan(cardNumber: string, maskChar: string = "*"): string {
    // Remove any non-digit characters from the card number
    const cleanedCardNumber = cardNumber.replace(/\D/g, "");

    // Ensure the card number has at least 8 digits
    if (cleanedCardNumber.length < 8) {
      throw new Error("Card number must have at least 8 digits.");
    }

    // Get the first 4 and last 4 digits of the card number
    const firstFourDigits = cleanedCardNumber.slice(0, 6);
    const lastFourDigits = cleanedCardNumber.slice(-4);

    // Generate the masked part of the PAN
    const maskedPartLength = cleanedCardNumber.length - 10;
    const maskedPart = maskChar.repeat(maskedPartLength);

    // Combine the first 4 digits, masked part, and last 4 digits
    return `${firstFourDigits}${maskedPart}${lastFourDigits}`;
  },

  reformatExportData: (
    valuePair: { [x: string]: string | string[] },
    data: Record<any, any>
  ) => {
    let keys = Object.keys(valuePair);

    return data.length > 0
      ? data?.map((chi: any) => {
          const newL: any = {};
          keys.forEach((element: any) => {
            if (typeof valuePair[element] === "object") {
              // check if data has metadata
              const strArr = valuePair[element] as string[];

              const isStatus = valuePair[element][0] === "status";

              strArr.forEach((el) => {
                const isMeta = el.includes(".") ? el?.split(".") : false;
                const meta =
                  isMeta && typeof chi[isMeta[0]] === "string"
                    ? JSON.parse(chi[isMeta[0]])
                    : false;
                if (isMeta && meta) {
                  // process and parse the metadata
                  const meta =
                    typeof chi[isMeta[0]] === "string" ? JSON.parse(chi[isMeta[0]]) : [];
                  newL[element] = meta[isMeta[1]];
                } else {
                  if (Boolean(chi[el]) && chi[el]?.length > 0) {
                    // dynamically handle status codes
                    if (isStatus) {
                      newL[element] = detectStatus(Number(chi[el]), strArr[1]);
                    } else newL[element] = chi[el];
                  } else {
                    return false;
                  }
                }
              });
            } else {
              // check if data has metadata
              let pair = valuePair as any;
              const isMeta = valuePair[element].includes(".")
                ? pair[element]?.split(".")
                : false;

              if (isMeta) {
                // process and parse the metadata
                const meta =
                  typeof chi[isMeta[0]] === "string" ? JSON.parse(chi[isMeta[0]]) : [];
                newL[element] = meta[isMeta[1]];
              } else {
                newL[element] = chi[pair[element]];
              }
            }
          });
          return newL;
        })
      : [];
  },

  downloadExport: (
    data: Record<any, any>,
    type: ExportType["fileType"],
    name?: string
  ) => {
    const fileName = name ?? "Bugiss- Export Data";
    const exportType =
      type === "csv" ? exportFromJSON.types.csv : exportFromJSON.types.xls;

    exportFromJSON({ data, fileName, exportType });
  },

  safeArray<T>(arr?: T[]): T[] {
    return arr ?? [];
  },
  // safeArray: <T>(arr?: Partial<T>): T => {
  //   return (arr ?? []) as T;
  // },

  checkUser: async (dispatch: any, redirect?: boolean) => {
    const resp = await dispatch(getUser({}));

    if (resp.payload?.status === "success") {
      let { fname, lname, platforms, email, clearance } = resp.payload.data;

      setCookie(
        "bgu",
        JSON.stringify({
          name: Util.join(fname).with(lname),
          email: email,
          platforms: platforms,
          role: clearance,
        }),
        3000000
      );

      if (window.location.href.includes("login")) {
        window.location.replace("auth-welcome");
      }
    } else {
      if (redirect) {
        window.location.replace("login");
      }
    }
  },

  safeObject: <T extends { [key: string]: any }>(payload: T): T => {
    return Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v !== undefined)
    ) as T;
  },
  safeParseJson<T>(jsonString: string, defaultValue: T): T {
    try {
      const parsedData = JSON.parse(jsonString);

      // Validate the parsed data
      if (typeof parsedData === "object" && parsedData !== null) {
        return parsedData as T;
      }
      throw new Error("Parsed data is not an object");
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return defaultValue;
    }
  },
  getUser: async () => {
    const user = getCookie("bgu");

    if (user && user?.length > 1) {
      return JSON.parse(user);
    } else {
      return false;
    }
  },
  search: (str: IsUncertain<SN>, search: IsUncertain<SN>) => {
    const searchStr = lowerCase(String(Util.safeText(str)));
    const _search = lowerCase(String(Util.safeText(search)));

    return searchStr.includes(_search);
  },

  createSearchFn: (search: SN) => {
    const fn = (str: IsUncertain<SN>) => Util.search(str, search);
    return fn;
  },

  isNotExportReq: (paginate?: "no" | "yes") => {
    if (paginate?.trim() === "no") {
      return false;
    } else if (!paginate) {
      return true;
    } else {
      return true;
    }
  },

  splitByAsterisksAndUnderscores: (str: string) => {
    // Split a string by any number of asterisks or underscores using a regular expression
    return str.split(/[*_]+/);
  },

  /**
   * Conditionally render a component.
   * @param {boolean} condition - The condition to evaluate.
   * @param {JSX.Element} component - The component to render if the condition is true.
   * @returns {JSX.Element|null} - The component if the condition is true, otherwise null.
   */
  renderIf: (condition: boolean, component: JSX.Element): JSX.Element | null => {
    return condition ? component : null;
  },

  getDateRange(
    filterType: GraphFilterType,
    filterValue: string | number
  ): { start_date: string; end_date: string } {
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (filterType) {
      case "daily":
        startDate = new Date(today.getFullYear(), today.getMonth(), Number(filterValue));
        endDate = startDate;
        break;

      case "weekly":
        const monthIndex = new Date(
          Date.parse(`${filterValue} 1, ${today.getFullYear()}`)
        ).getMonth();
        const firstDayOfMonth = new Date(today.getFullYear(), monthIndex, 1);
        startDate = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
        endDate = endOfWeek(firstDayOfMonth, { weekStartsOn: 1 });
        break;

      case "monthly":
        const month = new Date(
          Date.parse(`${filterValue} 1, ${today.getFullYear()}`)
        ).getMonth();
        startDate = startOfMonth(new Date(today.getFullYear(), month, 1));
        endDate = endOfMonth(new Date(today.getFullYear(), month, 1));
        break;

      case "yearly":
        const year = Number(filterValue);
        startDate = startOfYear(new Date(year, 0, 1));
        endDate = endOfYear(new Date(year, 0, 1));
        break;

      default:
        throw new Error(
          "Invalid filter type. Must be 'daily', 'weekly', 'monthly', or 'yearly'."
        );
    }

    return {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
    };
  },

  openInNewTab(url: string): void {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Failed to open the new tab.");
    }
  },
};
