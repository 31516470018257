import React, { ReactEventHandler } from "react";
import { returnInitial } from "../../../utils/helper/Helper";
import { generateRandomColor } from "../../../utils/helper/color";
import "./styles/index.css";
import TwinValue, { TwinValueProps } from "../twinValue";
import { XStack } from "../stacks";
import Redacted from "../shimmer/redact";
import { Util } from "@/utils/utility";

interface AvatarProps {
  name: string;
  full?: boolean;
  imgSrc?: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  loading?: boolean;
  twinClassNames?: {
    one?: string;
    two?: string;
  };
  onError?: ReactEventHandler<HTMLImageElement> | undefined;
}

const Avatar = ({ name, full, imgSrc, loading, onError }: AvatarProps) => {
  return (
    <div className="name-avatar">
      <span
        style={{
          backgroundColor: generateRandomColor("#e9f5ff"),
        }}
      >
        {loading ? (
          <Redacted.Avatar />
        ) : imgSrc ? (
          <figure className="avatar-img">
            <img src={imgSrc} onError={onError} alt="" />
          </figure>
        ) : (
          <p>{returnInitial(name)}</p>
        )}
      </span>

      {full && <p>{Util.capitalizeEachWord(name)}</p>}
    </div>
  );
};

type AvatarWithTwinTextProps = AvatarProps & TwinValueProps & { gap?: number };

export const AvatarWithTwinValue = (props: AvatarWithTwinTextProps) => {
  const { name, one, two, full, twinClassNames, imgSrc, gap, copy } = props;

  return (
    <XStack ai="center" gap={gap ?? 0.4}>
      <Avatar name={name} full={full} imgSrc={imgSrc} />
      <TwinValue
        twinClassNames={twinClassNames}
        one={one}
        two={two}
        copy={copy}
        gap={copy ? 0 : undefined}
      />
    </XStack>
  );
};

export default Avatar;
