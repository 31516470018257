import {
  BlockOrUnblockCardModal,
  PhysicalCardDetailsTable,
  PhysicalCardLimitModal,
} from "@/apps/personal/__internals__/combined-tables/cards/physical-cards/card-details-table";
import { PHYSICAL_CARDS_STATUS } from "@/apps/personal/__internals__/combined-tables/cards/utils";
import { icons } from "@/assets/icons";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { ConditionalCopyText } from "@/components/common/copyCheck";
import EmptyScreen from "@/components/common/emptyScreen";
import { IconButton } from "@/components/common/icon-button";
import { MainContentCol } from "@/components/common/mainContentCol";
import { EmptyShell, TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { TRANSFER_EMPTY_SCREEN } from "@/constants";
import { useActionBar } from "@/hooks/useActionBar";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { useDynamicQueryParams } from "@/hooks/useDynamicQueryParams";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { lowerCase, uppercase } from "@/utils/formatWord";
import { iife, setFnToUndefined } from "@/utils/general";
import { formatDateTime } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { useState } from "react";

enum ACTION {
  LIMITS = "LIMITS",
  DESTROY = "DESTROY",
}

interface CardStatus {
  icon: keyof typeof icons;
  text: string;
  borderColor: string;
  ctx: ACTION_CONTEXT;
}

const CardColors = {
  Danger: "#FF0F00",
  Success: "#1ACE37",
};

type ACTION_CONTEXT = "block" | "activate";

const InactiveCardAction: CardStatus = {
  text: "Activate Card",
  icon: "sun_01_green",
  borderColor: CardColors.Success,
  ctx: "activate",
};

// TODO: confirm card status
const cardStatus: Record<string, CardStatus> = {
  inactive: InactiveCardAction,
  blocked: InactiveCardAction,
  frozen: InactiveCardAction,
  active: {
    text: "Block Card",
    icon: "slash_circle_01",
    borderColor: CardColors.Danger,
    ctx: "block",
  },
};

//TODO: do card to color thing.
export const PhysicalCardsDetailsPage = () => {
  const { id } = useDynamicQueryParams({ keys: "id" });

  const [search, setSearch] = useState("");
  const {
    data: cardDetails,
    isLoading,
    isFetching,
    refetch,
  } = personalReduxAPI.useGetSinglePhysicalCardQuery(id);
  const [showActionBar, setShowActionBar] = useState(true);
  const [modalAction, setModalAction] = useState<ACTION>();

  const [blockOrUnblock, setBlockOrUnblock] = useState<ACTION_CONTEXT>();

  const { renderAsSafeModal } = useDynamicModal(modalAction);
  const { renderAsSafeModal: renderBlockModal } = useDynamicModal(blockOrUnblock);

  const inActiveCard = cardDetails?.status !== PHYSICAL_CARDS_STATUS.ACTIVE;

  const { ActionBar } = useActionBar({
    visible: inActiveCard,
    msg: cn("This card is", cardDetails?.status),
    onCancel: () => setShowActionBar(false),
    type: "warning",
  });

  const joinedLoaderDetails = isLoading || isFetching;
  const redactedFn = renderRedactedComponent(joinedLoaderDetails);

  const { RedactedLabel: DetailsRedactFn } = redactedFn;

  if (isLoading || !cardDetails)
    return (
      <EmptyShell pageTitle="Card Details">
        <EmptyScreen loading={joinedLoaderDetails} />
      </EmptyShell>
    );

  const filteredDetails = iife(() => {
    if (!search) return cardDetails.physical_card_debits;
    return cardDetails.physical_card_debits.filter((debit) =>
      lowerCase(debit.transaction_reference ?? "").includes(lowerCase(search))
    );
  });

  const actionButton = cardStatus[cardDetails.status];

  const statusColor = iife(() => {
    if (cardDetails.status === "active") return CardColors.Success;
    return CardColors.Danger;
  });

  return (
    <DashboardLayout>
      {showActionBar ? <ActionBar /> : null}
      <PageLayout
        pageTitle="Card Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap reusable-details-card-top">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img card-avatar-image personal-cards-page">
              {icons.business_cards}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Type">
                      <p>Physical Card</p>
                    </MainContentCol.Item>
                  )}
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Status">
                      {cardDetails.status ? (
                        <p style={{ color: statusColor, fontWeight: 700 }}>
                          {uppercase(cardDetails.status)}
                        </p>
                      ) : (
                        "--"
                      )}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Number">
                      <ConditionalCopyText text={cardDetails.masked_pan} />
                    </MainContentCol.Item>
                  )}
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Brand">
                      <p>{uppercase(Util.safeText(cardDetails.card_brand))}</p>
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Skin">
                      <p>{Util.safeText(cardDetails.color)}</p>
                    </MainContentCol.Item>
                  )}
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Date Created">
                      {cardDetails.created_at ? (
                        <p>{formatDateTime(cardDetails.created_at)}</p>
                      ) : (
                        "--"
                      )}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {DetailsRedactFn(
                    <MainContentCol.Item title="Card Holder's Email">
                      <ClickAbleEmail email={cardDetails.email} />
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
              </div>
              <div className="user-data__account-summary">
                <div className="top-wrap__actions">
                  {DetailsRedactFn(
                    <IconButton
                      borderColor="#ACACAC"
                      text="View Limits"
                      icon="arrow_circle_right_dynamic"
                      onClick={() => setModalAction(ACTION.LIMITS)}
                    />
                  )}
                  {actionButton &&
                    DetailsRedactFn(
                      <IconButton
                        {...actionButton}
                        onClick={() => setBlockOrUnblock(actionButton.ctx)}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-user-details__table-wrap">
          {cardDetails.physical_card_debits.length > 0 ? (
            <>
              <SmartFilter
                searchTitle="Search with reference"
                hideExport
                tableExport={{
                  data: filteredDetails,
                  fileName: cn("Physical Card request", new Date().getTime()),
                }}
                hideFilter
                onSearchChange={(query) => setSearch(query)}
              />
              {filteredDetails.length > 0 ? (
                <PhysicalCardDetailsTable
                  ctx="page"
                  data={filteredDetails}
                  redactedFn={redactedFn}
                />
              ) : (
                <TransferEmptyScreen height={TRANSFER_EMPTY_SCREEN.height} />
              )}
            </>
          ) : (
            <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} />
          )}
        </div>

        {renderAsSafeModal((state) => {
          if (state !== ACTION.LIMITS) return <></>;

          return (
            <PhysicalCardLimitModal
              data={cardDetails}
              refetch={refetch}
              onCancel={setFnToUndefined(setModalAction)}
            />
          );
        })}

        {renderBlockModal((state) => {
          if (!state) return <></>;

          return (
            <BlockOrUnblockCardModal
              data={cardDetails}
              ctx={state}
              refetch={refetch}
              onCancel={setFnToUndefined(setBlockOrUnblock)}
            />
          );
        })}
      </PageLayout>
    </DashboardLayout>
  );
};
