import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import { DashboardInfoCard } from "@/components/common/dashboardInfo";
import SmartFilter from "@/components/common/smartFilter";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { OutletTab } from "@/components/common/OutletTab";

export const BBTempKickbacksInner = ({ tab }: { tab: string }) => {
  return (
    <div className="kickbacks-filter-area">
      <SmartFilter
        alternateTitle={`${tab} Kickbacks`}
        pagination={{
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          onPageChange: (page: number) => {
            throw new Error("Function not implemented.");
          },
          activeFilter: "All Transactions",
        }}
      />
      <RavenTable headerList={["VOLUME PROCESSED", "COMMISSION", `${tab} COUNT`, "DATE"]}>
        <RavenTableRow one="₦1,325,000.00" two="₦125,000.00" three="5,000" four="Today" />
        <RavenTableRow
          one="₦125,000.00"
          two="₦125,000.00"
          three="5,000"
          four="Yesterday"
        />
        <RavenTableRow
          one="₦1,325,000.00"
          two="₦125,000.00"
          three="5,000"
          four="25 Nov, 2023"
        />
      </RavenTable>
    </div>
  );
};

const infoCards = [
  { title: "Volume Processed", value: "₦1,593,584,492.00" },
  { title: "Commission Generated", value: "₦23,584,849.00" },
  { title: "Total Count", value: "35,282" },
];

const tabElements = ["Airtime", "Data", "Electricity", "Cable TV", "Betting"];

const PageBaseURL = "/business-kickbacks";

export const BBKickbacksPage = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Kickbacks">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="airtime"
            >
              <DashboardInfoCard.Wrapper columns={3} marginTop={2.4}>
                {infoCards.map((ele) => (
                  <DashboardInfoCard {...ele} key={ele.title} />
                ))}
              </DashboardInfoCard.Wrapper>
            </OutletTab.Tabs>
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
