import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BusinessAPI } from "@/utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { toggleRedact } from "@/redux/actions/actions";
import { createDefaultReduxPaginationState, filterAPIQueryParams } from "@/utils/helpers";
import { BusinessBuilder } from "./api";

export const BusinessAuditTrailAPI = (builder: BusinessBuilder) => {
  return {
    getAuditTrail: builder.query<AuditTrailModel, Partial<PaginationQuery>>({
      queryFn: async (payload) => {
        const {
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_account: "yes",
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get("audit_trails/audit_trails_record", {
            params,
          });
          APIResponseHandler.tryHandler({ response, hideSuccessToast: true });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
