import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import Dropdown from "@/components/common/dropdown";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { useProductTheme } from "@/context/ProductTheme";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { PersonalAPIService } from "@/redux/slices/personal";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { APIResponseHandler } from "@/utils/api";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency, getThemePrimaryColor } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { LimitRequestDetailsModal } from "./modals/limit-request";
import { RejectLimitRequest } from "../components/modals";
import { GLOBAL_PER_PAGE } from "@/constants";
import TwinValue from "@/components/common/twinValue";
import { cn } from "@/utils/colorConvert";

interface ModalStatus {
  show: "approve" | "decline" | "view" | null;
  data: PersonalLimitRequests | null;
}

export const LimitRequests = () => {
  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetLimitRequestsQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });
  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    show: null,
    data: null,
  });

  const isActive = (status: ModalStatus["show"]) => modalStatus.show === status;
  const { productTheme } = useProductTheme();

  const handleModalCancel = () => {
    setModalStatus({ data: null, show: null });
  };

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable, RedactedLabel } = renderRedactedComponent(joinedLoader);

  const { renderDynamicModal } = useDynamicModal(Boolean(modalStatus.show));

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  const approveLimitRequest = async () => {
    setLoading(true);

    if (!modalStatus.data || !modalStatus.show) return toast.error("No selected Limit");
    if (modalStatus.show === "view") return;

    try {
      const {
        single_transfer_limit_requested,
        daily_transfer_limit_requested,
        limit_requested,
      } = modalStatus.data ?? {};

      const response = PersonalAPIService.approveOrRejectLimitRequest({
        request_id: modalStatus.data.id,
        single_transfer_limit: single_transfer_limit_requested,
        transfer_limit: daily_transfer_limit_requested,
        internal_limit: limit_requested,
        type: modalStatus.show,
      });

      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        handleModalCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const dropdown = [
    { label: "View Details", img: IconVault(icons.arrow_circle_right), ctx: "view" },
    { label: "Accept Request", img: IconVault(icons.check_circle_green), ctx: "approve" },
    { label: "Reject Request", img: IconVault(icons.block_circle), ctx: "decline" },
  ];

  return (
    <>
      <SmartFilter
        {...SmartFilterProps}
        searchTitle="Search by email"
        tableExport={{
          data: data.requests,
          fileName: cn("Limit Requests", new Date().toISOString()),
        }}
        hideExport
      />
      {data.requests?.length > 0 ? (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "User details",
              "Single Transfer Limit",
              "Daily Transfer Limit",
              "Status",
              "Date",
              " ",
            ]}
          >
            {data.requests.map((row) => {
              const { id } = row;

              return (
                <RavenTableRow
                  key={id}
                  {...{
                    one: RedactedTable(
                      <ClickAbleEmail email={Util.safeText(row.email)} />
                    ),
                    two: RedactedLabel(
                      <>
                        <TwinValue
                          one={cn(
                            "Requested Limit:",
                            formatNumberToCurrency(
                              row.single_transfer_limit_requested ?? 0
                            )
                          )}
                          two={cn(
                            "Current Limit:",
                            formatNumberToCurrency(row.single_transfer_limit_set ?? 0)
                          )}
                        />
                      </>
                    ),
                    three: RedactedLabel(
                      <>
                        <TwinValue
                          one={cn(
                            "Requested Limit:",
                            formatNumberToCurrency(
                              row.daily_transfer_limit_requested ?? 0
                            )
                          )}
                          two={cn(
                            "Current Limit:",
                            formatNumberToCurrency(row.daily_transfer_limit_set ?? 0)
                          )}
                        />
                      </>
                    ),
                    four: RedactedTable(formatStatus(row.status)),
                    five: RedactedLabel(formatDateTime(row.created_at)),
                    six: RedactedTable(
                      <div>
                        <Dropdown
                          items={dropdown}
                          defaultValue={dropdown[0]}
                          onChange={(e: any) => {
                            e.event.stopPropagation();
                            setModalStatus({ data: row, show: e.ctx });
                          }}
                          rightPosition={1}
                          disableTriggerButton
                          buttonChild={() => <ActionDrop more />}
                        />
                      </div>
                    ),
                  }}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}

      {isActive("approve") &&
        renderDynamicModal(
          <ActionModal
            visible={Boolean(modalStatus.show)}
            onCancel={handleModalCancel}
            onClick={approveLimitRequest}
            cancelText="No, Cancel"
            actionText="Yes, Approve"
            btnColor={getThemePrimaryColor(productTheme)}
            body="Are you sure you want to accept this user's limit request."
            title="Approve Limit Request"
            loading={loading}
          />
        )}

      {isActive("view") &&
        modalStatus.data !== null &&
        renderDynamicModal(
          <LimitRequestDetailsModal
            row={modalStatus.data}
            onCancel={handleModalCancel}
            onAccept={() => setModalStatus((old) => ({ ...old, show: "approve" }))}
            onReject={() => setModalStatus((old) => ({ ...old, show: "decline" }))}
          />
        )}

      {isActive("decline") &&
        modalStatus.data &&
        renderDynamicModal(
          <RejectLimitRequest
            id={modalStatus.data.id}
            refetch={refetch}
            onCancel={handleModalCancel}
          />
        )}
    </>
  );
};
