import { cleanWhiteSpace } from "@/utils/colorConvert";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";

export interface TableProps {
  savings: PersonalsSavingModel[];
  RedactedTable(node: React.ReactNode): React.ReactNode;
  onRowClick(record: PersonalsSavingModel): void;
}

export const TableHeader = {
  fixed: [
    "TITLE",
    "CURRENT AMOUNT",
    "INTEREST",
    "DATE CREATED",
    "RELEASE DATE",
    "STATUS",
  ],
  hifa: [
    "TITLE",
    "PRINCIPAL",
    "CURRENT AMOUNT",
    "INTEREST",
    "DATE CREATED",
    "RELEASE DATE",
    "STATUS",
  ],
  target: [
    "TITLE",
    "TARGET AMOUNT",
    "CURRENT AMOUNT",
    "INTEREST",
    "START DATE",
    "RELEASE DATE",
    "DATE CREATED",
    "STATUS",
  ],
  global: [
    "CURR",
    "AMOUNT SAVED",
    "INTEREST",
    "CURRENT AMOUNT",
    "DATE CREATED",
    "RELEASE DATE",
    "DURATION",
    "STATUS",
  ],
  spendXSave: ["AMOUNT SAVED", "% SAVED", "AMOUNT SAVED", "DATE SAVED", "BALANCE AFTER"],
};

export const FixedSavings = (props: TableProps) => {
  const { RedactedTable, savings } = props;

  return (
    <RavenTable className="table__main" headerList={TableHeader.fixed}>
      {savings.map((row) => {
        const { id, interest, current_amount, release_date, created_at, status, title } =
          row;

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              <div className="controlled-table-content">{Util.safeValue(title)}</div>
            )}
            two={RedactedTable(Util.safeValue(formatNumberToCurrency(current_amount)))}
            three={RedactedTable(Util.safeValue(cleanWhiteSpace(interest, "%")))}
            four={RedactedTable(Util.safeValue(formatDateTime(created_at)))}
            five={RedactedTable(
              Util.safeValue(release_date ? formatDateTime(release_date) : "--")
            )}
            six={RedactedTable(formatStatus(detectStatus(status, "pb-savings")))}
            onRowClick={() => props.onRowClick(row)}
          />
        );
      })}
    </RavenTable>
  );
};

export const TargetSavings = (props: TableProps) => {
  const { RedactedTable, savings } = props;

  return (
    <RavenTable className="table__main" headerList={TableHeader.target}>
      {savings.map((row) => {
        const { id, interest, current_amount, start, stop, status, title, target } = row;

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              <div className="controlled-table-content">{Util.safeValue(title)}</div>
            )}
            two={RedactedTable(Util.safeValue(formatNumberToCurrency(target)))}
            three={RedactedTable(Util.safeValue(formatNumberToCurrency(current_amount)))}
            four={RedactedTable(Util.safeValue(cleanWhiteSpace(interest, "%")))}
            five={RedactedTable(Util.safeValue(start ? formatDateTime(start) : "--"))}
            six={RedactedTable(Util.safeValue(stop ? formatDateTime(stop) : "--"))}
            seven={RedactedTable(Util.safeValue(formatDateTime(row.created_at)))}
            eight={RedactedTable(formatStatus(detectStatus(status, "pb-savings")))}
            onRowClick={() => props.onRowClick(row)}
          />
        );
      })}
    </RavenTable>
  );
};

export const HIFASavings = (props: TableProps) => {
  const { RedactedTable, savings } = props;

  return (
    <RavenTable className="table__main" headerList={TableHeader.hifa}>
      {savings.map((row) => {
        const {
          id,
          interest,
          current_amount,
          created_at,
          release_date,
          status,
          title,
          principal,
        } = row;

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              <div className="controlled-table-content">{Util.safeValue(title)}</div>
            )}
            two={RedactedTable(Util.safeValue(formatNumberToCurrency(principal)))}
            three={RedactedTable(Util.safeValue(formatNumberToCurrency(current_amount)))}
            four={RedactedTable(Util.safeValue(cleanWhiteSpace(interest, "%")))}
            five={RedactedTable(
              Util.safeValue(created_at ? formatDateTime(created_at) : "--")
            )}
            six={RedactedTable(
              Util.safeValue(release_date ? formatDateTime(release_date) : "--")
            )}
            seven={RedactedTable(formatStatus(detectStatus(status, "pb-savings")))}
            onRowClick={() => props.onRowClick(row)}
          />
        );
      })}
    </RavenTable>
  );
};
