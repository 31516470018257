import "./styles/index.css";
import TabComponent from "@/components/common/Tabs";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import PosInsightsSideBar from "~pb/___not__ready__/insights/components/insightsSidebar";
import Dropdown from "@/components/common/dropdown";
import { TabsElement } from "@/components/common/Tabs/shared";
import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Column, Row } from "@/components/common/stacks";
import { Outlet } from "react-router-dom";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";

const tabs: TabsElement[] = [
  {
    value: "Bank Transfers",
    label: "Bank Transfers",
    dropdown: {
      tabs: [
        { label: "Bank Transfers", value: "Bank Transfers" },
        { label: "Internal Transfers", value: "Internal Transfers" },
      ],
    },
  },
  "Referrals",
  "Transactions",
  {
    label: "Bills",
    value: "Bills",
    dropdown: {
      maxWidth: "15rem",
      tabs: ["Airtime", "Data", { label: "Cable TV", value: "Cable TV" }, "Betting"],
    },
  },
  "Deposit",
  {
    label: "Savings",
    value: "Savings",
    dropdown: {
      tabs: [
        "All Savings",
        "Fixed Savings",
        "Target Savings",
        "HIFA Savings",
        "Global Savings",
        "SpendXSave",
      ],
    },
  },
  "Overdraft",
  "Signups",
  "Expenses",
];

const PosInsights = () => (
  <DashboardLayout>
    <PageLayout pageTitle="Insights">
      {/* <div className="pb-insights__header">
        <Dropdown
          items={dropdown}
          defaultValue={dropdown[0]}
          className="hdr-dropdown"
          buttonChild={({ selected }) => <div>Insights • {selected?.label}</div>}
        />
      </div> */}
      <div className="pb-insights__tabs">
        <OutletTab>
          <OutletTab.Tabs
            tabs={tabs}
            baseURL={"/pos-insights"}
            defaultOutletUrl="bank-transfers"
          ></OutletTab.Tabs>
          <Gap size={15} />
          <OutletTab.Outlet />
        </OutletTab>
      </div>
    </PageLayout>
  </DashboardLayout>
);

export default PosInsights;
