import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";
import { usePagination } from "@/hooks/usePagination";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SETTLEMENT_STATUS } from "../__internals__/constants/card";
import { useEffect } from "react";
import { $event } from "@/utils/events/eventEmitter";

const tabElements = ["Pending Settlement", "Failed Settlement"];

const PageBaseURL = "/pos-settlement";

export const PosSettlement = () => {
  const pagination = usePagination();

  const location = useLocation();

  const res = posReduxAPI.useGetSettlementsQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
    status: location.pathname.includes("pending")
      ? SETTLEMENT_STATUS.PENDING
      : SETTLEMENT_STATUS.FAILED,
  });
  const { data } = res;

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, "", `?page=${pagination.currentPage}`);

    $event.emit("settlement_page_event", pagination.currentPage);
  }, [pagination.currentPage]);
  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Settlement">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="pending-settlement"
            ></OutletTab.Tabs>
            <Gap size={15} />
            <div>
              <SmartFilter {...SmartFilterProps} searchTitle={"Search settlement..."} />
            </div>
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
