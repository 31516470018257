import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { FixedSavings, HIFASavings, TargetSavings } from "./tables";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { cn } from "@/utils/colorConvert";
import { useNavigate } from "react-router-dom";
import { usePersonalSavingsFilterAndExport } from "../../__internals__/internal-hooks/savings";

interface NewSavingsPageProps {
  selectedTab: string;
}

const Wrapper = ({ children, className }: IProps) => (
  <div className={cn("savings-page-item-wrapper", className)}>{children}</div>
);

const NewSavingsPage = (props: NewSavingsPageProps) => {
  const { selectedTab } = props;
  const { ExportModal, onExport } = usePersonalSavingsFilterAndExport(selectedTab);
  const pagination = usePagination();
  const { data, isLoading, isFetching } = personalReduxAPI.useGetSavingsQuery({
    per_page: 100,
    type: selectedTab ?? "fixed",
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
    search: pagination.searchQuery,
    page: pagination.currentPage,
  });

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const navigate = useNavigate();

  const handleClick = (row: PersonalsSavingModel) => {
    navigate(`/personal-savings/${row.id}`);
  };

  if (isLoading || !data)
    return (
      <Wrapper>
        <TransferEmptyScreen loading={joinedLoader} />
      </Wrapper>
    );

  const renderTable = () => {
    switch (selectedTab) {
      case "fixed":
        return (
          <FixedSavings
            RedactedTable={RedactedTable}
            savings={data.savings}
            onRowClick={handleClick}
          />
        );
      case "fixed_new":
        return (
          <FixedSavings
            RedactedTable={RedactedTable}
            savings={data.savings}
            onRowClick={handleClick}
          />
        );
      case "target_new":
        return (
          <TargetSavings
            RedactedTable={RedactedTable}
            savings={data.savings}
            onRowClick={handleClick}
          />
        );
      case "target":
        return (
          <TargetSavings
            RedactedTable={RedactedTable}
            savings={data.savings}
            onRowClick={handleClick}
          />
        );
      case "hifa":
        return (
          <HIFASavings
            RedactedTable={RedactedTable}
            savings={data.savings}
            onRowClick={handleClick}
          />
        );
      default:
        return <TransferEmptyScreen height="68vh" loading={joinedLoader} />;
    }
  };

  return (
    <Wrapper>
      {selectedTab !== "global" && (
        <SmartFilter {...SmartFilterProps} onExport={onExport} />
      )}
      {data.savings?.length > 0 ? (
        renderTable()
      ) : (
        <TransferEmptyScreen height="65vh" loading={joinedLoader} />
      )}
      <ExportModal />
    </Wrapper>
  );
};

export const FixedSavingsPage = () => {
  return <NewSavingsPage selectedTab="fixed" />;
};
export const NewFixedSavingsPage = () => {
  return <NewSavingsPage selectedTab="fixed_new" />;
};
export const TargetSavingsPage = () => {
  return <NewSavingsPage selectedTab="target" />;
};
export const NewTargetSavingsPage = () => {
  return <NewSavingsPage selectedTab="target_new" />;
};

export const HIFASAvingsPage = () => {
  return <NewSavingsPage selectedTab="hifa" />;
};

export const GlobalSavingsPage = () => {
  return (
    <Wrapper>
      <TransferEmptyScreen
        height="100%"
        title="We are bring Global Savings shortly"
        subTitle="Nothing to see here for now, we are working hard to bring you global savings"
      />
    </Wrapper>
  );
};
