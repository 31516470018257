import "./styles/index.css";
import { DashboardInfoCard } from "../../../components/common/dashboardInfo";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { OutletTab } from "@/components/common/OutletTab";
import { formatNumberToCurrency } from "@/utils/helpers";
import { GENERAL_CLASSES } from "@/constants";
import PageLayout from "@/components/layouts/dashboard/pageLayout";

const Tabs = ["User Metrics", "Transaction Metrics"];

function numberWithCommas(x: string | number) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}

const toFormatted = (num: IsUncertain<number>) => (num ? numberWithCommas(num) : "--");

export function PersonalOverview() {
  const { isFetching, isLoading, data } = personalReduxAPI.useGetOverviewQuery();

  const joinedLoader = isLoading || isFetching;

  const dashboardInfoCards = [
    { title: "Total Users", value: toFormatted(data?.totalUsers) },
    {
      title: "Total Transactions (Year)",
      value: toFormatted(data?.transactionsThisYear),
    },
    {
      title: "Total Cash Balance",
      value: formatNumberToCurrency(data?.totalCashBalance ?? 0),
    },
    { title: "New Users (Month)", value: toFormatted(data?.usersThisMonth) },
  ];

  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Overview"
        style={{ backgroundColor: "transparent", width: "100%" }}
        childrenClassName="buggies-users-dashboard"
      >
        <DashboardInfoCard.Wrapper className="overview-stats">
          {dashboardInfoCards.map((info) => (
            <DashboardInfoCard key={info.title} {...info} loading={joinedLoader} />
          ))}
        </DashboardInfoCard.Wrapper>

        <div className="buggies-users-dashboard__chart-area overview-stats">
          <OutletTab>
            <OutletTab.Tabs
              baseURL="/personal-overview"
              tabs={Tabs}
              className={GENERAL_CLASSES.OUTLET}
            />
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
}
