import { PosBuilder } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosFeesApi = (builder: PosBuilder) => {
  return {
    getFees: createQueryWithRoute<
      PosFeesModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "fees", { name: "getFees" }),

    getFeesExport: createQueryWithRoute<
      PosFeesModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "fees", { export: true, name: "getFeesExport" }),
  };
};
