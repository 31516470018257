import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { getPbDepositsStatus } from "../utils";
import { CopyText } from "@/components/common/copyCheck";
import { STRING_TRIM_LENGTH } from "@/constants";

interface DepositDetailsProps {
  record: PersonalDepositsModel;
  onCancel(): void;
}

export const DepositDetails = (props: DepositDetailsProps) => {
  const { onCancel } = props;
  const {
    created_at,
    status,
    currency,
    amount,
    reference,
    description,
    destination_account_number,
    email,
    credited_amount,
  } = props.record;

  const { first_name, last_name, account_number, bank } = props.record.source;

  const view = {
    amount: formatNumberToCurrency(amount, currency),
    credited_amount: formatNumberToCurrency(credited_amount, currency),
    narration: Util.safeNode(
      <CopyText
        text={formatWord(Util.safeText(description, STRING_TRIM_LENGTH))}
        textToCopy={Util.safeText(description)}
      />,
      description
    ),
    reference: Util.safeText(reference),
    "sender's_name": cn(Util.safeText(first_name), Util.safeText(last_name)),
    "sender's_account_number": Util.safeText(account_number),
    "sender's_bank": Util.safeText(bank),
    "receiver's_email": cn(Util.safeText(email)),
    "receiver's_account_number": Util.safeText(destination_account_number),
    status: formatStatus(getPbDepositsStatus(status)),
    date: Util.safeText(formatDateTime(created_at)),
  };

  // TODO: remove this

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => onCancel()}
      title="Deposit Details"
      hideCancel
      className="very-big-modal"
      actionText="Close"
    >
      <div className="expense__details deposit-details-modal hide-scrollbar">
        <InlineDetails
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={Object.entries(view).map(([label, value]) => ({
            label: formatWord(label),
            value,
          }))}
          direction={""}
          title={""}
        />
      </div>
    </ActionModal>
  );
};
