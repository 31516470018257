import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { setCookie } from "@/utils/helper/Helper";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";

export const getPayrolls = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/payrolls",
  async (payload, thunkAPI) => {
    const {
      range,
      business_email,
      period_type,
      search_term,
      per_page,
      current_page,
      date_1,
      date_2,
      verification_type,
      paginate,
      status,
    } = payload;

    try {
      const params = {
        range: range || "3",
        business_email: business_email || "",
        period_type: period_type || "",
        search_term: search_term || "",
        per_page: per_page ? per_page.toString() : "20",
        current_page: current_page || "",
        date1: date_1 || "",
        date2: date_2 || "",
        verification_type: verification_type || "all",
        paginate: paginate,
        status: status,
      };
      const data = await axios.get(
        `payroll/get_filter_or_search_payroll_disbursement_for_businesses`,
        { params }
      );
      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        if (Util.isNotExportReq(paginate)) {
          thunkAPI.dispatch(toggleRedact(false));
          thunkAPI.dispatch(SET_BUSINESS_PAYROLL(data?.data?.data?.payroll_disbursement));
        }

        return data?.data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getDisbursements = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_salary_transaction_for_a_payroll",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `payroll/get_filter_or_search_salary_transaction_for_a_payroll?range=${
          payload?.range ?? "3"
        }&business_email=${payload?.business_email ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&disburse_reference=${
          payload.disburse_reference ?? ""
        }`
      );
      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(SET_BUSINESS_PAYROLL_TRX(data?.data?.data));
        thunkAPI.dispatch(toggleRedact(false));
        return data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      // if (error.message === "Network Error") {
      //   toast.error(error.message, {
      //     position: "top-right",
      //   });
      // }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getEmployeeDetails = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_details_of_an_employee_",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `payroll/get_details_of_an_employee_?range=${
          payload?.range ?? "3"
        }&business_email=${payload?.business_email ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&employees_email=${payload.employees_email ?? ""}`
      );
      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(SET_EMPLOYEES_DETAILS(data?.data?.data));
        thunkAPI.dispatch(toggleRedact(false));
        return data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      // if (error.message === "Network Error") {
      //   toast.error(error.message, {
      //     position: "top-right",
      //   });
      // }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);
const initialState: any = {
  loading: false,
  payrolls: [],
  payroll_trx: [],
  employee_details: {},
  isDisbursementLoading: false,
  isAuth: false,
};

export const businessPayrollSlice = createSlice({
  name: "business-payroll",
  initialState,
  reducers: {
    SET_BUSINESS_PAYROLL: (state, action) => {
      state.payrolls = action.payload;
    },
    SET_BUSINESS_PAYROLL_TRX: (state, action) => {
      state.payroll_trx = action.payload;
      state.isAuth = true;
    },
    SET_EMPLOYEES_DETAILS: (state, action) => {
      state.employee_details = action.payload;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(getPayrolls.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDisbursements.pending, (state) => {
      state.isDisbursementLoading = true;
    });
    builder.addCase(getEmployeeDetails.pending, (state) => {
      state.loading = true;
    });

    //fullfilled state changes
    builder.addCase(getPayrolls.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getDisbursements.fulfilled, (state) => {
      state.isDisbursementLoading = false;
    });
    builder.addCase(getEmployeeDetails.fulfilled, (state) => {
      state.loading = false;
    });

    //rejected state changes
    builder.addCase(getPayrolls.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getDisbursements.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getEmployeeDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { SET_BUSINESS_PAYROLL, SET_BUSINESS_PAYROLL_TRX, SET_EMPLOYEES_DETAILS } =
  businessPayrollSlice.actions;

export default businessPayrollSlice.reducer;
