import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { useEffect, useState } from "react";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  symbol,
  trimLongString,
} from "@/utils/helper/Helper";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
import {
  assertPaginationPageNumber,
  detectStatus,
  renderConditionally,
} from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { BIZ_TRANSACTION_STATUS } from "../__internals__/constants";

const BBTransactions = () => {
  const { isDarkMode } = useThemeMode();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      BugissApi.business.getTransactions({
        per_page: 20,
        current_page: currentPage,
        search_term: searchQuery,
        transaction_type: "",
        status: filterValue.filterValue,
        ...filterValue,
      })
    ).then((d) => console.log(d.payload?.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery, filterValue]);

  const { transactions, loading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const trx = transactions?.data ?? [];

  const { isRedacting } = useSelector((state: RootState) => state.general);

  async function handleDownload(ref: string, biz: string) {
    const resp = await dispatch(
      BugissApi.business.downloadTransactionReciept({ business_id: biz, trx_ref: ref })
    );

    if (resp.payload.status === "success") {
      const url = resp.payload.data.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `transaction-reciept-#${ref}-biz-${biz}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "email",
    REFERENCE: ["trx_ref", "bulk_transfer_reference"],
    SESSION_ID: "meta_data.session_id",
    DIRECTION: "direction",
    BANK: "meta_data.bank",
    "ACCOUNT NO": "meta_data.account_number",
    RECIPIENT: "meta_data.account_name",
    NARRATION: "meta_data.narration",
    DESCRIPTIOM: "type",
    CURRENCY: "currency",
    AMOUNT: "amount",
    FEE: "fee",
    "BAL BEFORE": "bal_before",
    "BAL AFTER": "bal_after",
    STATUS: ["status", "transactions"],
    DATE: "created_at ",
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    // data: trx,
    loading,
    valuePair,
    filterValue,
    title: "Business Export(Bugiss)",
    dataPath: "data.transactiions.data",
    apiFunction: BugissApi.business.getTransactions,
  });
  const { toggleModal } = useExportModal(handleExport);

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Transactions">
          <SmartFilter
            pagination={{
              currentPage,
              itemsPerPage: assertPaginationPageNumber(transactions?.per_page as number),
              totalItems: assertPaginationPageNumber(transactions?.total),
              onPageChange: function (page: number): void {
                setCurrentPage(page);
              },
              activeFilter: "All Transactions",
            }}
            singleSelect
            filters={[
              {
                label: "Status",
                filterBy: "status",
                options: [
                  { value: BIZ_TRANSACTION_STATUS.PENDING.toString(), label: "Pending" },
                  {
                    value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                    label: "Processing",
                  },
                  { value: BIZ_TRANSACTION_STATUS.FAILED.toString(), label: "Failed" },
                  { value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(), label: "Success" },
                  {
                    value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                    label: "Reversed",
                  },
                ],
              },
              // TODO: add filter by direction and fix issue in smart filter
              // {
              //   label: "Type",
              //   filterBy: "direction",
              //   options: [
              //     { value: "0", label: "Debit" },
              //     {
              //       value: "1",
              //       label: "Credit",
              //     },
              //   ],
              // },
            ]}
            onExport={() => toggleModal("Export Transactions")}
            onSearchChange={(e: string) => setSearchQuery(e)}
            onFilter={(e: any) => {
              setFilterValue(Util.filterStruct(e));
              setCurrentPage(1);
              // alert(JSON.stringify(e));
            }}
            searchTitle="Search Transactions..."
          />

          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            {(!isRedacting && loading) || trx?.length === 0 ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "BUSINESS DETAILS",
                  "REFERENCE",
                  "TYPE",
                  "AMOUNT",
                  "STATUS",
                  "TRANSACTION DATE",
                ]}
              >
                {trx.map((chi) => {
                  const meta = formatMetaData(chi.meta_data);

                  return (
                    <RavenTableRow
                      onRowClick={() => {
                        setView({
                          on: true,
                          data: chi,
                        });
                      }}
                      one={
                        loading ? (
                          <Redacted.Labelled />
                        ) : (
                          <TwinValue
                            one={trimLongString(chi.business_name, 30) ?? "--"}
                            two={chi.email}
                            copy
                          />
                        )
                      }
                      two={
                        loading ? (
                          <Redacted.AvatarWithValue />
                        ) : (
                          <Txreference
                            direction={chi.direction}
                            reference={trimLongString(
                              chi.trx_ref ??
                                chi.bulk_transfer_reference ??
                                meta?.session_id ??
                                "--",
                              15
                            )}
                            textToCopy={
                              chi.trx_ref ??
                              chi.bulk_transfer_reference ??
                              meta?.session_id ??
                              "--"
                            }
                          />
                        )
                      }
                      three={
                        <div>
                          {loading ? <Redacted.Table /> : Util.cleanText(chi.type)}
                        </div>
                      }
                      // four={
                      //   loading ? (
                      //     <Redacted.Table />
                      //   ) : chi.bulk_transfer_reference ? (
                      //     "Bulk transfer"
                      //   ) : (
                      //     "Single Transfer"
                      //   )
                      // }

                      five={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatStatus(detectStatus(Number(chi?.status), "transactions"))
                        )
                      }
                      four={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          `${symbol(chi?.currency.toLowerCase())}${formatNumWithComma(
                            chi.amount,
                            chi?.currency
                          )}`
                        )
                      }
                      six={loading ? <Redacted.Table /> : formatDateTime(chi.created_at)}
                    />
                  );
                })}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() =>
          handleDownload(String(view.data.trx_ref), String(view.data.business_id))
        }
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={loading}
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={view?.data?.direction}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "Amount",
                value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
                // copy: true,
              },
              // {
              //   label: "Business Email",
              //   value: view.data?.email,
              // },

              {
                label: "Bal Before",
                value: `₦${formatNumWithComma(view.data.bal_before ?? 0, "ngn")}`,
              },
              {
                label: "Bal After",
                value: `₦${formatNumWithComma(view.data.bal_after ?? 0, "ngn")}`,
              },

              ...renderConditionally(
                "Narration",
                trimLongString(formatMetaData(view.data.meta_data ?? "")?.narration, 30)
              ),

              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },
              ...renderConditionally(
                "Recipient",
                formatMetaData(view.data.meta_data ?? "")?.account_name
              ),
              ...renderConditionally(
                "Account Number",
                formatMetaData(view.data.meta_data ?? "")?.account_number
              ),
              ...renderConditionally(
                "Bank",
                formatMetaData(view.data.meta_data ?? "")?.bank
              ),

              {
                label:
                  view?.data?.direction === "credit" ? "Session ID" : "Transaction Ref",
                value:
                  view.data?.trx_ref ??
                  view.data?.bulk_transfer_reference ??
                  (typeof view?.data?.meta_data === "string"
                    ? JSON.parse(String(view?.data?.meta_data)).session_id
                    : null) ??
                  "--",
                copy: true,
                trim: true,
              },

              {
                label: "Status",
                value: formatStatus(detectStatus(Number(view.data?.status))),
              },

              {
                label: "Date Authorized",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </>
  );
};

export default BBTransactions;
