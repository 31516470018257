import { OutletTab } from "@/components/common/OutletTab";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { GENERAL_CLASSES } from "@/constants";

const Tabs = [
  "Virtual Cards",
  "Physical Cards",
  { label: "Physical Card Request", value: "physical-cards-request" },
];

export const PersonalCardsPage = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Cards">
        <OutletTab>
          <OutletTab.Tabs
            className={GENERAL_CLASSES.OUTLET}
            tabs={Tabs}
            baseURL="/personal-cards"
          />
          <OutletTab.Outlet />
        </OutletTab>
      </PageLayout>
    </DashboardLayout>
  );
};
