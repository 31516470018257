import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import { OutletTab } from "@/components/common/OutletTab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PaginationProvider, usePagination } from "@/hooks/usePagination";
import { fetchBizTransactions } from "@/redux/switches/business/transaction.sw";
import { Util } from "@/utils/utility";
import Gap from "@/components/common/styleComponents/Gap";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { BIZ_TRANSACTION_STATUS } from "../__internals__/constants";

const tabElements = [
  { label: "Airtime", value: "airtime" },
  { label: "Data", value: "data" },
  { label: "Electricity", value: "electricity" },
  { label: "Cable TV", value: "cable" },
  { label: "Betting", value: "betting" },
];

const PageBaseURL = "/business-bills";

export const BBBills = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<any>({
    value: "airtime",
    label: "Airtime",
  });

  const {
    currentPage,
    setCurrentPage,
    searchQuery,
    setFilterValue,
    filterValue,
    setSearchQuery,
  } = usePagination();
  useEffect(() => {
    dispatch(
      fetchBizTransactions({
        identifier: selectedTab?.value,
        payload: {
          current_page: currentPage,
          search_term: searchQuery,
          ...filterValue,
        },
      })
    );
  }, [filterValue, searchQuery, currentPage, selectedTab]);
  const { transactions, loading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const dispatchApi = () => {
    dispatch(
      fetchBizTransactions({
        identifier: selectedTab?.value,
        payload: {
          current_page: currentPage,
          search_term: searchQuery,
          ...filterValue,
          paginate: "no",
        },
      })
    );
  };
  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "business_email",
    AMOUNT: "amount",
    CURRENCY: "currency",
    RECIPIENT: ["meter_no", "phone"],
    TYPE: "type",
    REFERENCE: "trx_ref",
    CREATED: "created_at",
    PROVIDER: ["network", "provider"],
    PLAN: ["cable_plan"],
    STATUS: ["status", "transactions"],
  };
  const handleExport = useIsolatedExport({
    setFilterValue,
    data: transactions?.data as any,
    loading: loading,
    valuePair,
    dispatchFunction: dispatchApi,
    // dataPath: "payment_link_transactions_records.data",
    title: "Business Bills(Bugiss)",
    filterValue,
  });
  const [exportTriggered, triggerExport] = useState(false);

  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  useEffect(() => {
    const url = window.location.pathname;

    setSelectedTab(tabElements?.find((d) => d?.value === url.split("/")[2]));
  }, [tabElements]);
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Bills">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              onTabValueChange={(e: string) => setSelectedTab(e)}
              baseURL={PageBaseURL}
              defaultOutletUrl="airtime"
            >
              <Gap size={10} />

              <div>
                <SmartFilter
                  onFilter={(e: any) => {
                    setFilterValue(Util.filterStruct(e) as any);
                  }}
                  onSearchChange={(e: string) => {
                    setSearchQuery(e);
                  }}
                  onExport={() => {
                    toggleModal(`Export ${selectedTab.label}`);
                    triggerExport(true);
                  }}
                  pagination={{
                    currentPage: currentPage,
                    itemsPerPage: transactions.per_page as number,
                    totalItems: transactions.total,
                    onPageChange: function (page: number): void {
                      setCurrentPage(page);
                    },
                    activeFilter: "All Transactions",
                  }}
                  singleSelect
                  filters={[
                    {
                      label: "Status",
                      filterBy: "status",
                      options: [
                        {
                          value: BIZ_TRANSACTION_STATUS.PENDING.toString(),
                          label: "Pending",
                        },
                        {
                          value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                          label: "Processing",
                        },
                        {
                          value: BIZ_TRANSACTION_STATUS.FAILED.toString(),
                          label: "Failed",
                        },
                        {
                          value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(),
                          label: "Success",
                        },
                        {
                          value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                          label: "Reversed",
                        },
                      ],
                    },
                  ]}
                />
              </div>

              <OutletTab.Outlet />
            </OutletTab.Tabs>
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
