import React from "react";
import { RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatStatus } from "@/utils/helper/Helper";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { BillerStack, XStack } from "@/components/common/stacks";
import { icons } from "@/assets/icons";
import Copy from "@/components/common/copyCheck";
import { autoBillersLogo } from "@/constants/auto-billers-logo";

interface Defaults {
  name: string;
  email: string;
  amount: SN;
  provider: string;
  date: string;
  status: string;
}

export interface AirtimeDataProps extends Defaults {
  phoneNumber: string;
  reference: string;
}

interface MeterProps extends Defaults {
  meterNumber: string;
}

interface CableProps extends Defaults {
  cablePlan: string;
  cableNumber: string;
}

interface BettingProps extends Defaults {
  userId: string;
}

export class BillsTable {
  static AirtimeData = (props: AirtimeDataProps) => {
    const { email, name, amount, date, phoneNumber, provider, reference, status } = props;

    const biller = autoBillersLogo(provider);

    return (
      <RavenTableRow
        one={<AvatarWithTwinValue one={name} two={email} name={name} />}
        two={reference}
        three={<BillerStack biller={biller} provider={provider} />}
        four={amount}
        five={phoneNumber}
        six={date}
        seven={formatStatus(status)}
      />
    );
  };
  static Electricity = (props: MeterProps) => {
    const { amount, date, email, meterNumber, name, provider, status } = props;

    return (
      <RavenTableRow
        one={<AvatarWithTwinValue one={name} two={email} name={name} />}
        two={
          <XStack className="biller-row">
            {icons.atlas_bills_electricity}
            <p>{provider}</p>
          </XStack>
        }
        three={amount}
        four={meterNumber}
        five={date}
        six={formatStatus(status)}
      />
    );
  };
  static Cable = (props: CableProps) => {
    const { amount, date, email, cableNumber, cablePlan, name, provider, status } = props;

    return (
      <RavenTableRow
        one={<AvatarWithTwinValue one={name} two={email} name={name} />}
        two={
          <XStack className="biller-row">
            {icons.atlas_bills_cable}
            <p>{provider}</p>
          </XStack>
        }
        three={cablePlan}
        four={amount}
        five={cableNumber}
        six={date}
        seven={formatStatus(status)}
      />
    );
  };
  static Betting = (props: BettingProps) => {
    const { userId, amount, date, email, name, provider, status } = props;

    const biller = autoBillersLogo(provider);

    return (
      <RavenTableRow
        one={<AvatarWithTwinValue one={name} two={email} name={name} />}
        two={<BillerStack biller={biller} provider={provider} />}
        three={amount}
        four={
          <XStack gap={0.4} style={{ alignItems: "center" }}>
            <span>{userId}...</span>
            <Copy text={userId} tableCopy />
          </XStack>
        }
        five={date}
        six={formatStatus(status)}
      />
    );
  };
}
