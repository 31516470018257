import { formatDate } from "@/utils/helper/Helper";

export const ModalDayRange = [
  "All",
  "Today",
  "Last 7 Days",
  "Last 14 Days",
  "Last 30 Days",
  "Custom",
] as const;

export type ModalDayRangeType = (typeof ModalDayRange)[number];

export type DayRange = Exclude<ModalDayRangeType, "Custom">;

export type DateRange = {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
};

export function getNthDaysAgo(d: number) {
  const today = new Date(); // Get today's date
  const nthDay = new Date(today); // Create a new date object

  nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

  return nthDay;
}

export function addDays(date: Date | string, days: number) {
  // Function to add Days
  var result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
}

export function handleDateRangeFilter(str: DayRange): DateRange {
  const sevenDaysAgo = getNthDaysAgo(7);
  const fourteenDaysAgo = getNthDaysAgo(14);
  const thirtyDaysAgo = getNthDaysAgo(30);

  const formattedThirtyDaysAgo = formatDate(thirtyDaysAgo);
  const formattedSevenDaysAgo = formatDate(sevenDaysAgo);
  const formattedFourteenDaysAgo = formatDate(fourteenDaysAgo);

  const end_date = formatDate(addDays(new Date(), 1));

  switch (str) {
    case "All":
      return {
        start_date: formatDate(new Date("2022-06-01")),
        end_date,
      };

    case "Today":
      return {
        start_date: formatDate(new Date()),
        end_date,
      };

    case "Last 7 Days":
      return {
        start_date: formattedSevenDaysAgo,
        end_date,
      };

    case "Last 14 Days":
      return {
        start_date: formattedFourteenDaysAgo,
        end_date,
      };

    case "Last 30 Days":
      return {
        start_date: formattedThirtyDaysAgo,
        end_date,
      };
  }
}

type BaseComponent<Type extends string> = {
  type: Type;
  key: string;
  label: string;
  placeholder?: string;
  defaultValue?: string;
  errorKey?: string;
  required?: boolean;
  hidden?: boolean;
};

interface CheckBoxOrSelectInput extends BaseComponent<"checkbox" | "select"> {
  options: { label: SN; value: SN }[];
}

interface EmailOrStringInput extends BaseComponent<"email" | "text" | "account-number"> {}

type FilterParams = EmailOrStringInput | CheckBoxOrSelectInput;

type ROUTE = `/${string}`;
export interface PersonalExportModalProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  /** baseUrl: /support/user */
  route: ROUTE;
  /** not implemented but the intention is a url that would override the default route */
  overrideRoute?: string;
  filterParams: FilterParams[];
  btnText?: string;
  fileName: string;
}

export type SchemaErrorState = Partial<Record<string, string>>;
