import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { XStack } from "@/components/common/stacks";
import { disc01, icons } from "@/assets/icons";
import SmartFilter from "@/components/common/smartFilter";
import { useState } from "react";
import { LogExpenseModal } from "./components/logExpenseModal";
import Avatar from "@/components/common/avatar";
import { getThemePrimaryColor } from "@/utils/helpers";
import { useProductTheme } from "@/context/ProductTheme";

type Category =
  | "Salary and Wages"
  | "Office Expenses"
  | "Travel Expenses"
  | "Maintenance";

export const TopRightContent = ({ onClick }: { onClick(): void }) => {
  const { productTheme } = useProductTheme();

  return (
    <XStack className="bb-expenses__top_right">
      <button type="button">
        <XStack gap={1} className="bb-expenses__outline_button">
          <span>View Insights</span>
          <figure className="icon-right">{icons.arrow_narrow_up_right}</figure>
        </XStack>
      </button>
      <RavenButton
        onClick={onClick}
        color="black-light"
        style={{ backgroundColor: getThemePrimaryColor(productTheme) }}
      >
        Log Expense
      </RavenButton>
    </XStack>
  );
};

const assertCategory = (category: Category) => {
  switch (category) {
    case "Maintenance":
      return disc01.blue_fade;
    case "Office Expenses":
      return disc01.orange;
    case "Salary and Wages":
      return disc01.green;
    case "Travel Expenses":
      return disc01.purple;
  }
};

interface TableDummyData {
  category: Category;
  code: string;
  amount: string;
  description: string;
  logger: string;
  date: string;
}

const dummyData: TableDummyData[] = [
  {
    category: "Maintenance",
    code: "69247",
    amount: "₦50,000.00",
    description: "Salary and Wages",
    logger: "Femi Babalola",
    date: "5 Sep, 2023 ",
  },
  {
    category: "Office Expenses",
    code: "69247",
    amount: "₦250.00",
    description: "Office Expenses",
    logger: "Femi Babalola",
    date: "5 Sep, 2023 ",
  },
  {
    category: "Salary and Wages",
    code: "69247",
    amount: "₦20,000.00",
    description: "Payment for Salaries",
    logger: "Femi Babalola",
    date: "5 Sep, 2023 ",
  },
  {
    category: "Travel Expenses",
    code: "69247",
    amount: "₦25,000.00",
    description: "Office Expenses",
    logger: "Femi Babalola",
    date: "5 Sep, 2023 ",
  },
];

export const AtlasExpenses = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <DashboardLayout>
        <PageLayout
          pageTitle="Expenses"
          topRightContent={<TopRightContent onClick={() => setShowModal((c) => !c)} />}
        >
          <SmartFilter
            searchTitle="Search Expense..."
            pagination={{
              currentPage: 0,
              itemsPerPage: 0,
              totalItems: 0,
              onPageChange: (page: number) => {
                throw new Error("Function not implemented.");
              },
              activeFilter: "All Transactions",
            }}
          />
          <RavenTable
            headerList={[
              "CATEGORY",
              "CODE",
              "AMOUNT",
              "DESCRIPTION",
              "IMAGE",
              "LOGGED BY",
              "DATE LOGGED",
            ]}
          >
            {dummyData.map(({ amount, category, code, date, description, logger }, n) => (
              <RavenTableRow
                key={n}
                one={
                  <XStack gap={1}>
                    {assertCategory(category)} <span>{category}</span>
                  </XStack>
                }
                two={code}
                three={amount}
                four={description}
                five={
                  <XStack>
                    {icons.image_01}
                    {icons.download_01}
                  </XStack>
                }
                six={<Avatar name={logger} full />}
                seven={date}
              />
            ))}
          </RavenTable>
        </PageLayout>
      </DashboardLayout>
      <LogExpenseModal visible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};
