import Login from "../../pages/auth/login";
import AuthWelcome from "../../pages/auth/welcome";

export const auth_routes_group = [
  {
    path: "/login",
    element: Login,
  },
  {
    path: "/auth-welcome",
    element: AuthWelcome,
  },
];
