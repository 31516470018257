import { CURRENCY_MAPPER, CURRENCY_MAPPER_KEYS } from "@/constants";

export const formatWord = (text: string) =>
  text
    .replace(/\s/g, "") // remove whitespace
    .replace(/-/g, " ") // convert hyphens to whitespace
    .replace(/([A-Z])/g, (match) => ` ${match}`) // replace uppercase with space and word
    .replace(/([_])/g, (_) => ` `) // replace underscore with space
    .trim() // trim out excess whitespace
    .split(" ") // split words by space
    .map((word) => {
      if (word === "") return false; // if word is empty string return
      if (word?.length < 1) return word;
      return (word?.[0] ?? "").toUpperCase() + word.slice(1);
    })
    .filter(Boolean) // remove white space from inputs
    .join(" ");

export const capitalizeEachWord = (text: string): string => {
  if (text?.length > 0) {
    return text
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  } else {
    return "--";
  }
};

export const lowerCase = (str: string) => str.toLocaleLowerCase();
export const uppercase = (str: string) => str.toUpperCase();

export const replaceSymbolInText = (text: string) => {
  const keys = Object.keys(CURRENCY_MAPPER);

  let newStr = text ?? "";

  keys.forEach((key) => {
    if (text.includes(key)) {
      newStr = newStr.replaceAll(key, CURRENCY_MAPPER[key as CURRENCY_MAPPER_KEYS]);
    }
  });

  return newStr;
};

export function capitalizeFirstLetterOnly(str: string) {
  // Capitalize the first letter and concatenate it with the rest of the string
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase() ?? "--";
}
