import "./style/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { usePagination } from "@/hooks/usePagination";
import { formatNumberToCurrency } from "@/utils/helpers";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import EmptyScreen from "@/components/common/emptyScreen";
import { useDynamicQueryParams } from "@/hooks/useDynamicQueryParams";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { EmptyShell } from "@/components/common/shell-layouts";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import Txreference from "@/components/common/reference/TxRef";
import { CopyText } from "@/components/common/copyCheck";
import { FreezeOrUnfreezeCardModal } from "../../__internals__/combined-tables/cards/modals/modals";
import { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { useActionBar } from "@/hooks/useActionBar";
import { cn } from "@/utils/colorConvert";

const headerList = ["REFERENCE", "DESCRIPTION", "AMOUNT", "TRANSACTION DATE"];

export const PersonalSingleCardDetails = () => {
  const pagination = usePagination();
  const { card_id } = useDynamicQueryParams({ keys: "card_id" });
  const [showModal, setShowModal] = useState(false);

  const {
    data: cardDetails,
    isLoading,
    isFetching,
    refetch,
  } = personalReduxAPI.useGetVirtualCardDetailsQuery(card_id);
  const {
    data: transactions,
    isLoading: trxLoading,
    isFetching: trxFetching,
  } = personalReduxAPI.useGetVirtualCardTransactionsQuery({
    card_id,
  });
  const [showActionBar, setShowActionBar] = useState(true);
  const { ActionBar } = useActionBar({
    visible: cardDetails?.status === "inactive",
    msg: cn("This card is frozen"),
    onCancel: () => setShowActionBar(false),
    type: "warning",
  });
  const joinedLoaderDetails = isLoading || isFetching;
  const { RedactedLabel: DetailsRedactFn } = renderRedactedComponent(joinedLoaderDetails);
  const joinedLoaderTrx = trxLoading || trxFetching;

  const { RedactedTable: TrxRedactedTableFn } = renderRedactedComponent(joinedLoaderTrx);

  const { renderDynamicModal } = useDynamicModal(showModal);

  if (isLoading || !cardDetails)
    return (
      <EmptyShell pageTitle="Card Details">
        <EmptyScreen loading={joinedLoaderDetails} />
      </EmptyShell>
    );

  const isCardFreezed = () => cardDetails.status === "inactive";

  return (
    <>
      <DashboardLayout>
        {showActionBar ? <ActionBar /> : null}
        <PageLayout
          pageTitle="Card Details"
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "unset" }}
          childrenClassName="product-single-details-page"
        >
          <div className="single-user-details__top-wrap reusable-details-card-top">
            <div className="top-wrap__main-content">
              <div className="top-wrap__main-content--profile-img card-avatar-image personal-cards-page">
                {icons.business_cards}
              </div>

              <div className="top-wrap__main-content--user-data">
                <div className="user-data__main-content">
                  <MainContentCol>
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card name">
                        <p>{Util.safeText(cardDetails.card_name)}</p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Owner Email">
                        {cardDetails.email ? (
                          <ClickAbleEmail email={cardDetails.email} />
                        ) : (
                          "--"
                        )}
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Id">
                        {card_id ? (
                          <CopyText
                            textToCopy={Util.safeText(cardDetails.card_id)}
                            text={Util.safeText(cardDetails.card_id, 16)}
                          />
                        ) : (
                          "--"
                        )}
                      </MainContentCol.Item>
                    )}
                  </MainContentCol>
                  <MainContentCol>
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Balance">
                        <p>
                          {cardDetails.balance
                            ? formatNumberToCurrency(
                                cardDetails.balance,
                                cardDetails.currency
                              )
                            : "--"}
                        </p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Currency">
                        <p>{Util.safeText(cardDetails.currency)}</p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Skin">
                        <p>{Util.safeText(cardDetails.skin)}</p>
                      </MainContentCol.Item>
                    )}
                  </MainContentCol>
                  <MainContentCol>
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Type">
                        <p>{Util.safeText("Virtual Card")}</p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Brand">
                        <p>{Util.safeText(cardDetails.brand)}</p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Date Created">
                        <p>
                          {cardDetails.created_at
                            ? formatDateTime(cardDetails.created_at)
                            : "--"}
                        </p>
                      </MainContentCol.Item>
                    )}
                  </MainContentCol>
                  <MainContentCol>
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Pan">
                        <p>{Util.safeText(cardDetails.card_pan)}</p>
                      </MainContentCol.Item>
                    )}
                    {DetailsRedactFn(
                      <MainContentCol.Item title="Card Label">
                        <p>{Util.safeText(cardDetails.label)}</p>
                      </MainContentCol.Item>
                    )}
                  </MainContentCol>
                </div>
                <div className="user-data__account-summary">
                  <div className="top-wrap__actions">
                    {false && (
                      //TODO: Not available for now
                      <IconButton
                        borderColor="#FF0F00"
                        text="Destroy Card"
                        icon="slash_circle_01"
                        onClick={() => {}}
                      />
                    )}
                    <IconButton
                      text={` ${isCardFreezed() ? "Unfreeze" : "Freeze"} Card`}
                      borderColor="#EA872D"
                      icon="snowflakes"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-user-details__table-wrap">
            {!trxLoading && transactions && (
              <YStack>
                <SmartFilter
                  {...pagination}
                  hideExport
                  tableExport={{ data: transactions, fileName: "cards transactions" }}
                />
              </YStack>
            )}
            {trxLoading || !transactions ? (
              <>
                <EmptyScreen loading={joinedLoaderTrx} />
              </>
            ) : (
              <RavenTable headerList={headerList}>
                {transactions.map((transaction) => {
                  const {
                    amount,
                    direction,
                    currency,
                    narration,
                    trx_date,
                    tx_id,
                    // card_id,
                  } = transaction;

                  return (
                    <RavenTableRow
                      one={TrxRedactedTableFn(
                        <Txreference
                          direction={Util.safeText(direction)}
                          reference={Util.safeText(tx_id)}
                        />
                      )}
                      three={TrxRedactedTableFn(
                        trimLongString(Util.safeText(narration), 30)
                      )}
                      four={TrxRedactedTableFn(formatNumberToCurrency(amount, currency))}
                      five={TrxRedactedTableFn(formatDateTime(trx_date))}
                    />
                  );
                })}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      {renderDynamicModal(
        <FreezeOrUnfreezeCardModal
          onCancel={() => setShowModal(false)}
          refetch={refetch}
          selectedCard={{
            ctx: isCardFreezed() ? "unfreeze" : "unfreeze",
            card: cardDetails,
          }}
        />
      )}
    </>
  );
};
