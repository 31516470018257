import { RENDER_DYNAMIC_MODAL_ID } from "@/constants";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

const checkElement = (element: unknown): element is HTMLElement =>
  element instanceof HTMLElement;

// type AFunction = (()=> void)=> void

const getModalNode = () =>
  document.querySelector<HTMLDivElement>(`#${RENDER_DYNAMIC_MODAL_ID}`) ?? document.body;

/**Use to render a modal in any location, to create your own custom location pass in a ref or a querySelector*/
export function useDynamicModal<T>(stateToWatch: IsUncertain<T>) {
  const modalNode = getModalNode();

  type RefType = HTMLElement | HTMLDivElement | null;

  const renderDynamicModal = <T extends RefType>(
    Component: ReactNode,
    ref?: React.MutableRefObject<T> | RefType
  ) => {
    /** Shell node to check if node is html or react ref */
    const _node = checkElement(ref) ? ref : ref?.current;

    const node = _node ?? modalNode;

    return stateToWatch && createPortal(Component, node);
  };

  const safeConditionalModal = <State>(
    Component: ReactNode,
    state?: State
  ): ReactNode => {
    if (state) return state ? Component : null;
    return stateToWatch ? Component : null;
  };

  /** @deprecated totally unnecessary */
  const renderSafeModal = <State = T>(Component: ReactNode, state: State) => {
    return renderDynamicModal(safeConditionalModal(Component, state));
  };

  const renderAsSafeModal = (callback: (state: T) => ReactNode) => {
    if (stateToWatch) {
      return renderDynamicModal(callback(stateToWatch));
    }
  };

  return { renderDynamicModal, renderSafeModal, renderAsSafeModal };
}
