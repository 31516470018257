import { z } from "zod";
import {
  genericStringSchema,
  stringNumberSchema,
  emailSchema,
  createFileSchema,
  createSelectSchema,
  dateSchema,
  newAmountSchema,
  newDateSchema,
  accountNumberSchema,
} from "./schema";
import {
  handleValidationError,
  handleZodValidation,
  ValidationError,
  ZodExtension,
} from "./handlers";
import { invalidErrorMessage } from "./error-message";

/**
 * Validator uses zod + a collection of helper functions to create a helper utility method
 * see [zod](https://zod.dev/) docs for validator guide
 * @link https://zod.dev/
 */
export class Validator {
  static string = genericStringSchema;
  static number = stringNumberSchema;
  static email = emailSchema;
  static object = z.object;
  static array = z.array;
  static file = createFileSchema;
  static select = createSelectSchema;
  static date = dateSchema;
  static amount = newAmountSchema;
  static newDate = newDateSchema;
  static accountNumber = accountNumberSchema;

  // Methods
  static getErrorMessage = handleValidationError;
  static validatorFunc = handleZodValidation;

  static createError = <T extends ZodExtension>(_: T): ValidationError<T> | undefined => {
    return {};
  };

  // Refine methods
  static coerceStringToNumber = {
    method: (arg: string) => {
      if (!Number(arg)) return false;
      return true;
    },
    message: (fieldName: string) => ({ message: invalidErrorMessage(fieldName) }),
  };
}
