import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import { EmptyShell } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { GLOBAL_PER_PAGE } from "@/constants";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";

export const PersonalUsers = () => {
  const navigate = useNavigate();
  const pagination = usePagination();
  const { currentPage, searchQuery } = pagination;
  const { data, isLoading, isFetching } = personalReduxAPI.useGetAllUsersQuery({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    search: searchQuery,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );
  const joinedLoader = isLoading || isFetching;

  const redactedUtil = renderRedactedComponent(joinedLoader);
  const { RedactedTable, RedactedAvatar } = redactedUtil;

  if (isLoading || !data)
    return (
      <EmptyShell pageTitle="Users">
        <TransferEmptyScreen height="90vh" loading={isLoading} />
      </EmptyShell>
    );

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Users">
        <SmartFilter
          searchTitle="Search by email, first name or last name"
          {...SmartFilterProps}
          hideFilter
          hideExport
        />
        {data.users?.length > 0 ? (
          <>
            <div className="table">
              <RavenTable
                className="table__main"
                headerList={[
                  "FULLNAME",
                  "EMAIL ADDRESS",
                  "USERNAME",
                  "DATE JOINED",
                  "STATUS",
                  "  ",
                ]}
              >
                {data.users.map((record) => {
                  const { id, fname, lname, email, username, created_at, _blocked } =
                    record;

                  return (
                    <RavenTableRow
                      key={id}
                      one={RedactedAvatar(
                        <Avatar name={Util.join(fname).with(lname)} full />
                      )}
                      two={RedactedTable(email)}
                      three={RedactedTable(cleanWhiteSpace("@", username))}
                      four={RedactedTable(formatDateTime(created_at))}
                      five={RedactedTable(
                        formatStatus(detectStatus(_blocked, "personal-users-status"))
                      )}
                      six={RedactedTable(<span>{icons.arrow_circle_right_dynamic}</span>)}
                      onRowClick={() => {
                        navigate(`/personal-users/${email}`);
                      }}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </>
        ) : (
          <TransferEmptyScreen loading={joinedLoader} />
        )}
      </PageLayout>
    </DashboardLayout>
  );
};
