import { getQueryVariable } from "@/utils/helpers";
import { PersonalTransferInternalPage } from "./internal-transfer-page";
import { BankPage } from "./bank-transfer-page";

export const PersonalBankTransfersPage = () => {
  const selectedTab = getQueryVariable("type");

  const renderSelected = () => {
    switch (selectedTab) {
      case "bank":
        return <BankPage type="externalTransfer" />;
      case "internal":
        return <PersonalTransferInternalPage />;
      default:
        return <BankPage type="externalTransfer" />;
    }
  };

  return renderSelected();
};

export const PersonalTransferIssues = () => {
  return <BankPage type="withdrawalIssues" />;
};

export const PersonalStalledTransferIssues = () => {
  return <BankPage type="stalledWithdrawals" />;
};
