import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Txreference from "@/components/common/reference/TxRef";
import Dropdown from "@/components/common/dropdown";
import {
  IconVault,
  formatDateTime,
  formatNumWithComma,
  formatStatus,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import { DashboardInfoCard } from "@/components/common/dashboardInfo";
import "../../styles/index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { formatNumberToCurrency, getQueryVariable } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
const BusinessPayrollDetails = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });

  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});

  const biz = getQueryVariable("biz");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { payrolls, loading, isDisbursementLoading, payroll_trx } = useSelector(
    (state: RootState) => state.businessPayroll
  );

  const rolls = payrolls.data ? payrolls.data[0] : ([] as unknown as PayrollModel) ?? [];
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const infoCards = [
    {
      title: "Total Salary Amount",
      value: formatNumberToCurrency(rolls?.net_amount ?? 0, "NGN"),
    },
    {
      title: "Total Deductions",
      value: formatNumberToCurrency(calculateTotalDeductions(rolls ?? 0), "NGN"),
    },
    {
      title: "Total Overdfrafts",
      value: formatNumberToCurrency(rolls?.overdraft_deductions ?? 0, "NGN"),
    },
    { title: "Date Created", value: formatDateTime(rolls?.created_at) },
    { title: "Total Employees", value: Util.safeValue(rolls?.recipients) },
    { title: "Status", isStatus: true, value: Util.safeValue(rolls?.status) },
  ];

  // overdraft_deductions + employer_deductions + paye + nhf + pension

  function calculateTotalDeductions(arg: typeof rolls) {
    const res =
      Number(arg?.overdraft_deductions) +
      Number(arg?.employer_deductions) +
      Number(arg?.paye) +
      Number(arg?.nhf) +
      Number(arg?.pension);

    return res;
  }

  useEffect(() => {
    dispatch(
      BugissApi.business.getPayrolls({
        business_email: biz,
      })
    );
  }, [biz, dispatch]);

  useEffect(() => {
    if (typeof rolls?.business_email === "string" && biz) {
      dispatch(
        BugissApi.business.getDisbursements({
          business_email: biz,
          current_page: currentPage,
          search_term: searchQuery,
          disburse_reference: rolls.reference,
          ...filterValue,
        })
      );
    }
  }, [rolls, biz, searchQuery, filterValue, currentPage]);

  const disbursement =
    payroll_trx?.data ?? ([] as unknown as PayrollDisbursementModel[]) ?? [];

  return (
    <>
      <DashboardLayout>
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "transparent", width: "100%", padding: 0 }}
          pageTitle="Payroll Details"
        >
          <div className="bb-payroll-details-top">
            <div className="bb-payroll-owner">
              <Avatar name={Util.safeValue(rolls?.business_name)} />
              <div className="payroll-owner__name">
                <h6>{Util.safeValue(rolls?.business_name)}</h6>
                <p>{Util.safeValue(rolls?.business_email)}</p>
              </div>
            </div>
            <DashboardInfoCard.Wrapper columns={4} marginTop={2.4}>
              {infoCards.map((ele) => (
                <DashboardInfoCard loading={loading} {...ele} key={ele.title} />
              ))}
            </DashboardInfoCard.Wrapper>
          </div>

          <div className="bb-payroll-table">
            <SmartFilter
              onSearchChange={(e: string) => setSearchQuery(e)}
              onFilter={(e: any) => {
                setFilterValue(Util.filterStruct(e));
              }}
              pagination={{
                currentPage: currentPage,
                itemsPerPage: Number(payroll_trx?.per_page),
                totalItems: payroll_trx?.total,
                onPageChange: function (page: number): void {
                  setCurrentPage(page);
                },
                activeFilter: "All Employees",
              }}
              searchTitle="Search Employees..."
            />

            {(!isRedacting && loading) || disbursement?.length === 0 || !disbursement ? (
              <EmptyScreen loading={loading || isDisbursementLoading} />
            ) : (
              <div className={cn("bb-trx-table", isDarkMode && "pb-trx-table-dark")}>
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={[
                    "Employee Name",
                    "Bank",
                    "Account No",
                    "Salary",
                    "Deduction",
                    "Overdraft",
                    "Action",
                  ]}
                >
                  {disbursement.map((data, index) => (
                    <RavenTableRow
                      key={index}
                      one={
                        isDisbursementLoading ? (
                          <Redacted.AvatarWithValue />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1.2rem",
                            }}
                          >
                            <Avatar name={data.business} />
                            <TwinValue one={data.account_name} two={data.business} copy />
                          </div>
                        )
                      }
                      two={
                        isDisbursementLoading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(data.bank)
                        )
                      }
                      three={
                        isDisbursementLoading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(data.account_number)
                        )
                      }
                      four={
                        isDisbursementLoading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data?.net_amount ?? 0, "NGN")
                        )
                      }
                      five={
                        isDisbursementLoading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data.employer_dedcutions, "NGN")
                        )
                      }
                      six={
                        isDisbursementLoading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data?.overdraft_deductions ?? 0, "NGN")
                        )
                      }
                      seven={
                        isDisbursementLoading ? (
                          <Redacted.Avatar />
                        ) : (
                          <ActionDrop
                            visit
                            onVisitClick={() => {
                              navigate(`${data.employee}?biz=${data.business}`);
                            }}
                          />
                        )
                      }
                    />
                  ))}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPayrollDetails;
