import "./style/index.css";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { icons } from "../../../assets/icons";
import {
  RavenButton,
  RavenCheckBox,
  RavenInputField,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { formatDate } from "../../../utils/helper/Helper";
import useDebounce from "../../../hooks/UseDebounce";
import Pagination, { PaginationProps } from "../pagination";
import { toggleRedact } from "@/redux/actions/actions";
import { useProductColor } from "@/context/ProductTheme";
import { CustomFilter, CustomFilterFilters, Filters } from "./custom-filter";
import { TypeIs, filterAPIQueryParams, isEmptyObject } from "@/utils/helpers";
import { Validator } from "@/utils/validator";
import { Util } from "@/utils/utility";
import { CSVLink } from "react-csv";
import { Column } from "../stacks";
import FilterChip from "../filterChip";

type FilterDateRange = {
  startDate: string;
  endDate: string;
  filterValue: string;
  filterBy?: string;
};

const EmailSchema = Validator.email("Email");

export type FilterParams<T extends SN> = Partial<FilterDateRange> &
  Partial<Filters<T>> & {
    filterValue: string;
  };

export type OnFilter<T extends SN> = (filter: FilterParams<T>) => void;

type ActiveFilter = {
  type: { label: string; value: string }[];
  region?: string;
  days: string;
};

type FilterProp = {
  label: string;
  filterBy?: string;
  options: {
    value: string;
    label:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | null
      | undefined;
  }[];
}[];

interface SmartFilterProps<T extends SN> {
  typeList?: any[];
  pagination?: PaginationProps;
  page?: string;
  defaultFilterBy?: string;
  searchTitle?: string;
  simple?: boolean;
  hideExport?: boolean;
  hideFilter?: boolean;
  redact?: Function;
  onSearchChange?(query: string): void;
  filters?: FilterProp;
  onFilter?: OnFilter<T>;
  onExport?: MouseEventHandler<HTMLParagraphElement> | undefined;
  value?: {
    type: { label: string; value: string }[];
    days: string;
  };
  alternateTitle?: string;
  dynamicNode?: React.ReactNode;
  searchQuery?: string;
  customFilter?: {
    filters: CustomFilterFilters<T>[];
  };
  searchIsEmailOnly?: boolean;
  hideDateFilter?: boolean;
  forceControlledQuery?: boolean;
  /**
   * This applies to addition filters passed via filters
   */
  singleSelect?: boolean;
  tableExport?: {
    data: any[];
    header?: {
      label: string;
      key: string;
    }[];
    fileName?: string;
  };
}

const SmartFilter = <T extends SN>(props: SmartFilterProps<T>) => {
  const {
    typeList,
    onFilter,
    simple,
    defaultFilterBy,
    filters,
    searchTitle,
    hideExport,
    hideFilter,
    onExport,
    onSearchChange,
    alternateTitle,
    pagination,
    dynamicNode,
    searchQuery = "",
    customFilter,
    searchIsEmailOnly,
    forceControlledQuery,
    tableExport,
    singleSelect,
    hideDateFilter,
  } = props;

  const defaultDateParam = {
    startDate: "2022-06-01",
    endDate: formatDate(addDays(new Date(), 1)),
  };

  type Params = Filters<T>;

  const initialFilterState = Util.safeArray(customFilter?.filters).reduce((acc, curr) => {
    if (curr.defaultValue) {
      const key = curr?.key ?? curr.label;
      return { ...acc, [key]: curr.defaultValue };
    }
    return { ...acc };
  }, {});

  const [filterArgs, setFilterArgs] = useState<Params>(TypeIs.any(initialFilterState));
  const [activeFilter, setActiveFilter] = useState<ActiveFilter>({ type: [], days: "" });
  const [activeTab, setActiveTab] = useState("date");
  const [showFilter, setShowFilter] = useState(false);
  const [q, setQ] = useState(searchQuery);
  const [stateChange, setStatChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<Partial<FilterDateRange>>({
    startDate: "2022-06-01",
    endDate: formatDate(addDays(new Date(), 1)),
    filterValue: undefined,
    filterBy: defaultFilterBy || "type",
  });
  const { colorVariable } = useProductColor();
  const dispatch = useDispatch<AppDispatch>();

  function isFilter(s: string) {
    if (activeTab === "date" && s === activeFilter.days) {
      return true;
    } else
      return (
        activeFilter.type &&
        activeFilter.type.some((item: { value: string }) => item.value === s)
      );
  }

  const [dateFilter, setDateFilter] = useState<{
    to: Date | any;
    from: Date | any;
  }>({
    from: "2022-06-01",
    to: formatDate(addDays(new Date(), 1)),
  });

  function setFilter(e: string) {
    setStatChange(!stateChange);
    if (activeTab === "date") {
      setActiveFilter({
        ...activeFilter,
        days: e,
      });
    } else {
      // Parse the value as an object
      const parsedValue = JSON.parse(e);


      // Check if the value is already in the type array
      const isValueInTypeArray = activeFilter.type.some(
        (item) => item.value === parsedValue.value
      );

      

      if (singleSelect) {
        // Unselect others and select only one
        if(isValueInTypeArray){
          setActiveFilter({
            ...activeFilter,
            type: activeFilter.type.filter((item) => item.value !== parsedValue.value),
          });
        } else {
          setActiveFilter({
            ...activeFilter,
            type: [parsedValue], // Set the selected value as the only value in the array
          });
        }
    
      } else if (isValueInTypeArray) {
        // If the value is in the array, remove it
        setActiveFilter({
          ...activeFilter,
          type: activeFilter.type.filter((item) => item.value !== parsedValue.value),
        });
      } else {
        // If the value is not in the array, add it
        setActiveFilter({
          ...activeFilter,
          type: [...activeFilter.type, parsedValue],
        });
      }
    }
  }

  //get the last 7th Day
  function getNthDaysAgo(d: number) {
    const today = new Date(); // Get today's date
    const nthDay = new Date(today); // Create a new date object

    nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

    return nthDay;
  }


  function handleDateFilter(str?: string) {
    const seventhDayAgo = getNthDaysAgo(7);
    const last14Days = getNthDaysAgo(14);
    const last30Days = getNthDaysAgo(30);

    const formated30days = formatDate(last30Days);
    const formated14days = formatDate(last14Days);
    const formated7days = formatDate(seventhDayAgo);

    setDateRange({
      ...activeFilter,
      ...dateRange,
      startDate:
        str === "Today"
          ? formatDate(new Date())
          : str === "All"
          ? "2022-06-01"
          : str === "Last 7 Days"
          ? formated7days
          : str === "Last 14 Days"
          ? formated14days
          : str === "Last 30 Days"
          ? formated30days
          : dateFilter.from,
      endDate:
        str === "Today"
          ? formatDate(addDays(new Date(), 1))
          : str === "All"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 7 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 14 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 30 Days"
          ? formatDate(addDays(new Date(), 1))
          : dateFilter.to,
    });
  }

  function formatQuery(filters: any) {
    let p: string = "";
    // eslint-disable-next-line array-callback-return
    activeFilter?.type.map((d: any, i: number) => {
      if (activeFilter.type?.length !== i + 1) {
        p = p + d.value + ",";
      } else {
        p = p + d.value;
      }
    });

    return p;
  }

  // increment date function
  function addDays(date: Date | string, days: number) {
    // Function to add Days
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }

  async function reqFilter() {
    let resp: any;
    setLoading(true);

    const filterParams = filterAPIQueryParams(TypeIs.any(filterArgs));

    if (
      activeFilter.days?.length > 0 ||
      activeFilter.type?.length > 0 ||
      !isEmptyObject(filterArgs)
    ) {
      if (onFilter) {
        onFilter({
          ...dateRange,
          filterValue: formatQuery(activeFilter?.type),
          ...filterParams,
        });
      }
    }

    if (resp?.payload?.status === "success") {
      setLoading(false);
      setShowFilter(false);
    } else {
      setLoading(false);
    }

    setLoading(false);
    setShowFilter(false);
  }

  // handle search
  const debouncedSearchTerm = useDebounce(q, 1000);

  useEffect(() => {
    let isMount = true;

    /** Check if query is a valid email */
    const isEmail = EmailSchema.safeParse(q).success;

    if (isMount && debouncedSearchTerm?.length >= 2) {
      if (onSearchChange) {
        // call onSearchChange if its a valid email
        if (searchIsEmailOnly) isEmail && onSearchChange(q);
        else onSearchChange(q);
      }
    }

    if (isMount && debouncedSearchTerm?.length < 1) {
      if (onSearchChange) {
        if (searchIsEmailOnly) isEmail && onSearchChange(q);
        else onSearchChange(q);
      }
    }

    /** If query is empty */
    if (!Boolean(q) && onSearchChange) onSearchChange("");

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onRedact = () => dispatch(toggleRedact(true));

  const handleSearchChange = (value: string) => {
    setQ(value);
    onRedact();
  };

  //Manually forcing searchquery to change
  useEffect(() => {
    if (forceControlledQuery) {
      setQ(searchQuery);
    }
  }, [searchQuery, forceControlledQuery]);
  const filterParams = filterAPIQueryParams(TypeIs.any(filterArgs));

  const filterChiVal = {
    ...dateRange,
    filterValue: activeFilter?.type,
    ...filterParams,
  };

  const showChip =
    activeFilter?.type?.length > 0 ||
    (activeFilter.days?.length > 0 && activeFilter.days !== 'All') ||
    !isEmptyObject(filterArgs);


  return (
    <Column gap={0}>
      <div className="smartfilter">
        <div className="top-bar__search">
          {alternateTitle ? (
            <p className="sm-alternate-title">{alternateTitle}</p>
          ) : (
            <form onSubmit={(e) => e.preventDefault()} action="" autoComplete="off">
              <RavenInputField
                placeholder={
                  searchTitle || "Search by email, refs, first or last name..."
                }
                type="search"
                value={q}
                onChange={(e: InputOnChange) => {
                  const { value } = e.target;
                  handleSearchChange(value);
                }}
                color={colorVariable}
              />
            </form>
          )}
        </div>

        <div className="top-bar__filter-export">
          {!hideFilter && (
            <div
              onClick={() => setShowFilter(!showFilter)}
              className="filter-export__filter-btn"
            >
              {!simple && <p>Filter</p>} <figure>{icons.filter}</figure>
            </div>
          )}
          {dynamicNode && dynamicNode}
          {/* Section encompassing filter and it controls */}
          {showFilter && (
            <div className={`filter-export__drop-down filter-export__drop-down--open `}>
              <div className="filter-export__drop-down-wrap">
                <div className="dropdown__header">
                  <p>Filter</p>

                  <p
                    onClick={() => {
                      setActiveFilter({
                        days: "",
                        type: [],
                      });
                      setFilterArgs(TypeIs.any(initialFilterState));
                      // TODO: If filtering ever fails, check here
                      onFilter?.(
                        TypeIs.any({
                          ...defaultDateParam,
                          ...initialFilterState,
                        })
                      );

                      setShowFilter(false);
                    }}
                  >
                    {(activeFilter?.type?.length > 0 ||
                      activeFilter.days?.length > 0 ||
                      !isEmptyObject(filterArgs)) && <>Clear All</>}
                  </p>
                </div>
                {customFilter ? (
                  <CustomFilter
                    {...customFilter}
                    setActiveTab={(tab) => setActiveTab(TypeIs.any(tab ?? "empty"))}
                    activeTab={activeTab}
                    onFilter={setFilterArgs}
                    selectedFilters={filterArgs}
                  />
                ) : null}
                {/* days */}
                {!hideDateFilter && (
                  <>
                    <div
                      onClick={() => {
                        activeTab === "date"
                          ? setActiveTab("empty")
                          : setActiveTab("date");
                      }}
                      className="dropdown__date-title"
                    >
                      <p>Date</p>

                      <div
                        onClick={() => {
                          activeTab === "type"
                            ? setActiveTab("type")
                            : activeTab === "date"
                            ? setActiveTab("date")
                            : setActiveTab("empty");
                          setActiveFilter({
                            ...activeFilter,
                            days: "",
                          });
                        }}
                        className={`dropdown__tags ${activeFilter?.days && "show"}`}
                      >
                        {" "}
                        <p>{activeFilter?.days}</p>
                        <figure>{icons.x_circle}</figure>
                      </div>

                      <div className={`tag-close-icon ${activeFilter?.days && "hide"}`}>
                        <figure>{icons.chevron_down}</figure>
                      </div>
                    </div>

                    <div
                      className={`drop-down__item ${activeTab === "date" && "active"}`}
                    >
                      <span
                        onClick={() => {
                          setFilter("All");
                          handleDateFilter("All");
                        }}
                        className={`${isFilter("All") && "active"}`}
                      >
                        {isFilter("All") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>All</p>
                      </span>
                      <span
                        onClick={() => {
                          setFilter("Today");
                          handleDateFilter("Today");
                        }}
                        className={`${isFilter("Today") && "active"}`}
                      >
                        {isFilter("Today") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>Today</p>
                      </span>
                      <span
                        onClick={() => {
                          setFilter("Last 7 Days");
                          handleDateFilter("Last 7 Days");
                        }}
                        className={`${isFilter("Last 7 Days") && "active"} `}
                      >
                        {isFilter("Last 7 Days") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>Last 7 Days</p>
                      </span>
                      <span
                        onClick={() => {
                          setFilter("Last 30 Days");
                          handleDateFilter("Last 30 Days");
                        }}
                        className={`${isFilter("Last 30 Days") && "active"}`}
                      >
                        {isFilter("Last 30 Days") ? (
                          <figure>{icons.radio_check}</figure>
                        ) : (
                          <figure>{icons.radio_unchecked}</figure>
                        )}
                        <p>Last 30 Days</p>
                      </span>
                      <span
                        onClick={() => {
                          setFilter("Specific Day");
                          handleDateFilter("Specific Day");
                        }}
                        className={`custom-date ${isFilter("Specific Day") && "active "}`}
                      >
                        <div className="custom-date__select">
                          {isFilter("Specific Day") ? (
                            <figure>{icons.radio_check}</figure>
                          ) : (
                            <figure>{icons.radio_unchecked}</figure>
                          )}
                          <p>Specific Date</p>
                        </div>

                        <div
                          className={`custom-date__form-wrap ${
                            isFilter("Specific Day") && "show"
                          }`}
                        >
                          <RavenInputField
                            color={colorVariable}
                            label="date"
                            onChange={(e: Date) => {
                              setDateFilter({
                                from: formatDate(new Date(e)),
                                to: formatDate(addDays(new Date(), 1)),
                              });
                              setDateRange({
                                startDate: formatDate(new Date(e)),
                                endDate: formatDate(addDays(new Date(e), 1)),
                                filterValue: undefined,
                              });
                            }}
                            placeholder="Select a date"
                            type="date"
                          />
                        </div>
                      </span>
                      <span
                        onClick={() => {
                          setFilter("Custom");
                          handleDateFilter("Custom");
                        }}
                        className={`custom-date ${isFilter("Custom") && "active "}`}
                      >
                        <div className="custom-date__select">
                          {isFilter("Custom") ? (
                            <figure>{icons.radio_check}</figure>
                          ) : (
                            <figure>{icons.radio_unchecked}</figure>
                          )}
                          <p>Custom</p>
                        </div>

                        <div
                          className={`custom-date__form-wrap ${
                            isFilter("Custom") && "show"
                          }`}
                        >
                          <RavenInputField
                            color={colorVariable}
                            label="Start Date"
                            placeholder="Select a date"
                            type="date"
                            onChange={(e: Date) => {
                              setDateFilter({
                                ...dateFilter,
                                from: formatDate(new Date(e)),
                              });
                              setDateRange({
                                ...dateRange,
                                startDate: formatDate(new Date(e)),
                              });
                            }}
                          />
                          <RavenInputField
                            color={colorVariable}
                            label="End Date"
                            placeholder="Select a date"
                            onChange={(e: Date) => {
                              return (
                                setDateFilter({
                                  ...dateFilter,
                                  to: formatDate(new Date(e)),
                                }),
                                setDateRange({
                                  ...dateRange,
                                  endDate: formatDate(new Date(e)),
                                })
                              );
                            }}
                            type="date"
                          />
                        </div>
                      </span>
                    </div>
                  </>
                )}
                {/* end days */}

                {typeList && (
                  <>
                    <div
                      onClick={() => {
                        activeTab === "type"
                          ? setActiveTab("empty")
                          : setActiveTab("type");
                      }}
                      className="dropdown__date-title"
                    >
                      <p>Type</p>

                      <div
                        onClick={() => {
                          activeTab === "type"
                            ? setActiveTab("type")
                            : activeTab === "date"
                            ? setActiveTab("date")
                            : setActiveTab("empty");
                          setActiveFilter({
                            ...activeFilter,
                            type: [],
                          });
                        }}
                        className={`dropdown__tags ${
                          activeFilter?.type?.length > 0 && "show"
                        }`}
                      >
                        {activeFilter.type?.length === 1 ? (
                          <p>{activeFilter?.type[0]?.label}</p>
                        ) : (
                          <p>{activeFilter?.type?.length + " Selected"}</p>
                        )}

                        <figure>{icons.x_circle}</figure>
                      </div>

                      <div
                        className={`tag-close-icon ${
                          activeFilter?.type?.length > 0 && "hide"
                        }`}
                      >
                        <figure>{icons.chevron_down}</figure>
                      </div>
                    </div>

                    {typeList &&
                      typeList.map((chi, idx) => {
                        return (
                          <div
                            className={`drop-down__item ${
                              activeTab === "type" && "active"
                            }`}
                          >
                            <span
                              onClick={() => setFilter(JSON.stringify(chi))}
                              className={`${isFilter(chi.value) && "active"}`}
                            >
                              <RavenCheckBox
                                onChange={(e) => {
                                  handleDateFilter(chi.label);
                                  setFilter(JSON.stringify(chi));
                                }}
                                checked={isFilter(chi.value)}
                                color={colorVariable}
                                id={idx}
                              />

                              <p>{chi.label}</p>
                            </span>
                          </div>
                        );
                      })}
                  </>
                )}
                {filters &&
                  filters.map((chi, idx) => {
                    const evalStatus = activeFilter.type.some((filter) =>
                      chi.options.some((option) =>
                        filter.label.includes(option.label as any)
                      )
                    );


                    return (
                      <React.Fragment key={chi.label}>
                        <div
                          key={idx}
                          onClick={() => {
                            if (chi?.filterBy) {
                              setDateRange({
                                ...dateRange,
                                filterBy: chi.filterBy,
                              });
                            }
                            activeTab === chi.label
                              ? setActiveTab("empty")
                              : setActiveTab(chi.label);
                          }}
                          className="dropdown__date-title"
                        >
                          <p>{chi?.label}</p>

                          {evalStatus && (
                            <div
                              onClick={() => {
                                activeTab === chi.label
                                  ? setActiveTab(chi.label)
                                  : activeTab === "date"
                                  ? setActiveTab("date")
                                  : setActiveTab("empty");
                                setActiveFilter({
                                  ...activeFilter,
                                  type: [],
                                });
                              }}
                              className={`dropdown__tags ${
                                activeFilter?.type.length > 0 && "show"
                              }`}
                            >
                              {activeFilter.type.length === 1 ? (
                                <p>{activeFilter?.type[0]?.label}</p>
                              ) : (
                                <p>{activeFilter?.type.length + " Selected"}</p>
                              )}

                              <figure>{icons.x_circle}</figure>
                            </div>
                          )}

                          <div className={`tag-close-icon ${evalStatus && "hide"}`}>
                            <figure>{icons.chevron_down}</figure>
                          </div>
                        </div>

                        {chi?.options &&
                          chi?.options?.map((item, idx: number) => {

                            const state = activeFilter.type.filter((filter) =>
                              filter.label !== item.label
                            );
        

                            return (
                              <div
                                key={idx}
                                className={`drop-down__item ${
                                  activeTab === chi?.label && "active"
                                }`}
                              >
                                <span
                                  onClick={() => {
                                    setFilter(JSON.stringify(item));
                                    // handleDateFilter(item.label as string);
                                  }}
                                  className={`${isFilter(item.value) && "active"}`}
                                >
                                  <RavenCheckBox
                                    onChange={(e) => {
                                      // handleDateFilter(item.label as string);
                                      setFilter(JSON.stringify(state));
                                    }}
                                    checked={isFilter(item.value)}
                                    color={colorVariable}
                                    id={idx}
                                    key={idx}
                                  />

                                  <p>{item.label}</p>
                                </span>
                              </div>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}

                {/*  */}
              </div>

              {/* apply filter button */}
              <div className="dropdown__apply-filter-btn">
                <RavenButton
                  label="Apply Filter"
                  color={colorVariable}
                  onClick={reqFilter}
                  loading={loading}
                />
              </div>
              {/* apply filter ends here */}
            </div>
          )}
          {/* Outer close wrap for filters */}
          <div
            onClick={() => setShowFilter(false)}
            className={`filter-export__drop-down-closer ${
              showFilter && "filter-export__drop-down-closer--opened"
            }`}
          ></div>
          {/* End of Section encompassing filter and it controls */}
          {!hideExport && (
            <div
              onClick={onExport}
              // onClick={() => onView(!showFilter)}
              className="filter-export__filter-btn"
            >
              {!simple && <p>Export</p>} <figure>{icons.export}</figure>
            </div>
          )}
          {tableExport && (
            <CSVLink
              className="export_table_content_wrap tooltip-hover-wrap grey-bg"
              filename={tableExport.fileName ?? "Table Record"}
              data={tableExport.data}
              headers={tableExport.header}
            >
              <RavenToolTip
                color="black-light"
                text="Export Table Content"
                position="right"
              />
              <figure className="img-box">{icons.export}</figure>
            </CSVLink>
          )}
          {pagination && (
            <Pagination
              currentPage={pagination.currentPage}
              itemsPerPage={pagination?.itemsPerPage}
              totalItems={pagination?.totalItems}
              onPageChange={(e) => {
                pagination?.onPageChange(e);
                onRedact();
              }}
            />
          )}
        </div>
      </div>

      <FilterChip
        activeFilter={showChip ? filterChiVal.filterValue : false}
        isDefaultDate={activeFilter.days === 'All'}
        onDelete={(e) => {


          if(e.type==='date'){
            setActiveFilter({
              ...activeFilter,
              days: "All",
            });
            handleDateFilter("All");
            // setFilterArgs(TypeIs.any(initialFilterState));
          } else {
            setFilter(JSON.stringify({label: e.label, value: e.value}))
          }
        }}
        dateFilter={
          showChip
            ? {
                to: new Date(filterChiVal.endDate),
                from: new Date(filterChiVal.startDate),
              }
            : { to: "", from: "" }
        }
        onClear={() => {
          setActiveFilter({
            days: "",
            type: [],
          });
          setFilterArgs(TypeIs.any(initialFilterState));
          // TODO: If filtering ever fails, check here
          onFilter?.(
            TypeIs.any({
              ...defaultDateParam,
              ...initialFilterState,
            })
          );

          setShowFilter(false);
        }}
      />
    </Column>
  );
};

export default SmartFilter;
