import env from "@/env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BusinessExpenseAPI } from "./expenses";
import { BusinessCardsAPI } from "./cards";
import { BusinessTransferAPI } from "./transfers";
import { CreateBuilderContext } from "..";
import { BusinessBizApi } from "./business";
import { BusinessAuditTrailAPI } from "./auditTrail";
import { BusinessGlobalAccountAPI } from "./globalAccount";
import { BusinessOverviewAPI } from "./overview";
import { BusinessHniAPI } from "./hni";

const BusinessReduxTags = [
  "BusinessAPI.getAllTransferIssues",
  "BusinessAPI.getAllDeductedDeposits",
  "BusinessAPI.getAllTransferSummary",
  "BusinessAPI.getAllSubAccounts",
] as const;

type TagTypes = (typeof BusinessReduxTags)[number];

export const businessReduxAPI = createApi({
  reducerPath: "business-api",
  baseQuery: fetchBaseQuery({ baseUrl: env.business_api }),
  tagTypes: BusinessReduxTags,
  endpoints: (builder) => ({
    ...BusinessExpenseAPI(builder),
    ...BusinessCardsAPI(builder),
    ...BusinessTransferAPI(builder),
    ...BusinessBizApi(builder),
    ...BusinessAuditTrailAPI(builder),
    ...BusinessGlobalAccountAPI(builder),
    ...BusinessOverviewAPI(builder),
    ...BusinessHniAPI(builder),
  }),
});

export type BusinessBuilder = CreateBuilderContext<TagTypes, "business-api">;
