import "./style/index.css";
import { ActionModalContentWithImage } from "@/apps/personal/compliance/pages/modals/limit-request";
import CardImage from "./purple-card.png";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { Util } from "@/utils/utility";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import {
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
  reactSelectStyle,
} from "@/utils/helper/Helper";
import { getPhysicalCardsRequestStatus } from "../../utils";
import { Input } from "@/components/common/input";
import { useState } from "react";
import { isObjectWithValidValues, TypeIs } from "@/utils/helpers";
import { Validator } from "@/utils/validator";
import { z } from "zod";
import { PersonalAPIService } from "@/redux/slices/personal";
import { PB_CARD_REQUEST_STATUS } from "../tables";
import { useMutation } from "@/hooks/useMutation";
import { toast } from "@ravenpay/raven-bank-ui";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { iife } from "@/utils/general";

type Props = BaseModalProps<{ data: PbPhysicalCardRequest; refetch: RefetchFn }>;

export const PBPhysicalViewCardRequest = (props: Props) => {
  const { onCancel, data } = props;
  const [assignCard, setAssignCard] = useState(false);

  const isHome = iife(() => {
    if (data.delivery_method === "pickup") {
      return {};
    }

    return {
      state: Util.safeText(data.state),
      LGA: Util.safeText(data.lga),
    };
  });

  const view = {
    name: Util.safeText(data.name),
    email: <ClickAbleEmail email={data.email} />,
    deliver_method: Util.safeText(data.delivery_method),
    ...isHome,
    address: Util.safeText(data.address),
    date_requested: formatDateTime(data.created_at),
    status: formatStatus(getPhysicalCardsRequestStatus(data.status)),
    // TODO: add card masked pan
    // card_pan: Util.safeText(data.card_brand),
  };

  const isAssigned = data.status === PB_CARD_REQUEST_STATUS.ASSIGNED;

  if (assignCard) return <AssignCard {...props} />;

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      big
      onClick={() => {
        if (!isAssigned) return setAssignCard((c) => !c);
        else onCancel();
      }}
      actionText={!isAssigned ? "Assign Card Data" : "Close"}
      btnColor="var(--primary-product-color)"
      className={cn(GENERAL_CLASSES.BIG_MODAL, GENERAL_CLASSES.EXPAND_MODAL_BUTTON)}
      title="Physical Card Request"
      hideCancel
    >
      <ActionModalContentWithImage
        badgeText="Card Image"
        image={CardImage}
        content={Object.entries(view).map(([key, value]) => ({
          label: formatWord(key),
          value,
        }))}
        className="override-acm-modal"
      />
    </ActionModal>
  );
};

const fields = [
  {
    label: "Wallet Id",
    placeholder: "Wallet Id",
    name: "wallet_id",
    type: "text",
    className: "card__number two-column",
    target: "event",
    maxLength: 16,
  },
  {
    label: "Card PAN *",
    placeholder: "Enter Card PAN",
    name: "card_pan",
    type: "card_number",
    className: "card__number two-column",
    target: "event",
    maxLength: 16,
  },
  {
    label: "CVV *",
    placeholder: "E.g 482",
    name: "cvv",
    className: "one-column",
    type: "card-cvv",
    target: "data",
  },
  {
    label: "Expiry Date *",
    placeholder: "MM / YY",
    name: "expiry_date",
    className: "one-column",
    type: "card-exp",
    target: "data",
  },
  {
    label: "Default PIN *",
    placeholder: "Enter Default PIN",
    name: "default_pin",
    type: "account-number",
    maxLength: 4,
    target: "event",
    className: "two-column",
  },
] as const;

const cardColors = ["green", "purple", "black"];

const AssignCardSchema = Validator.object({
  card_pan: Validator.string("Card Pan", 16, 16).refine(
    Validator.coerceStringToNumber.method,
    Validator.coerceStringToNumber.message("Card Pan")
  ),
  cvv: Validator.string("CVV", 3, 3).refine(
    Validator.coerceStringToNumber.method,
    Validator.coerceStringToNumber.message("CVV")
  ),
  expiry_date: Validator.string("Expiry Date").refine((expiryDate) => {
    // Regular expression to match MM/YY format
    const regex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
    return regex.test(expiryDate);
  }, "Expiry date must be in MM/YY format"),
  default_pin: Validator.string("Default Pin", 4, 4).refine(
    Validator.coerceStringToNumber.method,
    Validator.coerceStringToNumber.message("Default Pin")
  ),
  color: Validator.string("Color"),
});

const AssignCard = (props: Props) => {
  type Field = (typeof fields)[number]["name"];
  type FieldData = Record<Field | "color", string>;
  const { data, onCancel, refetch } = props;

  const { mutate, isMutating } = useMutation({
    mutationFn: PersonalAPIService.assignCard,
    onSuccess: refetch,
    onMutateComplete: onCancel,
  });

  const schema = AssignCardSchema;

  const [formData, setFormData] = useState<FieldData>({
    card_pan: "",
    cvv: "",
    default_pin: "",
    expiry_date: "",
    color: "",
    wallet_id: "",
  });

  const [errors, setErrors] = useState(Validator.createError(schema));

  const disableButton = isObjectWithValidValues(formData);

  const assignCard = async (payload: z.infer<typeof schema>) => {
    mutate({
      physical_card_request_id: data.id,
      wallet_id: formData.wallet_id ?? "",
      ...payload,
    });
  };

  const validateFormData = () => {
    Validator.validatorFunc({
      data: { ...formData, card_pan: formData.card_pan.replaceAll(" ", "") },
      onError: setErrors,
      onSuccess: assignCard,
      schema,
    });
  };

  const createSelectOption = (str: string) => ({
    label: capitalizeFirstLetter(str),
    value: str,
  });

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={validateFormData}
      disableButton={!disableButton || !formData}
      title="Assign Card Data"
      hideCancel
      className={cn(GENERAL_CLASSES.BIG_MODAL, GENERAL_CLASSES.EXPAND_MODAL_BUTTON)}
      actionText="Assign Card Data to Account"
      loading={isMutating}
    >
      <div className="assign-physical-card">
        {fields.map((field) => (
          <div key={field.label} className={field.className}>
            <Input
              {...field}
              value={TypeIs.any(formData)?.[field.name] ?? ""}
              onChange={(e: any) => {
                const value = field.target === "event" ? e.target.value : e;

                setFormData((old) => ({ ...old, [field.name]: value }));
              }}
            />
            <p className="error-text">{TypeIs.any(errors)?.[field.name]}</p>
          </div>
        ))}

        <div className="two-column">
          <Input
            selectStyles={reactSelectStyle}
            selectOption={cardColors.map(createSelectOption)}
            selectValue={formData.color ? createSelectOption(formData.color) : undefined}
            showError={!!errors?.color}
            errorText={errors?.color}
            type="select"
            menuPlacement="top"
            label="Color"
            placeholder="Select Color"
            onChange={(e: any) => setFormData((old) => ({ ...old, color: e.value }))}
          />
          <p className="error-text">{errors?.color}</p>
        </div>
      </div>
    </ActionModal>
  );
};

export const ApproveCardRequestModal = (props: Props) => {
  const { isMutating, mutate } = useMutation({
    mutationFn: PersonalAPIService.approveCardRequest,
    onSuccess: props.refetch,
    onMutateComplete: props.onCancel,
  });

  return (
    <ActionModal
      visible
      onCancel={props.onCancel}
      onClick={() => mutate(props.data.id)}
      cancelText="No, Cancel"
      actionText="Yes, Approve"
      loading={isMutating}
      body="Are you sure you want to approve this card request? You can always come back to perform the action.."
      title="Approve Card Request"
    />
  );
};

export const MarkCardForDeliveryModal = (props: Props) => {
  const { isMutating, mutate } = useMutation({
    mutationFn: PersonalAPIService.markCardReadyForDelivery,
    onSuccess: () => {
      toast.success("Card delivery status updated.");
      props.refetch();
    },
    onMutateComplete: props.onCancel,
  });

  return (
    <ActionModal
      visible
      onCancel={props.onCancel}
      onClick={() => mutate(props.data.id)}
      cancelText="No, Cancel"
      actionText="Yes, Approve"
      loading={isMutating}
      body="Are you sure you want to mark this card ready for delivery ? You can always come back to perform the action.."
      title="Mark Card for Delivery"
    />
  );
};

export const RecreateDispatchOrderModal = (props: Props) => {
  const { isMutating, mutate } = useMutation({
    mutationFn: PersonalAPIService.recreateDispatchOrder,
    onSuccess: () => {
      toast.success("Card delivery status updated.");
      props.refetch();
    },
    onMutateComplete: props.onCancel,
  });

  return (
    <ActionModal
      visible
      onCancel={props.onCancel}
      onClick={() => mutate(props.data.id)}
      cancelText="No, Cancel"
      actionText="Yes, Approve"
      loading={isMutating}
      body="Are you sure you want to recreate this dispatch order ? You can always come back to perform the action.."
      title="Recreate Dispatch Order"
    />
  );
};

export const CardDeliveryHistory = (props: Props) => {
  const { data, isLoading, isFetching } = personalReduxAPI.useGetCardDeliveryHistoryQuery(
    props.data.id
  );

  const joinedLoader = isLoading || isFetching;

  return (
    <ActionModal
      visible
      onClick={props.onCancel}
      onCancel={props.onCancel}
      title="Card Delivery History"
      btnColor="#F7F7F7"
      btnTextColor="#020202"
      className={cn(GENERAL_CLASSES.BIG_MODAL, "modal-button-faded")}
    >
      {joinedLoader && <TransferEmptyScreen loading={joinedLoader} height="40rem" />}
    </ActionModal>
  );
};
