import { pbGetHandler, PersonalBaseParams, PersonalBuilder } from "..";
import { AnalyticsData } from "./types";

interface DateRange {
  start_date: string;
  end_date: string;
}

type RewardReferral = {
  username: string;
  reward: number;
  email: string;
}

type RewardsModel = CreatePersonalPaginatedResponse<"users", PersonalRewards[]>;

export const PersonalRewardsAPI = (builder: PersonalBuilder) => {
  return {
    getUserRewardRank: builder.query<RewardsModel, any>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "user-reward-ranks", params });
      },
    }),
    getRewardsInsight: builder.query<RewardInsight, {email:string}>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "rewards-insight", params });
      },
    }),

    getRefferedUserInsights: builder.query<RewardReferral[], {email:string}>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "referred-users-insight", params });
      },
    }),

    getRewards: builder.query<any, any>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "reward/how_to_earn", params });
      },
    }),
  };
};
