import React from "react";
import DisputesList from "./components/disputeList";
import DisputeDetails from "./components/disputeDetails";
import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";

export const BGBusinessDisputes = () => {
  return (
    <DashboardLayout>
      <div className="bg-disputes">
        <div className="bg-disputes__container">
          <DisputesList />
          <DisputeDetails />
        </div>
      </div>
    </DashboardLayout>
  );
};
