import { useState } from "react";
import { useExportModal } from "@/hooks/useExportModal";
import { Util } from "@/utils/utility";
import { CustomFilterFilters } from "@/components/common/smartFilter/custom-filter";

type FilterOptions = {
  label: string;
  value: string;
};

type FilterValue = {
  start_date: string;
  stop_date: string;
};

export const usePosFilter = (filters?: CustomFilterFilters<string>[]) => {
  const [filterValue, setFilterValue] = useState<FilterValue>({
    start_date: "",
    stop_date: "",
  });

  const exportOptions = useExportModal();

  return {
    filterOptions: filters
      ? {
          onFilter: (e: any) => setFilterValue(Util.filterStruct(e, "pos") as any),
          customFilter: { filters },
        }
      : {
          onFilter: (e: any) => setFilterValue(Util.filterStruct(e, "pos") as any),
        },
    exportOptions,
    filterValue,
  };
};
