import { YStack } from "@/components/common/stacks";
import "./styles/index.css";
import { OutletTab } from "@/components/common/OutletTab";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { useAppDispatch } from "@/redux/store";
import { useEffect } from "react";
import { BugissApi } from "@/redux/slices";
import { PaginationProvider, usePagination } from "@/hooks/usePagination";
import EmptyScreen from "@/components/common/emptyScreen";

const Tabs = ["Virtual Cards", "Physical Cards"];

export const BBCards = () => {
  const dispatch = useAppDispatch();
  const { currentPage, searchQuery, filterValue, ...rest } = usePagination();

  const fetchCards = async () => {
    dispatch(
      BugissApi.business.getBusinessCards({
        current_page: currentPage,
        per_page: 20,
        search_term: searchQuery,
        ...filterValue,
      }),
    );
  };

  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery, filterValue]);

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Cards">
        <PaginationProvider
          currentPage={currentPage}
          filterValue={filterValue}
          searchQuery={searchQuery}
          {...rest}
        >
          <OutletTab>
            <YStack style={{ marginBottom: "1rem" }}>
              <div className="bb-cards__tabs">
                <OutletTab.Tabs
                  // variant="secondary"
                  tabs={Tabs}
                  baseURL="/business-cards"
                />
              </div>
            </YStack>
            <OutletTab.Outlet />
          </OutletTab>
        </PaginationProvider>
      </PageLayout>
    </DashboardLayout>
  );
};

interface BusinessTempPhysicalCardsProps {
  height?: string;
}
export const BusinessTempPhysicalCards = (props?: BusinessTempPhysicalCardsProps) => {
  const { height = "100%" } = props ?? {};

  return (
    <div style={{ height, marginTop: "2rem" }}>
      <EmptyScreen />
    </div>
  );
};
