import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import TwinValue from "@/components/common/twinValue";
import { CardAvatarIcons } from "@/assets/images/cards";
import SmartFilter from "@/components/common/smartFilter";
import { formatNumberToCurrency } from "@/utils/helpers";
import { useSelector } from "react-redux";
import Redacted from "@/components/common/shimmer/redact";
import { formatDateTime } from "@/utils/helper/Helper";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import ActionModal from "@/components/common/modal/ActionModal";
import HoverTabs from "@/components/common/hoverTabs";
import { useState } from "react";
import InlineDetails from "@/components/common/inlineDetails";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import { CARD_STATUS, TERMINAL_TYPE } from "@/apps/pos/__internals__/constants/card";

export const MerchantPosCard = ({ email }: { email?: string }) => {
  const { tableData, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosCardCollection>("getCardCollections", { email }, { skip: !email });

  const cards = tableData;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const modalTabs = [
    {
      label: "Details",
    },
    {
      label: "Amount & Fee",
    },
    {
      label: "Device Details",
    },
  ];

  function getCardPan(e: string) {
    return e.slice(0, 4) + "******" + e.slice(-4);
  }

  // Modal

  const [showDetails, setShowDetails] = useState({
    data: {},
    on: false,
  });

  // modal tab
  const [activeModalTab, setActiveModalTab] = useState<any>(modalTabs[0]);

  // declare modal content -> MD
  const MC: Partial<PosCardCollection> = showDetails?.data;
  const renderInline = {
    details:
      activeModalTab?.label === "Details"
        ? [
            {
              label: "Business Email",
              value: Util.safeValue(MC.email),
            },
            {
              label: "RRN",
              value: Util.safeValue(MC.rrn),
            },
            {
              label: "Card Pan",
              value: Util.safeValue(MC.card_pan),
            },
            {
              label: "Stan",
              value: Util.safeValue(MC.stan),
            },
            {
              label: "Date",
              value: formatDateTime(String(MC.created_at)),
            },

            {
              label: "status",
              value: MC.response_code,
            },
          ]
        : activeModalTab?.label?.includes("Fee")
          ? [
              {
                label: "Amount",
                value: formatNumberToCurrency(Number(MC.amount), "NGN"),
              },
              {
                label: "VAT",
                value: formatNumberToCurrency(Number(MC.vat_bal), "NGN"),
              },
              {
                label: "PNL",
                value: formatNumberToCurrency(Number(MC.pnl), "NGN"),
              },
              {
                label: "Transaction Fee",
                value: formatNumberToCurrency(Number(MC.paid_fee), "NGN"),
              },
              {
                label: "Processor Fee",
                value: formatNumberToCurrency(Number(MC.processor_bal), "NGN"),
              },
            ]
          : [
              {
                label: "Terminal Label",
                value: Util.safeValue(
                  // JSON.parse(MC?.pdon_meta ?? "")?.app_profile?.poseidon_label
                  "",
                ),
              },
              {
                label: "Terminal ID",
                value: Util.safeValue(MC.terminal_id),
              },
              {
                label: "Serial No",
                value: Util.safeValue(MC.serial),
              },
            ],
  };

  return (
    <>
      <SmartFilter {...SmartFilterProps} />
      {isEmpty(cards) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "CARD DETAILS",
            "CHANNEL",
            "REFERENCES",
            "AMOUNT",
            "DATE",
          ]}
        >
          {cards?.map((card, idx) => {
            const meta = JSON.parse(card?.request_payload);

            return (
              <RavenTableRow
                className="pos-collections"
                key={idx}
                one={isRedacting ? <Redacted.Table /> : Util.safeValue(card?.email)}
                two={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    <div
                      className={`card_details ${
                        card?.response_code === CARD_STATUS.SUCCESFUL
                          ? "approved"
                          : "failed"
                      }`}
                    >
                      <AvatarWithTwinValue
                        imgSrc={
                          CardAvatarIcons[
                            Util.detectCard(String(meta?.pan))
                              ?.toLowerCase()
                              ?.trim() as "visa"
                          ]
                        }
                        name=""
                        gap={1}
                        one={
                          card?.response_code === CARD_STATUS.SUCCESFUL
                            ? "Approved"
                            : "Failed"
                        }
                        two={getCardPan(Util.safeValue(meta?.pan))}
                      />
                    </div>
                  )
                }
                three={
                  isRedacting ? (
                    <Redacted.Labelled />
                  ) : (
                    <TwinValue
                      one={Util.safeValue(
                        card?.trnx_type === TERMINAL_TYPE.BANKBOX ? "Bankbox" : "K11",
                      )}
                      two={`TID: ${Util.safeValue(card?.terminal_id)}`}
                    />
                  )
                }
                four={
                  isRedacting ? (
                    <Redacted.Labelled />
                  ) : (
                    <TwinValue
                      one={`RRN: ${Util.safeValue(card?.rrn)}`}
                      two={`Stan: ${Util.safeValue(card?.stan)}`}
                    />
                  )
                }
                five={
                  isRedacting ? (
                    <Redacted.Labelled />
                  ) : (
                    <TwinValue
                      one={formatNumberToCurrency(Number(card?.amount), "NGN")}
                      two={formatNumberToCurrency(Number(card?.paid_fee), "NGN")}
                    />
                  )
                }
                seven={isRedacting ? <Redacted.Table /> : formatDateTime(card.created_at)}
                onRowClick={() => {
                  setShowDetails({
                    on: true,
                    data: card,
                  });
                }}
              />
            );
          })}
        </RavenTable>
      )}

      <ActionModal
        visible={showDetails.on}
        title={"Card Collection Details"}
        onCancel={function (): void {
          setShowDetails({ on: false, data: {} });
        }}
        onClick={function (): void {
          setShowDetails({ on: false, data: {} });
        }}
        actionText={"Close"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        // loading={loading}
        hideCancel
      >
        <HoverTabs
          tabs={modalTabs}
          value={activeModalTab}
          onChange={(e: any) => {
            setActiveModalTab(e);
          }}
        />

        <InlineDetails className="no-shadow" simple content={[...renderInline.details]} />
      </ActionModal>
    </>
  );
};
