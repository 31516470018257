import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useSingleUserState } from "../../context";
import { CopyText } from "@/components/common/copyCheck";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { useRef, useState } from "react";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { DisputeModal } from "./dispute-modal";

export const UserDisputes = () => {
  const { email } = useSingleUserState();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetHelpLogForUserQuery(email);
  const someRef = useRef<HTMLDivElement | null>(null);
  const [selectedContent, setSelectedContent] = useState<NewPersonalHelpLog>();

  const { renderDynamicModal } = useDynamicModal(Boolean(selectedContent));

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable } = renderRedactedComponent(joinedLoader);

  const handleClose = () => setSelectedContent(undefined);

  if (isLoading || !data)
    return (
      <div className="mt-20">
        <TransferEmptyScreen loading={isLoading} height="75vh" />
      </div>
    );

  return (
    <>
      <div ref={someRef} className="h-full mt-20 balablue">
        {data?.length > 0 ? (
          <>
            <div className="table">
              <RavenTable
                className="table__main"
                headerList={[
                  "REFERENCE",
                  "MESSAGE",
                  "TYPE",
                  "HELP OPTION",
                  "TRANSACTION DATE",
                  "STATUS",
                  " ",
                ]}
              >
                {data.map((row) => {
                  const {
                    id,
                    created_at,
                    message,
                    status,
                    transaction_id,
                    transaction_type,
                    help_option,
                  } = row;

                  return (
                    <RavenTableRow
                      key={id}
                      one={RedactedTable(<CopyText text={transaction_id} />)}
                      two={RedactedTable(Util.safeValue(message || "--"))}
                      three={RedactedTable(Util.safeValue(transaction_type))}
                      four={RedactedTable(Util.safeValue(help_option))}
                      five={RedactedTable(formatDateTime(created_at))}
                      six={RedactedTable(formatStatus(status))}
                      seven={<ActionDrop more />}
                      onRowClick={() => setSelectedContent(row)}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </>
        ) : (
          <TransferEmptyScreen height="75vh" />
        )}
      </div>
      {renderDynamicModal(
        selectedContent ? (
          <DisputeModal onCancel={handleClose} row={selectedContent} refetch={refetch} />
        ) : null
      )}
    </>
  );
};
