import { useState, useEffect, useContext } from "react";
import ActionContext from "../context/ActionContext";
import { toggleTheme } from "../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";

export const useDarkMode = (def: any) => {
  const actionCtx = useContext(ActionContext);

  const dispatch = useDispatch();

  const theme: ThemeProp = useSelector((state: any) => state.theme.theme);

  type ThemeProp = "light" | "dark";

  const [t, setTheme] = useState<ThemeProp>(def);

  const setMode = (mode: ThemeProp) => {
    setTheme(mode);
    dispatch(toggleTheme(mode));

    window.localStorage.setItem("theme", mode);
  };

  const togTheme = () => {
    t === "dark" ? setMode("light") : setMode("dark");
  };

  useEffect(() => {
    const localTheme: ThemeProp = window.localStorage.getItem("theme") as ThemeProp;
    dispatch(toggleTheme(localTheme));

    localTheme ? setTheme(localTheme) : setMode("light");
  }, [def, actionCtx.toggleTheme]);

  return [theme, togTheme] as const;
};
