import "~/common/used-by-details/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "@/components/common/avatar";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { useParams } from "react-router-dom";
import { Validator } from "@/utils/validator";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { usePagination } from "@/hooks/usePagination";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { iife } from "@/utils/general";
import { useState } from "react";
import { BIZ_TRANSACTION_STATUS } from "@/apps/business/__internals__/constants";

const paramsSchema = Validator.object({
  bulk_reference: Validator.string("Bulk Reference"),
  business_email: Validator.string("Business Email"),
});

const DummyMetaData = {
  bulk_reference: "",
  summary: {
    total_sent: 0,
    total_failed: 0,
    total_amount: 0,
    transaction_fee: 0,
    total_beneficiaries: 0,
    total_no_sent: 0,
    total_no_failed: 0,
  },
};

type MainTransactions = GetBusinessBulkTransferMainTransactions;

const safeUndefined = <T,>(value?: T) => {
  if (!value) return {} as T;
  return value;
};

interface Meta_Data {
  bulk_reference: string;
  summary: Summary;
}

interface Summary {
  total_sent: number;
  total_failed: number;
  total_amount: number;
  transaction_fee: number;
  total_beneficiaries: number;
  total_no_sent: number;
  total_no_failed: number;
}

export const BBBulkTransferDetails = () => {
  const params = useParams();
  const { bulk_reference, business_email } = paramsSchema.parse(params);
  const pagination = usePagination();
  const { currentPage } = pagination;
  const [filterValue, setFilterValue] = useState<any>({});
  const { data, isLoading, isFetching } = businessReduxAPI.useGetBulkTransferDetailsQuery(
    {
      bulk_reference,
      business_email,
      current_page: currentPage,
      ...filterValue,
    }
  );

  const {
    SmartFilterProps: { onSearchChange, ...rest },
  } = useSmartFilterProps(data?.transaction_details, pagination);

  const joinedLoader = isLoading || isFetching;

  const { RedactedLabel } = renderRedactedComponent(isLoading);
  const { RedactedTable } = renderRedactedComponent(joinedLoader);

  const accessSafely = (key: keyof MainTransactions) => {
    const mainTrx = safeUndefined<MainTransactions>(data?.main_transaction);

    return mainTrx[key];
  };

  const metaData = iife(() => {
    if (!data) return DummyMetaData;

    const _metaData = JSON.parse(accessSafely("meta_data"));

    return _metaData as Meta_Data;
  });

  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Bulk Transfer Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset", padding: 0 }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img product-single-details-page__avatar">
              {icons.send_04_double_filled}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Amount">
                      {formatNumberToCurrency(metaData.summary.total_amount, "NGN")}
                    </MainContentCol.Item>
                  )}
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Amount Sent">
                      {formatNumberToCurrency(metaData.summary.total_sent, "NGN")}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Recipient">
                      {Util.safeValue(metaData.summary.total_beneficiaries)}
                    </MainContentCol.Item>
                  )}
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Successful">
                      {Util.safeValue(metaData.summary.total_no_sent)}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Fees">
                      {formatNumberToCurrency(metaData.summary.transaction_fee, "NGN")}
                    </MainContentCol.Item>
                  )}
                  {RedactedLabel(
                    <MainContentCol.Item title="Date Disbursed">
                      {formatDateTime(accessSafely("created_at"))}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
                <MainContentCol>
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Amount Failed">
                      {formatNumberToCurrency(metaData.summary.total_failed, "NGN")}
                    </MainContentCol.Item>
                  )}
                  {RedactedLabel(
                    <MainContentCol.Item title="Total Failed">
                      {Util.safeValue(metaData.summary.total_no_failed)}
                    </MainContentCol.Item>
                  )}
                </MainContentCol>
              </div>
              <div style={{ display: "none" }} className="user-data__account-summary">
                <div className="top-wrap__actions">
                  <RavenButton className="make-deduction-btn" color="white-light">
                    <figure>{icons.repeat}</figure>
                    <p>Retry Failed Transfers</p>
                  </RavenButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-user-details__table-wrap">
          <SmartFilter
            {...rest}
            alternateTitle=" "
            onFilter={(e: any) => {
              setFilterValue(Util.filterStruct(e) as any);
            }}
            singleSelect
            hideDateFilter
            hideExport
            filters={[
              {
                label: "Status",
                filterBy: "status",
                options: [
                  { value: BIZ_TRANSACTION_STATUS.PENDING.toString(), label: "Pending" },
                  {
                    value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                    label: "Processing",
                  },
                  { value: BIZ_TRANSACTION_STATUS.FAILED.toString(), label: "Failed" },
                  { value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(), label: "Success" },
                  {
                    value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                    label: "Reversed",
                  },
                ],
              },
            ]}
          />
          {data && data.transaction_details.data?.length > 0 ? (
            <RavenTable
              headerList={[
                "REFERENCE",
                "ACCOUNT NAME",
                "BANK",
                "RECIPIENT",
                "AMOUNT",
                "DATE INITIATED",
                "STATUS",
              ]}
            >
              {data.transaction_details.data.map((record) => {
                const {
                  id,
                  trx_ref,
                  account_name,
                  bank,
                  account_number,
                  amount,
                  created_at,
                  status,
                  identifier,
                } = record;

                return (
                  <RavenTableRow
                    key={id}
                    one={RedactedTable(trx_ref)}
                    two={RedactedTable(<Avatar name={account_name} full />)}
                    three={RedactedTable(bank)}
                    four={RedactedTable(
                      Util.safeValue(account_number ?? `@${identifier}`)
                    )}
                    five={RedactedTable(formatNumberToCurrency(amount, "NGN"))}
                    six={RedactedTable(formatDateTime(created_at))}
                    seven={RedactedTable(
                      formatStatus(detectStatus(Number(status), "transactions"))
                    )}
                  />
                );
              })}
            </RavenTable>
          ) : (
            <TransferEmptyScreen loading={joinedLoader} />
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
