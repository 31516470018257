import "./styles/index.css";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES, SPECIAL_ROUTES } from "@/constants";
import { TabsElement } from "@/components/common/Tabs";

const Tabs: TabsElement[] = [
  "BVN Verification",
  "NIN Verification",
  "Manual Address Verification",
  "Manual ID Verification",
  "Limit Requests",
  { label: "Details Checker", value: SPECIAL_ROUTES.DETAILS_CHECKER },
];

export const PersonalCompliance = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Compliance">
        <OutletTab>
          <OutletTab.Tabs
            className={GENERAL_CLASSES.OUTLET}
            baseURL="/personal-compliance"
            tabs={Tabs}
            specialUrls={[SPECIAL_ROUTES.DETAILS_CHECKER]}
          />
          <OutletTab.Outlet />
        </OutletTab>
      </PageLayout>
    </DashboardLayout>
  );
};
