import { TypeIs } from "@/utils/helpers";
import { PersonalBuilder, createPersonalURL, pbGetHandler } from "..";

type AirtimeOrDataParams = {
  per_page: SN;
  page: SN;
  phone_number: string;
  status?: string;
  reference?: string;
  search?: string;
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
  type: "airtime" | "data";
};

export type CombinedBillsType = "betting" | "electricity" | "cable";
interface BettingCableParams extends Omit<AirtimeOrDataParams, "type" | "phone_number"> {
  type: CombinedBillsType;
  email: string;
}

type AirtimeRes = CreatePersonalPaginatedResponse<
  "airtimeRecharges",
  PersonalAirtimeModel[]
>;
type DataRes = CreatePersonalPaginatedResponse<"dataRecharges", PersonalDataModel[]>;

type Electricity = CreatePersonalPaginatedResponse<
  "electricityRecharges",
  PersonalElectricityModel[]
>;
type Cable = CreatePersonalPaginatedResponse<"cableRecharges", PersonalCableModel[]>;
type Betting = CreatePersonalPaginatedResponse<
  "bettingRecharges",
  PersonalBettingModel[]
>;

type CombinedEndpointRes = CreatePersonalPaginatedResponse<
  "jointBillModel",
  PersonalJointBillModel[]
>;

type BothAirtimeAndData = AirtimeRes | DataRes;

export const PersonalBillersAPI = (builder: PersonalBuilder) => {
  return {
    getBothAirtimeAndData: builder.query<BothAirtimeAndData, AirtimeOrDataParams>({
      queryFn: ({ type, ...params }) => {
        const url = `user/${type}-recharges`;

        const route = createPersonalURL(url, params.search);

        return pbGetHandler({ params, route });
      },
    }),
    getBettingCableElectricity: builder.query<CombinedEndpointRes, BettingCableParams>({
      queryFn: ({ type, ...params }) => {
        const url = `user/${type}-recharges`;

        const route = createPersonalURL(url, params.search);

        const baseParams = { params, route };

        switch (type) {
          case "betting":
            return pbGetHandler({
              ...baseParams,
              mutateData(data: Betting): CombinedEndpointRes {
                const { bettingRecharges, pagination } = data;
                const jointBillModel = mapToCombinedResponse(bettingRecharges, type);
                return { pagination, jointBillModel };
              },
            });
          case "electricity":
            return pbGetHandler({
              ...baseParams,
              mutateData(data: Electricity) {
                const { electricityRecharges, pagination } = data;
                const jointBillModel = mapToCombinedResponse(electricityRecharges, type);
                return { pagination, jointBillModel };
              },
            });
          case "cable":
            return pbGetHandler({
              ...baseParams,
              mutateData(data: Cable) {
                const { cableRecharges, pagination } = data;
                const jointBillModel = mapToCombinedResponse(cableRecharges, type);
                return { pagination, jointBillModel };
              },
            });
        }
      },
    }),
  };
};

function mapToCombinedResponse<T extends AllCombinedJointBills>(
  data: T[],
  type: BillerTypeMeta
) {
  const jointBillModel = data.map((record) => {
    let provider, unique_identifier, request_id;
    const _record = TypeIs.any(record);

    if (type === "betting") {
      provider = _record.type;
      unique_identifier = _record.customer_id;
      request_id = _record.reference;
    }

    if (type === "cable") {
      provider = _record.service_id;
      unique_identifier = _record.iuc_number;
      request_id = _record.request_id;
    }

    if (type === "electricity") {
      provider = _record.service_id;
      unique_identifier = _record.meter_number;
      request_id = _record.request_id;
    }

    return {
      _biller: record,
      amount: record.amount,
      id: record.id,
      processed_at: record.processed_at ?? record.created_at,
      provider,
      status: record.status,
      unique_identifier,
      whatTypeIsThis: type,
      extra_data: TypeIs.any(record)?.plan ?? null,
      request_id,
    };
  }) as PersonalJointBillModel[];

  return jointBillModel;
}
