import React, { createContext, useContext } from "react";
import { useOutletURL, UseOutletURL } from "@/hooks/useOutletURL";
import TabComponent, { TabComponentProps } from "../Tabs";
import { Outlet } from "react-router-dom";
import { TabElementDefaults, TabsElement, TabWithLabel } from "../Tabs/shared";

interface ProviderContextState {
  hasOutlet: boolean;
}

const ProviderContext = createContext<ProviderContextState>({
  hasOutlet: false,
});

const useProviderContext = () => {
  const value = useContext(ProviderContext);

  return value;
};

export interface OutletTabProps extends Omit<UseOutletURL, "baseURL"> {
  tabs: TabsElement[];
  children?: React.ReactNode;
  baseURL: `/${string}`;
  variant?: TabComponentProps["variant"];
  onTabValueChange?: TabComponentProps["onTabValueChange"];
  selectedDropdown?: TabElementDefaults;
  className?: string;
}

const convertLabelToValue = (str: string) => {
  return str?.replaceAll(" ", "-").toLowerCase() ?? "";
};

const transformTabToTabWithLabel = (tabs: TabsElement[]) => {
  const transformedTab: TabWithLabel[] = tabs.map((tab) => {
    if (typeof tab === "string")
      return {
        label: tab,
        value: convertLabelToValue(tab),
      };

    return tab;
  });

  return transformedTab;
};

const Tab = (props: OutletTabProps) => {
  const {
    baseURL,
    defaultOutletUrl,
    tabs,
    children,
    variant,
    onTabValueChange,
    selectedDropdown,
    className,
  } = props;

  const Tabs = transformTabToTabWithLabel(tabs);

  if (Tabs?.length <= 0) {
    throw new Error("Tab cannot be an empty array");
  }

  const { handleURLChange, pageSlug } = useOutletURL({
    baseURL,
    defaultOutletUrl: convertLabelToValue(defaultOutletUrl ?? Tabs?.[0]?.value ?? ""),
    specialUrls: props.specialUrls,
  });

  return (
    <TabComponent
      onValueChange={(tab) => {
        handleURLChange(tab);
      }}
      onTabValueChange={onTabValueChange}
      defaultValue={pageSlug}
      variant={variant}
      tabs={tabs}
      className={className}
    >
      <TabComponent.Tabs
        selectedDropdown={selectedDropdown}
        tabs={Tabs}
        pageSlug={pageSlug}
      />
      {children}
    </TabComponent>
  );
};

interface ProviderProps extends IChildren {
  tabProps?: OutletTabProps;
}

const Provider = ({ tabProps, children }: ProviderProps) => {
  const hasOutlet = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === Outlet
  );

  return (
    <ProviderContext.Provider value={{ hasOutlet }}>
      <React.Fragment>
        {tabProps ? <Tab {...tabProps} /> : null}
        {children}
      </React.Fragment>
    </ProviderContext.Provider>
  );
};

Provider.Tabs = Tab;
Provider.Outlet = Outlet;

export const OutletTab = Provider;
