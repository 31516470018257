import {
  PersonalBaseParams,
  PersonalBuilder,
  PersonalPaginationParams,
  pbGetHandler,
} from "..";

export type TransferParams = Partial<PersonalPaginationParams> & PersonalBaseParams;

type FetchTransfer = Pick<PersonalPaginationParams, "email" | "transaction_id"> &
  PersonalBaseParams;

type FetchScheduledPayments = Pick<PersonalPaginationParams,"page" | "per_page" | "status" | "type"> &
  PersonalBaseParams;

  type ScheduledPayment = CreatePersonalPaginatedResponse<
  "payments",
  ScheduledPayment[]
>;

type PageParams = Pick<PersonalPaginationParams, "page" | "per_page"> &
  PersonalBaseParams;

type HelpLogParams = Pick<PersonalPaginationParams, "page" | "per_page" | "status"> &
  PersonalBaseParams;

type GetAllTransactions = PageParams & { email?: string } & PersonalBaseParams;

export const PersonalTransactionsAPI = (builder: PersonalBuilder) => {
  return {
    getAllTransactions: builder.query<PersonalGetTransfers, GetAllTransactions>({
      queryFn: (params) => pbGetHandler({ params, route: "transactions" }),
    }),
    getUserTransactions: builder.query<PersonalGetTransfers, GetAllTransactions>({
      queryFn: (params) => pbGetHandler({ params, route: "user/transactions" }),
    }),
    getSingleTransaction: builder.query<PersonalTransactionModel, string>({
      queryFn: (transaction_id) =>
        pbGetHandler({
          params: { transaction_id } as any,
          route: "get-user-transaction",
        }),
    }),
    getTransactionTypeForUser: builder.query<PersonalTransactionModel[], FetchTransfer>({
      queryFn: (params: any) =>
        pbGetHandler({ params, route: "transaction-type-for-user" }),
    }),
    getScheduledTransactions: builder.query<ScheduledPayment, FetchScheduledPayments>({
      queryFn: (params: any) =>
        pbGetHandler({ params, route: "scheduled-payments",
          mutateData(data: ScheduledPayment) {
            const { payments, pagination } = data;
            return { pagination, payments };
          },

         }),
    }),
    getHelpLogForUser: builder.query<NewPersonalHelpLog[], string>({
      queryFn: (email) =>
        pbGetHandler({
          params: { email },
          route: "user/get-help-logs",
        }),
    }),
    getAllHelpLog: builder.query<PersonalNewGetAllHelpLog, HelpLogParams>({
      queryFn: (params) => pbGetHandler({ params, route: "get-help-logs" }),
    }),
  };
};
