import { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

const ActionContext = createContext({
  sideMenuDrop: {
    one: true,
    two: false,
    three: false,
    four: false,
  },
  toggleTheme: () => {},
  setSideMenuDrop: (param) => {},
});

export function ActionContextProvider(props) {
  const location = useLocation();
  const [sideMenuDropVal, setSideMenuDropVal] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
  });

  function setSideMenuDropValFunc(param) {
    if (param === "one") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          // one: !sideMenuDropVal?.one,
          one: true,
          two: false,
          three: false,
          four: false,
        };
      });
    }

    if (param === "two") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          one: true,
          three: false,
          four: false,
          two: !sideMenuDropVal?.two,
        };
      });
    }
    if (param === "three") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          two: false,
          one: true,
          four: false,
          three: !sideMenuDropVal?.three,
        };
      });
    }

    if (param === "four") {
      setSideMenuDropVal((prev) => {
        return {
          ...prev,
          two: false,
          three: false,
          one: true,
          four: !sideMenuDropVal?.four,
        };
      });
    }
    if (param === "close") {
      setSideMenuDropVal((prev) => {
        return { ...prev, one: true, two: false, three: false, four: false };
      });
    }
  }

  const context = {
    sideMenuDrop: sideMenuDropVal,
    setSideMenuDrop: setSideMenuDropValFunc,
  };

  return (
    <ActionContext.Provider value={context}>{props.children}</ActionContext.Provider>
  );
}

export default ActionContext;
