import "./style/index.css";

import { Column, Row, XStack, YStack } from "@/components/common/stacks";
import { useOutletURL } from "@/hooks/useOutletURL";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import {
  formatDateTime,
  formatNumWithComma,
  IconVault,
  symbol,
} from "@/utils/helper/Helper";
import { cn } from "@/utils/colorConvert";
import { useEffect, useState } from "react";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { TabsElement } from "@/components/common/Tabs";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES, GLOBAL_PER_PAGE } from "@/constants";
import Gap from "@/components/common/styleComponents/Gap";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalCurrency } from "@/redux/actions/actions";
import Image from "@/components/common/image/Image";
import Redacted, { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { icons } from "@/assets/icons";
import { rankIcons } from "@/assets/icons/rankIcons";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import TwinValue from "@/components/common/twinValue";
import Avatar from "@/components/common/avatar";
import SmartFilter from "@/components/common/smartFilter";
import { useProductColor } from "@/context/ProductTheme";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { usePagination } from "@/hooks/usePagination";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";

const activeClass = "personal-rewards__tab__link--active";

export const getRewardIcons = (rank: string) => {
  const str = rank.trim().replaceAll(" ", "_").toLowerCase();
  return rankIcons[str as "raven_nestling"];
};


export const ranks = [
  { label: "Raven Nestling", value: 10 },
  { label: "Fledgling Flyer", value: 100 },
  { label: "Soaring Sparrow", value: 1000 },
  { label: "Wealth Connoisseur", value: 10000 },
  { label: "Mighty Falcon", value: 100000 },
  { label: "Platinum Phoenix", value: 1000000 },
  { label: "Mythical Raven", value: 10000000 },
];

export const PBRewards = () => {

  // const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(ranks[0]);

  const loading = !ranks;

  const loadMap = [0, 1, 2, 3, 4, 5, 6];

  const { colorToken, colorVariable } = useProductColor();
  const pagination = usePagination();


  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetUserRewardRankQuery({
      minimum: activeTab.value,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      page: pagination.currentPage,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const joinedLoader = isLoading || isFetching;

  const { RedactedLabel } = renderRedactedComponent(joinedLoader);

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Rewards">
        <Row gap={0} className="personal-rewards">
          <YStack className="personal-rewards__tab hide-scrollbar">
            {loading
              ? loadMap.map((d) => (
                  <div>
                    <Redacted.AvatarWithLabelled gap={5} />
                  </div>
                ))
              : ranks.map((item) => {
                  return (
                    <div
                      onClick={() => setActiveTab(item)}
                      className={cn(
                        "personal-rewards__tab__item cursor-pointer",
                        activeTab.label === item.label && activeClass
                      )}
                    >
                      <figure className="rank-icons">{getRewardIcons(item.label)}</figure>
                      <div className="flex col currency_name">
                        <p>{item.label} </p>
                      </div>
                    </div>
                  );
                })}
          </YStack>

          <div
            style={{ backgroundImage: IconVault(icons.wave) }}
            className="personal-rewards__content"
          >
            {/* children */}

            <div className="personal-rewards__content--reward-art">
              <figure className="reward-art__badge">{icons.reward_badge}</figure>

              <img className="reward-art__bg" src={IconVault(icons.wave)} alt="" />

              <h1 className="reward-art__title">{activeTab.label}</h1>

              <div className="reward-art__metrics">
                <Row gap={0.8} ai="center">
                  {icons.avatar}

                  <Column gap={0}>
                    <p className="users">Users</p>
                    <p className="count">
                      {formatNumWithComma(Number(data?.pagination?.total))}
                    </p>
                  </Column>
                </Row>
              </div>

              <Row gap={1.2} className="reward-art__actions">
                <figure className="info">{icons.reward_info}</figure>
                <figure className="settings">{icons.reward_gear}</figure>
              </Row>
            </div>

            <Column gap={0}>
              <Gap size={20} />
              <SmartFilter
                {...SmartFilterProps}
                // pagination={{
                //   currentPage: 0,
                //   itemsPerPage: 0,
                //   totalItems: 0,
                //   onPageChange: function (page: number): void {
                //     throw new Error("Function not implemented.");
                //   },
                //   activeFilter: "All Transactions",
                // }}
                hideDateFilter
                hideExport
                hideFilter
                searchTitle="Search Users by username..."
              />

              {isLoading || !data?.users || data.users?.length === 0 ? (
                <TransferEmptyScreen
                  className="mt-20 w-full reward-empty"
                  loading={joinedLoader}
                />
              ) : (
                <div className={cn("personal-rewards__table")}>
                  <RavenTable
                    action={false}
                    className="table__main"
                    headerList={[
                      "USER",
                      "ALL EARNINGS",
                      "AIRTIME CASHBACK",
                      "DATA CASHBACK",
                      "REFERRAL CASHBACK",
                      // "MOBILE NUMBER",
                      // "DATE REGISTERED",
                    ]}
                  >
                    {data?.users?.map((item, idx) => (
                      <RavenTableRow
                        key={idx}
                        one={RedactedLabel(
                          <TwinValue
                            one={Util.join(Util.safeValue(item?.fname))
                              .with(Util.safeValue(item?.fname))
                              ?.toUpperCase()}
                            two={Util.safeText(item?.email)}
                            copy={true}
                          />
                        )}
                        two={RedactedLabel(
                          <TwinValue
                            one={formatNumberToCurrency(item?.reward_balance)}
                            two={`${formatNumWithComma(
                              item?.reward_insight.total_reward_count
                            )} Transaction`}
                          />
                        )}
                        three={RedactedLabel(
                          <TwinValue
                            one={formatNumberToCurrency(
                              item?.reward_insight.sections[0]?.reward
                            )}
                            two={`${formatNumWithComma(
                              item?.reward_insight.sections[0]?.count
                            )} Transaction`}
                          />
                        )}
                        four={RedactedLabel(
                          <TwinValue
                            one={formatNumberToCurrency(
                              item?.reward_insight.sections[1]?.reward
                            )}
                            two={`${formatNumWithComma(
                              item?.reward_insight.sections[1]?.count
                            )} Transaction`}
                          />
                        )}
                        five={RedactedLabel(
                          <TwinValue
                            one={formatNumberToCurrency(
                              item?.reward_insight.sections[2]?.reward
                            )}
                            two={`${formatNumWithComma(
                              item?.reward_insight.sections[2]?.count
                            )} Transaction`}
                          />
                        )}
                        // five={formatDateTime(item?.)}
                      />
                    ))}
                  </RavenTable>
                </div>
              )}
            </Column>
          </div>
        </Row>{" "}
        {/* <div className="floating-add-btn">
          <RavenButton type="btn-plus" label="" color={colorVariable} />
        </div> */}
      </PageLayout>
    </DashboardLayout>
  );
};
