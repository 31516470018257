import { createContext, useContext } from "react";

interface SingleUserState extends PersonalUserModel {
  refetch(): void;
}

const Context = createContext<SingleUserState | undefined>(undefined);

interface SingleUserProviderProps {
  user: SingleUserState | undefined;
  children: React.ReactNode;
}

export const SingleUserProvider = ({ children, user }: SingleUserProviderProps) => {
  return <Context.Provider value={user}>{children}</Context.Provider>;
};

export const useSingleUserState = () => {
  const state = useContext(Context);

  return state as SingleUserState;
};
