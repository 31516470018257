import { cn } from "@/utils/colorConvert";
import { CreateTableRowProps } from "../shared";
import { GENERAL_CLASSES } from "@/constants";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import TwinValue from "@/components/common/twinValue";
import { useState } from "react";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { ControlledTableContent, XStack } from "@/components/common/stacks";
import { SCHEDULED_TRANSACTION_TYPES } from "@/apps/personal/scheduledTransactions/__internals__/constants";


type PBTProps = CreateTableRowProps<{
  data: PersonalScheduledPayments;
  refetch: RefetchFn;
  type: keyof typeof SCHEDULED_TRANSACTION_TYPES
}>;  


export const PersonalScheduledBankTransferTable = (props: PBTProps) => {
  const { data, redactedFn, refetch, ctx, type } = props;

  const {  RedactedLabel, RedactedTable } = redactedFn;
  const [selectedRechecks, setSelectedRechecks] = useState<SN[]>([]);


  const isInternal = SCHEDULED_TRANSACTION_TYPES[type] === SCHEDULED_TRANSACTION_TYPES.INTERNAL_TRANSFER


  return (
    <RavenTable
      className={cn("table__main", GENERAL_CLASSES.TABLE_WITH_DROPDOWN)}
      headerList={[
        "USER EMAIL",
        "RECIPIENT",
        "AMOUNT",
        "DATE SCHEDULED",
        "FREQUENCY",
        "STATUS",
      ].map((head) => {
        if (isInternal) {
          if(head === 'USER EMAIL'){
            return 'FROM'
          }
          if(head === 'RECIPIENT'){
            return "TO"
          }
        }
        return head ;
      })}
    >
      {data.map((row) => {
        const {
          id,
          status,
          amount,
          email,

        } = row;

        const data:PSPExternalTransfers = row.data;

        const {
          RedactedTable: CustomTableRedact,
          RedactedAvatar: CustomBadgeRedact,
          RedactedLabel: CustomBadgeLabel,
          RenderCustom: CustomCustom,
        } = renderRedactedComponent(selectedRechecks.find((idx) => idx === id));


        return (
          <RavenTableRow
            key={id}
            one={
              RedactedTable(
                Util.safeText(email)
              )
            }
            two={CustomBadgeLabel(
              RedactedLabel(
                <ControlledTableContent>
                {isInternal ?    RedactedTable(
                `@${Util.safeText(row?.data?.recipient)}`
              ) : <TwinValue
                    one={Util.safeText(trimLongString(data.account_name, 20))}
                    two={cn(Util.safeText(data.account_number), "•", Util.safeText(data?.bank))}
                  />}
                </ControlledTableContent>
              )
            )}
            three={
              RedactedTable(
                Util.safeText(formatNumberToCurrency(amount, 'ngn'))
              )
            }
            four={
              RedactedTable(
                formatDateTime(row.created_at)
              )
            }
            six={RedactedTable(
              Util.safeText(row?.frequency)
            )}
            seven={CustomCustom(
              RedactedTable(<XStack gap={0.4}>{formatStatus(status)}</XStack>),
              { height: 10, width: 40, className: GENERAL_CLASSES.SHIMMER }
            )}

          />
        );
      })}

    </RavenTable>
  );
};




