import { RavenActionBar } from "@ravenpay/raven-bank-ui";
import { ACTION_BAR_ID } from "@/constants";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
// import { useEffect } from "react";

interface UseActionBarArgs extends React.ComponentProps<typeof RavenActionBar> {}

export const useActionBar = (props: UseActionBarArgs) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const node = document.getElementById(ACTION_BAR_ID);

    const setNodeProperty = (val: string) => {
      if (node) {
        node.dataset.contentActive = val;
      }
    };

    if (props.visible) {
      setNodeProperty("true");
      setShow(true);
    } else {
      setNodeProperty("false");
      setShow(false);
    }
  }, [props.visible]);

  const ActionBar = (): JSX.Element => {
    const node = document.getElementById(ACTION_BAR_ID);

    if (node) {
      if (show) return createPortal(<RavenActionBar {...props} />, node);
      return null as any;
    }
    throw Error(`Check if an element with ${ACTION_BAR_ID} exists in DOM`);
  };

  return { ActionBar };
};
