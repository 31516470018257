import { trimLongString } from "@/utils/helper/Helper";
import { CopyText } from "../copyCheck";
import "./styles/index.css";
import { cn } from "@/utils/colorConvert";
import { Column } from "../stacks";

export interface TwinValueProps {
  one: string | React.ReactNode;
  two: string | React.ReactNode;
  copy?: boolean;
  className?: string;
  twinClassNames?: {
    one?: string;
    two?: string;
  };
  gap?: number;
  textToCopy?: string;
}

const TwinValue = ({
  one,
  two,
  className,
  twinClassNames,
  copy,
  gap,
  textToCopy,
}: TwinValueProps) => {
  return (
    <Column gap={gap ?? 0.4} className={cn("twin_value", className)}>
      <div className={cn("twin_value__one", twinClassNames?.one)}>{one}</div>
      {copy ? (
        <CopyText
          text={trimLongString(two as string, 20) as string}
          textToCopy={textToCopy || (two as string)}
        />
      ) : (
        <div className={cn("twin_value__two", twinClassNames?.two)}>{two}</div>
      )}
    </Column>
  );
};

export default TwinValue;
