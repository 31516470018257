import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { ApproveManualAddressProps } from "./approve-manual-address";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { formatStatus } from "@/utils/helper/Helper";

interface Props extends Omit<ApproveManualAddressProps, "view" | "setView"> {
  view: PersonalIdVerification;
  setView: (view?: PersonalIdVerification) => void;
  loading: boolean;
}

export const IDVerificationModal = (props: Props) => {
  const { setView, showRevokeModal, view, loading, handleVerification, tab } = props;
  const { isLoading, data, isFetching } = personalReduxAPI.useGetUserEmailQuery(
    view.email
  );

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable, RenderCustom } = renderRedactedComponent(joinedLoader);

  const getButtonText = () => {
    if (!view) return "--";

    if (view.verification_status === "pending_verification") return "Verify User";
    return "Close Modal";
  };

  const generateView = () => {
    if (!view) return {};
    const { email, verification_status } = view;

    return {
      name: RedactedTable(cn(Util.safeText(data?.fname), Util.safeText(data?.lname))),
      email: RedactedTable(Util.safeText(email)),
      verification_status: RedactedTable(formatStatus(verification_status)),
    };
  };

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  return (
    <ActionModal
      visible={Boolean(view)}
      onCancel={() => setView(undefined)}
      altCancelFunc={() => {
        setView(undefined);
        showRevokeModal(view.id);
      }}
      big
      onClick={handleVerification}
      actionText={getButtonText()}
      btnColor="var(--primary-product-color)"
      className="address-verification-modal"
      loading={loading || joinedLoader || loading}
      title={`${formatWord(tab)} Details`}
      cancelText="Reject Verification"
      hideCancel={joinedLoader}
    >
      {
        <div className="biz-compliance-results">
          {RenderCustom(
            <ImagePreview
              imgData={[
                {
                  src: TypeIs.any(view?.front_image_url),
                  alt: "Image 1",
                  containerClassName: "biz-compliance-results__image-preview",
                },
                {
                  src: TypeIs.any(view?.user_selfie_url),
                  alt: "Image 1",
                  containerClassName: "biz-compliance-results__image-preview",
                },
              ]}
            />,
            { height: 278, width: 280, className: "shimmer redacted curved mt-20" }
          )}
          <div className="compliance-results__main">
            <InlineDetails
              direction={""}
              title={"Transaction Details"}
              simple
              amount={0}
              onClick={() => {}}
              content={Object.entries(generateView()).map(([key, value]) => ({
                label: formatWord(key),
                value,
              }))}
            />
          </div>
        </div>
      }
    </ActionModal>
  );
};

/** 
 *  <ActionModal
            visible={Boolean(view)}
            onCancel={() => setView(undefined)}
            altCancelFunc={() => {
              setView(undefined);
              showRevokeModal(view.id);
            }}
            big
            onClick={handleVerification}
            actionText={getButtonText()}
            btnColor="var(--primary-product-color)"
            className="address-verification-modal"
            loading={loading}
            title={`${formatWord(tab)} Details`}
            cancelText="Reject Verification"
          >
            {
              <div className="biz-compliance-results">
                
                <div className="compliance-results__main">
                  <InlineDetails
                    direction={""}
                    title={"Transaction Details"}
                    simple
                    amount={0}
                    className="no-shadow"
                    onClick={() => {}}
                    content={Object.entries(generateView()).map(([key, value]) => ({
                      label: formatWord(key),
                      value,
                    }))}
                  />
                </div>
              </div>
            }
          </ActionModal>
 */
