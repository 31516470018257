import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";

function formatStatus(status: string | number, refunded?: number) {
  let s;
  if (status === 0 || status === "inactive") {
    s = "Failed";
  }
  if (status === 1 || status === "active") {
    s = "Successful";
  }
  return (
    <div className={`bugiss-status ${s && s?.toLowerCase()}`}>
      <p>{s}</p>
    </div>
  );
}

const AtlasTransactions = () => {
  const { isDarkMode } = useThemeMode();

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Transactions">
        <SmartFilter
          pagination={{
            currentPage: 0,
            itemsPerPage: 0,
            totalItems: 0,
            onPageChange: function (page: number): void {
              throw new Error("Function not implemented.");
            },
            activeFilter: "All Transactions",
          }}
          searchTitle="Search Users by username..."
        />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "MERCHANT DETAILS",
              "REFERENCE",
              "DESCRIPTION",
              "AMOUNT",
              "TYPE",
              "TRANSACTION DATE",
            ]}
          >
            {[0, 1, 2, 3].map((n) => (
              <RavenTableRow
                key={n}
                one={
                  <TwinValue one="Nathaniel & Sons LTD" two="emmy4sure.web@gmail.com" />
                }
                two={
                  <Txreference
                    direction={n % 2 === 0 ? "credit" : "debit"}
                    reference="20230330..."
                    hideCopy
                  />
                }
                three={
                  <TwinValue
                    one="Adeeko Emmanuel"
                    two="2135876118 • United Bank for Africa"
                  />
                }
                four="₦250,000.00"
                five="Collections"
                six="Today • 5:24am"
              />
            ))}
          </RavenTable>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default AtlasTransactions;
