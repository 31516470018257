import React from "react";
import "../styles/dispute-info/dispute-info.css";
import { icons } from "@/assets/icons";
const DisputeInfo: React.FC = () => {
  return (
    <div className="bg-disputes__info">
      <div className="bg-disputes__info-main">
        <h3 className="bg-disputes__info-title">
          Transfers failed but i have not gotten a refund yet
        </h3>
        <span className="bg-disputes__info-date">Date • 24, Feb 2024 • 12:56pm</span>
      </div>
      <div className="bg-disputes__info-meta">
        <span className="bg-disputes__info-tx">Trx Ref • 766364578582</span>
        <div className="bg-disputes__info-actions">
          <button className="bg-disputes__info-action">{icons.dispute_users}</button>
          <button className="bg-disputes__info-action">{icons.dispute_plus}</button>
          <span className="bg-disputes__info-status">In Review</span>
        </div>
      </div>
    </div>
  );
};

export default DisputeInfo;
