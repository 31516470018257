import { SEARCH_DOES_NOT_EXIST } from "@/constants";
import { PersonalBuilder, pbGetHandler, PersonalBaseParams, createPersonalURL } from "..";

type GenericFraudUser = CreatePersonalPaginatedResponse<
  "users",
  PersonalBlockedUserModel[]
>;

export const PersonalFraudAPI = (builder: PersonalBuilder) => {
  return {
    getFraudBlockedUsers: builder.query<GenericFraudUser, PersonalBaseParams>({
      queryFn: (params) => {
        return pbGetHandler({
          params,
          route: createPersonalURL("blocked-users", SEARCH_DOES_NOT_EXIST),
        });
      },
    }),
    getFraudRagnarUsers: builder.query<GenericFraudUser, PersonalBaseParams>({
      queryFn: (params) => {
        return pbGetHandler({
          params,
          route: createPersonalURL("striked-users", SEARCH_DOES_NOT_EXIST),
        });
      },
    }),
    getFraudLienedUsers: builder.query<GenericFraudUser, PersonalBaseParams>({
      queryFn: (params) => {
        return pbGetHandler({
          params,
          route: createPersonalURL("liened-users", SEARCH_DOES_NOT_EXIST),
        });
      },
    }),
    getFraudPNDUsers: builder.query<GenericFraudUser, PersonalBaseParams>({
      queryFn: (params) => {
        return pbGetHandler({ params, route: "users-on-pnd" });
      },
    }),
  };
};
