import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ActionContextProvider } from "./context/ActionContext";
import { ProductThemeProvider } from "./context/ProductTheme";
import { ExportModalProvider } from "./hooks/useExportModal";
import { DetailsCheckerProvider } from "~pb/compliance/pages/details-checker/components/details-wrapper/context";
import { BizDetailsCheckerProvider } from "./apps/business/compliance/details-checker/components/details-wrapper/context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <ActionContextProvider>
      <React.StrictMode>
        <ProductThemeProvider>
          <ExportModalProvider>
            <DetailsCheckerProvider>
              <BizDetailsCheckerProvider>
                <App />
              </BizDetailsCheckerProvider>
            </DetailsCheckerProvider>
          </ExportModalProvider>
        </ProductThemeProvider>
      </React.StrictMode>
    </ActionContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
