import { RavenTable } from "@ravenpay/raven-bank-ui";
import { SettlementDataProps, SettlmentTable } from "../tables/PosTables";
import { usePagination } from "@/hooks/usePagination";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { useSelector } from "react-redux";
import { useQueryLoadingState } from "@/apps/pos/__internals__/hooks/useQueryLoadingState";
import { useNavigate } from "react-router-dom";
import { SETTLEMENT_STATUS } from "@/apps/pos/__internals__/constants/card";
import { Util } from "@/utils/utility";
import React, { useEffect, useState } from "react";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { $event } from "@/utils/events/eventEmitter";

const AirtimeHeader = [
  "MERCHANT DETAILS",
  "ACCOUNT DETAILS",
  "BANK DETAILS",
  "TYPE",
  "AMOUNT",
  "DATE",
  "ACTION",
];
export const PendingSettlementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  function onPageEvent(event: any) {
    console.log("onPageEvent", event);
    setCurrentPage(event.detail);
  }
  const { settlements, combinedLoading, isRedacting } = useSettlement({
    status: SETTLEMENT_STATUS.PENDING,
    page: currentPage,
  });

  useEffect(() => {
    $event.addEventListener("settlement_page_event", onPageEvent);

    // Cleanup function to remove the event listener
    return () => {
      $event.removeEventListener("settlement_page_event", onPageEvent);
    };
  }, []);

  return isEmpty(settlements) ? (
    <EmptyScreen loading={combinedLoading || isRedacting} />
  ) : (
    <RavenTable headerList={AirtimeHeader}>
      {Util.safeArray(settlements)?.map((data, idx) => (
        <SettlmentTable.Pending key={idx} {...data} />
      ))}
    </RavenTable>
  );
};

export const FailedSettlementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  function onPageEvent(event: any) {
    console.log("onPageEvent", event);
    setCurrentPage(event.detail);
  }

  useEffect(() => {
    $event.addEventListener("settlement_page_event", onPageEvent);

    // Cleanup function to remove the event listener
    return () => {
      $event.removeEventListener("settlement_page_event", onPageEvent);
    };
  }, []);
  const { settlements, combinedLoading, isRedacting } = useSettlement({
    status: SETTLEMENT_STATUS.FAILED,
    page: currentPage,
  });
  return isEmpty(settlements) ? (
    <EmptyScreen loading={combinedLoading || isRedacting} />
  ) : (
    <RavenTable headerList={AirtimeHeader}>
      {Util.safeArray(settlements)?.map((data, idx) => (
        <React.Fragment key={idx}>
          <SettlmentTable.Failed {...data} />
        </React.Fragment>
      ))}
    </RavenTable>
  );
};

const useSettlement = ({ status, page }: { status: SN; page: number }) => {
  const pagination = usePagination();

  const res = posReduxAPI.useGetSettlementsQuery({
    page: page,
    search: pagination.searchQuery,
    status: status,
  });
  const { data } = res;

  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  const settlements = data?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const combinedLoading = useQueryLoadingState(res);

  const navigate = useNavigate();

  return {
    pagination,
    res,
    data,
    SmartFilterProps,
    settlements,
    isRedacting,
    combinedLoading,
    navigate,
  };
};
