import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BusinessAPI } from "@/utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { toggleRedact } from "@/redux/actions/actions";
import { createDefaultReduxPaginationState, filterAPIQueryParams } from "@/utils/helpers";
import { BusinessBuilder } from "./api";

export const getExpenses = createAsyncThunk<unknown, BasePaginationPayload>(
  "/get-expenses",
  async (payload, thunkAPI) => {
    try {
      const { search_term } = payload;

      const url = search_term ? "expenses/search_expenses" : "expenses/fetch_expenses";

      type Response = APIResponse<BusinessExpenseResponse>;

      const res = await BusinessAPI.get<Response>(url, {
        params: filterAPIQueryParams(payload),
      });

      const response = APIResponseHandler.tryHandler({ response: res });
      thunkAPI.dispatch(toggleRedact(false));

      if (!response) return thunkAPI.rejectWithValue(res.data);
      thunkAPI.dispatch(SET_BUSINESS_EXPENSES(res.data.data.expenses));
      return res.data.data.expenses;
    } catch (error: any) {
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      thunkAPI.dispatch(toggleRedact(false));

      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getExpenseCategory = createAsyncThunk(
  "/get-expense-category",
  async (_, thunkAPI) => {
    try {
      const response = await BusinessAPI.get("expenses/fetch_expense_categories");
      const res = APIResponseHandler.tryHandler({ response });
      if (!res) return thunkAPI.rejectWithValue(response.data);

      thunkAPI.dispatch(SET_BUSINESS_EXPENSE_CATEGORIES(response.data.data));

      return response.data.data;
    } catch (error) {
      APIResponseHandler.catchHandler(error, thunkAPI);
    }
  }
);

const expenses: BusinessExpensesData = {
  ...createDefaultReduxPaginationState<BusinessExpenseRecord[]>([]),
  image_base_url: "",
};

const initialState = { loading: false, expenses, expenseCategory: [] };

export const businessExpensesSlice = createSlice({
  name: "business-expenses",
  initialState,
  reducers: {
    SET_BUSINESS_EXPENSES: (state, action: PayloadAction<BusinessExpensesData>) => {
      state.expenses = action.payload;
    },
    SET_BUSINESS_EXPENSE_CATEGORIES: (state, action) => {
      state.expenseCategory = action.payload;
    },
  },

  extraReducers: (builder) => {
    const builderHandler = new ReduxBuilderHandler(builder, initialState);

    builderHandler.handleStates(getExpenses);
    builderHandler.handleStates(getExpenseCategory);
  },
});

export const { SET_BUSINESS_EXPENSES, SET_BUSINESS_EXPENSE_CATEGORIES } =
  businessExpensesSlice.actions;

export default businessExpensesSlice.reducer;

export const BusinessExpenseAPI = (builder: BusinessBuilder) => {
  type ExpenseCategory = {
    expense_categories: string[];
  };

  return {
    getExpenseCategories: builder.query<ExpenseCategory, void>({
      queryFn: async () => {
        try {
          const response = await BusinessAPI.get("expenses/fetch_expense_categories");
          APIResponseHandler.tryHandler({ response, hideSuccessToast: true });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
