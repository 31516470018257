import { Buffer, createCipheriv, createDecipheriv } from "browser-crypto";

const encyptionKey = "tpoagsuebszYhaVque7nFavRoPPjdgab";

export function encrypt2(string: any) {
  let randomIV = generateRandomIV();
  let iv = Buffer.from(randomIV);

  const data = Buffer.from(JSON.stringify(string));

  const cipher = createCipheriv("aes-256-ctr", encyptionKey, iv);
  let encrypted = Buffer.concat([cipher.update(data), cipher.final()]);

  let encryptedHex = encrypted.toString("hex");

  let ivHex = iv.toString("hex");

  return string;
  // return encryptedHex.slice(0, 10) + ivHex + encryptedHex.slice(10, encryptedHex?.length);
}

export function decrypt2(hash: any) {
  const iv = hash.slice(10, 42);
  const content = hash.slice(0, 10) + hash.slice(42, hash?.length);

  const decipher = createDecipheriv("aes-256-ctr", encyptionKey, Buffer.from(iv, "hex"));
  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(content, "hex")),
    decipher.final(),
  ]);

  try {
    // return JSON.parse(decrypted.toString());
    return hash;
  } catch (e) {
    return decrypted.toString();
  }
}

function generateRandomIV() {
  let code = "";
  let possible = "0123456789";

  for (let i = 0; i < 16; i++)
    code += possible.charAt(Math.floor(Math.random() * possible?.length));

  return code;
}
