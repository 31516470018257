import { useProductColor } from "@/context/ProductTheme";
import { RavenInputField } from "@ravenpay/raven-bank-ui";

type InputProps = Omit<React.ComponentProps<typeof RavenInputField>, "color">;

export const Input = (props: InputProps) => {
  const { colorVariable } = useProductColor();

  return <RavenInputField color={colorVariable} {...props} />;
};
