import { useProductColor } from "@/context/ProductTheme";
import { RavenModal } from "@ravenpay/raven-bank-ui";

import { useLocation, useNavigate } from "react-router-dom";

interface SessionEndModalProps {
  visible: boolean;
  onCancel: () => void;
  onClick: () => void;
}

const SessionEndModal = ({ visible, onCancel, onClick }: SessionEndModalProps) => {
  const location = useLocation();
  const { colorVariable } = useProductColor();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSave = location.pathname.split("/")[1];

  return (
    <RavenModal
      btnColor={colorVariable}
      onBtnClick={onClick}
      // onBtnClick={handleClear}
      visble={visible}
      btnLabel="Back to login"
      onClose={() => {
        // actionCtx?.setIsLogout(false);
      }}
      outerClose={true}
      dontShowClose={true}
    >
      <div
        style={{
          marginBottom: "1rem",
          marginTop: "1.5rem",
        }}
        className="session-expired-wrap"
        // style={{marginTop: ""}}
      >
        <p
          style={{
            fontWeight: "700",
            marginBottom: ".5rem",
            fontSize: "1.8rem",
          }}
          className="title"
        >
          Session Expired
        </p>
        <p className="text grey-white-color">
          We apologise for any inconvenience this may have caused, but we take your
          security very seriously. Please navigate back to our login page to re-enter your
          credentials and restart your session.
        </p>
      </div>
    </RavenModal>
  );
};

export default SessionEndModal;
