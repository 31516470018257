import React from "react";
import "../styles/dispute-header/dispute-header.css";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
const DisputeHeader: React.FC = () => {
  return (
    <div className="bg-disputes__header">
      <h2 className="bg-disputes__header-id">#2398819</h2>
      <div className="bg-disputes__header-actions">
        <RavenButton color="white-light" className="bg-disputes__header-button">
          View Transaction
        </RavenButton>
        <button className="bg-disputes__header-button bg-disputes__header-button--success">
          <span className="bg-disputes__header-button-icon">
            {icons.check_circle_green}
          </span>
        </button>
      </div>
    </div>
  );
};

export default DisputeHeader;
