import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { setCookie } from "@/utils/helper/Helper";
import { SET_BUSINESS_TRANSACTIONS } from "./transaction";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { ReduxBuilderHandler } from "@/utils/api";
import { fetchAirtime } from "./business";

export const fetchPaymentLinks = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/businesses/payment-links",
  async (payload, thunkAPI) => {
    const params = Util.safeObject({
      range: payload?.range ?? "3",
      business_email: payload?.business_email ?? "",
      business_id: payload?.business_id ?? "",
      period_type: payload.period_type ?? "",
      search_term: payload.search_term ?? "",
      per_page: payload.per_page ?? 1,
      current_page: payload?.current_page ?? "",
      date1: payload.date_1 ?? "",
      date2: payload.date_2 ?? "",
      status: payload.status ?? "",
      payment_link_type: payload.payment_link_type,
      paginate: payload.paginate,
      ...payload,
    });

    try {
      const data = await axios.get(
        `payment_links/get_filter_or_search_payment_links_with_businesses`,
        {
          params,
        }
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          // toast.error(data?.data.data, {
          //   position: "top-right",
          // });
        } else {
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(
          SET_PAYMENT_LINKS(data?.data?.data?.payment_link_transactions_records)
        );
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchPaymentLinkTrx = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/businesses/fetchPaymentLinkTrx",
  async (payload, thunkAPI) => {
    const params = Util.safeObject({
      range: payload?.range ?? "3",
      business_email: payload?.business_email ?? "",
      business_id: payload?.business_id ?? "",
      period_type: payload.period_type ?? "",
      search_term: payload.search_term ?? "",
      per_page: payload.per_page ?? 1,
      current_page: payload?.current_page ?? "",
      date_1: payload.date_1 ?? "",
      date_2: payload.date_2 ?? "",
      payment_link_type: payload.payment_link_type,
      payment_link_id: payload.payment_link_id,
    });

    try {
      const data = await axios.get(
        `payment_links/get_single_payment_link_and_transaction_with_business`,
        {
          params,
        }
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          // toast.error(data?.data.data, {
          //   position: "top-right",
          // });
        } else {
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_PAYMENT_LINKS_TRX(data?.data?.data));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const deletePaymentLink = createAsyncThunk<
  any,
  {
    business_email: string;
    id: SN;
  }
>("/businesses/delete-payment-link", async (payload, thunkAPI) => {
  const params = Util.safeObject({
    business_email: payload.business_email,
    id: payload.id,
  });

  try {
    const data = await axios.post(`payment_links/delete_payment_link_data`, params);

    if (data?.data?.status === "fail") {
      if (typeof data?.data?.data === "string") {
        // toast.error(data?.data.data, {
        //   position: "top-right",
        // });
      } else {
      }
      thunkAPI.dispatch(toggleRedact(false));

      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      // toast.success(data?.data?.message, {
      //   position: "top-right",
      // });

      thunkAPI.dispatch(toggleRedact(false));
      // thunkAPI.dispatch(SET_PAYMENT_LINKS_TRX(data?.data?.data));
      return data.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});
const initialPaymentLinkData = {
  total: 0,
  per_page: "",
  offset: 0,
  to: 0,
  last_page: 0,
  current_page: "",
  from: 0,
  data: [],
  first_page_url_params: "",
  prev_page_url_params: "",
  more_page_url_params: "",
  next_page_url_params: "",
  last_page_url_params: "",
};
const initialState: businessPaymentLinksSlice = {
  loading: false,
  payment_links: initialPaymentLinkData,
  payment_links_trx: {
    payment_link: initialPaymentLinkData["data"][0],
    payment_link_transactions_records: {},
  },
};

export const businessPaymentLinksSlice = createSlice({
  name: "business-bills",
  initialState,
  reducers: {
    SET_PAYMENT_LINKS: (state, action: PayloadAction<any>) => {
      state.payment_links = action.payload;
    },
    SET_PAYMENT_LINKS_TRX: (state, action: PayloadAction<any>) => {
      state.payment_links_trx = action.payload;
    },
  },

  extraReducers: (builder) => {
    const builderHandler = new ReduxBuilderHandler(builder, initialState);
    builderHandler.handleStates(fetchPaymentLinks);
    builderHandler.handleStates(fetchPaymentLinkTrx);
    builderHandler.handleStates(deletePaymentLink);
  },
});

export const { SET_PAYMENT_LINKS, SET_PAYMENT_LINKS_TRX } =
  businessPaymentLinksSlice.actions;

export default businessPaymentLinksSlice.reducer;
