import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";
import { usePagination } from "@/hooks/usePagination";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SETTLEMENT_STATUS } from "../__internals__/constants/card";
import { useEffect } from "react";
import { $event } from "@/utils/events/eventEmitter";

const tabElements = ["Transfer", "Airtime", "Data", "Electricity","Cable TV", "Betting"];

const PageBaseURL = "/pos-reversal-center";

export const PosReversalCenter = () => {
  const pagination = usePagination();

  const location = useLocation();



  return (
    <DashboardLayout>
      <PageLayout pageTitle="Reversal Center">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="pending-settlement"
            ></OutletTab.Tabs>
            <Gap size={15} />
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
