import { icons } from "@/assets/icons";
import TabComponent from "@/components/common/Tabs";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { XStack } from "@/components/common/stacks";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { TypeIs, detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { useState } from "react";

interface DisputeModalProps {
  onCancel(): void;
  row: NewPersonalHelpLog;
  refetch(): void;
}

const tabs = ["Dispute Details", "Transaction"];

export const DisputeModal = (props: DisputeModalProps) => {
  const { onCancel, row, refetch } = props;
  const [isLoading, setIsLoading] = useState(false);

  const renderButtonText = () => {
    const defaultT = {
      textColor: "#755AE2",
      background: "#FAFAFF",
      label: "This Transaction Issue has been resolved already Gracias!",
      next: "in-review" as const,
    };
    if (!row) return defaultT;

    switch (row.status) {
      case "pending":
        return {
          textColor: "#755AE2",
          background: "#FAFAFF",
          label: "Set to In Review",
          next: "in-review" as const,
        };
      case "in-review":
        return {
          textColor: "#1ACE37",
          background: "#E8FFF6",
          label: "Set to Resolved",
          next: "resolved" as const,
        };
      case "resolved":
        return defaultT;
    }
  };

  const controls = renderButtonText();

  const updateDisputeStatus = async () => {
    setIsLoading(true);
    try {
      const res = await PersonalAPIService.updateGetHelpStatus({
        id: row.id,
        status: controls.next,
      });
      if (res.data) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { email, transaction } = row;

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={updateDisputeStatus}
      title="Transaction Details"
      hideCancel
      btnColor={controls.background}
      btnTextColor={controls.textColor}
      actionText={controls.label}
      className="dispute-modal"
      loading={isLoading}
      hideNormalButton={row.status === "resolved"}
    >
      <div className="expense__details">
        <TabComponent defaultValue={tabs[0]} variant="secondary">
          <TabComponent.Tabs tabs={tabs} className="tab-space-evenly" />

          <TabComponent.Content value={tabs[0]}>
            <InlineDetails
              direction=""
              title={"Transaction Details"}
              simple
              className="no-shadow"
              amount={0}
              onClick={() => {}}
              content={[
                { label: "User Email", value: email },
                {
                  label: "Reference",
                  value: Util.safeText(row.transaction_id),
                  copy: true,
                },
                { label: "Description", value: Util.safeText(row.message) },
                { label: "Type", value: Util.safeText(row.transaction_type) },
                { label: "Status", value: TypeIs.any(formatStatus(row.status)) },
                { label: "Date Logged", value: formatDateTime(row.created_at) },
              ]}
            />
          </TabComponent.Content>
          <TabComponent.Content value={tabs[1]}>
            <InlineDetails
              direction=""
              title={"Transaction Details"}
              simple
              className="no-shadow"
              amount={0}
              onClick={() => {}}
              content={[
                { label: "Amount", value: transaction.amount },
                {
                  label: "Balance Before",
                  value: Util.safeValue(
                    formatNumberToCurrency(transaction.b_before, transaction.currency)
                  ),
                },
                {
                  label: "Balance After",
                  value: Util.safeValue(
                    formatNumberToCurrency(transaction.b_after, transaction.currency)
                  ),
                },
                { label: "Description", value: Util.safeValue(transaction.description) },
                {
                  label: "Reversal Status",
                  // fix this reversal status
                  value: TypeIs.any(formatStatus(detectStatus(transaction.reversed))),
                },
                { label: "Date Logged", value: formatDateTime(transaction.created_at) },
              ]}
            />
          </TabComponent.Content>
        </TabComponent>

        {row.status === "resolved" && (
          <XStack className="resolved-footer">
            {icons.check_circle_green}
            <p>This Transaction Issue has been resolved already Gracias!</p>
          </XStack>
        )}
      </div>
    </ActionModal>
  );
};
