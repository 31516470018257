import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";
import { AllBills, createStatusDropdownForBills } from "../combined-tables/bills/utils";
import { capitalizeFirstLetter } from "@/utils/helper/Helper";
import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";
import { cleanWhiteSpace } from "@/utils/colorConvert";

export const usePbBillerExport = (tab: AllBills, email?: string) => {
  const options = createStatusDropdownForBills(tab);

  const value = usePersonalExportModal({
    fileName: `${capitalizeFirstLetter(tab)} Table`,
    filterParams: [
      {
        key: "status",
        label: "Status",
        options,
        type: "checkbox",
        defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
      },
      {
        key: "email",
        type: "email",
        label: "Email",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: true,
      },
    ],
    route: cleanWhiteSpace("/", tab, "-recharges/export") as any,
    title: `Export ${capitalizeFirstLetter(tab)} Record`,
  });

  return value;
};
