import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../../shared";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { STRING_TRIM_LENGTH } from "@/constants";
import { getPBPhysicalCardsStatus } from "../utils";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus, IconVault } from "@/utils/helper/Helper";
import { XStack } from "@/components/common/stacks";
import { assertCardType } from "@/assets/images/cards";
import { CopyText } from "@/components/common/copyCheck";
import { useNavigate } from "react-router-dom";
import { icons } from "@/assets/icons";
import Dropdown from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";

type Props = CreateTableRowProps<{ data: PersonalPhysicalCardModel[] }>;

export const PBPhysicalCardsTable = (props: Props) => {
  const { data, redactedFn, ctx } = props;
  const { RedactedTable } = redactedFn;
  const navigate = useNavigate();

  return (
    <RavenTable
      headerList={[
        "USER DETAILS",
        "CARD NUMBER",
        "EXP. DATE",
        "STATUS",
        "DATE CREATED",
        " ",
      ]}
    >
      {data.map((card) => {
        const { id, created_at, status, card_brand, masked_pan, email } = card;

        const cardStatus = getPBPhysicalCardsStatus(status);

        const RowOne =
          ctx === "page"
            ? RedactedTable(<ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />)
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            two={RedactedTable(
              <XStack gap={1}>
                <img className="pb__avatar" src={assertCardType(card_brand)} alt="" />
                {masked_pan ? <CopyText text={masked_pan} /> : "--"}
              </XStack>
            )}
            three={RedactedTable(formatDateTime(created_at))}
            four={RedactedTable(formatStatus(cardStatus))}
            five={RedactedTable(Util.safeText(formatDateTime(created_at)))}
            six={RedactedTable(
              <Dropdown
                rightPosition
                items={[
                  { label: "View Details", img: IconVault(icons.arrow_circle_broken) },
                ]}
                buttonChild={() => <ActionDrop more />}
              />
            )}
            onRowClick={() => navigate(`/personal-cards/physical/${id}`)}
          />
        );
      })}
    </RavenTable>
  );
};
