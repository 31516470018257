import { cn } from "@/utils/colorConvert";
import "./index.css";

export interface ItemProps extends IClass {
  title: string;
  children: SN | React.ReactNode;
}

export const MainContentCol = ({ children, className }: IProps) => {
  return <div className={cn("main-content__col", className)}>{children}</div>;
};

export const MainContentColItem = (props: ItemProps) => {
  const { children, title, className } = props;

  return (
    <div className={cn("main-content__col--item", className)}>
      <small>{title}</small>
      {["string", "number"].includes(typeof children) ? <p>{children}</p> : children}
    </div>
  );
};

MainContentCol.Item = MainContentColItem;
