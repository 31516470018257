import { cn } from "./colorConvert";
import { formatDateTime } from "./helper/Helper";
import { Validator } from "./validator";

/** Accepts a modified prop definition and returns the original prop type
 * @param props: The modified object you want to convert
 * @param uniqueIdentifier: A unique identifier in the modified type
 * @param  elementType: The Prop type of the elements to return, could be string or Component
 *
 * @example
 * ```jsx
 * type ButtonProps = {[T in keyof BtnProps as `btnProp-${T}`]: BtnProps[T]};
 * <Button {...renderValidProps(props, "btnProp-", Button)}>
 *		Search
 *</Button>
 *```
 * @see {@link  https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as} for mapped types example
 */
export const getCombinedObjectProps = <identifier extends string>(
  props: Record<string, unknown>,
  uniqueIdentifier: identifier
) => {
  const key = "custom";

  const newObj = Object.keys(props).reduce(
    (acc, currentKey) => {
      if (currentKey.includes(uniqueIdentifier)) {
        const newKey = currentKey.replaceAll(uniqueIdentifier, "");

        return {
          ...acc,
          [key]: { ...acc[key], [newKey]: props[currentKey] },
        };
      }
      return {
        ...acc,
        defaultProps: { ...acc.defaultProps, [currentKey]: props[currentKey] },
      };
    },
    { [key]: {}, defaultProps: {} }
  );

  return newObj;
};

/** As seen on twitter, a clean way to create immediately invoked function expression */
export const iife = <T>(fn: () => T) => fn();

export const setFnToUndefined = <T, U>(fn: (val?: T) => U) => {
  return () => fn();
};

export const isDevOnly = <T>(arg: T) => {
  if (process.env.NODE_ENV === "development") return arg;
};

export const createTableRecordTitle = (title: string) => {
  const str = cn(title, formatDateTime(new Date()));
  return str.replaceAll(" ", "_");
};

export const isEmail = (str: string) => {
  const res = Validator.email("Email").safeParse(str);
  return res.success;
};
