import { PBPhysicalCardsTable } from "@/apps/personal/__internals__/combined-tables/cards/physical-cards/tables";
import { usePhysicalPersonalCardFilter } from "@/apps/personal/__internals__/internal-hooks/cards";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { GLOBAL_PER_PAGE } from "@/constants";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";

export const PersonalPhysicalCards = () => {
  const { filterArgs, filterProps, pagination } = usePhysicalPersonalCardFilter();
  const { data, isFetching, isLoading } = personalReduxAPI.useGetPhysicalCardsQuery({
    page: pagination.currentPage,
    per_page: GLOBAL_PER_PAGE,
    status: filterArgs.status,
    search: pagination.searchQuery,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (!data || isLoading) return <TransferEmptyScreen loading={joinedLoader} />;

  return (
    <>
      <SmartFilter
        searchTitle="Search by card pan or email"
        {...SmartFilterProps}
        {...filterProps}
        hideExport
        tableExport={{ data: data.physicalCards }}
      />
      {data.physicalCards.length > 0 ? (
        <PBPhysicalCardsTable
          ctx="page"
          data={data.physicalCards}
          redactedFn={redactedFn}
        />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} />
      )}
    </>
  );
};
