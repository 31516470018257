import { HTMLAttributes } from "react";
import { cn } from "../../../utils/colorConvert";
import "./styles/index.css";
import { formatStatus } from "@/utils/helper/Helper";
import Redacted from "../shimmer/redact";

interface DashboardInfoCardProps {
  title: string;
  value: string;
  stats?: React.ReactNode;
  isPositive?: boolean;
  isStatus?: boolean;
  loading?: boolean;
}

export const DashboardInfoCard = (props: DashboardInfoCardProps) => {
  const { title, value, stats, isPositive, isStatus, loading } = props;

  return (
    <article className="dashboard_info_card bdr-eee-333">
      {loading ? (
        <Redacted.Labelled />
      ) : (
        <>
          <h4 className="dashboard_info_card__title">{title}</h4>
          {isStatus ? (
            formatStatus(value)
          ) : (
            <p className="dashboard_info_card__desc text-white-black">{value}</p>
          )}
          {stats && (
            <p
              className={cn(
                isPositive ? "trend-up" : "down-trend",
                "dashboard_info_card__status"
              )}
            >
              {stats}
            </p>
          )}
        </>
      )}
    </article>
  );
};

interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  /** Number defaults to 2, and is parsed as 2rem. */
  marginTop?: number;
  columns?: number;
}

const Wrapper = (props: WrapperProps) => {
  const { marginTop = 2, style, className, columns = 4, ...prop } = props;

  return (
    <div
      {...prop}
      style={
        {
          ...style,
          "--dashboardInfoCard-top-offset": `${marginTop}rem`,
          "--dashboardInfoCard-columns": columns,
        } as React.CSSProperties
      }
      className={cn("dashboard-info-card__row", className)}
    />
  );
};

DashboardInfoCard.Wrapper = Wrapper;
