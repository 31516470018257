/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactElement, useEffect, useState } from "react";
import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import { RavenInputField, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
  mapHotkey,
  trimLongString,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";

import ActionModal from "../../../../components/common/modal/ActionModal";
import TwinValue from "@/components/common/twinValue";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "../styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import { detectStatus } from "@/utils/helpers";
import ImagePreview from "@/components/common/imagePreview";
import { cn } from "@/utils/colorConvert";
import Gap from "@/components/common/styleComponents/Gap";
import { XStack } from "@/components/common/stacks";
import { FaLink } from "react-icons/fa";
import { BIZ_VERIFICATION_STATUS } from "../../__internals__/constants/index";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { CopyText } from "@/components/common/copyCheck";

const Tabs = [
  {
    label: "BVN Verification",
    value: "bvn",
  },
  { label: "NIN Verification", value: "nin" },

  {
    label: "Address Verification",
    value: "address",
  },
  {
    label: "CAC Verification",
    value: "cac",
  },
  {
    label: "Business Details",
    value: "business_info",
  },
  {
    label: "Details Checker",
    value: "details_checker",
  },
];

const BAddressVerification = ({ tab }: { tab?: string }) => {
  const [empty, setEmpty] = useState(false);
  const [revokeModal, showRevokeModal] = useState({ chi: "", on: false });
  const [declineModal, showDeclineModal] = useState({ chi: "", on: false });
  const [filterValue, setFilterValue] = useState<any>({});

  const [verify, showVerifyModal] = useState<any>({
    chi: "",
    on: false,
    address_url: "",
  });
  const [view, setView] = useState<{ on: boolean; chi: Partial<VerificationDetails> }>({
    chi: {},
    on: false,
  });
  // const navigate = useNavigate();
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<{
    label: string;
    value: string;
  }>({ label: "BVN Verification", value: "bvn" });

  const tableHeader = [
    "Business Details",
    "State",
    "Full Address",
    "Type",
    "Date",
    "Status",
    " ",
  ];

  const dropdown = [
    {
      label: `Verify ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.info_circle),
      func: showVerifyModal,
    },
    {
      label: `Revoke ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
    {
      label: `Decline ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showDeclineModal,
    },
  ] as const;

  function returnDropdown(status: SN) {
    if (status === 1) {
      return [dropdown[0], dropdown[2]];
    } else if (status === 3) {
      return [dropdown[1]];
    } else return [dropdown[0]];
  }
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "address",
        // business_email: "",
        status: BIZ_VERIFICATION_STATUS.PENDING,
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, currentPage, searchQuery, filterValue]);

  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  type Business = BusinessComplianceModel["business"];
  type Verifications = BusinessComplianceModel["business_verification_details"];
  const businesses: Business = business_compliances?.data?.business;

  const compliance: Verifications[] =
    business_compliances?.data?.business_verification_details;

  const assertVeri = (email: string): any => {
    var data: any = compliance[email as any];

    const l = data?.filter(
      (d: VerificationDetails) => d?.id_verification_type === "address"
    )[0];

    if (l) {
      return l;
    } else {
      return [];
    }
  };
  const { isRedacting } = useSelector((store: RootState) => store.general);

  const handleRevokeDecline = async () => {
    const data: Partial<Business[0]> = view.chi;
    const payload = {
      status: 0,
      business_id: view.chi.business_owner_id,
      address_decline_reason: declineModal.chi,
      action_to_take: declineReason.find((d) => d.reason === declineModal.chi)?.action,
      address_report_url: verify.address_url,
      deny_or_revoke: assertIsRevoke(data?.address ?? 0),
    };

    const resp = await dispatch(
      BugissApi.business.verifyDeclineAddressVerification(payload)
    );
    if (resp?.payload) {
      showRevokeModal({
        on: false,
        chi: "",
      });

      showVerifyModal({
        on: false,
        chi: "",
      });

      showDeclineModal({
        on: false,
        chi: "",
      });
    }

    setView({ on: false, chi: {} });
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "address",
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
        status: filterValue?.filterValue?.split(",")[0],
      })
    );
  };

  const handleVerifyAddress = async () => {
    const payload = {
      status: 1,
      business_id: view.chi.business_owner_id,
      address_report_url: verify.address_url,
      deny_or_revoke: "",
    };

    const resp = await dispatch(
      BugissApi.business.verifyDeclineAddressVerification(payload)
    );
    if (resp?.payload) {
      showRevokeModal({
        on: false,
        chi: "",
      });

      showVerifyModal({
        on: false,
        chi: "",
      });

      showDeclineModal({
        on: false,
        chi: "",
      });
    }

    setView({ on: false, chi: {} });
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "address",
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
        status: filterValue?.filterValue?.split(",")[0],
      })
    );
  };
  const navigate = useNavigate();

  const reasonReq = businessReduxAPI.useFetchReasonsQuery(
    { type: "address_decline" },
    { skip: !declineModal?.on }
  );

  const declineReason = reasonReq?.data?.reasons ?? [];

  function formatDeclineReason() {
    const newReason = declineReason.map((reason) => {
      return {
        label: Util.cleanText(reason?.reason),
        value: reason?.id,
      };
    });

    return newReason;
  }

  useEffect(() => {
    setSelectedTab(Tabs.find((d) => d.value === "address") as any);
  }, [tab]);
  return (
    <>
      <div>
        <div style={{ marginTop: "2rem" }}></div>
        <SmartFilter
          page="compliance"
          // simple
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
            // reset current page to 1 on filter
            setCurrentPage(1);
          }}
          defaultFilterBy="status"
          filters={[
            {
              label: "Status",
              filterBy: "status",
              options: [
                {
                  label: "Pending",
                  value: "0",
                },
                {
                  label: "Completed",
                  value: "1",
                },
              ],
            },
          ]}
          onSearchChange={(e: string) => setSearchQuery(e)}
          searchTitle={`Search ${selectedTab.label}...`}
          pagination={{
            currentPage: currentPage,
            itemsPerPage: Number(business_compliances.per_page),
            totalItems: Number(business_compliances.total),
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
            activeFilter: `All ${selectedTab}`,
          }}
        />

        {!businesses || businesses?.length === 0 || (loading && !isRedacting) ? (
          <EmptyScreen loading={loading} />
        ) : (
          <div>
            <div className="table">
              <RavenTable action={false} className="table__main" headerList={tableHeader}>
                {businesses?.map((data, index) => {
                  const veriData: VerificationDetails = assertVeri(data.email);

                  // console.log(data, "the within", data.email);
                  return (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => {
                        // if (!veriData.flow_id) {
                        //   toast.error("No verification details found for user");
                        // } else
                        setView({
                          on: true,
                          chi: {
                            ...veriData,
                            ...data,
                            business_owner_id: data.id,
                            email: data.email,
                          },
                        });
                      }}
                      {...{
                        one: loading ? (
                          <Redacted.Labelled />
                        ) : (
                          <TwinValue
                            one={Util.safeValue(data.business_name)}
                            two={Util.safeValue(data.email)}
                          />
                        ),
                        two: loading ? (
                          <Redacted.Labelled />
                        ) : (
                          <TwinValue
                            one={Util.safeValue(data.business_details_from_central.state)}
                            two={Util.safeValue(data.business_details_from_central.lga)}
                          />
                        ),
                        three: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(
                            data.business_details_from_central.business_address
                          )
                        ),
                        four: loading ? <Redacted.Table /> : "Automatic",
                        five: loading ? (
                          <Redacted.Table />
                        ) : (
                          formatDateTime(data.created_at)
                        ),
                        six: loading ? (
                          <Redacted.Table />
                        ) : (
                          <div>
                            {formatStatus(detectStatus(data.address, "compliance"))}
                          </div>
                        ),
                        seven: loading ? (
                          <Redacted.Avatar />
                        ) : (
                          <div>
                            <Dropdown
                              items={returnDropdown(data.address)}
                              defaultValue={dropdown[0]}
                              onChange={(e: any) => {
                                e.event.stopPropagation();

                                setView({
                                  on: false,
                                  chi: {
                                    ...veriData,
                                    ...data,
                                    business_owner_id: data.id,
                                  },
                                });
                                e.func({ chi: "", on: true });
                              }}
                              className="ragna-dropdown"
                              buttonChild={() => (
                                <ActionDrop
                                  more
                                  // onMoreClick={() => {
                                  //   navigate("/personal-users/re43rfs");
                                  // }}
                                />
                              )}
                            />
                          </div>
                        ),
                      }}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </div>
        )}
      </div>

      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          showRevokeModal({ chi: "", on: false });
        }}
        onClick={handleRevokeDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${selectedTab.label}?`}
      ></ActionModal>

      <ActionModal
        visible={declineModal.on}
        onCancel={() => {
          showDeclineModal({ chi: "", on: false });
        }}
        onClick={handleRevokeDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Decline"
        btnColor="#FF0F00"
        body="Are you sure to decline this verification data? You can always come back to perform the action.."
        title={`Decline ${selectedTab.label}?`}
      >
        <>
          <p>
            Are you sure to decline this verification data? You can always come back to
            perform the action..
          </p>
          <Gap size={20} />
          <RavenInputField
            // selectMenuOpen={true}
            color={"black-light"}
            onChange={(e: any) => {
              showDeclineModal({
                ...declineModal,
                chi: e?.label,
              });
            }}
            type="select"
            selectOption={formatDeclineReason()}
            value={{
              value: declineReason.find((d) => d?.reason === declineModal.chi)?.reason,
              label:
                declineReason.find((d) => d?.reason === declineModal.chi)?.reason ?? "",
            }}
            label={"Decline reason*"}
            placeholder="e.g 'Address not on map'"
          />
        </>
      </ActionModal>

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        loading={loading}
        cancelText="No, Cancel"
        onClick={handleVerifyAddress}
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure to verify this compliance details? You can always come back to perform the action.."
        title={`Verify ${selectedTab.label}?`}
      >
        <>
          <p>
            Are you sure to verify this compliance details? You can always come back to
            perform the action..
          </p>
          <Gap size={20} />
          <RavenInputField
            color={"black-light"}
            onChange={(e: any) => {
              showVerifyModal({
                ...verify,
                address_url: e.target.value,
              });
            }}
            type="text"
            value={verify.address_url}
            label={"Map URL*"}
            placeholder="e.g 'input the address map url'"
          />
        </>
      </ActionModal>

      <div className={cn(selectedTab.value, "biz-info-modal")}>
        <ActionModal
          visible={view.on}
          onCancel={() => {
            setView({ chi: {}, on: false });
          }}
          big={true}
          cancelText="No, Cancel"
          actionText="View Full Profile"
          onClick={() => {
            navigate(
              `/business-businesses/${
                view.chi.email && view.chi.email?.length > 5
                  ? view.chi.email
                  : view?.chi?.alt_email
              }/transactions`
            );
          }}
          btnColor="black"
          loading={loading}
          hideCancel
          body="Are you sure to verify this compliance data? You can always come back to perform the action.."
          title={`${selectedTab.label}`}
        >
          {
            <div className="biz-compliance-results">
              {renderAddressImage(view.chi)}

              <div className="compliance-results__main">
                <InlineDetails
                  direction={""}
                  className="no-shadow"
                  title={"Verification Details"}
                  simple
                  isTransaction={
                    selectedTab.value === "business_info" || selectedTab.value === "cac"
                  }
                  amount={0}
                  onClick={() => {}}
                  content={addressComplianceData(view.chi)}
                />
              </div>
            </div>
          }
        </ActionModal>
      </div>
    </>
  );
};

export default BAddressVerification;

function renderAddressImage(data: Partial<Business>): React.ReactNode {
  return (
    <ImagePreview
      imgData={[
        {
          src: data?.business_details_from_central?.address_image ?? "",
          alt: "image",
          isMap: false,
        },
        {
          src: renderGoogleMap(
            data?.business_details_from_central?.business_address ?? ""
          ) as any,
          alt: "business-address",
          isMap: true,
        },
      ]}
    />
  );
}

function addressComplianceData(data: Partial<Business>): {
  label: string;
  value: string | ReactElement;
  copy?: boolean | undefined;
  onClick?: () => void;
  trim?: boolean | undefined;
}[] {
  return [
    {
      label: "Business",
      value: Util.safeValue(data.business_name),
    },

    {
      label: "Email Address",
      value: Util.safeValue(data.business_owner_email),
    },

    {
      label: "LGA",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.lga)
      ),
    },

    {
      label: "Status",
      value: formatStatus(
        detectStatus(Number(data?.address), "compliance")
      ) as ReactElement,
    },

    {
      label: "Full Address",
      copy: true,
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.business_address)
      ),
    },
    {
      label: "Address URL",
      copy: true,
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.address_report_url)
      ),
    },

    {
      label: "Document URL",
      copy: true,
      trim: true,
      onClick: () =>
        Util.openInNewTab(data?.business_details_from_central?.address_image as string),
      value: data?.business_details_from_central?.address_image as unknown as string,
    },
    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data.created_at ?? "")),
    },
  ];
}

function renderGoogleMap(address: string) {
  return (
    <iframe
      id="map-canvas"
      frameBorder="0"
      className="map_part"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyALt4gqjbZPw3ISNJs4CBjIzpMfeBBkPbI&q=${address}`}
    />
  );
}

function assertIsRevoke(status: SN) {
  if (status === BIZ_VERIFICATION_STATUS.SUCCESSFUL) {
    return "revoke";
  } else return "deny";
}
