import { OutletTab } from "@/components/common/OutletTab";
import { TabsElement } from "@/components/common/Tabs";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";

const tabs: TabsElement[] = [
  {
    value: "savings",
    label: "Savings",
    dropdown: {
      tabs: [
        { label: "Fixed Savings", value: "fixed" },
        { label: "New Fixed Savings", value: "fixed_new" },
        { label: "Target Savings", value: "target" },
        { label: "New Target Savings", value: "target_new" },
        { label: "HIFA Savings", value: "hifa" },
        { label: "Global Savings", value: "global" },
        { label: "SpendXSave", value: "spendxsave" },
        { label: "Pocket", value: "pocket" },
      ],
    },
  },
];

export const PersonalInsightsPage = () => {
  return (
    <DashboardLayout>
      <PageLayout>
        <OutletTab>
          <OutletTab.Tabs baseURL="/personal-insights" tabs={tabs} />
          <div className="mt-20 h-full">
            <OutletTab.Outlet />
          </div>
        </OutletTab>
      </PageLayout>
    </DashboardLayout>
  );
};
