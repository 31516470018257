import "./style/index.css";
import { Input } from "@/components/common/input";
import { ALL_CUSTOM_EVENTS, CURRENCY_MAPPER } from "@/constants";
import { PersonalAPIService } from "@/redux/slices/personal";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { APIResponseHandler } from "@/utils/api";
import { formatWord } from "@/utils/formatWord";
import { reactSelectStyle } from "@/utils/helper/Helper";
import { TypeIs, isObjectWithValidValues } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { Validator } from "@/utils/validator";
import { RavenButton, toast } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { z } from "zod";

type Fields =
  | "amount"
  | "bank"
  | "created_at"
  | "narration"
  | "session_id"
  | "destination_account_number"
  | "originator_account_number"
  | "originator_account_name";

const schema = Validator.object({
  amount: Validator.amount("Amount"),
  bank: Validator.select("Bank"),
  created_at: Validator.newDate("Date"),
  narration: Validator.string("Narration"),
  session_id: Validator.string("Session Id"),
  destination_account_number: Validator.string("Destination Account Number"),
  originator_account_number: Validator.string("Originator Account Number"),
  originator_account_name: Validator.string("Originator Account Name"),
});

type Payload = z.infer<typeof schema>;

interface Props {
  onClose?(): void;
  selectPosition?: string;
}

export const ManualDepositResolution = ({ onClose, selectPosition }: Props) => {
  const { data: banks } = personalReduxAPI.useGetAllBanksQuery();
  const [loading, setLoading] = useState(false);

  const fields = [
    { name: "session_id" as const, placeholder: "Enter session ID", label: "Session ID" },
    {
      name: "amount" as const,
      type: "number",
      label: "amount",
      placeholder: "Enter Amount",
      thousandFormat: true,
      numberPrefix: CURRENCY_MAPPER.NGN,
    },
    {
      name: "destination_account_number" as const,
      type: "account-number",
      placeholder: "Enter Account Number",
    },
    {
      name: "originator_account_number" as const,
      type: "account-number",
      placeholder: "Enter Account Number",
    },
    { name: "originator_account_name" as const, placeholder: "Enter Account Name" },
    {
      name: "bank" as const,
      placeholder: "Select Bank",
      selectOption: Util.safeArray(banks).map((bank) => ({
        label: bank.Name,
        value: bank.Code,
      })),
      selectStyles: reactSelectStyle,
      type: "select",
      menuPlacement: selectPosition,
    },
    { name: "narration" as const, placeholder: "Enter Narration" },
    {
      name: "created_at" as const,
      type: "date",
      label: "date",
      dateOptions: { maxDate: new Date() },
    },
  ];

  type AllField = (typeof fields)[number];

  const [formData, setFormData] = useState<Record<Fields, any>>({
    amount: "",
    bank: { label: "", value: "" },
    created_at: null,
    destination_account_number: "",
    narration: "",
    originator_account_name: "",
    originator_account_number: "",
    session_id: "",
  });
  const [errors, setErrors] = useState(Validator.createError(schema));

  const setInputValue = (name: string, value: any) =>
    setFormData((old) => ({ ...old, [name]: value }));

  const handleChange = (field: AllField, event: any) => {
    if (field.type && ["select", "date"].includes(field.type)) {
      if (field.type === "select") setInputValue(field.name, event);
      else setInputValue(field.name, new Date(event[0]));
      return;
    }

    const { value } = event.target;
    return setInputValue(field.name, value);
  };

  const logManualDeposit = async (payload: Payload) => {
    setLoading(true);

    try {
      const { bank, created_at, ...rest } = payload;
      const response = await PersonalAPIService.manualDepositLogin({
        ...rest,
        bank: bank.label,
        bank_code: bank.value,
        createdAt: TypeIs.custom<string>(created_at),
      });
      const isSuccess = APIResponseHandler.tryHandler({
        response,
        hideErrorToast: false,
      });

      if (isSuccess) {
        toast.success("Missing Deposit has been logged successfully");
        window.dispatchEvent(new Event(ALL_CUSTOM_EVENTS.LOGGED_DEPOSITS));
        onClose?.();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const disabled = !isObjectWithValidValues(formData);

  const handleFormSubmit = () => {
    Validator.validatorFunc({
      data: formData,
      onError: setErrors,
      onSuccess: logManualDeposit,
      schema,
    });
  };

  return (
    <div className="manual-deposit-resolution__wrapper">
      <div className="manual-deposit-resolution-form">
        {fields.map((field) => {
          return (
            <Input
              thousandFormat={field.name === "amount"}
              key={field.name}
              label={formatWord(field.name)}
              onChange={(event: any) => handleChange(field, event)}
              showError={Boolean(errors?.[field.name])}
              errorText={errors?.[field.name]}
              {...field}
            />
          );
        })}
      </div>

      <div className="manual-deposit-resolution-form__submit-button">
        <RavenButton
          color="purple-light"
          disabled={disabled}
          label="Log Missing Deposit"
          onClick={handleFormSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};
