export const BIZ_VERIFICATION_STATUS = {
  REVOKED: 5,
  DECLINED: 4,
  SUCCESSFUL: 3,
  FAILED: 2,
  PENDING: 1,
  UNVERIFIED: 0,
};

export const BIZ_TRANSACTION_STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  FAILED: 2,
  SUCCESS: 3,
  REVERSED: 11,
};

export const PAYROLL_STATUS = {
  PENDING_APPROVAL: "pending_approval",
  PENDING_PROCESSING: "pending_processing",
  CANCELLED: "cancelled",
  PROCESSED: "processed",
};

// TODO:Confirm this status
export const BIZ_INVOICE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
};
