import { pbGetHandler, PersonalBaseParams, PersonalBuilder } from "..";
import { AnalyticsData } from "./types";

interface DateRange {
  start_date: string;
  end_date: string;
}

export interface PersonalAuditTrailEntry {
  id: number;
  support: string;
  user: string;
  action: string;
  info: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export const PersonalAnalyticsAPI = (builder: PersonalBuilder) => {
  return {
    getSavingsAnalyticsForAPeriod: builder.query<AnalyticsData, DateRange>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "savings_analytics", params });
      },
    }),
    getAuditTrail: builder.query<PersonalAuditTrailEntry[], PersonalBaseParams>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "support_actions", params });
      },
    }),
  };
};
