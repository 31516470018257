export const singleCardInitial = {
  card_details: {
    id: 0,
    business: "",
    card_design: "",
    card_title: "",
    card_type: "",
    pin: "",
    pin_iv: "",
    status: "",
    currency: "",
    processing_fee: 0,
    created_at: "",
    updated_at: "",
    main_card_id: "",
    meta_data: undefined,
    card_status: undefined,
    brand: "",
    card_name: "",
    balance: 0,
    type: "",
    billing_address: "",
    card_number: "",
    card_number_iv: "",
    cvv: "",
    cvv_iv: "",
    expiry_month: "",
    expiry_month_iv: "",
    expiry_year: "",
    expiry_year_iv: "",
    last_four: "",
    last_four_iv: "",
    meta_iv: undefined,
    card_owner_email: undefined,
    send_email_notification_to_cardowner: 0,
    deleted_at: undefined,
    admin_freeze: undefined,
    email: "",
    business_name: "",
    username: undefined,
    phone: "",
    ngn_available_bal: 0,
    usd_available_bal: 0,
    gbp_available_bal: 0,
    eur_available_bal: 0,
    heard_about_raven_from: undefined,
    pnd: 0,
    address: 0,
    bvn: 0,
    cac: 0,
    fname: undefined,
    lname: undefined,
    daily_transfer_limit: 0,
    total_transferred_today: 0,
    total_transfer_limit: 0,
    total_received: 0,
    total_transferred: 0,
    total_raven_received: 0,
    total_raven_transferred: 0,
    business_info: 0,
    directors: 0,
    logo: 0,
    nin: 0,
    business_address: undefined,
    document_status: 0,
    email_notification_control: 0,
    transfer_enabled: 0,
    airtime_enabled: 0,
    data_enabled: 0,
    electricity_enabled: 0,
    cable_enabled: 0,
    betting_enabled: 0,
    internal_transfer_enabled: 0,
    activate_role_and_permission: 0,
    lien: 0,
    cac_decline_reason: "",
    business_verified: 0,
    user_email: undefined,
    user_id: undefined,
    total_number_of_transfers: 0,
    v05_to_v1_business_balance_update_status: 0,
    business_owner_email: "",
    single_transfer_limit: 0,
    initial_account_number_generation: 0,
    max_number_of_sub_accounts: 0,
    block_business: 0,
    airtime_limit: "",
    total_airtime_today: 0,
    data_limit: "",
    total_data_today: 0,
    cable_limit: "",
    total_cable_today: 0,
    electricity_limit: "",
    total_electricity_today: 0,
    betting_limit: "",
    total_betting_today: 0,
    initial_bill_limit_update_check: 0,
    main_card_object: {
      refund: 0,
      card_id: "",
      card_pan: "",
      cvv: "",
      pin: "",
      billing_address: {
        state: "",
        country: "",
        country_code: "",
        street: "",
        postal_code: "",
        state_code: "",
        city: "",
      },
      expiry_month: "",
      expiry_year: "",
      brand: "",
      card_name: "",
      card_number: "",
      balance: 0,
      type: "",
      currency: "",
      secure_pin: "",
      pin_3ds_activated: 0,
      last_four: "",
      created_at: "",
    },
  },
  card_transactions: {
    previousPage: undefined,
    nextPage: "",
    total: 0,
    totalPages: 0,
    items: [],
    currentPage: "",
  },
};
