import { toast } from "@ravenpay/raven-bank-ui";
import { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";
import { Util } from "./utility";
import { $event } from "./events/eventEmitter";
import { toggleRedact } from "@/redux/actions/actions";

interface TryHandlerArgs {
  response?: any;
  hideErrorToast?: boolean;
  hideSuccessToast?: boolean;
  onErrorCallback?(error: string): void;
  onSuccessCallBack?(): void;
  dispatch?: any;
}
export class APIResponseHandler {
  /** Checks if its a success or error response. */
  static tryHandler = (args: TryHandlerArgs) => {
    const {
      response,
      hideErrorToast,
      hideSuccessToast = true,
      onErrorCallback,
      onSuccessCallBack,
      dispatch,
    } = args;

    if (response?.data?.status === "fail") {
      let message: string;
      dispatch && dispatch(toggleRedact(false));

      if (typeof response?.data?.data === "string") {
        message = response?.data.data;
      } else {
        message = response?.data.message;
      }

      if (!hideErrorToast) {
        toast.error(message, { position: "top-right" });
      }

      if (onErrorCallback) {
        onErrorCallback(message);
      }

      return false;
    }

    if (response?.data?.status === "success") {
      if (!hideSuccessToast) {
        toast.success(response?.data?.message, {
          position: "top-right",
        });
      }

      dispatch && dispatch(toggleRedact(false));

      onSuccessCallBack?.();
      return true;
    }

    return false;
  };

  static catchHandler = (error: any, thunkAPI?: any) => {
    thunkAPI.dispatch(toggleRedact(false));

    if (error?.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }

    if (error?.data?.status === "fail" && error?.response?.status !== 401) {
      if (thunkAPI) return thunkAPI.rejectWithValue(error);
    }
  };

  static successToast = toast.success;
}

interface ReduxInitialState {
  loading: boolean;
}

export class ReduxBuilderHandler<State extends ReduxInitialState> {
  private builder: ActionReducerMapBuilder<State>;
  private initialState: State;

  constructor(builder: ActionReducerMapBuilder<State>, initialState: State) {
    this.builder = builder;
    this.initialState = initialState;
  }

  handleStates(
    thunk: AsyncThunk<any, any, any>,
    loadingKey?: keyof State,
    handler?: (state: any) => void
  ) {
    if (!loadingKey) {
      this.builder.addCase(thunk.pending, (state, action) => {
        handler?.(state);
        if (Util.isNotExportReq(action?.meta?.arg?.paginate)) {
          state.loading = true;
        } else {
          $event.emit("exporting", "started");
        }
      });

      this.builder.addCase(thunk.fulfilled, (state, action) => {
        state.loading = false;
        handler?.(state);
        if (!Util.isNotExportReq(action?.meta?.arg?.paginate)) {
          $event.emit("exporting", "done");
        }
      });
    }

    if (loadingKey) {
      this.builder.addCase(thunk.pending, (state: any, action) => {
        handler?.(state);
        if (Util.isNotExportReq(action?.meta?.arg?.paginate)) {
          state[loadingKey] = true;
        } else {
          $event.emit("exporting", "started");
        }
      });

      this.builder.addCase(thunk.fulfilled, (state: any, action) => {
        handler?.(state);

        if (!Util.isNotExportReq(action?.meta?.arg?.paginate)) {
          $event.emit("exporting", "done");
        }

        state[loadingKey] = false;
      });
    }

    this.builder.addCase(thunk.rejected, (state: any, action) => {
      state.loading = false;
      state[loadingKey] = false;
      handler?.(state);
      if (!Util.isNotExportReq(action?.meta?.arg?.paginate)) {
        $event.emit("exporting", "done");
      }
    });
  }
}
