import { useState } from "react";
import { PersonalExportModalProps } from "./utils";
import { PersonalExportModal } from "./modal";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { PERSONAL_EXPORT_MODAL_ID } from "@/constants";

type Props = Omit<PersonalExportModalProps, "visible" | "onClose">;

export const usePersonalExportModal = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const { renderDynamicModal } = useDynamicModal(visible);

  const Node = visible ? (
    <PersonalExportModal {...props} onClose={() => setVisible(false)} visible={visible} />
  ) : (
    <></>
  );

  const portal = renderDynamicModal(
    Node,
    document.getElementById(PERSONAL_EXPORT_MODAL_ID)
  );

  const onExport = () => {
    setVisible(true);
  };

  return {
    /** render anywhere in your app */
    ExportModal: () => <>{portal}</>,
    onExport,
  };
};
