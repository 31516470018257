import { getQueryVariable } from "@/utils/helpers";
import { SpendXSave } from "./spend-save-page";
import { UsersSavingsPage } from "./page";
import { Pockets } from "./pockets";

export const UsersSavings = () => {
  const selectedTab = getQueryVariable<PersonalSavingsType>("type");
  if (selectedTab === "spendxsave") return <SpendXSave />;
  if (selectedTab === "pocket") return <Pockets />;
  return <UsersSavingsPage />;
};
