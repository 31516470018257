import {
  getAirtimeDataElectricityStatus,
  isDataBillRow,
} from "~pb/__internals__/combined-tables/bills/utils";
import { CopyText } from "@/components/common/copyCheck";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { detectProvider } from "@/utils/helper/DetectNetwork";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";

interface Props {
  record: PersonalAirtimeOrData;
}

export const PersonalAirtimeOrDataDetailsModal = (props: CreateDetailsProps<Props>) => {
  const { onCancel, record } = props;

  const { amount, currency, created_at, phone_number, status, request_id } = props.record;

  const view = {
    amount: formatNumberToCurrency(amount, currency),
    mobile_number: phone_number,
    data_plan: isDataBillRow(record) ? record.plan : undefined,
    biller: detectProvider(phone_number).toUpperCase(),
    status: formatStatus(getAirtimeDataElectricityStatus(status)),
    request_id: request_id ? <CopyText text={request_id} /> : "--",
    date: formatDateTime(created_at),
  };

  const content = Object.entries(view).map(([key, value]) => {
    if (!value) return undefined;
    return { label: formatWord(key), value };
  });

  return (
    <ActionModal
      title="Bill Details"
      visible
      onCancel={onCancel}
      onClick={onCancel}
      hideCancel
      hideNormalButton
      className={GENERAL_CLASSES.BIG_MODAL}
    >
      <InlineDetails
        simple
        className={cn(
          GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW,
          GENERAL_CLASSES.INLINE_DETAILS.OVERRIDE
        )}
        content={TypeIs.any(content.filter(Boolean))}
      />
    </ActionModal>
  );
};
