import "./style/index.css";
import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useProductColor } from "@/context/ProductTheme";
import { formatDate } from "@/utils/helper/Helper";
import {
  DateRange,
  ModalDayRangeType,
  PersonalExportModalProps,
  SchemaErrorState,
  addDays,
} from "./utils";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { GENERAL_CLASSES } from "@/constants";
import { ExportModalDateSelector } from "./modal-date-selector";
import { ModalInputs } from "./inputs";
import { downloadFile } from "@/utils/helpers";
import { Validator } from "@/utils/validator";
import { formatWord } from "@/utils/formatWord";
import { ZodType } from "zod";
import { iife } from "@/utils/general";

type Props = PersonalExportModalProps;

export const PersonalExportModal = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { visible, onClose, title, filterParams, route, overrideRoute, btnText } = props;
  const [selectedDay, setSelectedDay] = React.useState<ModalDayRangeType>("All");
  const [dateRange, setDateRange] = React.useState<DateRange>({
    start_date: "2022-06-01",
    end_date: formatDate(addDays(new Date(), 1)),
  });
  const [customDateRange, setCustomDateRange] = useState<DateRange>({
    end_date: null,
    start_date: null,
  });
  const [errors, setErrors] = useState<SchemaErrorState>({});

  const initialFilterValues = filterParams.reduce((acc, { key, defaultValue }) => {
    if (defaultValue) {
      return { ...acc, [key]: defaultValue };
    }
    return { ...acc };
  }, {});

  const schemaObject = filterParams.reduce((acc, { type, key, errorKey, required }) => {
    const label = errorKey ?? formatWord(key);

    const shouldBeRequired = (schema: ZodType) => {
      return required ? schema : schema.optional();
    };

    if (type === "email") {
      return { ...acc, [key]: shouldBeRequired(Validator.email(label)) };
    }

    if (type === "account-number") {
      return { ...acc, [key]: shouldBeRequired(Validator.accountNumber(label)) };
    }

    return { ...acc, [key]: shouldBeRequired(Validator.string(label)) };
  }, {});

  const schema = Validator.object({
    ...schemaObject,
    end_date: Validator.string("To"),
    start_date: Validator.string("From"),
  });

  const [filters, setFilters] =
    useState<Record<string, IsUncertain<SN>>>(initialFilterValues);
  const { colorVariable } = useProductColor();

  const handleExport = async (payload: any) => {
    setLoading(true);
    const url = overrideRoute ?? cleanWhiteSpace("/support/user", route);

    try {
      const response = await PersonalAPIService.baseExportAPI(url, payload);
      const isSuccess = APIResponseHandler.tryHandler({
        response,
        hideErrorToast: false,
      });
      if (isSuccess) {
        downloadFile(response.data.data.link, props.fileName);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    }

    setLoading(false);
  };

  const parsedDateRange = iife(() => {
    if (selectedDay === "Custom") return customDateRange;
    return dateRange;
  });

  const validateFilterParams = () => {
    Validator.validatorFunc({
      data: { ...filters, ...parsedDateRange },
      onError: setErrors,
      onSuccess: handleExport,
      schema,
    });
  };

  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      btnLabel={btnText ?? "Export Data"}
      btnColor={colorVariable}
      loading={loading}
      onBtnClick={() => {
        validateFilterParams();
      }}
      className={cn(GENERAL_CLASSES.NORMAL_BIG_MODAL)}
    >
      <div className="export-modal pem-modal-wrapper">
        <div className="export-modal__title">
          <h5>{title}</h5>
          <p>Choose how you would like to export your data</p>
        </div>

        <div className="export-modal__export-as pem-modal">
          <ExportModalDateSelector
            selectedDay={selectedDay}
            setDateRange={setDateRange}
            setSelectedDay={setSelectedDay}
            setCustomDateRange={setCustomDateRange}
            customDateRange={customDateRange}
            errors={errors}
          />
        </div>
        <ModalInputs
          filterParams={filterParams}
          filters={filters}
          onChange={setFilters}
          errors={errors}
        />
      </div>
    </RavenModal>
  );
};
