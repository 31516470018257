import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { GLOBAL_PER_PAGE } from "@/constants";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { usePbSpendSave } from "../../__internals__/internal-hooks/savings";
import { ConditionalCopyText } from "@/components/common/copyCheck";

export const SpendXSave = () => {
  const pagination = usePagination();
  const { data, isFetching, isLoading } = personalReduxAPI.useGetSpendAndSaveQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
    per_page: GLOBAL_PER_PAGE,
    type: "spendxsave",
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });
  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );
  const { ExportModal, onExport } = usePbSpendSave();

  const navigate = useNavigate();

  const handleClick = (row: PersonalSpendSave) => {
    navigate(`/personal-savings/spendsave/${row.id}`);
  };

  if (isLoading || !data || data.spendSaves?.length < 1)
    return (
      <div className="savings-page-item-wrapper">
        <TransferEmptyScreen loading={joinedLoader} />;
      </div>
    );

  return (
    <div className="savings-page-item-wrapper">
      <SmartFilter
        searchTitle="Search by email or reference"
        {...SmartFilterProps}
        onExport={onExport}
      />
      <RavenTable
        className="table__main"
        headerList={[
          "EMAIL",
          "REFERENCE",
          "Balance",
          "PERCENTAGE",
          "DATE CREATED",
          "STATUS",
        ]}
      >
        {data.spendSaves.map((row) => {
          const { id, balance, percentage, reference, created_at, status, email } = row;

          return (
            <RavenTableRow
              key={id}
              one={RedactedTable(
                email ? <ClickAbleEmail email={Util.safeText(email)} /> : "--"
              )}
              two={RedactedTable(<ConditionalCopyText text={reference} />)}
              three={RedactedTable(Util.safeText(formatNumberToCurrency(balance)))}
              four={RedactedTable(Util.safeText(cleanWhiteSpace(percentage, "%")))}
              five={RedactedTable(
                Util.safeText(created_at ? formatDateTime(created_at) : "--")
              )}
              six={RedactedTable(formatStatus(detectStatus(status, "pb-savings")))}
              onRowClick={() => handleClick(row)}
            />
          );
        })}
      </RavenTable>
      <ExportModal />
    </div>
  );
};
