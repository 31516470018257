import { getQueryVariable } from "@/utils/helpers";
import { useSingleUserState } from "../../context";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { FixedSavings, HIFASavings, TargetSavings } from "./tables";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { TRANSFER_EMPTY_SCREEN } from "@/constants";
import { usePersonalSavingsFilterAndExport } from "@/apps/personal/__internals__/internal-hooks/savings";
import { useNavigate } from "react-router-dom";

export const UsersSavingsPage = () => {
  const selectedTab = getQueryVariable<PersonalSavingsType>("type");

  return <Page selectedTab={selectedTab} key={selectedTab} />;
};

type PageProps = { selectedTab?: string };

const Page = ({ selectedTab }: PageProps) => {
  const { email } = useSingleUserState();
  const pagination = usePagination();
  const { data, isLoading, isFetching } = personalReduxAPI.useGetSavingsQuery({
    per_page: 100,
    email,
    type: selectedTab ?? "fixed",
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
    page: pagination.currentPage,
    search: pagination.searchQuery,
  });
  const { ExportModal, onExport } = usePersonalSavingsFilterAndExport(
    selectedTab ?? "fixed",
    email
  );

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const navigate = useNavigate();

  const handleClick = (row: PersonalsSavingModel) => {
    navigate(`/personal-savings/${row.id}`);
  };

  if (isLoading || !data)
    return (
      <div className="mt-20">
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      </div>
    );

  const renderTable = () => {
    switch (selectedTab) {
      case "fixed":
        return (
          <FixedSavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );
      case "fixed_new":
        return (
          <FixedSavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );
      case "target_new":
        return (
          <TargetSavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );
      case "target":
        return (
          <TargetSavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );
      case "hifa":
        return (
          <HIFASavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );

      case "global":
        return (
          <TransferEmptyScreen
            height="80vh"
            title="We are bring Global Savings shortly"
            subTitle="Nothing to see here for now, we are working hard to bring you global savings"
            loading={joinedLoader}
          />
        );
      default:
        return (
          <FixedSavings
            onRowClick={handleClick}
            RedactedTable={RedactedTable}
            savings={data.savings}
          />
        );
    }
  };

  return (
    <div className="mt-20">
      <SmartFilter {...SmartFilterProps} onExport={onExport} />

      {data.savings.length > 0 ? (
        renderTable()
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </div>
  );
};
