import React, { useEffect, useState } from "react";
import { RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatDate, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { BillerStack, XStack } from "@/components/common/stacks";
import { icons } from "@/assets/icons";
import Copy, { CopyText } from "@/components/common/copyCheck";
import TwinValue from "@/components/common/twinValue";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { useSelector } from "react-redux";
import Redacted from "@/components/common/shimmer/redact";
import { capitalizeEachWord } from "@/utils/formatWord";

interface Defaults {
  merchant_name: string;
  merchant_email: string;
  account_no: string;
  account_name: string;
  type: string;
  amount: string;
  date: string | Date;
  status: string;
  bank: string;
}
export interface SettlementDataProps extends Defaults {}

export class SettlmentTable {
  static Pending = (props: PosSettlements) => {
    const {
      account_name,
      email,
      account_number,
      bank,
      settlement_type,
      amount,
      created_at,
    } = props;
    const { isRedacting } = useSelector((state: RootState) => state.general);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(email)} textToCopy={Util.safeValue(email)} />
          )
        }
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack className="biller-row">
              <p>{Util.safeValue(account_name)}</p>
            </XStack>
          )
        }
        three={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <TwinValue one={Util.safeValue(bank)} two={Util.safeValue(account_number)} />
          )
        }
        four={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            Util.safeValue(capitalizeEachWord(settlement_type))
          )
        }
        five={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        six={isRedacting ? <Redacted.Table /> : formatDateTime(created_at as Date)}
        seven={isRedacting ? <Redacted.Badge /> : formatStatus("pending")}
      />
    );
  };
  static Failed = (props: PosSettlements) => {
    const {
      account_name,
      email,
      account_number,
      bank,
      settlement_type,
      amount,
      created_at,
    } = props;
    const { isRedacting } = useSelector((state: RootState) => state.general);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText text={Util.safeValue(email)} textToCopy={Util.safeValue(email)} />
          )
        }
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack className="biller-row">
              <p>{Util.safeValue(account_name)}</p>
            </XStack>
          )
        }
        three={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <TwinValue one={Util.safeValue(bank)} two={Util.safeValue(account_number)} />
          )
        }
        four={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            Util.safeValue(capitalizeEachWord(settlement_type))
          )
        }
        five={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        six={isRedacting ? <Redacted.Table /> : formatDateTime(created_at as Date)}
        seven={isRedacting ? <Redacted.Badge /> : formatStatus("failed")}
      />
    );
  };
}
