import { useState } from "react";

type CopyAbleContent = Record<string, SN>;

export const copyViewContent = async (content: CopyAbleContent) => {
  const parsedString = Object.entries(content).reduce((acc, [key, value]) => {
    return `${acc}${key}: ${value}\n`;
  }, "");
  await navigator.clipboard?.writeText(parsedString);
};

interface UseCopyToClipboardArgs {
  initialText: string;
  copyingText?: string;
  copiedText?: string;
}

export const useCopyToClipboard = (args: UseCopyToClipboardArgs) => {
  const { initialText, copiedText, copyingText } = args;

  const [isCopying, setIsCopying] = useState(false);
  const [copyText, setCopyText] = useState(initialText);

  const copyContent = async (content: CopyAbleContent) => {
    setIsCopying(true);
    setCopyText(copyingText ?? "Copying...");

    await copyViewContent(content);
    setIsCopying(false);
    setCopyText(copiedText ?? "Copied To Clipboard");

    setTimeout(() => {
      setCopyText(initialText);
    }, 800);
  };

  return { isCopying, copy: copyContent, copyText };
};
