import PersonalApi from "@/utils/axios/personal";
import { cleanOutObject, filterAPIQueryParams } from "@/utils/helpers";
import type {
  AcceptLimitRequest,
  ApproveAddress,
  AssignCardPayload,
  BlockOrUnblockUser,
  FreezeOrUnfreezeCardPayload,
  GenerateBankStatementPayload,
  LienPayload,
  ManualDepositLoginPayload,
  MarkTransferAsPaidOrRefunded,
  RejectLimit,
  RejectOrApproveAddress,
  RejectPayload,
  RejectPayloadId,
  StripVerification,
  UpdateGetHelpStatus,
} from "./payload.types";

export class PersonalAPIService {
  static async updateGetHelpStatus(payload: UpdateGetHelpStatus) {
    const res = await PersonalApi.post("support/update-get-help-log-status", payload);
    return res;
  }

  static async blockOrUnblockUser(payload: BlockOrUnblockUser) {
    const { type, ...rest } = payload;
    const res = await PersonalApi.post(`support/${type}-user`, rest);
    return res;
  }
  static async lienOrUnlien(payload: LienPayload) {
    const { type, ...rest } = payload;
    const res = await PersonalApi.post(`support/${type}-user`, rest);
    return res;
  }
  static async deductUserAccount(payload: Omit<LienPayload, "type">) {
    const res = await PersonalApi.post(`support/deduct-user-account`, payload);
    return res;
  }

  static async stripVerification(payload: StripVerification) {
    const res = await PersonalApi.post("support/strip-verification", payload);
    return res;
  }
  static async stripAllVerification(payload: StripVerification) {
    const res = await PersonalApi.post("support/strip-verification", payload);
    return res;
  }

  static async approveAddress(_payload: ApproveAddress) {
    const { id, ...rest } = _payload;

    const res = await PersonalApi.post(`support/approve-user-manual-pending-address`, {
      address_id: id,
      ...rest,
    });
    return res;
  }
  static async rejectAddress(payload: RejectPayload) {
    const res = await PersonalApi.post(
      `support/reject-user-manual-pending-address`,
      payload
    );
    return res;
  }

  static async approveOrRejectId(_payload: RejectOrApproveAddress) {
    const { action, id } = _payload;

    const url = action === "approve" ? "approve-manual-id-verification" : "";

    const res = await PersonalApi.post(`support/${url}`, { id: Number(id) });
    return res;
  }
  static async rejectManualId(_payload: RejectPayloadId) {
    const url = "reject-manual-id-verification";

    const res = await PersonalApi.post(`support/${url}`, _payload);
    return res;
  }

  static approveOrRejectLimitRequest = async (_payload: AcceptLimitRequest) => {
    const { type } = _payload;

    const payload =
      type === "decline" ? { request_id: _payload.request_id } : cleanOutObject(_payload);

    const res = await PersonalApi.post(`/support/${type}-limit-request`, payload);
    return res;
  };
  static rejectLimitRequest = async (_payload: RejectLimit) => {
    const res = await PersonalApi.post(`/support/decline-limit-request`, _payload);
    return res;
  };

  static generateBankStatement = async (payload: GenerateBankStatementPayload) => {
    const { type, email, ...rest } = payload;
    type BODY = PersonalGenerateBankStatementPayload;
    const res = await PersonalApi.get<BODY>("/support/bank-statement", {
      params: filterAPIQueryParams({ email, range_type: type, ...rest }),
    });
    return res;
  };

  static async freezeOrUnfreezeCard(payload: FreezeOrUnfreezeCardPayload) {
    const { action, id } = payload;
    const res = await PersonalApi.post(`/support/${action}-card/${id}`);
    return res;
  }

  static async manualDepositLogin(payload: ManualDepositLoginPayload) {
    const res = await PersonalApi.post("/support/manual-deposit-queue", payload);
    return res;
  }

  static async manualDepositApprovalByAdmin(id: SN) {
    const res = await PersonalApi.post("/support/approve-manual-deposit", { id });
    return res;
  }

  static async markTransferAsPaidOrRefunded(data: MarkTransferAsPaidOrRefunded) {
    const { ctx } = data;
    const base = { withdrawal_id: data.recordId };
    const payload = ctx !== "refund_withdrawal" ? base : { ...base, recheck: 1 };
    const res = await PersonalApi.post(`/support/${ctx}`, payload);
    return res;
  }

  static async baseExportAPI(url: string, filters: Record<string, IsUncertain<SN>>) {
    type EXPORT = APIResponse<{ link: string }>;
    const params = filterAPIQueryParams(filters);
    const res = await PersonalApi.get<EXPORT>(url, { params });
    return res;
  }
  static async assignCard(payload: AssignCardPayload) {
    const res = await PersonalApi.post("/support/assign_physical_card", payload);
    return res;
  }
  static async approveCardRequest(id: SN) {
    const res = await PersonalApi.post("/support/approve_physical_card_request", { id });
    return res;
  }
  static async markCardReadyForDelivery(id: SN) {
    const res = await PersonalApi.post("/support/create_dispatch_order", {
      physical_card_request_id: id,
    });
    return res;
  }

  static async recreateDispatchOrder(id: SN) {
    const res = await PersonalApi.post("/support/recreate_dispatch_order", {
      physical_card_request_id: id,
    });
    return res;
  }
  static async trackCardOrder(id: SN) {
    const res = await PersonalApi.post("/support/track_dispatch_order", {
      physical_card_request_id: id,
    });
    return res;
  }
  static async updatePhysicalCard(payload: Partial<PersonalPhysicalCardModel>) {
    return await PersonalApi.post("/support/update_physical_card", payload);
  }
}

export type PersonalAPIServiceType = typeof PersonalAPIService;
