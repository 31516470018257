import { atlasIcons } from "@/assets/icons/atlasIcons";

export const atlasSideMenuList: SidebarMenuDataList = [
  {
    name: "Main Menu",
    numb: "one",
    icon: "",
    link: "",
    children: [
      {
        name: "Overview",
        link: "/atlas-overview",
        icon: atlasIcons.sidebar_overview,
        activeIcon: atlasIcons.sidebar_overview
      },

      {
        name: "Insights",
        link: "/atlas-insights",
        icon: atlasIcons.sidebar_insights,
      },
      {
        name: "Merchants",
        link: "/atlas-merchants",
        icon: atlasIcons.sidebar_merchants,
      },
      {
        name: "Transactions",
        link: "/atlas-transactions",
        icon: atlasIcons.sidebar_transactions,
      },
      {
        name: "Expenses",
        link: "/atlas-expenses",
        icon: atlasIcons.sidebar_expenses,
      },
    ],
  },
  {
    name: "Features",
    numb: "two",
    icon: "",
    link: "",
    children: [
      {
        name: "Transfer",
        link: "/atlas-transfers",
        icon: atlasIcons.sidebar_transfers,
      },

      {
        name: "Collections",
        link: "/atlas-collections",
        icon: atlasIcons.sidebar_collections,
      },
      {
        name: "Virtual Accounts",
        link: "/atlas-virtual-accounts",
        icon: atlasIcons.sidebar_virtual_wallet,
      },
      {
        name: "Bills",
        link: "/atlas-bills",
        icon: atlasIcons.sidebar_bills,
      },
      {
        name: "Cards",
        link: "/atlas-cards",
        icon: atlasIcons.sidebar_cards,
      },
      {
        name: "BankBox",
        link: "/atlas-bankbox",
        icon: atlasIcons.sidebar_bank_box,
      },
      {
        name: "Customers",
        link: "/atlas-customers",
        icon: atlasIcons.sidebar_customers,
      },
    ],
  },
  {
    name: "Support",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Compliance",
        link: "/atlas-compliance",
        icon: atlasIcons.sidebar_compliance,
      },
    ],
  },
  {
    name: "Admin Tools",
    numb: "four",
    icon: "",
    link: "",
    children: [
      {
        name: "Notification",
        link: "/atlas-notification",
        icon: atlasIcons.sidebar_merchants,
      },
    ],
  },
];
