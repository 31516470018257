import { usePagination } from "@/hooks/usePagination";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import SmartFilter from "@/components/common/smartFilter";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { CopyText } from "@/components/common/copyCheck";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { useNavigate } from "react-router-dom";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import { useState } from "react";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { BIZ_TRANSACTION_STATUS } from "@/apps/business/__internals__/constants";

const ArrowRight = (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={14} cy={14} r={14} fill="#F7F7F7" />
    <path
      d="M9.91675 14.0001H18.0834M18.0834 14.0001L14.0001 9.91675M18.0834 14.0001L14.0001 18.0834"
      stroke="#676767"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BBAllTransferBulkTransfer = () => {
  const pagination = usePagination();
  const [filterValue, setFilterValue] = useState({});
  const { currentPage, searchQuery } = pagination;
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = businessReduxAPI.useGetAllBulkTransfersQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
  });

  const exportApi = businessReduxAPI.useGetAllBulkTransfersQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
    paginate: "no",
  });

  const { SmartFilterProps } = useSmartFilterProps(data, pagination);

  const { renderRedacted } = renderRedactedComponent(isFetching);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "email",
    USERNAME: "identifier",
    REFERENCE: "bulk_transfer_reference",
    "TOTAL TRANSFERRED": "total_transferred",
    AMOUNT: "amount",
    CREATED: "created_at",
    STATUS: ["status", "transactions"],
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: exportApi.data?.data as any,
    loading: isLoading,
    valuePair,
    dispatchFunction: exportApi.refetch,
    filterValue,
    title: "Business Bulk Transfers(Bugiss)",
  });
  const [exportTriggered, triggerExport] = useState(false);
  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  if (isLoading) return <TransferEmptyScreen loading />;
  if (!data) return <TransferEmptyScreen />;

  return (
    <>
      <SmartFilter
        {...SmartFilterProps}
        onFilter={(e: any) => {
          setFilterValue(Util.filterStruct(e) as any);
          // reset current page to 1 on filter change
          pagination.setCurrentPage(1);
        }}
        onExport={() => {
          toggleModal("Export Bulk Transfers");
          triggerExport(true);
        }}
        singleSelect
        filters={[
          {
            label: "Status",
            filterBy: "status",
            options: [
              { value: BIZ_TRANSACTION_STATUS.PENDING.toString(), label: "Pending" },
              {
                value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                label: "Processing",
              },
              { value: BIZ_TRANSACTION_STATUS.FAILED.toString(), label: "Failed" },
              { value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(), label: "Success" },
              {
                value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                label: "Reversed",
              },
            ],
          },
        ]}
      />
      {data.data?.length > 0 ? (
        <RavenTable
          headerList={[
            "Business Details",
            "Reference",
            "Recipients",
            "Amount",
            "Date Initiated",
            "Status",
            " ",
          ]}
        >
          {data.data.map((record, idx) => {
            const meta = Util.safeParseJson<Partial<BulkTransferMeta>>(
              record?.meta_data,
              {
                bulk_reference: "",
              }
            );

            const {
              business_name,
              bulk_transfer_reference,
              status,
              currency,
              amount,
              created_at,

              business_owner_email,
              email,
            } = record;

            return (
              <RavenTableRow
                key={idx}
                one={renderRedacted(
                  <AvatarWithTwinValue
                    name={business_name}
                    one={trimLongString(business_name, 25)}
                    two={trimLongString(
                      Util.safeValue(email ?? business_owner_email),
                      25
                    )}
                    copy
                  />,
                  "AvatarWithValue"
                )}
                two={
                  <div onClick={(e) => e.stopPropagation()}>
                    {renderRedacted(<CopyText text={bulk_transfer_reference} />, "Table")}
                  </div>
                }
                three={renderRedacted(
                  Util.safeValue(meta?.summary?.total_beneficiaries),
                  "Table"
                )}
                four={renderRedacted(formatNumberToCurrency(amount, currency), "Table")}
                five={renderRedacted(formatDateTime(created_at), "Table")}
                six={renderRedacted(
                  formatStatus(detectStatus(Number(status), "transactions")),
                  "Table"
                )}
                seven={
                  <button
                    style={{ cursor: "pointer" }}
                    type="button"
                    onClick={() =>
                      navigate(
                        `/business-transfer/bulk-transfers/${bulk_transfer_reference}/${email}`
                      )
                    }
                  >
                    {ArrowRight}
                  </button>
                }
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen inner loading={isFetching} />
      )}
    </>
  );
};
