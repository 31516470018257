import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosCollectionsApi = (builder: PosBuilder) => {
  return {
    getBankCollections: createQueryWithRoute<
      PosBankCollectionModel,
      Partial<PosPaginationParams>
    >(builder, "bank/collections", { name: "getBankCollections" }),

    getBankCollectionsExport: createQueryWithRoute<
      PosBankCollectionModel,
      Partial<PosPaginationParams>
    >(builder, "bank/collections", { export: true, name: "getBankCollectionsExport" }),

    getABankCollection: builder.query<PosBankCollection, any>({
      queryFn: (params: any, thunk) => {
        return posGetHandler({ route: `bank/collections/${params.id}`, params }, thunk);
      },
    }),

    getCardCollections: createQueryWithRoute<
      PosCardCollectionModel,
      Partial<PosPaginationParams>
    >(builder, "card/collections", { name: "getCardCollections" }),

    getCardCollectionsExport: createQueryWithRoute<
      PosCardCollectionModel,
      Partial<PosPaginationParams>
    >(builder, "card/collections", { export: true, name: "getCardCollectionsExport" }),

    getACardCollection: builder.query<PosCardCollection, any>({
      queryFn: (params: any, thunk) => {
        return posGetHandler({ route: `card/collections/${params.id}`, params }, thunk);
      },
    }),
  };
};
