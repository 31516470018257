import "./styles/index.css";
import { DashboardInfoCard } from "../../../components/common/dashboardInfo";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import TabComponent from "@/components/common/Tabs";
import { RavenInputField, RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import { OverviewLineChart } from "@/components/charts/overview-chart";
import { posReduxAPI } from "@/redux/slices/pos/apis";
// import { months } from "@/components/common/graphFilter/months";
import { useState } from "react";
import EmptyScreen from "@/components/common/emptyScreen";

const Tabs = ["Merchants", "Transaction"];

export const reactSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

function PosOverview() {
  const { data, isFetching, isLoading } = posReduxAPI.useGetOverviewQuery({});

  const loading = isFetching || isLoading;

  const merchantGraphData = data?.merchantOverview?.monthlyMerchantData?.map(
    (item, idx) => {
      return item.totalCount;
    }
  );

  const txGraphData = data?.transactionOverview?.monthlyTransactionData?.map(
    (item, idx) => {
      return item.totalVol;
    }
  );

  const StatsData = [
    {
      title: "Total Merchants",
      value: RavenNumberFormat(data?.allTimeView.totalUsers, { convertToNumber: true }),
      // stats: `+6000 This month`,
      isPositive: true,
    },
    {
      title: "Total Transactions",
      value: RavenNumberFormat(data?.allTimeView?.totalTransactions, {
        numberAbbreviate: true,
      }),
      // stats: "+6% This month",
      isPositive: true,
    },
    {
      title: "Volume Processed",
      value: RavenNumberFormat(data?.allTimeView?.totalVolumeProcessed, {
        numberAbbreviate: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },

    {
      title: "Settlement Volume",
      value: RavenNumberFormat(data?.allTimeView?.totalSettlementVolume, {
        numberAbbreviate: true,
      }),
    },
    {
      title: "Total Terminals",
      value: RavenNumberFormat(data?.allTimeView?.totalTerminals, {
        numberAbbreviate: false,
        convertToNumber: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
    {
      title: "Card Collection Volume",
      value: RavenNumberFormat(data?.allTimeView?.totalCardCollectionVolume, {
        numberAbbreviate: true,
      }),
      // stats: "+8% This month",
      isPositive: true,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(Tabs[0]);

  return (
    <DashboardLayout>
      <main className="buggies-business-dashboard">
        <h1 className="page-title text-white-black">Overview</h1>
        <DashboardInfoCard.Wrapper className="highlighted-content-wrapper">
          {StatsData.map((ele) => (
            <DashboardInfoCard key={ele.title} {...ele} loading={loading} />
          ))}
        </DashboardInfoCard.Wrapper>

        <div className="buggies-business-dashboard__chart-area highlighted-content-wrapper">
          <TabComponent
            tabs={Tabs}
            onTabValueChange={(e) => setSelectedTab(e as any)}
            defaultValue={Tabs[0]}
          >
            <TabComponent.Tabs tabs={Tabs} />
            <div className="buggies-business-dashboard__chart-content bdr-eee-333">
              <div hidden className="buggies-business-dashboard__select-area">
                <RavenInputField
                  selectOption={[
                    { label: "Yearly", value: "yearly" },
                    { label: "Monthly", value: "monthly" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Daily", value: "daily" },
                  ]}
                  type="select"
                  color="black-light"
                  selectStyles={reactSelectStyle}
                  placeholder="Yearly"
                />
              </div>
              <div className="buggies-business-dashboard__chart">
                {loading ? (
                  <EmptyScreen loading={loading} />
                ) : (
                  <OverviewLineChart
                    data={
                      selectedTab.includes("Transaction")
                        ? (txGraphData as SN[])
                        : (merchantGraphData as SN[])
                    }
                  />
                )}
              </div>
            </div>
          </TabComponent>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default PosOverview;
