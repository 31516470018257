import { icons } from "@/assets/icons";
import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button<{ variant: "refund" | "discard" }>`
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 1rem;
  font-size: 1.2rem;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ variant }) =>
    variant === "refund" &&
    `
    background-color: #e6fff0;
    color: #00b341;
  `}

  ${({ variant }) =>
    variant === "discard" &&
    `
    background-color: #ffe6e6;
    color: #ff0000;
  `}
`;

const Icon = styled.span`
  margin-left: 0.5rem;
  font-size: 1.8rem;
`;

interface ActionButtonsProps {
  onRefund: () => void;
  onDiscard: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onRefund, onDiscard }) => {
  return (
    <ButtonContainer>
      <Button variant="refund" onClick={onRefund}>
        Refund{" "}
        <Icon>
          {React.cloneElement(icons.check_circle_green, { width: 15, height: 15 })}
        </Icon>
      </Button>
      <Button variant="discard" onClick={onDiscard}>
        Discard{" "}
        <Icon>
          {React.cloneElement(icons.x_circle_red, { width: 15, height: 15, path: "red" })}
        </Icon>
      </Button>
    </ButtonContainer>
  );
};

export default ActionButtons;
