
import { PosReversalCenter } from "@/apps/pos/reversal-center";
import { PosReversalBillsAirtimePage, PosReversalBillsCablePage, PosReversalBillsDataPage, PosReversalBillsElectricityPage, PosReversalTransferPage } from "@/apps/pos/reversal-center/components/pages/index";
export const pos_reversal_center_routes: RouteArray[] = [
  {
    path: "/pos-reversal-center",
    element: PosReversalCenter,
    children: [
      {
        path: "/pos-reversal-center/transfer",
        element: PosReversalTransferPage,
      },
      {
        path: "/pos-reversal-center/airtime",
        element: PosReversalBillsAirtimePage,
      },
      {
        path: "/pos-reversal-center/data",
        element: PosReversalBillsDataPage,
      },
      {
        path: "/pos-reversal-center/electricity",
        element: PosReversalBillsElectricityPage,
      },
      {
        path: "/pos-reversal-center/cable",
        element: PosReversalBillsCablePage,
      },
    ],
  },
];
