import { APIResponseHandler } from "@/utils/api";
import { useState } from "react";

type PotentialPromise<T> = Promise<T> | T;

interface UseMutationArgs<Args, Data> {
  onSuccess?(args: Args, data: Data): PotentialPromise<unknown>;
  onError?(error: unknown): PotentialPromise<unknown>;
  /** This called in the finally part of the try catch block. */
  onMutateComplete?(args: Args): PotentialPromise<unknown>;
  // TODO: allow this handle side effects and return data
  mutationFn(payload?: Args): PotentialPromise<Data>;
}

export const useMutation = <Args, Data>(args: UseMutationArgs<Args, Data>) => {
  const { mutationFn, onError, onSuccess, onMutateComplete } = args;
  const [isMutating, setIsMutating] = useState(false);

  const mutate = async (data: Args) => {
    setIsMutating(true);
    try {
      const res = await mutationFn(data);
      const isSuccess = APIResponseHandler.tryHandler({ response: res });
      if (isSuccess) {
        await onSuccess?.(data, res);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
      await onError?.(error);
    } finally {
      await onMutateComplete?.(data);
      setIsMutating(false);
    }
  };

  return { mutate, isMutating };
};
