import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import Image from "@/components/common/image/Image";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { Row } from "@/components/common/stacks";
import Gap from "@/components/common/styleComponents/Gap";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { sendRequest } from "@/utils/api-methods";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { IconVault } from "@/utils/helper/IconVault";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const UnlienedBusinesses = () => {
  const [checked, setChecked] = useState<SN[]>([]);
  const [allChecked, setIsAllChecked] = useState(false);

  const dropdown = (status: number) => {
    return [
      // { label: "Unlien Account", img: IconVault(icons.info_circle) },

      {
        label: "Unlien Account",
        value: "unlien",
        img: IconVault(icons.check_circle_green),
      },
    ] as const;
  };

  const isCheckActive = checked?.length > 0 || allChecked;
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const { data, isFetching, isLoading, refetch } =
    businessReduxAPI.useFetchGlobalAccLienQuery({
      status: 0,
    });
  const [isReqn, setIsReqn] = useState(false);

  const loading = isFetching || isLoading;

  const liens = data?.account_liens?.data;
  const [modal, setModal] = useState<{
    lien?: boolean;
    id?: string;
  }>({
    lien: false,
  });
  async function handleLienUnlien() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "global_accounts_controller/remove_lien_from_global_account",
      {
        global_account_lien_id: modal.id,
      }
    );
    if (resp?.status === "success") {
      setModal({
        lien: false,
      });

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }

  return (
    <div style={{ height: "67vh", display: "flex", flex: 1, flexDirection: "column" }}>
      <SmartFilter
        page="insights"
        hideFilter={true}
        hideExport={true}
        dynamicNode={
          isCheckActive ? (
            <Row gap={1.6}>
              {/* <UnblockBizBtn
                onClick={() => {
                  triggerBlock(showBlockModal, blockModal);
                }}
              />
              <MessageBizBtn
                onClick={() => {
                  showSendMessages({
                    on: true,
                    chi: {
                      count: checked?.length,
                    },
                  });
                }}
              /> */}
            </Row>
          ) : null
        }
        searchTitle={`Search liened account by email...`}
        // pagination={{
        //   currentPage: currentPage,
        //   itemsPerPage: Number(businesses.per_page),
        //   // itemsPerPage: Number(businesses.per_page),
        //   totalItems: businesses.total,
        //   onPageChange: function (page: number): void {
        //     setCurrentPage(page);
        //   },
        //   activeFilter: `All ragnarred`,
        // }}
      />

      {(!isRedacting && loading) || liens?.length === 0 || !liens ? (
        <EmptyScreen loading={loading} />
      ) : (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "Business Email",
              "Initiator",
              // "Reason for Lien",
              "Account ID",
              "Amount",
              "Date Unliened",
            ]}
          >
            {liens?.map((data, index: number) => (
              <RavenTableRow
                key={index}
                {...{
                  one: loading ? <Redacted.Table /> : data.business_email,
                  two: loading ? <Redacted.Table /> : Util.safeValue(data.initiated_by),
                  // three: loading ? <Redacted.Table /> : Util.safeValue(data),
                  four: loading ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <Row gap={0.5} style={{ alignItems: "center" }}>
                      <figure className="currency_flag">
                        {loading || !data?.currency ? (
                          <Redacted.Avatar />
                        ) : (
                          <Image
                            src={`https://businessapi.getraventest.com/files/currencies/${data?.currency.toLowerCase()}.png`}
                            alt={data?.currency}
                            fallbackSrc="/assets/eur.png"
                          />
                        )}
                      </figure>
                      {Util.cleanText(Util.safeValue(data.currency))}
                    </Row>
                  ),
                  five: loading ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(Number(data.amount), "NGN")
                  ),
                  six: formatDateTime(data.created_at),

                  // six: (
                  //   <div>
                  //     <Dropdown
                  //       items={dropdown(Number(data.status)) as any}
                  //       defaultValue={dropdown(Number(data.status))[0]}
                  //       onChange={(e) => {
                  //         setModal({ lien: true, id: String(data.id) });
                  //       }}
                  //       className="ragna-dropdown"
                  //       buttonChild={() => <ActionDrop more />}
                  //     />
                  //   </div>
                  // ),
                }}
              />
            ))}
          </RavenTable>
        </div>
      )}
      <ActionModal
        visible={modal?.lien as boolean}
        onCancel={() => {
          setModal({ lien: false });
        }}
        loading={isReqn}
        onClick={() => {
          handleLienUnlien();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, Unlien`}
        btnColor={"red"}
        body={
          "Are you sure to unlien this Business account? You can always come back to perform the action."
        }
        title={"Unlien Account"}
      />
    </div>
  );
};

export default UnlienedBusinesses;
