import BAddressVerification from "./address_verification";
import BBusinessInfo from "./business_info";
import BBvnVerification from "./bvn_verification";
import BCacVerification from "./cac_verification";
import BNinVerification from "./nin_verification";

export const BusinessInfoCompliancePage = () => {
  return <BBusinessInfo tab="business_info" />;
};

export const BusinessCacCompliancePage = () => {
  return <BCacVerification tab="cac" />;
};

export const BusinessBvnCompliancePage = () => {
  return <BBvnVerification tab="bvn" />;
};

export const BusinessNINCompliancePage = () => {
  return <BNinVerification tab="nin" />;
};

export const BusinessAddressVerification = () => {
  return <BAddressVerification tab="address" />;
};

export const BusinessCacVerification = () => {
  return <BCacVerification tab="cac" />;
};
