import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyScreen from "@/components/common/emptyScreen";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { Util } from "@/utils/utility";
import { SETTLEMENT_STATUS } from "@/apps/pos/__internals__/constants/card";
import { CopyText } from "@/components/common/copyCheck";
import Redacted from "@/components/common/shimmer/redact";
import { XStack } from "@/components/common/stacks";
import TwinValue from "@/components/common/twinValue";
import { capitalizeEachWord } from "@/utils/formatWord";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { useQueryLoadingState } from "@/apps/pos/__internals__/hooks/useQueryLoadingState";

const AirtimeHeader = [
  "MERCHANT DETAILS",
  "ACCOUNT DETAILS",
  "BANK DETAILS",
  "TYPE",
  "AMOUNT",
  "DATE",
  "ACTION",
];
export const PosMerchantSettlement = ({ email }: { email?: string }) => {
  const { settlements, combinedLoading, isRedacting } = useSettlement({
    status: SETTLEMENT_STATUS.FAILED,
    email: email as string,
  });
  return isEmpty(settlements) ? (
    <EmptyScreen loading={combinedLoading || isRedacting} />
  ) : (
    <>
      <SmartFilter />
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(settlements)?.map((data, idx) => {
          const {
            account_name,
            email,
            account_number,
            bank,
            settlement_type,
            amount,
            created_at,
          } = data;
          return (
            <RavenTableRow
              key={idx}
              one={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  <CopyText
                    text={Util.safeValue(email)}
                    textToCopy={Util.safeValue(email)}
                  />
                )
              }
              two={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  <XStack className="biller-row">
                    <p>{Util.safeValue(account_name)}</p>
                  </XStack>
                )
              }
              three={
                isRedacting ? (
                  <Redacted.Labelled />
                ) : (
                  <TwinValue
                    one={Util.safeValue(bank)}
                    two={Util.safeValue(account_number)}
                  />
                )
              }
              four={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  Util.safeValue(capitalizeEachWord(settlement_type))
                )
              }
              five={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  formatNumberToCurrency(Number(amount), "NGN")
                )
              }
              six={isRedacting ? <Redacted.Table /> : formatDateTime(created_at as Date)}
              seven={isRedacting ? <Redacted.Badge /> : formatStatus("pending")}
            />
          );
        })}
      </RavenTable>
    </>
  );
};

const useSettlement = ({ status, email }: { status: SN; email: string }) => {
  const pagination = usePagination();

  const res = posReduxAPI.useGetSettlementsQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
    status: status,
    email: email,
  });
  const { data } = res;

  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  const settlements = data?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const combinedLoading = useQueryLoadingState(res);

  const navigate = useNavigate();

  return {
    pagination,
    res,
    data,
    SmartFilterProps,
    settlements,
    isRedacting,
    combinedLoading,
    navigate,
  };
};
