import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";
import { useSmartFilterCustomFilter } from "@/hooks/useSmartFilterProps";
import { PB_CARD_REQUEST_STATUS } from "../combined-tables/cards/physical-cards-request/tables";
import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";

export const usePersonalCardFilter = () => {
  const value = useSmartFilterCustomFilter([
    {
      label: "status",
      options: [
        { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
        { label: "Active", value: 1 },
        { label: "Inactive", value: "0" },
      ],
      defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
    },
  ]);

  return value;
};
export const usePhysicalPersonalCardFilter = () => {
  const value = useSmartFilterCustomFilter([
    {
      label: "status",
      options: [
        { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
    },
  ]);

  return value;
};

export const usePersonalCardRequestFilter = () => {
  const options = [
    { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
    { label: "Approved", value: PB_CARD_REQUEST_STATUS.APPROVED },
    { label: "Assigned", value: PB_CARD_REQUEST_STATUS.ASSIGNED },
    { label: "Pending", value: PB_CARD_REQUEST_STATUS.PENDING },
  ];
  const statusFilter = {
    label: "status",
    options,
    defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
  };

  const value = useSmartFilterCustomFilter([statusFilter]);

  const exports = usePersonalExportModal({
    fileName: "pyhsical-card-request",
    filterParams: [
      {
        type: "checkbox",
        key: "status",
        ...statusFilter,
      },
    ],
    route: "/export_physical_card_requests",
    title: "Export Deposits",
    overrideRoute: "/support/export_physical_card_requests",
  });

  return { ...value, ...exports };
};
