import "./style/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatNumWithComma, symbol } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";

interface SelectedExpenseModalProps {
  onCancel: OnFunction;
  visible: boolean;
  selectedRow: BusinessExpenseRecord;
  image_base_url: string;
}

export const SelectedExpenseModal = (props: SelectedExpenseModalProps) => {
  const { onCancel, visible, selectedRow, image_base_url } = props;

  const {
    image,
    amount,
    category,
    description,
    created_at,
    currency,
    unique_code,
    logger,
    expense_date,
  } = selectedRow;

  const imgSrc = `${image_base_url}/${image}`;

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      onClick={() => {}}
      btnColor="#FF0F00"
      title="Expense Details"
      hideCancel
      hideNormalButton
    >
      <div className="expense__details">
        <img className="expense__image" src={imgSrc} alt="" />
        <InlineDetails
          direction=""
          title={"Transaction Details"}
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={[
            {
              label: "Amount",
              value: cn(
                symbol(currency.toLowerCase()),
                formatNumWithComma(amount, "true")
              ),
            },
            { label: "Category", value: category },
            { label: "Code", value: String(unique_code) },
            { label: "Description", value: description, trim: true, tooltip: true },
            { label: "Logged By", value: Util.safeValue(logger as any) },
            { label: "Expense Date", value: formatDateTime(expense_date) },
            { label: "Date Logged", value: formatDateTime(created_at) },
          ]}
        />
      </div>
    </ActionModal>
  );
};
