import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useSingleUserState } from "../../context";
import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { PersonalDepositsTable } from "@/apps/personal/__internals__/combined-tables/deposits/deposits-table";
import { usePbDepositsFilter } from "@/apps/personal/__internals__/internal-hooks/deposits";

export const UsersDeposits = () => {
  const { email } = useSingleUserState();
  const { filterArgs, filterProps, pagination, ExportModal } = usePbDepositsFilter(email);

  const { data, isFetching, isLoading } = personalReduxAPI.useGetUserDepositsQuery({
    page: pagination.currentPage,
    per_page: GLOBAL_PER_PAGE,
    email,
    status: filterArgs?.status,
    search: pagination.searchQuery,
  });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading ?? isFetching;

  if (isLoading || !data)
    return <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />;

  const redactedFn = renderRedactedComponent(joinedLoader);

  return (
    <HeightWithMarginOffset>
      <SmartFilter {...SmartFilterProps} {...filterProps} />
      {data.deposits.length > 0 ? (
        <PersonalDepositsTable ctx="user" data={data.deposits} redactedFn={redactedFn} />
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};
