import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../shared";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { formatNumberToCurrency } from "@/utils/helpers";
import { ALL_CURRENCY_LABEL, STRING_TRIM_LENGTH } from "@/constants";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { getCombinedTransferStatus } from "./utils";
import { ConditionalCopyText, CopyText } from "@/components/common/copyCheck";
import { Util } from "@/utils/utility";
import { useState } from "react";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { setFnToUndefined } from "@/utils/general";

type Props = CreateTableRowProps<{ data: PersonalInternalTransfersModel[] }>;

export const PersonalInternalTransferOutTable = (props: Props) => {
  const [record, setRecord] = useState<PersonalInternalTransfersModel>();
  const { renderDynamicModal } = useDynamicModal(record);
  const { data } = props;
  const { RedactedTable } = props.redactedFn;

  const { getStatus } = getCombinedTransferStatus("internal");

  return (
    <RavenTable
      className="table__main"
      headerList={["PAYMENT REF", "FROM", "TO", "AMOUNT", "DATE INITIATED", "STATUS"]}
    >
      {data.map((row) => {
        const { id, created_at, reference, email, _to, status, amount, refunded } = row;

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              reference ? (
                <CopyText text={Util.safeText(reference, STRING_TRIM_LENGTH)} />
              ) : (
                "--"
              )
            )}
            two={RedactedTable(Util.safeText(email))}
            three={RedactedTable(_to ? cleanWhiteSpace("@", _to) : "--")}
            four={RedactedTable(formatNumberToCurrency(amount, ALL_CURRENCY_LABEL.NGN))}
            five={RedactedTable(formatDateTime(created_at))}
            six={RedactedTable(formatStatus(getStatus(status, refunded)))}
            onRowClick={() => setRecord(record)}
          />
        );
      })}

      {record &&
        renderDynamicModal(
          <DetailsModal record={record} onCancel={setFnToUndefined(setRecord)} />
        )}
    </RavenTable>
  );
};

type DetailsModalProps = BaseModalProps<{ record: PersonalInternalTransfersModel }>;

const DetailsModal = (props: DetailsModalProps) => {
  const { onCancel, record } = props;
  const { getStatus } = getCombinedTransferStatus("internal");

  const view = {
    From: <ConditionalCopyText text={record.email} />,
    To: record._to ? cleanWhiteSpace("@", record._to) : "--",
    Amount: formatNumberToCurrency(record.amount),
    Description: Util.safeText(record.narration),
    Status: formatStatus(getStatus(record.status, record.refunded)),
    // Ref: <ConditionalCopyText text={record.ref} />,
    "Reference Id": <ConditionalCopyText text={record.reference} />,
    Date: formatDateTime(record.created_at),
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => onCancel()}
      title="Transfer Details"
      hideCancel
      className="very-big-modal"
      actionText="Close"
    >
      <div className="expense__details deposit-details-modal hide-scrollbar">
        <InlineDetails
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={Object.entries(view).map(([label, value]) => ({ label, value }))}
        />
      </div>
    </ActionModal>
  );
};
