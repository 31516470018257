import "./style/index.css";
import { iife } from "@/utils/general";
import { PersonalExportModalProps, SchemaErrorState } from "../utils";
import { icons } from "@/assets/icons";
import { TypeIs } from "@/utils/helpers";
import { XStack } from "@/components/common/stacks";
import { Input } from "@/components/common/input";
import { cn } from "@/utils/colorConvert";
import { reactSelectStyle } from "@/utils/helper/Helper";
import { Fragment } from "react/jsx-runtime";

type Filter = Record<string, IsUncertain<SN>>;
interface Props {
  filterParams: PersonalExportModalProps["filterParams"];
  onChange(record: Filter): void;
  filters: Filter;
  errors: SchemaErrorState;
}

export const ModalInputs = (props: Props) => {
  const { filterParams, filters, onChange, errors } = props;
  // const {  } = props
  const isSelected = (value: SN) => value in (filters ?? {});

  const renderRadio = (key: string, value: SN) => {
    if (!isSelected(key)) return icons.radio_unchecked;

    const selected = TypeIs.any(filters ?? {})[key] === value;

    return selected ? icons.radio_check : icons.radio_unchecked;
  };
  const handleChange = (key: string, arg: SN) => {
    const temp = filters ?? {};
    const selectedState = { ...temp, [key]: arg };
    onChange(selectedState);
  };

  return (
    <div className="pb-export-modal-inputs">
      {filterParams.map((filter) => {
        const { key, label, type, hidden } = filter;

        if (hidden) return <Fragment key={filter.key} />;

        return (
          <div className="pb-export-modal-inputs__item" key={filter.key}>
            {iife(() => {
              if (type === "checkbox") {
                return (
                  <div className="pb-export-modal-inputs--checkbox">
                    <p className="pb-export-modal-inputs--tile">{label}</p>
                    <div>
                      <div className="pb-export-modal-inputs--checkbox-items">
                        {filter.options.map((opt) => (
                          <button
                            onClick={() => handleChange(filter.key, opt.value)}
                            key={opt.value}
                            type="button"
                            className="custom-filter__item__options__trigger"
                          >
                            <XStack gap={0.5} ai="center">
                              {renderRadio(key, opt.value)}
                              <p>{opt.label}</p>
                            </XStack>
                          </button>
                        ))}
                      </div>
                      {errors?.[key] && (
                        <p className="error-text mt-01">{errors?.[key]}</p>
                      )}
                    </div>
                  </div>
                );
              }

              if (type === "select")
                return (
                  <Input
                    className="bugiss-block-modal"
                    label={label}
                    onChange={(event: any) => handleChange(key, event.value)}
                    placeholder={filter.placeholder ?? cn("Select option")}
                    type="select"
                    selectOption={filter.options}
                    selectStyles={reactSelectStyle}
                    menuPlacement="top"
                    showError={Boolean(errors?.[key])}
                    errorText={errors?.[key]}
                  />
                );

              return (
                <Input
                  label={label}
                  placeholder={filter.placeholder ?? label}
                  name={key}
                  type={type}
                  onChange={(event: any) => {
                    handleChange(key, event.target.value);
                  }}
                  showError={Boolean(errors?.[key])}
                  errorText={errors?.[key]}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
