import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../../shared";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { STRING_TRIM_LENGTH } from "@/constants";
import { getCardDeliveryStatus, getPhysicalCardsRequestStatus } from "../utils";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus, IconVault } from "@/utils/helper/Helper";
import Dropdown from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import {
  ApproveCardRequestModal,
  MarkCardForDeliveryModal,
  PBPhysicalViewCardRequest,
  RecreateDispatchOrderModal,
} from "./modals/card-request";
import EditIcon from "./modals/edit-05.png";
import { icons } from "@/assets/icons";
import { TypeIs } from "@/utils/helpers";
import Gap from "@/components/common/styleComponents/Gap";
import { Row } from "@/components/common/stacks";

type Props = CreateTableRowProps<{ data: PbPhysicalCardRequest[]; refetch: RefetchFn }>;

// "pending" | "assigned" | "approved"
export enum PB_CARD_REQUEST_STATUS {
  PENDING = "pending",
  ASSIGNED = "assigned",
  APPROVED = "approved",
}

enum StateContext {
  view,
  approve,
  assign,
  delivery,
  orderHistory,
  recreateDispatch,
}

const dropdown = [
  {
    label: "Assign Card",
    img: EditIcon,
    ctx: StateContext.assign,
    showFor: [PB_CARD_REQUEST_STATUS.PENDING, PB_CARD_REQUEST_STATUS.APPROVED],
  },

  {
    label: "Approve Request",
    img: EditIcon,
    ctx: StateContext.approve,
    showFor: [PB_CARD_REQUEST_STATUS.PENDING],
  },
  {
    label: "View Details",
    img: IconVault(icons.arrow_circle_broken),
    ctx: StateContext.assign,
    showFor: [
      PB_CARD_REQUEST_STATUS.APPROVED,
      PB_CARD_REQUEST_STATUS.ASSIGNED,
      PB_CARD_REQUEST_STATUS.PENDING,
    ],
  },
  {
    label: "Mark For Delivery",
    img: IconVault(icons.arrow_circle_broken),
    ctx: StateContext.delivery,
    showFor: [PB_CARD_REQUEST_STATUS.PENDING] as any[],
  },
  {
    label: "Recreate Dispatch Order",
    img: IconVault(icons.arrow_circle_broken),
    ctx: StateContext.recreateDispatch,
    showFor: [PB_CARD_REQUEST_STATUS.PENDING, PB_CARD_REQUEST_STATUS.ASSIGNED] as any[],
  },
  // {
  //   label: "View Delivery History",
  //   img: IconVault(icons.arrow_circle_broken),
  //   ctx: StateContext.orderHistory,
  //   // TODO: Show for only assigned cards
  //   showFor: [PB_CARD_REQUEST_STATUS.APPROVED],
  // },
] as const;

export const PBPhysicalCardsRequestTable = (props: Props) => {
  type Selected = {
    request: PbPhysicalCardRequest;
    ctx: StateContext;
  };
  const { data, redactedFn, ctx, refetch } = props;
  const { RedactedTable } = redactedFn;
  const [selectedRequest, setSelectedRequest] = useState<Selected>();
  const { renderDynamicModal: renderActionModal } = useDynamicModal(
    [StateContext.view, StateContext.assign].includes(TypeIs.any(selectedRequest?.ctx))
  );

  const { renderDynamicModal: renderApproveModal } = useDynamicModal(
    selectedRequest?.ctx === StateContext.approve
  );
  const { renderDynamicModal: renderMarkCardForDelviery } = useDynamicModal(
    selectedRequest?.ctx === StateContext.delivery
  );
  const { renderDynamicModal: renderRecreateDispatchOrder } = useDynamicModal(
    selectedRequest?.ctx === StateContext.recreateDispatch
  );

  const closeModal = () => setSelectedRequest(undefined);

  return (
    <RavenTable
      headerList={[
        "USER DETAILS",
        "DELIVERY METHOD ",
        "FULL ADDRESS",
        "STATUS",
        "DELIVERY STATUS",
        "DATE CREATED",
        " ",
      ]}
    >
      {data.map((card) => {
        const {
          id,
          created_at,
          status,
          address,
          delivery_method,
          delivery_status,
          email,
        } = card;

        const cardStatus = getPhysicalCardsRequestStatus(status);
        const deliveryStatus = getCardDeliveryStatus(delivery_status as any);

        const RowOne =
          ctx === "page"
            ? RedactedTable(<ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />)
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            two={RedactedTable(Util.safeText(delivery_method))}
            three={
              <Row>
                {RedactedTable(Util.safeText(address))}
                <Gap h={10} />
              </Row>
            }
            four={RedactedTable(formatStatus(cardStatus))}
            five={RedactedTable(formatStatus(deliveryStatus as string))}
            six={RedactedTable(Util.safeText(formatDateTime(created_at)))}
            seven={RedactedTable(
              <Dropdown
                items={dropdown
                  .filter(({ showFor, ctx }) =>
                    showFor.includes(
                      TypeIs.any(ctx === StateContext.delivery ? delivery_status : status)
                    )
                  )
                  .filter(({ ctx }) => {
                    const isRecreateDispatch = ctx === StateContext.recreateDispatch;
                    const isDelivery = ctx === StateContext.delivery;
                    const isPickup = delivery_method === "pickup";
                    const isPendingDelivery = delivery_status === "pending" && !isPickup;

                    if (isRecreateDispatch && (isPickup || isPendingDelivery)) {
                      return false;
                    }
                    if (isDelivery && isPickup) {
                      return false;
                    }
                    return true;
                  })}
                buttonChild={() => <ActionDrop more />}
                rightPosition
                className="no--overflow"
                onChangeWithEvent={(e) => {
                  e.event.stopPropagation();
                  setSelectedRequest({ ctx: e.ctx, request: card });
                }}
              />
            )}
            onRowClick={() => {
              setSelectedRequest({ request: card, ctx: StateContext.view });
            }}
          />
        );
      })}

      {selectedRequest && (
        <>
          {renderActionModal(
            <PBPhysicalViewCardRequest
              data={selectedRequest.request}
              onCancel={closeModal}
              refetch={refetch}
            />
          )}
          {renderApproveModal(
            <ApproveCardRequestModal
              data={selectedRequest.request}
              onCancel={closeModal}
              refetch={refetch}
            />
          )}
          {renderMarkCardForDelviery(
            <MarkCardForDeliveryModal
              data={selectedRequest.request}
              onCancel={closeModal}
              refetch={refetch}
            />
          )}

          {renderRecreateDispatchOrder(
            <RecreateDispatchOrderModal
              data={selectedRequest.request}
              onCancel={closeModal}
              refetch={refetch}
            />
          )}
        </>
      )}
    </RavenTable>
  );
};
