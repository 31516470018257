import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout, { PageLayoutProps } from "@/components/layouts/dashboard/pageLayout";
import EmptyScreen, { EmptyProps } from "../emptyScreen";
import { cn } from "@/utils/colorConvert";

interface EmptyShellProps extends IChildren, Omit<PageLayoutProps, "children"> {}

export const EmptyShell = ({ children, ...rest }: EmptyShellProps) => {
  return (
    <DashboardLayout>
      <PageLayout {...rest}>{children}</PageLayout>
    </DashboardLayout>
  );
};

interface TransferEmptyScreenProps extends EmptyProps {
  height?: string;
  className?: string;
  inner?: string | boolean;
}

export const TransferEmptyScreen = (props: TransferEmptyScreenProps) => {
  const { height = "98%", className, inner, ...rest } = props;

  const renderInner = (value: string | boolean) => {
    if (typeof value === "string") return value;
    return "90%";
  };

  return (
    <div className={className} style={{ height: inner ? renderInner(inner) : height }}>
      <EmptyScreen {...rest} />
    </div>
  );
};

export const HeightWithMarginOffset = ({ className, ...props }: DIVProps) => {
  return <div className={cn("h-full mt-20", className)} {...props} />;
};
