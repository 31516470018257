import { OverviewLineChart } from "@/components/charts/overview-chart";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { Util } from "@/utils/utility";

export const PbTransactionMetrics = () => {
  const { data, isLoading } = personalReduxAPI.useGetTransactionsMetricsQuery({
    year: new Date().getFullYear(),
  });

  if (isLoading)
    return <TransferEmptyScreen className="overview-chart-loader" loading={isLoading} />;

  return (
    <div className="overview-chart-area">
      <OverviewLineChart data={Util.safeArray(data?.monthlyTransactions)} />
    </div>
  );
};
