import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import {
  RavenButton,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import { XStack } from "@/components/common/stacks";
import { disc01, icons } from "@/assets/icons";
import SmartFilter from "@/components/common/smartFilter";
import { useEffect, useState } from "react";
import { LogExpenseModal } from "./components/logExpenseModal";
import { useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { useAppDispatch } from "@/redux/store";
import {
  assertPaginationPageNumber,
  downloadFile,
  formatNumberToCurrency,
} from "@/utils/helpers";
import { cn } from "@/utils/colorConvert";
import EmptyScreen from "@/components/common/emptyScreen";
import useThemeMode from "@/hooks/useThemeMode";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import { formatWord } from "@/utils/formatWord";
import { usePagination } from "@/hooks/usePagination";
import { SelectedExpenseModal } from "./components/selected-row-modal";
import { Util } from "@/utils/utility";
import Lightbox from "@/components/common/lightbox/Lightbox";

type Category =
  | "Salary and Wages"
  | "Office Expenses"
  | "Travel Expenses"
  | "Maintenance";

export const TopRightContent = ({ onClick }: { onClick(): void }) => {
  return (
    <XStack className="bb-expenses__top_right">
      <button type="button">
        {/* <XStack gap={1} className="bb-expenses__outline_button">
          <span>View Insights</span>
          <figure className="icon-right">{icons.arrow_narrow_up_right}</figure>
        </XStack> */}
      </button>
      <RavenButton onClick={onClick} color="black-light">
        Log Expense
      </RavenButton>
    </XStack>
  );
};

const assertCategory = (category: Category | ({} & string)) => {
  switch (category) {
    case "Maintenance":
      return disc01.blue_fade;
    case "Office Expenses":
      return disc01.orange;
    case "Salary and Wages":
      return disc01.green;
    case "Travel Expenses":
      return disc01.purple;
  }
};

const TableHeader = [
  "CATEGORY",
  "CODE",
  "AMOUNT",
  "DESCRIPTION",
  "PROOF",
  "LOGGED BY",
  "DATE LOGGED",
];

export const BBExpensesPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [lightbox, showLightbox] = useState({
    on: false,
    img: "",
  });
  const dispatch = useAppDispatch();
  const { isDarkMode } = useThemeMode();
  const { currentPage, searchQuery, setCurrentPage, setSearchQuery } = usePagination();

  const fetchExpenses = async () => {
    await dispatch(
      BugissApi.business.getExpenses({
        current_page: currentPage,
        per_page: 20,
        search_term: searchQuery,
      })
    );
  };
  const { loading, expenses } = useSelector((state: RootState) => state.businessExpenses);
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const [selectedRow, setSelectedRow] = useState<SN>();

  const selectedExpense = expenses.data.find(({ id }) => id === selectedRow);

  const { renderRedacted } = renderRedactedComponent(isRedacting);

  useEffect(() => {
    fetchExpenses();
  }, [currentPage, searchQuery]);

  return (
    <>
      <DashboardLayout>
        <PageLayout
          pageTitle="Expenses"
          topRightContent={<TopRightContent onClick={() => setShowModal((c) => !c)} />}
        >
          <SmartFilter
            onSearchChange={(e: string) => setSearchQuery(e)}
            searchTitle="Search Users by username..."
            pagination={{
              currentPage: currentPage,
              itemsPerPage: assertPaginationPageNumber(expenses.per_page),
              totalItems: assertPaginationPageNumber(expenses.total),
              onPageChange: (page: number) => {
                setCurrentPage(page);
              },
              activeFilter: "All Businesses",
            }}
          />

          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            {(!isRedacting && loading) || expenses.data?.length === 0 ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable headerList={TableHeader}>
                {expenses.data.map(
                  ({
                    amount,
                    category,
                    description,
                    unique_code,
                    image,
                    created_at,
                    logger,
                    currency,
                    id,
                  }) => {
                    const imgSrc = `${expenses.image_base_url}/${image}`;

                    return (
                      <RavenTableRow
                        key={id}
                        one={renderRedacted(
                          <XStack gap={1}>
                            {assertCategory(category)} <span>{formatWord(category)}</span>
                          </XStack>,
                          "Table"
                        )}
                        two={renderRedacted(unique_code, "Table")}
                        three={renderRedacted(
                          formatNumberToCurrency(amount, currency),
                          "Table"
                        )}
                        four={renderRedacted(trimLongString(description, 30), "Table")}
                        five={renderRedacted(
                          <XStack
                            onClick={(e) => {
                              e.stopPropagation();
                              // downloadFile(imgSrc, image);
                            }}
                            gap={"10px"}
                            className="expense-download-icons"
                          >
                            <figure
                              onClick={() =>
                                showLightbox({
                                  on: true,
                                  img: imgSrc,
                                })
                              }
                              className="tooltip-hover-wrap"
                            >
                              {icons.file_attachment_02}
                              <RavenToolTip
                                color={`black-light`}
                                text={`View Proof`}
                                position={`top-left`}
                              />
                            </figure>

                            <figure
                              onClick={() => {
                                downloadFile(imgSrc, image);
                              }}
                              className="tooltip-hover-wrap"
                            >
                              {icons.download_01}
                              <RavenToolTip
                                color={`black-light`}
                                text={`Download Proof`}
                                position={`top-right`}
                              />
                            </figure>
                          </XStack>,
                          "Table"
                        )}
                        six={renderRedacted(Util.safeValue(logger), "Table")}
                        seven={renderRedacted(formatDateTime(created_at), "Table")}
                        onRowClick={() => setSelectedRow(id)}
                      />
                    );
                  }
                )}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
      {showModal && (
        <LogExpenseModal visible={showModal} onClose={() => setShowModal(false)} />
      )}

      {selectedExpense && (
        <SelectedExpenseModal
          onCancel={() => setSelectedRow(undefined)}
          visible={Boolean(selectedRow)}
          selectedRow={selectedExpense}
          image_base_url={expenses.image_base_url}
        />
      )}
      <Lightbox
        show={lightbox.on}
        sources={[lightbox.img]}
        onClose={function (): void {
          showLightbox({
            on: false,
            img: "",
          });
        }}
      />
    </>
  );
};
