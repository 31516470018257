import { icons } from "@/assets/icons";
import React, { MouseEventHandler } from "react";
import "./styles/index.css";

type _Props = {
  edit?: boolean;
  more?: boolean;
  visit?: boolean;
  onEditClick?: MouseEventHandler<HTMLElement> | undefined;
  onMoreClick?: MouseEventHandler<HTMLElement> | undefined;
  onVisitClick?: MouseEventHandler<HTMLElement> | undefined;
};
const ActionDrop = ({
  edit = false,
  more = false,
  visit = false,
  onEditClick,
  onMoreClick,
  onVisitClick,
}: _Props) => {
  return (
    <div className="action-drops">
      {more && <figure onClick={onMoreClick && onMoreClick}>{icons.more}</figure>}
      {edit && <figure onClick={onEditClick && onEditClick}>{icons.edit}</figure>}
      {visit && (
        <figure onClick={onVisitClick && onVisitClick}>
          {icons.circle_arrow_right_faded}
        </figure>
      )}
    </div>
  );
};

export default ActionDrop;
