import "./style/index.css";
import EmptyScreen from "@/components/common/emptyScreen";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import { iife } from "@/utils/general";
import { ReactNode } from "react";
import { useGetDetailsChecker } from "./context";

const joinClass = (str: string) => cleanWhiteSpace("gdc__", str);

interface Props extends IClass {
  title: string;
  formElement: ReactNode;
  /** Children is the component that shows after fetching has been carried out */
  children?: ReactNode;
}

export const DetailsWrapper = (props: Props) => {
  const { title, formElement, className, children } = props;

  const { loading, data } = useGetDetailsChecker();

  const Children = iife(() => {
    if (!data)
      return (
        <EmptyScreen
          title="Results from query shows here"
          subTitle="User registered on the personal banking product gets populated in."
          loading={loading}
        />
      );
    return children;
  });

  return (
    <div className={cn(joinClass("tab_content"), className)}>
      <div className={cn(joinClass("tab_content__right"), "hide-scrollbar")}>
        <div className={joinClass("tab_content__right__title")}>
          <h4 className="gdc-content-contained ">{title}</h4>
        </div>
        <div className={cn(joinClass("tab_content__right__form"), "hide-scrollbar")}>
          <div className="gdc-content-contained gdc-tab_content__form">{formElement}</div>
        </div>
      </div>
      <div
        className={cn(joinClass("tab_content__left"), {
          "gdc-content-spaced hide-scrollbar": data,
        })}
      >
        {Children}
      </div>
    </div>
  );
};
