import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";

const Tabs = [
  { label: "Fixed Savings", value: "fixed" },
  { label: "New Fixed Savings", value: "fixed_new" },
  { label: "Target Savings", value: "target" },
  { label: "New Target Savings", value: "target_new" },
  { label: "HIFA Savings", value: "hifa" },
  { label: "Global Savings", value: "global" },
  { label: "SpendXSave", value: "spendxsave" },
  { label: "Pocket", value: "pocket" },
];

export const PersonalSavings = () => {
  return (
    <div>
      <DashboardLayout>
        <PageLayout pageTitle="Savings">
          <div className="personal-savings">
            <div className="personal-savings__top-wrap h-full">
              <OutletTab>
                <OutletTab.Tabs baseURL="/personal-savings" tabs={Tabs} />
                <OutletTab.Outlet />
              </OutletTab>
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>
    </div>
  );
};
