import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { GLOBAL_PER_PAGE } from "@/constants";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { capitalizeFirstLetter, formatDateTime } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";

const BlockedUserHeader = ["User Details", "Last Active", "First name", "Last name"];

export const PersonalFraudPNDUsers = () => {
  const pagination = usePagination();
  const { data, isFetching, isLoading } = personalReduxAPI.useGetFraudPNDUsersQuery({
    per_page: GLOBAL_PER_PAGE,
    search: pagination.searchQuery,
    page: pagination.currentPage,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  if (isLoading || !data) {
    return <TransferEmptyScreen className="mt-20" loading={joinedLoader} />;
  }

  return (
    <div className="table mt-20 h-full">
      <SmartFilter {...SmartFilterProps} />
      {data.users.length > 0 ? (
        <RavenTable action={false} className="table__main" headerList={BlockedUserHeader}>
          {data.users.map((row) => {
            return (
              <RavenTableRow
                key={row.id}
                {...{
                  one: RedactedTable(
                    <ClickAbleEmail email={row.email}>
                      <div>
                        <TwinValue
                          one={Util.safeText(row.email)}
                          two={Util.safeText(cleanWhiteSpace("@", row.username))}
                        />
                      </div>
                    </ClickAbleEmail>
                  ),
                  two: RedactedTable(formatDateTime(row.last_active_at)),
                  three: RedactedTable(capitalizeFirstLetter(Util.safeText(row.fname))),
                  four: RedactedTable(capitalizeFirstLetter(Util.safeText(row.lname))),
                }}
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen loading={joinedLoader} height="92%" />
      )}
    </div>
  );
};
