import { GENERAL_CLASSES } from "@/constants";
import { DetailsCheckerAPI } from "@/redux/slices/details-checker/api";
import { Validator } from "@/utils/validator";
import { useState } from "react";
import { z } from "zod";
import { useGetDetailsChecker } from "./details-wrapper/context";
import { APIResponseHandler } from "@/utils/api";
import { JoinedDetailsSubmitButton } from "./nin-bvn-voters-form";
import { isObjectWithValidValues } from "@/utils/helpers";
import { Input } from "@/components/common/input";
import { reactSelectStyle } from "@/utils/helper/Helper";

const schema = Validator.object({
  smart_card_number: Validator.string("Smart Card Number"),
  provider: Validator.string("Provider"),
  tv_service_provider: Validator.string("Tv Service Provider"),
});

// LENGTH OF CARD NUMBER is 9
// 7034453573
//7551930236

/** 
 * {
    "status": "success",
    "message": "cable information retrieved successfully",
    "data": {
        "smart_card_number": "7034453573",
        "customer_name": "SALIU MOHAMMED",
        "account_status": "Open",
        "due_date": "2024-04-30T00:00:00",
        "type": "DSTV"
    }
}
 */
export type METER_NUMBER_PAYLOAD = z.infer<typeof schema>;

type SelectOption = { label: string; value: string }[];
const providerOptions: SelectOption = [
  { label: "DStv", value: "dstv" },
  { label: "GOtv", value: "gotv" },
  { label: "StarTimes", value: "startimes" },
];

const tvServiceProviderOptions = [
  { label: "VTpass", value: "1" },
  { label: "Shago", value: "2" },
];

const initialState = {
  provider: "",
  smart_card_number: "",
  tv_service_provider: "",
};

type State = typeof initialState;

export const CableInfoChecker = () => {
  const [formData, setFormData] = useState<State>(initialState);
  const [errors, setErrors] = useState(Validator.createError(schema));
  const { setData, setLoading, loading } = useGetDetailsChecker();

  const handleChange = (key: keyof State, value: string) => {
    setFormData((old) => ({ ...old, [key]: value }));
  };

  const getSelected = (value: string, data: SelectOption) => {
    return data.find((opt) => value === opt.value);
  };
  const fields = [
    {
      label: "Provider",
      placeholder: "Select Provider",
      name: "provider" as const,
      selectOption: providerOptions,
      onChange: (data: any) => handleChange("provider", data.value),
      type: "select" as const,
      selectStyles: reactSelectStyle,
      value: getSelected(formData.provider, providerOptions),
    },
    {
      label: "TV Service Provider",
      placeholder: "Select TV Service Provider",
      name: "tv_service_provider" as const,
      selectOption: tvServiceProviderOptions,
      onChange: (data: any) => handleChange("tv_service_provider", data.value),
      type: "select" as const,
      selectStyles: reactSelectStyle,
      value: getSelected(formData.tv_service_provider, tvServiceProviderOptions),
    },
    {
      label: "Smart Card Number",
      placeholder: "Enter Smart Card Number",
      name: "smart_card_number" as const,
      onChange: (e: any) => handleChange("smart_card_number", e.target.value),
      type: "text",
      value: formData.smart_card_number,
    },
  ];

  const fetchDetails = async (payload: METER_NUMBER_PAYLOAD) => {
    setLoading(true);
    try {
      const response = await DetailsCheckerAPI.checkCableInfoDetails(payload);

      const success = APIResponseHandler.tryHandler({
        response,
        hideSuccessToast: false,
      });
      if (success) {
        setData(response.data.data);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    }
    setLoading(false);
  };
  const handledSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    Validator.validatorFunc({
      data: formData,
      onError: setErrors,
      onSuccess: fetchDetails,
      schema,
    });
  };

  return (
    <form className={GENERAL_CLASSES.DETAILS_CHECKER_FORM} onSubmit={handledSubmit}>
      {fields.map(({ name, ...rest }) => {
        const errorText = errors?.[name];

        return (
          <Input
            key={name}
            {...rest}
            errorText={errorText}
            showError={Boolean(errorText)}
          />
        );
      })}
      <JoinedDetailsSubmitButton
        disabled={!isObjectWithValidValues(formData)}
        className="mt-20"
        loading={loading}
      />
    </form>
  );
};
