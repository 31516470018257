import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import SmartFilter from "@/components/common/smartFilter";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";

const tabElements = ["Airtime", "Data", "Electricity", "Cable TV", "Betting"];

const PageBaseURL = "/pos-bills";

export const PosBills = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Bills">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="airtime"
            ></OutletTab.Tabs>
            <Gap size={15} />

            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
