import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow, RavenButton } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import { IconVault } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { icons } from "@/assets/icons";
import bankboxImg from "@/assets/images/bankbox-bb.png";
import "../styles/index.css";
import { useNavigate } from "react-router-dom";

const AtlasBankBoxDetail = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });

  const dropdown = [
    {
      label: `Mark as Paid`,
      img: IconVault(icons.check_circle),
      func: showMarkPaid,
    },
    {
      label: `Refire`,
      img: IconVault(icons.refire),
      func: showRefireModal,
    },
  ] as const;

  const mockData = [
    {
      businessDetails: {
        email: "adeekoemmanuel007@gmail.com",
        name: "374947356892 • Bunny Ventures",
      },
      employees: "55",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "123456789",
      senderDetails: {
        name: "John Doe",
        account: "Opay • 987654321",
      },
      status: "pending",
      amount: "₦500,000.00",
      dateDeposited: "1 Jan, 2024 • 10:15AM",
    },
    {
      businessDetails: {
        email: "adeekoemmanuel007@gmail.com",
        name: "374947356892 • Bjorn Ragnar",
      },
      employees: "13",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "987654321",
      senderDetails: {
        name: "Jane Smith",
        account: "Flutterwave • 123456789",
      },
      status: "deactivated",

      amount: "₦750,000.00",
      dateDeposited: "15 Feb, 2024 • 2:30PM",
    },
    {
      businessDetails: {
        email: "adeekoemmanuel007@gmail.com",
        name: "374947356892 • Joe Ship",
      },
      employees: "23",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "345678912",
      senderDetails: {
        name: "Robert Johnson",
        account: "PayPal • 456789012",
      },
      status: "paid",

      amount: "₦1,200,000.00",
      dateDeposited: "20 Mar, 2024 • 4:45PM",
    },
  ];

  const navigate = useNavigate();
  return (
    <>
      <DashboardLayout>
        <PageLayout
          style={{ backgroundColor: "transparent", width: "100%" }}
          pageTitle="Terminal Details"
        >
          <div className="bb-payroll-top-wrap">
            <div className="bb-payroll-top-wrap__main-content">
              <div className="bb-payroll-top-wrap__main-content--profile-img">
                <img src={bankboxImg} alt="" />
              </div>

              <div className="bb-payroll-top-wrap__main-content--user-data">
                <div className="user-data__main-content">
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Total Amount</small>
                      <p>Adeeko Emmanuel</p>
                    </div>

                    <div className="main-content__col--item">
                      <small>Date Initiated</small>
                      <p>204</p>
                    </div>
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Total Recipient</small>
                      <p>@monalee</p>
                    </div>
                    <div className="main-content__col--item">
                      <small>Total Succesful</small>
                      <p>3 months ago</p>
                    </div>
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Total Fees</small>
                      <p>₦12,000.00</p>
                    </div>

                    <div className="main-content__col--item">
                      <small>Total Pending</small>
                      <p>Verified</p>
                    </div>
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Mobile Number</small>
                      <p>+234 • 701 •5263 •711</p>
                    </div>

                    <div className="main-content__col--item">
                      <small>Total Referred Users</small>
                      <p>324</p>
                    </div>
                  </div>
                </div>

                <div className="bb-payroll-action-btn">
                  <div className="del-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.bb_edit}</figure>
                      Disable Terminal
                    </RavenButton>
                  </div>

                  <div className="edit-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.arrow_forward_circular}</figure>
                      View Alert Reciever
                    </RavenButton>
                  </div>

                  <div className="edit-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.arrow_forward_circular}</figure>
                      View Payment Flyer
                    </RavenButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bb-payroll-table">
            <SmartFilter
              pagination={{
                currentPage: 0,
                itemsPerPage: 0,
                totalItems: 0,
                onPageChange: function (page: number): void {
                  throw new Error("Function not implemented.");
                },
                activeFilter: "All Pending Deposits",
              }}
              searchTitle="Search pending deposits..."
            />

            <div className={cn("bb-trx-table", isDarkMode && "pb-trx-table-dark")}>
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "Email",
                  "Type",
                  "Amount",
                  "Details",
                  "Status",
                  "Transaction Date",
                  " ",
                ]}
              >
                {mockData.map((data, index) => (
                  <RavenTableRow
                    key={index}
                    one={
                      <div
                        style={{ display: "flex", alignItems: "center", gap: "1.2rem" }}
                      >
                        <TwinValue
                          one={data.businessDetails.name}
                          two={data.businessDetails.email}
                        />
                      </div>
                    }
                    two={data.employees}
                    three={data.pendingPayments}
                    four={data.amount}
                    five={data.dateDeposited}
                    six={data.dateDeposited}
                    seven={
                      <ActionDrop
                        more
                        onMoreClick={() => {
                          navigate("/business-payroll-details/3fmio34");
                        }}
                      />
                    }
                  />
                ))}
              </RavenTable>
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default AtlasBankBoxDetail;
