import "./styles/index.css";
import React, { useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { RavenButton, RavenInputField } from "@ravenpay/raven-bank-ui";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";
import { ManualDepositResolution } from "./pages/manual-deposit-resolution";

const Tabs = ["Deposit"];

export const PersonalResolutionCenter = () => {
  const [blockModal, showBlockModal] = useState({ chi: "", on: false });
  const [integrity, showIntegrityModal] = useState({ chi: "", on: false });

  const [selectedTab, setSelectedTab] = React.useState("Deposit");

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Resolution Center">
          <div className="personal-resolution__top-wrap">
            <TabComponent onValueChange={(e) => setSelectedTab(e)} defaultValue={Tabs[0]}>
              <TabComponent.Tabs tabs={Tabs} />
              {Tabs.map((tab) => (
                <TabComponent.Content key={tab} value={tab}></TabComponent.Content>
              ))}
            </TabComponent>
          </div>
          <div className="personal-resolution__container">
            <div className="personal-resolution__container--input-view w-full">
              <div className="input-view">
                <div className="input-view__title">{selectedTab}</div>

                <ManualDepositResolution />
              </div>
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={blockModal.on}
        onCancel={() => {
          showBlockModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Unblock"
        btnColor="var(--primary-product-color)"
        body="Are you sure to unblock this user? You can always come back to perform the action."
        title="Unblock User?"
      />

      <ActionModal
        visible={integrity.on}
        onCancel={() => {
          showIntegrityModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Transaction is Genuine"
        btnColor="#1ACE37"
        hideCancel={true}
        body="This would be sending a message to 235 businesses pertaining to their blocked accounts."
        title="Confirm the Integrity of Transaction"
      />
    </>
  );
};
