import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { BusinessAPI } from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { setCookie } from "@/utils/helper/Helper";
import { SET_BUSINESS_TRANSACTIONS } from "./transaction";
import { toggleRedact } from "@/redux/actions/actions";
import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { BusinessBuilder } from "./api";
import { Util } from "@/utils/utility";

export const getBusinesses = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/businesses",
  async (payload, thunkAPI) => {
    const {
      range,
      business_email,
      period_type,
      search_term,
      per_page,
      current_page,
      date_1,
      date_2,
      paginate,
    } = payload;

    try {
      const params = {
        range: range || "3",
        business_email: business_email || "",
        period_type: period_type || "",
        search_term: search_term || "",
        per_page: per_page ? per_page.toString() : "10",
        current_page: current_page || "",
        date_1: date_1 || "",
        date_2: date_2 || "",
        join_account: "yes",
        paginate: paginate,
      };

      const data = await axios.get(`businesses/fetch_filter_and_search_all_businesses`, {
        params,
      });
      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          // toast.error(data?.data.data, {
          //   position: "top-right",
          // });
        } else {
          // toast.error(data?.data.message, {
          //   position: "top-right",
          // });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        if (Util.isNotExportReq(paginate)) {
          thunkAPI.dispatch(toggleRedact(false));
          thunkAPI.dispatch(SET_BUSINESS_BUSINESSESS(data?.data?.data.businesses));
        }

        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const blockUnlockBusiness = createAsyncThunk<any, IBlockUnblock>(
  "/block-unlock-business",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`businesses/block_and_unblock_a_business`, payload);

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const unblockMultipleBusiness = createAsyncThunk<any, { business_ids: number[] }>(
  "/unblock_businesses_in_bulk",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`businesses/unblock_businesses_in_bulk`, payload);

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const sendBulkMessages = createAsyncThunk<any, { business_ids: number[] }>(
  "/send_messages_in_bulk_to_blocked_businesses",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `businesses/send_messages_in_bulk_to_blocked_businesses`,
        payload
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const blockUnlockUser = createAsyncThunk<any, Partial<IBlockUnblock>>(
  "/block-unlock-user",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`businesses/block_and_unblock_a_user`, payload);

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const confirmBusinessIntegrity = createAsyncThunk<any, Partial<IBlockUnblock>>(
  "/confirm_integrity_for_business_under_ragnar_strike",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `businesses/confirm_integrity_for_business_under_ragnar_strike`,
        {
          params: payload,
        }
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const lienBusiness = createAsyncThunk<any, ILienUnlien>(
  "/actions/add_lien_to_a_business_account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`actions/add_lien_to_a_business_account`, payload);

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const removeLienBusiness = createAsyncThunk<any, ILienUnlien>(
  "/actions/remove_lien_placed_on_a_business_account",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(
        `actions/remove_lien_placed_on_a_business_account`,
        payload
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const deductABusiness = createAsyncThunk<any, IDeductBusinessModel>(
  "/deduct-a-business",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/transactions/debit_or_credit_a_business`, {
        source: "web",
        action: "deduct_",
        ...payload,
      });

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data?.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });
        thunkAPI.dispatch(toggleRedact(false));

        // console.log("rdac", data?.data);
        return data?.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBusinessDetails = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch-business-details",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `businesses/get_business_details?business_email=${payload.business_email}`
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }
        thunkAPI.dispatch(toggleRedact(false));
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_DETAILS(data?.data?.data));

        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getBizBankAccounts = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_bank_account_for_a_buiness",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `bank_account/get_bank_account_for_a_buiness?business_id=${payload.business_id}`
      );

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          // toast.error(data?.data.data, {
          //   position: "top-right",
          // });
        } else {
          // toast.error(data?.data.message, {
          //   position: "top-right",
          // });
        }
        thunkAPI.dispatch(toggleRedact(false));
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        // thunkAPI.dispatch(SET_BUSINESS_DETAILS(data?.data?.data));

        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));

      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBusinessTrx = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch-business-trx",
  async (payload, thunkAPI) => {
    try {
      const {
        range,
        business_email,
        period_type,
        search_term,
        per_page,
        current_page,
        date_1,
        date_2,
        transaction_type,
        paginate,
        status,
      } = payload;

      const queryParams = {
        range: range || "3",
        business_email: business_email || "",
        period_type: period_type || "",
        search_term: search_term || "",
        per_page: per_page || 20,
        current_page: current_page || "",
        date_1: date_1,
        date_2: date_2,
        transaction_type: transaction_type || "",
        paginate,
        status,
      };

      const url = `transaction/get_transactions_with_business_email`;

      const data = await axios.get(url, { params: queryParams });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data?.data?.data.transactiions));

        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBlockedUsers = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch-business-blocked",
  async (payload, thunkAPI) => {
    try {
      const params = {
        range: payload?.range ?? "3",
        business_email: payload?.business_email ?? "",
        period_type: payload?.period_type ?? "",
        search_term: payload?.search_term ?? "",
        per_page: payload?.per_page ?? 20,
        current_page: payload?.current_page ?? "",
        date_1: payload?.date_1 ?? "",
        date_2: payload?.date_2 ?? "",
        transaction_type: payload?.transaction_type ?? "",
      };

      const data = await axios.get(
        `businesses/fetch_filter_or_search_non_blocked_busineses`,
        {
          params,
        }
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BLOCKED_USERS(data?.data?.data.blocked_businesses));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchRagnaroked = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_filter_or_search_businesses_under_ragnar_strike",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `businesses/fetch_filter_or_search_businesses_under_ragnar_strike?range=${
          payload?.range ?? "3"
        }&business_email=${payload?.business_email ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&transaction_type=${
          payload.transaction_type ?? ""
        }`
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BLOCKED_USERS(data?.data?.data.businesses_with_ragnar));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchSingleBusinessTransfer = createAsyncThunk<
  any,
  Partial<PaginationQuery>
>("/fetch-single-business-trf", async (payload, thunkAPI) => {
  try {
    const {
      range,
      business_email,
      period_type,
      search_term,
      per_page,
      current_page,
      date_1,
      date_2,
      transaction_type,
      paginate,
      status,
    } = payload;

    const queryParams = {
      range: range || "3",
      email: business_email || "",
      period_type: period_type || "",
      search_term: search_term || "",
      per_page: per_page || 20,
      current_page: current_page || "",
      date_1: date_1 || "",
      date_2: date_2 || "",
      transaction_type: transaction_type || "",
      paginate,
      status,
    };

    const data = await axios.get("transfer/get_or_filter_transfers_for_a_business", {
      params: queryParams,
    });

    if (data?.data?.status === "fail") {
      thunkAPI.dispatch(toggleRedact(false));

      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      // toast.success(data?.data?.message, {
      //   position: "top-right",
      // });

      thunkAPI.dispatch(toggleRedact(false));
      thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.transfers));
      return data.data.data.transfers;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const fetchBulkTransfer = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch-bulk-trf",
  async (payload, thunkAPI) => {
    try {
      const {
        range,
        business_email,
        period_type,
        search_term,
        per_page,
        current_page,
        date_1,
        date_2,
        transaction_type,
      } = payload;

      const queryParams = {
        range: range || "3",
        business_email: business_email || "",
        period_type: period_type || "all",
        search_term: search_term || "",
        per_page: per_page || 20,
        current_page: current_page || "",
        date_1: date_1 || "",
        date_2: date_2 || "",
        transaction_type: transaction_type || "",
      };

      const data = await axios.get("transaction/get_bulk_transactions_for_businesses", {
        params: queryParams,
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data?.data?.transactiions));
        return data.data?.data?.transactiions;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBulkTransferTrx = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch-bulk-trx-trf",
  async (payload, thunkAPI) => {
    const params = {
      range: payload?.range ?? "3",
      business_email: payload?.business_email,
      period_type: payload?.period_type ?? "all",
      search_term: payload?.search_term,
      per_page: payload?.per_page,
      current_page: payload?.current_page,
      date_1: payload?.date_1,
      date_2: payload?.date_2,
      transaction_type: payload.transaction_type,
      join_business: payload.join_business,
      bulk_reference: payload?.bulk_transfer_reference,
      status: payload?.status,
    };
    try {
      const data = await axios.get(`bulk_transaction/fetch_bulk_transfer_details`, {
        params,
      });
      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(
          SET_BUSINESS_TRANSACTIONS(data.data?.data?.transaction_details)
        );
        return data.data?.data?.transaction_details;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBulkTransferTrxTwo = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_bulk_transactions",
  async (payload, thunkAPI) => {
    const params = {
      range: payload?.range ?? "3",
      business_email: payload?.business_email,
      period_type: payload?.period_type ?? "all",
      search_term: payload?.search_term,
      per_page: payload?.per_page,
      current_page: payload?.current_page,
      date_1: payload?.date_1,
      date_2: payload?.date_2,
      transaction_type: payload.transaction_type,
      join_business: payload.join_business,
      bulk_reference: payload?.bulk_transfer_reference,
      status: payload?.status,
    };
    try {
      const data = await axios.get(`bulk_transaction/fetch_bulk_transfer_details`, {
        params,
      });
      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(
          SET_BUSINESS_TRANSACTIONS(data.data?.data?.transaction_details)
        );
        return data.data?.data?.transaction_details;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);
export const fetchSingleInternalTransfer = createAsyncThunk<
  any,
  Partial<PaginationQuery>
>("/fetch-single-internal-trf", async (payload, thunkAPI) => {
  try {
    const {
      range,
      business_email,
      period_type,
      search_term,
      per_page,
      current_page,
      date_1,
      date_2,
      transaction_type,
      status,
    } = payload;

    const queryParams = {
      range: range || "3",
      email: business_email || "",
      period_type: period_type || "all",
      search_term: search_term || "",
      per_page: per_page || 20,
      current_page: current_page || "",
      date_1: date_1 || "",
      date_2: date_2 || "",
      transaction_type: transaction_type || "",
      status,
    };

    const data = await axios.get(
      "transfer/get_or_filter_internal_transfers_for_a_business",
      {
        params: queryParams,
      }
    );

    if (data?.data?.status === "fail") {
      thunkAPI.dispatch(toggleRedact(false));

      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      // toast.success(data?.data?.message, {
      //   position: "top-right",
      // });

      thunkAPI.dispatch(toggleRedact(false));
      thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.transfers));
      return data.data.data.transfers;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const fetchAirtime = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_or__filter_or_search_airtime",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`airtime/get_or__filter_or_search_airtime`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
          status: payload?.status,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.airtime_datas));
        return data.data.data.airtime_datas;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchData = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_data",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`data/get_filter_or_search_data`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
          status: payload?.status,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.data_recharges));
        return data.data.data.data_recharges;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBlockReasons = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_reasons_for_business_block",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`businesses/fetch_reasons_for_business_block`);

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));

        return data.data.data?.reasons_for_block;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchCable = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_cable",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`cable/get_filter_or_search_cable`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
          status: payload?.status,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.cable_recharges));
        return data.data.data.cable_recharges;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchElectricity = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_electricity",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`electricity/get_filter_or_search_electricity`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
          status: payload?.status,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(
          SET_BUSINESS_TRANSACTIONS(data.data.data.electricity_tarrif_recharge)
        );
        return data.data.data.electricity_tarrif_recharge;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchBetting = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/get_filter_or_search_betting",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`betting/get_filter_or_search_betting`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
          status: payload?.status,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.betting_datas));
        return data.data.data.betting_datas;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchCards = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_or_search_all_cards_with_businesses",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`cards/fetch_or_search_all_cards_with_businesses`, {
        params: {
          range: payload?.range ?? "3",
          business_id: payload?.business_id,
          period_type: payload?.period_type ?? "all",
          search_term: payload?.search_term,
          per_page: payload?.per_page,
          current_page: payload?.current_page,
          date_1: payload?.date_1,
          date_2: payload?.date_2,
          transaction_type: payload.transaction_type,
          join_business: payload.join_business,
        },
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.card_details));
        return data.data.data.card_details;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchCardsTrx = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/retrieve_a_virtual_cards_transactions",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `cards/retrieve_a_virtual_cards_transactions?range=${
          payload?.range ?? "3"
        }&email=${payload?.business_id ?? ""}&period_type=${
          payload.period_type ?? "all"
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&transaction_type=${
          payload.transaction_type ?? ""
        }card_id=${payload.card_id}`
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data.data.data.card_transactions));
        return data.data.data.card_transactions;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: any = {
  loading: false,
  businesses: [],
  business_details: [],
  bizLoading: false,
};

export const businessBusinessesSlice = createSlice({
  name: "business-businesses",
  initialState,
  reducers: {
    SET_BUSINESS_BUSINESSESS: (state, action) => {
      state.businesses = action.payload;
      state.isAuth = true;
    },
    SET_BUSINESS_DETAILS: (state, action) => {
      state.business_details = action.payload;
      state.isAuth = true;
    },
    SET_BLOCKED_USERS: (state, action) => {
      state.businesses = action.payload;
      state.isAuth = true;
    },
  },

  extraReducers: (builder) => {
    // modern approach to handling reducers
    const builderHandler = new ReduxBuilderHandler(builder, initialState);

    builderHandler.handleStates(fetchBulkTransferTrxTwo);
    builderHandler.handleStates(getBusinesses, "bizLoading");
    builderHandler.handleStates(blockUnlockBusiness);
    builderHandler.handleStates(fetchBusinessDetails, "bizLoading");
    builderHandler.handleStates(fetchBusinessTrx);
    builderHandler.handleStates(fetchSingleBusinessTransfer);
    builderHandler.handleStates(fetchBulkTransfer);
    builderHandler.handleStates(fetchSingleInternalTransfer);
    builderHandler.handleStates(fetchBulkTransferTrx);
    builderHandler.handleStates(fetchAirtime);
    builderHandler.handleStates(fetchData);
    builderHandler.handleStates(fetchElectricity);
    builderHandler.handleStates(fetchCable);
    builderHandler.handleStates(fetchBetting);
    builderHandler.handleStates(fetchCards);
    builderHandler.handleStates(fetchCardsTrx);
    builderHandler.handleStates(deductABusiness);
    builderHandler.handleStates(lienBusiness);
    builderHandler.handleStates(removeLienBusiness);
    builderHandler.handleStates(fetchBlockedUsers);
    builderHandler.handleStates(blockUnlockUser);
    builderHandler.handleStates(fetchRagnaroked);
    builderHandler.handleStates(confirmBusinessIntegrity);
    builderHandler.handleStates(sendBulkMessages);
    builderHandler.handleStates(unblockMultipleBusiness);
  },
});

// Action creators are generated for each case reducer function
export const { SET_BUSINESS_BUSINESSESS, SET_BUSINESS_DETAILS, SET_BLOCKED_USERS } =
  businessBusinessesSlice.actions;

export default businessBusinessesSlice.reducer;

export const BusinessBizApi = (builder: BusinessBuilder) => {
  return {
    getAllSubAccounts: builder.query<
      { bank_account: ISubaccountModel },
      { business_id: string }
    >({
      queryFn: async (payload) => {
        try {
          const response = await BusinessAPI.get(
            "/bank_account/get_bank_account_for_a_buiness",
            {
              params: {
                business_id: payload?.business_id,
              },
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    fetchReasons: builder.query<{ reasons: BusinessReasons[] }, { type: string }>({
      queryFn: async (payload) => {
        try {
          const response = await BusinessAPI.get("/reasons/fetch_reasons", {
            params: {
              type: payload?.type,
            },
          });
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),

    fetchBusinessTeams: builder.query<BusinessTeams, Partial<PaginationQuery>>({
      queryFn: async (payload) => {
        const {
          range,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
        } = payload;
        const params = {
          range: range || "3",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          paginate: paginate,
          business_id: payload?.business_id,
        };
        try {
          const response = await BusinessAPI.get(
            "/team-controller-admin/fetch-team-members",
            {
              params,
            }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
