import { getQueryVariable } from "@/utils/helpers";
import { BBAllTransferBankTransfer } from "./BankTransfer";
import { BBAllTransferInternalTransfer } from "./InternalTransfer";
import { BBAllTransferBulkTransfer } from "./BulkTransfer";

type ActiveTabs = "bank-transfers" | "internal-transfers" | "bulk-transfers";

export const BusinessAllTransfer = () => {
  const currentTab = getQueryVariable<ActiveTabs>("type");

  const renderTabView = () => {
    switch (currentTab) {
      case "bank-transfers":
        return <BBAllTransferBankTransfer />;
      case "internal-transfers":
        return <BBAllTransferInternalTransfer />;
      case "bulk-transfers":
        return <BBAllTransferBulkTransfer />;
      default:
        return <BBAllTransferBankTransfer />;
    }
  };

  return <div className="h-empty-screen mt-20 force-modal-mount">{renderTabView()}</div>;
};
