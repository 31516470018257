import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import {
  mapTransfersPagination,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { useSingleUserState } from "../../../context";
import { PersonalBankTransferTable } from "~pb/__internals__/combined-tables/transfers-table";
import { usePbBankTransferExport } from "@/apps/personal/__internals__/internal-hooks/transfers";
import { BANK_TRANSFER_STATUS_FILTERS } from "@/apps/personal/__internals__/pb-constants";

const statusFilters = BANK_TRANSFER_STATUS_FILTERS;

export const PersonalSingleUserBankPage = () => {
  const { filterArgs, filterProps } = useSmartFilterCustomFilter([statusFilters]);

  const pagination = usePagination();
  const { email } = useSingleUserState();
  const { currentPage, searchQuery } = pagination;
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetAllExternalTransfersQuery({
      page: currentPage,
      per_page: GLOBAL_PER_PAGE,
      email: decodeURIComponent(email),
      search: searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
      status: filterArgs.status,
    });
  const { ExportModal, onExport } = usePbBankTransferExport(email);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />;

  return (
    <HeightWithMarginOffset>
      <SmartFilter {...SmartFilterProps} {...filterProps} onExport={onExport} />
      {data.transfers.length > 0 ? (
        <PersonalBankTransferTable
          ctx="user"
          data={data.transfers}
          redactedFn={redactedFn}
          refetch={refetch}
        />
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};
