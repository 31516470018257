import React, { SyntheticEvent, useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import "./style/index.css";
import FsLightbox from "fslightbox-react";
import Lightbox from "../lightbox/Lightbox";
import imagePlaceholder from "@/assets/images/image-placeholder.png";

interface ImageData {
  src: string;
  alt: string;
  containerClassName?: string;
  isMap?: boolean;
}

interface ImagePreviewProps {
  imgData: ImageData[];
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ imgData }) => {
  const [currentImg, setCurrentImg] = useState(0);

  const handlePrevious = () => {
    setCurrentImg((prev) =>
      prev === 0 ? imgData?.filter((d) => !d.isMap)?.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentImg((prev) =>
      prev === imgData?.filter((d) => !d.isMap).length - 1 ? 0 : prev + 1
    );
  };

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = imagePlaceholder;
  };

  const [toggler, setToggler] = useState(false);

  useEffect(() => {
    setToggler(false);
  }, [imgData]);

  const filteredSrc =
    imgData?.length > 0
      ? imgData.filter((img) => img.isMap || (img.src && img.src.includes("http")))
      : [];

  const imgLightbox = filteredSrc.map((d) => d.src);

  return (
    <div>
      <Lightbox
        onClose={() => setToggler(false)}
        show={toggler}
        // onShow={(e) => set}
        sources={imgLightbox.reverse()}
      />
      {imgData?.length === 0 ? (
        <div className={`empty__image-preview`}>
          <div className="image-preview__pagination">
            {/* <figure className="disabled">{icons.arrow_back_circular_inactive}</figure> */}

            <span>
              <p>No images available</p>
            </span>
            {/* <figure>{icons.arrow_back_circular_inactive}</figure> */}
          </div>
        </div>
      ) : (
        <figure
          className={`business-img-preview-wrap ${
            imgData[currentImg]?.containerClassName ?? ""
          }`}
        >
          {filteredSrc?.length > 0 && (
            <figure onClick={() => setToggler(true)} className="expand-icon">
              {icons.expand}
            </figure>
          )}

          {filteredSrc[currentImg]?.alt && filteredSrc?.length > 0 && (
            <div tabIndex={0} className="">
              <figure onClick={handlePrevious}>
                {icons.arrow_back_circular_inactive}
              </figure>

              <span>
                <p>
                  {currentImg + 1} of {imgData?.filter((d) => !d.isMap)?.length}
                </p>
              </span>
              <figure onClick={handleNext}>{icons.arrow_forward_circular}</figure>
            </div>
          )}
          <img
            style={{ height: "34.6rem" }}
            src={imgSrc ?? imgData[currentImg]?.src}
            alt={imgData[currentImg]?.alt}
            onError={addImageFallback}
          />
        </figure>
      )}
    </div>
  );
};

export default ImagePreview;
