import React, { useEffect, useState } from "react";
import "./styles/index.css";
import { IconVault, setCookie } from "../../../utils/helper/Helper";
import { brands, icons } from "../../../assets/icons";
import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";

import { AppDispatch } from "@/redux/store";
import { validateEmail } from "@/utils/helper/ValidateInput";
import { getUser } from "@/redux/slices/auth/auth";
import { Util } from "@/utils/utility";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;
  const [formData, setFormData] = useState<AuthPayload>({
    email: "",
    password: "",
    pin: "",
  });

  const login = async () => {
    if (!validateEmail(formData.email)) {
      toast.error("Email is required");
    } else if (formData.pin?.length < 5) {
      toast.error("Pin is required");
    } else if (formData.password?.length < 3) {
      toast.error("Password is required");
    } else {
      const resp = await dispatch(BugissApi.authenticate(formData));

      // console.log(resp.payload.data.status, "dunu");
      if (resp.payload?.data?.status === "success") {
        navigate("/auth-welcome");
      }
    }
  };

  const { loading } = useSelector((state: RootState) => state.auth);

  function handleChange(e: { target: { name: string; value: string } }) {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  useEffect(() => {
    Util.checkUser(dispatch);
  }, [dispatch]);

  return (
    <div style={{ backgroundImage: IconVault(icons.login_line_bg) }} className="login">
      <div className="login__auth-form">
        <div className="login__auth-form--header">
          <figure>{brands.raven}</figure>
          <h5>Bugisss</h5>
        </div>

        <div className="login__auth-form--auth-body">
          <div className="auth-body__header">
            <h5>Welcome to Bugiss</h5>
            <p>Please log in to access and manage users, transactions at ease</p>
          </div>

          <form
            onSubmit={(e) => e.preventDefault()}
            action=""
            className="auth-body__form d-flex f-column"
          >
            <RavenInputField
              color="blue-light"
              placeholder="Enter your email address"
              label="Email Address"
              name="email"
              type="email"
              onChange={handleChange}
            />
            <RavenInputField
              color="blue-light"
              placeholder="Enter your secure password"
              label="Password"
              name="password"
              type="password"
              onChange={handleChange}
            />

            <RavenInputField
              type="pin"
              onChange={(e: any) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    pin: e,
                  };
                });
              }}
              color="blue-light"
              value={formData.pin}
              label="User Pin"
            />

            <div className="auth-body__form--submit-btn">
              <RavenButton
                onClick={login}
                loading={loading}
                // disabled={disabledAlgo}
                color={"blue-light"}
                label="Sign in to Bugiss"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="login__big-bugiss">
        <h1>Bugisss</h1>
        <h1>Bugisss</h1>
        <h1>Bugisss</h1>
      </div>
    </div>
  );
};

export default Login;
