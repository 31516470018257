import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { useState } from "react";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
import {
  assertPaginationPageNumber,
  detectStatus,
  renderConditionally,
} from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { businessReduxAPI } from "@/redux/slices/business/api";

const BBAuditTrail = () => {
  const { isDarkMode } = useThemeMode();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<AuditTrail>;
  }>({
    on: false,
    data: {},
  });

  const { data, isFetching, isLoading } = businessReduxAPI.useGetAuditTrailQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
  });

  const loading = isLoading || isFetching;

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "EMAIL ADDRESS": "email",
    "MOBILE NUMBER": "phone",
    "DATE JOINED": "created_at",
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    // data: trails,
    loading,
    valuePair,
    filterValue,
    title: "Business Export(Bugiss)",
    dataPath: "data.transactiions.data",
    apiFunction: BugissApi.business.getTransactions,
  });
  const { toggleModal } = useExportModal(handleExport);

  const audits = data?.activities;
  const trails = audits?.data ?? [];

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Audit Trail">
          <SmartFilter
            pagination={{
              currentPage,
              itemsPerPage: assertPaginationPageNumber(audits?.per_page as number),
              totalItems: assertPaginationPageNumber(audits?.total),
              onPageChange: function (page: number): void {
                setCurrentPage(page);
              },

              activeFilter: "All Audit Trail",
            }}
            onExport={() => toggleModal("Export Audit Trail")}
            onSearchChange={(e: string) => setSearchQuery(e)}
            onFilter={(e: any) => {
              setFilterValue(Util.filterStruct(e));
            }}
            searchTitle="Search Audit Trail..."
          />

          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            {!isRedacting && loading ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "INITIATOR",
                  "BUSINESS EMAIL",
                  "ACTION",
                  "DESCRIPTION",
                  "DATE",
                ]}
              >
                {trails.map((chi, idx) => {
                  return (
                    <RavenTableRow
                      onRowClick={() =>
                        setView({
                          on: true,
                          data: chi,
                        })
                      }
                      one={loading ? <Redacted.Table /> : Util.safeValue(chi?.email)}
                      two={
                        loading ? <Redacted.Table /> : Util.safeValue(chi?.business_email)
                      }
                      three={
                        <div>
                          {loading ? (
                            <Redacted.Table />
                          ) : (
                            Util.safeValue(trimLongString(chi?.action_name ?? "", 30))
                          )}
                        </div>
                      }
                      four={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(trimLongString(chi?.description ?? "", 30))
                        )
                      }
                      five={loading ? <Redacted.Table /> : formatDateTime(chi.created_at)}
                    />
                  );
                })}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {
          setView({ data: {}, on: false });
        }}
        actionText={"Close Details"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={loading}
        hideCancel
        title={`Audit Log`}
      >
        {
          <InlineDetails
            // direction={view?.data?.direction}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              // ...renderConditionally("Narration", {}),
              {
                label: "Staff Email",
                value: Util.safeValue(view?.data?.email),
              },
              {
                label: "Business Email",
                value: Util.safeValue(view?.data?.business_email),
              },
              {
                label: "Action Taken",
                value: Util.safeValue(view?.data?.action_name),
              },
              {
                label: "Description",
                value: Util.safeValue(view?.data?.description),
              },
              {
                label: "Date",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </>
  );
};

export default BBAuditTrail;
