import "./styles/index.css";
import { icons } from "@/assets/icons";
import { formatStatus } from "@/utils/helper/Helper";
import { TypeIs, detectStatus } from "@/utils/helpers";
import { useNavigate } from "react-router-dom";
import { XStack } from "../stacks";

interface PageLayoutTopBackButtonProps {
  to?: SN;
}

export const PageLayoutTopBackButton = ({ to = -1 }: PageLayoutTopBackButtonProps) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(TypeIs.any(to))} className="atom-bg-circle">
      {icons.chevron_left}
    </div>
  );
};

interface DebugStatusProps {
  withDetect?: boolean;
  status: SN;
  disable?: boolean;
  detectStatusKey?: string;
}
export const DebugStatus = (props: DebugStatusProps) => {
  const { status, withDetect, disable, detectStatusKey } = props;
  const _status = withDetect ? detectStatus(TypeIs.any(status), detectStatusKey) : status;

  return (
    <XStack gap={1}>
      {formatStatus(_status)}
      {!disable && status}
    </XStack>
  );
};
