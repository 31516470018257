import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import usePosGetQuery from "../__internals__/hooks/usePosGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";

const PosAuditTrail = () => {
  const { isDarkMode } = useThemeMode();
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosAuditTrail>("getAuditTrail");
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<PosAuditTrail>;
  }>({
    on: false,
    data: {},
  });
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Audit Trails">
        <SmartFilter {...SmartFilterProps} />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {isEmpty(tableData) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "INITIATOR",
                "MERCHANT EMAIL",
                "ACTION",
                "DESCRIPTION",
                "DATE",
              ]}
            >
              {Util.safeArray(tableData).map((data, idx) => (
                <RavenTableRow
                  key={idx}
                  onRowClick={() =>
                    setView({
                      on: true,
                      data,
                    })
                  }
                  one={
                    isRedacting ? <Redacted.Table /> : Util.safeValue(data?.support_email)
                  }
                  two={
                    isRedacting ? <Redacted.Table /> : Util.safeValue(data?.user_email)
                  }
                  three={isRedacting ? <Redacted.Table /> : Util.safeValue(data?.title)}
                  four={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(trimLongString(data?.action, 30))
                    )
                  }
                  five={
                    isRedacting ? <Redacted.Table /> : formatDateTime(data?.created_at)
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {
          setView({ data: {}, on: false });
        }}
        actionText={"Close Details"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={combinedLoading}
        hideCancel
        title={`Audit Log`}
      >
        {
          <InlineDetails
            // direction={view?.data?.direction}
            title={"Audit Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              // ...renderConditionally("Narration", {}),
              {
                label: "Staff Email",
                value: Util.safeValue(view?.data?.support_email),
              },
              {
                label: "Merchant Email",
                value: Util.safeValue(view?.data?.user_email),
              },
              {
                label: "Action Taken",
                value: Util.safeValue(view?.data?.title),
              },
              {
                label: "Description",
                value: Util.safeValue(view?.data?.action),
              },
              {
                label: "Date",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </DashboardLayout>
  );
};

export default PosAuditTrail;
