import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosSettlementApi = (builder: PosBuilder) => {
  return {
    getSettlements: createQueryWithRoute<
      PosSettlementsModel,
      Partial<PosPaginationParams & { status?: SN; email?: string }>
    >(builder, "settlements", { name: "getSettlements" }),

    getSettlementsExport: createQueryWithRoute<
      PosSettlementsModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "settlements", { export: true, name: "getSettlementsExport" }),
  };
};
