import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../shared";
import { electricityBillerIcon } from "./icons";
import { getJointBillerStatus } from "./utils";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { autoBillersLogo } from "@/constants/auto-billers-logo";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { BillerStack } from "@/components/common/stacks";
import { CopyText } from "@/components/common/copyCheck";
import { STRING_TRIM_LENGTH } from "@/constants";
import { iife, setFnToUndefined } from "@/utils/general";
import { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { PersonalJoinedBillersDetailsModal } from "./modals/joined-billers-modal";

const electricityHeader = [
  "Transaction ID",
  "Provider",
  "Meter Number",
  "Amount",
  "Transaction Date",
  "Status",
];
const cableHeader = [
  "Transaction ID",
  "Cable TV",
  "Cable Plan",
  "Card Number",
  "Amount",
  "Transaction Date",
  "Status",
];
const bettingHeader = [
  "Transaction ID",
  "Platform",
  "User Id",
  "Amount",
  "Transaction Date",
  "Status",
];

type Props = CreateTableRowProps<{ data: PersonalJointBillModel[]; tab: BillerTypeMeta }>;

export const PersonalJointBillersTable = (props: Props) => {
  const [record, setRecord] = useState<PersonalJointBillModel>();
  const { renderSafeModal } = useDynamicModal(record);
  const { RedactedTable } = props.redactedFn;

  const Header = iife(() => {
    switch (props.tab) {
      case "betting":
        return bettingHeader;
      case "cable":
        return cableHeader;
      case "electricity":
        return electricityHeader;
    }
  });

  return (
    <RavenTable className="table__main" headerList={Header}>
      {props.data.map((record) => {
        const {
          amount,
          id,
          processed_at,
          provider,
          unique_identifier,
          status,
          request_id,
          whatTypeIsThis,
          extra_data,
          _biller,
        } = record;

        const _status = getJointBillerStatus(status, whatTypeIsThis);

        if (whatTypeIsThis === "cable") {
          return (
            <RavenTableRow
              key={id}
              one={RedactedTable(
                request_id ? (
                  <CopyText
                    text={Util.safeText(request_id, STRING_TRIM_LENGTH)}
                    textToCopy={request_id}
                  />
                ) : (
                  "--"
                )
              )}
              two={RedactedTable(
                <BillerStack biller={autoBillersLogo(provider)} provider={provider} />
              )}
              four={RedactedTable(
                unique_identifier ? (
                  <CopyText
                    text={Util.safeText(unique_identifier, STRING_TRIM_LENGTH)}
                    textToCopy={unique_identifier}
                  />
                ) : (
                  "--"
                )
              )}
              three={RedactedTable(Util.safeText(extra_data))}
              five={RedactedTable(formatNumberToCurrency(amount, _biller.currency))}
              six={RedactedTable(formatDateTime(processed_at))}
              seven={RedactedTable(formatStatus(_status))}
              onRowClick={() => setRecord(record)}
            />
          );
        }

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              request_id ? (
                <CopyText
                  text={Util.safeText(request_id, STRING_TRIM_LENGTH)}
                  textToCopy={request_id}
                />
              ) : (
                "--"
              )
            )}
            two={RedactedTable(
              <BillerStack
                biller={
                  whatTypeIsThis === "electricity"
                    ? electricityBillerIcon
                    : autoBillersLogo(provider.toLowerCase())
                }
                provider={provider}
              />
            )}
            three={RedactedTable(
              unique_identifier ? (
                <CopyText
                  text={Util.safeText(unique_identifier, STRING_TRIM_LENGTH)}
                  textToCopy={unique_identifier}
                />
              ) : (
                "--"
              )
            )}
            four={RedactedTable(formatNumberToCurrency(amount, _biller.currency))}
            five={RedactedTable(formatDateTime(processed_at))}
            six={RedactedTable(formatStatus(_status))}
            onRowClick={() => setRecord(record)}
          />
        );
      })}

      {renderSafeModal(
        <PersonalJoinedBillersDetailsModal
          record={TypeIs.safe(record)}
          onCancel={setFnToUndefined(setRecord)}
          ctx={props.ctx}
        />,
        record
      )}
    </RavenTable>
  );
};
