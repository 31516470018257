import { OutletTab } from "@/components/common/OutletTab";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { TabsElement, isTabWithDropdown } from "@/components/common/Tabs/shared";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryVariable } from "@/utils/helpers";
import { useTransfersContext } from "./components/transactions-context";
import { SelectedTransactionModal } from "./components/transaction-details";

export const BusinessTransferPageContent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentTab = getQueryVariable("type");
  const { selectedTransaction, setSelectedTransaction } = useTransfersContext();

  const tabs: TabsElement[] = [
    {
      value: "transfers",
      label: "Bank Transfers",
      dropdown: {
        onChange(tab) {
          if (isTabWithDropdown(tab)) {
            navigate(`${pathname}?type=${tab.value}`);
          } else {
            navigate(`${pathname}?type=${tab}`);
          }
        },
        tabs: [
          { label: "Bank Transfers", value: "bank-transfers" },
          { label: "Internal Transfers", value: "internal-transfers" },
          { label: "Bulk Transfers", value: "bulk-transfers" },
        ],
      },
    },
    "Transfer Issues",
    "Deducted Deposits",
    "Transfer Summary",
  ];

  const getDropdownTab = (tab: TabsElement) => {
    if (!isTabWithDropdown(tab)) return;

    const selectedTab = (tab.dropdown?.tabs ?? []).find((selected) => {
      if (isTabWithDropdown(selected)) return selected.value === currentTab;
      return selected === currentTab;
    });

    return selectedTab;
  };

  const selectedDropdown = getDropdownTab(tabs[0]);

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Bank Transfer">
          <div className="business-transfers h-full">
            <div className="business-transfers__top-wrap h-full">
              <OutletTab>
                <OutletTab.Tabs
                  selectedDropdown={selectedDropdown}
                  baseURL="/business-transfer"
                  tabs={tabs}
                />
                <OutletTab.Outlet />
              </OutletTab>
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      {selectedTransaction && (
        <SelectedTransactionModal
          visible={Boolean(selectedTransaction)}
          onCancel={() => setSelectedTransaction(undefined)}
          transactions={selectedTransaction}
        />
      )}
    </>
  );
};
