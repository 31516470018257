import { PbTransactions } from "~pb/transactions";
import { PersonalResolutionCenter } from "~pb/resolution-center";
import { PersonalDispute } from "~pb/dispute";
import { personal_nested_routes } from "./nested-routes";
import { PBRewards } from "@/apps/personal/rewards";
import PersonalAuditTrail from "@/apps/personal/audit-trail";

export const personal_routes_group: RouteArray[] = [
  { path: "/personal-transactions", element: PbTransactions },
  { path: "/personal-resolution-center", element: PersonalResolutionCenter },
  { path: "/personal-dispute", element: PersonalDispute },
  { path: "/personal-rewards", element: PBRewards },
  { path: "/personal-audit-trail", element: PersonalAuditTrail },
  ...personal_nested_routes,
];
