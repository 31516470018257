import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { formatDateTime } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatNumberToCurrency } from "@/utils/helpers";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import { useState } from "react";
import useIsolatedExport from "@/hooks/useIsolatedExport";

export const BusinessTransferSummary = () => {
  const pagination = usePagination();
  const [filterValue, setFilterValue] = useState({});
  const { currentPage, searchQuery } = pagination;
  const { data, isLoading, isFetching } = businessReduxAPI.useGetAllTransferSummaryQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
  });

  const exportApi = businessReduxAPI.useGetAllTransferSummaryQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
    paginate: "no",
  });

  const { SmartFilterProps } = useSmartFilterProps(data, pagination);

  const { renderRedacted } = renderRedactedComponent(isFetching);

  const valuePair = {
    "TRANSFER COUNT": "transfer_out_count",
    "TRANSFER SUM": "transfer_out_sum",
    "TRANSFER FEES": "transfer_out_fees",
    CREATED: "created_at",
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: exportApi.data?.data as any,
    loading: isLoading,
    valuePair,
    title: "Business Transfer Summary(Bugiss)",
    filterValue,
    dispatchFunction: exportApi.refetch,
  });

  const [exportTriggered, triggerExport] = useState(false);
  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  if (isLoading) return <TransferEmptyScreen height="91%" className="mt-20" loading />;
  if (!data) return <TransferEmptyScreen />;

  return (
    <div className="h-empty-screen mt-20">
      <SmartFilter
        {...SmartFilterProps}
        onExport={() => {
          toggleModal("Export Transfer Summary");
          triggerExport(true);
        }}
        onFilter={(e: any) => {
          setFilterValue(Util.filterStruct(e) as any);
          // reset current page to 1 on filter change
          pagination.setCurrentPage(1);
        }}
      />
      {data.data?.length > 0 ? (
        <RavenTable
          headerList={["Count", "Volume Processed", "Transfer Fee", "PNL", "Date"]}
        >
          {data.data.map((record) => {
            const {
              created_at,
              id,
              transfer_out_count,
              transfer_out_sum,
              transfer_out_fees,
            } = record;

            return (
              <RavenTableRow
                key={id}
                one={renderRedacted(Util.safeValue(transfer_out_count), "Table")}
                // Not sure of this
                two={renderRedacted(
                  String(formatNumberToCurrency(transfer_out_sum, "NGN")),
                  "Table",
                )}
                three={renderRedacted(
                  String(formatNumberToCurrency(transfer_out_fees, "NGN")),
                  "Table",
                )}
                four={renderRedacted(
                  String(formatNumberToCurrency(transfer_out_fees, "NGN")),
                  "Table",
                )}
                five={renderRedacted(formatDateTime(created_at), "Table")}
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen inner loading={isFetching} />
      )}
    </div>
  );
};
