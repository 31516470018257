import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./styles/index.css";
import { OverviewLineChart } from "@/components/charts/overview-chart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "User Metrics",
      data: [
        30000, 50000, 40000, 60000, 70000, 75635, 60000, 50000, 70000, 60000, 50000,
        60000,
      ],
      fill: true,
      backgroundColor: "rgba(234, 135, 45, 0.1)",
      borderColor: "#EA872D",
      pointBackgroundColor: "#EA872D",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#EA872D",
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return `₦${context.raw.toLocaleString()}`;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        color: "#EEEEEE",
      },
    },
  },
};

const MetricsGraph: React.FC = () => {
  return (
    <div className="metrics-card" style={{ width: "100%" }}>
      <div className="metrics-card__header">
        <h2 className="metrics-card__title">USER METRICS</h2>
        <div className="metrics-card__year-selector">
          <span>Yearly</span>
          <span>2023</span>
        </div>
      </div>
      <div className="metrics-card__metrics">
        <div className="metrics-card__total-value">₦846,848,484.08</div>
        <div className="metrics-card__count">COUNT • 43527</div>
      </div>
      <div className="metrics-card__chart-container" style={{ width: "100%" }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default MetricsGraph;
