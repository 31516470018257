import { APIResponseHandler } from "@/utils/api";
import { BusinessAPI } from "@/utils/axios/business";
import { BusinessBuilder } from "./api";

// new api method
export const BusinessHniAPI = (builder: BusinessBuilder) => {
  return {
    getHniBusinesses: builder.query<
      BusinessHNIModel,
      Partial<PaginationQuery & { manager_email: string }>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_email,
          manager_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          manager_email: manager_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get(
            "business_management/fetch_hni_businesses",
            { params }
          );
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
    getOperationStaffs: builder.query<
      BusinessHNIOperatorsModel,
      Partial<PaginationQuery>
    >({
      queryFn: async (payload, thunk) => {
        const {
          range,
          business_email,
          period_type,
          search_term,
          per_page,
          current_page,
          date_1,
          date_2,
          paginate,
        } = payload;
        const params = {
          range: range || "3",
          business_email: business_email || "",
          period_type: period_type || "",
          search_term: search_term || "",
          per_page: per_page ? per_page.toString() : "10",
          current_page: current_page || "",
          date_1: date_1 || "",
          date_2: date_2 || "",
          join_businesses: "yes",
          paginate: paginate,
        };
        try {
          const response = await BusinessAPI.get("business_management/fetch_managers", {
            params,
          });
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
            dispatch: thunk.dispatch,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
