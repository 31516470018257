import "../styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { useEffect, useState } from "react";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { usePagination } from "@/hooks/usePagination";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { TypeIs, formatNumberToCurrency } from "@/utils/helpers";
import { formatWord } from "@/utils/formatWord";
import { ControlledTableContent, YStack } from "@/components/common/stacks";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import {
  ManualDepositActionState,
  ManualDepositsQuickActionsModal,
} from "../components/unapproved-deposits-details";
import { ALL_CUSTOM_EVENTS, GLOBAL_PER_PAGE, STRING_TRIM_LENGTH } from "@/constants";
import { getPbUnapprovedDepositsStatus } from "../../__internals__/combined-tables/deposits/utils";
import { CopyText } from "@/components/common/copyCheck";

const headerList = [
  "DESTINATION DETAILS",
  "Session Id",
  "Amount",
  "Senders Details",
  "Description",
  "Date Deposited",
  "STATUS",
  // " ",
];

export const CheckIcon = (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00004 7.99992L7.00004 9.99992L11 5.99992M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00004 14.6666C4.31814 14.6666 1.33337 11.6818 1.33337 7.99992C1.33337 4.31802 4.31814 1.33325 8.00004 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992Z"
      stroke="#1ACE37"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnapprovedDeposits = () => {
  const { isDarkMode } = useThemeMode();
  const pagination = usePagination();
  // TODO: update search
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetManualDepositQueueQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      session_id: pagination.searchQuery,
    } as any);
  const [selected, setSelected] = useState<ManualDepositActionState>();
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const { renderDynamicModal } = useDynamicModal(selected);

  const joinedLoader = isLoading || isFetching;

  const { RedactedLabel, RedactedTable } = renderRedactedComponent(joinedLoader);

  useEffect(() => {
    const handleRefetch = () => {
      refetch();
    };

    window.addEventListener(ALL_CUSTOM_EVENTS.LOGGED_DEPOSITS, handleRefetch);

    return () => {
      window.removeEventListener(ALL_CUSTOM_EVENTS.LOGGED_DEPOSITS, handleRefetch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !data) {
    return <TransferEmptyScreen className="mt-20" loading={joinedLoader} />;
  }

  return (
    <>
      <div className="mt-20">
        <SmartFilter {...SmartFilterProps} searchTitle="Search by session id" />
      </div>
      {data.deposits.length > 0 ? (
        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          <RavenTable className="table__main" headerList={headerList}>
            {data.deposits.map((record: any) => {
              const {
                amount,
                id,
                destination_account_number,
                originator_account_name,
                originator_account_number,
                bank,
                narration,
                session_id,
                created_at,
                status,
              } = record;

              const _status = getPbUnapprovedDepositsStatus(status);

              return (
                <RavenTableRow
                  key={id}
                  one={RedactedTable(
                    <YStack>
                      <p>{Util.safeText(destination_account_number)}</p>
                    </YStack>
                  )}
                  two={RedactedTable(
                    Util.safeNode(
                      <CopyText
                        text={Util.safeText(session_id, STRING_TRIM_LENGTH)}
                        textToCopy={session_id}
                      />,
                      session_id
                    )
                  )}
                  three={RedactedTable(Util.safeText(formatNumberToCurrency(amount)))}
                  four={RedactedLabel(
                    <TwinValue
                      one={cn(Util.safeText(originator_account_name))}
                      two={TypeIs.any(
                        <ControlledTableContent>
                          {cn(
                            Util.safeText(bank),
                            "•",
                            Util.safeText(originator_account_number)
                          )}
                        </ControlledTableContent>
                      )}
                    />
                  )}
                  five={RedactedTable(
                    formatWord(Util.safeText(narration, STRING_TRIM_LENGTH))
                  )}
                  six={RedactedTable(Util.safeText(formatDateTime(created_at)))}
                  seven={formatStatus(_status)}
                  onRowClick={() => setSelected({ ctx: "view", record })}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen loading={joinedLoader} />
      )}

      {selected &&
        renderDynamicModal(
          <ManualDepositsQuickActionsModal
            action={selected}
            setAction={setSelected}
            refetch={refetch}
          />
        )}
    </>
  );
};
