import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";

export const BANK_TRANSFER_STATUS_FILTERS = {
  label: "status",
  options: [
    { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
    { label: "Failed", value: "2" },
    { label: "Pending Reversal", value: "5" },
    { label: "Successful", value: "3" },
  ],
};
