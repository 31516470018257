import {
  DateRange,
  handleDateRangeFilter,
  ModalDayRange,
  ModalDayRangeType,
  SchemaErrorState,
} from "./utils";
import { formatDate } from "@/utils/helper/Helper";
import { cn } from "@/utils/colorConvert";
import { icons } from "@/assets/icons";
import { Input } from "@/components/common/input";

const days = ModalDayRange;

interface Props {
  setDateRange(date: DateRange): void;
  customDateRange: DateRange;
  setSelectedDay(day: ModalDayRangeType): void;
  selectedDay: ModalDayRangeType;
  setCustomDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  errors: SchemaErrorState;
}

export const ExportModalDateSelector = (props: Props) => {
  const {
    setDateRange,
    customDateRange,
    setSelectedDay,
    selectedDay,
    setCustomDateRange,
    errors,
  } = props;

  const handleCustomDateChange = (e: any, key: keyof DateRange) => {
    setCustomDateRange((old) => ({
      ...old,
      [key]: formatDate(new Date(e)),
    }));
  };

  return (
    <>
      <div className="export-modal__days-selector mt-20">
        <label htmlFor="">Date</label>

        <div className="days-selector__item-wrap">
          {days.map((range) => {
            return (
              <div
                key={range}
                onClick={() => {
                  if (range !== "Custom") {
                    const _date_range = handleDateRangeFilter(range);
                    setDateRange(_date_range);
                    setCustomDateRange({ end_date: null, start_date: null });
                  }
                  setSelectedDay(range);
                }}
                className={cn("days-selector__item", selectedDay === range && "selected")}
              >
                <p>{range}</p>
                {selectedDay === range && <figure>{icons.check_circle_green}</figure>}
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={cn(
          "export-modal__custom-date custom",
          selectedDay === "Custom" && "show"
        )}
      >
        <Input
          label="From"
          placeholder="Select a Date"
          type="date"
          onChange={(e: string) => handleCustomDateChange(e, "start_date")}
          dateOptions={{ maxDate: new Date() }}
          showError={Boolean(errors?.start_date)}
          errorText="From date is required"
        />
        <Input
          label="To"
          placeholder="Select a Date"
          type="date"
          onChange={(e: string) => handleCustomDateChange(e, "end_date")}
          dateOptions={{
            maxDate: new Date(),
            minDate: customDateRange.start_date,
          }}
          showError={Boolean(errors?.end_date)}
          errorText="To date is required"
        />
      </div>
    </>
  );
};
