import { cn } from "@/utils/colorConvert";
import { DetailsWrapper } from "../components/details-wrapper";
import {
  BVN_NIN_METER_NUMBER,
  NinOrBvnOrVotersForm,
} from "../components/nin-bvn-voters-form";
import { useGetDetailsChecker } from "../components/details-wrapper/context";
import InlineDetails from "@/components/common/inlineDetails";
import { GENERAL_CLASSES, PLACEHOLDER_IMAGE } from "@/constants";
import { formatWord } from "@/utils/formatWord";
import { Util } from "@/utils/utility";
import { useState } from "react";
import { iife } from "@/utils/general";

interface Props {
  ctx: BVN_NIN_METER_NUMBER;
}

const getTitle = (type: BVN_NIN_METER_NUMBER): string => {
  switch (type) {
    case "nin":
    case "bvn":
      return cn(type.toUpperCase(), "Checker");
    case "vin":
      return "Voter's ID";
  }
};

/** 
 * {
        "firstname": "ISMAIL",
        "lastname": "YUSUF",
        "place_of_issuance": "NIGER",
        "date_of_issuance": "06-07-2022",
        "state_of_issuance": "NIGER"
    }
 */

const BvnNINMeterNumberPage = ({ ctx }: Props) => {
  const title = getTitle(ctx);
  const [image, setImage] = useState<string>();

  const { data } = useGetDetailsChecker<DetailsCheckerNINOrBVNData>();

  const { photo, firstname, lastname, middlename, phone, phone_number, ...rest } =
    data ?? {};

  const content = Object.entries(rest).map(([key, value]) => ({
    label: formatWord(key),
    value: Util.safeText(value),
  }));

  const picture = iife(() => {
    if (!photo) return PLACEHOLDER_IMAGE;
    if (photo?.includes("http")) return photo;
    return "data:image/jpeg;base64," + photo;
  });

  return (
    <DetailsWrapper title={title} formElement={<NinOrBvnOrVotersForm type={ctx} />}>
      <div className="gtd-details-section">
        <img
          src={image ?? picture}
          className={cn(
            "gtd-details-image",
            ctx === "vin" ? "vin--image" : "bvn-nin--image"
          )}
          onError={() => {
            setImage(PLACEHOLDER_IMAGE);
          }}
          alt=""
        />
        <InlineDetails
          simple
          className={cn(GENERAL_CLASSES.NO_SHADOW)}
          content={[
            { label: "First name", value: Util.safeText(firstname) },
            { label: "Last name", value: Util.safeText(lastname) },
            { label: "Middle name", value: Util.safeText(middlename) },
            { label: "Phone Number", value: Util.safeText(phone ?? phone_number) },
            ...content,
          ]}
        />
      </div>
    </DetailsWrapper>
  );
};

export const BVNDetailsChecker = () => <BvnNINMeterNumberPage ctx="bvn" />;
export const NINDetailsChecker = () => <BvnNINMeterNumberPage ctx="nin" />;
// 90F5AEF77E521644398
export const VotersCardDetailsChecker = () => <BvnNINMeterNumberPage ctx="vin" />;
