import { SEARCH_DOES_NOT_EXIST } from "@/constants";
import { PersonalBaseParams, PersonalBuilder, createPersonalURL, pbGetHandler } from "..";
import { isEmail } from "@/utils/general";
import PersonalApi from "@/utils/axios/personal";

export const FakeSpendSave = {
  spendSaves: [],
  pagination: {
    perPage: 0,
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    totalPages: 1,
    total: 1,
  },
};
interface BaseParam extends PersonalBaseParams {
  email: string;
  type?: PersonalSavingsType;
}

type Params = Partial<BaseParam> & {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
};

type Response = CreatePersonalPaginatedResponse<"savings", PersonalsSavingModel[]>;
type SpendSaveResponse = PersonalPersonalSpendSaveResponse;
type PocketsResponse = PersonalPocketResponse;

type GetSavingsById = PersonalsSavingModel;

export const PersonalSavingsAPI = (builder: PersonalBuilder) => {
  return {
    getSavings: builder.query<Response, Params>({
      queryFn: (params) => {
        const route = params.search ? "savings/search" : "user/savings";

        return pbGetHandler({ route, params });
      },
    }),
    getSavingsById: builder.query<GetSavingsById, string>({
      queryFn: (id) => {
        return pbGetHandler({ route: `savings/${id}`, params: {} });
      },
    }),

    getSpendAndSave: builder.query<SpendSaveResponse, Params>({
      queryFn: ({ search, ...rest }) => {
        const params = { ...rest };

        if (search) {
          (params as any)[isEmail(search) ? "email" : "reference"] = search;
        }
        const route = createPersonalURL("user/spend-saves", SEARCH_DOES_NOT_EXIST);
        return pbGetHandler({ route, params });
      },
    }),
    getPockets: builder.query<PocketsResponse, Params>({
      queryFn: ({ search, ...rest }) => {
        const params = { ...rest };

        if (search) {
          (params as any)[isEmail(search) ? "email" : "reference"] = search;
        }

        return pbGetHandler({
          route: createPersonalURL("user-pockets", SEARCH_DOES_NOT_EXIST),
          params,
        });
      },
    }),
    getPocketById: builder.query<PersonalPocketData, string>({
      queryFn: (id) => {
        return pbGetHandler({ route: `pocket/${id}`, params: {} });
      },
    }),
    getSpendSaveById: builder.query<PersonalSpendSave, string>({
      queryFn: (id) => {
        return pbGetHandler({ route: `spend-save/${id}`, params: {} });
      },
    }),
  };
};

interface RangeExportArgs {
  start_date: string;
  end_date: string;
  route:
    | "export_spend_save_histories"
    | "export_savings_transactions"
    | "export_pocket_transactions";
}

export const SavingsExport = {
  async exportSavingsProduct({ route, ...params }: RangeExportArgs) {
    const res = await PersonalApi(`/support/`);
  },
};
