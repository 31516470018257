import { pbGetHandler, PersonalBuilder } from "..";

interface OverviewArgs {
  year: SN;
}

// backend endpoint is cached for an hour, just doing an overkill here.
const OneHour = 60 * 60;

export const PersonalOverviewAPI = (builder: PersonalBuilder) => {
  return {
    getOverview: builder.query<GetOverviewResponse, void>({
      keepUnusedDataFor: OneHour,
      queryFn: () => {
        return pbGetHandler({ route: "overview" });
      },
    }),
    getUserMetrics: builder.query<UserMetrics, OverviewArgs>({
      keepUnusedDataFor: OneHour,
      queryFn: (params: any) => {
        return pbGetHandler({ route: "users-overview", params });
      },
    }),
    getTransactionsMetrics: builder.query<TransactionMetrics, OverviewArgs>({
      keepUnusedDataFor: OneHour,
      queryFn: (params: any) => {
        return pbGetHandler({ route: "transactions-overview", params });
      },
    }),
  };
};

interface GetOverviewResponse {
  totalUsers: number;
  totalCashBalance: number;
  usersToday: number;
  usersThisMonth: number;
  usersThisYear: number;
  usersThisWeek: number;
  transactionsToday: number;
  transactionsThisMonth: number;
  transactionsThisYear: number;
  transactionsThisWeek: number;
}

interface UserMetrics {
  year: number;
  signupsByMonth: number[];
}

interface TransactionMetrics {
  monthlyTransactions: number[];
  year: number;
}
