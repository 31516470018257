import { useEffect, useState } from "react";
import "./style/index.css";

type Tab = {
  label: string;
  value?: string;
};

type HoverTabProps = {
  tabs: Tab[];
  value: Tab;
  onChange: (value: Tab) => void;
};

function HoverTabs({ tabs, onChange, value }: HoverTabProps) {
  const [active, setActive] = useState(value);

  useEffect(() => {
    setActive(value);
  }, [value]);

  function handleChange(tab: Tab): void {
    setActive(tab);
    onChange(tab);
  }

  return (
    <div className="hover_tab">
      {tabs.map((d) => (
        <div
          key={d.label}
          onClick={() => handleChange(d)}
          className={`hover_tab__item ${active?.label === d?.label ? "active" : ""}`}
        >
          <p>{d.label}</p>
        </div>
      ))}
    </div>
  );
}

export default HoverTabs;
