import "./style/index.css";
import { icons } from "@/assets/icons";
import Copy, { CopyText } from "../copyCheck";
import { TypeIs } from "@/utils/helpers";
import { trimLongString } from "@/utils/helper/Helper";

type TxProp = {
  reference?: string;
  direction?: "credit" | "debit" | (string & {});
  hideCopy?: boolean;
  trimLength?: number;
  trim?: boolean;
  textToCopy?: SN;
};
const Txreference = ({
  reference,
  direction,
  hideCopy = false,
  textToCopy,
  trimLength,
  trim = false,
}: TxProp) => {
  return (
    <div className="trx-ref-comp">
      {direction !== undefined ? (
        direction === "credit" ? (
          <figure className="trx-icon-box trx-icon-box--credit">{icons.credit}</figure>
        ) : (
          <figure className="trx-icon-box">{icons.debit}</figure>
        )
      ) : (
        ""
      )}
      {!hideCopy ? (
        <CopyText
          text={TypeIs.custom<string>(
            trim ? trimLongString(reference, trimLength ?? 20) : reference
          )}
          textToCopy={textToCopy}
        />
      ) : (
        <p>{trim ? trimLongString(reference, trimLength ?? 20) : reference}</p>
      )}
    </div>
  );
};

export default Txreference;
