import "./styles/index.css";
import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import SmartFilter from "../../../components/common/smartFilter";
import EmptyScreen from "../../../components/common/emptyScreen";
import {
  RavenNumberFormat,
  RavenTable,
  RavenTableRow,
  RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import Avatar, { AvatarWithTwinValue } from "../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  formatDateTime,
  formatNumWithComma,
  mapHotkey,
  trimLongString,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { dropdownIcons, icons } from "@/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Redacted from "@/components/common/shimmer/redact";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import TwinValue from "@/components/common/twinValue";
import { GENERAL_CLASSES } from "@/constants";
import { businessReduxAPI } from "@/redux/slices/business/api";
import Gap from "@/components/common/styleComponents/Gap";
import { formatNumberToCurrency } from "@/utils/helpers";

function formatStatus(status: string | number, refunded?: number) {
  let s;
  if (status === 0 || status === "blocked") {
    s = "blocked";
  }
  if (status === 1 || status === "active") {
    s = "active";
  }
  return (
    <div className={`bugiss-status ${s && s?.toLowerCase()}`}>
      <p>{s}</p>
    </div>
  );
}

const BusinessHNI = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<any>({});

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching, isLoading } = businessReduxAPI.useGetHniBusinessesQuery({});

  function handleDropSelect(arg: string, arg2?: string) {
    if (arg === "view") {
      navigate(`/business-businesses/${arg2}/transactions`);
    }
  }

  const { isDarkMode } = useThemeMode();
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "EMAIL ADDRESS": "email",
    "MOBILE NUMBER": "phone",
    "DATE JOINED": "created_at",
    STATUS: ["block_business", ""],
  };
  const combinedLoading = isFetching || isLoading;

  const triggerExport = useIsolatedExport({
    setFilterValue,
    loading: combinedLoading,
    valuePair,
    title: "Business Export(Bugiss)",
    apiFunction: BugissApi.business.getBusinesses,
    dataPath: "businesses.data",
    filterValue,
  });

  const { toggleModal } = useExportModal(triggerExport);

  const hnis = data?.data;

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="HNI Businesses">
          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            <SmartFilter
              page="insights"
              onSearchChange={(e: string) => {
                setSearchQuery(e);
                setCurrentPage(1);
              }}
              onFilter={(e: any) => {
                setFilterValue(Util.filterStruct(e));
              }}
              onExport={() => toggleModal("Export Businesses")}
              hideExport
              hideFilter
              searchTitle="Search businesses..."
              pagination={{
                currentPage: currentPage,
                itemsPerPage: data?.per_page as number,
                totalItems: data?.total as number,
                onPageChange: (page: number) => {
                  setCurrentPage(page);
                },
                activeFilter: "All Businesses",
              }}
            />

            {hnis?.length === 0 || !hnis || (!isRedacting && combinedLoading) ? (
              <EmptyScreen loading={combinedLoading} />
            ) : (
              <div className="table">
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={[
                    "BUSINESS DETAILS",
                    "MANAGERS ASSIGNED",
                    "TRANSFERS",
                    "BANK COLLECTION",
                    "  ",
                  ]}
                >
                  {hnis?.map((chi, idx) => {
                    return (
                      <RavenTableRow
                        one={
                          combinedLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <TwinValue
                              one={Util.safeValue(chi?.business_name)}
                              two={Util.safeValue(chi?.business_email)}
                              copy
                            />
                          )
                        }
                        two={
                          combinedLoading ? (
                            <Redacted.Table />
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              className={cn(GENERAL_CLASSES.TOOL_TIP_WRAPPER)}
                              onClick={(e) => {
                                // e.stopPropagation();
                              }}
                            >
                              {Number(chi?.hni_managers_details?.length)} Managers
                              <RavenToolTip
                                color={"white-light"}
                                position="top-right"
                                tooltipClassName={"hni-tooltip"}
                                children={
                                  <ManagerTooltipContent
                                    data={chi?.hni_managers_details}
                                  />
                                }
                              ></RavenToolTip>
                            </div>
                          )
                        }
                        three={
                          combinedLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <div className={cn(GENERAL_CLASSES.TOOL_TIP_WRAPPER)}>
                              <TwinValue
                                one={formatNumberToCurrency(chi?.total_transfer)}
                                two={`${formatNumWithComma(
                                  chi?.total_transfer_count
                                )} Transactions`}
                              />
                              <RavenToolTip
                                color={"white-light"}
                                position="right"
                                tooltipClassName={"hni-tooltip"}
                                children={
                                  <TransactionTooltipContent
                                    name="Transfer: "
                                    pending={{
                                      count: chi?.total_successful_transfer,
                                      sum: chi?.total_successful_transfer_count,
                                    }}
                                    succesful={{
                                      count: chi?.total_failed_transfer,
                                      sum: chi?.total_failed_transfer_count,
                                    }}
                                  />
                                }
                              />
                            </div>
                          )
                        }
                        five={
                          combinedLoading ? (
                            <Redacted.Table />
                          ) : (
                            <div>
                              <TwinValue
                                one={formatNumberToCurrency(chi?.total_bank_collection)}
                                two={`${formatNumWithComma(
                                  chi?.total_bank_collection_count
                                )} Transactions`}
                              />
                              <RavenToolTip
                                color={"white-light"}
                                position="left"
                                tooltipClassName={"hni-tooltip"}
                                children={
                                  <TransactionTooltipContent
                                    name=""
                                    pending={{ count: 0, sum: 0 }}
                                    succesful={{ count: 0, sum: 0 }}
                                  />
                                }
                              />
                            </div>
                          )
                        }
                      />
                    );
                  })}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
    </>
  );
};

export default BusinessHNI;

interface ManagerTooltip<T> {
  data: T;
}
function ManagerTooltipContent<T>({ data }: ManagerTooltip<HniManagersDetail[]>) {
  return data?.map((chi, idx) => (
    <div>
      <AvatarWithTwinValue
        name={Util.safeValue(chi?.fullname)}
        one={Util.safeValue(chi?.fullname)}
        two={trimLongString(Util.safeValue(chi?.email), 25)}
      />
      <Gap size={10} />
    </div>
  ));
}

type TransactionTooltip = {
  name: string;
  succesful: {
    count: number;
    sum: number;
  };
  pending: {
    count: number;
    sum: number;
  };
};

function TransactionTooltipContent({ succesful, name, pending }: TransactionTooltip) {
  return (
    <div>
      <AvatarWithTwinValue
        className="transaction-tooltip-content"
        name="Risk Risk"
        twinClassNames={{
          two: "transaction-tooltip-content__second-text credit",
        }}
        imgSrc={IconVault(icons.credit)}
        one={formatNumberToCurrency(succesful.sum)}
        two={`Successful ${name} ${RavenNumberFormat(succesful.count, {
          convertToNumber: true,
        })}`}
      />
      <Gap size={10} />
      <AvatarWithTwinValue
        className="transaction-tooltip-content"
        name="Risk Risk"
        twinClassNames={{
          two: "transaction-tooltip-content__second-text debit",
        }}
        imgSrc={IconVault(icons.debit)}
        one={formatNumberToCurrency(pending.sum)}
        two={`Failed ${name} ${RavenNumberFormat(pending.count, {
          convertToNumber: true,
        })}`}
      />
    </div>
  );
}
