import AtlasOverview from "@/apps/atlas/overview";
import AtlasTransactions from "@/apps/atlas/transactions";
import AtlasCollections from "@/apps/atlas/collections";
import AtlasTransfers from "@/apps/atlas/transfers";
import { AtlasExpenses } from "@/apps/atlas/expenses";
import { AtlasMerchants } from "@/apps/atlas/merchants";
import { MerchantDetails } from "@/apps/atlas/merchants/merchant-details";
import { AtlasVirtualAccounts } from "@/apps/atlas/virtual-accounts";
import { AtlasVirtualAccountDetails } from "@/apps/atlas/virtual-accounts/virtual-account-details";
import { AtlasBills } from "@/apps/atlas/bills";
import {
  BillsAirtimePage,
  BillsBettingPage,
  BillsCablePage,
  BillsDataPage,
  BillsElectricityPage,
} from "@/apps/atlas/bills/components/pages";
import { AtlasCard } from "@/apps/atlas/cards";
import { AtlasCardDetails } from "@/apps/atlas/cards/card-details";
import AtlasBankbox from "@/apps/atlas/bankbox";
import AtlasBankBoxDetail from "@/apps/atlas/bankbox/bankboxDetail";
import AtlasCustomers from "@/apps/atlas/customers";
import AtlasCompliance from "@/apps/atlas/compliance";

export const atlas_routes_group: RouteArray[] = [
  { path: "/atlas-overview", element: AtlasOverview },
  { path: "/atlas-merchants", element: AtlasMerchants },
  { path: "/atlas-merchants/:id", element: MerchantDetails },
  { path: "/atlas-transfers", element: AtlasTransfers },
  { path: "/atlas-transactions", element: AtlasTransactions },
  { path: "/atlas-expenses", element: AtlasExpenses },
  { path: "/atlas-collections", element: AtlasCollections },
  { path: "/atlas-virtual-accounts", element: AtlasVirtualAccounts },
  { path: "/atlas-virtual-accounts/:id", element: AtlasVirtualAccountDetails },
  {
    path: "/atlas-bills",
    element: AtlasBills,
    children: [
      { path: "", element: BillsAirtimePage },
      { path: "airtime", element: BillsAirtimePage },
      { path: "data", element: BillsDataPage },
      { path: "electricity", element: BillsElectricityPage },
      { path: "cable-tv", element: BillsCablePage },
      { path: "betting", element: BillsBettingPage },
    ],
  },
  { path: "/atlas-cards", element: AtlasCard },
  { path: "/atlas-cards/:id", element: AtlasCardDetails },
  { path: "/atlas-bankbox", element: AtlasBankbox },
  { path: "/atlas-bankbox/:id", element: AtlasBankBoxDetail },
  { path: "/atlas-customers", element: AtlasCustomers },
  { path: "/atlas-compliance", element: AtlasCompliance },
];
