import React from "react";
import DisputeHeader from "./disputeHeader";
import DisputeInfo from "./disputeInfo";
import DisputeMessage from "./disputeMessage";
import DisputeReply from "./disputeReply";

const DisputeDetails: React.FC = () => {
  return (
    <div className="bg-disputes__details">
      <DisputeHeader />
      <DisputeInfo />
      <DisputeMessage />
      <DisputeReply />
    </div>
  );
};

export default DisputeDetails;
