import "./style/index.css";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { XStack } from "@/components/common/stacks";
import { GENERAL_CLASSES } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { FaLink } from "react-icons/fa";

export interface ApproveManualAddressProps {
  view: PersonalAddress;
  setView: (view?: PersonalAddress) => void;
  showRevokeModal(id: SN): void;
  handleVerification(): void;
  tab: string;
}

export const ApproveManualAddress = (props: ApproveManualAddressProps) => {
  const { setView, showRevokeModal, view, handleVerification, tab } = props;
  const { isLoading, data, isFetching } = personalReduxAPI.useGetUserEmailQuery(
    view.email
  );

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable, RenderCustom } = renderRedactedComponent(joinedLoader);

  const getButtonText = () => {
    if (!view) return "--";

    if (view.verification_status === "pending_verification") return "Verify User";
    return "Close Modal";
  };

  const generateView = () => {
    if (!view) return {};

    const { document_type, document_url, email, home_address, landmark, lga, state } =
      view;

    return {
      name: RedactedTable(cn(Util.safeText(data?.fname), Util.safeText(data?.lname))),
      state: RedactedTable(Util.safeText(state)),
      local_government: RedactedTable(Util.safeText(lga)),
      home_address: RedactedTable(Util.safeText(home_address)),
      landmark: RedactedTable(Util.safeText(landmark)),
      email: RedactedTable(Util.safeText(email)),
      document_type: RedactedTable(Util.safeText(document_type)),
      document_url: RedactedTable(
        document_url ? (
          <a
            href={document_url}
            target="_blank"
            style={{ textDecoration: "underline" }}
            rel="noopener noreferrer"
          >
            <XStack gap={1} style={{ alignItems: "center" }}>
              <FaLink size={18} /> <span>Link</span>
            </XStack>
          </a>
        ) : (
          "--"
        )
      ),
    };
  };

  return (
    <ActionModal
      visible={Boolean(view)}
      onCancel={() => setView()}
      altCancelFunc={() => {
        setView();
        showRevokeModal(view.id);
      }}
      big
      onClick={handleVerification}
      actionText={getButtonText()}
      btnColor="var(--primary-product-color)"
      className={cn(
        "address-verification-modal compliance-modal-main-lg",
        GENERAL_CLASSES.SCROLLABLE_MODAL
      )}
      cancelText={TypeIs.any("Reject Verification")}
      title={`${formatWord(tab)} Details`}
      loading={joinedLoader}
      hideCancel={joinedLoader}
    >
      <div className="biz-compliance-results">
        {RenderCustom(
          <ImagePreview
            imgData={[
              {
                src: TypeIs.any(view?.location_image_url),
                alt: "Image 1",
                containerClassName: "biz-compliance-results__image-preview",
              },
              {
                src: TypeIs.any(view?.location_image2_url),
                alt: "Image 1",
                containerClassName: "biz-compliance-results__image-preview",
              },
            ]}
          />,
          { height: 278, width: 280, className: "shimmer redacted curved mt-20" }
        )}
        <div className="compliance-results__main compliance-lg-modal">
          <InlineDetails
            direction={""}
            title={"Transaction Details"}
            className="no-shadow"
            simple
            amount={0}
            onClick={() => {}}
            content={Object.entries(generateView()).map(([key, value]) => ({
              label: formatWord(key),
              value,
            }))}
          />
        </div>
      </div>
    </ActionModal>
  );
};
