import { cn } from "@/utils/colorConvert";
import { CreateTableRowProps } from "../shared";
import { GENERAL_CLASSES, STRING_TRIM_LENGTH } from "@/constants";
import { RavenTable, RavenTableRow, RavenToolTip, toast } from "@ravenpay/raven-bank-ui";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { getCombinedTransferStatus } from "./utils";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { ConditionalCopyText, CopyText } from "@/components/common/copyCheck";
import TwinValue from "@/components/common/twinValue";
import { APIResponseHandler } from "@/utils/api";
import { PersonalAPIService } from "@/redux/slices/personal";
import { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { icons } from "@/assets/icons";
import { useProductColor } from "@/context/ProductTheme";
import Dropdown from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { Util } from "@/utils/utility";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { ControlledTableContent, XStack } from "@/components/common/stacks";
import { IconVault } from "@/utils/helper/IconVault";
import { PersonalBankTransferIcons } from "@/assets/icons/personal-icons";
import { TransferActionsModal, SelectedAction } from "./modals";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
import { setFnToUndefined } from "@/utils/general";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { format } from 'date-fns'; 

type Status = "reversed" | "failed" | "successful" | "pending_reversal" | "pending";

type PBTProps = CreateTableRowProps<{
  data: PersonalExternalTransferModel[];
  refetch: RefetchFn;
}>;  

const renderDropdown = (status: Status) => {
  const dropdown = [ 
    {
      label: "Recheck Status",
      img: IconVault(PersonalBankTransferIcons.recheck),
      ctx: "recheck_status",
    },

    {
      label: "Mark as paid",
      img: IconVault(PersonalBankTransferIcons.mark_as_paid),
      ctx: "mark_withdrawal_as_paid",
    },
    status !== "pending"
      ? {
          label: "Make a refund",
          img: IconVault(PersonalBankTransferIcons.make_refund),
          ctx: "refund_withdrawal",
        }
      : undefined,
  ];

  return dropdown.filter(Boolean);
};

export const PersonalBankTransferTable = (props: PBTProps) => {
  const { data, redactedFn, refetch, ctx } = props;

  const { RedactedAvatar, RedactedLabel, RedactedTable } = redactedFn;
  const [selectedRechecks, setSelectedRechecks] = useState<SN[]>([]);
  const [record, setRecord] = useState<PersonalExternalTransferModel>();

  const [actions, setActions] = useState<SelectedAction>();

  const { renderSafeModal } = useDynamicModal(actions);
  const { renderDynamicModal } = useDynamicModal(record);

  const { colorVariable } = useProductColor();

  const { getStatus } = getCombinedTransferStatus("external");

  const updateRecheckArray = (idx: SN) => {
    setSelectedRechecks((old) => {
      const temp = old.filter((id) => idx !== id);
      if (temp.length < old.length) {
        return temp;
      }
      return [...old, idx];
    });
  };

  const handleRecheck = async (idx: SN) => {
    updateRecheckArray(idx);
    try {
      const response = await PersonalAPIService.markTransferAsPaidOrRefunded({
        ctx: "recheck_withdrawal",
        recordId: idx,
      });
      const isSuccess = APIResponseHandler.tryHandler({ response });
      if (isSuccess) {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      updateRecheckArray(idx);
    }
  };

  const getActionButton = (status: Status, row: PersonalExternalTransferModel) => {
    if (status === "successful")
      return (
        <>
          <button
            style={{ cursor: "pointer" }}
            type="button"
            className={cn(GENERAL_CLASSES.TOOL_TIP_WRAPPER)}
            onClick={() => handleRecheck(row.id)}
          >
            {icons.repeat_04}
            <RavenToolTip color={colorVariable} text="Recheck Status" position="left" />
          </button>
        </>
      );
    if (["pending", "failed"].includes(status)) {
      return (
        <Dropdown
          disableTriggerButton
          rightPosition
          items={renderDropdown(status) as any}
          buttonChild={() => <ActionDrop more />}
          onChange={(event: any) => {
            event.event.stopPropagation();
            if (event.ctx === "recheck_status") return handleRecheck(row.id);
            else setActions({ ctx: event.ctx, recordId: row.id });
          }}
        />
      );
    }
    return <div> </div>;
  };

  return (
    <RavenTable
      className={cn("table__main", GENERAL_CLASSES.TABLE_WITH_DROPDOWN)}
      headerList={[
        "SYSTEM",
        "Session Id",
        "EMAIL",
        "RECIPIENT",
        "AMOUNT",
        "DATE INITIATED",
        "STATUS",
        " ",
      ].filter((head) => {
        if (ctx === "page") return true;
        return head !== "EMAIL";
      })}
    >
      {data.map((row) => {
        const {
          id,
          created_at,
          status,
          amount,
          account_name,
          bank,
          account_number,
          email,
          refunded,
          processed_with,
          system_ref,
          session_id,
        } = row;

        const stats = getStatus(status, refunded);

        const {
          RedactedTable: CustomTableRedact,
          RedactedAvatar: CustomBadgeRedact,
          RedactedLabel: CustomBadgeLabel,
          RenderCustom: CustomCustom,
        } = renderRedactedComponent(selectedRechecks.find((idx) => idx === id));

        const rowSeven = getActionButton(TypeIs.custom<Status>(stats), row);

        return (
          <RavenTableRow
            key={id}
            one={CustomBadgeLabel(
              RedactedLabel(
                <TwinValue
                  one={
                    system_ref
                      ? TypeIs.any(
                          <CopyText
                            className={GENERAL_CLASSES.TWIN.CUSTOM.ONE}
                            text={Util.safeText(system_ref)}
                            textToCopy={system_ref}
                          />
                        )
                      : "--"
                  }
                  two={Util.safeText(processed_with)}
                />
              )
            )}
            two={RedactedTable(<ConditionalCopyText text={session_id} />)}
            four={
              ctx === "page"
                ? CustomTableRedact(
                    RedactedTable(
                      <ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />
                    )
                  )
                : null
            }
            five={CustomBadgeLabel(
              RedactedLabel(
                <ControlledTableContent>
                  <TwinValue
                    one={Util.safeText(account_name)}
                    two={cn(Util.safeText(account_number), "•", Util.safeText(bank))}
                  />
                </ControlledTableContent>
              )
            )}
            six={CustomCustom(RedactedTable(formatNumberToCurrency(amount, "NGN")), {
              height: 10,
              width: 80,
              className: GENERAL_CLASSES.SHIMMER,
            })}
            seven={CustomTableRedact(RedactedTable(formatDateTime(created_at)))}
            eight={CustomCustom(
              RedactedTable(<XStack gap={0.4}>{formatStatus(stats)}</XStack>),
              { height: 10, width: 40, className: GENERAL_CLASSES.SHIMMER }
            )}
            nine={CustomBadgeRedact(RedactedAvatar(rowSeven))}
            onRowClick={() => setRecord(row)}
          />
        );
      })}

      {renderSafeModal(
        <TransferActionsModal
          action={TypeIs.safe(actions)}
          onCancel={() => setActions(undefined)}
          refetch={async () => {
            setSelectedRechecks([]);
            await refetch();
          }}
          ctx={props.ctx}
        />,
        actions
      )}

      {record
        ? renderDynamicModal(
            <DetailsModal record={record} onCancel={setFnToUndefined(setRecord)} />
          )
        : null}
    </RavenTable>
  );
};

type DetailsModalProps = BaseModalProps<{ record: PersonalExternalTransferModel }>;

const DetailsModal = (props: DetailsModalProps) => {
  const { onCancel, record } = props;
  const { getStatus } = getCombinedTransferStatus("external");
  const { copy, copyText } = useCopyToClipboard({
    initialText: "Copy All Information",
  });

  const view = {
    "User Email": <ConditionalCopyText text={record.email} />,
    Amount: formatNumberToCurrency(record.amount ?? 0),
    Recipient: cn(record.account_name),
    "Account No": cn(record.account_number),
    Bank: cn(record.bank),
    Status: formatStatus(getStatus(record.status, record.refunded)),
    "System Ref": <ConditionalCopyText text={record.system_ref} />,
    "Session Id": <ConditionalCopyText text={record.session_id} />,
    Fee: formatNumberToCurrency(record.fee ?? 0),
    Date: formatDate(record.created_at as any),
  };

  

  function getOrdinalSuffix(day: string): string {
    const dayNum = parseInt(day.trim(), 10);
    const suffixes = ["th", "st", "nd", "rd"];
    const v = dayNum % 100;
    return dayNum + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
  
  function formatDate(date: Date): string {
    // Using 'date-fns' to format the date
    const actualDate = format(date, "MMMM, dd, yyyy  — hh:mm a");
    
    const splitDate = actualDate.split(",");
    
    const newDate =
      getOrdinalSuffix(splitDate[1].trim().split(" ")[0]) + 
      " " +
      splitDate[0].slice(0, 3) +
      "" +
      splitDate[2]?.replace("—", ",");
    
    return newDate;
  }


  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => {
        const {
          Status,
          "User Email": uEmail,
          "Session Id": sId,
          "System Ref": ref,
          ...rest
        } = view;

        const copyContent = {
          "User Email": record.email,
          ...rest,
          "System Ref": record.system_ref,
          "Session Id": record.session_id,
          Status: getStatus(record.status, record.refunded),
        };

        copy(copyContent);
      }}
      title="Transfer Details"
      hideCancel
      className="very-big-modal"
      actionText={copyText}
    >
      <div className="expense__details deposit-details-modal hide-scrollbar">
        <InlineDetails
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={Object.entries(view).map(([label, value]) => ({ label, value }))}
        />
      </div>
    </ActionModal>
  );
};
