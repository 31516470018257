import "~/common/shared/single-user/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import {
  TabElementDefaults,
  TabsElement,
  isTabWithDropdown,
} from "@/components/common/Tabs";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useLocation, useNavigate } from "react-router-dom";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { useDynamicQueryParams } from "@/hooks/useDynamicQueryParams";
import { SingleUserProvider } from "./context";
import EmptyScreen from "@/components/common/emptyScreen";
import { PersonalSingleUserPage } from "./page";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { getPreviousRoute } from "@/utils/helpers";

const PersonalSingleUser = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { email } = useDynamicQueryParams({ keys: "email" });

  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetUserEmailQuery(email);

  const joinedLoader = isLoading || isFetching;

  const redactedUtil = renderRedactedComponent(joinedLoader);

  const handleTabChange = (tab: TabElementDefaults) => {
    const lowerCase = (str: string) => str.toLowerCase().replaceAll(" ", "-");

    if (isTabWithDropdown(tab)) {
      navigate(`${pathname}?type=${lowerCase(tab.value)}`);
    } else {
      navigate(`${pathname}?type=${lowerCase(tab as string)}`);
    }
  };

  const tabs: TabsElement[] = [
    { label: "Transactions", value: "transactions" },
    {
      value: "transfers",
      label: "Transfers",
      dropdown: {
        onChange: handleTabChange,
        tabs: [
          { label: "Bank Transfers", value: "bank" },
          { label: "Internal Transfers", value: "internal" },
        ],
      },
    },
    {
      value: "bills",
      label: "Bills",
      dropdown: {
        onChange: handleTabChange,
        tabs: ["Airtime", "Data", "Betting", "Electricity", "Cable"],
      },
    },
    {
      value: "savings",
      label: "Savings",
      dropdown: {
        onChange: handleTabChange,
        tabs: [
          { label: "Fixed Savings", value: "fixed" },
          { label: "New Fixed Savings", value: "fixed_new" },
          { label: "Target Savings", value: "target" },
          { label: "New Target Savings", value: "target_new" },
          { label: "HIFA Savings", value: "hifa" },
          { label: "Global Savings", value: "global" },
          { label: "SpendXSave", value: "spendxsave" },
          { label: "Pocket", value: "pocket" },
        ],
      },
    },
    "Overdraft",
    "Deposits",
    {
      value: "cards",
      label: "Cards",
      dropdown: {
        onChange: handleTabChange,
        tabs: ["Virtual Cards", "Physical Cards"],
      },
    },
    "Compliance",
    "Disputes",
  ];

  if (!data)
    return (
      <DashboardLayout>
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton to={getPreviousRoute({ state })} />}
          pageTitle="User Details"
        >
          <EmptyScreen loading={isLoading} />
        </PageLayout>
      </DashboardLayout>
    );

  return (
    <SingleUserProvider user={{ ...data, refetch }}>
      <PersonalSingleUserPage
        data={data}
        redactedUtil={redactedUtil}
        email={email}
        tabs={tabs}
        refetch={refetch}
      />
    </SingleUserProvider>
  );
};

export default PersonalSingleUser;
