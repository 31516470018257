import { ALL_CURRENCIES, CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";
import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";

export const usePbTransactionExports = (email: string) => {
  const value = usePersonalExportModal({
    fileName: "User Transaction Export",
    filterParams: [
      {
        type: "select",
        label: "Currency",
        key: "currency",
        options: ALL_CURRENCIES.map(({ label, value }) => ({
          label,
          value: label,
        })),
      },
      {
        type: "checkbox",
        label: "Direction",
        key: "direction",
        defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
        options: [
          { label: "All", value: CUSTOM_FILTER_VALUE_FOR_ALL },
          { label: "Credit", value: "credit" },
          { label: "Debit", value: "debit" },
        ],
      },
    ],
    route: `/transactions/export?email=${email}`,
    title: "Export Transactions",
  });

  return value;
};
