import React, { ReactNode } from "react";
import { Shimmer, ShimmerProps } from "react-shimmer";
import "./styles/redact.css";

interface RedactedProps extends Partial<ShimmerProps> {
  secondary?: boolean;
  size?: number;
}

interface IRedactedLabelProps extends RedactedProps {
  gap?: number;
}

// Define shimmer component
const Redacted = (props: ShimmerProps) => {
  return <Shimmer {...props} />;
};

// Define different shimmer effects as subcomponents
Redacted.Avatar = (props: RedactedProps) => {
  return (
    <Shimmer
      {...props}
      className="redacted rounded"
      width={props.size || 30}
      height={props.size || 30}
      duration={1000}
    />
  );
};

Redacted.Headline = (props: RedactedProps) => {
  return <Shimmer {...props} width={70} height={30} duration={1000} />;
};

Redacted.Title = (props: RedactedProps) => {
  return <Shimmer {...props} width={50} height={20} duration={1000} />;
};

Redacted.Table = (props: RedactedProps) => {
  return (
    <Shimmer
      className="redacted curved"
      {...props}
      width={100}
      height={10}
      duration={1000}
    />
  );
};

Redacted.Badge = (props: RedactedProps) => {
  return (
    <Shimmer
      className="redacted badge"
      {...props}
      width={100}
      height={20}
      duration={1000}
    />
  );
};

Redacted.AvatarWithValue = (props: IRedactedLabelProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: `${props.gap ?? 10}px`,
      }}
    >
      <Redacted.Avatar />
      <Shimmer
        className="redacted curved"
        {...props}
        width={100}
        height={10}
        duration={1000}
      />
    </div>
  );
};

Redacted.AvatarWithLabelled = (props: IRedactedLabelProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: `${props.gap ?? 10}px`,
      }}
    >
      <Redacted.Avatar />

      <Redacted.Labelled gap={props.gap} />
    </div>
  );
};

Redacted.Labelled = (props: IRedactedLabelProps) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: `${props.gap ?? 10}px` }}
    >
      <Shimmer
        className="redacted curved"
        {...props}
        height={10}
        width={100}
        duration={1000}
      />
      <Shimmer
        className={"redacted curved"}
        {...props}
        height={props.secondary ? 15 : 10}
        width={70}
        duration={1000}
      />
    </div>
  );
};

// Export shimmer component
export default Redacted;

export const renderRedactedComponent = <T,>(isRedacting: IsUncertain<T>) => {
  const renderRedacted = (component: React.ReactNode, key: keyof typeof Redacted) => {
    const AsComponent = Redacted[key];

    return isRedacting ? <AsComponent /> : component;
  };

  const RenderCustom = (component: React.ReactNode, props: ShimmerProps) => {
    return isRedacting ? <Shimmer {...props} /> : component;
  };

  const RedactedLabel = (node: ReactNode) => renderRedacted(node, "Labelled");
  const RedactedTable = (node: ReactNode) => renderRedacted(node, "Table");
  const RedactedAvatarWithValue = (node: ReactNode) =>
    renderRedacted(node, "AvatarWithValue");
  const RedactedAvatar = (node: ReactNode) => renderRedacted(node, "Avatar");
  const RedactedBadge = (node: ReactNode) => renderRedacted(node, "Badge");

  return {
    renderRedacted,
    RedactedLabel,
    RedactedTable,
    RedactedAvatarWithValue,
    RedactedAvatar,
    RedactedBadge,
    RenderCustom,
  };
};

export type RenderRedactedReturn = ReturnType<typeof renderRedactedComponent>;
