import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";

const Tabs = ["Blocked Users", "Ragnar Strike", "Liened Users", "PND Users"];

export const PersonalFraudCenter = () => {
  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Fraud Center">
          <OutletTab>
            <OutletTab.Tabs baseURL="/personal-fraud-center" tabs={Tabs} />
            <OutletTab.Outlet />
          </OutletTab>
        </PageLayout>
      </DashboardLayout>
    </>
  );
};
