import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula, oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCheck, FiCopy } from "react-icons/fi";
import "./styles/index.css";
import { icons } from "../../../assets/icons";
import { useDarkMode } from "../../../hooks/useDarkMode";
interface JsonPreviewProps {
  json: Record<string, unknown>;
}

const CodePreview: React.FC<JsonPreviewProps> = ({ json }) => {
  const jsonString = JSON.stringify(json, null, 2);
  const [copied, setCopied] = useState(false);

  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));

  const handleCopyClick = () => {
    navigator.clipboard?.writeText(jsonString);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
    // You can add any additional logic or feedback here, like showing a tooltip or toast message.
  };

  return (
    <div className="code-editor-wrap animate-move-up-class">
      <div className="copy-button" onClick={handleCopyClick}>
        {/* <FiCopy /> */}
        {copied ? (
          <figure className="checkmark">
            <FiCheck />
          </figure>
        ) : (
          <figure>{icons.copy}</figure>
        )}
        <p>{copied ? "Copied" : "Copy"}</p>
      </div>
      <SyntaxHighlighter language="json" style={theme === "dark" ? dracula : oneLight}>
        {jsonString}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodePreview;
