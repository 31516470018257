import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { useState } from "react";
import { getPbUnapprovedDepositsStatus } from "~pb/__internals__/combined-tables/deposits/utils";

interface UnapprovedDepositsProps {
  record: ManualDepositsQueueModel;
  onCancel(): void;
  handleApprovalFunc(): Promise<any>;
  loading?: boolean;
}

export const UnapprovedDepositsDetailsModal = (props: UnapprovedDepositsProps) => {
  const { onCancel, handleApprovalFunc, loading } = props;

  const {
    amount,
    bank,
    created_at,
    destination_account_number,
    narration,
    originator_account_name,
    originator_account_number,
    session_id,
    status,
  } = props.record;

  const _status = getPbUnapprovedDepositsStatus(status);

  const view = {
    amount: formatNumberToCurrency(amount),
    narration: Util.safeText(narration),
    session_id: Util.safeText(session_id),
    "sender's_name": cn(Util.safeText(originator_account_name)),
    "sender's_account_number": Util.safeText(originator_account_number),
    "sender's_bank": Util.safeText(bank),
    "receiver's_account_number": Util.safeText(destination_account_number),
    status: formatStatus(_status),
    date: Util.safeText(formatDateTime(created_at)),
  };

  const shouldApprove = _status === "pending";

  const doNotShow = <T,>(val: T) => {
    if (_status === "pending") return val;
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={handleApprovalFunc}
      title="Deposit Details"
      hideCancel
      className="very-big-modal"
      actionText={shouldApprove ? "Approve" : "Close"}
      btnColor={doNotShow("#E8FFF6")}
      btnTextColor={doNotShow("#1ACE37")}
      loading={loading}
      hideNormalButton={_status !== "pending"}
    >
      <div className="expense__details deposit-details-modal hide-scrollbar">
        <InlineDetails
          simple
          className="no-shadow"
          amount={0}
          onClick={() => {}}
          content={Object.entries(view).map(([label, value]) => ({
            label: formatWord(label),
            value,
          }))}
          direction={""}
          title={""}
        />
      </div>
    </ActionModal>
  );
};

export type ManualDepositAction = "approve" | "view";

export interface ManualDepositActionState {
  ctx: ManualDepositAction;
  record: ManualDepositsQueueModel;
}

interface QuickActionsModalProps {
  action: ManualDepositActionState;
  setAction(action?: ManualDepositActionState): void;
  refetch: RefetchFn;
}

export const ManualDepositsQuickActionsModal = (props: QuickActionsModalProps) => {
  const { action, setAction, refetch } = props;
  const [loading, setLoading] = useState(false);

  const actionIsActive = (value: ManualDepositAction) => value === action.ctx;

  const handleCancel = () => setAction();

  const handleManualDepositApproval = async () => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.manualDepositApprovalByAdmin(
        action.record.id
      );
      APIResponseHandler.tryHandler({ response });
      await refetch();
      handleCancel();
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {actionIsActive("approve") && (
        <ActionModal
          visible
          onCancel={handleCancel}
          onClick={handleManualDepositApproval}
          cancelText="No, Cancel"
          actionText="Yes, Approve"
          btnColor="#FF0F00"
          body="Are you sure you want to approve this deposit ? This action is irreversible."
          title="Approve Deposit"
          loading={loading}
        />
      )}

      {actionIsActive("view") && (
        <UnapprovedDepositsDetailsModal
          onCancel={handleCancel}
          record={action.record}
          handleApprovalFunc={handleManualDepositApproval}
          loading={loading}
        />
      )}
    </>
  );
};
