import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";
import { XStack } from "@/components/common/stacks";
import { Button } from "@/components/common/overrides/button";
import { useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { PbManualDepositModal } from "~pb/__internals__/combined-tables/deposits/modals/manual-deposit-modals";

export const PersonalDeposits = () => {
  const [showModal, setShowModal] = useState(false);

  const { renderDynamicModal } = useDynamicModal(showModal);

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Deposits">
        <OutletTab>
          <XStack ai="start" className="deposits-header-wrap">
            <OutletTab.Tabs
              baseURL="/personal-deposits"
              tabs={["All Deposits", "Unapproved Deposits"]}
            />
            <Button
              label="Log Deposit"
              className="log-deposit-button"
              onClick={() => setShowModal(true)}
            />
          </XStack>
          <OutletTab.Outlet />
        </OutletTab>
      </PageLayout>

      {renderDynamicModal(<PbManualDepositModal onClose={() => setShowModal(false)} />)}
    </DashboardLayout>
  );
};
