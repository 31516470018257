import { PersonalDeposits } from "~pb/deposits";
import { PersonalAllDeposits } from "~pb/deposits/pages/deposits";
import { UnapprovedDeposits } from "~pb/deposits/pages/unapproved-deposits";

export const personal_deposit_routes: RouteArray[] = [
  {
    path: "/personal-deposits",
    element: PersonalDeposits,
    children: [
      { path: "", element: PersonalAllDeposits },
      { path: "all-deposits", element: PersonalAllDeposits },
      { path: "unapproved-deposits", element: UnapprovedDeposits },
    ],
  },
];
