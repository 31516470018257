import { icons } from "@/assets/icons";
import { formatStatus } from "@/utils/helper/Helper";
import React from "react";
import "./styles/index.css";
import { Util } from "@/utils/utility";
const AuthorizedDevices = ({ data }: { data: IDevices[] }) => {
  return (
    <div className="auth-device-container flex col">
      <p style={{ color: "#676767", marginTop: "1rem" }}></p>
      {data?.map((d) => {
        const device = d.components?.useragent?.os.family?.toLowerCase();
        const isMobile = device === "ios" || device === "android";
        return (
          <div className="auth-device-container__item">
            <div className="auth-device-container__item--title-wrap">
              <figure>{isMobile ? icons.mobile_device : icons.desktop_device}</figure>
              <span className="device-title">
                <p>{Util.safeValue(d.components?.useragent?.browser?.family)}</p>
                <small>{Util.safeValue(d.components?.useragent?.os?.family)}</small>
              </span>
            </div>

            <div className="status">
              {formatStatus("active")}
              <figure>{icons.more}</figure>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AuthorizedDevices;
