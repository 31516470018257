import { IdAddressVerification } from "./manual-id";
import { LimitRequests } from "./limit-requests";
import { ManualAddressVerification } from "./manual-address";
import { ComplianceNINOrBVN } from "./nin-or-bvn";

export const PersonalNINCompliancePage = () => {
  return <ComplianceNINOrBVN tab="nin" />;
};

export const PersonalBVNCompliancePage = () => {
  return <ComplianceNINOrBVN tab="bvn" />;
};

export const PersonalAddressCompliancePage = () => {
  return <ManualAddressVerification tab="Address" />;
};

export const PersonalIdCompliancePage = () => {
  return <IdAddressVerification tab="Id Verification" />;
};

export const PersonalLimitRequestCompliancePage = () => {
  return <LimitRequests />;
};
