import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useSingleUserState } from "../../context";
import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import {
  PersonalPhysicalCardsTable,
  PersonalVirtualCardsTable,
} from "@/apps/personal/__internals__/combined-tables/cards";
import { usePersonalCardFilter } from "@/apps/personal/__internals__/internal-hooks/cards";

export const PersonalPhysicalCardsPage = () => {
  const { pagination, filterArgs, filterProps } = usePersonalCardFilter();
  const { email } = useSingleUserState();
  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetPhysicalCardsQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      email,
      status: filterArgs.status,
    });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading ?? isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (!data || isLoading) {
    return <TransferEmptyScreen loading={joinedLoader} {...TRANSFER_EMPTY_SCREEN} />;
  }

  return (
    <HeightWithMarginOffset>
      <SmartFilter alternateTitle=" " {...SmartFilterProps} {...filterProps} />
      {data.physicalCards?.length > 0 ? (
        <PersonalPhysicalCardsTable
          ctx="user"
          data={data.physicalCards}
          redactedFn={redactedFn}
          refetch={refetch}
        />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} {...TRANSFER_EMPTY_SCREEN} />
      )}
    </HeightWithMarginOffset>
  );
};
