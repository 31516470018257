import { NEVER_CACHE_TIME } from "@/constants";
import { PersonalBuilder, pbGetHandler, PersonalPaginationParams } from "..";

type ValidKeys = "per_page" | "page" | "status" | "stalled";

type DateExtra = {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
};

type ExternalParams = PersonalWithSearch<
  Partial<Pick<PersonalPaginationParams & { stalled: number }, ValidKeys | "email">>
> &
  DateExtra;
type InternalParams = PersonalWithSearch<
  Partial<
    Pick<PersonalPaginationParams & { stalled: number }, ValidKeys | "username" | "email">
  >
> &
  DateExtra & {
    /** External transfers are filtered by email while internal transfers use username
     * types should be of `internal` | `external`
     */
    inUserPageType?: SN;
  };

type GetExternalRes = CreatePersonalPaginatedResponse<
  "transfers",
  PersonalExternalTransferModel[]
>;

type GetInternalRes = CreatePersonalPaginatedResponse<
  "internalTransfers",
  PersonalInternalTransfersModel[]
>;

type GetTransferWithIssues = CreatePersonalPaginatedResponse<
  "data",
  PersonalTransferWithIssues[]
>;

export const PersonalTransfersAPI = (builder: PersonalBuilder) => {
  return {
    getAllExternalTransfers: builder.query<GetExternalRes, ExternalParams>({
      queryFn: (params) => {
        const route = !params.search ? `user/transfers` : `user/transfers/search`;
        return pbGetHandler({ params, route });
      },
      keepUnusedDataFor: NEVER_CACHE_TIME,
    }),
    getAllInternalTransfers: builder.query<GetInternalRes, InternalParams>({
      queryFn: ({ username, email, inUserPageType = "external", ...rest }) => {
        const isExternal = inUserPageType === "external";

        const params = {
          ...rest,
          [isExternal ? "email" : "username"]: isExternal ? email : username,
        };

        const route = !params.search ? `internal-transfers` : `internal-transfers/search`;
        return pbGetHandler({ params, route });
      },
    }),
    getExternalTransfersNotReversed: builder.query<GetTransferWithIssues, ExternalParams>(
      {
        queryFn(params) {
          const route = params.search
            ? "search_failed_withdrawals"
            : "failed_withdrawals2";

          return pbGetHandler({
            params,
            route,
            mutateData(data) {
              return { transfers: data?.data ?? [], pagination: data.pagination };
            },
          });
        },
        keepUnusedDataFor: NEVER_CACHE_TIME,
      }
    ),
  };
};
