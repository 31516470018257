import { TypeIs } from "@/utils/helpers";
import Slot from "../slot";
import { useLocation, useNavigate } from "react-router-dom";
import { Util } from "@/utils/utility";
import { CopyText } from "../copyCheck";

type ClickableRowProps<Type> = {
  children: React.ReactNode;
  onClick(): void;
} & Type;
export const ClickableRow = <Type,>(props: ClickableRowProps<Type>) => {
  const { children, onClick, ...rest } = props;
  return TypeIs.any(
    <Slot
      {...rest}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {children}
    </Slot>
  );
};

interface ClickAbleEmailProps {
  email: IsUncertain<string>;
  url?: string;
  children?: React.ReactNode;
  hideCopy?: string;
  trim?: number;
}

/** Redirect emails click to user details page */
export const ClickAbleEmail = ({
  email,
  url,
  children,
  hideCopy,
  trim,
  ...rest
}: ClickAbleEmailProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const _email = Util.safeText(email);

  return (
    <ClickableRow
      onClick={() => {
        navigate(url ?? `/personal-users/${email}`, {
          state: { previousRoute: location.pathname },
        });
      }}
      {...rest}
    >
      {children ?? !hideCopy ? (
        <CopyText text={email ? Util.safeText(email, trim) : "--"} textToCopy={_email} />
      ) : (
        <div>{_email}</div>
      )}
    </ClickableRow>
  );
};
