import React from "react";
import Redacted from "../shimmer/redact";
import "./style/index.css";

interface AccountSummaryItemProps {
  label: string;
  value: string;
  loading: boolean;
  valueColor?: string;
}

const AccountSummaryItem: React.FC<AccountSummaryItemProps> = ({
  label,
  value,
  loading,
  valueColor,
}) => {
  return (
    <div className="user-data__account-summary--item">
      {loading ? (
        <Redacted.Labelled secondary />
      ) : (
        <>
          <p style={{ color: valueColor }}>{label}</p>
          <h6>{value}</h6>
        </>
      )}
    </div>
  );
};

export default AccountSummaryItem;
