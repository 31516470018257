import { AvatarWithTwinValue } from "@/components/common/avatar";
import { CopyText } from "@/components/common/copyCheck";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { usePagination } from "@/hooks/usePagination";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useTransfersContext } from "../../components/transactions-context";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import { useEffect, useState } from "react";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { $event } from "@/utils/events/eventEmitter";
import { BIZ_TRANSACTION_STATUS } from "@/apps/business/__internals__/constants";

export const BBAllTransferBankTransfer = () => {
  const pagination = usePagination();
  const [filterValue, setFilterValue] = useState({});
  const { currentPage, searchQuery } = pagination;
  const [exportTriggered, triggerExport] = useState(false);

  const { data, isLoading, isFetching } = businessReduxAPI.useGetAllBankTransfersQuery({
    current_page: currentPage,
    search_term: searchQuery,
    join_businesses: "yes",
    ...filterValue,
  });

  const exportApi = businessReduxAPI.useGetAllBankTransfersQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
    paginate: "no",
  }); // Set to null if you don't want to trigger the API call
  const { SmartFilterProps } = useSmartFilterProps(data, pagination);
  const { setSelectedTransaction } = useTransfersContext();

  const { renderRedacted } = renderRedactedComponent(isFetching);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "email",
    "ACCOUNT NUMBER": "account_number",
    "ACCOUNT NAME": "account_name",
    AMOUNT: "amount",
    CREATED: "created_at",
    STATUS: ["status", "transactions"],
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    loading: isLoading,
    valuePair,
    title: "Business Transfers(Bugiss)",
    dispatchFunction: exportApi.refetch,
    data: exportApi?.currentData?.data,
    filterValue,
  });
  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };

  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  if (isLoading) return <TransferEmptyScreen loading />;
  if (!data) return <TransferEmptyScreen />;

  return (
    <>
      <SmartFilter
        {...SmartFilterProps}
        onFilter={(e: any) => {
          setFilterValue(Util.filterStruct(e) as any);
          // reset current page to 1 on filter change
          pagination.setCurrentPage(1);
        }}
        onExport={() => {
          toggleModal("Export Transfers");
          triggerExport(true);
        }}
        singleSelect
        filters={[
          {
            label: "Status",
            filterBy: "status",
            options: [
              { value: BIZ_TRANSACTION_STATUS.PENDING.toString(), label: "Pending" },
              {
                value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                label: "Processing",
              },
              { value: BIZ_TRANSACTION_STATUS.FAILED.toString(), label: "Failed" },
              { value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(), label: "Success" },
              {
                value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                label: "Reversed",
              },
            ],
          },
        ]}
      />
      {data.data?.length > 0 ? (
        <RavenTable
          headerList={[
            "Business Details",
            "Reference",
            "Recipients",
            "Amount",
            "Date Initiated",
            "Status",
          ]}
        >
          {data.data.map((record: any, idx) => {
            const {
              business_name,
              account_name,
              email,
              account_number,
              trx_ref,
              status,
              currency,
              business_email,
              amount,
              bank,
              created_at,
            } = record;

            return (
              <RavenTableRow
                key={idx}
                one={renderRedacted(
                  <AvatarWithTwinValue
                    name={business_name}
                    one={trimLongString(business_name, 25)}
                    two={trimLongString(Util.safeValue(email ?? business_email), 25)}
                    copy
                  />,
                  "AvatarWithValue"
                )}
                two={
                  <div onClick={(e) => e.stopPropagation()}>
                    {renderRedacted(<CopyText text={trx_ref} />, "Table")}
                  </div>
                }
                three={renderRedacted(
                  <TwinValue
                    one={Util.safeValue(account_name)}
                    two={cn(Util.safeValue(account_number), "•", Util.safeValue(bank))}
                  />,
                  "Table"
                )}
                four={renderRedacted(formatNumberToCurrency(amount, currency), "Table")}
                five={renderRedacted(formatDateTime(created_at), "Table")}
                six={renderRedacted(
                  formatStatus(detectStatus(Number(status), "transactions")),
                  "Table"
                )}
                onRowClick={() =>
                  setSelectedTransaction({
                    ...record,
                    reference: trx_ref,
                    type: "bank",
                    recipient: account_name,
                  })
                }
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen inner loading={isFetching} />
      )}
    </>
  );
};
