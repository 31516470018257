import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosAuditTrailApi = (builder: PosBuilder) => {
  return {
    getAuditTrail: createQueryWithRoute<
      PosAuditTrailModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "audit_trail", { name: "getAuditTrail" }),

    getAuditTrailExport: createQueryWithRoute<
      PosAuditTrailModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "audit_trail", { export: true, name: "getAuditTrailExport" }),
  };
};
