import "../styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { TypeIs } from "@/utils/helpers";
import { GLOBAL_PER_PAGE } from "@/constants";
import { PersonalDepositsTable } from "~pb/__internals__/combined-tables/deposits/deposits-table";
import { usePbDepositsFilter } from "../../__internals__/internal-hooks/deposits";

export const PersonalAllDeposits = () => {
  const { filterArgs, filterProps, pagination, ExportModal } = usePbDepositsFilter();

  const { data, isLoading, isFetching } = personalReduxAPI.useGetUserDepositsQuery({
    page: pagination.currentPage,
    per_page: GLOBAL_PER_PAGE,
    search: pagination.searchQuery,
    start_date: filterArgs.startDate,
    end_date: filterArgs.endDate,
    status: TypeIs.any(filterArgs?.status),
  });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data) {
    return <TransferEmptyScreen className="mt-20" loading={joinedLoader} />;
  }

  return (
    <>
      <div className="mt-20">
        <SmartFilter
          {...SmartFilterProps}
          {...filterProps}
          searchTitle="Search  deposits..."
        />
      </div>
      {data.deposits.length > 0 ? (
        <PersonalDepositsTable ctx="page" data={data.deposits} redactedFn={redactedFn} />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} />
      )}
      <ExportModal />
    </>
  );
};
