import { capitalizeEachWord, formatWord } from "@/utils/formatWord";
import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";
import { iife } from "@/utils/general";
import { cn } from "@/utils/colorConvert";

export const usePbSavings = (type: string, email?: string) => {
  const title = iife(() => {
    const word = formatWord(type).toLowerCase();
    if (type.includes("new")) {
      return capitalizeEachWord(cn("new", word?.replace("new", "")));
    }

    return capitalizeEachWord(word);
  });

  const value = usePersonalExportModal({
    fileName: "Savings",
    filterParams: [
      {
        key: "type",
        type: "text",
        label: "Type",
        placeholder: "Enter type",
        defaultValue: type,
        hidden: true,
      },
      {
        key: "email",
        type: "email",
        label: "Email (optional)",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: Boolean(email),
      },
    ],
    route: "/savings/export",
    title: `Export ${title} Savings`,
  });

  return value;
};

export const usePbPocketExport = (email?: string) => {
  const value = usePersonalExportModal({
    fileName: "Pocket Savings",
    filterParams: [
      {
        key: "email",
        type: "email",
        label: "Email (optional)",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: Boolean(email),
      },
    ],
    route: "/user-pockets/export",
    overrideRoute: "/support/user-pockets/export",
    title: `Export Pocket Savings Record`,
  });

  return value;
};
export const usePbSpendSave = (email?: string) => {
  const value = usePersonalExportModal({
    fileName: "SpendSave",
    filterParams: [
      {
        key: "email",
        type: "email",
        label: "Email (optional)",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: Boolean(email),
      },
    ],
    route: "/user-pockets/export",
    overrideRoute: "/support/user/spend-saves/export",
    title: `Export SpendSave Record`,
  });

  return value;
};

export const usePersonalSavingsFilterAndExport = (type: string, email?: string) => {
  const filter = usePbSavings(type, email);

  return filter;
};
