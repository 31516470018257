import loaderImg from "../../../assets/images/raven-loader.gif";
import "./style/index.css";
const Loader = (props: any) => {
  return (
    <div className="loader-wrapper">
      <figure>
        <img src={loaderImg} alt="a raven loader animation" />
      </figure>
      <div>
        {props.loadingText ? (
          <div className="loader-wrapper-text">{props?.loadingText}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Loader;
