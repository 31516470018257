import { icons } from "../../../../../assets/icons";

export const businessSideMenuList: SidebarMenuDataList = [
  {
    name: "Main Menu",
    numb: "one",
    icon: "",
    link: "",
    children: [
      {
        name: "Overview",
        link: "/business-overview",
        icon: icons.sidebar_bb_overview,
      },

      // {
      //   name: "Insights",
      //   link: "#", // replace this
      //   icon: icons.sidebar_bb_insights,
      // },
      {
        name: "Businesses",
        link: "/business-businesses",
        icon: icons.sidebar_bb_businesses,
      },

      {
        name: "Transactions",
        link: "/business-transactions",
        icon: icons.sidebar_bb_transactions,
      },
      {
        name: "Global Account",
        link: "/business-global-account/accounts",
        icon: icons.sidebar_bb_global_account,
      },
      {
        name: "Expenses",
        link: "/business-expenses",
        icon: icons.sidebar_bb_expenses,
      },
    ],
  },

  {
    name: "Features",
    numb: "two",
    icon: "",
    link: "",
    children: [
      {
        name: "Transfers",
        link: "/business-transfer",
        icon: icons.sidebar_bb_transfers,
      },

      {
        name: "Cards",
        link: "/business-cards",
        icon: icons.sidebar_bb_cards,
      },

      {
        name: "Invoices",
        link: "/business-invoices",
        icon: icons.sidebar_bb_invoices,
      },
      {
        name: "Payment Links",
        link: "/business-links",
        icon: icons.sidebar_bb_links,
      },
      {
        name: "Payroll",
        link: "/business-payroll",
        icon: icons.sidebar_bb_payroll,
      },

      // {
      //   name: "Bankbox",
      //   link: "/business-bankbox",
      //   icon: icons.sidebar_bankbox,
      // },

      // {
      //   name: "Stores",
      //   link: "/business-stores",
      //   icon: icons.sidebar_bb_stores,
      // },

      {
        name: "Bills",
        link: "/business-bills",
        icon: icons.sidebar_bb_bills,
      },

      {
        name: "Pending Deposits",
        link: "/business-pending-deposits",
        icon: icons.sidebar_bb_deposits,
      },
    ],
  },
  {
    name: "HNI Tool",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Businesses",
        link: "/business-hni",
        icon: icons.sidebar_bb_notification,
      },
      {
        name: "Managers",
        link: "/business-managers",
        icon: icons.sidebar_bb_notification,
      },
    ],
  },
  {
    name: "Support",
    numb: "four",
    icon: "",
    link: "",
    children: [
      {
        name: "Compliance",
        link: "/business-compliance/bvn",
        icon: icons.sidebar_bb_compliance,
      },
      {
        name: "Fraud Center",
        link: "/business-fraud-center",
        icon: icons.sidebar_bb_fraud,
      },

      // {
      //   name: "Resolution Center",
      //   link: "/business-resolution-center",
      //   icon: icons.sidebar_bb_resolution,
      // },
      {
        name: "Audit Trail",
        link: "/business-audit-trail",
        icon: icons.sidebar_bb_audit_trail,
      },
    ],
  },

  // {
  //   name: "Manage",
  //   numb: "four",
  //   icon: "",
  //   link: "",
  //   children: [
  //     {
  //       name: "Notification",
  //       link: "/business-notification",
  //       icon: icons.sidebar_bb_notification,
  //     },
  //   ],
  // },
];
