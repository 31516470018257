import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { toggleRedact } from "@/redux/actions/actions";
import { ReduxBuilderHandler } from "@/utils/api";
import { Util } from "@/utils/utility";

export const getTransactions = createAsyncThunk<any, any>(
  "/transactions",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get("transaction/get_all_transactions_with_business", {
        params: {
          range: payload?.range ?? "3",
          business_email: payload?.business_email ?? "",
          period_type: payload.period_type ?? "",
          search_term: payload.search_term ?? "",
          per_page: payload.per_page ?? 20,
          current_page: payload?.current_page ?? "",
          date_1: payload.date_1 ?? "",
          date_2: payload.date_2 ?? "",
          transaction_type: payload.transaction_type ?? "",
          paginate: payload.paginate,
          status: payload?.status,
          direction: payload?.direction,
        },
      });

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          toast.error(data?.data.message, {
            position: "top-right",
          });
        }

        thunkAPI.dispatch(toggleRedact(false));
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        if (Util.isNotExportReq(payload.paginate)) {
          thunkAPI.dispatch(toggleRedact(false));
          thunkAPI.dispatch(SET_BUSINESS_TRANSACTIONS(data?.data?.data?.transactiions));
        }

        return data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const downloadReciept = createAsyncThunk<
  any,
  { trx_ref: string; business_id: string }
>("/download-reciepts", async (payload, thunkAPI) => {
  try {
    const data = await axios.post(`transfer/generate_transaction_receipts`, payload);

    if (data?.data?.status === "fail") {
      if (typeof data?.data?.data === "string") {
        toast.error(data?.data.data, {
          position: "top-right",
        });
      } else {
        toast.error(data?.data.message, {
          position: "top-right",
        });
      }

      thunkAPI.dispatch(toggleRedact(false));
      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      toast.success(data?.data?.message, {
        position: "top-right",
      });

      thunkAPI.dispatch(toggleRedact(false));
      return data?.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const generateStatement = createAsyncThunk<
  any,
  {
    business_id: string;
    currency: string;
    file_type: string;
    date: string;
    send_to: string;
    account_id: string;
    currency_type?: string;
  }
>("/download-reciepts", async (payload, thunkAPI) => {
  try {
    const data = await axios.get(`transaction/download_bank_statement_for_business`, {
      params: payload,
    });

    if (data?.data?.status === "fail") {
      if (typeof data?.data?.data === "string") {
        toast.error(data?.data.data, {
          position: "top-right",
        });
      } else {
        toast.error(data?.data.message, {
          position: "top-right",
        });
      }

      thunkAPI.dispatch(toggleRedact(false));
      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      toast.success(data?.data?.message, {
        position: "top-right",
      });

      thunkAPI.dispatch(toggleRedact(false));
      return data?.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

const initialState: any = {
  loading: false,
  transactions: [],
  isAuth: false,
};

export const businessTransactionSlice = createSlice({
  name: "business-transactions",
  initialState,
  reducers: {
    SET_BUSINESS_TRANSACTIONS: (state, action) => {
      state.transactions = action.payload;
      state.isAuth = true;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(getTransactions.pending, (state) => {
      state.loading = true;
    });

    //fullfilled state changes
    builder.addCase(getTransactions.fulfilled, (state) => {
      state.loading = false;
    });

    //rejected state changes
    builder.addCase(getTransactions.rejected, (state) => {
      state.loading = false;
    });

    // Modern approach using reduxBuilder

    const builderHandler = new ReduxBuilderHandler(builder, initialState);
    builderHandler.handleStates(downloadReciept);
  },
});

// Action creators are generated for each case reducer function
export const { SET_BUSINESS_TRANSACTIONS } = businessTransactionSlice.actions;

export default businessTransactionSlice.reducer;
