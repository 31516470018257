import ActionModal from "@/components/common/modal/ActionModal";
import { Validator } from "@/utils/validator";
import { APIResponseHandler } from "@/utils/api";
import { PersonalAPIService } from "@/redux/slices/personal";
import { useState } from "react";
import { z } from "zod";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { reactSelectStyle } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { Input } from "@/components/common/input";

interface BaseModalProps {
  id: SN;
  refetch(): void;
  onCancel(): void;
}

const BasicSchema = Validator.object({
  label: Validator.string("Reason"),
  value: Validator.string("Action"),
});

type Payload = z.infer<typeof BasicSchema>;

export const RejectManualAddressVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery();

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectAddress({
        address_id: id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Address Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why address verification is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection*"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};
export const RejectIdVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery("manual_id");

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectManualId({
        id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        await refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Id Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why this ID verification is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection*"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};

export const RejectLimitRequest = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery("limit_request");

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectLimitRequest({
        request_id: id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        await refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Limit Request"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why this limit request is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection *"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};

const Schema = Validator.object({
  visitation_link: Validator.string("Visitation Link"),
});
type AcceptPayload = z.infer<typeof Schema>;

export const AcceptManualAddressVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [visitation_link, setVisitationLink] = useState("");
  const [errors, setErrors] = useState(Validator.createError(Schema));

  const handleChange = (event: InputOnChange) => {
    const { value } = event.target;
    setVisitationLink(value);
  };

  const acceptVerification = async ({ visitation_link }: AcceptPayload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.approveAddress({
        id,
        visitation_link,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    try {
      const res = Schema.parse({ visitation_link });
      setErrors({});
      acceptVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleAccept}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Accept Address Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Enter visitation link to confirm the address is a live location
      </p>

      <Input
        className="bugiss-block-modal"
        label="Visitation Link*"
        value={visitation_link}
        onChange={handleChange}
        showError={Boolean(errors?.visitation_link)}
        errorText={errors?.visitation_link}
        placeholder="Enter Visitation Link*"
        name="reason"
      />
    </ActionModal>
  );
};
