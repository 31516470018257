import "./styles/index.css";

import { XStack, YStack } from "@/components/common/stacks";
import { useOutletURL } from "@/hooks/useOutletURL";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { TabsElement } from "@/components/common/Tabs";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES } from "@/constants";
import Gap from "@/components/common/styleComponents/Gap";

const BusinessFraudCenter = () => {
  const Tabs: TabsElement[] = [
    {
      label: "Blocked Business",
      value: "blocked-business",
    },
    { label: "Ragnar Strike", value: "ragnar-strike" },

    {
      label: "Liened Accounts",
      value: "liened-accounts",
    },
    {
      label: "Unlien History",
      value: "unlien-history",
    },
  ];

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Fraud Center">
        <OutletTab>
          <OutletTab.Tabs
            tabs={Tabs}
            className={GENERAL_CLASSES.OUTLET}
            baseURL="/business-fraud-center"
          >
            <Gap size={10} />
            <OutletTab.Outlet />
          </OutletTab.Tabs>
        </OutletTab>{" "}
      </PageLayout>
    </DashboardLayout>
  );
};

export default BusinessFraudCenter;
