import { PersonalBuilder, pbGetHandler, PersonalBaseParams } from "..";

type GetAllUserParam = PersonalBaseParams & { page: SN; per_page: SN; search?: string };

export type TransferParams = Partial<Pagination>;

export const PersonalUsersAPI = (builder: PersonalBuilder) => {
  return {
    getAllUsers: builder.query<PersonalGetAllUsersModel, GetAllUserParam>({
      queryFn: (params) => {
        if (!params.search) return pbGetHandler({ params, route: "users" });

        return pbGetHandler({ params, route: "user/search" });
      },
    }),
    getUser: builder.query<PersonalUserModel, string>({
      queryFn: (id) => pbGetHandler({ route: `user/${id}`, params: {} }),
    }),
    getUserEmail: builder.query<PersonalUserModel, string>({
      queryFn: (email) =>
        pbGetHandler({ route: "user/get-user-by-email", params: { email } }),
    }),
    getUserReferral: builder.query<PersonalUserModel, string>({
      queryFn: (email) => pbGetHandler({ route: "user/downlines", params: { email } }),
    }),
    getUserRavenBeneficiaries: builder.query<RavenBeneficiaries[], string>({
      queryFn: (email) => pbGetHandler({ route: "user/favorites", params: { email } }),
    }),
    getUserAccounts: builder.query<PersonalUserModel, string>({
      queryFn: (email) => pbGetHandler({ route: "user-accounts", params: { email } }),
    }),
  };
};
