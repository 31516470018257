import { DetailsWrapper } from "../components/details-wrapper";
import { CableInfoChecker } from "../components/cable-info-form";
import { MeterNumberCheckerForm } from "../components/meter-info";
import { formatWord } from "@/utils/formatWord";
import { useGetDetailsChecker } from "../components/details-wrapper/context";
import { Util } from "@/utils/utility";
import InlineDetails from "@/components/common/inlineDetails";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { formatDateTime } from "@/utils/helper/Helper";

interface MeterData {
  meter_no: string;
  cust_name: string;
  address: string;
  district: any;
  meter_type: string;
}

export const MeterNumberDetailsChecker = () => {
  const { data } = useGetDetailsChecker<MeterData>();

  const { cust_name, ...rest } = data ?? {};

  const content = Object.entries(rest).map(([key, value]) => ({
    label: formatWord(key),
    value: Util.safeText(value),
  }));

  return (
    <DetailsWrapper title="Meter Info" formElement={<MeterNumberCheckerForm />}>
      <div className="gtd-details-section">
        <InlineDetails
          simple
          className={cn(GENERAL_CLASSES.NO_SHADOW)}
          content={[{ label: "Full Name", value: Util.safeText(cust_name) }, ...content]}
        />
      </div>
    </DetailsWrapper>
  );
};

interface CardDetails {
  smart_card_number: string;
  customer_name: string;
  account_status: string;
  due_date: string;
  type: string;
}

export const CableInfoDetailsChecker = () => {
  const { data } = useGetDetailsChecker<CardDetails>();

  const { customer_name, due_date, ...rest } = data ?? {};

  const content = Object.entries(rest).map(([key, value]) => ({
    label: formatWord(key),
    value: Util.safeText(value),
  }));

  return (
    <DetailsWrapper title="Cable Info" formElement={<CableInfoChecker />}>
      <div className="gtd-details-section">
        <InlineDetails
          simple
          className={cn(GENERAL_CLASSES.NO_SHADOW)}
          content={[
            { label: "Full Name", value: Util.safeText(customer_name) },
            ...content,
            { label: "Due Date", value: due_date ? formatDateTime(due_date) : "--" },
          ]}
        />
      </div>
    </DetailsWrapper>
  );
};
