import { createContext, useContext, useState } from "react";

interface State<T> {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: T | undefined;
  setData: React.Dispatch<React.SetStateAction<T>>;
}

const Context = createContext<State<any>>({
  loading: false,
  setLoading: () => {
    throw new Error(
      "DetailsChecker context was used before its provider was initialized"
    );
  },
  data: undefined,
  setData: () => {
    throw new Error(
      "DetailsChecker context was used before its provider was initialized"
    );
  },
});

/** Using this to control the loading state for the EmptyScreen */
export const BizDetailsCheckerProvider = ({ children }: IChildren) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  return (
    <Context.Provider value={{ loading, setLoading, data, setData }}>
      {children}
    </Context.Provider>
  );
};

export const useGetDetailsChecker = <T,>() => {
  const context = useContext(Context);

  return context as State<T>;
};
