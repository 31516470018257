import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import { RavenButton, RavenTable } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import "../../styles/index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchBizTransactions } from "@/redux/switches/business/transaction.sw";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { $event } from "@/utils/events/eventEmitter";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { detectSingleBizValuePair } from "@/apps/business/businesses/__exports__/value_pairs";
import BusinessUsersTables from "../../tables/BusinessTables";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { detectStatus, renderConditionally } from "@/utils/helpers";
import { Row } from "@/components/common/stacks";
import { BugissApi } from "@/redux/slices";

const SingleBusinessCards = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { email } = useParams();
  let [searchParams] = useSearchParams();
  const bulk_ref = searchParams.get("bulk_ref") ?? undefined;
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});

  const header = [
    "TRANSACTION REF.",
    "ACCOUNT NAME",
    "BANK",
    "ACCOUNT NO.",
    "AMOUNT",
    "DATE INITIATED",
    "STATUS",
  ];
  let timeoutId: ReturnType<typeof setTimeout>;

  // Debounce function to delay execution
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // declare const
  const { transactions, loading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const trx: any = transactions?.data ?? [];
  const [biz, setBiz] = useState<Partial<XBusinessesModel["business"]>>({});

  const Tables = new BusinessUsersTables({ type: "cards", trx: trx });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exportTriggered, triggerExport] = useState(false);
  useEffect(() => {
    dispatch(toggleRedact(true));
    const debouncedDispatch = debounce(() => {
      dispatch(
        fetchBizTransactions({
          identifier: "cards",
          payload: {
            business_email: email as string,
            // business_id: businessId,
            current_page: currentPage,
            bulk_transfer_reference: bulk_ref,
            search_term: searchQuery,
            join_business: "yes",
            transaction_type: "",
            ...filterValue,
          },
        })
      );
    }, 10); // Adjust the delay as needed

    // Execute the debounced dispatch function
    debouncedDispatch();

    // Cleanup function to cancel pending dispatches
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentPage, searchQuery, filterValue]);

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessSingleTrxModel>;
  }>({
    on: false,
    data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  const { isRedacting } = useSelector((state: RootState) => state.general);
  async function fetchData() {
    if (email) {
      const [businessesResponse] = await Promise.all([
        dispatch(
          BugissApi.business.fetchBusinessDetails({
            per_page: 20,
            business_email: email as string,
            // current_page: currentPage,
            // search_term: searchQuery,
          })
        ),
      ]);

      const resp = await businessesResponse;

      if (resp.payload?.status === "success") {
        setBiz(resp.payload.data.business);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [email]);

  function dispatchFunction() {
    dispatch(
      fetchBizTransactions({
        identifier: "cards",
        payload: {
          business_email: email as string,
          //   business_id: businessId,
          current_page: currentPage,
          bulk_transfer_reference: bulk_ref,
          search_term: searchQuery,
          join_business: "yes",
          transaction_type: "cards",
          ...filterValue,
          paginate: "no",
        },
      })
    );
  }

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: trx,
    loading,
    ...detectSingleBizValuePair("cards"),
    title: `Transactions Export`,
    dispatchFunction,
    filterValue,
  });

  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  useEffect(() => {
    const handleExporting = (e: any) => {
      if (e.detail === "done") {
        setTimeout(() => {
          dispatch(
            fetchBizTransactions({
              identifier: "cards",
              payload: {
                business_email: email as string,
                // business_id: businessId,
                current_page: currentPage,
                bulk_transfer_reference: bulk_ref,
                search_term: searchQuery,
                join_business: "yes",
                transaction_type: "cards",
                ...filterValue,
              },
            })
          );
        }, 100);
      }
    };
    $event.addEventListener("exporting", handleExporting);

    // Cleanup function to remove the event listener
    return () => {
      $event.removeEventListener("exporting", handleExporting);
    };
  }, []);

  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedIds, setSelectedIds] = useState<SN[]>([]);
  const [isMutating, setIsMutating] = useState<true>();

  const handleCheckBoxChange = (id: SN, state: boolean) => {
    if (state) {
      setIsAllChecked(false);

      setSelectedIds((old) => [...old, id]);
    } else {
      setIsAllChecked(false);

      const unselectedIds = selectedIds.filter((ref) => ref !== id);
      if (unselectedIds?.length < selectedIds?.length) setSelectedIds(unselectedIds);
    }
  };

  const isCheckActive = selectedIds?.length > 0;

  const handleStatusChange = async (type: string) => {
    setIsMutating(true);
    // const res = await BusinessAPIService.recheckTransferStatusInBulk(selectedIds);
    // if (res) refetch();
    setIsMutating(undefined);
    // setSelectedIds([]);
    // setIsAllChecked(false);
  };

  const [selectedCard, setSelectedCard] = useState<Partial<BusinessCardModel>>({});
  const [modal, setModal] = useState<{
    cardView?: boolean;
    cardFreeze?: boolean;
    cardDestroy?: boolean;
  }>({
    cardFreeze: false,
    cardDestroy: false,
  });

  function handleCardDestroy() {
    dispatch(
      BugissApi.business.freezeBusinessCard({
        business_id: biz.id,
        card_id: String(selectedCard?.id),
      })
    );
  }
  function handleCardFreeze() {
    dispatch(
      BugissApi.business.unfreezeBusinessCard({
        business_id: biz.id,
        card_id: String(selectedCard?.id),
      })
    );
  }
  const { loading: cardLoading } = useSelector((state: RootState) => state.businessCards);

  return (
    <div className="business-trx-wrap">
      {/* <h6>Transactions</h6> */}

      <>
        {/* <div className="type-title">
          <h6>{capitalizeFirstLetter(type.label)}</h6>
        </div> */}
        <SmartFilter
          page="insights"
          onExport={() => {
            toggleModal(`Export Cards`);
            triggerExport(true);
          }}
          searchTitle="Search cards..."
          onSearchChange={(e: string) => {
            setSearchQuery(e);
          }}
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
          }}
          dynamicNode={
            isCheckActive ? (
              <Row gap={1.6}>
                <FreezeCardBtn onClick={() => handleStatusChange("freeze")} />
                <DestroyCardBtn onClick={() => handleStatusChange("destroy")} />
              </Row>
            ) : null
          }
          pagination={{
            currentPage: currentPage,
            itemsPerPage: transactions.per_page as number,
            totalItems: transactions.total,
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
          }}
        />

        {(!isRedacting && loading) || trx?.length === 0 ? (
          <EmptyScreen loading={loading} />
        ) : (
          <RavenTable action={false} className="table__main" headerList={header}>
            {/* Bills Table */}
            {trx.map((chi: BusinessCardModel, idx: SN) => {
              return (
                <React.Fragment key={idx}>
                  <Tables.Cards
                    content={chi}
                    allChecked={isAllChecked}
                    loading={loading}
                    onCheck={(e: { state: boolean; id: string }) => {
                      handleCheckBoxChange(e.id, e.state);
                    }}
                    checkValue={selectedIds as any}
                    onItemClick={(e: any) => {
                      if (e.action === "freeze") {
                        setModal({
                          cardFreeze: true,
                        });
                        setSelectedCard(e.data);
                      }

                      if (e.action === "destroy") {
                        setModal({
                          cardDestroy: true,
                        });
                        setSelectedCard(e.data);
                      }

                      if (e.action === "view") {
                        navigate(
                          `/business-cards/${e.data.id}?biz=${e.data.business_id}`
                        );
                      }
                    }}
                  />
                </React.Fragment>
              );
            })}
          </RavenTable>
        )}
      </>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {}}
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={"debit"}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "Amount",
                value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
                // copy: true,
              },
              // {
              //   label: "Business Email",
              //   value: view.data?.email,
              // },

              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },

              // {
              //   label: "Narration",
              //   value: trimLongString(
              //     formatMetaData(view.data.meta_data ?? "")?.narration,
              //     30
              //   ),
              // },
              ...renderConditionally(
                "Narration",
                trimLongString(formatMetaData(view.data.meta_data ?? "")?.narration, 30)
              ),
              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },

              ...renderConditionally(
                "Recipient",
                formatMetaData(view.data.meta_data ?? "")?.account_name
              ),
              // {
              //   label: "Recipient",
              //   value: formatMetaData(view.data.meta_data ?? "")?.account_name,
              // },

              {
                label: "Transaction Ref",
                value: view.data?.trx_ref ?? "--",
                copy: true,
                trim: true,
              },

              {
                label: "Status",
                value: formatStatus(detectStatus(Number(view.data?.status))),
              },

              {
                label: "Date Authorized",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>

      <ActionModal
        visible={modal?.cardFreeze as boolean}
        onCancel={() => {
          setModal({ cardFreeze: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardFreeze();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, Freeze Card`}
        btnColor={"#EA872D"}
        body="Are you sure to freeze this card? you can always come back to this action later."
        title={"Freeze Card?"}
      ></ActionModal>

      <ActionModal
        visible={modal?.cardDestroy as boolean}
        onCancel={() => {
          setModal({ cardDestroy: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardDestroy();
        }}
        cancelText="No, Cancel"
        actionText={`Destroy Card`}
        btnColor={"red"}
        body="Are you sure to destroy this card? This action cannot be undone?"
        title={"Destroy Card?"}
      ></ActionModal>
    </div>
  );
};

export default SingleBusinessCards;

const DestroyCardBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="recheck-btn" color="orange-dark" onClick={onClick}>
      Destroy
    </RavenButton>
  );
};

const FreezeCardBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="destroy-btn" color="orange-dark" onClick={onClick}>
      Freeze
    </RavenButton>
  );
};
