type ValuePair = {
  [key: string]: string | string[];
};

type ValuePairProp = {
  valuePair: ValuePair;
};
export function detectSingleBizValuePair(page: string): ValuePairProp {
  let valuePair: ValuePair = {};

  switch (page) {
    case "transactions":
      valuePair = {
        DIRECTION: "direction",
        "TRANSACTION REFERENCE": "trx_ref",
        "BULK TRF REF": "bulk_transfer_reference",
        "SESSION ID": "meta_data.session_id",
        TYPE: "type",
        CREATED: "created_at",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        AMOUNT: "amount",
        STATUS: ["status", "transactions"],
      };
      break;
    case "airtime":
      valuePair = {
        "TRANSACTION REF": "trx_ref",
        NETWORK: ["network", "provider"],
        AMOUNT: "amount",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        CREATED: "created_at",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;
    case "data":
      valuePair = {
        "TRANSACTION REF": "trx_ref",
        NETWORK: ["network", "provider"],
        AMOUNT: "amount",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        CREATED: "created_at",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;

    case "cable":
      valuePair = {
        "TRANSACTION REF": "trx_ref",
        NETWORK: ["network", "provider"],
        AMOUNT: "amount",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        CREATED: "created_at",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;
    case "electricity":
      valuePair = {
        "TRANSACTION REF": "trx_ref",
        NETWORK: ["network", "provider"],
        AMOUNT: "amount",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        CREATED: "created_at",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;
    case "betting":
      valuePair = {
        "TRANSACTION REF": "trx_ref",
        NETWORK: ["network", "provider"],
        AMOUNT: "amount",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        CREATED: "created_at",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;
    case "cards":
      valuePair = {
        "CARD NAME": "card_name",
        "CARD NUMBER": "card_number",
        CURRENCY: "currency",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        BALANCE: "balance",
        "EXP MONTH": "expiry_month",
        "EXP YEAR": "expiry_year",
        STATUS: ["status", "transactions"],
        PHONE: "phone",
      };
      break;
    case "transfer":
      valuePair = {
        RECIPIENT: "account_name",
        BANK: "bank",
        "ACCOUNT NUMBER": "account_number",
        "TRANSACTION REFERENCE": "trx_ref",
        "BULK TRF REF": "bulk_transfer_reference",
        "SESSION ID": "meta_data.session_id",
        TYPE: "type",
        CREATED: "created_at",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        AMOUNT: "amount",
        STATUS: ["status", "transactions"],
      };
      break;
    case "internal-transfer":
      valuePair = {
        RECIPIENT: "account_name",
        USERNAME: "identifier",
        BANK: "bank",
        "TRANSACTION REFERENCE": "trx_ref",
        "BULK TRF REF": "bulk_transfer_reference",
        "SESSION ID": "meta_data.session_id",
        TYPE: "type",
        CREATED: "created_at",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        AMOUNT: "amount",
        STATUS: ["status", "transactions"],
      };
      break;
    case "bulk-transfer":
      valuePair = {
        REFERENCES: "bulk_transfer_reference",
        "TOTAL AMOUNT": "amount",
        "META DATA": "meta_data.summary.total_beneficiaries",
        // SUCCESSFUL: "meta_data.summary.total_no_sent",
        // FAILED: "meta_data.summary.total_no_failed",
        "TOTAL FEES": "fee",
        DATE: "created_at",
      };
      break;
    case "bulk-transfer-details":
      valuePair = {
        RECIPIENT: "account_name",
        BANK: "bank",
        "ACCOUNT NUMBER": "account_number",
        "TRANSACTION REFERENCE": "trx_ref",
        "SESSION ID": "meta_data.session_id",
        TYPE: "type",
        CREATED: "created_at",
        "BAL BEFORE": "bal_before",
        "BAL AFTER": "bal_after",
        AMOUNT: "amount",
        STATUS: ["status", "transactions"],
      };
      break;
    default:
      break;
  }
  return { valuePair };
}
