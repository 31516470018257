/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactElement, useEffect, useState } from "react";

import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { AvatarWithTwinValue } from "../../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, formatDateTime } from "@/utils/helper/Helper";
import ActionModal from "../../../../components/common/modal/ActionModal";

import "../styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";

import { cn } from "@/utils/colorConvert";
import { BIZ_VERIFICATION_STATUS } from "../../__internals__/constants";

const Tabs = [
  {
    label: "BVN Verification",
    value: "bvn",
  },
  { label: "NIN Verification", value: "nin" },

  {
    label: "Address Verification",
    value: "address",
  },
  {
    label: "CAC Verification",
    value: "cac",
  },
  {
    label: "Business Details",
    value: "business_info",
  },
  {
    label: "Details Checker",
    value: "details_checker",
  },
];

const BBusinessInfo = ({ tab }: { tab?: string }) => {
  const [filterValue, setFilterValue] = useState<any>({});

  const [view, setView] = useState<{ on: boolean; chi: Partial<VerificationDetails> }>({
    chi: {},
    on: false,
  });

  const [selectedTab, setSelectedTab] = React.useState<{
    label: string;
    value: string;
  }>({ label: "BVN Verification", value: "bvn" });

  const tableHeader = [
    "Business Details",
    "Industry",
    "Staff",
    "Transaction Range",
    "Date",
    // "Status",
    // "",
  ];

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: selectedTab.value.trim(),
        // business_email: "",
        current_page: currentPage,
        search_term: searchQuery,
        status: BIZ_VERIFICATION_STATUS.PENDING,
        ...filterValue,
      })
    );
  }, [selectedTab, currentPage, searchQuery, filterValue]);

  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  type Business = BusinessComplianceModel["business"];
  type Verifications = BusinessComplianceModel["business_verification_details"];
  const businesses: Business = business_compliances?.data?.business;

  const compliance: Verifications[] =
    business_compliances?.data?.business_verification_details;

  const assertVeri = (email: string): any => {
    var data: any = compliance[email as any];

    // const filter = data.filter((d) => d.type === selectedTab.value);
    const l = data?.filter(
      (d: VerificationDetails) => d?.id_verification_type === selectedTab?.value
    )[0];

    if (l) {
      return l;
    } else {
      return [];
    }
  };
  const { isRedacting } = useSelector((store: RootState) => store.general);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(Tabs.find((d) => d.value === tab) as any);
  }, [tab]);
  // console.log(filterValue, "fi");
  return (
    <>
      <div>
        <div style={{ marginTop: "2rem" }}></div>
        <SmartFilter
          page="compliance"
          // simple
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
            // reset current page to 1 on filter
            setCurrentPage(1);
          }}
          defaultFilterBy="status"
          filters={[
            {
              label: "Status",
              filterBy: "status",
              options: [
                {
                  label: "Pending",
                  value: "0",
                },
                {
                  label: "Completed",
                  value: "1",
                },
              ],
            },
          ]}
          onSearchChange={(e: string) => setSearchQuery(e)}
          searchTitle={`Search ${selectedTab.label}...`}
          pagination={{
            currentPage: currentPage,
            itemsPerPage: Number(business_compliances.per_page),
            totalItems: Number(business_compliances.total),
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
            activeFilter: `All ${selectedTab}`,
          }}
        />

        {!businesses || businesses?.length === 0 || (loading && !isRedacting) ? (
          <EmptyScreen loading={loading} />
        ) : (
          <div>
            <div className="table">
              <RavenTable action={false} className="table__main" headerList={tableHeader}>
                {businesses?.map((data, index) => {
                  const veriData: VerificationDetails = assertVeri(data.email);

                  return (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => {
                        // if (!veriData.flow_id) {
                        //   toast.error("No verification details found for user");
                        // } else
                        setView({
                          on: true,
                          chi: {
                            ...veriData,
                            ...data,
                            business_owner_id: data.id,
                            email: data.email,
                          },
                        });
                      }}
                      {...{
                        one: loading ? (
                          <Redacted.AvatarWithValue />
                        ) : (
                          <div className="table-icon-row">
                            <AvatarWithTwinValue
                              name={Util.join(
                                Util.safeValue(
                                  data.business_details_from_central.first_name
                                )
                              ).with(
                                Util.safeValue(
                                  data.business_details_from_central.last_name
                                )
                              )}
                              one={Util.join(
                                Util.safeValue(
                                  data.business_details_from_central.first_name
                                )
                              ).with(
                                Util.safeValue(
                                  data.business_details_from_central.last_name
                                )
                              )}
                              two={Util.safeValue(
                                data.business_details_from_central.business_email
                              )}
                              // full
                            />
                          </div>
                        ),

                        two: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(data.business_details_from_central.industry)
                        ),
                        three: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(data.business_details_from_central.employee_size)
                        ),
                        four: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(
                            data.business_details_from_central.transaction_range
                          )
                        ),
                        five: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(formatDateTime(data.created_at))
                        ),
                        // six: loading ? (
                        //   <Redacted.Table />
                        // ) : (
                        //   formatStatus(
                        //     detectStatus(data.business_info, "compliance")
                        //   )
                        // ),
                        // seven: loading ? (
                        //   <Redacted.Badge />
                        // ) : (
                        //   <div>
                        //     <Dropdown
                        //       items={returnDropdown(data.business_info)}
                        //       defaultValue={dropdown[0]}
                        //       onChange={(e: any) => {
                        //         e.event.stopPropagation();
                        //         setView({
                        //           on: false,
                        //           chi: {
                        //             ...veriData,
                        //             ...data,
                        //             business_owner_id: data.id,
                        //           },
                        //         });
                        //         e.func({ chi: "", on: true });
                        //       }}
                        //       className="ragna-dropdown"
                        //       buttonChild={({ selected }) => (
                        //         <ActionDrop
                        //           more
                        //           // onMoreClick={() => {
                        //           //   navigate("/personal-users/re43rfs");
                        //           // }}
                        //         />
                        //       )}
                        //     />
                        //   </div>
                        // ),
                      }}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </div>
        )}
      </div>

      <div className={cn(selectedTab.value, "biz-info-modal")}>
        <ActionModal
          visible={view.on}
          onCancel={() => {
            setView({ chi: {}, on: false });
          }}
          big={true}
          cancelText="No, Cancel"
          actionText="View Full Profile"
          onClick={() => {
            navigate(
              `/business-businesses/${
                view.chi.email && view.chi.email?.length > 5
                  ? view.chi.email
                  : view?.chi?.alt_email
              }/transactions`
            );
          }}
          btnColor="black"
          loading={loading}
          hideCancel
          body="Are you sure to verify this compliance data? You can always come back to perform the action.."
          title={`${selectedTab.label}`}
        >
          {
            <div className="biz-compliance-results">
              <div className="compliance-results__main">
                <InlineDetails
                  direction={""}
                  className="no-shadow"
                  title={"Verification Details"}
                  simple
                  isTransaction={
                    selectedTab.value === "business_info" || selectedTab.value === "cac"
                  }
                  amount={0}
                  onClick={() => {}}
                  content={businessInfoComplianceData(view.chi)}
                />
              </div>
            </div>
          }
        </ActionModal>
      </div>
    </>
  );
};

export default BBusinessInfo;

function businessInfoComplianceData(data: Partial<Business>): {
  label: string;
  value: string | ReactElement;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  return [
    {
      label: "Business",
      value: Util.safeValue(capitalizeFirstLetter(data.business_name)),
    },

    {
      label: "Email Address",
      value: Util.safeValue(
        data?.business_details_from_central?.business_email ?? data?.email
      ),
    },

    {
      label: "Industry",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.industry)
      ),
    },

    {
      label: "Staff",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.employee_size)
      ),
    },

    {
      label: "Transaction Range",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.transaction_range)
      ),
    },
    // {
    //   label: "Status",
    //   value: formatStatus(
    //     Number(data?.business_verified) === 0 ? "unverified" : "verified"
    //   ) as ReactElement,
    // },
    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data?.created_at ?? "")),
    },
  ];
}
