import { Input } from "@/components/common/input";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { IconVault, reactSelectStyle } from "@/utils/helper/Helper";
import { Validator } from "@/utils/validator";
import { SyntheticEvent, useState } from "react";
import { DETAILS_CHECKER_ICONS } from "./details-wrapper/icons";
import { isObjectWithValidValues } from "@/utils/helpers";
import { useGetDetailsChecker } from "./details-wrapper/context";
import { z } from "zod";
import { DetailsCheckerAPI } from "@/redux/slices/details-checker/api";
import { APIResponseHandler } from "@/utils/api";

export type BVN_NIN_METER_NUMBER = "bvn" | "nin" | "vin";

interface Props {
  type: BVN_NIN_METER_NUMBER;
}

const getNinOrBvnOrVotersForm = (type: BVN_NIN_METER_NUMBER): string => {
  switch (type) {
    case "nin":
      return "National Identification number";
    case "bvn":
      return "Bank Verification Number";
    case "vin":
      return "Voter's Identification Number";
  }
};

interface FormDropdownProps {
  img: string;
  label: string;
  value: string;
  isActive?: boolean;
}
const formDropdown = ({ img, label, value, isActive }: FormDropdownProps) => {
  return {
    label: (
      <div className={cn(GENERAL_CLASSES.DROPDOWN_W_IMAGE, "DROPDOWN_W_IMAGE--active")}>
        <img src={img} alt="" />
        <span>{label}</span>
      </div>
    ),
    value,
  };
};

const selectOption: Omit<FormDropdownProps, "isActive">[] = [
  {
    img: IconVault(DETAILS_CHECKER_ICONS.blue_salt),
    label: "Blusalt",
    value: "blusalt",
  },
  {
    img: IconVault(DETAILS_CHECKER_ICONS.verify_me),
    label: "VerifyMe",
    value: "verifyme",
  },
];

export const NinOrBvnOrVotersForm = ({ type }: Props) => {
  // const [data, setData] = useState();
  const { setLoading, loading, setData } = useGetDetailsChecker();
  const key = getNinOrBvnOrVotersForm(type);

  const schema = Validator.object({
    token: Validator.string(key).refine((arg) => arg.length > 10, {
      message: cn(key, "is too short"),
    }),
    provider: Validator.string("Data Provider"),
  });

  const [formData, setFormData] = useState({
    token: "",
    provider: "",
  });
  const [errors, setErrors] = useState(Validator.createError(schema));

  const disabled = !isObjectWithValidValues(formData);

  const fetchDetails = async (data: z.infer<typeof schema>) => {
    setLoading(true);
    try {
      const response = await DetailsCheckerAPI.checkNINOrBVNOrMeterNumber({
        ...data,
        type,
      });
      const success = APIResponseHandler.tryHandler({
        response,
        hideSuccessToast: false,
      });
      if (success) {
        setData(response.data.data);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    }
    setLoading(false);
  };

  const handledSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    Validator.validatorFunc({
      data: formData,
      onError: setErrors,
      onSuccess: fetchDetails,
      schema,
    });
  };

  return (
    <form className={GENERAL_CLASSES.DETAILS_CHECKER_FORM} onSubmit={handledSubmit}>
      <DataProviderSelect
        onChange={(data) => setFormData((old) => ({ ...old, provider: data }))}
        formData={formData}
      />
      <Input
        type={type === "vin" ? "text" : "number"}
        placeholder={cn("Enter Customer's", type.toUpperCase())}
        label={key}
        value={formData.token ?? ""}
        onChange={(e: any) => setFormData((old) => ({ ...old, token: e.target.value }))}
        showError={Boolean(errors?.token)}
        errorText={errors?.token}
      />
      <JoinedDetailsSubmitButton
        disabled={disabled}
        loading={loading}
        className="mt-20"
      />
    </form>
  );
};

interface DataProviderSelectProps {
  formData?: { provider: string };
  onChange(data: string): void;
  error?: string;
}

export const DataProviderSelect = (props: DataProviderSelectProps) => {
  const { onChange, formData, error } = props;

  const selectValue =
    selectOption.find((opt) => opt.value === formData?.provider) ?? undefined;

  return (
    <Input
      type="select"
      placeholder="Select a Data Provider"
      label="Data Provider"
      selectOption={selectOption.map((val) =>
        formDropdown({ ...val, isActive: formData?.provider === val.value })
      )}
      selectStyles={reactSelectStyle}
      onChange={(data: any) => {
        onChange(data.value);
      }}
      value={selectValue}
      showError={Boolean(error)}
      errorText={error}
    />
  );
};

interface JDSBProps {
  disabled: boolean;
  loading: boolean;
  className?: string;
}

export const JoinedDetailsSubmitButton = ({
  disabled,
  loading,
  className,
}: JDSBProps) => (
  <Input
    value="Fetch Customer Details"
    className={className}
    disabled={disabled}
    loading={loading}
    type="submit"
  />
);
