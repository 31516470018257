import { authenticate, getUser } from "./auth/auth";
import { BusinessBankingAPI } from "./business";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

export const BugissApi = {
  authenticate: authenticate,
  getUser: getUser,
  business: BusinessBankingAPI,
};

export type CreateBuilderContext<
  TagTypes extends string,
  Path extends string = "api"
> = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  TagTypes,
  Path
>;
