import { useDarkMode } from "./useDarkMode";

const useThemeMode = () => {
  const [theme] = useDarkMode(localStorage.getItem("theme"));

  const isDarkMode = theme === "dark";

  return { theme, isDarkMode };
};

export default useThemeMode;
