import { Fragment, ReactNode } from "react";
import "./styles/index.css";
import { cn } from "@/utils/colorConvert";
import { Row } from "@/components/common/stacks";

export interface PageLayoutProps {
  pageTitle?: string;
  pageSubtitle?: string;
  tableTitle?: string;
  activeCurrency?: (e: any) => any;
  children?: ReactNode;
  style?: React.CSSProperties | undefined;
  topRightContent?: ReactNode;
  childrenClassName?: string;
  topLeftContent?: ReactNode;
}

const PageLayout = (props: PageLayoutProps) => {
  return (
    <Fragment>
      <div className="page-wrap">
        <div className={cn("page-wrap__top")}>
          <Row ai={"center"} gap={0.5}>
            {props.topLeftContent && (
              <div className="top__top-left-content">{props.topLeftContent}</div>
            )}
            <div className="top__title ">
              <h5>{props.pageTitle ?? "Title goes here "}</h5>
              {props.pageSubtitle && <p>{props.pageSubtitle ?? "Subtitle goes here"}</p>}
            </div>
          </Row>

          {props.topRightContent && (
            <div className="top__top-right-content">{props.topRightContent}</div>
          )}
        </div>

        <div
          style={props.style}
          className={cn("history__content", props.childrenClassName)}
        >
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export default PageLayout;
