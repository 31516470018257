import React, { useEffect } from "react";
import "./styles/index.css";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import { formatDateTime } from "@/utils/helper/Helper";
import Redacted from "@/components/common/shimmer/redact";

const InvoiceDetails = ({ data }: { data: BusinessInvoiceModel }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      BugissApi.business.fetchSingleInvoice({
        reference: data.invoice_reference,
      })
    );
  }, [data, dispatch]);

  const { invoice_details, loadingSingle: loading } = useSelector(
    (state: RootState) => state.businessInvoice
  );

  const { items } = invoice_details;

  return (
    <div className="invoice-modal">
      <div className="invoice-modal__top-details">
        <div className="invoice-modal__top-details--item">
          <h6>Invoice Number</h6>
          <p>#{invoice_details.id}</p>
        </div>
        {loading ? (
          <Redacted.Labelled />
        ) : (
          <div className="invoice-modal__top-details--item">
            <h6>Date Created</h6>
            <p>{formatDateTime(invoice_details.created_at)}</p>
          </div>
        )}

        {loading ? (
          <Redacted.Labelled />
        ) : (
          <div className="invoice-modal__top-details--item">
            <h6>Due Date</h6>
            <p>{formatDateTime(invoice_details.due_date)}</p>
          </div>
        )}

        {loading ? (
          <Redacted.Labelled />
        ) : (
          <div className="invoice-modal__top-details--item">
            <h6>Status</h6>
            <p className="item__paid">
              {invoice_details.paid_at != null ? "Paid" : "Unpaid"}
            </p>
          </div>
        )}
      </div>
      <div className="invoice-modal__bill-details">
        {loading ? (
          <Redacted.Labelled />
        ) : (
          <div className="invoice-modal__bill-details--item">
            <h6>Bill From:</h6>
            <p>{Util.safeValue(invoice_details?.business_name)}</p>
            <p>{Util.safeValue(invoice_details.business_address)}</p>
          </div>
        )}
        {loading ? (
          <Redacted.Labelled />
        ) : (
          <div className="invoice-modal__bill-details--item">
            <h6>Bill To:</h6>
            <p>{Util.safeValue(invoice_details.customer_name)}</p>
            <p>{Util.safeValue(invoice_details.customer_phone)}</p>
          </div>
        )}
      </div>

      <div className="invoice-modal__table">
        <RavenTable headerList={["Items", "Cost", "Qty", "Price"]}>
          {items?.map((item, idx) => (
            <tr>
              <td>{loading ? <Redacted.Table /> : item.product}</td>
              <td>
                {loading ? (
                  <Redacted.Table />
                ) : (
                  formatNumberToCurrency(item.unit_price, "NGN")
                )}
              </td>
              <td>{loading ? <Redacted.Table /> : Util.safeValue(item.quantity)}</td>
              <td>
                {loading ? (
                  <Redacted.Table />
                ) : (
                  formatNumberToCurrency(Number(item.quantity) * Number(item.unit_price))
                )}
              </td>
            </tr>
          ))}
        </RavenTable>
        <div className="table-subtotal">
          <div className="table-subtotal__gap"></div>

          <div className="table-subtotal__main">
            {loading ? (
              <Redacted.Labelled />
            ) : (
              <div className="table-subtotal__item">
                <p>Subtotal:</p>
                <p>{formatNumberToCurrency(Number(invoice_details?.net), "NGN")}</p>
              </div>
            )}
            {loading ? (
              <Redacted.Labelled />
            ) : (
              <div className="table-subtotal__item">
                <p>Discount:</p>
                <p>{formatNumberToCurrency(Number(invoice_details?.discount), "NGN")}</p>
              </div>
            )}

            {loading ? (
              <Redacted.Labelled />
            ) : (
              <div className="table-subtotal__item">
                <p>Tax:</p>
                <p>{formatNumberToCurrency(Number(invoice_details?.tax), "NGN")}</p>
              </div>
            )}
          </div>
        </div>

        <div className="table-subtotal total">
          <div className="table-subtotal__gap"></div>

          <div className="table-subtotal__main">
            {loading ? (
              <Redacted.Labelled />
            ) : (
              <div className="table-subtotal__item">
                <p>Total:</p>
                <p>{formatNumberToCurrency(Number(invoice_details?.gross), "NGN")}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <Redacted.Labelled />
      ) : (
        <div className="invoice-modal__note">
          <h6>Note:</h6>
          <p>{Util.safeValue(invoice_details.note)}</p>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;
