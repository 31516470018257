import "./styles/index.css";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import SmartFilter from "../../../components/common/smartFilter";
import EmptyScreen from "../../../components/common/emptyScreen";
import { RavenNumberFormat, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatNumWithComma } from "@/utils/helper/Helper";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import TwinValue from "@/components/common/twinValue";
import { formatNumberToCurrency } from "@/utils/helpers";
import usePosGetQuery from "../__internals__/hooks/usePosGetQuery";

const PosHNI = () => {
  const { isDarkMode } = useThemeMode();

  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosHniBusiness>("getHni");

  const hnis = tableData;

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="HNI Businesses">
          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            <SmartFilter {...SmartFilterProps} />

            {hnis?.length === 0 || !hnis || (!isRedacting && combinedLoading) ? (
              <EmptyScreen loading={combinedLoading} />
            ) : (
              <div className="table">
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={[
                    "BUSINESS DETAILS",
                    "TOTAL",
                    "TRANSFERS",
                    "CARD COLLECTION",
                    "BANK COLLECTION",
                  ]}
                >
                  {hnis?.map((chi) => {
                    return (
                      <RavenTableRow
                        one={
                          combinedLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <TwinValue
                              one={Util.safeValue(chi?.business_name)}
                              two={Util.safeValue(chi?.email)}
                              copy
                            />
                          )
                        }
                        two={
                          combinedLoading ? (
                            <Redacted.Table />
                          ) : (
                            <TwinValue
                              one={formatNumberToCurrency(chi?.totalVolume, "ngn")}
                              two={`${RavenNumberFormat(chi?.totalCount, {
                                convertToNumber: true,
                              })} Transactions`}
                            />
                          )
                        }
                        three={
                          combinedLoading ? (
                            <Redacted.Labelled />
                          ) : (
                            <div>
                              <TwinValue
                                one={formatNumberToCurrency(chi?.transferVolume)}
                                two={`${formatNumWithComma(
                                  chi?.transferCount
                                )} Transfers`}
                              />
                            </div>
                          )
                        }
                        four={
                          combinedLoading ? (
                            <Redacted.Table />
                          ) : (
                            <div>
                              <TwinValue
                                one={formatNumberToCurrency(chi?.cardsVolume)}
                                two={`${formatNumWithComma(
                                  chi?.cardsCount
                                )} Collections `}
                              />
                            </div>
                          )
                        }
                        five={
                          combinedLoading ? (
                            <Redacted.Table />
                          ) : (
                            <div>
                              <TwinValue
                                one={formatNumberToCurrency(chi?.bankCollectionVolume)}
                                two={`${formatNumWithComma(
                                  chi?.bankCollectionCount
                                )} Collections `}
                              />
                            </div>
                          )
                        }
                      />
                    );
                  })}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
    </>
  );
};

export default PosHNI;
