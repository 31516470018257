import { icons } from "@/assets/icons";
import { formatStatus } from "@/utils/helper/Helper";
import React from "react";
import "./styles/index.css";
import { Util } from "@/utils/utility";
const BusinessBeneficiary = ({ data }: { data: IBeneficiary[] }) => {
  return (
    <div className="auth-device-container flex col">
      <p style={{ color: "#676767", marginTop: "1rem" }}></p>
      {data?.map((d) => {
        return (
          <div className="auth-device-container__item">
            <div className="auth-device-container__item--title-wrap">
              <figure>{icons.arrow_circle_broken}</figure>
              <span className="device-title">
                <p>{Util.safeValue(d.account_name)}</p>
                <small>{Util.safeValue(d.account_number)}</small>
              </span>
            </div>

            <div className="status">
              {/* {formatStatus("active")} */}
              <figure>{icons.more}</figure>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BusinessBeneficiary;
