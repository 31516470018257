import { PosBuilder } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosBillsApi = (builder: PosBuilder) => {
  const apis = {
    getAirtimeRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/airtime", { name: "getAirtimeRecords" }),

    getAirtimeRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/airtime", { export: true, name: "getAirtimeRecordsExport" }),

    getDataRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/data", { name: "getDataRecords" }),

    getDataRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/data", { export: true, name: "getDataRecordsExport" }),

    getCableRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/cable", { name: "getCableRecords" }),

    getCableRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/cable", { export: true, name: "getCableRecordsExport" }),

    getElectricityRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/electricity", { name: "getElectricityRecords" }),

    getElectricityRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/electricity", { export: true, name: "getElectricityRecordsExport" }),

    getBettingRecords: createQueryWithRoute<
      PosBillBetModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/bet", { name: "getBettingRecords" }),

    getBettingRecordsExport: createQueryWithRoute<
      PosBillBetModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "bill/bet", { export: true, name: "getBettingRecordsExport" }),
  };

  return apis;
};
