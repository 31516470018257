import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";
import { CombinedBillsType } from "@/redux/slices/personal/api/bills/api";

// TODO: ASK ABOUT THIS
export const getAirtimeDataElectricityStatus = (status: number) => {
  switch (status) {
    case 4:
      return "successful";
    case 2:
      return "failed";
    case 3:
      return "pending";
    default:
      throw new Error(`Unresolved status reached for ${status}`);
  }
};

export const getBettingStatus = (status: number) => {
  switch (status) {
    case 1:
      return "pending";
    case 2:
      return "failed";
    case 3:
      return "successful";
    default:
      throw new Error(`Unresolved status reached for ${status}`);
  }
};

export type AllBills = CombinedBillsType | "airtime" | "data";

export const createStatusDropdownForBills = (type: AllBills) => {
  switch (type) {
    case "airtime":
    case "data":
    case "electricity":
    case "cable":
      return [
        { value: CUSTOM_FILTER_VALUE_FOR_ALL, label: "All" },
        { value: 4, label: "Successful" },
        { value: 3, label: "Pending" },
        { value: 2, label: "Failed" },
      ];
    case "betting":
      return [
        { value: CUSTOM_FILTER_VALUE_FOR_ALL, label: "All" },
        { value: 3, label: "Successful" },
        { value: 1, label: "Pending" },
        { value: 2, label: "Failed" },
      ];
  }
};

export const getJointBillerStatus = (status: number, type: CombinedBillsType) => {
  if (type === "electricity") return getAirtimeDataElectricityStatus(status);
  if (type === "betting") return getBettingStatus(status);
  if (type === "cable") return getAirtimeDataElectricityStatus(status);

  throw new Error(`Unreachable status reached for ${type}`);
};

type AirtimeOrData = PersonalAirtimeOrData;

export const isAirtimeBillRow = (row: AirtimeOrData): row is PersonalAirtimeModel =>
  "trx_id" in row;

export const isDataBillRow = (row: AirtimeOrData): row is PersonalDataModel =>
  "plan" in row;

type Rec = AllCombinedJointBills;

export const getJoinedBillerNarrowFunc = (row: PersonalJointBillModel) => {
  const isCable = (rec: Rec): rec is PersonalCableModel => row.whatTypeIsThis === "cable";

  const isBetting = (rec: Rec): rec is PersonalBettingModel =>
    row.whatTypeIsThis === "betting";

  const isElectricity = (rec: Rec): rec is PersonalElectricityModel =>
    row.whatTypeIsThis === "electricity";

  return { isCable, isBetting, isElectricity };
};
