import { PersonalFraudCenter } from "~pb/fraud";
import { PersonalFraudBlockedUsers } from "~pb/fraud/pages/blocked-users";
import { PersonalFraudRagnarStrike } from "~pb/fraud/pages/ragnar-strike";
import { PersonalFraudLienedUsers } from "~pb/fraud/pages/liened-users";
import { PersonalFraudPNDUsers } from "~pb/fraud/pages/pnd-users";

export const personal_fraud_routes: RouteArray[] = [
  {
    path: "/personal-fraud-center",
    element: PersonalFraudCenter,
    children: [
      { path: "", element: PersonalFraudBlockedUsers },
      { path: "blocked-users", element: PersonalFraudBlockedUsers },
      { path: "ragnar-strike", element: PersonalFraudRagnarStrike },
      { path: "liened-users", element: PersonalFraudLienedUsers },
      { path: "pnd-users", element: PersonalFraudPNDUsers },
    ],
  },
];
