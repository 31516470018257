import SmartFilter from "@/components/common/smartFilter";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useSingleUserState } from "../../context";
import { GLOBAL_PER_PAGE, SMART_FILTER, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { PersonalTransactionTable } from "~pb/__internals__/combined-tables/transaction-table";
import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { usePbTransactionExports } from "@/apps/personal/__internals__/internal-hooks/transactions";

export const UsersTransactions = () => {
  const pagination = usePagination();
  const { email } = useSingleUserState();
  const { currentPage } = pagination;
  const { data, isLoading, isFetching } = personalReduxAPI.useGetUserTransactionsQuery({
    email,
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });

  const { ExportModal, onExport } = usePbTransactionExports(email);

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />;

  return (
    <HeightWithMarginOffset>
      <SmartFilter
        alternateTitle={SMART_FILTER.HAS_NO_SEARCH}
        {...SmartFilterProps}
        onExport={onExport}
      />
      {data.transactions?.length > 0 ? (
        <PersonalTransactionTable
          ctx="user"
          data={data.transactions}
          redactedFn={redactedFn}
        />
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};
