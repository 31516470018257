import "./styles/modal.css";
import { Input } from "@/components/common/input";
import ActionModal from "@/components/common/modal/ActionModal";
import Gap from "@/components/common/styleComponents/Gap";
import { CURRENCY_MAPPER, REASONS_FOR_BLOCK } from "@/constants";
import { useProductTheme } from "@/context/ProductTheme";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { iife } from "@/utils/general";
import { formatNumWithoutCommaNaira, reactSelectStyle } from "@/utils/helper/Helper";
import { fileDownloadUtil, formatWithDayJs, getThemePrimaryColor } from "@/utils/helpers";
import { Validator } from "@/utils/validator";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { SyntheticEvent, useState } from "react";
import { z } from "zod";

interface BaseModalProps {
  onCancel(): void;
  /** E.g To block would be main action = true */
  isMainAction: boolean;
  visible: boolean;
  refetch(): void | Promise<any>;
  email: string;
}

export const BlockUnblockModal = (props: BaseModalProps) => {
  const schema = Validator.object({
    reason: Validator.select("Reason"),
  });

  const { onCancel, isMainAction, visible, email, refetch } = props;
  const { productTheme } = useProductTheme();
  const btnColor = getThemePrimaryColor(productTheme);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState <{reason: RavenSelectType, custom_reason?:string}> ({
    reason: { label: "", value: "" },
    custom_reason:''
  });
  const [error, setError] = useState("");

  const handleChange = (event: any) => {
    setForm({ reason: event });
  };

  const blockOrUnblock = async () => {
    setLoading(true);
    try {
      const res = await PersonalAPIService.blockOrUnblockUser({
        email,
        reason: form.reason.value === "custom" ? form.custom_reason as string : form.reason.value as string,
        type: isMainAction ? "block" : "unblock",
      });
      APIResponseHandler.tryHandler({ response: res });
      refetch();
      onCancel();
    } catch (errors) {
      APIResponseHandler.catchHandler(errors);
    } finally {
      setLoading(false);
    }
  };

  const handleBlockUnblock = () => {
    setError("");

    try {
      if (form.reason.value === "custom" && !form.custom_reason) {
        throw new Error("Custom reason is required");
      }
      schema.parse(form);
      blockOrUnblock();
    } catch (error) {
      if (isMainAction) setError("A block reason is required");
      else setError("An unblock reason is required");
    }
  };

  const text = isMainAction ? "Reason for Block*" : "Reason for unblock";

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      loading={loading}
      onClick={handleBlockUnblock}
      hideCancel
      actionText={`Yes, ${!isMainAction ? "Unblock" : "Block"}`}
      btnColor={!isMainAction ? btnColor : "red"}
      body={!isMainAction ? "Unblock this account" : "Block this account"}
      title={!isMainAction ? "Unblock User" : "Block User"}
    >
      <p style={{ marginBottom: "2rem" }}>
        {!isMainAction ? "Unblock this account" : "Block this account"}
      </p>

      <Input
        value={form.reason}
        className="bugiss-block-modal"
        label={text}
        onChange={handleChange}
        placeholder={text}
        type="select"
        selectValue={form.reason}
        selectOption={REASONS_FOR_BLOCK}
        showError={Boolean(error)}
        // selectMenuOpen={true}
        errorText={error}
        selectStyles={reactSelectStyle}
      />

      <Gap size={20}/>

     {form.reason.value === "custom" && (
      <Input
        value={form.custom_reason}
        className="bugiss-block-modal"
        label={'Custom Reason*'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setForm((old) => ({...old, custom_reason: e.target.value}))
        }}
        placeholder={text}
        type="text"
        showError={Boolean(error)}
        errorText={error}
      />
     )}
    </ActionModal>
  );
};

const BasicSchema = Validator.object({
  reason: Validator.string("Reason"),
  amount: Validator.number("Amount"),
});

type Payload = z.infer<typeof BasicSchema>;

type LienUnlienModalProps = BaseModalProps & { amount?: string };

export const LienUnlienModal = (props: LienUnlienModalProps) => {
  const { isMainAction, onCancel, visible, email, refetch, amount = "" } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    amount,
    reason: "",
  });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));
  const { productTheme } = useProductTheme();
  const btnColor = getThemePrimaryColor(productTheme);

  const formData = {
    amount: formatNumWithoutCommaNaira(form.amount),
    reason: form.reason,
  };

  const handleChange = (event: InputOnChange) => {
    const { name, value } = event.target;
    setForm((old) => ({ ...old, [name]: value }));
  };

  const lienOrUnlienUser = async (payload: Payload) => {
    setLoading(true);
    try {
      const res = await PersonalAPIService.lienOrUnlien({
        amount: Number(payload.amount),
        reason: payload.reason,
        email,
        type: isMainAction ? "lien" : "unlien",
      });
      APIResponseHandler.tryHandler({ response: res });
      refetch();
      onCancel();
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLienOrUnlien = () => {
    try {
      const res = BasicSchema.parse(formData);
      setErrors({});
      lienOrUnlienUser(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      loading={loading}
      onClick={handleLienOrUnlien}
      cancelText="No, Cancel"
      actionText={`Yes, ${!isMainAction ? "Unlien" : "Lien"}`}
      btnColor={!isMainAction ? btnColor : "red"}
      body={`Are you sure you want to ${
        isMainAction ? "lien" : "unlien"
      } this user account? You can always come back to perform the action.`}
      title={!isMainAction ? "Unlien Amount" : "Lien Amount"}
    >
      <p style={{ marginBottom: "2rem" }}>
        {`Are you sure you want to ${
          isMainAction ? "lien" : "unlien"
        } this user account? You can always come back to perform the action.`}
      </p>

      <Input
        className="bugiss-block-modal"
        label="Amount*"
        value={form.amount}
        onChange={handleChange}
        showError={Boolean(errors?.amount)}
        errorText={errors?.amount}
        placeholder="Amount*"
        type="number"
        name="amount"
        thousandFormat
        numberPrefix={CURRENCY_MAPPER.NGN}
      />

      <Input
        value={form.reason}
        className="bugiss-block-modal mt-10"
        label="Reason for Lien*"
        showError={Boolean(errors?.reason)}
        errorText={errors?.reason}
        onChange={handleChange}
        placeholder="Reason for Lien"
        type="textarea"
        name="reason"
      />
    </ActionModal>
  );
};

export const MakeDeductionModal = (props: BaseModalProps) => {
  const { productTheme } = useProductTheme();
  const { onCancel, visible, email, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    amount: "",
    reason: "",
  });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const formData = {
    amount: formatNumWithoutCommaNaira(form.amount),
    reason: form.reason,
  };

  const handleChange = (event: InputOnChange) => {
    const { name, value } = event.target;
    setErrors((old) => ({
      ...old,
      [name]: undefined,
    }));
    setForm((old) => ({ ...old, [name]: value }));
  };

  const makeDeduction = async (payload: Payload) => {
    setLoading(true);
    try {
      const res = await PersonalAPIService.deductUserAccount({
        amount: Number(payload.amount),
        reason: payload.reason,
        email,
      });
      APIResponseHandler.tryHandler({ response: res });
      refetch();
      onCancel();
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeduction = () => {
    try {
      const res = BasicSchema.parse(formData);
      setErrors({});
      makeDeduction(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      loading={loading}
      onClick={handleDeduction}
      hideCancel
      actionText="Make a Deduction"
      btnColor={getThemePrimaryColor(productTheme)}
      body="Deduct specified amount from user balance"
      title="Make a Deduction"
    >
      <p style={{ marginBottom: "2rem" }}>Deduct a certain amount from this user</p>
      <Input
        className="bugiss-block-modal"
        label="Amount*"
        value={form.amount}
        onChange={handleChange}
        showError={Boolean(errors?.amount)}
        errorText={errors?.amount}
        placeholder="Amount*"
        type="number"
        name="amount"
        thousandFormat
        numberPrefix={CURRENCY_MAPPER.NGN}
      />
      <Input
        value={form.reason}
        className="bugiss-block-modal mt-10"
        label="Reason for Deduction*"
        showError={Boolean(errors?.reason)}
        errorText={errors?.reason}
        onChange={handleChange}
        placeholder="Reason for Deduction"
        type="textarea"
        name="reason"
      />
    </ActionModal>
  );
};

export const StripVerificationsModal = (props: BaseModalProps) => {
  const { refetch, email, visible, onCancel } = props;
  const [loading, setLoading] = useState(false);

  const handleStripVerification = async () => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.stripAllVerification({
        email,
        verification_type: "bvn",
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        await refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      onClick={handleStripVerification}
      cancelText="No, Cancel"
      actionText="Yes, Strip"
      btnColor="red"
      body="Are you sure you want to strip this user's verification ? You can always come back to perform the action.."
      title="Strip Verification"
      loading={loading}
    />
  );
};

const actionsDropdown = [
  { label: "Custom", value: "custom" },
  { label: "Month", value: "month" },
  { label: "Days", value: "day" },
] as const;

export const GenerateStatementModal = (props: Omit<BaseModalProps, "isMainAction">) => {
  const schema = Validator.object({
    selectedAction: Validator.select("Range"),
  });
  const [error, setError] = useState(Validator.createError(schema));
  const { visible, email, onCancel } = props;
  type SelectedActions = (typeof actionsDropdown)[number];
  const [selectedAction, setSelectedAction] = useState<SelectedActions>({
    label: "Custom",
    value: "custom",
  });
  const [loading, setLoading] = useState(false);
  const { productTheme } = useProductTheme();

  const generateBankStatement = async (data: DiscriminatedPersonalStatement) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.generateBankStatement({ ...data, email });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        console.log(response.data.link, 'nini')
        fileDownloadUtil(response.data.link, "user-bank-statement.pdf");
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler({ error });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data: DiscriminatedPersonalStatement) => {
    Validator.validatorFunc({
      data: { selectedAction },
      onError: setError,
      schema,
      onSuccess: (payload) => {
        generateBankStatement(data);
      },
    });
  };

  const renderActionInput = () => {
    switch (selectedAction.value) {
      case "day":
        return (
          <DaySelector
            selectedAction={selectedAction}
            setMainError={setError}
            onSubmit={handleSubmit}
            loading={loading}
          />
        );
      case "month":
        return (
          <MonthSelector
            selectedAction={selectedAction}
            setMainError={setError}
            onSubmit={handleSubmit}
            loading={loading}
          />
        );
      case "custom":
        return (
          <CustomSelector
            selectedAction={selectedAction}
            setMainError={setError}
            onSubmit={handleSubmit}
            loading={loading}
          />
        );
    }
  };

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      onClick={() => {}}
      cancelText="No, Cancel"
      actionText="Generate"
      btnColor={getThemePrimaryColor(productTheme)}
      title="Generate Bank Statement"
      loading={loading}
      hideNormalButton
    >
      <p>Click on the dropdown to select range for the bank statement</p>
      <div className="mt-20">
        <Input
          className="bugiss-block-modal"
          label="Select what range to generate"
          onChange={(e: any) => setSelectedAction(e)}
          placeholder="Select range"
          type="select"
          selectValue={selectedAction}
          value={selectedAction}
          selectOption={actionsDropdown}
          showError={Boolean(error?.selectedAction)}
          errorText={error?.selectedAction}
          selectStyles={reactSelectStyle}
        />
      </div>
      <div className="generate-statement-wrapper">{renderActionInput()}</div>
    </ActionModal>
  );
};

interface BaseCustom<T> {
  onSubmit(data: T): void;
  setMainError: React.Dispatch<
    React.SetStateAction<Partial<Record<"selectedAction", string>> | undefined>
  >;
  selectedAction: RavenSelectType;
  loading: boolean;
}

const DaySelector = (props: BaseCustom<PersonalStatementDaySelector>) => {
  const { onSubmit, selectedAction, setMainError, loading } = props;
  const schema = Validator.object({
    days: Validator.string("Number of days"),
    selectedAction: Validator.select("Range"),
  });

  const [days, setDays] = useState("");
  const [error, setError] = useState(Validator.createError(schema));

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    Validator.validatorFunc({
      data: { days, selectedAction },
      schema,
      onError: (err) => {
        setError(err);
        setMainError(err);
      },
      onSuccess: (payload) => {
        onSubmit({ number_of_days: payload.days, type: "day" });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="Enter number of days"
        onChange={(event: any) => setDays(event.target.value)}
        name="number_of_days"
        showError={Boolean(error?.days)}
        errorText={error?.days}
        label="Enter number days"
        type="number"
      />
      <RavenButton loading={loading} color="purple-light" label="Submit" />
    </form>
  );
};

const MonthSelector = (props: BaseCustom<PersonalStatementMonth>) => {
  const { onSubmit, selectedAction, setMainError, loading } = props;
  const schema = Validator.object({
    month: Validator.string("Number of months"),
    selectedAction: Validator.select("Range"),
  });

  const [month, setMonths] = useState("");
  const [error, setError] = useState(Validator.createError(schema));

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    Validator.validatorFunc({
      data: { month, selectedAction },
      schema,
      onError: (err) => {
        setError(err);
        setMainError(err);
      },
      onSuccess: (payload) => {
        onSubmit({ number_of_months: payload.month, type: "month" });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="Enter number of months"
        label="Enter number of months"
        onChange={(event: any) => setMonths(event.target.value)}
        name="number_of_days"
        showError={Boolean(error?.month)}
        errorText={error?.month}
        type="number"
      />
      <RavenButton loading={loading} color="purple-light" label="Submit" />
    </form>
  );
};
const CustomSelector = (props: BaseCustom<PersonalStatementCustom>) => {
  const { onSubmit, selectedAction, setMainError, loading } = props;
  const schema = Validator.object({
    from_date: Validator.string("From date"),
    to_date: Validator.string("To date"),
    selectedAction: Validator.select("Range"),
  });

  const [formData, setFormData] = useState({
    from_date: "",
    to_date: "",
    selectedAction,
  });
  const [error, setError] = useState(Validator.createError(schema));

  const handleChange = (date: Date, key: keyof typeof formData) => {
    setFormData((old) => ({
      ...old,
      [key]: formatWithDayJs(date, "YYYY-MM-DD"),
    }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    Validator.validatorFunc({
      data: formData,
      schema,
      onError: (err) => {
        setError(err);
        setMainError(err);
      },
      onSuccess: (payload) => {
        onSubmit({
          from: payload.from_date,
          to: payload.to_date,
          type: "custom",
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        placeholder="Enter from date"
        label="Enter from date"
        onChange={(date: any) => handleChange(date, "from_date")}
        name="from_date"
        showError={Boolean(error?.from_date)}
        errorText={error?.from_date}
        type="date"
        dateOptions={{ maxDate: new Date() }}
      />
      <Input
        placeholder="Enter to date"
        label="Enter to date"
        onChange={(date: any) => handleChange(date, "to_date")}
        name="to_date"
        showError={Boolean(error?.to_date)}
        errorText={error?.to_date}
        type="date"
        dateOptions={{
          maxDate: new Date(),
          minDate: formData.from_date,
        }}
      />
      <RavenButton loading={loading} color="purple-light" label="Submit" />
    </form>
  );
};
