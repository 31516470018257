import InlineDetails, { InlineDetailsContent } from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";

interface Props {
  transaction: PersonalTransactionModel;
}

export const PersonalTransactionModal = (props: CreateDetailsProps<Props>) => {
  const { onCancel, transaction } = props;

  const { amount, email, reference, description, type, fee, created_at } = transaction;

  const Content: InlineDetailsContent[] = [
    { label: "Amount", value: replaceSymbolInText(amount) },
    { label: "Balance Before", value: formatNumberToCurrency(transaction.b_before ?? 0) },
    { label: "Balance After", value: formatNumberToCurrency(transaction.b_after ?? 0) },
    { label: "Recipient", value: Util.safeText(email) },
    { label: "Description", value: Util.safeText(description) },
    { label: "Type", value: Util.safeText(type) },
    { label: "Fee", value: fee ? formatNumberToCurrency(fee) : "--" },
    { label: "Reference ID", value: Util.safeText(reference), newCopy: true },
    { label: "Date Logged", value: formatDateTime(created_at) },
  ];

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => onCancel()}
      title="Transaction Details"
      hideCancel
      btnColor="#F7F7F7"
      btnTextColor="#020202"
      // DISABLING download receipt for now
      actionText="Export as PDF"
      hideNormalButton
      className={cn(GENERAL_CLASSES.BIG_MODAL, "pb-transaction-dt-modal")}
    >
      <div className={cn(GENERAL_CLASSES.INLINE_DETAILS.WRAPPER)}>
        <InlineDetails
          title="Transaction Details"
          simple
          className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
          amount={0}
          content={Content}
        />
      </div>
    </ActionModal>
  );
};
