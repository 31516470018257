import "./styles/index.css";
import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import SmartFilter from "../../../components/common/smartFilter";
import EmptyScreen from "../../../components/common/emptyScreen";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  formatDateTime,
  mapHotkey,
  trimLongString,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown, { DropdownItemType } from "@/components/common/dropdown";
import { dropdownIcons } from "@/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Redacted from "@/components/common/shimmer/redact";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { CopyText } from "@/components/common/copyCheck";

function formatStatus(status: string | number, refunded?: number) {
  let s;
  if (status === 0 || status === "blocked") {
    s = "blocked";
  }
  if (status === 1 || status === "active") {
    s = "active";
  }
  return (
    <div className={`bugiss-status ${s && s?.toLowerCase()}`}>
      <p>{s}</p>
    </div>
  );
}

const dropdownItems = [
  {
    label: "View Business Profile",
    img: IconVault(dropdownIcons.arrow_circle_right),
    value: "view",
  },
  // {
  //   label: "Deactivate Business",
  //   img: IconVault(dropdownIcons.info_circle),
  //   value: "deactivate",
  // },
  // {
  //   label: "Lien Business Account",
  //   img: IconVault(dropdownIcons.info_circle),
  //   value: "lien",
  // },
  // {
  //   label: "Block Business",
  //   img: IconVault(dropdownIcons.slash_circle_01),
  //   value: "block",
  // },
];

const BusinessUsers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<any>({});

  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { businesses, bizLoading: loading } = useSelector(
    (state: RootState) => state.businessBusinesses
  );

  useEffect(() => {
    dispatch(toggleRedact(true));
    dispatch(
      BugissApi.business.getBusinesses({
        per_page: 20,
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
      })
    );
  }, [currentPage, searchQuery, filterValue]);

  const biz = businesses.data ?? [];

  function handleDropSelect(arg: string, arg2?: string) {
    if (arg === "view") {
      navigate(`/business-businesses/${arg2}/transactions`);
    }
  }

  const { isDarkMode } = useThemeMode();
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "EMAIL ADDRESS": "email",
    "MOBILE NUMBER": "phone",
    "DATE JOINED": "created_at",
    STATUS: ["block_business", ""],
  };

  const triggerExport = useIsolatedExport({
    setFilterValue,
    loading,
    valuePair,
    title: "Business Export(Bugiss)",
    apiFunction: BugissApi.business.getBusinesses,
    dataPath: "businesses.data",
    filterValue,
  });

  const { toggleModal } = useExportModal(triggerExport);

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Businesses">
          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            <SmartFilter
              page="insights"
              onSearchChange={(e: string) => {
                setSearchQuery(e);
                setCurrentPage(1);
              }}
              onFilter={(e: any) => {
                setFilterValue(Util.filterStruct(e));
              }}
              onExport={() => toggleModal("Export Businesses")}
              searchTitle="Search businesses..."
              pagination={{
                currentPage: currentPage,
                itemsPerPage: businesses?.per_page as number,
                totalItems: businesses?.total as number,
                onPageChange: (page: number) => {
                  setCurrentPage(page);
                },
                activeFilter: "All Businesses",
              }}
            />

            {biz?.length === 0 || (!isRedacting && loading) ? (
              <EmptyScreen loading={loading} />
            ) : (
              <div className="table">
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={[
                    "BUSINESS NAME",
                    "EMAIL ADDRESS",
                    "MOBILE NUMBER",
                    "DATE JOINED",
                    "STATUS",
                    "  ",
                  ]}
                >
                  {biz.map((chi, idx) => {
                    return (
                      <RavenTableRow
                        one={
                          loading ? (
                            <Redacted.AvatarWithValue />
                          ) : (
                            <Avatar
                              name={trimLongString(chi.business_name, 25) as string}
                              full
                            />
                          )
                        }
                        two={
                          loading ? (
                            <Redacted.Table />
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/business-businesses/${chi.email}/transactions`
                                );
                              }}
                            >
                              {<CopyText text={chi.email} />}
                            </div>
                          )
                        }
                        three={loading ? <Redacted.Table /> : chi.phone ?? "--"}
                        four={
                          loading ? (
                            <Redacted.Table />
                          ) : (
                            formatDateTime(chi.created_at ?? "")
                          )
                        }
                        five={
                          loading ? (
                            <Redacted.Table />
                          ) : (
                            formatStatus(chi.block_business === 1 ? 0 : 1)
                          )
                        }
                        six={
                          loading ? (
                            <Redacted.Avatar />
                          ) : (
                            <Dropdown
                              items={dropdownItems}
                              rightPosition
                              defaultValue={dropdownItems[0]}
                              onChange={(e: any) => handleDropSelect(e.value, chi.email)}
                              buttonChild={() => (
                                <ActionDrop
                                  visit
                                  onVisitClick={(e) =>
                                    handleDropSelect("view", chi.email)
                                  }
                                />
                              )}
                            />
                          )
                        }
                      />
                    );
                  })}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
    </>
  );
};

export default BusinessUsers;
