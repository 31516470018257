import React from "react";
import "../styles/dispute-message/dispute-message.css";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { Column } from "@/components/common/stacks";
import { icons } from "@/assets/icons";
import { IconVault } from "@/utils/helper/Helper";
const DisputeMessage: React.FC = () => {
  return (
    <div className="bg-disputes__message">
      <div className="bg-disputes__message-user">
        <AvatarWithTwinValue
          name={"3mmie4sure"}
          one={"3mmie4sure"}
          two={"emmy4sure.web@gmail.com"}
          className="bg-disputes__message-avatar"
          imgSrc="https://play-lh.googleusercontent.com/jA5PwYqtmoFS7StajBe2EawN4C8WDdltO68JcsrvYKSuhjcTap5QMETkloXSq5soqRBqFjuTAhh28AYrA6A=w240-h480-rw"
        />
      </div>
      <Column className="bg-disputes__message-wrapper">
        <p className="bg-disputes__message-content">
          Transfers failed but i have not gotten a refund yet
        </p>
        <p className="bg-disputes__message-details">
          The monetary amount I dispatched through the designated channel failed to
          successfully reach its intended destination, thereby leading to an instance
          where the individual designated as the recipient did not receive the anticipated
          funds
        </p>
        <div className="bg-disputes__message-attachments">
          <div className="bg-disputes__message-attachment">
            <img src={IconVault(icons.dispute_attachment)} alt="" />
            <span className="bg-disputes__message-attachment-name">Attachment</span>
          </div>
          <div className="bg-disputes__message-attachment">
            <img src={IconVault(icons.dispute_attachment)} alt="" />
            <span className="bg-disputes__message-attachment-name">Attachment</span>
          </div>
        </div>
      </Column>
    </div>
  );
};

export default DisputeMessage;
