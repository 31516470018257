import "./styles/index.css";
import { TransfersContext } from "./components/transactions-context";
import { BusinessTransferPageContent } from "./page";

/**
 * Acts as the entry file so i can initialize the context value
 */
const BusinessTransfers = () => {
  return (
    <TransfersContext>
      <BusinessTransferPageContent />
    </TransfersContext>
  );
};

export default BusinessTransfers;
