import "./styles/modal.css";
import ActionModal from "@/components/common/modal/ActionModal";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { XStack, YStack } from "@/components/common/stacks";
import TabComponent from "@/components/common/Tabs";
import { useProductTheme } from "@/context/ProductTheme";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import { getThemePrimaryColor } from "@/utils/helpers";
import { Util } from "@/utils/utility";

const phone = (
  <svg
    width={14}
    height={26}
    viewBox="0 0 14 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9486 2.65078C13.9486 1.80634 13.4797 1.20412 12.6659 0.987673C12.5672 0.96362 12.4662 0.950357 12.3646 0.948117H1.80813C1.41465 0.9435 1.03135 1.07315 0.721463 1.31567C0.232574 1.71034 0.0930186 2.29701 0.0952409 2.95745C0.113908 9.72812 0.105019 16.4997 0.105019 23.2686C0.105019 24.3797 0.778796 25.0508 1.89569 25.0517H12.1357C13.2846 25.0517 13.9495 24.3894 13.9495 23.245V7.02901C13.9495 5.56945 13.9492 4.11004 13.9486 2.65078ZM8.19213 3.34767C7.79969 3.35034 7.40724 3.34767 7.0148 3.34767C6.6308 3.34767 6.24591 3.34767 5.86146 3.34767C5.58324 3.34767 5.42146 3.20101 5.42457 2.96678C5.42813 2.74456 5.58635 2.59745 5.85035 2.59701C6.63524 2.59315 7.42043 2.59315 8.20591 2.59701C8.46946 2.59701 8.62857 2.74323 8.63169 2.96723C8.63302 3.20012 8.46946 3.3459 8.19213 3.34767Z"
      fill="#ACACAC"
    />
  </svg>
);

type BaseModalProps<Type> = {
  onCancel(): void;
  data: Type;
};
interface DeviceDetailProps {
  titleText: IsUncertain<string>;
  descText: IsUncertain<string>;
  icon?: JSX.Element | React.ReactNode;
  className?: string;
}
const DeviceDetail = (props: DeviceDetailProps) => {
  const { titleText, descText, icon, className } = props;
  return (
    <XStack gap={1.4} className={cn("personal-device-details items-center", className)}>
      {icon && <figure className="phone-icon">{icon}</figure>}
      <YStack gap={0.1}>
        <div className="personal-device-details__name">{Util.safeText(titleText)}</div>
        <div className="personal-device-details__os">{Util.safeText(descText)}</div>
      </YStack>
    </XStack>
  );
};

export const PersonalUserDeviceModal = (props: BaseModalProps<PersonalUserDevice[]>) => {
  const { onCancel, data } = props;
  const { productTheme } = useProductTheme();
  return (
    <ActionModal
      visible
      title="Authorised Devices"
      onCancel={onCancel}
      onClick={onCancel}
      hideCancel
      btnColor={getThemePrimaryColor(productTheme)}
      className="pb-pdm-modal personal-device hide-scrollbar personal-device--modal"
      actionText="Close"
      hideNormalButton
    >
      <p className="mb-20">View all user authorized devices</p>
      <div className="personal-device-ctx hide-scrollbar">
        {data.map(({ device_name, device_os }, idx) => (
          <DeviceDetail
            key={idx}
            titleText={device_name}
            icon={phone}
            descText={device_os}
          />
        ))}
      </div>
    </ActionModal>
  );
};

interface PersonalAccountsProps extends BaseModalProps<PersonalUserAccount[]> {
  ravenBeneficiaries: RavenBeneficiaries[];
  loading: boolean;
}
export const PersonalAccountsModal = (props: PersonalAccountsProps) => {
  const { onCancel, data, ravenBeneficiaries, loading } = props;
  const { productTheme } = useProductTheme();

  const tab = ["Raven", "Other Banks"];

  return (
    <ActionModal
      visible
      title="Beneficiaries"
      onCancel={onCancel}
      onClick={onCancel}
      hideCancel
      btnColor={getThemePrimaryColor(productTheme)}
      className="pb-pdm-modal personal-device hide-scrollbar personal-device--modal rm-beneficiary"
      actionText="Close"
      hideNormalButton
    >
      <p className="mb-20">View all beneficiary accounts</p>

      <div className="rm-beneficiary-account__tab">
        <TabComponent variant="secondary" defaultValue={tab[0]}>
          <TabComponent.Tabs tabs={tab} />
          <TabComponent.Content value={tab[0]}>
            <div className="rm-beneficiary-account">
              {ravenBeneficiaries.length > 0 ? (
                ravenBeneficiaries.map((beneficiary) => (
                  <DeviceDetail
                    className="rm-beneficiary-account__item"
                    key={beneficiary.email}
                    descText={
                      beneficiary.username
                        ? cleanWhiteSpace("@", Util.safeText(beneficiary.username))
                        : "--"
                    }
                    titleText={cn(beneficiary.fname, beneficiary.lname)}
                  />
                ))
              ) : (
                <TransferEmptyScreen
                  height="30rem"
                  loading={loading}
                  subTitle="No saved RAVEN beneficiary to display"
                  noMascot
                />
              )}
            </div>
          </TabComponent.Content>
          <TabComponent.Content value={tab[1]}>
            <div className="rm-beneficiary-account">
              {data.length > 0 ? (
                data.map((beneficiary) => (
                  <DeviceDetail
                    className="rm-beneficiary-account__item"
                    key={beneficiary.id}
                    descText={cn(
                      Util.safeText(beneficiary.account_number),
                      "•",
                      Util.safeText(beneficiary.bank)
                    )}
                    titleText={beneficiary.account_name}
                  />
                ))
              ) : (
                <TransferEmptyScreen
                  height="30rem"
                  noMascot
                  subTitle="No saved external beneficiary to display"
                />
              )}
            </div>
          </TabComponent.Content>
        </TabComponent>
      </div>
    </ActionModal>
  );
};
