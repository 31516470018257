import { PBPhysicalCardsRequestTable } from "~pb/__internals__/combined-tables/cards/physical-cards-request/tables";
import { usePersonalCardRequestFilter } from "~pb/__internals__/internal-hooks/cards";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { GLOBAL_PER_PAGE } from "@/constants";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";

export const PersonalPhysicalCardsRequest = () => {
  const { filterArgs, filterProps, pagination, ExportModal, onExport } =
    usePersonalCardRequestFilter();
  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetPhysicalCardsRequestQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      status: filterArgs.status,
      email: pagination.searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (!data || isLoading) return <TransferEmptyScreen loading={joinedLoader} />;

  return (
    <>
      <ExportModal />
      <SmartFilter
        searchTitle="Search by email"
        searchIsEmailOnly
        {...SmartFilterProps}
        {...filterProps}
        onExport={onExport}
      />
      {data.requests.length > 0 ? (
        <PBPhysicalCardsRequestTable
          ctx="page"
          data={data.requests}
          redactedFn={redactedFn}
          refetch={refetch}
        />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} />
      )}
    </>
  );
};
