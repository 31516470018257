import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import {
  mapTransfersPagination,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWithDayJs, TypeIs } from "@/utils/helpers";
import { GENERAL_CLASSES, GLOBAL_PER_PAGE } from "@/constants";
import { iife } from "@/utils/general";
import { PersonalBankTransferTable } from "~pb/__internals__/combined-tables/transfers-table/index";
import { BANK_TRANSFER_STATUS_FILTERS } from "~pb/__internals__/pb-constants";
import { usePbBankTransferExport } from "../../__internals__/internal-hooks/transfers";
import { SCHEDULED_TRANSACTION_TYPES } from "../__internals__/constants";
import { PersonalScheduledBillsTable } from "../../__internals__/combined-tables/scheduled-tx-table/bills-table";


const statusFilters = BANK_TRANSFER_STATUS_FILTERS;


export const BillsPage = ({ type }: { type: keyof typeof SCHEDULED_TRANSACTION_TYPES }) => {
  const { filterArgs, filterProps, pagination } = useSmartFilterCustomFilter(
    [statusFilters]
  );
  const { currentPage, searchQuery } = pagination;


  const { end_date, start_date } = iife(() => {
    const { endDate, startDate } = filterArgs;

    const start_date = startDate ? formatWithDayJs(startDate, "YYYY-MM-DD") : undefined;
    const end_date = endDate ? formatWithDayJs(endDate, "YYYY-MM-DD") : undefined;

    return { start_date, end_date };
  });

  
  const { data, isLoading, isFetching, refetch } = personalReduxAPI.useGetScheduledTransactionsQuery({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    search: searchQuery,
    end_date,
    start_date,
    type: SCHEDULED_TRANSACTION_TYPES[type],
    status: filterArgs.status as string,
  });



  const { SmartFilterProps } = useSmartFilterProps(
    data ? mapTransfersPagination(data) : undefined,
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  const { onExport, ExportModal } = usePbBankTransferExport();

  if (isLoading || !data)
    return (
      <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP, "")}>
        <EmptyScreen loading={isLoading} />
      </div>
    );

  return (
    <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP)}>
      <SmartFilter
        {...SmartFilterProps}
        {...filterProps}
        onExport={onExport}
        hideExport={true}
      />
         {data.payments.length > 0 ? (
  <PersonalScheduledBillsTable
  ctx="page"
  type={type}
  data={TypeIs.any(data?.payments) as  PersonalScheduledPayments}
  redactedFn={redactedFn}
  refetch={refetch}
/>
      ) : (
        <TransferEmptyScreen />
      )}

      <ExportModal />
    </div>
  );
};
