import {
  UsersBills,
  UsersCards,
  UsersCompliance,
  UsersDeposits,
  UsersOverdraft,
  UsersSavings,
  UsersTransactions,
  UsersTransfers,
  UserDisputes,
} from "~pb/users/single-user/pages";
import PersonalSingleUser from "~pb/users/single-user";
import { PersonalUsers } from "~pb/users";

export const personal_user_routes: RouteArray[] = [
  { path: "/personal-users", element: PersonalUsers },
  {
    path: "/personal-users/:email",
    element: PersonalSingleUser,
    children: [
      { path: "", element: UsersTransactions },
      { path: "transactions", element: UsersTransactions },
      { path: "transfers", element: UsersTransfers },
      { path: "bills", element: UsersBills },
      { path: "savings", element: UsersSavings },
      { path: "overdraft", element: UsersOverdraft },
      { path: "deposits", element: UsersDeposits },
      { path: "cards", element: UsersCards },
      { path: "compliance", element: UsersCompliance },
      { path: "disputes", element: UserDisputes },
    ],
  },
];
