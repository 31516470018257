import { filterAPIQueryParams } from "@/utils/helpers";
import { BusinessBuilder } from "./api";
import { BusinessAPI } from "@/utils/axios/business";
import { APIResponseHandler } from "@/utils/api";

interface GetAllBankTransfers extends BasePaginationPayload {
  email?: SN;
}
interface GetBulkTransfers extends BasePaginationPayload {
  trx_ref?: SN;
  bulk_transfer_reference?: string;
  transactiion_type?: SN;
}

interface GetHandlerArgs<T> {
  route: string;
  params: T;
  baseUrl?: string;
  objectKey?: string;
}

const enableJoinBusiness = (params: any) => {
  return { ...params, join_businesses: "yes" };
};

export const getHandler = async <T extends BasePaginationPayload>(
  args: GetHandlerArgs<T>
) => {
  const { params, route, baseUrl = "transfer", objectKey } = args;

  try {
    const response = await BusinessAPI.get<APIResponse<any>>(`${baseUrl}/${route}`, {
      params: filterAPIQueryParams(params),
    });
    APIResponseHandler.tryHandler({ response });

    if (objectKey) return { data: response.data.data[objectKey] };

    return { data: response.data.data };
  } catch (error) {
    APIResponseHandler.catchHandler(error);
    throw error;
  }
};

type Response = GenericBusinessTransactionResponse["transfers"];

type TransferIssuesResponse = ResponseWithPagination<GetBusinessDisputedTransferModel[]>;

type DeductedDepositResponse = ResponseWithPagination<
  GetBusinessAllDeductedDepositsModel[]
>;

type TransferSummary = ResponseWithPagination<GetBusinessTransferSummaryModel[]>;

interface GetBulkTransferDetails extends BasePaginationPayload {
  business_email: string;
  bulk_reference: string;
}

export const BusinessTransferAPI = (builder: BusinessBuilder) => {
  return {
    getAllBankTransfers: builder.query<Response, GetAllBankTransfers>({
      queryFn: (params) =>
        getHandler({
          params: enableJoinBusiness(params),
          route: "get_or_filter_transfers_for_a_business",
          objectKey: "transfers",
        }),
    }),
    getAllBulkTransfers: builder.query<Response, GetBulkTransfers>({
      queryFn: (params) =>
        getHandler({
          params: enableJoinBusiness(params),
          route: "get_bulk_transactions_for_businesses",
          objectKey: "transactiions",
          baseUrl: "transaction",
        }),
    }),
    getAllInternalTransfers: builder.query<Response, GetAllBankTransfers>({
      queryFn: (params) =>
        getHandler({
          params: enableJoinBusiness(params),
          route: "get_or_filter_internal_transfers_for_a_business",
          objectKey: "transfers",
        }),
    }),
    getAllTransferIssues: builder.query<TransferIssuesResponse, BasePaginationPayload>({
      queryFn: (params) =>
        getHandler({
          params: enableJoinBusiness(params),
          route: "get_or_filter_non_successful_transfers",
          objectKey: "transfers",
        }),
      // providesTags: ["BusinessAPI.getAllTransferIssues"],
    }),
    getAllDeductedDeposits: builder.query<DeductedDepositResponse, BasePaginationPayload>(
      {
        queryFn: (params) =>
          getHandler({
            params: enableJoinBusiness(params),
            route: "get_or_filter_system_deductions_and_credits",
            objectKey: "deduction_or_credit_records",
            baseUrl: "transaction",
          }),
        // providesTags: ["BusinessAPI.getAllDeductedDeposits"],
      }
    ),
    getAllTransferSummary: builder.query<TransferSummary, BasePaginationPayload>({
      queryFn: (params) =>
        getHandler({
          params,
          route: "get_or_filter_transfer_summary",
          objectKey: "transfers",
        }),
    }),
    getBulkTransferDetails: builder.query<
      GetBusinessBulkTransferResponse,
      GetBulkTransferDetails
    >({
      queryFn: (params) =>
        getHandler({
          params,
          route: "fetch_bulk_transfer_details",
          baseUrl: "bulk_transaction",
        }),
    }),
  };
};
