import "@/components/common/complianceCards/styles/index.css";
import { icons } from "@/assets/icons";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { Util } from "@/utils/utility";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import { formatWord } from "@/utils/formatWord";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";

type Meta = { label: string; app: string; type?: string };

export const ComplianceEmptyState = ({ app, label }: Meta) => (
  <div className="compliance-card__empty">
    <div className="compliance-card__inner-title">
      <p className="">{label}</p>
    </div>

    <div className="compliance-card__empty-content-wrap">
      <figure className="">{icons.compliance_image_placeholder}</figure>
      <div className="empty-content-wrap__item">
        <h6>{`${label} hasn't been Verified`}</h6>
        <p>{` This user is yet to verify their ${label} on the ${app} Platform`}</p>
      </div>
    </div>
  </div>
);

type ImageData = {
  src: string;
  alt: string;
  /** "biz-compliance-results__image-preview" */
  containerClassName?: string;
};

interface PersonalComplianceCardsProps {
  meta: Meta;
  data?: Partial<Record<string, SN>> | null;
  imgData?: ImageData[];
  onRevokeClick?(): void;
  loading?: boolean;
  isVerified: boolean;
}

export const PersonalComplianceCards = (props: PersonalComplianceCardsProps) => {
  const { meta, data, imgData, onRevokeClick, loading, isVerified } = props;

  const { RedactedTable } = renderRedactedComponent(loading ?? false);

  const isAddress = meta?.type === "Address";

  return (
    <div className="compliance-card">
      {!data ? (
        <ComplianceEmptyState {...meta} />
      ) : (
        <div className="compliance-main-content">
          <div className="compliance-main-content__main-content-wrap">
            <div className="compliance-main-content__inner-title">
              <p className="">{meta.label}</p>
              {onRevokeClick && (
                <RavenButton
                  className="revoke-btn"
                  color="error-light"
                  label={
                    isVerified
                      ? `Revoke ${meta.type ?? ""} Verification`
                      : isAddress
                      ? Boolean(data?.rejected_at)
                        ? "Revoked"
                        : `Revoke ${meta.type ?? ""} Verification`
                      : "Revoked"
                  }
                  onClick={() => {
                    if (
                      isAddress ? !Boolean(data?.rejected_at) || isVerified : isVerified
                    ) {
                      onRevokeClick();
                    }
                  }}
                />
              )}
            </div>

            <div className="compliance-main-content__table">
              {imgData && (
                <ImagePreview
                  imgData={imgData.map((rest) => ({
                    ...rest,
                    containerClassName:
                      rest?.containerClassName ?? "biz-compliance-results__image-preview",
                  }))}
                />
              )}

              <InlineDetails
                direction={""}
                title={""}
                className="compliance-card__content-table no-shadow"
                amount={0}
                simple
                onClick={() => {}}
                content={Object.entries(data ?? {}).map(([key, value]) => {
                  return {
                    label: RedactedTable(formatWord(key)),
                    value: RedactedTable(Util.safeText(value)),
                  };
                })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
