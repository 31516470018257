export const LOADING_WORDS = [
  "🧘 Doing some yoga for your request",
  "🔢 Calculating the transaction length",
  "🧚 Summoning digital fairies to speed things up",
  "☕ Brewing a cup of virtual coffee for the journey",
  "🤖 Tickling the servers to wake them up",
  "🐹 Summoning a team of hamsters to power the servers",
  "✨ Polishing the code to make it shine",
  "🤝 Convincing the bits and bytes to cooperate",
  "🧠 Practicing mindfulness while processing",
  "🤣 Tickling the algorithms to make them laugh",
  "🚀 Hitting the turbo boost button",
  "☁️ Channeling the power of the cloud",
  "💃 Dancing with the data",
  "🌐 Sending positive vibes to the internet",
  "🔄 Spinning the wheel of progress",
  "💬 Whispering sweet nothings to the database",
  "🖐️ High-fiving the network connections",
  "🌊 Riding the digital wave",
  "🌱 Nurturing the pixels with care",
  "⛴️ Captaining the ship through cyberspace",
  "😤 Taking some abido shaker so I can shake the servers",
];
