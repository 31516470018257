import "./style/index.css";
import React, { useEffect } from "react";
import { GlobalStyles, lightTheme } from "../../../../utils/themes/theme";
import { useDarkMode } from "../../../../hooks/useDarkMode";
import { ThemeProvider } from "styled-components";
import ravenLoader from "../../../../assets/images/raven-loader.gif";
import HeaderNavComponent from "../headerNav";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../../../redux/actions/actions";
import SideMenuComponent from "../sidebar/index";
import { cn } from "@/utils/colorConvert";
import { useProductTheme } from "@/context/ProductTheme";
import PrivateRoute from "@/routes/PrivateGuard";
import { getCookie } from "@/utils/helper/Helper";
import { ACTION_BAR_ID, SESSION_COOKIE_NAME } from "@/constants";
import { useNavigate } from "react-router-dom";

interface MyComponentProps extends IClass {
  children?: React.ReactNode;
  loading?: boolean;
}

const DashboardLayout = ({ children, loading, className }: MyComponentProps) => {
  const { isMenuOpen } = useSelector((state: RootState) => state.general);
  const navigate = useNavigate();
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const { productTheme: product } = useProductTheme();

  const dispatch = useDispatch();

  function toggleNav() {
    dispatch(toggleMenu(false));
  }

  useEffect(() => {
    document.body.style.opacity = "0";
    const hasCookie = getCookie(SESSION_COOKIE_NAME);

    if (!hasCookie) {
      navigate("/login");
      document.body.style.opacity = "1";
    }

    document.body.style.opacity = "1";
  }, []);

  return (
    <PrivateRoute>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />

        <div
          className={cn(
            "dashboard-layout-wrap",
            theme === "dark" && "buggies-dark",
            `${product}-bugggis`
          )}
        >
          {/* sidenav wrap start */}

          <div
            className={cn(
              "side-nav-wrap mobile-nav-thread",
              isMenuOpen && "tablet-collapsed  show-mobile-nav "
            )}
          >
            <SideMenuComponent product={product} theme={theme} />
          </div>
          {loading && (
            <figure className="loader-animation">
              <img src={ravenLoader} alt="" />
            </figure>
          )}
          {/* sidenav wrap end */}
          {/* main body wrap start */}
          <div className={cn("main-body-wrap", !isMenuOpen && "expanded")}>
            {/* header wrap start */}

            <div
              className={cn(
                "header-wrap white-base-black-bg",
                window.location.pathname.includes("dashboard-onboarding-") &&
                  "onboarding-header"
              )}
            >
              <div className="container-wrap">
                <HeaderNavComponent theme={theme} toggleTheme={toggleTheme} />
              </div>
            </div>

            <div id={ACTION_BAR_ID}></div>

            {isMenuOpen && (
              <div onClick={toggleNav} className="mobile-invisible-close"></div>
            )}

            {/* header wrap end */}
            {/* main wrap start */}

            <div className={cn("main-wrap main-wrap-bg container-wrap", className)}>
              {children}
            </div>
            {/* main wrap end */}
          </div>
          {/* main body wrap end */}
        </div>
      </ThemeProvider>
    </PrivateRoute>
  );
};

export default DashboardLayout;
