import { ClickAbleEmail } from "@/components/common/clickable-row";
import InlineDetails, { InlineDetailsContent } from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { useProductTheme } from "@/context/ProductTheme";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import {
  createInlineDetailsContent,
  formatNumberToCurrency,
  getThemePrimaryColor,
} from "@/utils/helpers";
import ImagePlaceholder from "@/assets/images/image-placeholder.png";
import { cn } from "@/utils/colorConvert";

interface LimitRequestModalProps {
  onCancel(): void;
  row: PersonalLimitRequests;
  onReject(): void;
  onAccept(): void;
}

export const LimitRequestDetailsModal = (props: LimitRequestModalProps) => {
  const { onCancel, row, onAccept, onReject } = props;

  const { productTheme } = useProductTheme();

  const {
    limit_requested,
    reason,
    single_transfer_limit_requested,
    daily_transfer_limit_requested,
    status,
  } = row;

  const data = {
    email: <ClickAbleEmail email={row.email} />,
    limit_requested: formatNumberToCurrency(limit_requested),
    single_transfer_limit: formatNumberToCurrency(single_transfer_limit_requested),
    daily_transfer_limit: formatNumberToCurrency(daily_transfer_limit_requested),
    reason,
    status: formatStatus(status),
    date: formatDateTime(row.created_at),
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={onAccept}
      altCancelFunc={onReject}
      title="Limit Request Details"
      btnColor={getThemePrimaryColor(productTheme)}
      actionText="Verify"
      cancelText="Reject Request"
      className="dispute-modal limit-request-details-modal__wrapper"
      big
    >
      <div className="limit-request-details-modal hide-scrollbar">
        <div className="limit-request-signature-row">
          <div className="limit-request-span-text">User's Signature</div>
          <img src={row.signature ?? ImagePlaceholder} alt="" />
        </div>
        <div className="expense__details">
          <InlineDetails
            direction=""
            title={"Limit Request Details"}
            simple
            className="personal-limit-details-modal"
            amount={0}
            onClick={() => {}}
            content={createInlineDetailsContent(data)}
          />
        </div>
      </div>
    </ActionModal>
  );
};

interface ActionModalLeftImageProps {
  image?: string;
  badgeText: string;
  content: InlineDetailsContent[];
  className?: string;
}

export const ActionModalContentWithImage = (props: ActionModalLeftImageProps) => {
  const { badgeText, content, image, className } = props;
  return (
    <div className={cn("limit-request-details-modal hide-scrollbar", className)}>
      <div className="limit-request-signature-row">
        <div className="limit-request-span-text">{badgeText}</div>
        <img src={image ?? ImagePlaceholder} alt="" />
      </div>
      <div className="expense__details">
        <InlineDetails
          direction=""
          title={"Limit Request Details"}
          simple
          className="personal-limit-details-modal"
          amount={0}
          onClick={() => {}}
          content={content}
        />
      </div>
    </div>
  );
};
