import { isEmptyObject } from "../../../utils/helpers";

export const isEmpty = <T>(data: T) => {
  return (
    isEmptyObject((data as Object) ?? []) ||
    !data ||
    data === undefined ||
    !Array.isArray(data) ||
    Array.from(data).length < 1
  );
};
