import { Trigger } from "@radix-ui/react-tabs";
import {
  TabElementDefaults,
  TabPropsWithDropdown,
  TabWithLabel,
  getSelectedTabValue,
} from "../shared";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useEffect, useRef, useState } from "react";
import "@/components/common/dropdown/style/index.css";
import { cn } from "@/utils/colorConvert";
import { RavenDropDown } from "@ravenpay/raven-bank-ui";
import "./style/index.css";

interface Props {
  tab: Omit<
    Required<TabPropsWithDropdown>,
    "compoundName" | "isParent" | "preventTabLabelChanging"
  >;
  selectedDropdown?: TabElementDefaults;
  pageSlug?: string;
}

export const TabWithDropdown = (props: Props) => {
  const { tab, selectedDropdown, pageSlug } = props;
  const { label, value, dropdown } = tab;

  const initialSelected = () => {
    return pageSlug === value ? selectedDropdown : undefined;
  };

  const [show, setShow] = useState(false);
  const wrapperRef = useOnClickOutside<HTMLDivElement>(() => {
    setShow(false);
  });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState(initialSelected());

  const handleChange = (item: TabWithLabel) => {
    const { label } = item;
    setSelected(item);
    if (dropdown.onChange) {
      const selectedTab = dropdown.tabs.find((curr) => {
        if (typeof curr === "string") {
          return curr === label;
        }

        return curr.label === label;
      });

      if (selectedTab) {
        dropdown.onChange(selectedTab);
      }
    }
    setShow(false);
  };

  const selectedLabel = getSelectedTabValue(selected, "label");

  useEffect(() => {
    setSelected(initialSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSlug]);

  return (
    <div
      style={
        {
          "--trigger-max-width": dropdown.maxWidth ?? "18rem",
        } as React.CSSProperties
      }
      ref={wrapperRef}
      className={cn("tabcomponent__dropdown", dropdown.className)}
    >
      <Trigger
        key={value}
        ref={buttonRef}
        className={cn(
          "tabcomponent__trigger",
          "tabcomponent__dropdown__trigger",
          !show && "unflip-carret"
        )}
        value={value}
        onFocus={() => setShow(true)}
        onClick={() => setShow(true)}
        onDoubleClick={() => setShow(false)}
      >
        <div className="tabcomponent__trigger__label">
          {label}
          {selectedLabel ? (
            <span className="selected-dropdown-label"> : {selectedLabel}</span>
          ) : (
            ""
          )}
        </div>
        <div className="tabcomponent__trigger__icon">
          <figure className="tabcomponent__trigger__icon__box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="none"
              className="tabcomponent__dropdown__icon"
            >
              <path
                d="M4.5 6.75L9 11.25L13.5 6.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </figure>
        </div>
      </Trigger>
      <div
        className={cn(
          "pb-dropdown__portal",
          !show ? "pb-dropdown__portal--closed" : "pb-dropdown__portal--open"
        )}
      >
        <RavenDropDown
          className="pb-dropdown-content"
          list={
            dropdown.tabs.map((ele) => {
              if (typeof ele === "string") return { label: ele };

              return { label: ele.label };
            }) as any
          }
          onAction={(e) => {
            setShow(false);
            handleChange(e);
          }}
        />
      </div>
    </div>
  );
};
