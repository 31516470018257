import {
  PersonalBankTransfersPage,
  PersonalTransferIssues,
  PersonalStalledTransferIssues,
} from "~pb/transfers/page";
import { PersonalTransfer } from "~pb/transfers";

export const personal_transfer_routes: RouteArray[] = [
  {
    path: "/personal-transfer",
    element: PersonalTransfer,
    children: [
      { path: "", element: PersonalBankTransfersPage },
      { path: "transfers", element: PersonalBankTransfersPage },
      { path: "transfers-issues", element: PersonalTransferIssues },
      { path: "stalled-transfers", element: PersonalStalledTransferIssues },
    ],
  },
];
