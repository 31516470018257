import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../shared";
import { detectProvider } from "@/utils/helper/DetectNetwork";
import {
  getAirtimeDataElectricityStatus,
  isDataBillRow,
} from "~pb/__internals__/combined-tables/bills/utils";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { LONG_TRIM_LENGTH, STRING_TRIM_LENGTH } from "@/constants";
import { Util } from "@/utils/utility";
import { CopyText } from "@/components/common/copyCheck";
import { BillerStack } from "@/components/common/stacks";
import TwinValue from "@/components/common/twinValue";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { useState } from "react";
import { PersonalAirtimeOrDataDetailsModal } from "./modals/airtime-or-data";
import { autoBillersLogo } from "@/constants/auto-billers-logo";

type Props = CreateTableRowProps<{ data: PersonalAirtimeOrData[] }>;

export const PersonalAirtimeOrDataTable = (props: Props) => {
  const { RedactedTable } = props.redactedFn;
  const [record, setRecord] = useState<PersonalAirtimeOrData>();
  const { renderSafeModal } = useDynamicModal(record);

  return (
    <RavenTable
      className="table__main"
      headerList={[
        "REQUEST ID",
        "PROVIDER",
        "AMOUNT",
        "MOBILE NUMBER",
        "DATE INITIATED",
        "STATUS",
      ]}
    >
      {props.data.map((record) => {
        const { id, created_at, status, amount, request_id, phone_number, currency } =
          record;

        const provider = detectProvider(phone_number);

        const _status = getAirtimeDataElectricityStatus(status);

        const _amount = formatNumberToCurrency(amount, currency);

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(
              request_id ? (
                <CopyText
                  text={Util.safeText(request_id, STRING_TRIM_LENGTH)}
                  textToCopy={request_id}
                />
              ) : (
                "--"
              )
            )}
            two={RedactedTable(
              <BillerStack biller={autoBillersLogo(provider)} provider={provider} />
            )}
            three={RedactedTable(
              isDataBillRow(record) ? (
                <TwinValue
                  one={_amount}
                  two={Util.safeText(record.plan, LONG_TRIM_LENGTH)}
                />
              ) : (
                _amount
              )
            )}
            four={RedactedTable(phone_number ? <CopyText text={phone_number} /> : "--")}
            five={RedactedTable(formatDateTime(created_at))}
            six={RedactedTable(formatStatus(_status))}
            onRowClick={() => setRecord(record)}
          />
        );
      })}

      {renderSafeModal(
        <PersonalAirtimeOrDataDetailsModal
          record={TypeIs.safe(record)}
          onCancel={() => setRecord(undefined)}
          ctx={props.ctx}
        />,
        record
      )}
    </RavenTable>
  );
};
