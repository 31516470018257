import { formatWord } from "@/utils/formatWord";
import { Validator } from "@/utils/validator";
import { useParams } from "react-router-dom";

interface UseDynamicQueryParamArgs<T> {
  /** make sure dynamic name's generally make sense, e.g use user_id vs id */
  keys: T | T[];
}

export const useDynamicQueryParams = <T extends string>(
  args: UseDynamicQueryParamArgs<T>
) => {
  const params = useParams();
  const { keys } = args;

  const createValidatorSchema = () => {
    if (!Array.isArray(keys)) return { [keys]: Validator.string(formatWord(keys)) };

    const schema: Record<string, any> = {};

    keys.forEach((key) => {
      schema[key] = Validator.string(formatWord(key));
    });

    return schema;
  };

  const UserIDQuerySchema = Validator.object(createValidatorSchema());

  const query = UserIDQuerySchema.parse(params);

  return query as Record<T, string>;
};
