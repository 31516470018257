import "~/common/used-by-details/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatStatus } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { XStack, YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import Copy from "@/components/common/copyCheck";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import { isEven } from "@/utils/helpers";
import { PageLayoutTopBackButton } from "@/components/common/atoms";

const status = ["successful", "pending", "failed"];

export const AtlasVirtualAccountDetails = () => {
  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Account Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img product-single-details-page__avatar">
              {icons.send_04_double_filled}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  <MainContentCol.Item title="Account Name">
                    PayM • Adedipo Blessing
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Cunstomer  Name">
                    Adedipo Blessing
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Bank ">Wema Bank</MainContentCol.Item>
                  <MainContentCol.Item title="Customer Email">
                    adedipoblessing@gmail.com
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Account Number">
                    <XStack style={{ alignItems: "center" }} gap={1}>
                      <span>2135876118</span>
                      <Copy tableCopy text="2135876118" />
                    </XStack>
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Date Created">
                    26, October 2021
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Account Type">Temporal</MainContentCol.Item>
                  <MainContentCol.Item title="Balance">₦420,000.00</MainContentCol.Item>
                </MainContentCol>
              </div>
              <div>
                <div className="top-wrap__actions">
                  <IconButton
                    borderColor="#EA872D"
                    text="Archive Account"
                    icon="archive_orange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-user-details__table-wrap">
          <YStack>
            <SmartFilter />
          </YStack>
          <RavenTable
            headerList={[
              "REFERENCE",
              "DESCRIPTION",
              "AMOUNT",
              "TRANSACTION DATE",
              "STATUS",
            ]}
          >
            {status.map((status, idx) => {
              const credit = isEven(idx);

              return (
                <RavenTableRow
                  key={status}
                  one={
                    <Txreference
                      hideCopy
                      direction={isEven(idx) ? "credit" : "debit"}
                      reference="7685493023456..."
                    />
                  }
                  two={
                    <TwinValue
                      one={`Money ${credit ? "Received" : "Sent"}`}
                      two={
                        credit
                          ? "Your received a deposit of ₦250,000"
                          : " You sent ₦120,000 to Olakunle Temitay..."
                      }
                    />
                  }
                  three="₦250,000.00"
                  four="5 Sept, 2022 • 5:48PM"
                  five={formatStatus(status)}
                />
              );
            })}
          </RavenTable>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
