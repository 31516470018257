import { AvatarWithTwinValue } from "@/components/common/avatar";
import { CopyText } from "@/components/common/copyCheck";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { usePagination } from "@/hooks/usePagination";
import { useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import {
  RavenButton,
  RavenCheckBox,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { useState } from "react";
import { BusinessAPIService } from "@/utils/api-methods";
import { XStack } from "@/components/common/stacks";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";

export const BusinessTransferIssuesPage = () => {
  const pagination = usePagination();
  const [filterValue, setFilterValue] = useState({});
  const { currentPage, searchQuery } = pagination;
  const { data, isLoading, isFetching, refetch } =
    businessReduxAPI.useGetAllTransferIssuesQuery({
      current_page: currentPage,
      search_term: searchQuery,
      ...filterValue,
    });

  const exportApi = businessReduxAPI.useGetAllTransferIssuesQuery({
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
    paginate: "no",
  });
  const [isMutating, setIsMutating] = useState<true>();
  const [selectedIds, setSelectedIds] = useState<SN[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const { SmartFilterProps } = useSmartFilterProps(data, pagination);

  const joinLoader = isMutating ?? isFetching;

  const { renderRedacted } = renderRedactedComponent(joinLoader);

  const handleStatusChange = async () => {
    setIsMutating(true);
    const res = await BusinessAPIService.recheckTransferStatusInBulk(selectedIds);
    if (res) refetch();
    setIsMutating(undefined);
    setSelectedIds([]);
    setIsAllChecked(false);
  };

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "business_email",
    "ACCOUNT NUMBER": "account_number",
    "ACCOUNT NAME": "account_name",
    AMOUNT: "amount",
    CREATED: "created_at",
    STATUS: ["status", "transactions"],
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: exportApi.data?.data as any,
    loading: isLoading,
    valuePair,
    title: "Business Transfer Issues(Bugiss)",
    filterValue,
    dispatchFunction: exportApi.refetch,
  });
  const [exportTriggered, triggerExport] = useState(false);
  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  if (isLoading) return <TransferEmptyScreen height="91%" className="mt-20" loading />;
  if (!data) return <TransferEmptyScreen />;

  const handleCheckBoxChange = (id: SN) => {
    const unselectedIds = selectedIds.filter((ref) => ref !== id);
    setIsAllChecked(false);
    if (unselectedIds?.length < selectedIds?.length) setSelectedIds(unselectedIds);
    else setSelectedIds((old) => [...old, id]);
  };

  const isCheckActive = selectedIds?.length > 0;

  const handleSelectAll = () => {
    if (isAllChecked) setSelectedIds([]);
    else setSelectedIds(data.data.map(({ transfer_id }) => transfer_id));

    setIsAllChecked(!isAllChecked);
  };

  return (
    <div className="h-empty-screen mt-20">
      <SmartFilter
        hideFilter={isCheckActive}
        hideExport={isCheckActive}
        dynamicNode={
          isCheckActive ? <RecheckStatusButton onClick={handleStatusChange} /> : null
        }
        {...SmartFilterProps}
        onFilter={(e: any) => {
          setFilterValue(Util.filterStruct(e) as any);
          // reset current page to 1 on filter change
          pagination.setCurrentPage(1);
        }}
        onExport={() => {
          triggerExport(true);
          toggleModal("Export Transfer Issues");
        }}
      />
      {data.data?.length > 0 ? (
        <RavenTable
          headerList={[
            <XStack gap={1} style={{ alignItems: "center" }}>
              <div style={{ opacity: joinLoader ? 0 : 1 }}>
                <RavenCheckBox
                  checked={isAllChecked}
                  id={69247}
                  onChange={handleSelectAll}
                  color="black-light"
                />
              </div>
              <span> BUSINESS DETAILS</span>
            </XStack>,
            "Reference",
            "Recipients",
            "Amount",
            "Date Initiated",
            "Action",
          ]}
        >
          {data.data.map((record, idx) => {
            const {
              account_name,
              account_number,
              trx_ref,
              status,
              currency,
              amount,
              bank,
              created_at,
              business_name,
              business_email,
              transfer_id,
            } = record;

            const isActive = Boolean(selectedIds.find((id) => id === transfer_id));

            return (
              <RavenTableRow
                key={idx}
                one={
                  <XStack gap={1} style={{ alignItems: "center" }}>
                    <div style={{ opacity: joinLoader ? 0 : 1 }}>
                      <RavenCheckBox
                        id={Number(transfer_id)}
                        onChange={(e) => handleCheckBoxChange(transfer_id)}
                        color="black-light"
                        checked={isActive}
                      />
                    </div>
                    {renderRedacted(
                      <AvatarWithTwinValue
                        name={business_name}
                        one={Util.safeValue(business_name)}
                        two={Util.safeValue(business_email)}
                      />,
                      "AvatarWithValue"
                    )}
                  </XStack>
                }
                two={renderRedacted(<CopyText text={trx_ref} />, "Table")}
                three={renderRedacted(
                  <TwinValue
                    one={Util.safeValue(account_name)}
                    two={cn(Util.safeValue(account_number), "•", Util.safeValue(bank))}
                  />,
                  "Table"
                )}
                four={renderRedacted(formatNumberToCurrency(amount, currency), "Table")}
                five={renderRedacted(formatDateTime(created_at), "Table")}
                six={renderRedacted(
                  formatStatus(detectStatus(Number(status), "transactions")),
                  "Table"
                )}
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen inner loading={isFetching} />
      )}
    </div>
  );
};

const RecheckStatusButton = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="recheck-btn" color="orange-dark" onClick={onClick}>
      Recheck Status
    </RavenButton>
  );
};
