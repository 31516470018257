import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BusinessAPI } from "@/utils/axios/business";
import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { createDefaultReduxPaginationState, filterAPIQueryParams } from "@/utils/helpers";
import { toggleRedact } from "@/redux/actions/actions";
import { BusinessBuilder } from "./api";
import { singleCardInitial } from "../initials/cards";

export const getBusinessCards = createAsyncThunk<unknown, BasePaginationPayload>(
  "/get-business-cards",
  async (params, thunkAPI) => {
    try {
      const url = "cards/fetch_or_search_all_cards_with_businesses";

      type Response = APIResponse<BusinessCardDetailResponse>;

      const res = await BusinessAPI.get<Response>(url, {
        params: filterAPIQueryParams(params),
      });

      const status = APIResponseHandler.tryHandler({
        response: res,
        hideErrorToast: true,
        hideSuccessToast: true,
      });

      thunkAPI.dispatch(toggleRedact(false));
      if (!status) return thunkAPI.rejectWithValue(res.data);

      thunkAPI.dispatch(SET_BUSINESS_CARDS(res.data.data.card_details));
      return res.data.data;
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      APIResponseHandler.catchHandler(error, thunkAPI);
    }
  }
);

export const getBusinessCardDetails = createAsyncThunk<unknown, BasePaginationPayload>(
  "/get-business-cards",
  async (params, thunkAPI) => {
    try {
      const url = `cards/get_single_virtual_card?business_id=${params.business_id}&card_id=${params.card_id}`;

      type Response = APIResponse<BusinessCardDetailResponse>;

      const res = await BusinessAPI.get<Response>(url);

      const status = APIResponseHandler.tryHandler({
        response: res,
        hideErrorToast: true,
        hideSuccessToast: true,
      });

      thunkAPI.dispatch(toggleRedact(false));
      if (!status) return thunkAPI.rejectWithValue(res.data);
      thunkAPI.dispatch(SET_SINGLE_CARD(res.data.data));
      return res.data.data;
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      APIResponseHandler.catchHandler(error, thunkAPI);
    }
  }
);

export const freezeBusinessCard = createAsyncThunk<unknown, BasePaginationPayload>(
  "/get-business-freeze",
  async (params, thunkAPI) => {
    try {
      const url = `cards/freeze_a_virtual_card?business_id=${params.business_id}`;

      type Response = APIResponse<BusinessCardDetailResponse>;

      const res = await BusinessAPI.post<Response>(url, {
        card_id: params.card_id,
      });

      const status = APIResponseHandler.tryHandler({
        response: res,
        hideSuccessToast: false,
      });

      thunkAPI.dispatch(toggleRedact(false));
      if (!status) return thunkAPI.rejectWithValue(res.data);
      // thunkAPI.dispatch(SET_BUSINESS_CARDS(res.data.data.card_details));
      return res.data.data;
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      APIResponseHandler.catchHandler(error, thunkAPI);
    }
  }
);

export const unfreezeBusinessCard = createAsyncThunk<unknown, BasePaginationPayload>(
  "/un_freeze_a_virtual_card",
  async (params, thunkAPI) => {
    try {
      const url = `cards/un_freeze_a_virtual_card?business_id=${params.business_id}`;

      type Response = APIResponse<BusinessCardDetailResponse>;

      const res = await BusinessAPI.post<Response>(url, {
        card_id: params.card_id,
      });

      const status = APIResponseHandler.tryHandler({
        response: res,
        hideSuccessToast: false,
      });

      thunkAPI.dispatch(toggleRedact(false));
      if (!status) return thunkAPI.rejectWithValue(res.data);
      // thunkAPI.dispatch(SET_BUSINESS_CARDS(res.data.data.card_details));
      return res.data.data;
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      APIResponseHandler.catchHandler(error, thunkAPI);
    }
  }
);

const card_details = createDefaultReduxPaginationState<BusinessCardDetail[]>([]);

const initialState: BusinessCardsSlice = {
  loading: false,
  card_details,
  single_card: singleCardInitial,
};

type CardDetailThunk = BusinessCardDetailResponse["card_details"];

export const businessCardsSlice = createSlice({
  name: "business-cards-details",
  initialState,
  reducers: {
    SET_BUSINESS_CARDS: (state, action: PayloadAction<CardDetailThunk>) => {
      state.card_details = action.payload;
    },
    SET_SINGLE_CARD: (state, action) => {
      state.single_card = action.payload;
    },
  },

  extraReducers: (builder) => {
    const builderHandler = new ReduxBuilderHandler(builder, initialState);
    // builderHandler.handleStates(getBusinessCards, undefined, (state)=>{

    // } );

    // pending state
    builder.addCase(getBusinessCardDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(freezeBusinessCard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unfreezeBusinessCard.pending, (state) => {
      state.loading = true;
    });

    // fullfilled state
    builder.addCase(getBusinessCardDetails.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(freezeBusinessCard.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(unfreezeBusinessCard.fulfilled, (state) => {
      state.loading = false;
    });

    // rejected state
    builder.addCase(getBusinessCardDetails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(freezeBusinessCard.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(unfreezeBusinessCard.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { SET_BUSINESS_CARDS, SET_SINGLE_CARD } = businessCardsSlice.actions;

export default businessCardsSlice.reducer;

export const BusinessCardsAPI = (builder: BusinessBuilder) => {
  type GetCardTransactionsPayload = BasePaginationPayload & {
    card_id: SN;
    business_id: SN;
  };

  return {
    getCardTransactions: builder.query<
      BusinessCardTransactionsResponse,
      GetCardTransactionsPayload
    >({
      queryFn: async (params) => {
        try {
          const url = "cards/retrieve_a_virtual_cards_transactions";
          const response = await BusinessAPI.get(url, {
            params: filterAPIQueryParams(params),
          });
          APIResponseHandler.tryHandler({
            response,
            hideSuccessToast: true,
            hideErrorToast: true,
          });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
