import { getQueryVariable } from "@/utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { OutletTab } from "../OutletTab";

export const getSelectedTabValue = (
  tab?: TabsElement,
  key: "value" | "label" = "value"
) => {
  if (!tab) return tab as string;

  if (typeof tab === "string") return tab;

  return tab[key];
};

export type TabWithLabel = { label: string; value: string; compoundName?: string };

export type TabElementDefaults = string | TabWithLabel;

export type TabPropsWithDropdown = {
  value: string;
  label: string;
  compoundName?: string;
  isParent?: boolean;
  dropdown?: {
    tabs: TabElementDefaults[];
    onChange?(tab: TabElementDefaults): void;
    className?: string;
    maxWidth?: string;
  };
};

export type TabsElement = string | TabPropsWithDropdown;

export const isTabWithDropdown = (tab: TabsElement): tab is TabPropsWithDropdown =>
  typeof tab !== "string";

export const findActiveDropdownTab = (tabs: TabsElement[], queryKey = "type") => {
  const currentTab = getQueryVariable(queryKey);

  const lowerCase = (str: string) => str.toLowerCase();

  const getDropdownTab = (tab: TabsElement) => {
    if (!isTabWithDropdown(tab)) return;

    const selectedTab = (tab.dropdown?.tabs ?? []).find((selected) => {
      if (isTabWithDropdown(selected)) return lowerCase(selected.value) === currentTab;
      return lowerCase(selected as string) === currentTab;
    });

    return selectedTab;
  };

  const active = tabs
    .map((tab) => {
      const selected = getDropdownTab(tab);

      return selected;
    })
    .filter(Boolean);

  return active[0];
};

export const useOutletTab = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOutletTabChange = (tab: TabElementDefaults) => {
    const lowerCase = (str: string) => str.toLowerCase().replaceAll(" ", "-");

    if (isTabWithDropdown(tab)) {
      navigate(`${pathname}?type=${lowerCase(tab.value)}`);
    } else {
      navigate(`${pathname}?type=${lowerCase(tab as string)}`);
    }
  };

  const getSelectedDropDown = findActiveDropdownTab;

  return { handleOutletTabChange, getSelectedDropDown, OutletTab };
};
