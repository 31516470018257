import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { toggleRedact } from "@/redux/actions/actions";

export const fetchCompliance = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/compliance_details",
  async (payload, thunkAPI) => {
    try {
      const params = {
        range: payload?.range ?? "3",
        business_email: payload?.business_email ?? "",
        period_type: payload.period_type ?? "",
        search_term: payload.search_term ?? "",
        per_page: payload.per_page ?? 20,
        current_page: payload?.current_page ?? "",
        date_1: payload.date_1 ?? "",
        date_2: payload.date_2 ?? "",
        verification_type: payload.verification_type ?? "all",
        status: payload.filterValue ?? payload?.status,
        join_account: "yes",
      };

      const url = `businesses/compliance_details`;

      const data = await axios.get(url, {
        params,
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_COMPLIANCES([]));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_COMPLIANCES(data.data.data));
        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const verifyDeclineAddressVerification = createAsyncThunk<
  any,
  Partial<ComplianceDeclinePayload>
>("/update_address_status", async (payload, thunkAPI) => {
  try {
    const data = await axios.post(`businesses/update_address_status`, {
      status: String(payload?.status),
      business_id: String(payload.business_id),
      deny_or_revoke: String(payload.deny_or_revoke),
      address_decline_reason: String(payload.address_decline_reason),
      address_report_url: String(payload.address_report_url),
      action_to_take: payload?.action_to_take,
    });

    if (data?.data?.status === "fail") {
      thunkAPI.dispatch(toggleRedact(false));
      toast.error(data?.data?.message, {
        position: "top-right",
      });
      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      toast.success(data?.data?.message, {
        position: "top-right",
      });

      thunkAPI.dispatch(toggleRedact(false));

      return data.data.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const verifyDeclineCacVerification = createAsyncThunk<
  any,
  Partial<ComplianceDeclinePayload>
>("/update_address_status", async (payload, thunkAPI) => {
  try {
    const data = await axios.post(`businesses/update_cac_status`, {
      status: String(payload?.status),
      business_id: String(payload.business_id),
      cac_decline_reason: String(payload.cac_decline_reason),
      deny_or_revoke: String(payload.deny_or_revoke),
      action_to_take: payload?.action_to_take,
    });

    if (data?.data?.status === "fail") {
      thunkAPI.dispatch(toggleRedact(false));
      toast.error(data?.data?.message, {
        position: "top-right",
      });
      return thunkAPI.rejectWithValue(data);
    }

    if (data?.data?.status === "success") {
      toast.success(data?.data?.message, {
        position: "top-right",
      });

      thunkAPI.dispatch(toggleRedact(false));

      return data.data.data;
    }
  } catch (error: any) {
    thunkAPI.dispatch(toggleRedact(false));
    if (error.message === "Network Error") {
      toast.error(error.message, {
        position: "top-right",
      });
    }
    if (error.response.data.status === "fail" && error.response.status !== 401) {
      return thunkAPI.rejectWithValue(error);
    }
  }
});

export const verifyDeclineBVN = createAsyncThunk<any, Partial<ComplianceDeclinePayload>>(
  "/update_bvn_Status",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`businesses/update_bvn_Status`, {
        status: String(payload?.status),
        business_id: String(payload.business_id),
        deny_or_revoke: String(payload.deny_or_revoke),
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        thunkAPI.dispatch(toggleRedact(false));

        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const verifyDeclineNIN = createAsyncThunk<any, Partial<ComplianceDeclinePayload>>(
  "/update_nin_Status",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`businesses/update_nin_Status`, {
        status: String(payload?.status),
        business_id: String(payload.business_id),
        deny_or_revoke: String(payload.deny_or_revoke),
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));
        toast.error(data?.data?.message, {
          position: "top-right",
        });
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {
          position: "top-right",
        });

        thunkAPI.dispatch(toggleRedact(false));

        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchCacDeclineReason = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_decline_reason",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `businesses/fetch_reasons_for_business_cac_decline?range=${
          payload?.range ?? "3"
        }&business_email=${payload?.business_email ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&verification_type=${
          payload.verification_type ?? "all"
        }`
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BLOCK_REASON(data.data.data));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const fetchAddressDeclineReason = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_reasons_for_address_decline",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `businesses/fetch_reasons_for_address_decline?range=${
          payload?.range ?? "3"
        }&business_email=${payload?.business_email ?? ""}&period_type=${
          payload.period_type ?? ""
        }&search_term=${payload.search_term ?? ""}&per_page=${
          payload.per_page ?? 20
        }&current_page=${payload?.current_page ?? ""}&date_1=${
          payload.date_1 ?? ""
        }&date_2=${payload.date_2 ?? ""}&verification_type=${
          payload.verification_type ?? "all"
        }`
      );

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_BLOCK_REASON(data.data.data));
        return data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: any = {
  loading: false,
  business_compliances: [],
  block_reason: [],
};

export const businessComplianceSlice = createSlice({
  name: "business-compliance",
  initialState,
  reducers: {
    SET_COMPLIANCES: (state, action) => {
      state.business_compliances = action.payload;
    },
    SET_BLOCK_REASON: (state, action) => {
      state.block_reason = action.payload;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(fetchCompliance.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyDeclineAddressVerification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyDeclineNIN.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyDeclineBVN.pending, (state) => {
      state.loading = true;
    });

    //fullfilled state changes

    builder.addCase(fetchCompliance.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineAddressVerification.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineBVN.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineNIN.fulfilled, (state) => {
      state.loading = false;
    });

    //++++++ rejected state changes ++++++

    builder.addCase(fetchCompliance.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineAddressVerification.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineBVN.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(verifyDeclineNIN.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { SET_COMPLIANCES, SET_BLOCK_REASON } = businessComplianceSlice.actions;

export default businessComplianceSlice.reducer;
