import { iife } from "@/utils/general";
import { Validator } from "@/utils/validator";
import { SyntheticEvent, useState } from "react";
import { useGetDetailsChecker } from "./details-wrapper/context";
import { APIResponseHandler } from "@/utils/api";
import { z } from "zod";
import { GENERAL_CLASSES } from "@/constants";
import { JoinedDetailsSubmitButton } from "./nin-bvn-voters-form";
import { Input } from "@/components/common/input";
import { formatWord } from "@/utils/formatWord";
import { cn } from "@/utils/colorConvert";
import { formatWithDayJs, isObjectWithValidValues } from "@/utils/helpers";
import { DetailsCheckerAPI } from "@/redux/slices/details-checker/api";

export type LICENSE_OR_PASSPORT_CTX = "license" | "passport";

interface Props {
  ctx: LICENSE_OR_PASSPORT_CTX;
}

const licenseSchema = Validator.object({
  license_number: Validator.string("License Number"),
  phone_number: Validator.string("Phone Number"),
  full_name: Validator.string("Full Name"),
  date_of_birth: Validator.string("Date of birth"),
});

const passportSchema = Validator.object({
  passport_number: Validator.string("Passport Number"),
  phone_number: Validator.string("Phone Number"),
  first_name: Validator.string("First Name"),
  last_name: Validator.string("Last Name"),
  date_of_birth: Validator.string("Date of birth"),
});

export type PassportOrLicensePayload = z.infer<
  typeof licenseSchema | typeof passportSchema
>;

export const DriversLicenseOrPassportForm = (props: Props) => {
  const { ctx } = props;
  const schema = ctx === "license" ? licenseSchema : passportSchema;

  const { loading, setData, setLoading } = useGetDetailsChecker();

  const initialState = iife(() => {
    if (ctx === "license") {
      return { license_number: "", full_name: "", date_of_birth: "", phone_number: "" };
    }
    return {
      passport_number: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      date_of_birth: "",
    };
  });

  const [formData, setFormData] = useState(initialState);

  const [errors, setErrors] = useState(Validator.createError(schema));

  const fetchDetails = async (data: z.infer<typeof schema>) => {
    setLoading(true);
    try {
      const response = await DetailsCheckerAPI.checkDriversOrPassport({
        type: ctx,
        data: {
          ...data,
          phone_number: `0${data.phone_number}`,
        },
      });

      const success = APIResponseHandler.tryHandler({
        response,
        hideSuccessToast: false,
      });
      if (success) {
        setData(response.data.data);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    }
    setLoading(false);
  };

  const handledSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    Validator.validatorFunc({
      data: formData,
      onError: (ee) => {
        setErrors(ee);
      },
      onSuccess: fetchDetails,
      schema,
    });
  };

  const assertType = (key: string) => {
    if (key === "phone_number") return "phone";
    if (key === "date_of_birth") return "date";

    return "text";
  };

  const handleChange = (e: string, key: string) => {
    setFormData((old) => ({ ...old, [key]: e }));
  };

  return (
    <form className={GENERAL_CLASSES.DETAILS_CHECKER_FORM} onSubmit={handledSubmit}>
      <div className={cn("form-two-cols", GENERAL_CLASSES.DETAILS_CHECKER_FORM)}>
        {Object.entries(initialState).map(([key]) => {
          const label = iife(() => {
            if (key === "passport_number") return "Passport ID";
            return formatWord(key);
          });
          const error = (errors as any)?.[key];

          const isNotTwoCols = ["first_name", "last_name"].includes(key);

          return (
            <Input
              className={cn(
                !isNotTwoCols ? "col-span-2" : "col-span-1",
                error && GENERAL_CLASSES.FORM_GROUP_ERROR
              )}
              name={key}
              label={label}
              placeholder={cn("Enter", label)}
              type={assertType(key)}
              errorText={error}
              showError={error}
              value={(formData as any)[key]}
              onChange={(e: any) => {
                if (key === "date_of_birth") {
                  return handleChange(formatWithDayJs(e, "YYYY-MM-DD"), key);
                }

                return handleChange(e.target.value, key);
              }}
            />
          );
        })}
      </div>
      <JoinedDetailsSubmitButton
        disabled={!isObjectWithValidValues(formData)}
        className="mt-20"
        loading={loading}
      />
    </form>
  );
};
