import { Column, Row } from "@/components/common/stacks";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import "./styles/index.css";
import { icons } from "@/assets/icons";
import { RavenInputProps } from "@ravenpay/raven-bank-ui/dist/esm/components/types";
import { Util } from "@/utils/utility";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import { EmptyShell } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
type AlertProp = {
  onChange: RavenInputProps["onChange"];
  value: RavenInputProps["value"];
};
export default function AddAlertRecievers({ onChange, value }: AlertProp) {
  function handleChange(e: InputOnChange) {
    onChange &&
      onChange({
        ...value,
        [e.target.name]: e.target.value,
      });
  }
  return (
    <div className="alert-recievers">
      <Column className="alert-recievers__form">
        <RavenInputField
          color="orange-light"
          label="Fullname"
          name={"name"}
          onChange={handleChange}
          value={value.name}
          placeholder="Enter fullname"
          type="text"
        />
        <RavenInputField
          color="orange-light"
          label="Email"
          name={"email"}
          onChange={handleChange}
          value={value.email}
          placeholder="Enter Email"
          type="text"
        />
        <RavenInputField
          color="orange-light"
          label="Phone"
          name="phone"
          onChange={handleChange}
          value={value.phone}
          placeholder="91 342 *** ***"
          type="phone"
        />

        <div className="alert-recievers__note-card">
          <Row gap={1}>
            <figure>{icons.info_circle_filled}</figure>
            <p>NB: The mobile number included must be an active WhatsApp number</p>
          </Row>
        </div>
      </Column>
    </div>
  );
}

export function ViewAlertRecievers({
  data,
  onDelete,
}: {
  data?: PosAlertRecievers[];
  onDelete: Function;
}) {
  return (
    <div className="alert-recievers">
      <div className="alert-recievers__form">
        {isEmpty(data) ? (
          <EmptyScreen
            noMascot
            subTitle="It's happens that this user hasnt configured an alert reciever"
            title="No Alert Reciever Found"
          />
        ) : (
          Util.safeArray(data).map((item, idx) => (
            <Row className="alert-recievers__item">
              <Column gap={0.1}>
                <p>
                  {Util.join(Util.safeValue(item.fname)).with(Util.safeValue(item.lname))}
                </p>
                <small>{Util.safeValue(item.phone)}</small>
              </Column>

              <figure onClick={() => onDelete(item.id)} className="cursor">
                {icons.delete_circle}
              </figure>
            </Row>
          ))
        )}
      </div>
    </div>
  );
}
