import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { formatWord, lowerCase } from "@/utils/formatWord";
import { formatNumberToCurrency } from "@/utils/helpers";
import { getJointBillerStatus, getJoinedBillerNarrowFunc } from "../utils";
import { iife } from "@/utils/general";
import { formatDateTime } from "@/utils/helper/Helper";
import { ConditionalCopyText } from "@/components/common/copyCheck";
import { Util } from "@/utils/utility";

function extractToken(tokenString: string): string {
  // Regular expression to match the token pattern (digits and letters)
  const tokenPattern = /\b(?:token|Token)?(\w+)\b/gi;

  const matches = tokenString.match(tokenPattern);
  if (matches) {
    // Join the matches to form the token string
    return lowerCase(matches.join("")).replace("token", "");
  }
  return "";
}

interface Props {
  record: PersonalJointBillModel;
}

export const PersonalJoinedBillersDetailsModal = (props: CreateDetailsProps<Props>) => {
  const { onCancel, record } = props;

  const {
    amount,
    provider,
    unique_identifier,
    status,
    request_id,
    whatTypeIsThis,
    extra_data,
  } = record;
  const { created_at, currency } = props.record._biller;

  const { isElectricity } = getJoinedBillerNarrowFunc(record);

  const meta = iife(() => {
    switch (whatTypeIsThis) {
      case "betting":
        return { provider: "platform", unique: "user_id" };
      case "electricity":
        return { provider: "provider", unique: "meter_number" };
      case "cable":
        return { provider: "cable_tv", unique: "card_number" };
    }
  });

  const view1 = {
    amount: formatNumberToCurrency(amount, currency),
    [meta.provider]: provider,
    [meta.unique]: (<ConditionalCopyText text={unique_identifier} />) as any,
  };

  const addToView = (data: Record<string, any>, type: BillerTypeMeta) => {
    return record.whatTypeIsThis === type ? data : {};
  };

  const token = isElectricity(record._biller)
    ? extractToken(Util.safeText(record._biller.token))
    : "--";

  const view = {
    ...view1,
    ...addToView({ cable_plan: Util.safeText(extra_data) }, "cable"),
    status: getJointBillerStatus(status, whatTypeIsThis),
    reference: <ConditionalCopyText text={request_id} />,
    date: formatDateTime(created_at),
    ...addToView({ token }, "electricity"),
  };

  const content = Object.entries(view).map(([key, value]) => {
    return { label: formatWord(key), value };
  });

  return (
    <ActionModal
      title="Bill Details"
      visible
      onCancel={onCancel}
      onClick={onCancel}
      hideCancel
      hideNormalButton
      className={GENERAL_CLASSES.BIG_MODAL}
    >
      <InlineDetails
        simple
        className={cn(
          GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW,
          GENERAL_CLASSES.INLINE_DETAILS.OVERRIDE,
          { inline_with_last_child_dark: whatTypeIsThis === "electricity" }
        )}
        content={content}
      />
    </ActionModal>
  );
};
