import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { useSelector } from "react-redux";
import { usePagination } from "@/hooks/usePagination";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { useQueryLoadingState } from "../__internals__/hooks/useQueryLoadingState";
import { useNavigate } from "react-router-dom";
import { Util } from "@/utils/utility";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Column, Row } from "@/components/common/stacks";
import Redacted from "@/components/common/shimmer/redact";
import Copy from "@/components/common/copyCheck";
import { useState } from "react";

function formatStatus(status: string | number, refunded?: number) {
  let s;
  if (status === 0 || status === "inactive") {
    s = "Failed";
  }
  if (status === 1 || status === "active") {
    s = "Successful";
  }
  return (
    <div className={`bugiss-status ${s && s?.toLowerCase()}`}>
      <p>{s}</p>
    </div>
  );
}

const PosDailySummary = () => {
  const { isDarkMode } = useThemeMode();

  const pagination = usePagination();

  const res = posReduxAPI.useGetDailySummaryQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
  });
  const { data } = res;

  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  const summary = data?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const combinedLoading = useQueryLoadingState(res);

  const [showCopy, setShowCopy] = useState(-1);

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Daily Summary">
        <SmartFilter {...SmartFilterProps} searchTitle="Search daily summary..." />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {isEmpty(summary) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MERCHANT DETAILS",
                "TOTAL",
                "CARDS",
                "TRANSFERS",
                "COLLECTION",
                "BILLS & PNL",
              ]}
            >
              {Util.safeArray(summary).map((data, idx) => (
                <RavenTableRow
                  key={idx}
                  one={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <TwinValue
                        one={
                          <Row
                            onMouseOver={() => setShowCopy(idx)}
                            onMouseLeave={() => setShowCopy(-1)}
                            ai="center"
                            gap={0.5}
                          >
                            {Util.safeValue(trimLongString(data?.email, 20))}
                            <Copy
                              className={cn(
                                showCopy === idx
                                  ? "copy-text-visible"
                                  : "copy-text-not-visible"
                              )}
                              text={data?.email}
                              tableCopy
                            />
                          </Row>
                        }
                        two={formatDateTime(data?.created_at)}
                      />
                    )
                  }
                  two={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <Column gap={0}>
                        <TwinValue
                          one={`Amount : ${formatNumberToCurrency(
                            Number(data?.tAmount),
                            "NGN"
                          )}`}
                          two={`Count : ${Util.safeValue(data?.tCount)}`}
                        />
                        <p className="twin_value twin_value__two">{`Fees: ${Util.safeValue(
                          formatNumberToCurrency(data?.tFees, "NGN")
                        )}`}</p>
                      </Column>
                    )
                  }
                  three={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <Column gap={0}>
                        <TwinValue
                          one={`Amount : ${formatNumberToCurrency(
                            Number(data?.card),
                            "NGN"
                          )}`}
                          two={`Count : ${Util.safeValue(data?.transfer_out_count)}`}
                        />
                        <p className="twin_value twin_value__two">{`Fees: ${Util.safeValue(
                          formatNumberToCurrency(data?.paid_fees, "NGN")
                        )}`}</p>
                      </Column>
                    )
                  }
                  four={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <Column gap={0}>
                        <TwinValue
                          one={`Amount : ${formatNumberToCurrency(
                            Number(data?.transfer_out_sum),
                            "NGN"
                          )}`}
                          two={`Count : ${Util.safeValue(data?.transfer_out_count)}`}
                        />
                        <p className="twin_value twin_value__two">{`Fees: ${Util.safeValue(
                          formatNumberToCurrency(data?.transfer_out_fees, "NGN")
                        )}`}</p>
                      </Column>
                    )
                  }
                  five={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <Column gap={0}>
                        <TwinValue
                          one={`Amount : ${formatNumberToCurrency(
                            Number(data?.transfer_in_sum),
                            "NGN"
                          )}`}
                          two={`Count : ${Util.safeValue(data?.transfer_in_count)}`}
                        />
                        <p className="twin_value twin_value__two">{`Fees: ${Util.safeValue(
                          formatNumberToCurrency(data?.transfer_in_fees, "NGN")
                        )}`}</p>
                      </Column>
                    )
                  }
                  six={
                    isRedacting ? (
                      <Redacted.Labelled />
                    ) : (
                      <Column gap={0}>
                        <TwinValue
                          one={`Amount : ${formatNumberToCurrency(
                            Number(data?.tBills),
                            "NGN"
                          )}`}
                          two={`Fees : ${Util.safeValue(data?.transfer_in_count)}`}
                        />
                        <p className="twin_value twin_value__two">{`PNL: ${Util.safeValue(
                          formatNumberToCurrency(data?.pnl, "NGN")
                        )}`}</p>
                      </Column>
                    )
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PosDailySummary;
