import "../style/index.css";
import "./styles/index.css";
import { Fragment } from "react";
import { XStack } from "../../stacks";
import { icons } from "@/assets/icons";
import { TypeIs } from "@/utils/helpers";
import { cn } from "@/utils/colorConvert";

export interface CustomFilterFiltersOptions {
  value: SN;
  label: SN;
}

export interface CustomFilterFilters<T extends SN> {
  label: T;
  /** Label is the fallback option if no key is provided, key is the identifier returned for the filter type */
  key?: T;
  options: CustomFilterFiltersOptions[];
  /** Multiple filter status is disabled for now */
  // isMulti?: boolean;
  /** just pass the value of the selected own */
  defaultValue?: SN;
}

export type Filters<T extends SN> = Record<T, SN>;

export interface CustomFilterProps<T extends SN> {
  filters: CustomFilterFilters<T>[];
  onFilter(filters: Filters<T> | {}): void;
  activeTab: SN;
  setActiveTab(value?: SN): void;
  selectedFilters?: Filters<T>;
}

/** @todo: Add implementation of multiple */
export const CustomFilter = <T extends SN>(props: CustomFilterProps<T>) => {
  const { filters, onFilter, activeTab, setActiveTab, selectedFilters } = props;

  const isSelected = (value: SN) => value in (selectedFilters ?? {});

  const renderRadio = (key: T, value: SN) => {
    if (!isSelected(key)) return icons.radio_unchecked;

    const selected = TypeIs.any(selectedFilters ?? {})[key] === value;

    return selected ? icons.radio_check : icons.radio_unchecked;
  };
  const handleChange = (key: T, arg: CustomFilterFiltersOptions) => {
    const temp = selectedFilters ?? {};
    const selectedState = { ...temp, [key]: arg.value };
    onFilter(selectedState);
  };

  const handleDropdown = (key: SN) => {
    if (activeTab === key) setActiveTab();
    else setActiveTab(key);
  };

  const getLabel = (arg: T) => {
    const selected = filters.find(({ label, key }) => {
      const _key = key ?? label;

      return _key === arg;
    });

    if (selected) {
      const value = selected.options.find(
        ({ value }) => selectedFilters?.[arg] === value
      );

      return value?.label;
    }
  };

  const handleItemClear = (arg: T) => {
    const temp: any = { ...(selectedFilters ?? {}) };
    if (arg in temp) {
      delete temp[arg];
      onFilter(temp);
    }
  };

  return (
    <div className="custom-filter">
      {filters.map((filter) => {
        const key = filter.key ?? filter.label;

        const selectedLabel = getLabel(key);

        return (
          <Fragment key={key}>
            <button
              onClick={() => handleDropdown(key)}
              type="button"
              className={cn("custom-filter__item dropdown__date-title")}
            >
              <p className="custom-filter__item__title" key={filter.key ?? filter.label}>
                {filter.label}
              </p>
              <div
                className={cn("dropdown__tags", {
                  show: selectedLabel,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleItemClear(key);
                }}
              >
                <p>{selectedLabel}</p>
                <figure>{icons.x_circle}</figure>
              </div>
              <div className={cn("tag-close-icon", { hide: selectedLabel })}>
                <figure>{icons.chevron_down}</figure>
              </div>
            </button>
            <div
              className={cn("custom-filter__item__options", "drop-down__item", {
                active: activeTab === key,
              })}
            >
              {filter.options.map((option) => {
                return (
                  <button
                    onClick={() => handleChange(filter.key ?? filter.label, option)}
                    key={option.value}
                    type="button"
                    className="custom-filter__item__options__trigger"
                  >
                    <XStack gap={0.5} ai="center">
                      {renderRadio(key, option.value)}
                      <p>{option.label}</p>
                    </XStack>
                  </button>
                );
              })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
