import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { TabsElement, useOutletTab } from "@/components/common/Tabs";

export const PersonalScheduledTransaction = () => {
  const { OutletTab, handleOutletTabChange, getSelectedDropDown } = useOutletTab();

  const transfers: TabsElement[] = [
    {
      value: "transfers",
      label: "Transfers",
      dropdown: {
        onChange: handleOutletTabChange,
        tabs: [
          { label: "Bank Transfers", value: "bank" },
          { label: "Internal Transfers", value: "internal" },
        ],
      },
    },
 "Airtime",
 "Data",
 "Electricity",
 "Cable"
  ];
  const selectedDropdown = getSelectedDropDown(transfers);

  return (
    <div>
      <DashboardLayout>
        <PageLayout pageTitle="Bank Transfer">
          <div className="personal-transfers">
            <OutletTab>
              <div className="personal-transfers__top-wrap">
                <OutletTab.Tabs
                  tabs={transfers}
                  baseURL="/personal-scheduled-transactions"
                  selectedDropdown={selectedDropdown}
                />
              </div>
              <OutletTab.Outlet />
            </OutletTab>
          </div>
        </PageLayout>
      </DashboardLayout>
    </div>
  );
};
