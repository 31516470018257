import { PersonalOverview } from "@/apps/personal/overview";
import { PbTransactionMetrics } from "@/apps/personal/overview/pages/transaction-metrics";
import { PbUserMetrics } from "@/apps/personal/overview/pages/user-metrics";

export const personal_overview_routes: RouteArray[] = [
  {
    element: PersonalOverview,
    path: "/personal-overview",
    children: [
      { element: PbUserMetrics, path: "" },
      { element: PbUserMetrics, path: "user-metrics" },
      { element: PbTransactionMetrics, path: "transaction-metrics" },
    ],
  },
];
