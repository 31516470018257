import { usePagination } from "@/hooks/usePagination";
import { mapPosPagination, useSmartPosFilter } from "@/hooks/useSmartFilterProps";
import { posExportAPI, posReduxAPI } from "@/redux/slices/pos/apis";
import { useSelector } from "react-redux";
import { useQueryLoadingState } from "./useQueryLoadingState";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/redux/store";
import { usePosFilter } from "./usePosFilter";
import { useExportModal } from "@/hooks/useExportModal";
import { Util } from "@/utils/utility";
import { createExportEndpoint } from "@/redux/slices/pos/apis/util";
import { FetchArgs } from "@reduxjs/toolkit/query";
import { useState, useEffect } from "react";

type GetQueryApi = keyof typeof posReduxAPI.endpoints;

const usePosGetQuery = <T>(
  api: GetQueryApi,
  params?: Record<string, any>,
  { skip }: { skip?: boolean } = {}
) => {
  const pagination = usePagination();
  const { filterOptions, filterValue } = usePosFilter();

  const { toggleModal } = useExportModal(handleExport);

  // Get the endpoint's query hook
  const queryApi = posReduxAPI.endpoints[api]?.useQuery as (
    params: any,
    { skip }: { skip: boolean }
  ) => {
    data: PosResponseStruct<T>;
    error: any;
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    refetch: () => void;
  };

  const exportBase = `${api}Export` as typeof api;
  const exportApi = posReduxAPI.endpoints[exportBase].useQuery;

  const [exportTriggered, setExportTriggered] = useState(false);
  const { data, refetch, ...resp } = queryApi(
    {
      page: pagination.currentPage,
      perPage: 200,
      search: pagination.searchQuery,
      ...filterValue,
      ...params,
    },
    { skip: skip as boolean }
  );

  const exportResp = exportApi(
    {
      ...filterValue,
      ...params,
    },
    { skip: !exportTriggered }
  );

  const { SmartFilterProps } = useSmartPosFilter(mapPosPagination(data), pagination);

  const tableData = data?.data ?? data?.result;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const combinedLoading = useQueryLoadingState(resp);

  useEffect(() => {
    if (exportTriggered && exportResp.data) {
      const url = String(exportResp?.data); // You need to assign the correct URL from exportResp.data
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Data Export for ${getPageNameByUrl()}`;
      a.target = "_blank"; // Open in a new tab
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      toggleModal(false);
      setExportTriggered(false);
    }
  }, [exportResp.data, exportTriggered, toggleModal]);

  function handleExport() {
    setExportTriggered(true);
  }

  const navigate = useNavigate();
  return {
    pagination,
    resp,
    data,
    refetch, // Include refetch here
    SmartFilterProps: {
      ...SmartFilterProps,
      ...filterOptions,
      onExport: () => {
        toggleModal(getPageNameByUrl());
      },
    },
    tableData,
    isRedacting,
    combinedLoading,
    navigate,
  };
};

function getPageNameByUrl() {
  const url = window.location.pathname;

  return Util.cleanText(url.replace("/", "").replace("pos", ""));
}

export default usePosGetQuery;
