import { cn } from "@/utils/colorConvert";
import { CreateTableRowProps } from "../shared";
import { GENERAL_CLASSES } from "@/constants";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import TwinValue from "@/components/common/twinValue";
import { SyntheticEvent, useState } from "react";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus, IconVault, trimLongString } from "@/utils/helper/Helper";
import { ControlledTableContent, XStack } from "@/components/common/stacks";
import { SCHEDULED_TRANSACTION_TYPES } from "@/apps/personal/scheduledTransactions/__internals__/constants";
import { capitalizeEachWord } from "@/utils/formatWord";
import Avatar from "@/components/common/avatar";
import { autoBillersLogo } from "@/constants/auto-billers-logo";
import { icons } from "@/assets/icons";

type PBTProps = CreateTableRowProps<{
  data: PersonalScheduledPayments;
  refetch: RefetchFn;
  type: keyof typeof SCHEDULED_TRANSACTION_TYPES
}>;

export const PersonalScheduledBillsTable = (props: PBTProps) => {
  const { data, redactedFn, refetch, ctx, type } = props;

  const { RedactedLabel, RedactedTable } = redactedFn;
  const [selectedRechecks, setSelectedRechecks] = useState<SN[]>([]);

  const getBillTypeSpecificData = (row: PersonalScheduledPayment) => {
    switch (type) {
      case 'AIRTIME_RECHARGE':
        const airtimeData: PSPAirtimeData = row.data;
        return {
          provider: airtimeData.network,
          recipient: airtimeData.phone_number,
        };
      case 'DATA_RECHARGE':
        const dataData: PSPAirtimeData = row.data;
        return {
          provider: dataData.provider?.split('-')[0] || '',
          recipient: dataData.phone_number,
          plan: dataData.sub_plan || 'N/A',
        };
      case 'ELECTRICITY_RECHARGE':
        return {
          provider: row.data.disco,
          recipient: row.data.meter_number,
        };
      case 'CABLE_RECHARGE':
        return {
          provider: row.data.cable_provider,
          recipient: row.data.smart_card_number,
          plan: row.data.cable_plan || 'N/A',
        };
      default:
        return {
          provider: '',
          recipient: '',
          plan: '',
        };
    }
  };

  const getHeaderList = () => {
    const baseHeaders = [
      "USER EMAIL",
      "PROVIDER",
      "RECIPIENT",
      "AMOUNT",
      "DATE SCHEDULED",
      "FREQUENCY",
      "STATUS",
    ];
    if (type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE') {
      baseHeaders.splice(3, 0, type === 'DATA_RECHARGE' ? "DATA PLAN" : "CABLE PLAN");
    }
    return baseHeaders;
  };

  return (
    <RavenTable
      className={cn("table__main", GENERAL_CLASSES.TABLE_WITH_DROPDOWN)}
      headerList={getHeaderList()}
    >
      {data.map((row) => {
        const {
          id,
          status,
          amount,
          email,
        } = row;

        const billTypeData = getBillTypeSpecificData(row);
        const imagePlaceholder = IconVault(icons.electricity_placeholder);

        const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.src = imagePlaceholder;
          };
        const {
          RedactedTable: CustomTableRedact,
          RedactedAvatar: CustomBadgeRedact,
          RedactedLabel: CustomBadgeLabel,
          RenderCustom: CustomCustom,
        } = renderRedactedComponent(selectedRechecks.find((idx) => idx === id));

        const biller = autoBillersLogo(
            Util.safeValue(
                billTypeData?.provider?.toLowerCase()
            )
          ) as string;
        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(Util.safeText(email))}
            two={
              <figure className="biller-logo">
                <Avatar
                  name={Util.safeValue(
                    capitalizeEachWord(billTypeData.provider)
                  )}
                  full
                  onError={addImageFallback}
                  imgSrc={biller}
                />
              </figure>
            }
            three={RedactedTable(Util.safeText(billTypeData.recipient))}
            four={type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE' ? 
              RedactedTable(Util.safeText(billTypeData.plan)) : 
              RedactedTable(Util.safeText(formatNumberToCurrency(amount, 'ngn')))}
            five={type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE' ? 
              RedactedTable(Util.safeText(formatNumberToCurrency(amount, 'ngn'))) : 
              RedactedTable(formatDateTime(row.created_at))}
            six={type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE' ? 
              RedactedTable(formatDateTime(row.created_at)) : 
              RedactedTable(Util.safeText(row?.frequency))}
            seven={type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE' ? 
              RedactedTable(Util.safeText(row?.frequency)) : 
              CustomCustom(
                RedactedTable(<XStack gap={0.4}>{formatStatus(status)}</XStack>),
                { height: 10, width: 40, className: GENERAL_CLASSES.SHIMMER }
              )}
            eight={type === 'DATA_RECHARGE' || type === 'CABLE_RECHARGE' ? 
              CustomCustom(
                RedactedTable(<XStack gap={0.4}>{formatStatus(status)}</XStack>),
                { height: 10, width: 40, className: GENERAL_CLASSES.SHIMMER }
              ) : undefined}
          />
        );
      })}
    </RavenTable>
  );
};
