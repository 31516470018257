import "./style.css";
import React, { ReactNode, useState } from "react";
import { handleCopy } from "../../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import { icons } from "../../../assets/icons";
import { cn } from "@/utils/colorConvert";
import { XStack } from "../stacks";
import { Util } from "@/utils/utility";
import { STRING_TRIM_LENGTH } from "@/constants";

interface CopyProps {
  text: string | number;
  icon?: ReactNode;
  className?: string;
  tableCopy?: boolean;
}

function Copy({ text, icon, className, tableCopy }: CopyProps) {
  const [copied, setCopied] = React.useState(false);

  return (
    <div
      className={cn(
        "checkmark__wrapper",
        tableCopy && "checkmark__wrapper--table",
        className
      )}
    >
      <figure
        onClick={(e) => {
          e.stopPropagation();
          handleCopy(text as string, setCopied);
        }}
      >
        {copied ? (
          <figure className="copy-checkmark">
            <FaCheck color="#014345" />
          </figure>
        ) : (
          icon ?? icons.copy
        )}
      </figure>
    </div>
  );
}

export default Copy;

interface CopyTextProps {
  text: SN;
  textToCopy?: SN;
  className?: string;
  gap?: number;
  onClick?(): void;
}

export const CopyText = (props: CopyTextProps) => {
  const { text, textToCopy, className, gap, onClick } = props;
  const [showCopy, setShowCopy] = useState(false);

  return (
    <XStack
      onClick={onClick}
      gap={gap ?? 0.4}
      ai="center"
      className={cn("copy--text", className)}
      onMouseOver={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      <span>{text}</span>
      <Copy
        className={cn(showCopy ? "copy-text-visible" : "copy-text-not-visible")}
        text={textToCopy ?? text}
        tableCopy
      />
    </XStack>
  );
};

interface ConditionalCopyTextProps {
  trim?: number;
  text: IsUncertain<SN>;
  className?: string;
}

/** Do not trim value being passed into it */
export const ConditionalCopyText = (props: ConditionalCopyTextProps) => {
  const { text, trim = STRING_TRIM_LENGTH, className } = props;

  return text ? (
    <CopyText className={className} text={Util.safeText(text, trim)} textToCopy={text} />
  ) : (
    <>{"--"}</>
  );
};
