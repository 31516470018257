import { DependencyList, useEffect } from "react";
import { usePagination, UsePaginationState } from "./usePagination";
import { assertPaginationPageNumber } from "@/utils/helpers";
import { FilterParams, OnFilter } from "@/components/common/smartFilter";
import { CustomFilterFilters } from "@/components/common/smartFilter/custom-filter";
import { FilterType } from "@/utils/utility";

type BasePaginate = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: React.Dispatch<React.SetStateAction<number>>;
};
export interface UseSmartFilterState {
  SmartFilterProps: {
    onSearchChange: (query: string) => void;
    pagination: BasePaginate;
    onFilter: OnFilter<any>;
  };
}

export const useSmartFilterProps = <Data,>(
  data: ResponseWithPagination<Data> | undefined,
  pagination: UsePaginationState,
): UseSmartFilterState => {
  const { currentPage, setCurrentPage, setSearchQuery, setFilterValue } = pagination;

  const SmartFilterProps: UseSmartFilterState["SmartFilterProps"] = {
    onSearchChange: (query: string) => {
      setSearchQuery(query);
    },
    onFilter: (data) => {
      setFilterValue(data);
      setSearchQuery("");
    },
    pagination: {
      currentPage: assertPaginationPageNumber(currentPage),
      itemsPerPage: assertPaginationPageNumber(data?.per_page ?? data?.per_page),
      totalItems: assertPaginationPageNumber(data?.total),
      onPageChange: setCurrentPage,
    },
  };

  return { SmartFilterProps };
};

export const useSmartPosFilter = <Data,>(
  data: PosResponseStruct<Data>["pagination"] | undefined,
  pagination: UsePaginationState,
): UseSmartFilterState => {
  const { currentPage, setCurrentPage, setSearchQuery, setFilterValue } = pagination;

  const SmartFilterProps: UseSmartFilterState["SmartFilterProps"] = {
    onSearchChange: (query: string) => {
      setSearchQuery(query);
    },
    onFilter: (data) => {
      setFilterValue(data);
      setSearchQuery("");
    },
    pagination: {
      currentPage: assertPaginationPageNumber(currentPage),
      itemsPerPage: assertPaginationPageNumber(data?.perPage),
      totalItems: assertPaginationPageNumber(data?.total),
      onPageChange: setCurrentPage,
    },
  };

  return { SmartFilterProps };
};

interface MapTransferPagination {
  pagination: PersonalPagination;
}

export const mapTransfersPagination = <T extends MapTransferPagination>(
  data?: T,
): ResponseWithPagination<any> => {
  if (!data)
    return {
      current_page: "1",
      total: 1,
      per_page: "1",
    } as any;

  return {
    current_page: data.pagination.currentPage as any,
    total: data.pagination.total,
    per_page: data.pagination.perPage as any,
  } as any;
};

export const mapPosPagination = <T extends Omit<PosResponseStruct<any>, "data">>(
  data?: T,
): any => {
  if (!data)
    return {
      current_page: "1",
      total: 1,
      per_page: "1",
    } as any;

  return {
    page: data.pagination.currentPage as any,
    total: data.pagination.total,
    perPage: data.pagination.perPage as any,
  } as any;
};

export const usePaginationRefresh = (
  pagination: UsePaginationState,
  dependencies: DependencyList = [],
) => {
  useEffect(() => {
    pagination.setCurrentPage(1);
    pagination.setSearchQuery("");
    pagination.setFilterValue({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const mapAltPersonalPagination = (data?: any): ResponseWithPagination<any> => {
  if (!data)
    return {
      current_page: "1",
      total: 1,
      per_page: "1",
    } as any;

  return {
    current_page: data.pagination.current_page as any,
    total: data.pagination.total,
    per_page: data.pagination.per_page as any,
  } as any;
};

/**
 * If label and key is passed, use key instead
 */
export const useSmartFilterCustomFilter = <T extends SN>(
  filters: CustomFilterFilters<T>[],
) => {
  const pagination = usePagination();

  const onFilter: OnFilter<T> = (params) => {
    pagination.setCurrentPage(1);
    pagination.setSearchQuery("");
    pagination.setFilterValue(params);
  };

  return {
    /** Use the inferred value for key if label and key is passed */
    filterArgs: pagination.filterValue as FilterParams<T> & Partial<FilterType>,
    filterProps: { onFilter, customFilter: { filters } },
    /** Do not use filterValue, use filterArgs and  */
    pagination: pagination,
  };
};
