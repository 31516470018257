import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import {
  formatDateTime,
  formatStatus,
  symbol,
  trimLongString,
} from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { CopyText } from "@/components/common/copyCheck";
import { cn } from "@/utils/colorConvert";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { usePagination } from "@/hooks/usePagination";
import {
  assertPaginationPageNumber,
  detectStatus,
  formatNumberToCurrency,
  getQueryVariable,
} from "@/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import Redacted, { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";

interface CardTypeProps {
  status: string;
}
const CardType = ({ status }: CardTypeProps) => {
  const cardStatus = (() => {
    switch (status?.toLowerCase()) {
      case "funding":
        return "successful";
      case "purchase":
        return "pending";
      case "refund":
        return "refunded";
      case "charge back":
        return "inreview";
      default:
        return "deactivated";
    }
  })();

  return (
    <div className={cn("bugiss-status", cardStatus)}>
      <p>{status}</p>
    </div>
  );
};

const headerList = [
  "REFERENCE",
  "CARD ID",
  "DESCRIPTION",
  "AMOUNT",
  "TYPE",
  "TRANSACTION DATE",
  // "STATUS",
];

const status = ["successful", "pending", "failed", "pending"];

const cardTypes = ["Funding", "Purchase", "Refund", "Charge Back"];

export const SingleBusinessCardPage = () => {
  const { currentPage, searchQuery, setCurrentPage, setSearchQuery } = usePagination();
  const [canUpdata, setCanUpdate] = useState(false);
  const { isRedacting } = useSelector((store: RootState) => store.general);
  const { renderRedacted } = renderRedactedComponent(isRedacting);
  const params = useParams();
  const searchParams = useSearchParams();

  const { id: cardId } = params;
  const bizId = getQueryVariable("biz");

  const { data: cardData } = businessReduxAPI.useGetCardTransactionsQuery({
    business_id: bizId ?? "",
    card_id: cardId ?? "",
    current_page: currentPage,
    search_term: searchQuery,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // if (cardId && bizId) {
    dispatch(
      BugissApi.business.getBusinessCardDetails({
        business_id: bizId ?? "",
        card_id: cardId ?? "",
      })
    );
    // }
  }, [cardId, bizId, canUpdata]);

  const { single_card, loading } = useSelector((state: RootState) => state.businessCards);

  const { card_details, card_transactions } = single_card;

  const [modal, setModal] = useState<{
    block?: boolean;
    strip?: boolean;
    cardView?: boolean;
    cardFreeze?: boolean;
    cardDestroy?: boolean;
    lien?: boolean;
    deduct?: boolean;
  }>({
    block: false,
    strip: false,
    lien: false,
    deduct: false,
    cardFreeze: false,
    cardDestroy: false,
    cardView: false,
  });

  async function handleCardDestroy() {
    const resp: any = await dispatch(
      BugissApi.business.freezeBusinessCard({
        business_id: bizId,
        card_id: String(card_details?.id),
      })
    );

    if (resp.payload?.status === "success") {
      setCanUpdate(!canUpdata);
      setModal({
        cardDestroy: false,
        cardFreeze: false,
      });
    }
  }
  async function handleCardFreeze() {
    if (isCardFreezed()) {
      const resp: any = await dispatch(
        BugissApi.business.unfreezeBusinessCard({
          business_id: bizId,
          card_id: String(card_details?.id),
        })
      );

      if (resp.payload?.status === "success") {
        setCanUpdate(!canUpdata);
        setModal({
          cardDestroy: false,
          cardFreeze: false,
        });
      }
    } else {
      const resp: any = await dispatch(
        BugissApi.business.freezeBusinessCard({
          business_id: bizId,
          card_id: String(card_details?.id),
        })
      );

      if (resp.payload?.status === "success") {
        setCanUpdate(!canUpdata);
        setModal({
          cardDestroy: false,
          cardFreeze: false,
        });
      }
    }
  }
  const { loading: cardLoading } = useSelector((state: RootState) => state.businessCards);

  function isCardFreezed() {
    if (card_details.status === "freezed") {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      {" "}
      <DashboardLayout>
        <PageLayout
          pageTitle="Card Details"
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "unset" }}
          childrenClassName="product-single-details-page"
        >
          <div className="single-user-details__top-wrap reusable-details-card-top">
            <div className="top-wrap__main-content">
              <div className="top-wrap__main-content--profile-img card-avatar-image">
                {icons.business_cards}
              </div>

              <div className="top-wrap__main-content--user-data">
                <div className="user-data__main-content">
                  <MainContentCol>
                    <MainContentCol.Item title="Card holder name">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(card_details.main_card_object.card_name)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Date Joined">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        formatDateTime(card_details.main_card_object.created_at)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Country">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(
                          card_details.main_card_object.billing_address.country
                        )
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="Card Name">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(card_details.main_card_object.card_name)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Currency">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(card_details.currency)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Postal Code">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(
                          card_details.main_card_object.billing_address.postal_code
                        )
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="Card Type">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(card_details.card_type)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Expiry Date">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.join(
                          Util.safeValue(card_details.main_card_object.expiry_year)
                        ).with(
                          `/ ${Util.safeValue(
                            card_details.main_card_object.expiry_month
                          )}`
                        )
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Billing Address">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.join(
                          Util.safeValue(
                            card_details.main_card_object.billing_address.street
                          )
                        ).with(
                          Util.join(
                            Util.safeValue(
                              card_details.main_card_object.billing_address.city
                            )
                          ).with(
                            `${Util.safeValue(
                              card_details.main_card_object.billing_address.state
                            )}, ${Util.safeValue(
                              card_details.main_card_object.billing_address.country
                            )}`
                          )
                        )
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="Card CVV">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(card_details.main_card_object.cvv)
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="Card Balance">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <>
                          {symbol(card_details.main_card_object.currency) +
                            formatNumberToCurrency(card_details.main_card_object.balance)}
                        </>
                      )}
                    </MainContentCol.Item>
                    <MainContentCol.Item title="City">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(
                          card_details?.main_card_object.billing_address.city
                        )
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                </div>
                <div className="user-data__account-summary">
                  <div className="top-wrap__actions">
                    <IconButton
                      borderColor="#FF0F00"
                      text="Destroy Card"
                      icon="slash_circle_01"
                      onClick={() => {
                        setModal({
                          cardDestroy: true,
                        });
                      }}
                    />
                    <IconButton
                      text={` ${isCardFreezed() ? "Unfreeze" : "Freeze"} Card`}
                      borderColor="#EA872D"
                      icon="snowflakes"
                      onClick={() => {
                        setModal({
                          cardFreeze: true,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-user-details__table-wrap">
            <YStack>
              <SmartFilter
                onSearchChange={(e: string) => setSearchQuery(e)}
                pagination={{
                  currentPage: assertPaginationPageNumber(currentPage),
                  itemsPerPage: assertPaginationPageNumber(
                    cardData?.card_transactions.total
                  ),
                  totalItems: assertPaginationPageNumber(
                    cardData?.card_transactions.totalPages
                  ),
                  onPageChange: setCurrentPage,
                  activeFilter: "All Cards",
                }}
              />
            </YStack>
            {!cardId ||
            cardData?.card_transactions?.items?.length === 0 ||
            (loading && !isRedacting) ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable headerList={headerList}>
                {cardData?.card_transactions.items.map((item, idx) => (
                  <RavenTableRow
                    key={idx}
                    one={renderRedacted(
                      Util.safeValue(trimLongString(item.tx_id, 30)),
                      "Table"
                    )}
                    two={renderRedacted(
                      <CopyText
                        text={trimLongString(Util.safeValue(item.card_id), 30) ?? ""}
                      />,
                      "Table"
                    )}
                    three={renderRedacted(Util.safeValue(item.narration), "Table")}
                    four={renderRedacted(
                      formatNumberToCurrency(
                        assertPaginationPageNumber(Util.safeValue(item.amount))
                      ),
                      "Table"
                    )}
                    six={renderRedacted(formatDateTime(item.trx_date), "Table")}
                    five={renderRedacted(<CardType status={cardTypes[idx]} />, "Table")}
                    // seven={renderRedacted(formatStatus(item.status ?? "Unknown"), "Table")}
                  />
                ))}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>
      <ActionModal
        visible={modal?.cardFreeze as boolean}
        onCancel={() => {
          setModal({ cardFreeze: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardFreeze();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${isCardFreezed() ? "Unfreeze" : "Freeze"}`}
        btnColor={"#EA872D"}
        body={`Are you sure to  ${
          isCardFreezed() ? "unfreeze" : "freeze"
        } this card? you can always come back to this action later.`}
        title={` ${isCardFreezed() ? "Unfreeze" : "Freeze"} Card`}
      ></ActionModal>
      <ActionModal
        visible={modal?.cardDestroy as boolean}
        onCancel={() => {
          setModal({ cardDestroy: false });
        }}
        loading={cardLoading}
        onClick={() => {
          handleCardDestroy();
        }}
        cancelText="No, Cancel"
        actionText={`Destroy Card`}
        btnColor={"red"}
        body="Are you sure to destroy this card? This action cannot be undone?"
        title={"Destroy Card?"}
      ></ActionModal>
    </>
  );
};
