import { icons } from "@/assets/icons";
import { formatStatus, trimLongString } from "@/utils/helper/Helper";
import React from "react";
import "./styles/index.css";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import Avatar from "@/components/common/avatar";
const Operators = ({
  data,
  onDelete,
}: {
  data: HNIOperators[];
  onDelete: (e: HNIOperators) => void;
}) => {
  return (
    <div className="service-operators-container flex col">
      <p style={{ color: "#676767", marginTop: "1rem" }}></p>
      {data?.map((d) => {
        return (
          <div className="service-operators-container__item">
            <div className="service-operators-container__item--title-wrap">
              <Avatar name={Util.join(d.fname).with(d.lname)} />
              <span className="device-title">
                <p>{trimLongString(Util.safeValue(d.fname), 20)}</p>
                <small>{Util.safeValue(d.fname)} • BestStar MFB </small>
              </span>
            </div>

            <div
              onClick={() => {
                onDelete(d);
              }}
              className="status"
            >
              <figure>{icons.x}</figure>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Operators;
