import { z } from "zod";
import {
  INVALID_PASSWORD_WHITE_SPACE_ERROR_MESSAGE,
  WEAK_PASSWORD_ERROR_MESSAGE,
  invalidEmailErrorMessage,
  invalidErrorMessage,
  invalidUsernameErrorMessage,
  lowerLimitErrorMessage,
  noNumberErrorMessage,
  requiredErrorMessage,
  upperLimitErrorMessage,
  createDefaultError,
} from "./error-message";
import { formatNumWithoutCommaNaira } from "../helper/Helper";

// const specialCharacterRegex = new RegExp(/[^a-zA-Z0-9 ]/);
// const allowPeriodAndCommaRegex = new RegExp(/[^a-zA-Z0-9., ]/);
const userNameRegex = new RegExp(/[^a-zA-Z0-9_ ]/);
const numberRegex = new RegExp(/\d/);
const passwordRegex = new RegExp(/^\S{8,}$/);
const whiteSpaceRegex = new RegExp(/\s/);

/**
 * @description Schema function for validating a string field.
 * @param fieldName - Name of field. In human readable format. This is shown on the frontend.
 * @param lowerLimit - What is the least character length the string should contain? This defaults to 2.
 * @param upperLimit - Maximum character length the string should contain. This defaults to 200.
 * @param allowPeriodAndComma - Should periods and commas be allowed in the string? Defaults to false.
 * @param includeSpecialChar - Should special characters (all) be allowed in the string? Defaults to false.
 * @param disAllowNumbers - Should numbers be disallowed in the string? Defaults to false.
 */
export const genericStringSchema = (
  fieldName: string,
  lowerLimit?: number,
  upperLimit?: number,
  disAllowNumbers?: boolean,
  genericMessage?: string
) =>
  z
    .string({
      required_error: genericMessage ?? requiredErrorMessage(fieldName),
      invalid_type_error: genericMessage ?? invalidErrorMessage(fieldName),
    })
    .trim()
    .max(upperLimit || 200, {
      message: genericMessage ?? upperLimitErrorMessage(fieldName, upperLimit || 200),
    })
    .min(lowerLimit || 1, {
      message: lowerLimit
        ? genericMessage ?? lowerLimitErrorMessage(fieldName, lowerLimit)
        : genericMessage ?? `${fieldName} cannot be blank`,
    })
    .refine(
      (arg) => {
        return disAllowNumbers ? !arg.match(numberRegex) : true;
      },
      { message: genericMessage ?? noNumberErrorMessage(fieldName) }
    );

export const accountNumberSchema = (fieldName: string) =>
  genericStringSchema(fieldName).refine(
    (arg) => arg.length > 9,
    `${fieldName} is too short`
  );

export const dateSchema = (fieldName: string) =>
  z.preprocess(
    (arg) => {
      if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
    },
    z.date({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
  );

export const newDateSchema = (fieldName: string) =>
  z.unknown({ ...createDefaultError(fieldName) }).transform((arg, ctx) => {
    if (!(arg instanceof Date)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: requiredErrorMessage(fieldName),
      });

      return z.NEVER;
    }

    return arg;
  });

export const durationSchema = z.object({
  validFrom: dateSchema("validFrom"),
  validTo: dateSchema("validTo").optional(),
});

export const idSchema = (fieldName: string) =>
  z
    .string({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
    .uuid(invalidErrorMessage(fieldName));

export const amountSchema = (fieldName: string) =>
  genericStringSchema(fieldName)
    .refine(
      (arg) => {
        const value = formatNumWithoutCommaNaira(arg);
        return !isNaN(Number(value));
      },
      { message: invalidErrorMessage(fieldName) }
    )
    .transform((arg, ctx) => {
      const num = Number(formatNumWithoutCommaNaira(arg));
      if (isNaN(num)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: invalidErrorMessage(fieldName),
        });

        return z.NEVER;
      }

      if (num <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${fieldName} mus be greater than 0`,
        });

        return z.NEVER;
      }

      return num;
    });

export const stringNumberSchema = (fieldName: string) =>
  z
    .string({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
    .refine(
      (arg) => {
        if (!Number(arg)) return false;
        return true;
      },
      { message: invalidErrorMessage(fieldName) }
    )
    .refine((arg) => Number(arg) > 0, { message: invalidErrorMessage(fieldName) });

export const dateSearchSchema = z.object({
  searchFrom: dateSchema("searchFrom"),
  searchTo: dateSchema("searchTo"),
});

export const emailSchema = (fieldName: string) =>
  z
    .string({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
    .email(invalidEmailErrorMessage(fieldName));

export const passwordSchema = (fieldName: string) =>
  z
    .string({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
    .trim()
    .refine((password) => !password.match(whiteSpaceRegex), {
      message: INVALID_PASSWORD_WHITE_SPACE_ERROR_MESSAGE,
    })
    .refine((password) => Boolean(password.match(passwordRegex)), {
      message: WEAK_PASSWORD_ERROR_MESSAGE,
    });

export const userNameSchema = (fieldName: string) => {
  const LOWERLIMIT = 3;
  const UPPERLIMIT = 16;

  return z
    .string({
      required_error: requiredErrorMessage(fieldName),
      invalid_type_error: invalidErrorMessage(fieldName),
    })
    .trim()
    .max(UPPERLIMIT, {
      message: upperLimitErrorMessage(fieldName, UPPERLIMIT),
    })
    .min(LOWERLIMIT, {
      message: lowerLimitErrorMessage(fieldName, LOWERLIMIT),
    })
    .refine(
      (arg) => {
        return !arg.match(userNameRegex);
      },
      {
        message: invalidUsernameErrorMessage(fieldName),
      }
    );
};

export const urlSchema = (fieldName: string) =>
  genericStringSchema(fieldName, 5, undefined, undefined);

/**
 * 1kb = 1 * 1000 = 1000bytes
 * 1Mb = 1 * 1000kb
 */
const MAX_FILE_SIZE = 5_000_000;

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

interface FileArgs {
  key: string;
  maxSize?: number;
  formats?: string[];
}

/**
 * https://github.com/colinhacks/zod/issues/387#issuecomment-1191390673
 *
 * This is modified to accept only single file vs all files
 * Returns a file, but zod returns it as any, you can directly access file types if it successful
 */
export const createFileSchema = (args: FileArgs) => {
  const { key, formats = ACCEPTED_IMAGE_TYPES, maxSize = MAX_FILE_SIZE } = args;

  return z
    .any()
    .refine((file) => Boolean(file), `${key} is required`)
    .refine((file) => file?.size <= maxSize, `Max file size is 5MB`)
    .refine(
      (file) => formats.includes(file?.type),
      "Invalid type provided for file, only .jpg, .jpeg, .png and .webp files are accepted"
    )
    .refine((file) => Boolean(file), `${key} is required`);
};

export const createSelectSchema = (key: string, path?: string) =>
  z.object(
    {
      label: genericStringSchema(key),
      value: genericStringSchema(key),
    },
    {
      ...createDefaultError(key),
    }
  );

export const newAmountSchema = (fieldName: string) => amountSchema(fieldName);

export const dateSchemaWithTransform = (fieldName: string) =>
  z.unknown({ ...createDefaultError(fieldName) }).transform((arg, ctx) => {
    if (!arg) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: requiredErrorMessage(fieldName),
      });

      return z.NEVER;
    }

    const date = new Date(arg as any);

    if (!(date instanceof Date)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: invalidErrorMessage(fieldName),
      });

      return z.NEVER;
    }

    return date;
  });
