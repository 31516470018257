import EmptyScreen from "@/components/common/emptyScreen";

export const UsersOverdraft = () => {
  return (
    <div style={{ height: "70vh" }} className="mt-20">
      <EmptyScreen
        title="Overdrafts are coming soon"
        subTitle="We are working hard to bring you overdrafts please be patient with us :wink :wink "
      />
    </div>
  );
};
