import {
  assertProductTheme,
  getProductColorScheme,
  getThemePrimaryColor,
} from "@/utils/helpers";
import { createContext, useCallback, useContext, useState } from "react";

const LocalStorageKey = "raven-product-theme";

interface ProductThemeContextState {
  productTheme: RavenProducts;
  setProductTheme(theme: RavenProducts): void;
}

const ProductThemeContext = createContext<ProductThemeContextState>({
  productTheme: "personal",
  setProductTheme: () => {
    throw new Error("Provider has not been initialized");
  },
});

export const ProductThemeProvider = ({ children }: IChildren) => {
  const theme = localStorage.getItem(LocalStorageKey);

  const [productTheme, setProductTheme] = useState(assertProductTheme(theme));

  const handleProductThemeChange = useCallback((theme: RavenProducts) => {
    localStorage.setItem(LocalStorageKey, theme);

    setProductTheme(theme);
  }, []);

  return (
    <ProductThemeContext.Provider
      value={{ productTheme, setProductTheme: handleProductThemeChange }}
    >
      {children}
    </ProductThemeContext.Provider>
  );
};

export const useProductTheme = (): ProductThemeContextState => {
  const { productTheme, setProductTheme } = useContext(ProductThemeContext);

  return { productTheme, setProductTheme };
};

export const useProductColor = (product?: RavenProducts) => {
  const { productTheme } = useProductTheme();

  const _product = product ?? productTheme;

  const colorVariable = getProductColorScheme(_product);
  const colorToken = getThemePrimaryColor(_product);

  return {
    colorToken,
    colorVariable,
  } as const;
};
