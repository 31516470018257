import PosOverview from "@/apps/pos/overview";
import PosTransactions from "@/apps/pos/transactions";
import { PosBankCollections } from "@/apps/pos/collections";
import PosTransfers from "@/apps/pos/transfers";
import { PosExpenses } from "@/apps/pos/expenses";
import { PosTerminals } from "@/apps/pos/merchants";
import { MerchantDetails } from "@/apps/pos/merchants/merchant-details";
import { PosVirtualAccounts } from "@/apps/pos/terminals";
import { PosTerminalDetails } from "@/apps/pos/terminals/terminal-details";
import { PosBills } from "@/apps/pos/bills";
import {
  BillsAirtimePage,
  BillsBettingPage,
  BillsCablePage,
  BillsDataPage,
  BillsElectricityPage,
} from "@/apps/pos/bills/components/pages";
import { PosCard } from "@/apps/pos/cards";
import { PosCardDetails } from "@/apps/pos/cards/card-details";
import PosBankbox from "@/apps/pos/bankbox";
import PosBankBoxDetail from "@/apps/pos/bankbox/bankboxDetail";
import PosCustomers from "@/apps/pos/customers";
import PosCompliance from "@/apps/pos/compliance";
import PosInsights from "@/apps/pos/insights";
import PosDailySummary from "@/apps/pos/daily-summary";
import PosFeeDetails from "@/apps/pos/fee-details";
import { PosSettlement } from "@/apps/pos/settlement";
import {
  FailedSettlementPage,
  PendingSettlementPage,
} from "@/apps/pos/settlement/components/pages";
import { PosDeviceRequests } from "@/apps/pos/device-requests";
import PosNotification from "@/apps/pos/notification";
import PosAuditTrail from "@/apps/pos/audit-trail";
import { pos_hni_routes } from "./nested-routes/hni";
import PosHNI from "@/apps/pos/hni";
import PosHniManagers from "@/apps/pos/hni/managers";
import { pos_reversal_center_routes } from "./nested-routes/reversal-center";
import { pos_insights_routes } from "./nested-routes/insights";

export const pos_routes_group: RouteArray[] = [
  { path: "/pos-overview", element: PosOverview },
  { path: "/pos-insights", element: PosInsights },
  { path: "/pos-merchants", element: PosTerminals },
  { path: "/pos-device-requests", element: PosDeviceRequests },
  { path: "/pos-merchants/:id", element: MerchantDetails },
  { path: "/pos-transfers", element: PosTransfers },
  { path: "/pos-transactions", element: PosTransactions },
  { path: "/pos-expenses", element: PosExpenses },
  { path: "/pos-collections", element: PosBankCollections },
  { path: "/pos-terminals", element: PosVirtualAccounts },
  { path: "/pos-daily-summary", element: PosDailySummary },
  { path: "/pos-audit-trails", element: PosAuditTrail },
  { path: "/pos-fee-details", element: PosFeeDetails },
  { path: "/pos-terminals/:id", element: PosTerminalDetails },
  {
    path: "/pos-bills",
    element: PosBills,
    children: [
      { path: "", element: BillsAirtimePage },
      { path: "airtime", element: BillsAirtimePage },
      { path: "data", element: BillsDataPage },
      { path: "electricity", element: BillsElectricityPage },
      { path: "cable-tv", element: BillsCablePage },
      { path: "betting", element: BillsBettingPage },
    ],
  },
  {
    path: "/pos-settlement",
    element: PosSettlement,
    children: [
      { path: "", element: PendingSettlementPage },
      { path: "failed-settlement", element: FailedSettlementPage },
      { path: "pending-settlement", element: PendingSettlementPage },
    ],
  },

  { path: "/pos-cards", element: PosCard },
  { path: "/pos-cards/:id", element: PosCardDetails },
  { path: "/pos-bankbox", element: PosBankbox },
  { path: "/pos-bankbox/:id", element: PosBankBoxDetail },
  { path: "/pos-customers", element: PosCustomers },
  { path: "/pos-compliance", element: PosCompliance },
  { path: "/pos-notification-center", element: PosNotification },
  { path: "/pos-hni", element: PosHNI },
  { path: "/pos-managers", element: PosHniManagers },
  ...pos_reversal_center_routes,
  ...pos_insights_routes,
  // ...pos_hni_routes,
];
