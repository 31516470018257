import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { GLOBAL_PER_PAGE } from "@/constants";
import { PersonalVirtualCardsTable } from "../../../__internals__/combined-tables/cards";
import { usePersonalCardFilter } from "../../../__internals__/internal-hooks/cards";
import { isEmail } from "@/utils/general";
import { cleanWhiteSpace } from "@/utils/colorConvert";

export const PersonalCardVirtualCardsPage = () => {
  const { filterArgs, filterProps, pagination } = usePersonalCardFilter();
  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetVirtualCardQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      email: isEmail(pagination.searchQuery) ? pagination.searchQuery : undefined,
      status: filterArgs.status,
      card_id: !isEmail(pagination.searchQuery) ? pagination.searchQuery : undefined,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (!data || isLoading) return <TransferEmptyScreen loading={joinedLoader} />;

  return (
    <>
      <SmartFilter
        searchTitle="Search by Email or Card Id"
        {...SmartFilterProps}
        {...filterProps}
        hideExport
        tableExport={{
          data: data.virtualCards,
          fileName: cleanWhiteSpace("Virtual_Card_Table"),
        }}
      />

      {data.virtualCards?.length > 0 ? (
        <PersonalVirtualCardsTable
          ctx="page"
          data={data.virtualCards}
          redactedFn={redactedFn}
          refetch={refetch}
        />
      ) : (
        <TransferEmptyScreen loading={joinedLoader} />
      )}
    </>
  );
};
