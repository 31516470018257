import { createContext, useContext, useState } from "react";

export interface UsePaginationState {
  currentPage: number;
  searchQuery: string;
  filterValue: Partial<FilterType>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchQuery: (query: string) => void;
  setFilterValue: React.Dispatch<React.SetStateAction<Partial<FilterType>>>;
}

interface UsePaginationArgs {
  initialPage?: number;
  initialSearchQuery?: string;
  initialFilterValue?: {};
}

interface FilterType {
  type: any[];
  days: string;
  startDate: string;
  endDate: string;
  filterValue: string;
}

export const usePagination = (args?: UsePaginationArgs): UsePaginationState => {
  const {
    initialPage = 1,
    initialSearchQuery = "",
    initialFilterValue = {},
  } = args ?? {};

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [filterValue, setFilterValue] = useState(initialFilterValue);
  const [searchQuery, _setSearchQuery] = useState(initialSearchQuery);

  const handleSearchChange = (query: string) => {
    setCurrentPage(1);
    _setSearchQuery(query);
  };

  return {
    currentPage,
    searchQuery,
    setCurrentPage,
    setSearchQuery: handleSearchChange,
    filterValue,
    setFilterValue,
  };
};

const PaginationContext = createContext<UsePaginationState>({
  currentPage: 1,
  searchQuery: "",
  filterValue: {},
  setCurrentPage: () => {
    throw new Error("Trying to set current page out of provider context");
  },
  setSearchQuery: () => {
    throw new Error("Trying to set search query out of provider context");
  },
  setFilterValue: () => {
    throw new Error("Trying to set filter value out of provider context");
  },
});

interface PaginationProviderProps extends IChildren, UsePaginationState {}

export const PaginationProvider = (props: PaginationProviderProps) => {
  const { children, ...rest } = props;

  return <PaginationContext.Provider value={rest}>{children}</PaginationContext.Provider>;
};

export const usePaginationContext = () => {
  const state = useContext(PaginationContext);

  return state;
};
