import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axios/business";
import paxios from "../utils/axios/personal";
import posaxios from "../utils/axios/pos";
import { getCookie, setCookie } from "../utils/helper/Helper";
import SessionEndModal from "../components/layouts/dashboard/session/SessionExpired";
import {
  AUTH_ERROR_STATUS,
  AUTH_EXPIRED_CUSTOM_EVENT,
  LOGOUT_CUSTOM_EVENT,
  SESSION_COOKIE_NAME,
} from "@/constants";
import { LogOutModal } from "@/components/layouts/dashboard/session/log-out";

const sessionHasExpired = (response: any) => {
  const status =
    AUTH_ERROR_STATUS.includes(response?.data?.message) ||
    AUTH_ERROR_STATUS.includes(response?.data?.status);

  return status;
};

export const LOGOUT = async () => {
  localStorage.clear();

  setCookie(SESSION_COOKIE_NAME, "", 0);
};

const PrivateRoute = ({ children }: any) => {
  const [sessionModal, setSessionModal] = React.useState(false);
  const [logoutModal, setLogOutModal] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthExpired = () => {
      setSessionModal(true);
      LOGOUT();
    };

    window.addEventListener(AUTH_EXPIRED_CUSTOM_EVENT, handleAuthExpired);

    return () => {
      window.removeEventListener(AUTH_EXPIRED_CUSTOM_EVENT, handleAuthExpired);
    };
  }, []);

  useEffect(() => {
    const showLogOutDialog = () => {
      setLogOutModal(true);
    };

    window.addEventListener(LOGOUT_CUSTOM_EVENT, showLogOutDialog);
    return () => {
      window.removeEventListener(LOGOUT_CUSTOM_EVENT, showLogOutDialog);
    };
  }, []);

  let isLoggingOut = true; // global
  axios.interceptors.response.use(async (response: any) => {
    if (sessionHasExpired(response)) {
      isLoggingOut = false;
      if (!isLoggingOut) {
        isLoggingOut = true;
        LOGOUT();
        try {
          setTimeout(() => {
            // setSessionModal(true);
          }, 50);
        } catch (error) {}
        return;
      }
    } else {
      return response;
    }
  });

  paxios.interceptors.response.use(async (response: any) => {
    if (sessionHasExpired(response)) {
      isLoggingOut = false;
      if (!isLoggingOut) {
        isLoggingOut = true;
        LOGOUT();
        try {
          setTimeout(() => {
            // setSessionModal(true);
          }, 50);
        } catch (error) {}
        return;
      }
    } else {
      return response;
    }
  });

  posaxios.interceptors.response.use(async (response: any) => {
    if (sessionHasExpired(response)) {
      isLoggingOut = false;
      if (!isLoggingOut) {
        isLoggingOut = true;
        LOGOUT();
        try {
          setTimeout(() => {
            // setSessionModal(true);
          }, 50);
        } catch (error) {}
        return;
      }
    } else {
      return response;
    }
  });
  const authenticated = getCookie("token");

  useEffect(() => {
    if (!authenticated) {
      setSessionModal(true);
    }
  }, [authenticated]);

  const handleLogOut = () => {
    LOGOUT();
    navigate("/login");
    setLogOutModal(false);
  };

  return (
    <>
      {children}
      <SessionEndModal
        onClick={() => {
          navigate("/login");
          setSessionModal(false);
        }}
        onCancel={() => {
          navigate("/login");
          setSessionModal(false);
        }}
        visible={sessionModal}
      />
      <LogOutModal
        visible={logoutModal}
        onCancel={() => setLogOutModal(false)}
        onClick={handleLogOut}
      />
    </>
  );
};

export default PrivateRoute;
