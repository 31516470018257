import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";
import { BANK_TRANSFER_STATUS_FILTERS } from "../pb-constants";

export const usePbBankTransferExport = (email?: string) => {
  const value = usePersonalExportModal({
    filterParams: [
      {
        key: "status",
        type: "checkbox",
        label: "Status",
        options: BANK_TRANSFER_STATUS_FILTERS.options,
        defaultValue: BANK_TRANSFER_STATUS_FILTERS.options[0].value,
      },
      {
        key: "email",
        type: "email",
        label: "Email",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: Boolean(email),
      },
      {
        key: "account_number",
        type: "account-number",
        label: "Account Number",
        placeholder: "Enter account number",
        errorKey: "Account number",
      },
    ],
    route: "/transfers/export",
    title: "Export Bank transfers",
    btnText: "Export Bank transfers",
    fileName: "Bank Transfer Export",
  });

  return value;
};

export const usePbInternalTransferExport = (email?: string) => {
  const value = usePersonalExportModal({
    fileName: "Internal Transfer Export",
    filterParams: [
      {
        key: "email",
        type: "email",
        label: "Email",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: true,
      },
    ],
    route: "/",
    overrideRoute: "/support/internal-transfers/export",
    title: "Export Internal Transfers",
  });

  return value;
};
