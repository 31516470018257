import "./style/index.css";
import { cn } from "@/utils/colorConvert";
import { Component, ErrorInfo, ReactNode } from "react";
import EmptyScreen from "../emptyScreen";

export const ErrorBoundaryVariant = {
  page: "page",
  component: "component",
} as const;

type ERROR_BOUNDARY_VARIANT = keyof typeof ErrorBoundaryVariant;

interface ErrorBoundaryProps {
  children: ReactNode;
  variant?: ERROR_BOUNDARY_VARIANT;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: { body: Error; info: ErrorInfo };
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private propsVariant: ERROR_BOUNDARY_VARIANT;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.propsVariant = this.props.variant ?? "component";
  }

  // TODO: implement error login
  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, info);
    this.setState({ hasError: true, error: { body: error, info } });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={cn(`${this.propsVariant}-error-boundary`, "error-boundary")}>
          <EmptyScreen
            noMascot
            title="Something Went Wrong"
            subTitle="We are only humans, and apologize you have to see this screen, even engineers make mistake"
          />
        </div>
      );
    }
    return this.props.children;
  }
}
