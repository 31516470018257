import { XStack, YStack } from "@/components/common/stacks";
import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenModal, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "@/components/common/avatar";
import TwinValue from "@/components/common/twinValue";
import SmartFilter from "@/components/common/smartFilter";
import {
  IconVault,
  formatDateTime,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import Dropdown, { DropdownItemType } from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { icons } from "@/assets/icons";
import ActionModal from "@/components/common/modal/ActionModal";
import InvoiceDetails from "./component/InvoiceDetails";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import {
  assertPaginationPageNumber,
  detectStatus,
  formatNumberToCurrency,
} from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";
import EmptyScreen from "@/components/common/emptyScreen";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";

const dropdown: DropdownItemType = [
  {
    label: "View Details",
    img: IconVault(icons.arrow_circle_right_dynamic),
    value: "view",
  },
  { label: "Download Invoice", img: IconVault(icons.download_01), value: "download" },
  { label: "Delete Invoice", img: IconVault(icons.trash_03), value: "delete" },
];

export const BBInvoices = () => {
  const [view, setView] = useState<{ on: boolean; data: any }>({ on: false, data: {} });
  const [deleteInvoice, setDeleteInvoice] = useState<any>("");
  const [downloadInvoice, setDownloadInvoice] = useState<any>("");
  const { business_invoices, loading } = useSelector(
    (state: RootState) => state.businessInvoice
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchInvoices({
        per_page: 20,
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
      })
    );
  }, [currentPage, searchQuery, filterValue]);

  const invoices = business_invoices?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  async function handleDelete() {
    const resp = await dispatch(
      BugissApi.business.deleteInvoice({
        id: String(deleteInvoice.id),
        business_id: String(deleteInvoice.business_id),
      })
    );

    if (resp.payload.status === "success") {
      setDeleteInvoice("");
    }
  }

  async function handleDownload() {
    const resp = await dispatch(
      BugissApi.business.downloadInvoice({
        invoice_id: String(downloadInvoice.id),
        business_id: String(downloadInvoice.business_id),
      })
    );

    if (resp.payload.status === "success") {
      const url = resp.payload.data.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `invoice-reciept-#${downloadInvoice.id}-biz-${downloadInvoice.business_id}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloadInvoice("");
    }
  }

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "business_email",
    "INVOICE NAME": "title",
    "CUSTOMER NAME": "customer_name",
    AMOUNT: "gross",
    CREATED: "created_at",
    "DUE DATE": "due_date",
    STATUS: ["status", "invoice"],
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    // data: invoices,
    apiFunction: BugissApi.business.fetchInvoices,
    loading,
    valuePair,
    dataPath: "invoice_records.data",
    title: "Business Invoices(Bugiss)",
    filterValue,
  });
  const { toggleModal } = useExportModal(handleExport);

  return (
    <>
      {" "}
      <DashboardLayout>
        <PageLayout pageTitle="Invoice">
          <SmartFilter
            onSearchChange={(e: string) => setSearchQuery(e)}
            pagination={{
              currentPage: currentPage,
              itemsPerPage: assertPaginationPageNumber(business_invoices.per_page),
              totalItems: assertPaginationPageNumber(business_invoices.total),
              onPageChange: (page: number) => {
                setCurrentPage(page);
              },
              activeFilter: "All Transactions",
            }}
            searchTitle="Search Invoices..."
            onExport={() => toggleModal("Export Invoices")}
            onFilter={(e: any) => setFilterValue(Util.filterStruct(e))}
          />

          {(!isRedacting && loading) || invoices?.length === 0 || !invoices ? (
            <div className="invoice-loading">
              <EmptyScreen loading={loading} />
            </div>
          ) : (
            <RavenTable
              headerList={[
                "BUSINESS DETAILS",
                "INV. NAME",
                "CUSTOMER NAME",
                "AMOUNT.",
                "DUE DATE",
                "STATUS",
                "ACTION ",
              ]}
            >
              {invoices?.map((data, idx) => (
                <RavenTableRow
                  key={idx}
                  one={
                    loading ? (
                      <Redacted.AvatarWithValue />
                    ) : (
                      <XStack gap={0.8}>
                        <Avatar name={Util.safeValue(data.business_name)} />
                        <TwinValue
                          one={trimLongString(Util.safeValue(data.business_name), 25)}
                          two={Util.safeValue(data.business_email)}
                          copy
                        />
                      </XStack>
                    )
                  }
                  two={
                    loading ? (
                      <Redacted.Table />
                    ) : (
                      <TwinValue one={`#${data.id}`} two={Util.safeValue(data.title)} />
                    )
                  }
                  three={
                    loading ? <Redacted.Table /> : Util.safeValue(data.customer_name)
                  }
                  four={
                    loading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(data.gross ?? 0, "NGN")
                    )
                  }
                  five={loading ? <Redacted.Table /> : formatDateTime(data?.due_date)}
                  six={
                    loading ? (
                      <Redacted.Badge />
                    ) : (
                      formatStatus(detectStatus(data.status, "invoice"))
                    )
                  }
                  seven={
                    loading ? (
                      <Redacted.Avatar />
                    ) : (
                      <Dropdown
                        rightPosition
                        onChange={(e: any) => {
                          if (e.value === "view") {
                            setView({
                              data,
                              on: true,
                            });
                          } else if (e.value === "delete") {
                            setDeleteInvoice({
                              business_id: data.business_id,
                              id: data.id,
                            });
                          } else if (e.value === "download") {
                            setDownloadInvoice({
                              business_id: data.business_id,
                              id: data.id,
                            });
                          }
                        }}
                        buttonChild={() => <ActionDrop more />}
                        items={dropdown}
                      />
                    )
                  }
                />
              ))}
            </RavenTable>
          )}
        </PageLayout>
      </DashboardLayout>
      <div className={"bb-invoice-modal"}>
        <RavenModal
          visble={view.on}
          onClose={() =>
            setView({
              on: false,
              data: {},
            })
          }
          onBtnClick={function (e?: any): void {}}
        >
          <InvoiceDetails data={view.data} />
        </RavenModal>
      </div>
      <ActionModal
        visible={Boolean(deleteInvoice)}
        onCancel={() => {
          setDeleteInvoice("");
        }}
        onClick={handleDelete}
        cancelText="No, Cancel"
        loading={loading}
        actionText="Yes, Delete"
        btnColor="#FF0F00"
        body="Are you sure you want to delete this invoice?.. This action can't be undone."
        title={`Delete Invoice`}
      />
      <ActionModal
        visible={Boolean(downloadInvoice)}
        onCancel={() => {
          setDownloadInvoice("");
        }}
        loading={loading}
        onClick={handleDownload}
        cancelText="No, Cancel"
        actionText="Yes, Download"
        btnColor={"black"}
        body="Are you sure you want to Download this invoice?"
        title={`Download Invoice`}
      />
    </>
  );
};
