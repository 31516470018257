import { business_routes_group } from "@/routes/business";
import { personal_routes_group } from "@/routes/personal";
import { atlas_routes_group } from "@/routes/atlas";
import { pos_routes_group } from "@/routes/pos";

export const overview_routes_group = [
  ...personal_routes_group,
  ...business_routes_group,
  ...atlas_routes_group,
  ...pos_routes_group,
];
