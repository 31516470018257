/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { AvatarWithTwinValue } from "../../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
  mapHotkey,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "../../../../components/common/modal/ActionModal";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "../styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import { detectStatus } from "@/utils/helpers";
import ImagePreview from "@/components/common/imagePreview";
import { cn } from "@/utils/colorConvert";
import { CopyText } from "@/components/common/copyCheck";
import { BIZ_VERIFICATION_STATUS } from "../../__internals__/constants";

const Tabs = [
  {
    label: "BVN Verification",
    value: "bvn",
  },
  { label: "NIN Verification", value: "nin" },

  {
    label: "Address Verification",
    value: "address",
  },
  {
    label: "CAC Verification",
    value: "cac",
  },
  {
    label: "Business Details",
    value: "business_info",
  },
  {
    label: "Details Checker",
    value: "details_checker",
  },
];

const BBvnVerification = ({ tab }: { tab?: string }) => {
  const [empty, setEmpty] = useState(false);
  const [revokeModal, showRevokeModal] = useState({ chi: "", on: false });
  const [declineModal, showDeclineModal] = useState({ chi: "", on: false });
  const [filterValue, setFilterValue] = useState<any>({});
  const [verify, showVerifyModal] = useState<any>({
    chi: "",
    on: false,
    address_url: "",
  });
  const [view, setView] = useState<{ on: boolean; chi: Partial<VerificationDetails> }>({
    chi: {},
    on: false,
  });
  // const navigate = useNavigate();
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<{
    label: string;
    value: string;
  }>({ label: "BVN Verification", value: "bvn" });

  const tableHeader = [
    "Full Name",
    "Email Address",
    "Mobile Number",
    "BVN",
    "Date Logged",
    "Status",
    "Action",
  ];

  const dropdown = [
    {
      label: `Verify ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.info_circle),
      func: showVerifyModal,
    },
    {
      label: `Revoke ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
    {
      label: `Decline ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showDeclineModal,
    },
  ] as const;

  function returnDropdown(status: SN) {
    if (status === 1) {
      return [dropdown[0], dropdown[2]];
    } else if (status === 3) {
      return [dropdown[1]];
    } else return [dropdown[0]];
  }
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: selectedTab.value.trim(),
        // business_email: "",
        current_page: currentPage,
        search_term: searchQuery,
        status: BIZ_VERIFICATION_STATUS.PENDING,
        ...filterValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, currentPage, searchQuery, filterValue]);

  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  type Business = BusinessComplianceModel["business"];
  type Verifications = BusinessComplianceModel["business_verification_details"];
  const businesses: Business = business_compliances?.data?.business;

  const compliance: Verifications[] =
    business_compliances?.data?.business_verification_details;

  const assertVeri = (email: string): any => {
    var data: any = compliance[email as any];

    // const filter = data.filter((d) => d.type === selectedTab.value);
    const l = data?.filter(
      (d: VerificationDetails) => d?.id_verification_type === selectedTab?.value
    )[0];

    if (l) {
      return l;
    } else {
      return [];
    }
  };
  const { isRedacting } = useSelector((store: RootState) => store.general);

  const handleVerifyDecline = async () => {
    let resp;

    const data: Partial<Business[0]> = view.chi;

    if (selectedTab.value === "bvn") {
      resp = await dispatch(
        BugissApi.business.verifyDeclineBVN({
          status: data?.bvn === 3 ? 0 : 1,
          business_id: view.chi.business_owner_id,
          deny_or_revoke: assertIsRevoke(data?.bvn ?? 0),
        })
      );
    }

    if (resp?.payload) {
      showRevokeModal({
        on: false,
        chi: "",
      });

      showVerifyModal({
        on: false,
        chi: "",
      });

      showDeclineModal({
        on: false,
        chi: "",
      });
    }

    setView({ on: false, chi: {} });
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: selectedTab.value.trim(),
        // business_email: "",
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
        status: filterValue?.filterValue?.split(",")[0],
      })
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(Tabs.find((d) => d.value === "bvn") as any);
  }, [tab]);

  return (
    <>
      <div>
        <div style={{ marginTop: "2rem" }}></div>
        <SmartFilter
          page="compliance"
          // simple
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
            // reset current page to 1 on filter
            setCurrentPage(1);
          }}
          defaultFilterBy="status"
          filters={[
            {
              label: "Status",
              filterBy: "status",
              options: [
                {
                  label: "Pending",
                  value: "0",
                },
                {
                  label: "Completed",
                  value: "1",
                },
              ],
            },
          ]}
          onSearchChange={(e: string) => setSearchQuery(e)}
          searchTitle={`Search ${selectedTab.label}...`}
          pagination={{
            currentPage: currentPage,
            itemsPerPage: Number(business_compliances.per_page),
            totalItems: Number(business_compliances.total),
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
            activeFilter: `All ${selectedTab}`,
          }}
        />

        {!businesses || businesses?.length === 0 || (loading && !isRedacting) ? (
          <EmptyScreen loading={loading} />
        ) : (
          <div>
            <div className="table">
              <RavenTable action={false} className="table__main" headerList={tableHeader}>
                {businesses?.map((data, index) => {
                  const veriData: VerificationDetails = assertVeri(data.email);

                  return (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => {
                        // if (!veriData.flow_id) {
                        //   toast.error("No verification details found for user");
                        // } else
                        setView({
                          on: true,
                          chi: {
                            ...veriData,
                            ...data,
                            business_owner_id: data.id,
                            email: data.email,
                          },
                        });
                      }}
                      {...{
                        one: loading ? (
                          <Redacted.AvatarWithValue />
                        ) : (
                          <div className="table-icon-row">
                            <AvatarWithTwinValue
                              name={Util.join(
                                Util.safeValue(
                                  data.business_details_from_central.first_name
                                )
                              ).with(
                                Util.safeValue(
                                  data.business_details_from_central.last_name
                                )
                              )}
                              one={Util.join(
                                Util.safeValue(
                                  data.business_details_from_central.first_name
                                )
                              ).with(
                                Util.safeValue(
                                  data.business_details_from_central.last_name
                                )
                              )}
                              two={Util.safeValue(data.business_name)}
                              // full
                            />
                          </div>
                        ),

                        two: loading ? <Redacted.Table /> : Util.safeValue(data.email),
                        three: loading ? <Redacted.Table /> : Util.safeValue(data.phone),
                        four: loading ? (
                          <Redacted.Table />
                        ) : (
                          <div onClick={(e) => e.stopPropagation()}>
                            <CopyText
                              text={Util.safeValue(
                                data.business_details_from_central.owner_bvn
                              )}
                            />
                          </div>
                        ),
                        five: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(formatDateTime(data.created_at))
                        ),
                        six: loading ? (
                          <Redacted.Table />
                        ) : (
                          formatStatus(
                            detectStatus(data[selectedTab.value], "compliance")
                          )
                        ),
                        seven: loading ? (
                          <Redacted.Badge />
                        ) : (
                          <div>
                            <Dropdown
                              items={returnDropdown(data[selectedTab.value])}
                              defaultValue={dropdown[0]}
                              onChange={(e: any) => {
                                e.event.stopPropagation();
                                setView({
                                  on: false,
                                  chi: {
                                    ...veriData,
                                    business_owner_id: data.id,
                                    alt_email: data.email,
                                    ...data,
                                  },
                                });

                                e.func({ chi: "", on: true });
                              }}
                              className="ragna-dropdown"
                              buttonChild={({ selected }) => (
                                <ActionDrop
                                  more
                                  // onMoreClick={() => {
                                  //   navigate("/personal-users/re43rfs");
                                  // }}
                                />
                              )}
                            />
                          </div>
                        ),
                      }}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </div>
        )}
      </div>

      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          showRevokeModal({ chi: "", on: false });
        }}
        onClick={handleVerifyDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${selectedTab.label}?`}
      ></ActionModal>

      <ActionModal
        visible={declineModal.on}
        onCancel={() => {
          showDeclineModal({ chi: "", on: false });
        }}
        onClick={handleVerifyDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Decline"
        btnColor="#FF0F00"
        body="Are you sure to decline this verification data? You can always come back to perform the action.."
        title={`Decline ${selectedTab.label}?`}
      ></ActionModal>

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        loading={loading}
        cancelText="No, Cancel"
        onClick={handleVerifyDecline}
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure to verify this compliance details? You can always come back to perform the action.."
        title={`Verify ${selectedTab.label}?`}
      ></ActionModal>

      {/* Main Business Info Modal */}
      <div className={cn(selectedTab.value, "biz-info-modal")}>
        <ActionModal
          visible={view.on}
          onCancel={() => {
            setView({ chi: {}, on: false });
          }}
          big={true}
          cancelText="No, Cancel"
          actionText="View Full Profile"
          onClick={() => {
            navigate(
              `/business-businesses/${
                view.chi.email && view.chi.email?.length > 5
                  ? view.chi.email
                  : view?.chi?.alt_email
              }/transactions`
            );
          }}
          btnColor="black"
          loading={loading}
          hideCancel
          body="Are you sure to verify this compliance data? You can always come back to perform the action.."
          title={`${selectedTab.label}`}
        >
          {
            <div className="biz-compliance-results">
              <ImagePreview
                imgData={[
                  {
                    src: `${
                      view.chi.response?.verification?.verification_image ??
                      view.chi?.verification_data?.photo
                    }`,
                    alt: "Image 1",
                    containerClassName: "biz-compliance-results__image-preview", // Optional custom container class
                  },
                  {
                    src: `${
                      view.chi.response?.verification?.user_image ??
                      view.chi?.verification_data?.photo
                    }`,
                    alt: "Image 2",
                    containerClassName: "biz-compliance-results__image-preview", // Optional custom container class
                  },

                  // Add more image data as needed
                ]}
              />

              {/* BVN Compliance Result */}
              <div className="compliance-results__main">
                <InlineDetails
                  direction={""}
                  className="no-shadow"
                  title={"Verification Details"}
                  simple
                  isTransaction={
                    selectedTab.value === "business_info" || selectedTab.value === "cac"
                  }
                  amount={0}
                  onClick={() => {}}
                  content={generalComplianceData(selectedTab, view.chi)}
                />
              </div>
            </div>
          }
        </ActionModal>
      </div>
    </>
  );
};

export default BBvnVerification;

function generalComplianceData(
  selectedTab: { label: string; value: string },
  data: Partial<VerificationDetails>
): {
  label: string;
  value: string;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  return [
    {
      label: selectedTab.label,
      value: Util.safeValue(
        detectIdNumber(selectedTab.value, data.response?.verification)
      ),
      copy: true,
    },
    {
      label: "Fullname",
      value: Util.safeValue(
        Util.join(Util.safeValue(data.response?.first_name)).with(
          Util.safeValue(data.response?.last_name)
        )
      ),
    },

    {
      label: "Mobile Number",
      value: Util.safeValue(detectPhoneNo(data)),
    },

    {
      label: "Address",
      value: Util.join(
        Util.safeValue(capitalizeFirstLetter(data?.verification_data?.residentialAddress))
      ).with(
        capitalizeFirstLetter(
          `${Util.safeValue(
            data?.verification_data?.lga_of_residence
          )} , ${Util.safeValue(data?.verification_data?.state_of_residence)} `
        )
      ),
    },

    {
      label: "Date of Birth",
      value:
        formatDateTime(data?.verification_data?.dateOfBirth ?? "") === "Invalid Date"
          ? Util.safeValue(data?.verification_data?.dateOfBirth as any)
          : formatDateTime(data?.verification_data?.dateOfBirth ?? ""),
    },

    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data.created_at ?? "")),
    },
  ];
}

function detectIdNumber(type: string, data: any) {
  if (type === "bvn") {
    return data && data?.bvn_number;
  } else if (type === "nin") {
    return data && data?.document_number;
  }
}

function detectPhoneNo(data: any) {
  if (data?.response?.verification?.verification_data?.phone?.length > 3) {
    return data?.response?.verification?.verification_data?.phone;
  } else {
    return data && data?.phone_number?.length < 5 ? data?.phone : data?.phone_number;
  }
}

function assertIsRevoke(status: SN) {
  if (status === 3) {
    return "revoke";
  } else return "deny";
}
