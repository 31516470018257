import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { posReduxAPI } from "@/redux/slices/pos/apis";

// Utility function to create an export endpoint
export const createExportEndpoint =
  <N extends string, T>(
    entityName: N,
    originalApi: any = posReduxAPI, // The original API object
  ) =>
  (builder: EndpointBuilder<any, any, any>) => {
    const originalEndpoint = originalApi.endpoints[entityName];

    console.log(originalEndpoint?.route, "the origin");
    // if (!originalEndpoint || !originalEndpoint.route) {
    //   throw new Error(
    //     `Endpoint ${entityName} not found or does not have a route property`,
    //   );
    // }
    const { route } = originalEndpoint;

    return {
      [`${entityName}Export`]: builder.query<T, any>({
        query: (args) => {
          const { page, search, ...rest } = args;
          return {
            url: `${route}/export`,
            params: {
              page,
              search,
              ...rest,
            },
          };
        },
        // Optionally include additional configuration for the new endpoint
      }),
    } as const;
  };
