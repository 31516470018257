import axios from "axios";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const EmbedPDF = ({ source }: { source: string }) => {
  const [pdfData, setPdfData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);
  const baseUrl = window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(source, { responseType: "arraybuffer" });

        if (!response.data) {
          throw new Error("Empty response");
        }

        const blob = new Blob([response.data], { type: "application/pdf" });

        const link = URL.createObjectURL(blob);

        setPdfData(link);
      } catch (error: any) {
        console.error("Error fetching PDF:", error.message);
        setError("Failed to fetch the PDF. Please try again later.");
      }
    };

    fetchPdf();
  }, [source]);

  return (
    <>
      <iframe
        title="PDF Viewer"
        frameBorder="0"
        src={`${baseUrl}/pdf/viewer/web/viewer.html?file=${pdfData}`}
        width="100%"
        height="100%"
      />
    </>
  );
};

export default EmbedPDF;
