import React, { useState } from "react";
import "./style.css";
import { TypeIs } from "@/utils/helpers";

interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  fallbackSrc?: string;
}

const Image: React.FC<ImageWithFallbackProps> = ({ src, fallbackSrc, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState<string>(src);
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleError = () => {
    setImageSrc(fallbackSrc ?? "");
  };

  const handleLoad = () => {
    setLoaded(true);
  };

  // reconstruct width and height

  // if (props.width && TypeIs.string(props.width)) {
  //   prop
  // }

  return (
    <img
      src={imageSrc}
      alt={alt}
      onError={handleError}
      onLoad={handleLoad}
      className={loaded ? "fade-in" : ""}
      {...props}
    />
  );
};

export default Image;
