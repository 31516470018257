import { TabsElement } from "@/components/common/Tabs/shared";
import { iife } from "@/utils/general";
import { Util } from "@/utils/utility";
import { useLocation, useNavigate } from "react-router-dom";

export interface UseOutletURL {
  baseURL: string;
  defaultOutletUrl?: string;
  /** Special urls that you want to always get their state  */
  specialUrls?: string[];
}

export const useOutletURL = (args: UseOutletURL) => {
  const { baseURL, defaultOutletUrl = "/", specialUrls } = args;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, outletURL, ...rest] = pathname.split(baseURL);

  if (typeof outletURL === "undefined") {
    throw new Error(
      `Expected baseURL to include ${pathname}, but got ${baseURL}, please make sure baseURL is correct`
    );
  }

  const pageSlug = outletURL.replaceAll("/", "");

  const urlToReturn = pageSlug || defaultOutletUrl || "/";

  const handleURLChange = (tab: TabsElement) => {
    const tabValue = iife(() => {
      if (typeof tab === "string") return tab;
      return tab.value;
    });

    navigate(`${baseURL}/${tabValue.toLowerCase().replaceAll(" ", "-")}`);
  };

  const specialUrl = iife(() => {
    let url: string | undefined = undefined;
    Util.safeArray(specialUrls).forEach((route) => {
      if (pathname.includes(route)) {
        url = route;
      }
    });

    return url;
  });

  return { pageSlug: specialUrl ?? urlToReturn, handleURLChange };
};
