import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import env from "@/env";
import { sendRequest } from "@/utils/api-methods";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const MerchantPosTerminals = ({ email }: { email?: string }) => {
  const [modal, setModal] = useState<any>("");
  const [isReqn, setIsReqn] = useState(false);

  const { tableData, SmartFilterProps, isRedacting, refetch, combinedLoading } =
    usePosGetQuery<PosTerminals>("getTerminals", { email }, { skip: !email });

  const merchants = tableData;

  const navigate = useNavigate();

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/de_re_activate",
      {
        tid: modal.tid,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }
  return (
    <>
      <SmartFilter {...SmartFilterProps}  />

      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "TERMINAL DETAILS",
            "TYPE",
            "VOLUME",
            "TRXS",
            "DATE IMBURSED",
            "STATUS",
            " ",
          ]}
        >
          {merchants?.map((data) => (
            <RavenTableRow
              onRowClick={() => {
                navigate(`/pos-terminals/${data.terminalDetail.id}`);
              }}
              one={
                isRedacting ? (
                  <Redacted.AvatarWithValue />
                ) : (
                  <TwinValue
                    one={Util.safeText(data?.business?.businessName)}
                    two={Util.safeText(data?.business?.businessEmail)}
                  />
                )
              }
              two={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  <TwinValue
                    one={Util.safeText(data?.terminalDetail?.serial)}
                    two={`TID:${Util.safeText(data?.terminalDetail?.tid)}`}
                  />
                )
              }
              three={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  Util.safeValue(Util.capitalizeEachWord(data.terminalDetail?.type))
                )
              }
              four={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  Util.safeValue(data.summary?.totalVolume ?? undefined)
                )
              }
              five={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  Util.safeValue(data.summary?.totalCount)
                )
              }
              six={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  formatDateTime(data.terminalDetail?.created_at)
                )
              }
              seven={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  formatStatus(
                    Boolean(data.terminalDetail?.status) ? "active" : "deactivated"
                  )
                )
              }
              eight={
                isRedacting ? (
                  <Redacted.Avatar />
                ) : (
                  <Dropdown
                    rightPosition
                    items={[
                      { label: "View Details", img: IconVault(icons.edit_04) },
                      {
                        label: Boolean(data.terminalDetail?.status)
                          ? "Disable Terminal"
                          : "Activate Terminal",
                        img: IconVault(icons.user_profile_x),
                      },
                      // { label: "Generate Statement", img: IconVault(icons.file) },
                      // { label: "Block Merchant", img: IconVault(icons.slash_circle_01) },
                    ]}
                    buttonChild={() => {
                      return <ActionDrop more />;
                    }}
                    onChangeWithEvent={(e) => {
                      e.event.stopPropagation();
                      if (e.label.includes("View")) {
                        navigate(`${data.terminalDetail?.id}`);
                      }

                      if (e.label.includes("Disable") || e.label.includes("Activate")) {
                        setModal({
                          tid: data.terminalDetail?.tid,
                          label: Boolean(data.terminalDetail?.status)
                            ? "Disable"
                            : "Activate",
                        });
                      }
                    }}
                  />
                )
              }
            />
          ))}
        </RavenTable>
      )}

      <ActionModal
        visible={Boolean(modal)}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleActivateDeactivate();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${modal?.label}`}
        btnColor={"red"}
        body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
        title={`${modal?.label} Terminal?`}
      ></ActionModal>
    </>
  );
};
