import ActionModal from "@/components/common/modal/ActionModal";
import { useProductColor } from "@/context/ProductTheme";

interface SessionEndModalProps {
  visible: boolean;
  onCancel: () => void;
  onClick: () => void;
}

export const LogOutModal = (props: SessionEndModalProps) => {
  const { visible, onCancel, onClick } = props;
  const { colorVariable } = useProductColor();

  return (
    <ActionModal
      visible={visible}
      onCancel={onCancel}
      onClick={onClick}
      cancelText="No, Continue"
      actionText="Yes, Logout"
      btnColor="red"
      body="Are you sure you want to logout"
      title="Logout"
    />
  );
};
