import { RavenToolTip } from "@ravenpay/raven-bank-ui";
import React, { ReactElement, ReactNode } from "react";
import { icons } from "../../../assets/icons";
import "./style/index.css";
import Copy, { CopyText } from "../copyCheck";
import CodePreview from "../codePreview/CodePreview";
import { cn } from "@/utils/colorConvert";
import { TypeIs } from "@/utils/helpers";

export interface InlineDetailsContent {
  label: string | ReactNode;
  value: string | ReactElement | ReactNode;
  copy?: boolean;
  trim?: boolean;
  tooltip?: boolean;
  tooltipValue?: string;
  tooltipPosition?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  newCopy?: boolean;
  onClick?: () => void;
}

type Modal = {
  direction?: string;
  title?: string;
  simple?: boolean;
  amount?: number;
  className?: string;
  meta?: string;
  onClick?: Function;
  isTransaction?: boolean;
  directionTitle?: string;
  content: InlineDetailsContent[];
};

const InlineDetails = ({
  title,
  content,
  direction,
  directionTitle,
  className,
  meta,
  isTransaction,
  simple,
}: Modal) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const renderValue = (value: string | ReactNode, trim = false) => {
    if (!trim) return value;

    if (TypeIs.string(value)) {
      return value?.length > 20 ? value?.slice(0, 20) + "..." : value;
    }

    return value ?? "--";
  };

  return (
    <div className={cn("inline-trx-details-main-wrapper", className)}>
      {!simple && <div className="close-btn">{icons.x}</div>}
      <div className="transaction-details-modal details-modal-wrap ">
        {!simple && (
          <h5 className="details-modal-wrap__title">{title || "Transfer Details"}</h5>
        )}

        <div className="details-modal-wrap__content">
          <div className="content__main">
            <div
              className={cn(
                "main__account-details",
                !collapsed && "collapsed",
                collapsed && "not-collapsed"
              )}
            >
              {direction && (
                <div
                  className={`account-details__item ${
                    isTransaction ? "transaction" : ""
                  } `}
                >
                  <p>{directionTitle || "Direction"}</p>
                  <div>
                    <div className={`badge badge--${direction}`}>
                      <p>
                        {direction?.slice(0, 1).toUpperCase() + direction?.slice(1, 10)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {content?.map((chi, idx) => {
                return (
                  <div
                    onClick={chi.onClick}
                    key={idx}
                    style={{ overflow: chi.tooltip ? "unset" : "hidden" }}
                    className={cn(
                      "account-details__item",
                      isTransaction && "transaction",
                      "tooltip-hover-wrap"
                    )}
                  >
                    <p>{chi?.label}</p>
                    <div className="item__value">
                      <div className={cn(chi.tooltip, "item__value__item")}>
                        {!chi.copy && renderValue(chi.value, chi.trim)}
                      </div>

                      {TypeIs.string(chi.value) ? (
                        chi.copy ? (
                          <CopyText
                            text={String(renderValue(chi.value, chi.trim))}
                            textToCopy={String(chi?.value)}
                          />
                        ) : null
                      ) : null}
                    </div>

                    {TypeIs.string(chi.value)
                      ? chi?.value?.length > 5 &&
                        chi.tooltip && (
                          <RavenToolTip
                            color={`black-light`}
                            text={chi.tooltipValue ?? chi.value}
                            className={"inline-tooltip"}
                            position={chi.tooltipPosition ?? `top-right`}
                          />
                        )
                      : null}
                  </div>
                );
              })}
            </div>
            {meta && (
              <div className={`meta_preview_wrapper ${!collapsed && "expanded"}`}>
                <div className={`meta_preview ${collapsed && "collapsed"}`}>
                  <CodePreview json={JSON.parse(meta)} />
                </div>
              </div>
            )}

            <div className="inline-trx-details__button-wrap">
              {meta && (
                <button onClick={() => setCollapsed(!collapsed)}>
                  <p>{collapsed ? "View MetaData" : "Collapse MetaData"}</p>
                  <figure>{icons.copy}</figure>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InlineDetails;
