import "./style/index.css";

import { XStack, YStack } from "@/components/common/stacks";
import { useOutletURL } from "@/hooks/useOutletURL";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { symbol } from "@/utils/helper/Helper";
import { cn } from "@/utils/colorConvert";
import { useEffect, useState } from "react";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { TabsElement } from "@/components/common/Tabs";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES } from "@/constants";
import Gap from "@/components/common/styleComponents/Gap";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalCurrency } from "@/redux/actions/actions";
import Image from "@/components/common/image/Image";
import Redacted from "@/components/common/shimmer/redact";

const activeClass = "bglobal-accounts__tab__link--active";

export const BBGlobalWallets = () => {
  const { data, isFetching, isLoading, refetch } = businessReduxAPI.useGetCurrenciesQuery(
    {}
  );

  const currencies = data?.all_support_currencies;
  const activeTab = useSelector(
    (state: RootState) => state.general
  ).selectedGlobalCurrency;

  const setActiveTab = (d: any) => {
    dispatch(setGlobalCurrency(d));
  };
  useEffect(() => {
    currencies && setActiveTab(currencies![1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies]);

  const Tabs: TabsElement[] = [
    {
      label: "Accounts",
      value: "accounts",
    },
    { label: "Transfers", value: "transfers" },

    {
      label: "Deposits",
      value: "deposits",
    },
  ];

  const dispatch = useDispatch();

  const loading = isFetching || isLoading || !currencies;

  const loadMap = [0, 1, 2, 3, 4, 5, 6];
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Global Account">
        <XStack gap={0} className="bglobal-accounts">
          <YStack className="bglobal-accounts__tab hide-scrollbar">
            {loading
              ? loadMap.map((d) => (
                  <div>
                    <Redacted.AvatarWithLabelled gap={5} />
                  </div>
                ))
              : currencies
                  ?.filter((d) => d?.code !== "NGN")
                  .map((item) => {
                    return (
                      <div
                        onClick={() => setActiveTab(item)}
                        className={cn(
                          "bglobal-accounts__tab__item cursor-pointer",
                          activeTab.code === item.code && activeClass
                        )}
                      >
                        <figure className="currency_flag">
                          <Image
                            src={`https://businessapi.getraventest.com/files/currencies/${item.code.toLowerCase()}.png`}
                            alt={item.name}
                            fallbackSrc="/assets/eur.png"
                          />
                        </figure>
                        <div className="flex col currency_name">
                          <p>{item.name} </p>
                          <small>{item.code}</small>
                        </div>
                      </div>
                    );
                  })}
          </YStack>
          <div className="bglobal-accounts__content">
            <OutletTab>
              <OutletTab.Tabs
                tabs={Tabs}
                className={GENERAL_CLASSES.OUTLET}
                baseURL="/business-global-account"
              >
                <Gap size={10} />
                <OutletTab.Outlet />
              </OutletTab.Tabs>
            </OutletTab>{" "}
          </div>
        </XStack>{" "}
      </PageLayout>
    </DashboardLayout>
  );
};
