import { getQueryVariable } from "@/utils/helpers";
import { PersonalTransferInternalPage } from "./internal-transfer-page";
import { BankPage } from "./bank-transfer-page";
import { BillsPage } from "./bills-page";
import { useLocation } from "react-router-dom";
import { SCHEDULED_TRANSACTION_TYPES } from "../__internals__/constants";

export const PersonalScheduledBankTransfersPage = () => {

  const location = useLocation()

  const selectedTab = getQueryVariable("type");

  const renderSelected = () => {
    switch (selectedTab) {
      case "internal":
        return <BankPage type="INTERNAL_TRANSFER" />;
      case "bank":
        return <BankPage type="EXTERNAL_TRANSFER" />;
      default:
        return <BankPage type="INTERNAL_TRANSFER" />;
    }
  };

  return renderSelected();
};



export const PersonalScheduledBills = () => {
  const location = useLocation();
  const currentTabViaPath = location.pathname.split('/')[2];

  const getBillType = () => {
    switch (currentTabViaPath) {
      case 'airtime':
        return 'AIRTIME_RECHARGE';
      case 'data':
        return 'DATA_RECHARGE';
      case 'electricity':
        return 'ELECTRICITY_RECHARGE';
      case 'cable':
        return 'CABLE_RECHARGE';
      default:
        return 'AIRTIME_RECHARGE'; // Default to airtime if path is not recognized
    }
  };

  return <BillsPage type={getBillType()} />;
};
