export const requiredErrorMessage = (fieldName: string) => {
  return `${fieldName} is required`;
};

export const invalidErrorMessage = (fieldName: string) => {
  return `${fieldName} is invalid`;
};

export const lowerLimitErrorMessage = (fieldName: string, limit: number) => {
  return `${fieldName} should be at least ${limit} characters`;
};

export const upperLimitErrorMessage = (fieldName: string, limit: number) => {
  return `${fieldName} should not be more than ${limit} characters`;
};

export const noNumberErrorMessage = (fieldName: string) => {
  return `${fieldName} cannot contain numbers`;
};

export const noSpecialCharErrorMessage = (fieldName: string) => {
  return `${fieldName} cannot have special character`;
};

export const invalidEmailErrorMessage = (fieldName: string) => {
  return `${fieldName} must be a valid email`;
};

export const invalidUsernameErrorMessage = (fieldName: string) => {
  return `${fieldName} should contain only letters, numbers and underscores`;
};

export const createDefaultError = (key: string) => ({
  required_error: requiredErrorMessage(key),
  invalid_type_error: invalidErrorMessage(key),
});

export const WEAK_PASSWORD_ERROR_MESSAGE =
  "Weak password. Password must have atleast 8 characters";

export const INVALID_PASSWORD_WHITE_SPACE_ERROR_MESSAGE =
  "Password should not contain space";
