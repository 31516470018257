import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Txreference from "@/components/common/reference/TxRef";
import Dropdown from "@/components/common/dropdown";
import { IconVault, formatStatus } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import { useNavigate } from "react-router-dom";

const BusinessBankbox = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });

  const dropdown = [
    {
      label: `Mark as Paid`,
      img: IconVault(icons.check_circle),
      func: showMarkPaid,
    },
    {
      label: `Refire`,
      img: IconVault(icons.refire),
      func: showRefireModal,
    },
  ] as const;

  const mockData = [
    {
      businessDetails: {
        name: "ABC Company",
        email: "abc@example.com",
      },
      employees: "55",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "123456789",
      senderDetails: {
        name: "John Doe",
        account: "Opay • 987654321",
      },
      status: "active",
      amount: "₦500,000.00",
      dateDeposited: "1 Jan, 2024 • 10:15AM",
    },
    {
      businessDetails: {
        name: "XYZ Ltd",
        email: "xyz@example.com",
      },
      employees: "13",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "987654321",
      senderDetails: {
        name: "Jane Smith",
        account: "Flutterwave • 123456789",
      },
      status: "deactivated",

      amount: "₦750,000.00",
      dateDeposited: "15 Feb, 2024 • 2:30PM",
    },
    {
      businessDetails: {
        name: "PQR Corp",
        email: "pqr@example.com",
      },
      employees: "23",
      pendingPayments: "₦203,270,000.00",
      overdraft: "₦23,324,000.00",
      reference: "345678912",
      senderDetails: {
        name: "Robert Johnson",
        account: "PayPal • 456789012",
      },
      status: "active",

      amount: "₦1,200,000.00",
      dateDeposited: "20 Mar, 2024 • 4:45PM",
    },
  ];

  const navigate = useNavigate();
  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Bankbox">
          <SmartFilter
            pagination={{
              currentPage: 0,
              itemsPerPage: 0,
              totalItems: 0,
              onPageChange: function (page: number): void {
                throw new Error("Function not implemented.");
              },
              activeFilter: "All Pending Deposits",
            }}
            searchTitle="Search pending deposits..."
          />

          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "Business Details",
                "Terminal Details",
                "Volumen",
                "Count",
                "Date Disbursed",
                "Status",
                "Action",
              ]}
            >
              {mockData.map((data, index) => (
                <RavenTableRow
                  key={index}
                  onRowClick={() => navigate("details")}
                  one={
                    <div style={{ display: "flex", alignItems: "center", gap: "1.2rem" }}>
                      <Avatar name={data.businessDetails.name} />
                      <TwinValue
                        one={data.businessDetails.name}
                        two={data.businessDetails.email}
                      />
                    </div>
                  }
                  two={
                    <TwinValue
                      one={data.businessDetails.name}
                      two={data.businessDetails.email}
                    />
                  }
                  three={data.pendingPayments}
                  four={"43"}
                  five={data.dateDeposited}
                  six={formatStatus(data.status as string)}
                  seven={
                    <ActionDrop
                      more
                      onMoreClick={() => {
                        navigate("3fmio34");
                      }}
                    />
                  }
                />
              ))}
            </RavenTable>
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessBankbox;
