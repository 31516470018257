import {
  HeightWithMarginOffset,
  TransferEmptyScreen,
} from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import {
  mapTransfersPagination,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { useSingleUserState } from "../../../context";
import { PersonalInternalTransferOutTable } from "~pb/__internals__/combined-tables/transfers-table";
import { usePbInternalTransferExport } from "@/apps/personal/__internals__/internal-hooks/transfers";

export const PersonalSingleUserInternalPage = () => {
  const { filterArgs, filterProps, pagination } = useSmartFilterCustomFilter([
    {
      label: "status",
      key: "inUserPageType",
      options: [
        { label: "Sent Out", value: "external" },
        { label: "Received", value: "internal" },
      ],
      defaultValue: "external",
    },
  ]);
  const { username, email } = useSingleUserState();
  const { currentPage, searchQuery } = pagination;
  const { data, isFetching, isLoading } =
    personalReduxAPI.useGetAllInternalTransfersQuery({
      page: currentPage,
      per_page: GLOBAL_PER_PAGE,
      username,
      email,
      search: searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
      inUserPageType: filterArgs?.inUserPageType,
    });

  const { ExportModal, onExport } = usePbInternalTransferExport(email);

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />;

  return (
    <HeightWithMarginOffset>
      <SmartFilter {...SmartFilterProps} {...filterProps} onExport={onExport} />
      {data.internalTransfers.length > 0 ? (
        <PersonalInternalTransferOutTable
          redactedFn={redactedFn}
          ctx="user"
          data={data.internalTransfers}
        />
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </HeightWithMarginOffset>
  );
};
