import { TypeIs } from "@/utils/helpers";
import { PersonalBaseParams, PersonalBuilder, pbGetHandler } from "..";

interface BaseParam extends PersonalBaseParams {
  email: string;
  search: string;
  currency: string;
  status: SN;
}

type DepositsParams = Partial<BaseParam>;

type DepositsResponse = CreatePersonalPaginatedResponse<
  "deposits",
  PersonalDepositsModel[]
>;
type ManualDepositsResponse = CreatePersonalPaginatedResponse<
  "deposits",
  ManualDepositsQueueModel[]
>;

export const PersonalDepositsAPI = (builder: PersonalBuilder) => {
  return {
    getUserDeposits: builder.query<DepositsResponse, DepositsParams>({
      queryFn: (params) => {
        const url = "user/deposits";
        const route = params.search ? `${url}/search` : url;
        return pbGetHandler({ route, params: TypeIs.any(params) });
      },
    }),
    getManualDepositQueue: builder.query<ManualDepositsResponse, DepositsParams>({
      queryFn: (params) => {
        const route = "manual-deposit-queue";
        return pbGetHandler({ route, params: TypeIs.any(params) });
      },
    }),
  };
};
