import { assertCardType } from "@/assets/images/cards";
import Avatar, { AvatarWithTwinValue } from "@/components/common/avatar";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePaginationContext } from "@/hooks/usePagination";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatNumWithComma, symbol } from "@/utils/helper/Helper";
import { assertPaginationPageNumber } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const headerList = [
  "BUSINESS DETAILS",
  "CARD NAME",
  "CARD NUMBER",
  "CURR.",
  "BALANCE",
  "EXP. DATE",
  "DATE CREATED",
];

export const BusinessVirtualCardsPage = () => {
  const { card_details, loading } = useSelector(
    (store: RootState) => store.businessCards
  );
  const { isDarkMode } = useThemeMode();
  const { currentPage, setCurrentPage, searchQuery, setSearchQuery, setFilterValue } =
    usePaginationContext();

  const { isRedacting } = useSelector((store: RootState) => store.general);
  const { renderRedacted } = renderRedactedComponent(isRedacting);

  const navigate = useNavigate();

  useEffect(() => {
    setFilterValue({});
  }, [searchQuery]);

  return (
    <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
      <SmartFilter
        onSearchChange={setSearchQuery}
        onFilter={(e: any) => {
          setFilterValue(e);
        }}
        pagination={{
          currentPage: assertPaginationPageNumber(currentPage),
          itemsPerPage: assertPaginationPageNumber(card_details.per_page),
          totalItems: assertPaginationPageNumber(card_details.total),
          onPageChange: setCurrentPage,
          onFilterChange: setFilterValue,
          activeFilter: "All Cards",
        }}
      />
      {!isRedacting && loading ? (
        <EmptyScreen loading={loading} />
      ) : (
        <>
          {card_details.data?.length > 0 ? (
            <RavenTable headerList={headerList}>
              {card_details.data.map((card) => {
                const {
                  id,
                  main_card_object,
                  business_name,
                  business_email,
                  card_name,
                  created_at,
                  business_id,
                } = card;

                return (
                  <RavenTableRow
                    key={id}
                    onRowClick={() => navigate(`${id}?biz=${business_id}`)}
                    one={renderRedacted(
                      <AvatarWithTwinValue
                        name={business_name}
                        one={business_name}
                        two={Util.safeValue(business_email)}
                      />,
                      "Labelled"
                    )}
                    two={renderRedacted(card_name, "Table")}
                    three={renderRedacted(
                      <Avatar
                        name={main_card_object.card_number}
                        full
                        imgSrc={assertCardType(main_card_object.brand)}
                      />,
                      "AvatarWithValue"
                    )}
                    four={renderRedacted(main_card_object.currency, "Table")}
                    five={renderRedacted(
                      cn(
                        symbol(main_card_object.currency.toLowerCase()),
                        formatNumWithComma(main_card_object.balance)
                      ),
                      "Table"
                    )}
                    six={renderRedacted(
                      cn(
                        main_card_object.expiry_month,
                        "/",
                        main_card_object.expiry_year
                      ),
                      "Table"
                    )}
                    seven={renderRedacted(formatDateTime(new Date(created_at)), "Table")}
                  />
                );
              })}
            </RavenTable>
          ) : (
            <div style={{ height: "calc(100% - 65px)", paddingTop: "1rem" }}>
              <EmptyScreen />
            </div>
          )}
        </>
      )}
    </div>
  );
};
