import { BugissApi } from "@/redux/slices";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface ITransactionSwitch {
  identifier: string;
  payload: Partial<PaginationQuery>;
}

// Define async thunk action creator
// TODO: reassess this
export const fetchBizTransactions = createAsyncThunk<any, ITransactionSwitch>(
  "transactions/fetchData",
  async (payload, { dispatch }) => {
    const { identifier, payload: requestPayload } = payload;

    switch (identifier) {
      case "transactions":
        return dispatch(BugissApi.business.fetchBusinessTrx(requestPayload));
      case "transfer":
        return dispatch(BugissApi.business.fetchSingleBusinessTransfer(requestPayload));
      case "internal-transfer":
        return dispatch(BugissApi.business.fetchSingleInternalTransfer(requestPayload));
      case "bulk-transfer":
        return dispatch(BugissApi.business.fetchBulkTransfer(requestPayload));
      case "bulk-transfer-details":
        return dispatch(BugissApi.business.fetchBulkTransferTrxTwo(requestPayload));
      case "airtime":
        return dispatch(BugissApi.business.fetchAirtime(requestPayload));
      case "data":
        return dispatch(BugissApi.business.fetchData(requestPayload));
      case "cable":
        return dispatch(BugissApi.business.fetchCable(requestPayload));
      case "electricity":
        return dispatch(BugissApi.business.fetchElectricity(requestPayload));
      case "betting":
        return dispatch(BugissApi.business.fetchBetting(requestPayload));
      case "cards":
        return dispatch(BugissApi.business.fetchCards(requestPayload));
      default:
        throw new Error(`Unsupported API identifier: ${identifier}`);
    }
  }
);
