import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { useProductColor } from "@/context/ProductTheme";
import { PersonalAPIService } from "@/redux/slices/personal";
import { APIResponseHandler } from "@/utils/api";
import { capitalizeFirstLetterOnly, formatWord } from "@/utils/formatWord";
import { createDialogText } from "@/utils/helpers";

export type SelectedAction = PersonalTransferActionsSelectedAction;

interface Props {
  action: SelectedAction;
  refetch: RefetchFn;
}

export const TransferActionsModal = (props: CreateDetailsProps<Props>) => {
  const { onCancel, refetch } = props;
  const { ctx } = props.action;
  const [loading, setLoading] = useState(false);
  const { colorToken } = useProductColor();

  const handleFreezeOrUnfreeze = async () => {
    setLoading(true);
    try {
      const response = PersonalAPIService.markTransferAsPaidOrRefunded(props.action);
      APIResponseHandler.tryHandler({ response });
      await refetch();
      onCancel();
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const action =
    ctx === "mark_withdrawal_as_paid" ? formatWord(ctx) : "Refund this withdrawal";

  const btnText = ctx === "mark_withdrawal_as_paid" ? "Mark As Paid" : "Refund";

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleFreezeOrUnfreeze}
      cancelText="No, Cancel"
      actionText={btnText}
      btnColor={colorToken}
      body={createDialogText(capitalizeFirstLetterOnly(action))}
      title={formatWord(ctx)}
    />
  );
};
