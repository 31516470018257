import axios from "axios";
import { PersonalBuilder, pbGetHandler } from "..";
import { APIResponseHandler } from "@/utils/api";
import { Util } from "@/utils/utility";

export interface GetAllElectricityProviders {
  label: string;
  value: string;
}

export const PersonalGeneralAPI = (builder: PersonalBuilder) => {
  return {
    getUsersAccounts: builder.query<PersonalGetUserAccounts[], string>({
      queryFn: (email) => {
        return pbGetHandler({ route: "user-accounts", params: { email } });
      },
    }),
    getElectricityProviders: builder.query<GetAllElectricityProviders[], void>({
      queryFn: () => {
        return pbGetHandler({
          route: "electricity-providers",
          params: {},
          mutateData: (data) =>
            Util.safeArray(data).map((opt: any) => ({
              label: opt?.name,
              value: opt?.code,
            })),
        });
      },
    }),
    getAllBanks: builder.query<GetAllBanks[], void>({
      queryFn: async () => {
        try {
          const res = await axios.get("https://api.getravenbank.com/v2/banks");
          const success = APIResponseHandler.tryHandler({ response: res });
          if (success) return res.data;
        } catch (error) {
          APIResponseHandler.catchHandler(error);
        }
      },
    }),
  };
};
