import React from "react";

const Gap = ({ h, v, size }: { v?: SN; h?: SN; size?: SN }) => {
  return (
    <div
      style={{
        marginTop: `${v || size}px`,
        marginLeft: `${h ?? size}px`,
      }}
    ></div>
  );
};

export default Gap;
