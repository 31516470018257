import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import {
  mapTransfersPagination,
  useSmartFilterCustomFilter,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWithDayJs } from "@/utils/helpers";
import { GENERAL_CLASSES, GLOBAL_PER_PAGE } from "@/constants";
import { iife } from "@/utils/general";
import { PersonalBankTransferTable } from "~pb/__internals__/combined-tables/transfers-table/index";
import { BANK_TRANSFER_STATUS_FILTERS } from "~pb/__internals__/pb-constants";
import { usePbBankTransferExport } from "../../__internals__/internal-hooks/transfers";

interface BankPageProps {
  type: "externalTransfer" | "withdrawalIssues" | "stalledWithdrawals";
}

const statusFilters = BANK_TRANSFER_STATUS_FILTERS;

const filterStatus = (type: BankPageProps["type"]) => {
  return type === "withdrawalIssues" || type === "stalledWithdrawals"
    ? []
    : [statusFilters];
};

export const BankPage = ({ type }: BankPageProps) => {
  const { filterArgs, filterProps, pagination } = useSmartFilterCustomFilter(
    filterStatus(type)
  );
  const { currentPage, searchQuery } = pagination;

  const queryFn = iife(() => {
    if (type === "externalTransfer") {
      return "useGetAllExternalTransfersQuery";
    }
    return "useGetExternalTransfersNotReversedQuery";
  });

  const { end_date, start_date } = iife(() => {
    const { endDate, startDate } = filterArgs;

    const start_date = startDate ? formatWithDayJs(startDate, "YYYY-MM-DD") : undefined;
    const end_date = endDate ? formatWithDayJs(endDate, "YYYY-MM-DD") : undefined;

    return { start_date, end_date };
  });

  const { data, isLoading, isFetching, refetch } = personalReduxAPI[queryFn]({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    search: searchQuery,
    end_date,
    start_date,
    status: filterArgs.status,
    stalled: type === "stalledWithdrawals" ? 1 : 0,
  });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  const { onExport, ExportModal } = usePbBankTransferExport();

  if (isLoading || !data)
    return (
      <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP, "")}>
        <EmptyScreen loading={isLoading} />
      </div>
    );

  return (
    <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP)}>
      <SmartFilter
        {...SmartFilterProps}
        {...filterProps}
        onExport={onExport}
        hideExport={type === "withdrawalIssues" || type === "stalledWithdrawals"}
      />
      {data.transfers.length > 0 ? (
        <PersonalBankTransferTable
          ctx="page"
          data={data.transfers}
          redactedFn={redactedFn}
          refetch={refetch}
        />
      ) : (
        <TransferEmptyScreen />
      )}
      <ExportModal />
    </div>
  );
};
