import React from "react";
import "../styles/dispute-reply/dispute-reply.css";

const DisputeReply: React.FC = () => {
  return (
    <div className="bg-disputes__reply">
      <div className="bg-disputes__reply-user">
        <img
          src="https://play-lh.googleusercontent.com/jA5PwYqtmoFS7StajBe2EawN4C8WDdltO68JcsrvYKSuhjcTap5QMETkloXSq5soqRBqFjuTAhh28AYrA6A=w240-h480-rw"
          alt="User Avatar"
          className="bg-disputes__reply-avatar"
        />
        <div className="bg-disputes__reply-userinfo">
          <h4 className="bg-disputes__reply-username">Oladipupo Pelumni</h4>
          <span className="bg-disputes__reply-email">
            oladipupo.pelumni@getravenbank.com
          </span>
        </div>
      </div>
      <h3 className="bg-disputes__reply-title">
        Reply: Transfers failed but i have not gotten a refund...
      </h3>
      <p className="bg-disputes__reply-content">
        Thank you for providing the necessary details for us to assist you further. We
        appreciate your cooperation. Rest assured, our team is diligently working on
        reversing the failed transaction.
      </p>
      <p className="bg-disputes__reply-content">
        We will keep you updated on the progress and ensure that the funds are promptly
        returned to your account. Your patience and understanding are greatly appreciated
        as we work to resolve this matter to your satisfaction.
      </p>
    </div>
  );
};

export default DisputeReply;
