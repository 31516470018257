import { useSmartFilterCustomFilter } from "@/hooks/useSmartFilterProps";
import { PERSONAL_DEPOSIT_STATUS } from "../combined-tables/deposits/utils";
import { usePersonalExportModal } from "../export-modal/use-personal-export-modal";
import { CUSTOM_FILTER_VALUE_FOR_ALL } from "@/constants";

export const usePbExportTable = (email?: string) => {
  const value = usePersonalExportModal({
    fileName: "Deposits",
    filterParams: [
      {
        key: "status",
        label: "Status",
        options: PERSONAL_DEPOSIT_STATUS,
        type: "checkbox",
        defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
      },
      {
        key: "email",
        type: "email",
        label: "Email",
        placeholder: "Enter email",
        defaultValue: email,
        hidden: Boolean(email),
      },
    ],
    route: "/deposits/export",
    title: "Export Deposits",
  });

  return value;
};

export const usePbDepositsFilter = (email?: string) => {
  const { filterProps, ...response } = useSmartFilterCustomFilter([
    {
      label: "status",
      options: PERSONAL_DEPOSIT_STATUS,
      defaultValue: CUSTOM_FILTER_VALUE_FOR_ALL,
    },
  ]);

  const { onExport, ExportModal } = usePbExportTable(email);

  return { ...response, filterProps: { ...filterProps, onExport }, ExportModal };
};
