import axios from "axios";
import env from "../../env";
import { encrypt2 } from "../encrypt";
import { getCookie } from "../helper/Helper";

const API = env.business_api;

/** base url to make request to the BE end point */

const baxios = axios.create({
  baseURL: API,
});

baxios.interceptors.request.use(
  async (config: any) => {
    const _reToken = getCookie("g-rec-response");
    if (_reToken) {
      config.headers["g-rec-response"] = _reToken;
    }
    try {
      const encryptedData = await encrypt2(config.data ?? "");

      // config.data = { data: encryptedData };
    } catch (error) {
      // logger.log(error);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

baxios.interceptors.response.use(
  async (response: any) => {
    // response.data.data = decrypt2(response.data.data);
    delete response.config.transformRequest;
    delete response.data?.header;
    return response;
  },
  async (error: any) => {
    let val;

    // logger.log(decrypt2(error.response.data.data), "the error");
    if (error.response.data.status === "fail") {
      val = error.response;
      delete val.config.transformRequest;
    }

    // val.data.data = await decrypt2(val.data.data);

    return val;
  },
);

export default baxios;

export { baxios as BusinessAPI };
