import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { EmptyShell } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { GLOBAL_PER_PAGE } from "@/constants";
import { PersonalTransactionTable } from "~pb/__internals__/combined-tables/transaction-table";

export const PbTransactions = () => {
  const pagination = usePagination();
  const { currentPage } = pagination;
  const { data, isLoading, isFetching } = personalReduxAPI.useGetAllTransactionsQuery({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return (
      <EmptyShell pageTitle="Transaction">
        <EmptyScreen loading={isLoading} />
      </EmptyShell>
    );

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Transactions">
          <SmartFilter
            alternateTitle="Transactions"
            {...SmartFilterProps}
            hideExport
            hideFilter
          />
          <PersonalTransactionTable
            ctx="page"
            data={data.transactions}
            redactedFn={redactedFn}
          />
        </PageLayout>
      </DashboardLayout>
    </>
  );
};
