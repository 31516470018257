import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import Dropdown from "@/components/common/dropdown";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { GLOBAL_PER_PAGE } from "@/constants";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { usePagination } from "@/hooks/usePagination";
import {
  mapTransfersPagination,
  usePaginationRefresh,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { PersonalAPIService } from "@/redux/slices/personal";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { APIResponseHandler } from "@/utils/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { IconVault, formatDateTime } from "@/utils/helper/Helper";
import { TypeIs, safeParse } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { useState } from "react";

interface ComplianceNINOrBVNProps {
  tab: "bvn" | "nin";
}

const Uppercase = (str: string) => str.toUpperCase();

export const ComplianceNINOrBVN = (props: ComplianceNINOrBVNProps) => {
  const { tab } = props;
  const [view, setView] = useState<PersonalComplianceRes>();
  const [revokeModal, showRevokeModal] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { renderDynamicModal } = useDynamicModal(Boolean(revokeModal));
  const { renderDynamicModal: renderView } = useDynamicModal(Boolean(view));

  const tableHeader = [
    "Full Name",
    "Email Address",
    "Mobile Number",
    `${Uppercase(tab)} Number`,
    "Date Logged",
    "Action",
  ];

  const getNinOrBvnData = () => {
    if (!view) return {};

    const { date_of_birth, first_name, gender, last_name, middle_name, phone } = view;

    return {
      [tab]: Util.safeText(view[tab]),
      fullName: Util.safeText(cn(first_name, last_name)),
      middle_name: Util.safeText(middle_name),
      gender: Util.safeText(gender),
      mobileNumber: Util.safeText(phone),
      dateOfBirth: Util.safeText(String(date_of_birth)),
    };
  };

  const dropdown = [
    {
      label: `Revoke ${Uppercase(tab)}`,
      img: IconVault(icons.block_circle),
    },
  ];

  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetUserIdTokenQuery({
      type: tab,
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      search: pagination.searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable } = renderRedactedComponent(joinedLoader);

  usePaginationRefresh(pagination, [tab]);

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  const handleRevoke = async () => {
    if (!revokeModal) return toast.error("User not selected");
    setLoading(true);
    try {
      const response = await PersonalAPIService.stripVerification({
        email: revokeModal,
        verification_type: tab,
      });

      const res = APIResponseHandler.tryHandler({ response });

      if (res) {
        refetch();
        showRevokeModal(undefined);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmartFilter
        {...SmartFilterProps}
        searchTitle={`Search by email, ${tab}, first or last name`}
      />

      {data.verifications?.length > 0 ? (
        <div className="table">
          <RavenTable action={false} className="table__main" headerList={tableHeader}>
            {data.verifications.map((row) => {
              const { res, id, type, email, created_at, token } = row;

              const payload = safeParse<PersonalComplianceRes>(res ?? "");

              if (!payload) {
                return (
                  <RavenTableRow
                    key={id}
                    {...{
                      one: RedactedTable("--"),
                      two: RedactedTable(Util.safeText(email)),
                      three: RedactedTable("--"),
                      four: RedactedTable(Util.safeText(token)),
                      five: RedactedTable(created_at ? formatDateTime(created_at) : "--"),
                      six: RedactedTable("--"),
                    }}
                  />
                );
              }

              return (
                <RavenTableRow
                  key={id}
                  onRowClick={() => setView(payload)}
                  {...{
                    one: RedactedTable(
                      <div className="table-icon-row">
                        <Avatar name={cn(payload.first_name, payload.last_name)} full />
                      </div>
                    ),
                    three: RedactedTable(Util.safeText(payload.phone)),
                    two: RedactedTable(<ClickAbleEmail email={email} />),
                    four: RedactedTable(Util.safeText(payload[type])),
                    five: RedactedTable(formatDateTime(created_at)),
                    six: RedactedTable(
                      <div>
                        <Dropdown
                          items={dropdown}
                          defaultValue={dropdown[0]}
                          onChange={(e: any) => {
                            e.event.stopPropagation();

                            showRevokeModal(email);
                          }}
                          className="ragna-dropdown"
                          disableTriggerButton
                          buttonChild={({ selected }) => <ActionDrop more />}
                        />
                      </div>
                    ),
                  }}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}

      {revokeModal &&
        renderDynamicModal(
          <ActionModal
            visible={Boolean(revokeModal)}
            onCancel={() => showRevokeModal(undefined)}
            onClick={handleRevoke}
            cancelText="No, Cancel"
            actionText="Yes, Revoke"
            btnColor="red"
            body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
            title={`Revoke ${Uppercase(tab)}`}
            loading={loading}
          />
        )}

      {view &&
        renderView(
          <ActionModal
            visible={Boolean(view)}
            onCancel={() => setView(undefined)}
            big
            onClick={() => setView(undefined)}
            actionText="Close Modal"
            btnColor="var(--primary-product-color)"
            hideCancel
            title={`${Uppercase(tab)} Details`}
          >
            {
              <div className="biz-compliance-results">
                <ImagePreview
                  imgData={[
                    {
                      src: TypeIs.any(view?.photo),
                      alt: "Image 1",
                      containerClassName: "biz-compliance-results__image-preview",
                    },
                  ]}
                />
                <div className="compliance-results__main">
                  <InlineDetails
                    direction={""}
                    title={"Transaction Details"}
                    simple
                    amount={0}
                    onClick={() => {}}
                    content={Object.entries(getNinOrBvnData()).map(([key, value]) => ({
                      label: formatWord(key),
                      value,
                    }))}
                  />
                </div>
              </div>
            }
          </ActionModal>
        )}
    </>
  );
};
