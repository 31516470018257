import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import "./styles/index.css";
import { XStack, YStack } from "@/components/common/stacks";
import { reactSelectStyle } from "@/utils/helper/Helper";

interface LogExpenseModalProps {
  visible: boolean;
  onClose(): void;
}
/** 
 * Salary & Wages
Office Expense
Maintanance
Travel Expenses
 */

const options = [
  { label: "Salary & Wages", value: "Salary & Wages" },
  { label: "Office Expense", value: "Office Expense" },
  { label: "Maintenance", value: "Maintenance" },
  { label: "Travel Expenses", value: "Travel Expenses" },
];

export const LogExpenseModal = ({ onClose, visible }: LogExpenseModalProps) => {
  const handleChange = () => {
    // implement this
  };

  return (
    <RavenModal
      onClose={onClose}
      visble={visible}
      btnColor="black-light"
      btnLabel="Log Expense"
      onBtnClick={onClose}
      className="lem"
    >
      <h4 className="lem__title">Log an expense</h4>
      <YStack>
        <XStack className="lem__xstack">
          <RavenInputField
            onChange={handleChange}
            color="black-light"
            placeholder="6924"
            label="Code"
          />
          <RavenInputField
            onChange={handleChange}
            color="black-light"
            placeholder="Select Category"
            label="Category"
            type="select"
            selectOption={options}
            selectStyles={reactSelectStyle}
          />
        </XStack>
        <XStack className="lem__xstack">
          <RavenInputField
            onChange={handleChange}
            color="black-light"
            placeholder="NGN"
            label="Currency"
            type="select"
          />
          <RavenInputField
            onChange={handleChange}
            color="black-light"
            placeholder="6924"
            label="Amount"
            type="amount"
          />
        </XStack>
        <RavenInputField
          onChange={handleChange}
          color="black-light"
          placeholder="Enter Description"
          label="Description"
        />
        <RavenInputField
          onChange={handleChange}
          color="black-light"
          placeholder="Enter Description"
          label="Description"
          type="date"
        />
        <RavenInputField
          onChange={handleChange}
          color="black-light"
          placeholder="6924"
          label="Category"
          type="upload"
          textColor="black-light"
          labelColor="black-light"
        />
      </YStack>
    </RavenModal>
  );
};
