import { businessBusinessesSlice } from "./business";
import { businessExpensesSlice } from "./expenses";
import { businessOverviewSlice } from "./overview";
import { businessTransactionSlice } from "./transaction";
import { businessCardsSlice } from "./cards";
import { businessComplianceSlice } from "./compliance";
import { businessPayrollSlice } from "./payroll";
import { businessInvoiceSlice } from "./invoice";
import { businessPaymentLinksSlice } from "./paymentLinks";
import { businessDepositSlice } from "./deposits";

export const businessStore = {
  businessOverview: businessOverviewSlice.reducer,
  businessTransactions: businessTransactionSlice.reducer,
  businessBusinesses: businessBusinessesSlice.reducer,
  businessExpenses: businessExpensesSlice.reducer,
  businessCards: businessCardsSlice.reducer,
  businessCompliance: businessComplianceSlice.reducer,
  businessPayroll: businessPayrollSlice.reducer,
  businessInvoice: businessInvoiceSlice.reducer,
  businessPaymentLinks: businessPaymentLinksSlice.reducer,
  businessDeposits: businessDepositSlice.reducer,
};
