import { PersonalScheduledBankTransfersPage, PersonalScheduledBills } from "~pb/scheduledTransactions/page";
import { PersonalScheduledTransaction } from "~pb/scheduledTransactions";

export const personal_scheduled_transaction_routes: RouteArray[] = [
  {
    path: "/personal-scheduled-transactions",
    element: PersonalScheduledTransaction,
    children: [
      { path: "", element: PersonalScheduledBankTransfersPage },
      { path: "transfers", element: PersonalScheduledBankTransfersPage },
      {path: 'airtime', element: PersonalScheduledBills},
      {path: 'data', element: PersonalScheduledBills},
      {path: 'electricity', element: PersonalScheduledBills},
      {path: 'cable', element: PersonalScheduledBills}


    ],
  },
];
