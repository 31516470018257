import { APIResponseHandler, ReduxBuilderHandler } from "@/utils/api";
import { BusinessAPI } from "@/utils/axios/business";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios/business";
import { toggleRedact } from "@/redux/actions/actions";
import { toast } from "@ravenpay/raven-bank-ui";
import { Util } from "@/utils/utility";

export const fetchDeposits = createAsyncThunk<any, Partial<PaginationQuery>>(
  "/fetch_filter_or_search_deposits",
  async (payload, thunkAPI) => {
    try {
      const params = Util.safeObject({
        range: payload?.range ?? "3",
        business_email: payload?.business_email ?? "",
        business_id: payload?.business_id ?? "",
        period_type: payload.period_type ?? "",
        search_term: payload.search_term ?? "",
        per_page: payload.per_page ?? 20,
        current_page: payload?.current_page ?? "",
        date_1: payload.date_1 ?? "",
        date_2: payload.date_2 ?? "",
      });
      const data = await axios.get(`deposits/fetch_filter_or_search_deposits`, {
        params,
      });

      if (data?.data?.status === "fail") {
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));
        thunkAPI.dispatch(SET_DEPOSITS(data.data.data));
        return data.data.data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: any = {
  loading: false,
  business_deposits: [],
};

export const businessDepositSlice = createSlice({
  name: "business-compliance",
  initialState,
  reducers: {
    SET_DEPOSITS: (state, action) => {
      state.business_deposits = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Modern approach using reduxBuilder

    const builderHandler = new ReduxBuilderHandler(builder, initialState);
    builderHandler.handleStates(fetchDeposits);
  },
});

// Action creators are generated for each case reducer function
export const { SET_DEPOSITS } = businessDepositSlice.actions;

export default businessDepositSlice.reducer;
