import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { FakePaginationProps } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import TwinValue from "@/components/common/twinValue";
import { cn } from "@/utils/colorConvert";
import Avatar from "@/components/common/avatar";
import { useNavigate } from "react-router-dom";

const isEmpty = false;

interface VirtualAccountTypeProps {
  type: "temporal" | "permanent";
}

const VirtualAccountType = ({ type }: VirtualAccountTypeProps) => {
  return (
    <div className={cn("bugiss-status", `virtual-account-type--${type}`)}>{type}</div>
  );
};

const AtlasVirtualAccountsTable = () => {
  const navigate = useNavigate();

  return (
    <div>
      <SmartFilter pagination={FakePaginationProps} />
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "ACCOUNT DETAILS",
          "BANK",
          "TYPE",
          "CUSTOMER NAME",
          "DATE CREATED",
          "ACTION",
        ]}
      >
        <RavenTableRow
          one={<TwinValue one="Nathaniel & Sons LTD" two="emmy4sure.web@gmail.com" />}
          two={<TwinValue one="NandS • Feranmi Ogechi" two="2135876118" />}
          three="Wema"
          four={<VirtualAccountType type="permanent" />}
          five={<Avatar name="Dayo Aderamo" full />}
          six="5 Sept, 2022 - 5:48PM"
          seven={icons.circle_arrow_right_faded}
          onRowClick={() => navigate("/atlas-virtual-accounts/rxjla")}
        />
        <RavenTableRow
          one={<TwinValue one="Nathaniel & Sons LTD" two="emmy4sure.web@gmail.com" />}
          two={<TwinValue one="NandS • Feranmi Ogechi" two="2135876118" />}
          three="Wema"
          four={<VirtualAccountType type="temporal" />}
          five={<Avatar name="Dayo Aderamo" full />}
          six="5 Sept, 2022 - 5:48PM"
          seven={icons.circle_arrow_right_faded}
          onRowClick={() => navigate("/atlas-virtual-accounts/rxjla")}
        />
      </RavenTable>
    </div>
  );
};

export const AtlasVirtualAccounts = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Merchants">
        {isEmpty ? <EmptyScreen /> : <AtlasVirtualAccountsTable />}
      </PageLayout>
    </DashboardLayout>
  );
};
