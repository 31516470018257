import { RavenTable } from "@ravenpay/raven-bank-ui";
import { AirtimeDataProps, BillsTable } from "../tables/BusinessTables";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EmptyScreen from "@/components/common/emptyScreen";

const AirtimeHeader = [
  "BUSINESS DETAILS",
  "REFERENCE",
  "PROVIDER",
  "AMOUNT",
  "MOBILE NUMBER",
  "DATE",
  "STATUS",
];
export const BillsAirtimePage = () => {
  const { transactions, loading: tloading } = useSelector(
    (state: RootState) => state.businessTransactions
  );
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const { loading } = useSelector((state: RootState) => state.businessBusinesses);
  const Bills = new BillsTable({ loading, content: transactions?.data as any });
  const trx: Partial<BusinessAirtimeModel>[] = (transactions?.data as any) ?? [];

  return (
    <>
      {trx?.length === 0 || (!isRedacting && loading) ? (
        <EmptyScreen loading={loading} />
      ) : (
        <RavenTable headerList={AirtimeHeader}>
          {trx.map((bill, idx) => (
            <Bills.AirtimeData key={idx} {...bill} />
          ))}
        </RavenTable>
      )}
    </>
  );
};

export const BillsDataPage = () => {
  const { transactions, loading: tloading } = useSelector(
    (state: RootState) => state.businessTransactions
  );
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const { loading } = useSelector((state: RootState) => state.businessBusinesses);
  const Bills = new BillsTable({ loading, content: transactions?.data as any });
  const trx: Partial<BusinessAirtimeModel>[] = (transactions?.data as any) ?? [];

  return (
    <>
      {trx?.length === 0 || (!isRedacting && loading) ? (
        <EmptyScreen loading={loading} />
      ) : (
        <RavenTable headerList={AirtimeHeader}>
          {trx.map((bill, idx) => (
            <Bills.AirtimeData key={idx} {...bill} />
          ))}
        </RavenTable>
      )}
    </>
  );
};

export const BillsElectricityPage = () => {
  const { transactions, loading: tloading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const { loading } = useSelector((state: RootState) => state.businessBusinesses);
  const Bills = new BillsTable({ loading, content: transactions?.data as any });
  const trx: Partial<BusinessAirtimeModel>[] = (transactions?.data as any) ?? [];
  const { isRedacting } = useSelector((state: RootState) => state.general);

  return (
    <>
      {trx?.length === 0 || (!isRedacting && loading) ? (
        <EmptyScreen loading={loading} />
      ) : (
        <RavenTable
          headerList={[
            "BUSINESS DETAILS",
            "REFERENCE",
            "PROVIDER",
            "AMOUNT",
            "METER NUMBER",
            "DATE",
            "STATUS",
          ]}
        >
          {trx.map((bill, idx) => (
            <Bills.Electricity {...bill} key={idx} />
          ))}
        </RavenTable>
      )}
    </>
  );
};

export const BillsCablePage = () => {
  const { transactions, loading: tloading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const { loading } = useSelector((state: RootState) => state.businessBusinesses);
  const Bills = new BillsTable({ loading, content: transactions?.data as any });
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const trx: Partial<BusinessAirtimeModel>[] = (transactions?.data as any) ?? [];

  return (
    <>
      {trx?.length === 0 || (!isRedacting && loading) ? (
        <EmptyScreen loading={loading} />
      ) : (
        <RavenTable
          headerList={[
            "BUSINESS DETAILS",
            "REFERENCE",
            "PROVIDER",
            "CABLE PLAN",
            "AMOUNT",
            "DATE",
            "STATUS",
          ]}
        >
          {trx.map((bill, idx) => (
            <Bills.Cable {...bill} key={idx} />
          ))}
        </RavenTable>
      )}
    </>
  );
};

export const BillsBettingPage = () => {
  const { transactions, loading: tloading } = useSelector(
    (state: RootState) => state.businessTransactions
  );

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const { loading } = useSelector((state: RootState) => state.businessBusinesses);
  const Bills = new BillsTable({ loading, content: transactions?.data as any });
  const trx: Partial<BusinessAirtimeModel>[] = (transactions?.data as any) ?? [];
  return (
    <>
      {trx?.length === 0 || (!isRedacting && loading) ? (
        <EmptyScreen loading={loading} />
      ) : (
        <RavenTable
          headerList={[
            "BUSINESS DETAILS",
            "REFERENCE",
            "PROVIDER",
            "AMOUNT",
            "DATE",
            "STATUS",
          ]}
        >
          {trx.map((bill, idx) => (
            <Bills.Betting key={idx} {...bill} />
          ))}
        </RavenTable>
      )}
    </>
  );
};
