import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosDailySummaryApi = (builder: PosBuilder) => {
  return {
    getDailySummary: createQueryWithRoute<
      PosDailySummaryModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "daily_summary", { name: "getDailySummary" }),

    getDailySummaryExport: createQueryWithRoute<
      PosDailySummaryModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "daily_summary", { export: true, name: "getDailySummaryExport" }),
  };
};
