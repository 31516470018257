import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow, RavenButton } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import { IconVault, formatDateTime, trimLongString } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import "../styles/index.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usePagination } from "@/hooks/usePagination";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import Copy, { CopyText } from "@/components/common/copyCheck";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
const BusinessPaymentLinksDetails = () => {
  const { isDarkMode } = useThemeMode();

  const [deleteModal, showDeleteModal] = useState(false);
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });
  const params = useParams();

  const dispatch = useDispatch();

  const {
    currentPage,
    setCurrentPage,
    filterValue,
    setFilterValue,
    searchQuery,
    setSearchQuery,
  } = usePagination();

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchPaymentLinkTrx({
        current_page: currentPage,
        search_term: searchQuery,
        payment_link_id: params?.id,
        per_page: 20,
        ...filterValue,
      })
    );
  }, [currentPage, filterValue, searchQuery]);

  const { payment_links_trx, loading } = useSelector(
    (state: RootState) => state.businessPaymentLinks
  );

  const navigate = useNavigate();
  const link = payment_links_trx.payment_link;
  const trx: IBusinessPaymentLinksTrx =
    payment_links_trx.payment_link_transactions_records ?? [];

  const { isRedacting } = useSelector((state: RootState) => state.general);

  async function handleDeleteLink() {
    const resp = await dispatch(
      BugissApi.business.deletePaymentLink({
        business_email: link.business_email,
        id: link.id,
      })
    );
    if (resp.payload.status) {
      navigate("/business-links");
    }
  }

  return (
    <>
      <DashboardLayout>
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "transparent", width: "100%", padding: 0 }}
          pageTitle="Payment Link Details"
        >
          <div className="bb-payment-link-top-wrap">
            <div className="bb-payment-link-top-wrap__main-content">
              <div className="bb-payment-link-top-wrap__main-content--profile-img">
                {icons.bb_payment_link}
              </div>

              <div className="bb-payment-link-top-wrap__main-content--user-data">
                <div className="user-data__main-content">
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Link Title</small>
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>{Util.safeValue(link?.title)}</p>
                      )}
                    </div>

                    <div className="main-content__col--item">
                      <small>Date Created</small>
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>{formatDateTime(link?.created_at)}</p>
                      )}
                    </div>
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Link Type</small>
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>
                          {Util.safeValue(link?.recurring ? "Recurring" : "One time")}
                        </p>
                      )}
                    </div>
                    <div className="main-content__col--item">
                      <small>Description</small>
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>{Util.safeValue(link?.request_description)}</p>
                      )}
                    </div>
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Amount Requested</small>

                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>
                          {formatNumberToCurrency(link?.request_amount, link?.currency)}
                        </p>
                      )}
                    </div>

                    {/* <div className="main-content__col--item">
                      <small>Total Pending</small>
                      <p>Verified</p>
                    </div> */}
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    <div className="main-content__col--item">
                      <small>Amount Recieved</small>

                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        <p>
                          {formatNumberToCurrency(
                            link?.total_amount_received,
                            link?.currency
                          )}
                        </p>
                      )}
                    </div>

                    {/* <div className="main-content__col--item">
                      <small>Total Pending</small>
                      <p>Verified</p>
                    </div> */}
                  </div>
                </div>

                <div className="bb-payment-link-action-btn">
                  <div className="link-url">
                    {loading ? (
                      <Redacted.Table />
                    ) : (
                      <CopyText
                        text={trimLongString(Util.safeValue(link?.link), 30) as string}
                        textToCopy={Util.safeValue(link?.link)}
                      />
                    )}
                  </div>
                  <div className="del-btn">
                    <RavenButton
                      onClick={() => showDeleteModal(true)}
                      color={"black-light"}
                    >
                      <figure>{icons.delete}</figure>
                      Delete Link
                    </RavenButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ minHeight: "55rem" }}
            className="bb-payment-link-table pb-trx-table"
          >
            <SmartFilter
              pagination={{
                currentPage: currentPage,
                itemsPerPage: Number(20),
                totalItems: Number(trx.total),
                onPageChange: function (page: number): void {
                  setCurrentPage(page);
                },
                activeFilter: "All Pending Deposits",
              }}
              searchTitle="Search link transa..."
              onFilter={(e: any) => setFilterValue(Util.filterStruct(e) as any)}
              onSearchChange={(e: string) => setSearchQuery(e)}
            />

            {(!isRedacting && loading) || trx?.data?.length === 0 ? (
              <EmptyScreen loading={loading} />
            ) : (
              <div className={cn("bb-trx-table", isDarkMode && "pb-trx-table-dark")}>
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={["FullName", "Email", "Amount", "Payment Date"]}
                >
                  {trx?.data?.map(
                    (data: IBusinessPaymentLinksTrx["data"][0], index: any) => (
                      <RavenTableRow
                        key={index}
                        one={
                          loading ? (
                            <Redacted.AvatarWithValue />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.2rem",
                              }}
                            >
                              <Avatar
                                full
                                name={Util.join(Util.safeValue(data.customer_fname)).with(
                                  Util.safeValue(data.customer_lname)
                                )}
                              />
                            </div>
                          )
                        }
                        two={
                          loading ? (
                            <Redacted.Table />
                          ) : (
                            Util.safeValue(data.customer_email)
                          )
                        }
                        // TODO: request currency key from backend
                        four={
                          loading ? (
                            <Redacted.Table />
                          ) : (
                            formatNumberToCurrency(data.amount, "NGN")
                          )
                        }
                        five={
                          loading ? <Redacted.Table /> : formatDateTime(data.created_at)
                        }
                        // seven={
                        //   <ActionDrop
                        //     more
                        //     onMoreClick={() => {
                        //       navigate("/business-payroll-details/3fmio34");
                        //     }}
                        //   />
                        // }
                      />
                    )
                  )}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={deleteModal}
        onCancel={() => {
          showDeleteModal(false);
        }}
        onClick={handleDeleteLink}
        cancelText="No, Cancel"
        actionText="Yes, Delete"
        btnColor="#FF0F00"
        loading={loading}
        body="Are you sure you want to delete this payment link?"
        title={`Delete payment link`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPaymentLinksDetails;
