import { icons } from "@/assets/icons";
import "./index.css";
import Dropdown from "../dropdown";
import { useEffect, useState } from "react";
import { cn } from "@/utils/colorConvert";
import { months } from "./months";

interface IGraphFilter {
  defaultFilter?: "Monthly" | "Yearly" | "Daily";
  onChange: (e: { for: SN; type: string }) => void;
}

type SN = string | number; // Define SN appropriately based on your usage

export default function GraphFilter({
  defaultFilter = "Monthly",
  onChange,
}: IGraphFilter) {
  const years = [2020, 2021, 2022, 2023, 2024];

  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const supportedFilters = ["Daily", "Monthly", "Yearly"];
  const [value, setValue] = useState<{ type: string; for: SN }>({
    type: "Monthly",
    for: getCurrentDate().month,
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const loopValue =
    value.type === "Yearly" ? years : value.type === "Monthly" ? months : days;

  function next() {
    setCurrentIndex((prevIndex) => {
      if (prevIndex !== loopValue?.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  }

  function prev() {
    setCurrentIndex((prevIndex) => {
      if (prevIndex !== 0) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  }

  useEffect(() => {
    const currentDate = getCurrentDate();

    if (defaultFilter) {
      const initialValue = {
        type: defaultFilter,
        for:
          defaultFilter === "Yearly"
            ? currentDate.year
            : defaultFilter === "Monthly"
              ? currentDate.month
              : currentDate.day,
      };
      setValue(initialValue);
      setCurrentIndex(loopValue.findIndex((item) => item === initialValue.for));
    } else {
      setValue({
        type: "Monthly",
        for: currentDate.month,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  useEffect(() => {
    setValue((e) => ({
      ...e,
      for: loopValue[currentIndex],
    }));
  }, [currentIndex]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className="graph-filter">
      <figure
        onClick={prev}
        className={cn("graph-filter__nav", currentIndex === 0 && "disabled")}
      >
        {icons.chevron_left}
      </figure>

      <div className="graph-filter__dropdown">
        <Dropdown
          buttonChild={() => (
            <span className="graph-filter__dropdown--item">
              <p>{value.type}</p>
              <figure className="graph-filter__dropdown--icon">
                {icons.chevron_down}
              </figure>
            </span>
          )}
          items={supportedFilters}
          rightPosition={-5}
          onChange={(e: any) => {
            const newValue = {
              type: e.label,
              for:
                e.label === "Yearly"
                  ? getCurrentDate().year
                  : e.label === "Monthly"
                    ? getCurrentDate().month
                    : getCurrentDate().day,
            };
            setValue(newValue);
            setCurrentIndex(0);
          }}
          closeOnChange
          style={{ marginTop: "20px" }}
        />

        <small>{value.for}</small>
      </div>

      <figure
        onClick={next}
        className={cn(
          "graph-filter__nav",
          currentIndex === loopValue.length - 1 && "disabled",
        )}
      >
        {icons.chevron_right}
      </figure>
    </div>
  );
}

function getCurrentDate() {
  const now = new Date();

  const day = now.getDate(); // Gets the current day of the month
  const monthIndex = now.getMonth(); // Gets the current month (0-11)
  const year = now.getFullYear(); // Gets the current year

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[monthIndex]; // Convert month index to month name

  return { day, month, year };
}
