import BusinessSingleUser from "@/apps/business/businesses/components/singleBusiness";
import SingleBusinessBills from "@/apps/business/businesses/components/singleBusiness/pages/bills/airtime";
import SingleBusinessCards from "@/apps/business/businesses/components/singleBusiness/pages/cards";
import SingleBusinessCompliance from "@/apps/business/businesses/components/singleBusiness/pages/compliance";
import SingleBusinessTeams from "@/apps/business/businesses/components/singleBusiness/pages/teams";
import SingleBusinessTransactions from "@/apps/business/businesses/components/singleBusiness/pages/transactions";
import SingleBusinessBulkTransfer from "@/apps/business/businesses/components/singleBusiness/pages/transfers/bulk_transfer";
import SingleBusinessInternalTransfer from "@/apps/business/businesses/components/singleBusiness/pages/transfers/internal_transfers";
import SingleBusinessTransfers from "@/apps/business/businesses/components/singleBusiness/pages/transfers/transfers";
export const business_details_routes: RouteArray[] = [
  {
    path: "/business-businesses/:email",
    element: BusinessSingleUser,
    children: [
      {
        path: "/business-businesses/:email/transactions",
        element: SingleBusinessTransactions,
      },
      {
        path: "/business-businesses/:email/bulk-transfer",
        element: SingleBusinessBulkTransfer,
      },
      {
        path: "/business-businesses/:email/data",
        element: () => <SingleBusinessBills bill="data" />,
      },
      {
        path: "/business-businesses/:email/airtime",
        element: () => <SingleBusinessBills bill="airtime" />,
      },
      {
        path: "/business-businesses/:email/bulk-transfer-details",
        element: BusinessSingleUser,
      },
      {
        path: "/business-businesses/:email/bills",
        element: () => <SingleBusinessBills bill="airtime" />,
      },
      {
        path: "/business-businesses/:email/transfer",
        element: SingleBusinessTransfers,
      },
      {
        path: "/business-businesses/:email/cable",
        element: () => <SingleBusinessBills bill="cable" />,
      },
      {
        path: "/business-businesses/:email/electricity",
        element: () => <SingleBusinessBills bill="electricity" />,
      },
      {
        path: "/business-businesses/:email/betting",
        element: () => <SingleBusinessBills bill="betting" />,
      },
      {
        path: "/business-businesses/:email/internal-transfer",
        element: SingleBusinessInternalTransfer,
      },
      {
        path: "/business-businesses/:email/cards",
        element: SingleBusinessCards,
      },
      {
        path: "/business-businesses/:email/overdraft",
        element: BusinessSingleUser,
      },
      {
        path: "/business-businesses/:email/compliance",
        element: SingleBusinessCompliance,
      },
      {
        path: "/business-businesses/:email/team-members",
        element: SingleBusinessTeams,
      },
    ],
  },
];
