import { RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "./RedPayModal.css";

interface MyComponentProps {
  children?: React.ReactNode;
  loading?: boolean;
  visible?: boolean;
  disabled?: boolean;
  dontShowClose?: boolean;
  outerClose?: boolean;
  onBtnClick?: () => void;
  onClose?: () => void;
  btnLabel?: string;
  modalTitle?: string;
}

const OffsetModal = ({
  children,
  loading,
  onBtnClick,
  onClose,
  visible,
  btnLabel,
  modalTitle,
  disabled,
  dontShowClose,
  outerClose
}: MyComponentProps) => {
  return (
    <RavenModal
      className={`Red-pay-reusable-modal-wrap`}
      btnColor="black-light"
      btnLabel={btnLabel}
      visble={visible}
      onClose={onClose}
      loading={loading}
      disabled={disabled}
      outerClose={outerClose}
      dontShowClose={dontShowClose}
      effect="fadeInLeft"
      onBtnClick={() => {
        onBtnClick && onBtnClick();
      }}
    >
      <div className="modal-title-box">
        <p className="title">{modalTitle}</p>
      </div>
      {children}
    </RavenModal>
  );
};

export default OffsetModal;
