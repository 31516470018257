import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Txreference from "@/components/common/reference/TxRef";
import Dropdown from "@/components/common/dropdown";
import { IconVault, formatDateTime, trimLongString } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import { BugissApi } from "@/redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import EmptyScreen from "@/components/common/emptyScreen";
import { usePagination } from "@/hooks/usePagination";
import Redacted from "@/components/common/shimmer/redact";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";

function formatStatus(status: string | number, refunded?: number) {
  let s;
  if (status === 0 || status === "inactive") {
    s = "Failed";
  }
  if (status === 1 || status === "active") {
    s = "Successful";
  }
  return (
    <div className={`bugiss-status ${s && s?.toLowerCase()}`}>
      <p>{s}</p>
    </div>
  );
}

const BusinessPendingDeposits = () => {
  const { isDarkMode } = useThemeMode();
  const { setCurrentPage, searchQuery, currentPage, setSearchQuery } = usePagination();
  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });
  const [filterValue, setFilterValue] = useState<any>({});

  const dropdown = [
    {
      label: `Mark as Paid`,
      img: IconVault(icons.check_circle),
      func: showMarkPaid,
    },
    {
      label: `Refire`,
      img: IconVault(icons.refire),
      func: showRefireModal,
    },
  ] as const;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      BugissApi.business.fetchDeposits({
        per_page: 20,
        search_term: searchQuery,
        ...filterValue,
      })
    );
  }, []);

  const { business_deposits, loading } = useSelector(
    (state: RootState) => state.businessDeposits
  );
  const { isRedacting } = useSelector((state: RootState) => state.general);

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchDeposits({
        per_page: 20,
        search_term: searchQuery,
        ...filterValue,
      })
    );
  }, [currentPage, searchQuery, filterValue]);
  const deposits = business_deposits?.data;

  const valuePair = {
    "RECIEVER NAME": "receiving_account_name",
    "RECIEVER ACC NO": "email",
    "SENDER NAME": "meta?.first_name",
    "SENDER ACC NO": "meta?.last_name",
    AMOUNT: "amount",
    "DATE DEPOSITED": "created_at",
  };
  const { handleExport } = useExportHandler({
    setFilterValue,
    data: deposits,
    loading,
    valuePair,
    title: "Business Export(Bugiss)",
  });
  const { toggleModal } = useExportModal(handleExport);

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Deposits">
          <SmartFilter
            onSearchChange={(e: string) => setSearchQuery(e)}
            onExport={() => toggleModal("Export Deposits")}
            pagination={{
              currentPage: currentPage,

              itemsPerPage: 20,
              totalItems: business_deposits?.total,
              onPageChange: function (page: number): void {
                setCurrentPage(page);
              },
              activeFilter: "All Deposits",
            }}
            onFilter={(e: any) => setFilterValue(Util.filterStruct(e) as any)}
            searchTitle="Search deposits..."
          />

          <div className={cn("bb-trx-table", isDarkMode && "bb-trx-table-dark")}>
            {(!isRedacting && loading) || !deposits || deposits?.length === 0 ? (
              <div className="invoice-loading">
                <EmptyScreen loading={loading} />
              </div>
            ) : (
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "Destination",
                  "Reference",
                  "Sender Details",
                  "Amount",
                  "Date Deposited",
                  "",
                ]}
              >
                {deposits.map((data: any, index: SN) => {
                  const meta = JSON.parse(data?.source);

                  return (
                    <RavenTableRow
                      key={index}
                      one={
                        loading ? (
                          <Redacted.Labelled />
                        ) : (
                          <TwinValue
                            one={Util.safeValue(
                              trimLongString(data?.receiving_account_name, 30)
                            )}
                            two={data?.receiving_account_number}
                          />
                        )
                      }
                      two={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          <Txreference
                            reference={Util.safeValue(
                              trimLongString(data?.session_id, 15)
                            )}
                          />
                        )
                      }
                      three={
                        loading ? (
                          <Redacted.Labelled />
                        ) : (
                          <TwinValue
                            one={Util.safeValue(
                              trimLongString(
                                Util.safeValue(meta?.first_name) +
                                  " " +
                                  Util.safeValue(meta?.last_name),
                                15
                              )
                            )}
                            two={Util.safeValue(meta?.account_number)}
                          />
                        )
                      }
                      four={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data?.amount, "NGN")
                        )
                      }
                      five={
                        loading ? <Redacted.Table /> : formatDateTime(data?.created_at)
                      }
                    />
                  );
                })}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Refire"
        btnColor="#FF0F00"
        body="Are you sure to refire this transaction? This action is irreversible."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Mark Paid"
        btnColor="#1ACE37"
        body="Are you sure to mark this transaction as paid? This action is irreversible.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPendingDeposits;
