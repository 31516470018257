import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import EmptyScreen from "@/components/common/emptyScreen";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { GENERAL_CLASSES, GLOBAL_PER_PAGE } from "@/constants";
import { PersonalInternalTransferOutTable } from "~pb/__internals__/combined-tables/transfers-table/index";
import { cn } from "@/utils/colorConvert";
import { usePbInternalTransferExport } from "../../__internals__/internal-hooks/transfers";

export const PersonalTransferInternalPage = () => {
  const { ExportModal, onExport } = usePbInternalTransferExport();
  const pagination = usePagination();
  const { currentPage, searchQuery } = pagination;
  const { data, isFetching, isLoading } =
    personalReduxAPI.useGetAllInternalTransfersQuery({
      page: currentPage,
      per_page: GLOBAL_PER_PAGE,
      search: searchQuery,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  if (isLoading || !data)
    return (
      <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP, "")}>
        <EmptyScreen loading={isLoading} />
      </div>
    );

  return (
    <div className={cn(GENERAL_CLASSES.PERSONAL_TRANSFER_TABLE_WRAP, "")}>
      <SmartFilter {...SmartFilterProps} onExport={onExport} />
      {data.internalTransfers.length > 0 ? (
        <PersonalInternalTransferOutTable
          ctx="page"
          data={data.internalTransfers}
          redactedFn={redactedFn}
        />
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}
      <ExportModal />
    </div>
  );
};
