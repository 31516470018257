import { useState } from "react";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import SmartFilter from "@/components/common/smartFilter";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import TwinValue from "@/components/common/twinValue";
import "./styles/index.css";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { EmptyShell } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { CopyText } from "@/components/common/copyCheck";
import { Util } from "@/utils/utility";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { usePagination } from "@/hooks/usePagination";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { DisputeModal } from "../users/single-user/pages/disputes/dispute-modal";
import { TypeIs } from "@/utils/helpers";
import { GLOBAL_PER_PAGE } from "@/constants";
import { ControlledTableContent } from "@/components/common/stacks";
import { cn } from "@/utils/colorConvert";

export const PersonalDispute = () => {
  const [selectedRow, setSelectedRow] = useState<PersonalNewHelpLugDatum>();
  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } = personalReduxAPI.useGetAllHelpLogQuery(
    {
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      status: "",
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
      email: pagination.searchQuery,
    }
  );

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { renderDynamicModal } = useDynamicModal(Boolean(selectedRow));

  if (isLoading || !data)
    return (
      <EmptyShell pageTitle="Dispute">
        <TransferEmptyScreen loading={isLoading} height="75vh" />
      </EmptyShell>
    );

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Dispute">
          <div className="personal-savings__top-wrap">
            <SmartFilter
              {...SmartFilterProps}
              tableExport={{
                data: data.data,
                fileName: cn("Disputes", new Date().toString()),
              }}
              hideExport
            />
          </div>
          {data.data?.length > 0 ? (
            <>
              <div className="table">
                <RavenTable
                  className="table__main"
                  headerList={[
                    "User details",
                    "REFERENCE",
                    "MESSAGE",
                    "TYPE",
                    "HELP OPTION",
                    "TRANSACTION DATE",
                    "STATUS",
                    " ",
                  ]}
                >
                  {data.data.map((row) => {
                    const {
                      id,
                      created_at,
                      message,
                      status,
                      transaction_id,
                      transaction_type,
                      help_option,
                      email,
                      transaction,
                    } = row;

                    return (
                      <RavenTableRow
                        key={id}
                        one={RedactedTable(
                          <ClickAbleEmail email={email}>
                            <div style={{ cursor: "pointer" }}>
                              <TwinValue
                                one={email}
                                two={Util.safeText(transaction._from)}
                              />
                            </div>
                          </ClickAbleEmail>
                        )}
                        two={RedactedTable(<CopyText text={transaction_id} />)}
                        three={RedactedTable(
                          trimLongString(Util.safeText(message || "--"), 30)
                        )}
                        four={RedactedTable(Util.safeText(transaction_type))}
                        five={RedactedTable(
                          <ControlledTableContent>
                            {Util.safeText(help_option)}
                          </ControlledTableContent>
                        )}
                        six={RedactedTable(formatDateTime(created_at))}
                        seven={RedactedTable(formatStatus(status))}
                        onRowClick={() => {
                          setSelectedRow(row);
                        }}
                      />
                    );
                  })}
                </RavenTable>
              </div>
            </>
          ) : (
            <TransferEmptyScreen height="75vh" />
          )}
        </PageLayout>
      </DashboardLayout>

      {renderDynamicModal(
        selectedRow ? (
          <DisputeModal
            onCancel={() => setSelectedRow(undefined)}
            row={TypeIs.any(selectedRow)}
            refetch={refetch}
          />
        ) : null
      )}
    </>
  );
};
