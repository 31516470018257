import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCookie } from "../utils/helper/Helper";
import baxios from "@/utils/axios/business";
import paxios from "@/utils/axios/personal";
import posaxios from "@/utils/axios/pos";
import axios from "@/utils/axios/general";
import { SET_TOKEN } from "@/redux/slices/auth/auth";

const useUser = () => {
  const dispatch = useDispatch();
  //   const { profile } = useSelector((state: RootState) => state.wallet);
  const tokenInState = useSelector((state: RootState) => state.auth).token;
  const token = getCookie("token") ?? tokenInState;

  const setAuthorizationHeaders = (token: string) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      redirect: "follow",
    };
    baxios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      redirect: "follow",
    };
    paxios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      redirect: "follow",
    };
    posaxios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      redirect: "follow",
    };
  };

  if (token) {
    setAuthorizationHeaders(token);
  } else {
    const localToken = getCookie("token");
    if (localToken) {
      setAuthorizationHeaders(localToken);
      dispatch(SET_TOKEN(localToken));
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }
};

export default useUser;
