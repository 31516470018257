import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { Row } from "@/components/common/stacks";
import TwinValue from "@/components/common/twinValue";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { IconVault } from "@/utils/helper/IconVault";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const BGlobalDeposits = () => {
  const [checked, setChecked] = useState<SN[]>([]);
  const [allChecked, setIsAllChecked] = useState(false);

  const dropdown = (status: number) => {
    return [
      { label: "Confirm Integrity", img: IconVault(icons.info_circle) },

      {
        label: "Unblock User",
        img: IconVault(icons.check_circle_green),
      },
    ] as const;
  };

  const isCheckActive = checked?.length > 0 || allChecked;
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const { data, isFetching, isLoading, refetch } =
    businessReduxAPI.useGetGlobalAccountsDepositsQuery({});

  const loading = isFetching || isLoading;

  const deposits = data?.transactions?.data;

  return (
    <div style={{ height: "67vh", display: "flex", flex: 1, flexDirection: "column" }}>
      <SmartFilter
        page="insights"
        hideFilter={isCheckActive}
        hideExport={isCheckActive}
        dynamicNode={
          isCheckActive ? (
            <Row gap={1.6}>
              {/* <UnblockBizBtn
                onClick={() => {
                  triggerBlock(showBlockModal, blockModal);
                }}
              />
              <MessageBizBtn
                onClick={() => {
                  showSendMessages({
                    on: true,
                    chi: {
                      count: checked?.length,
                    },
                  });
                }}
              /> */}
            </Row>
          ) : null
        }
        searchTitle={`Search deposits...`}
        // pagination={{
        //   currentPage: currentPage,
        //   itemsPerPage: Number(businesses.per_page),
        //   // itemsPerPage: Number(businesses.per_page),
        //   totalItems: businesses.total,
        //   onPageChange: function (page: number): void {
        //     setCurrentPage(page);
        //   },
        //   activeFilter: `All ragnarred`,
        // }}
      />

      {(!isRedacting && loading) || deposits?.length === 0 || !deposits ? (
        <EmptyScreen loading={loading} />
      ) : (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "Reciever",
              "Reference",
              //   "Reason for Lien",
              "Amount",
              "Date Deposited",
              "Status",
            ]}
          >
            {deposits?.map((data, index: number) => (
              <RavenTableRow
                key={index}
                {...{
                  one: loading ? (
                    <Redacted.Labelled />
                  ) : (
                    <TwinValue one={data?.business_email} two={data.business_email} />
                  ),
                  two: loading ? <Redacted.Table /> : Util.safeValue(data.trx_ref),
                  three: loading ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(
                      Number(data.amount),
                      data?.currency?.toUpperCase()
                    )
                  ),
                  four: loading ? <Redacted.Table /> : formatDateTime(data?.created_at),
                  five: loading ? (
                    <Redacted.Badge />
                  ) : (
                    <div>
                      {formatStatus(
                        data.status
                        // discoverStatus(data) === 1 ? "active" : "inactive"
                      )}
                    </div>
                  ),

                  six: (
                    <div>
                      <Dropdown
                        items={dropdown(Number(data.status)) as any}
                        defaultValue={dropdown(Number(data.status))[0]}
                        onChange={(e) => {}}
                        className="ragna-dropdown"
                        buttonChild={() => (
                          <ActionDrop
                            more
                            // onMoreClick={() => {
                            //   navigate("/personal-users/re43rfs");
                            // }}
                          />
                        )}
                      />
                    </div>
                  ),
                }}
              />
            ))}
          </RavenTable>
        </div>
      )}
    </div>
  );
};

export default BGlobalDeposits;
