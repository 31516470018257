import mtn from "@/assets/images/mtn.png";
import glo from "@/assets/images/glo.png";
import airtel from "@/assets/images/airtel.png";
import mobile from "@/assets/images/9mobile.png";
import consat from "@/assets/images/consat.png";
import dstv from "@/assets/images/dstv.png";
import gotv from "@/assets/images/gotv.png";
import bet9ja from "@/assets/images/bet9ja.png";
import betKing from "@/assets/images/betking.png";
import bet1960 from "@/assets/images/bet360.png";
import bet360 from "@/assets/images/1960bet.png";
import xbet from "@/assets/images/1xbet.png";
import danaAir from "@/assets/images/dana-air.png";
import discoveryAir from "@/assets/images/discovery-air.png";
import wakanow from "@/assets/images/wakanow.png";
import aeroAir from "@/assets/images/aero-air.png";
import hakAir from "@/assets/images/hak-air.png";
import betway from "@/assets/images/betway.png";
import startimes from "@/assets/images/startimes.png";
import { brands } from "@/assets/icons";
import { lowerCase } from "@/utils/formatWord";

const billersLogoMap: Record<string, string> = {
  mtn: mtn,
  glo: glo,
  airtel: airtel,
  "9mobile": mobile,
  gotv: gotv,
  dstv: dstv,
  startimes: startimes,
  consat: consat,
  bet9ja: bet9ja,
  "1960bet": bet1960,
  betway: betway,
  betking: betKing,
  bet360: bet360,
  "1xbet": xbet,
  "dana airline": danaAir,
  wakanow: wakanow,
  "hak airlines": hakAir,
  "fly aero airline": aeroAir,
  "discovery airlines": discoveryAir,
};

export function autoBillersLogo(biller: string) {
  const normalizedBrandName = lowerCase(biller);
  return billersLogoMap[normalizedBrandName] || brands.raven;
}
