import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import gradient from "chartjs-plugin-gradient";
import useThemeMode from "@/hooks/useThemeMode";
import { useProductTheme } from "@/context/ProductTheme";
import { getThemePrimaryColor } from "@/utils/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  gradient
);

type Options = React.ComponentProps<typeof Line>["options"];

const options: Options = {
  elements: {
    line: { tension: 0.4, borderWidth: 2 },
  },
  maintainAspectRatio: false,
  responsive: true,
  // removed gradient from here, if it breaks anything, please add
  plugins: {
    legend: { position: "top", display: false },
    title: { display: false, text: "Chart.js Line Chart" },
  },
  scales: {
    x: { backgroundColor: "#fff", grid: { display: false } },
    y: { display: false },
  },
};

const graphLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

interface OverviewAreaChartProps {
  data: SN[];
  labels?: SN[];
}

export const OverviewLineChart = (props: OverviewAreaChartProps) => {
  const { isDarkMode } = useThemeMode();
  const { productTheme } = useProductTheme();
  const { data, labels } = props;

  const lightModeGradient =
    productTheme === "atlas"
      ? {
          axis: "y",
          colors: {
            0: "#FEFEFF",
            "37.81": "#E8FFF6",
            63: "#FCFCFF",
            "76.59": "#FEFEFF",
            "93.05": "#FEFEFF",
          },
        }
      : {
          axis: "y",
          colors: {
            0: "#F5F5FF",
            "37.81": "#F8F8FF",
            63: "#FCFCFF",
            "76.59": "#FEFEFF",
            "93.05": "#FEFEFF",
          },
        };

  const darkModeGradient = {
    axis: "y",
    colors: {
      0: "#292929",
      100: "#1A1A1A",
    },
  };

  const graphData = {
    labels: labels ?? graphLabels,
    datasets: [
      {
        fill: productTheme !== "business",
        label: "",
        data,
        borderColor: getThemePrimaryColor(),
        gradient: {
          backgroundColor: isDarkMode ? darkModeGradient : lightModeGradient,
        },
      },
    ],
  };

  return (
    <Line
      height={100}
      width={100}
      redraw={true}
      options={options}
      data={graphData as any}
    />
  );
};

export const Graph = ({ data, labels }: { data: any[]; labels?: any[] }) => {
  const { isDarkMode } = useThemeMode();
  const { productTheme } = useProductTheme();

  const lightModeGradient =
    productTheme === "atlas"
      ? {
          axis: "y",
          colors: {
            0: "#FEFEFF",
            "37.81": "#E8FFF6",
            63: "#FCFCFF",
            "76.59": "#FEFEFF",
            "93.05": "#FEFEFF",
          },
        }
      : {
          axis: "y",
          colors: {
            0: "#F5F5FF",
            "37.81": "#F8F8FF",
            63: "#FCFCFF",
            "76.59": "#FEFEFF",
            "93.05": "#FEFEFF",
          },
        };

  const darkModeGradient = {
    axis: "y",
    colors: {
      0: "#292929",
      100: "#1A1A1A",
    },
  };

  const graphData: ChartData<any> = {
    labels: labels || graphLabels,
    datasets: [
      {
        fill: productTheme !== "business",
        label: "",
        data: data,
        borderColor: getThemePrimaryColor(),
        gradient: {
          backgroundColor: isDarkMode ? darkModeGradient : lightModeGradient,
        },
      },
    ],
  };

  return (
    <Line height={100} width={100} redraw={true} options={options} data={graphData} />
  );
};
