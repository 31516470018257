import { createContext, useContext, useState } from "react";
import { BaseTransactionProps } from "./transaction-details";

interface State {
  selectedTransaction: IsUncertain<BaseTransactionProps>;
  setSelectedTransaction(transaction?: BaseTransactionProps): void;
}

const Context = createContext<State>({
  selectedTransaction: undefined,
  setSelectedTransaction: () => {
    throw new Error("Context used before it was defined");
  },
});

export const TransfersContext = ({ children }: IChildren) => {
  const [selectedTransaction, setSelectedTransaction] = useState<BaseTransactionProps>();
  return (
    <Context.Provider value={{ selectedTransaction, setSelectedTransaction }}>
      {children}
    </Context.Provider>
  );
};

export const useTransfersContext = () => {
  const state = useContext(Context);

  return state;
};
