import {
  TOGGLE_MENU,
  ONSWATCH,
  TOGGLE_REDACT,
  SET_GLOBAL_CURRENCY,
} from "./actions/actionTypes";

interface GeneralState {
  isMenuOpen: boolean;
  isRedacting: boolean;
  selectedGlobalCurrency: Partial<Currencies>;
  bankbox_brand_color: string;
}

const initialState: GeneralState = {
  isMenuOpen: false,
  isRedacting: false,
  bankbox_brand_color: "EA872D",
  selectedGlobalCurrency: {},
};

const generalReducer = (
  state: GeneralState = initialState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: action.payload ?? !state.isMenuOpen,
      };

    case TOGGLE_REDACT:
      return {
        ...state,
        isRedacting: action.payload ?? !state.isRedacting,
      };
    case ONSWATCH:
      return {
        ...state,
        isMenuOpen: action.payload ?? !state.isMenuOpen,
      };

    case SET_GLOBAL_CURRENCY:
      return {
        ...state,
        selectedGlobalCurrency: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
