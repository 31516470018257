import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useSingleUserState } from "../../context";
import { cleanWhiteSpace } from "@/utils/colorConvert";
import { GLOBAL_PER_PAGE } from "@/constants";
import { useNavigate } from "react-router-dom";
import { usePbSpendSave } from "@/apps/personal/__internals__/internal-hooks/savings";
import { ConditionalCopyText } from "@/components/common/copyCheck";

export const SpendXSave = () => {
  const pagination = usePagination();
  const { email } = useSingleUserState();
  const { data, isFetching, isLoading } = personalReduxAPI.useGetSpendAndSaveQuery({
    page: pagination.currentPage,
    // search: pagination.searchQuery,
    per_page: GLOBAL_PER_PAGE,
    type: "spendxsave",
    email,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });
  const { ExportModal, onExport } = usePbSpendSave(email);
  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );
  const navigate = useNavigate();

  const handleClick = (row: PersonalSpendSave) => {
    navigate(`/personal-savings/spendsave/${row.id}`);
  };

  if (isLoading || !data)
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />;
      </div>
    );

  const filteredSavings = data.spendSaves.filter((record) => {
    if (!pagination.searchQuery) return true;
    const search = pagination.searchQuery ?? "";
    return Util.search(record.reference, search);
  });

  return (
    <div className="mt-20">
      <SmartFilter
        {...SmartFilterProps}
        searchTitle="Search by reference"
        onExport={onExport}
        pagination={{
          ...SmartFilterProps.pagination,
          currentPage: pagination.searchQuery ? 1 : pagination.currentPage,
          totalItems: pagination.searchQuery
            ? filteredSavings.length
            : SmartFilterProps.pagination.totalItems,
        }}
      />
      <RavenTable
        className="table__main"
        headerList={["REFERENCE", "Balance", "PERCENTAGE", "DATE CREATED", "STATUS"]}
      >
        {filteredSavings.map((row) => {
          const { id, balance, percentage, reference, created_at, status } = row;

          return (
            <RavenTableRow
              key={id}
              one={RedactedTable(<ConditionalCopyText text={reference} />)}
              two={RedactedTable(Util.safeText(formatNumberToCurrency(balance)))}
              three={RedactedTable(Util.safeText(cleanWhiteSpace(percentage, "%")))}
              five={RedactedTable(
                Util.safeText(created_at ? formatDateTime(created_at) : "--")
              )}
              seven={RedactedTable(formatStatus(detectStatus(status, "pb-savings")))}
              onRowClick={() => handleClick(row)}
            />
          );
        })}
      </RavenTable>
      <ExportModal />
    </div>
  );
};
