import {
  LICENSE_OR_PASSPORT_CTX,
  PassportOrLicensePayload,
} from "@/apps/personal/compliance/pages/details-checker/components/drivers-license-passort-form";
import { METER_NUMBER_PAYLOAD } from "@/apps/personal/compliance/pages/details-checker/components/cable-info-form";
import { BVN_NIN_METER_NUMBER } from "@/apps/personal/compliance/pages/details-checker/components/nin-bvn-voters-form";
import PersonalApi from "@/utils/axios/personal";
import { iife } from "@/utils/general";

interface CheckNINOrBVNOrMeterNumber {
  type: BVN_NIN_METER_NUMBER;
  token: SN;
  provider: string;
}

interface CheckDriversOrPassport {
  data: PassportOrLicensePayload;
  type: LICENSE_OR_PASSPORT_CTX;
}

export const DetailsCheckerAPI = {
  async checkNINOrBVNOrMeterNumber(data: CheckNINOrBVNOrMeterNumber) {
    const { provider, token, type } = data;

    const url = iife(() => {
      if (type === "vin") return "/support/pvc-info";
      return `/support/${type}-info`;
    });

    const res = await PersonalApi.get(url, {
      params: {
        [type]: token,
        provider,
      },
    });

    return res;
  },
  async checkDriversOrPassport(payload: CheckDriversOrPassport) {
    const { data, type } = payload;

    const url = iife(() => {
      if (type === "license") return "/support/drivers-license-info";
      return "/support/verify-passport";
    });

    const res = await PersonalApi.post(url, data);

    return res;
  },
  async checkCableInfoDetails(payload: METER_NUMBER_PAYLOAD) {
    const res = await PersonalApi.post("/support/cable-account-info", payload);

    return res;
  },
  async checkMeterCardNumber(payload: unknown) {
    const res = await PersonalApi.post("/support/validate-electricity-meter", payload);
    return res;
  },
};
