import PosInsights from "@/apps/pos/insights";
import { PosInsightsTransfers } from "@/apps/pos/insights/pages/transfers";
export const pos_insights_routes: RouteArray[] = [
  {
    path: "/pos-insights",
    element: PosInsights,
    children: [
      {
        path: "/pos-insights/bank-transfers",
        element: PosInsightsTransfers.BankTransfers,
      },
      {
        path: "/pos-insights/internal-transfers",
        element: PosInsightsTransfers.InternalTransfers,
      },
      //   {
      //     path: "/pos-insights/airtime",
      //     element: PosReversalBillsAirtimePage,
      //   },
      //   {
      //     path: "/pos-insights/data",
      //     element: PosReversalBillsDataPage,
      //   },
      //   {
      //     path: "/pos-insights/electricity",
      //     element: PosReversalBillsElectricityPage,
      //   },
      //   {
      //     path: "/pos-insights/cable",
      //     element: PosReversalBillsCablePage,
      //   },
    ],
  },
];
