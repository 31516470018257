import { RavenTableRow } from "@ravenpay/raven-bank-ui";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { BillerStack, XStack } from "@/components/common/stacks";
import Copy, { CopyText } from "@/components/common/copyCheck";
import { autoBillersLogo } from "@/constants/auto-billers-logo";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";

interface Defaults {
  name: string;
  email: string;
  amount: SN;
  provider: string;
  date: string;
  status: string;
}

export interface AirtimeDataProps extends Defaults {
  phoneNumber: string;
  reference: string;
}

export class BillsTable {
  static AirtimeData = (
    props: (PosBillAirtime | PosBillData) & { isRedacting: boolean },
  ) => {
    const {
      amount,
      email,
      reference,
      isRedacting,
      phone_number,
      status,
      tid,
      created_at,
    } = props;

    const meta = JSON.parse(props?.meta_data);
    const platform = meta?.network ?? "";
    const biller = autoBillersLogo(platform);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <AvatarWithTwinValue
              one={
                <CopyText text={trimLongString(email, 20) as string} textToCopy={email} />
              }
              two={`TID: ${Util.safeValue(tid)}`}
              name={Util.safeValue(email)}
            />
          )
        }
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText
              text={trimLongString(reference, 20) as string}
              textToCopy={reference}
            />
          )
        }
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        three={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <BillerStack biller={biller} provider={platform} />
          )
        }
        five={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack gap={0.4} style={{ alignItems: "center" }}>
              <span>{phone_number}...</span>
              <Copy text={phone_number} tableCopy />
            </XStack>
          )
        }
        six={isRedacting ? <Redacted.Table /> : formatDateTime(created_at)}
        seven={isRedacting ? <Redacted.Badge /> : formatStatus(status)}
      />
    );
  };
  static Electricity = (props: PosBillElectricity & { isRedacting: boolean }) => {
    const {
      amount,
      email,
      isRedacting,
      status,
      provider,
      tid,
      created_at,
      meter_number,
    } = props;

    const biller = autoBillersLogo(provider);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <AvatarWithTwinValue
              one={
                <CopyText text={trimLongString(email, 20) as string} textToCopy={email} />
              }
              two={`TID: ${Util.safeValue(tid)}`}
              name={Util.safeValue(email)}
            />
          )
        }
        two={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <BillerStack biller={biller} provider={provider} />
          )
        }
        three={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        four={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack gap={0.4} style={{ alignItems: "center" }}>
              <span>{meter_number}...</span>
              <Copy text={meter_number} tableCopy />
            </XStack>
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDateTime(created_at)}
        six={isRedacting ? <Redacted.Badge /> : formatStatus(status)}
      />
    );
  };
  static Cable = (props: PosBillCable & { isRedacting: boolean }) => {
    const {
      amount,
      email,
      isRedacting,
      smart_card_number,
      tid,
      status,
      provider,
      created_at,
    } = props;

    const biller = autoBillersLogo(provider);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <AvatarWithTwinValue
              one={
                <CopyText text={trimLongString(email, 20) as string} textToCopy={email} />
              }
              two={`TID: ${Util.safeValue(tid)}`}
              name={Util.safeValue(email)}
            />
          )
        }
        two={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <BillerStack biller={biller} provider={provider} />
          )
        }
        three={isRedacting ? <Redacted.Badge /> : formatStatus(status)}
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        five={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack gap={0.4} style={{ alignItems: "center" }}>
              <span>{smart_card_number}...</span>
              <Copy text={smart_card_number} tableCopy />
            </XStack>
          )
        }
        six={isRedacting ? <Redacted.Table /> : formatDateTime(created_at)}
        seven={isRedacting ? <Redacted.Badge /> : formatStatus(status)}
      />
    );
  };
  static Betting = (props: PosBillBet & { isRedacting: boolean; loading: boolean }) => {
    const { amount, email, isRedacting, status, platform, tid, created_at, account_id } =
      props;

    const biller = autoBillersLogo(platform);

    return (
      <RavenTableRow
        one={
          isRedacting ? (
            <Redacted.Labelled />
          ) : (
            <AvatarWithTwinValue
              one={
                <CopyText text={trimLongString(email, 20) as string} textToCopy={email} />
              }
              two={`TID: ${Util.safeValue(tid)}`}
              name={Util.safeValue(email)}
            />
          )
        }
        two={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <BillerStack biller={biller} provider={platform} />
          )
        }
        three={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        four={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <XStack gap={0.4} style={{ alignItems: "center" }}>
              <span>{account_id}...</span>
              <Copy text={account_id} tableCopy />
            </XStack>
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDateTime(created_at)}
        six={isRedacting ? <Redacted.Badge /> : formatStatus(status)}
      />
    );
  };
}
